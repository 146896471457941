import React from 'react'

//import scss
import './Destinations.scss'

//using react context
import {TravelCategories_Context} from '../../Manage/Context/TravelCategories_Context'

//import icons 
import { BsBookmark } from "react-icons/bs";
import { BsFillBookmarkFill } from "react-icons/bs"; 
import { TravelCategories_2_Context } from '../../Manage/Context/TravelCategories_2_Context';

function Destinations() {

    // using react context 
    const {
        famousDestinations, twoDaysFamousDestinations, offBeatDestinations, 
        navigation_ActiveDestination, setNavigation_ActiveDestination, 
        activeDestination, setActiveTouristPlaceNavigation,
        saved_destinations_data, 
        handleChange_Destination_TouristPlaces, handle_add_destination, handle_remove_destination, 
        activeTravelCategory, setActiveTravelCategory
    } = TravelCategories_Context();


  return (
    <div className='container_p-Destinations'>

        <div className='container_s-Destinations'>

            {/* conatiner 1 - to hold the navigation and destinations  */}
            <div className='conatiner_p-1-Destinations-Destinations'>
                        
                <div className='conatiner_p-navigationButtons-Destinations'>
                    {
                        famousDestinations.length > 0 && 
                        (         
                            <button 
                                onClick={()=>{setNavigation_ActiveDestination("famous")}}
                                className={navigation_ActiveDestination === "famous" ? 'button-active-navigation-Destinations' : 'button-unActive-navigation-Destinations'}
                            >
                                Famous Destinations
                            </button>
                        )
                    }

                    {
                        twoDaysFamousDestinations.length > 0 && 
                        (    
                            <button 
                                onClick={()=>{setNavigation_ActiveDestination("two-days")}}
                                className={navigation_ActiveDestination === "two-days" ? 'button-active-navigation-Destinations' : 'button-unActive-navigation-Destinations'}
                            >
                                Two-Days Famous Destinations
                            </button>
                        )
                    }

                    {
                        offBeatDestinations.length > 0 && 
                        (         
                            <button 
                                onClick={()=>{setNavigation_ActiveDestination("off-beat")}}
                                className={navigation_ActiveDestination === "off-beat" ? 'button-active-navigation-Destinations' : 'button-unActive-navigation-Destinations'}
                            >
                                Off-beat Destinations
                            </button>
                        )
                    }
                
                </div> 

                {/* //! Destinations code-1*/}
            
                <div className='container_p-DestinationNames-Destinations'>
                    {   (navigation_ActiveDestination === "famous" && famousDestinations) &&
                        famousDestinations.map((data, index) => {
                        //  if(data.popular_destination === 1){
                            return(
                                <div key={index} 
                                className={data.district_name === activeDestination ? 'conatiner_s-active-DestinationName-Destinations' : 'conatiner_s-unActive-DestinationName-Destinations'}
                                >
                                    <div>

                                    <button
                                        className={data.district_name === activeDestination ? 'button-active-DestinationName-Destinations' : 'button-unActive-DestinationName-Destinations'}
                                        onClick={() => {
                                            handleChange_Destination_TouristPlaces(data.district_name, "famous")
                                            setActiveTouristPlaceNavigation("all_places");
                                            setActiveTravelCategory("tourist_places")
                                        }}
                                    >
                                        {data.district_name}  

                                    </button> 

                                    {
                                        saved_destinations_data.some(savedData => savedData === data.district_name) ?
                                        (
                                            <button
                                                className='icon-Bookmark-Fill-Destinations'
                                                onClick={() => handle_remove_destination(data.district_name)}
                                            >
                                                <BsFillBookmarkFill />
                                            </button>
                                        )
                                        :
                                        (
                                            <button
                                                className='icon-Bookmark-Empty-Destinations'
                                                onClick={() => handle_add_destination(data.district_name, data.lat, data.lng)}
                                            >
                                                <BsBookmark />
                                            </button>
                                        )
                                    }
                                    </div> 

                                    {data.district_name === activeDestination && 
                                        <div 
                                        className='line-DestinationName-Destinations'
                                        >
                                        </div> 
                                    }   
 
                                </div>    
                            )
                        //  }   
                        })
                    }
                </div>


                <div className='container_p-DestinationNames-Destinations'>
                    {   (navigation_ActiveDestination === "two-days" && twoDaysFamousDestinations) &&
                        twoDaysFamousDestinations.map((data, index) => {
                        //  if(data.popular_destination === 1){
                            return(
                                <div key={index} 
                                className={data.district_name === activeDestination ? 'conatiner_s-active-DestinationName-Destinations' : 'conatiner_s-unActive-DestinationName-Destinations'}
                                >
                                    <div>
                                    <button
                                        className={data.district_name === activeDestination ? 'button-active-DestinationName-Destinations' : 'button-unActive-DestinationName-Destinations'}
                                        onClick={() => {
                                            handleChange_Destination_TouristPlaces(data.district_name, "two-days")
                                            setActiveTouristPlaceNavigation("all_places");
                                            setActiveTravelCategory("tourist_places")
                                    }}
                                    >
                                        {data.district_name} 
                                    </button> 

                                    {
                                        saved_destinations_data.some(savedData => savedData === data.district_name) ?
                                        (
                                            <button
                                                className='icon-Bookmark-Fill-Destinations'
                                                onClick={() => handle_remove_destination(data.district_name)}
                                            >
                                                <BsFillBookmarkFill />
                                            </button>
                                        )
                                        :
                                        (
                                            <button
                                                className='icon-Bookmark-Empty-Destinations'
                                                onClick={() => handle_add_destination(data.district_name, data.lat, data.lng)}
                                            >
                                                <BsBookmark />
                                            </button>
                                        )
                                    }
                                </div>    

                                {data.district_name === activeDestination && 
                                    <div 
                                    className='line-DestinationName-Destinations'
                                    >
                                    </div> 
                                }   
                               
                            </div> 
                            )
                        //  }   
                        })
                    }
                </div>
            
                <div className='container_p-DestinationNames-Destinations'>
                    {   (navigation_ActiveDestination === "off-beat" && offBeatDestinations) &&
                        offBeatDestinations.map((data, index) => {
                        //  if(data.popular_destination === 1){
                            return(
                                <div key={index} 
                                className={data.district_name === activeDestination ? 'conatiner_s-active-DestinationName-Destinations' : 'conatiner_s-unActive-DestinationName-Destinations'}
                                >
                                    <div>
                                    <button
                                        className={data.district_name === activeDestination ? 'button-active-DestinationName-Destinations' : 'button-unActive-DestinationName-Destinations'}
                                        onClick={() => {
                                            handleChange_Destination_TouristPlaces(data.district_name, "off-beat")
                                            setActiveTouristPlaceNavigation("all_places");
                                            setActiveTravelCategory("tourist_places");
                                    }}
                                    >
                                        {data.district_name} 
                                    </button> 

                                    {
                                        saved_destinations_data.some(savedData => savedData === data.district_name) ?
                                        (
                                            <button
                                                className='icon-Bookmark-Fill-Destinations'
                                                onClick={() => handle_remove_destination(data.district_name)}
                                            >
                                                <BsFillBookmarkFill />
                                            </button>
                                        )
                                        :
                                        (
                                            <button
                                                className='icon-Bookmark-Empty-Destinations'
                                                onClick={() => handle_add_destination(data.district_name, data.lat, data.lng)}
                                            >
                                                <BsBookmark />
                                            </button>
                                        )
                                    }
                                </div>

                                {data.district_name === activeDestination && 
                                    <div 
                                    className='line-DestinationName-Destinations'
                                    >
                                    </div> 
                                }   

                                </div> 
                            )
                        //  }   
                        })
                    }
                </div>
                
            </div>

            {/* conatiner 2 - to hold the line */}
                        
            {/* //! code-2  */}
            <div className='conatiner_p-2-line-Destinations'>

                <div className='line-Destinations'>

                </div>

            </div>

            {/* conatiner 3 - to hold the saved destinations  */}

            {/* //! Saved Destinations code-3*/}
            {
                saved_destinations_data && 

                <div 
                className='conatiner_p-3-SavedDestinations-Destinations'
                >
                    <div
                    className='heading-1-savedDestination-Destinations'
                    >
                        Saved Destinations 
                    </div>    

                    <div 
                    className='conatiner_t-SavedDestinations-Destinations'
                    >
                        {
                            saved_destinations_data.map((data, index) => {
                                return(
                                    <button onClick={()=>{handleChange_Destination_TouristPlaces(data, navigation_ActiveDestination)}}
                                    className={data === activeDestination ? 'conatiner_t-active-SavedDestination-Destinations' : 'conatiner_t-unActive-SavedDestination-Destinations'}
                                    >
                                        <div className='text-1-savedDestination-Destinations'
                                        >
                                            {data}
                                        </div>
                                        <div>
                                            <button
                                                className='icon-1-savedDestination-Destinations'
                                                onClick={() => handle_remove_destination(data)}
                                            >
                                                <BsFillBookmarkFill />
                                            </button>
                                        </div>
                                    </button>
                                )
                            })
                        }
                    </div>

                </div>     
            }

        </div> 

    </div>        
  )
}

export default Destinations