import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import NotesBackend, { modules, formats } from "./NotesBackend";
import "react-quill/dist/quill.snow.css";
import "./Notes_Tool.scss";

import axios from "axios";
import TestingLexical from "./TestingLexical/TestingLexical";
import TipTap_ReadOnly from "./TestingLexical/TipTap_ReadOnly";

import { Helmet } from 'react-helmet'


export const Notes_Tool = (props) => {
  const { userInputText, handleUserInputText, topic_id, day_id, placeholderPassed, textLength, handleTextLength, handleDayInfo_FitinMaxLimit_NoteInput, wordLimit } = props;

  // const[textLength, setTextLength] = useState(0)

  // Function to handle changes in the editor content
  const handleChange = (value, delta, source) => {
    // Check if the length of the content exceeds the limit or if the user is deleting content
    const strippedLength = stripHtmlTags(value);
    if (strippedLength <= wordLimit || (delta && delta.ops[0].delete)) {
      // Update the editor content if within the limit or if the user is deleting content
      handleUserInputText(value, topic_id, day_id);
      // setSaveChangesStatus(true)
      // setTextLength(strippedLength);
    }
    // else{
    //   handleDayInfo_FitinMaxLimit_NoteInput()
    // }
  };

  const stripHtmlTags = (html) => {
    if (html) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      const textContent = tempDiv.textContent || tempDiv.innerText || "";
      return textContent.length;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    // const result = stripHtmlTags(userInputText);
    // // setTextLength(result);
    // handleTextLength(result)

    // console.log("userInputText: ", userInputText)
  }, [userInputText]);

  return (
    <div>
<Helmet>   

<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Assistant:wght@400&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap" rel="stylesheet" />

</Helmet>

      <div className="text-editor">
        <TestingLexical
          value={userInputText}
          onChange={handleChange}
          placeholder={"Write something awesome..."}
          wordLimit={wordLimit}
        />
      </div>
    </div>
  );
};

export default Notes_Tool;
 