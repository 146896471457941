import React, {useState} from 'react'
import NearBy_AI from '../../../../AI Components/Near by/NearBy_AI';

function Header_Reach(props) {

  const {firstMode, unique_id, navigationId, ai_feature_id, prompt} = props;

  const [askPropt_ai, setAskPropt_ai] = useState()

  const handleAnotherPrompt = (passedMainAiComponentId) => {
    setAskPropt_ai(passedMainAiComponentId)
  }


  return (
    <div>

      <div>
        <button onClick={()=>{handleAnotherPrompt(ai_feature_id)}}>
          Another Prompt 
        </button>
      </div>

      {
        ai_feature_id === "nb-01" &&
        (
          <div>
            <b>
              Find Nearby
            </b>
          </div>  
        )
      }

      <br></br>

      {
        navigationId === "nb-02" &&
        (
          <div>
            Airports
          </div>  
        )
      }

      {
        navigationId === "nb-03" &&
        (
          <div>
            Railway Stations
          </div>  
        )
      }

      {
        navigationId === "nb-04" &&
        (
          <div>
            Bus Stands 
          </div>  
        )
      }

      <br></br>

      {
        prompt && 
        <div>
          <b>
            {prompt}
          </b>
        </div>
      }

      {askPropt_ai && 
        (
          <div>
            <button onClick={()=>{setAskPropt_ai()}}>
              close
            </button>
          </div>  
        )
      }
      {
        askPropt_ai && askPropt_ai === "nb-01" && 
        (
          <div> 
            <NearBy_AI />
          </div>  
        )
      }

    </div>
  )
}

export default Header_Reach