import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
//for generating unique id
import { v4 as uuidv4 } from 'uuid';

//importing arrays
import {get_prompt_1, get_prompt_2} from './Prompts_HowToReach'

// import other tools 
import axios from 'axios';
import { server_baseUrl } from '../../Universal Components/Server Side Hit Url/ServerHitUrlPart';

function HowToReach_AI() {

  //@@ 
  const navigate = useNavigate();

  //states
  const [randomPromt1, setRandomPromt1] = useState()
  const [randomPromt2, setRandomPromt2] = useState()

  const [promt1_options, setPromt1_options] = useState()
  const [promt2_options, setPromt2_options] = useState()

  const [promt1_select, setPromt1_select] = useState()
  const [promt2_select, setPromt2_select] = useState() 

  const [destinationType, setDestinationType] = useState()
  const [inputResult, setInputResult] = useState()


   //@@ useEffect 
  useEffect(() => {
    handleGetPromt1();
    // handleGetValuesPromt1();
  }, [])


  // handle load prompt
  const handleGetPromt1 = () => {
    const randomEntity_temp = get_prompt_1[Math.floor(Math.random() * get_prompt_1.length)];
    setRandomPromt1(randomEntity_temp);
  }

  const handleGetPromt2 = () => {
    const randomEntity_temp = get_prompt_2[Math.floor(Math.random() * get_prompt_2.length)];
    setRandomPromt2(randomEntity_temp);
  }
  
  // handle load functions click
  const handleGetValuesPromt1 = async (value) => {
    const user_input = value;
    const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/how_to_reach_ai/entity_destination_options`, {user_input});

    // console.log("handleGetValuesPromt1", response.data);

    if(response.data.length > 0){
      setPromt1_options(response.data)
      setDestinationType(response.data[0].entity_destination_options)
    }
  }

  const handleGetValuesPromt2 = async () => {
    const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/how_to_reach_ai/get_entity_type`);

    // console.log("handleGetValuesPromt2", response.data);

    if(response.data.length > 0){
      setPromt2_options(response.data)
    }
  }

  // handle button click
  const handleOption1_click = (value, type) => {
    setPromt1_select(value);
    handleGetPromt2()
    setDestinationType(type)
    handleGetValuesPromt2()
  }
  const handleOption2_click = (value) => {
    setPromt2_select(value);
  }

  //search location
  const [inputDestinationValue, setInputDestinationValue] = useState() 

  const handleDestinationInput = async (event) => {
    const inputValue = event.target.value;
    if(inputValue){
      setInputDestinationValue(inputValue);
      const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/manage_ai_components/get_destination_name/all`, {inputValue})
      // console.log("handleDestinationInput", response.data);
      if(response.data.length > 0){
        setInputResult(response.data)
      }
    }
  }

  //submit user input/query
  const handleSubmit = async () => {

    const unique_id = await generateUniqueId();

    await insertNearbySearch(unique_id)

    navigate(`ai-tool/how-to-reach/explore-travel-modes/${unique_id}`)
  }

  const insertNearbySearch = async (unique_id) => {
    let place_type, place_id;

    place_type = inputResult[0].type;
    // console.log("destinationType", destinationType)
    const response =  await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/how_to_reach_ai/insert_unique_id`, {unique_id, promt1_select, promt2_select, destinationType})

    return;
  }

  const generateUniqueId = () => {
    const data = {explore_entity: promt1_select, nearby_type: promt2_select};
    const timeStamp = Date.now();
    const random_number = Math.floor(Math.random() * 1000000); 
    const concatenatedString = `${data.explore_entity}-${data.nearby_type}-${timeStamp}-${random_number}`;
    const id = uuidv4(concatenatedString);  
    // console.log("id", id);
    return id;
  }


  return (
    <div>

      <div>
        
        <div>

          <div>
            {randomPromt1}
          </div>

           {/* prompt 1 */}
           {/* {promt1_select && 
          ( */}
            <div>
              
              {/* prompt 3 options */}
            {/* { promt1_options && */}
              <div>
                <div>
                  {/* { //promt1_options &&
                    promt1_options.map((data, index) => {
                      if(data.entity_destination_options === "All Destinations"){
                      return(
                        <div>
                          <input placeholder='enter destination' value={inputDestinationValue} onChange={handleDestinationInput}/>
                        </div>
                      )}
                    })
                  } */}

                     <div>
                        <input placeholder='enter destination' value={inputDestinationValue} onChange={handleDestinationInput}/>
                      </div>


                  { (inputResult) &&

                    inputResult.map((data, index) => {
                      return(
                        <div>
                          <div>
                            <button onClick={()=>{handleOption1_click(data.name, data.type)}}>
                              {data.name}, <i>a {data.type}</i>
                            </button>
                            &nbsp; <br></br>
                          </div>
                        </div>  
                      )
                    })
                  }
                </div>
              </div>      
            {/* } */}

            </div>
          {/* )
          } */}

          {/* prompt 2 */}
          <div>
              {randomPromt2}
          </div>

          <div>
                <i>{promt2_select} near <b>{promt1_select}</b></i>
          </div>

          {/* prompt 2 options */}
          <div>
            <div>
              { promt2_options &&
                promt2_options.map((data, index) => {
                  return(
                    <div>
                      <button onClick={()=>{handleOption2_click(data.entity_name)}}>{data.entity_name}</button>
                       &nbsp; <br></br>
                    </div>
                  )
                })
              }
            </div>
          </div>

          {/* submit */}
          <div>
            <div>
              { (promt2_select) &&
                <button onClick={handleSubmit}>Discover {promt2_select} 🤟</button>
              }
            </div>
          </div>


        </div>
      </div>

    </div>
  )
}

export default HowToReach_AI