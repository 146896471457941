import React, {useRef} from 'react'

//import scss
import './TouristPlaces_Top10.scss'

//
import StarRatingComponent from 'react-star-rating-component';

//import icon
import {AiOutlineLeft} from 'react-icons/ai'
import {AiOutlineRight} from 'react-icons/ai'

function TouristPlaces_Top10(props) {

    const{famousPlaces_T10_Data, tripDestinationType, tripDestinationName, stateName} = props;

    const modified_state_name = stateName && stateName.toLowerCase().replace(/\s+/g, '_');

    //!scroll
    const containerRef = useRef()

    const scrollLeft = () => {
        if(containerRef.current){
            containerRef.current.scrollBy({
                left: -750,
                behavious: 'smooth',
            })
        }
    }

    const scrollRight = () => {
        if(containerRef.current){
            containerRef.current.scrollBy({
                left: 750,
                behavious: 'smooth',
            })
        }
    }

    return (
        // Tourist Places - Start
        <div className='container_p-places-Overview_D_Top10'>
          

          <div className='container_t-1-imgHead-places-TP_Top10'>
            <div>
              <img
                className='container_t-image-places-TP_Top10'
                src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/about_destination_5.png'
                // src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/trip_overview-1.jpeg'
                alt='Tourist Place'
              />
            </div>
            <div className='heading-bestTime-TP_Top10'>Famous Tourist Places</div>
          </div>
    
          <div className='container_t-2-main-places-TP_Top10'>
            <button className='scroll-button-left-TP_Top10' onClick={scrollLeft}>
              <AiOutlineLeft />
            </button>
    
            <div className='container_t-Content-places-TP_Top10' ref={containerRef}>
              {famousPlaces_T10_Data.data1 &&
                famousPlaces_T10_Data.data1.map((data, index) => (
                  <div className='card-place-Overview_D_Top10' key={index}>
                    <div style={{ width: '100%' }}>
                      <img
                        className='image-place-card-TP_Top10'
                        // src='https://www.hlimg.com/images/stories/738X538/khajjiar_1427953906m.jpg?w=800&dpr=1.3'

                        src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${data.sn}-01.jpg`}
                        onError={(e) => { e.target.src = 'https://www.hlimg.com/images/stories/738X538/khajjiar_1427953906m.jpg?w=800&dpr=1.3'; }}


                        alt={data.name}
                      />
    
                      <div className='heading-placeName-card-TP_Top10'>{data.name}</div>
    
                      {/* <div>{data.rating} ({data.user_ratings_total})</div> */}

                    <div
                    className='star-card-TP_Top10'
                    >

                        <StarRatingComponent
                        name="rating"
                        starCount={5}
                        value={data.rating}
                        editing={false}
                        starColor="gold" // This sets the color for the filled stars
                        emptyStarColor="#ccc" // This sets the color for the empty stars
                        />  

                        <div>
                            &nbsp; {data.rating} 
                            &nbsp; ({parseInt(data.review_count).toLocaleString('en-IN')})

                        </div> 

                    </div>
    

                      {tripDestinationType === 'state' && (
                        <>
                          <div className='text-card-TP_Top10'>in #{data.destination}</div>
                          <div className='text-card-TP_Top10'>
                            <i>explore duration:</i> {data.explore_duration}
                          </div>
                        </>
                      )}
    
                      <div className='container_t-hashtags-card-TP_Top10'>
                        <div
                        className='heading-1-hashtags-card-TP_Top10'
                        >
                          #{data.category}
                        </div>
                        
                        {/* {
                        data.famous_location_destination && 
                        <div>#Famous Place</div>
                        }

                        {data.famous_location_state && 
                        <div>#Must-Visit</div>
                        } */}
                      </div>
    
                      {
                      famousPlaces_T10_Data.dataAbout2.map((dataAbout) => {
                        if (dataAbout.sn === data.sn && dataAbout.location_about) {
                          return (
                            <div className='content-overview-card-TP_Top10' key={dataAbout.sn}>
                              {dataAbout.location_about
                                ? dataAbout.location_about.split(' ').slice(0, 50).join(' ') +
                                  (dataAbout.location_about.split(' ').length > 50 ? '...' : '')
                                : ''}
                            </div>
                          );
                        }
                        return null;
                      })
                      }
                    </div>
                  </div>
                ))}
            </div>
    
            <button className='scroll-button-right-TP_Top10' onClick={scrollRight}>
              <AiOutlineRight />
            </button>
          </div>
        </div>
      );
    }

export default TouristPlaces_Top10