import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
//for generating unique id
import { v4 as uuidv4 } from 'uuid';

//importing arrays
import {get_prompt_1, get_prompt_2, get_prompt_3} from './Prompts_Nearby'

// import other tools 
import axios from 'axios';

//import search components
import {
  Search_Ds_and_Ls,
  Search_AllDestinations, Search_Cities, Search_Districts, Search_States, 
  Search_Locations
} from '../../Universal Components/Search/Manage Search/Export/Export_Search'
import { server_baseUrl } from '../../Universal Components/Server Side Hit Url/ServerHitUrlPart';

// import context
// import { useMyContext } from '../Main - AI Components/Context_AiComponents';


function NearBy_AI() {

  //fetch relevant states and functions
  // const { setData_1, setData_2, setData_3, data_1, data_2, data_3, handleSetData1 } = useMyContext(); 
   
  //@@ 
  const navigate = useNavigate();

  //states
  const [randomPromt1, setRandomPromt1] = useState()
  const [randomPromt2, setRandomPromt2] = useState()
  const [randomPromt3, setRandomPromt3] = useState()

  const [promt1_options, setPromt1_options] = useState()
  const [promt2_options, setPromt2_options] = useState()
  const [promt3_options, setPromt3_options] = useState()

  const [promt1_select, setPromt1_select] = useState()
  const [promt2_select, setPromt2_select] = useState() 
  const [promt3_select, setPromt3_select] = useState()

  const [destinationType, setDestinationType] = useState()
  const [navigationId, setNavigationId] = useState()

  const [activeSearchLocationType, setActiveSearchLocationType] = useState("destination")

  const [placeId, setPlaceId] = useState()
  const [stateName, setStateName] = useState()
  const [placeType, setPlaceType] = useState()


  //@@ useEffect 
  useEffect(() => {
    handleGetPromt1();
    handleGetValuesPromt1();
  }, [])


  // handle load prompt
  const handleGetPromt1 = () => {
    const randomEntity_temp = get_prompt_1[Math.floor(Math.random() * get_prompt_1.length)];
    setRandomPromt1(randomEntity_temp);
  }
  const handleGetPromt2 = () => {
    const randomEntity_temp = `let's be specific`;
    setRandomPromt2(randomEntity_temp);
  }
  const handleGetPromt3 = () => {
    const randomEntity_temp = get_prompt_3[Math.floor(Math.random() * get_prompt_3.length)];
    setRandomPromt3(randomEntity_temp);
  }

  // handle load functions click
  const handleGetValuesPromt1 = async () => {
    const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/nearby_ai/get_entity_type`)
    // console.log("handleGetValuesPromt1", response.data)
    if(response.data.length > 0){
      setPromt1_options(response.data)
    }
  }

  const handleGetValuesPromt2 = async (value) => {
    const promt1_select = value;
    const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/nearby_ai/entity_name`, {promt1_select})
    // console.log("handleGetValuesPromt2", response.data)
    if(response.data.length > 1){
      setPromt2_options(response.data)
    }
    else if(response.data.length == 1){
      handleOption2_click(response.data[0].entity_name, response.data[0].navigation_id)
    }
  }
  
  const handleGetValuesPromt3 = async (value) => {
    const user_input = value;
    const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/nearby_ai/entity_destination_options`, {user_input})
    // console.log("handleGetValuesPromt3", response.data)
    if(response.data.length > 0){
      setPromt3_options(response.data)
      setDestinationType(response.data[0].entity_destination_options)
    }
  }


  // handle button click
  const handleOption1_click = (value, navigation) => {
    setPromt1_select(value);
    handleGetPromt2();
    handleGetValuesPromt2(value);
    // console.log("handleOption1_click - navigation", navigation)
    setNavigationId(navigation); //this is for entities where there is only one option, and after seleting it skips the 2nd click
  }
  const handleOption2_click = (value, navigation) => {
    setPromt2_select(value);
    handleGetPromt3()
    handleGetValuesPromt3(value)
    // console.log("handleOption2_click - navigation", navigation)
    setNavigationId(navigation); //this is for entities where there is 2nd option (further more options to choise from)
  }
  const handleOption3_click = (value, passedPlaceType) => {
    setPromt3_select(value);
    setPlaceType(passedPlaceType)
    // console.log("passedPlaceType", passedPlaceType);
    // setDestinationType(passedPlaceType)
    // setInputResult([...passedInputResult])
    // // console.log("passedInputResult", passedInputResult)
  }
  
  const handleOption3_click_location = (value, passedPlaceId, passedStateName) => {
    setPromt3_select(value);
    setPlaceId(passedPlaceId)
    setStateName(passedStateName)
    // setInputResult([...passedInputResult])
    // // console.log("passedInputResult", passedInputResult)
  }

  //search location/destination
  const [inputResult, setInputResult] = useState([])


  //submit user input/query
  const handleSubmit = async () => {

    // const unique_id = await generateUniqueId();

    const unique_id = await insertNearbySearch();
    // console.log("navigationId", navigationId)
    const ai_feature_id = "nb-01";
    if(unique_id !== "0" && navigationId){
      navigate(`/ai-tool/discover-nearby/${unique_id}/${navigationId}/${ai_feature_id}`)
      // Check if the current URL contains "discover-nearby"
      if (window.location.href.includes("discover-nearby")) {
        // console.log("found - refresh")
        window.location.reload();
      }
    }
    else{
      alert("opp! something went wrong. You might try again.")
      // console.log("!! error - Everything Famous; in getting the id for navigation. AI Tools.")
    }
  }

  // const handleSubmit = async () => {
  //   const unique_id = await insertNearbySearch();
  //   const ai_feature_id = "nb-01";
  //   if (unique_id !== "0" && navigationId) {
  //     history.replace(`ai-tool/discover-nearby/${unique_id}/${navigationId}/${ai_feature_id}`);
  //   } else {
  //     alert("Oops! Something went wrong. You might try again.");
  //     // console.log("Error - Everything Famous; unable to get the id for navigation in AI Tools.");
  //   }
  // }

  const insertNearbySearch = async () => {
    let place_type, place_id;
    place_id = placeId;
    place_type = placeType;

    // place_type = placeType !== 
    // "city" ? placeType : "destination"

    // console.log("inputResult - insertNearbySearch", inputResult, stateName)


    if(activeSearchLocationType === "location"){
      place_type = "location";
    }

    // console.log('\n', "promt1_select, promt2_select, destinationType, promt3_select, place_type, place_id", '\n', promt1_select, promt2_select, destinationType, promt3_select, place_type, place_id)

    const response =  await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/nearby_ai/insert_data`, {promt1_select, promt2_select, destinationType, promt3_select, place_type, place_id, stateName})
    
    return response.data;
  }


  return (
    <div>
        {/* Data_1: {data_1} <br></br>
        Data_2: {data_2} <br></br>
        Data_3: {data_3} <br></br> */}
      {/* ai prompt */}
      <div>
        <div>

          {/* prompt 1 */}
          <div>
              {randomPromt1}
          </div>

          {/* prompt 1 options */}
          <div>
            <div>
              { promt1_options &&
                promt1_options.map((data, index) => {
                  return(
                    <div>
                      <button onClick={()=>{handleOption1_click(data.entity_type, data.navigation_id)}}>{data.entity_type}</button>
                       &nbsp; <br></br>
                    </div>
                  )
                })
              }
            </div>
          </div>


          {/* prompt 2 */}
          {promt2_options && promt1_select &&
          (
            <div>
              <div>
                  {randomPromt2}
              </div>

              <div>
                <div>
                  { promt2_options &&
                    promt2_options.map((data, index) => {
                      return(
                        <div>
                          <button onClick={()=>{handleOption2_click(data.entity_name, data.navigation_id)}}>{data.entity_name}</button>
                          &nbsp; <br></br>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          )}

          {/* prompt 3 */}
          {promt2_select && 
          (
            <div>
              <div>
                {randomPromt3}
              </div>

              <div>
                <i>{promt2_select} nearby <b>{promt3_select}</b></i>
              </div>
              
            {/* prompt 3 options */}
            { !promt3_select &&
              <div>
                <div>

                  { promt3_options &&
                    promt3_options.map((data, index) => {
                      if(data.entity_destination_options === "Location_and_Destination"){
                      return(
                        <div>

                          <div>
                              <div>
                                <button 
                                  onClick={()=>{
                                    setActiveSearchLocationType("destination") 
                                  }}
                                  className={activeSearchLocationType === "destination" ? 'active' : 'unactive'}
                                >
                                  Destination6
                                </button>
                              </div>  

                              <div>
                                <button 
                                  onClick={()=>{setActiveSearchLocationType("location")}}
                                  className={activeSearchLocationType === "location" ? 'active' : 'unactive'}
                                >
                                  Location
                                </button>
                              </div>  
                          </div>    

                        </div>
                      )}
                    })
                  }
                  

                  { promt3_options &&
                    promt3_options.map((data, index) => {
                      if(data.entity_destination_options === "Location" || 
                      (data.entity_destination_options === "Location_and_Destination" && activeSearchLocationType === "location")){
                      return(
                        <div>
                          {/* <input placeholder='enter location name' value={inputLocationValue} onChange={handleLocationInput}/> */}
                          <Search_Locations
                              handleSearchResultClick={handleOption3_click_location}
                              />
                        </div>
                      )}
                    })
                  }

                  
                  { promt3_options &&
                    promt3_options.map((data, index) => {
                      if(data.entity_destination_options === "All Destinations" || 
                      (data.entity_destination_options === "Location_and_Destination" && activeSearchLocationType === "destination")){
                      return(
                        <div>
                          {/* <input placeholder='enter location name' value={inputLocationValue} onChange={handleLocationInput}/> */}
                          <Search_AllDestinations
                              handleSearchResultClick={handleOption3_click}
                          />
                        </div>
                      )}
                    })
                  }

                  { promt3_options &&
                    promt3_options.map((data, index) => {
                      if(data.entity_destination_options === "City"){
                      return(
                        <div>
                          {/* <input placeholder='enter city/town name' value={inputCityValue} onChange={handleCityInput}/> */}
                          <Search_Cities
                              handleSearchResultClick={handleOption3_click}
                          />
                        </div>
                      )}
                    })
                  }

                  { promt3_options &&
                    promt3_options.map((data, index) => {
                      if(data.entity_destination_options === "District"){
                      return(
                        <div>
                          {/* <input placeholder='enter district name' value={inputDistrictValue} onChange={handleDistrictInput}/> */}
                          <Search_Districts
                              handleSearchResultClick={handleOption3_click}
                          />
                        </div>
                      )}
                    })
                  }

                  { promt3_options &&
                    promt3_options.map((data, index) => {
                      if(data.entity_destination_options === "State"){
                      return(
                        <div>
                          {/* <input placeholder='enter state name' value={inputStateValue} onChange={handleStateInput}/> */}
                          <Search_States
                              handleSearchResultClick={handleOption3_click}
                          />
                        </div>
                      )}
                    })
                  }

                </div>
              </div>      
            }

            </div>
          )
          }

          {/* submit */}
          <div>
            <div>
              { (promt3_select) &&
                <button onClick={handleSubmit}>Discover {promt1_select} 🤟</button>
              }
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}
export default NearBy_AI

// const [inputLocationValue, setInputLocationValue] = useState()
// const [inputDestinationValue, setInputDestinationValue] = useState() 
// const [inputCityValue, setInputCityValue] = useState() 
// const [inputDistrictValue, setInputDistrictValue] = useState() 
// const [inputStateValue, setInputStateValue] = useState()  

// const handleLocationInput = async (event) => {
//   const inputValue = event.target.value;
//   if(inputValue){
//     setInputLocationValue(inputValue);
//     const response = await axios.post('A${server_baseUrl}/csc/api/ai_tools/ai_components/nearby_ai/get_location_name', {inputValue})
//     // console.log("handleLocationInput", response.data);
//     if(response.data.length > 0){
//       setInputResult(response.data)
//     }
//   }
// }

// const handleDestinationInput = async (event) => {
//   const inputValue = event.target.value;
//   if(inputValue){
//     setInputDestinationValue(inputValue);
//     const response = await axios.post('A${server_baseUrl}/csc/api/ai_tools/ai_components/manage_ai_components/get_destination_name/unique', {inputValue})
//     // console.log("handleDestinationInput", response.data);
//     if(response.data.length > 0){
//       setInputResult(response.data)
//     }
//   }
// }

// const handleCityInput = async (event) => {
//   const inputValue = event.target.value;
//   if(inputValue){
//     setInputCityValue(inputValue);
//     const response = await axios.post('A${server_baseUrl}/csc/api/ai_tools/ai_components/nearby_ai/get_city_name', {inputValue})
//     // console.log("handleDestinationInput", response.data);
//     if(response.data.length > 0){
//       setInputResult(response.data)
//     }
//   }
// }

// const handleDistrictInput = async (event) => {
//   const inputValue = event.target.value;
//   if(inputValue){
//     setInputDistrictValue(inputValue);
//     const response = await axios.post('A${server_baseUrl}/csc/api/ai_tools/ai_components/nearby_ai/get_district_name', {inputValue})
//     // console.log("handleDestinationInput", response.data);
//     if(response.data.length > 0){
//       setInputResult(response.data)
//     }
//   }
// }

// const handleStateInput = async (event) => {
//   const inputValue = event.target.value;
//   if(inputValue){
//     setInputStateValue(inputValue);
//     const response = await axios.post('A${server_baseUrl}/csc/api/ai_tools/ai_components/nearby_ai/get_state_name', {inputValue})
//     // console.log("handleDestinationInput", response.data);
//     if(response.data.length > 0){
//       setInputResult(response.data)
//     }
//   }
// }



  // //@@dropdown
  // //handle entity selected
  // const handleExploreEnitySelect = (event) => {
  //   setExploreEntity(event.target.value)
  // }
  // //neary destination type selected
  // const handleDestinationSelect = (event) => {
  //   setNearbyType(event.target.value)
  // }





//   {/* take user input */}
//   <div>
//   <div>
//     <h3>Find me</h3> 
//   </div>

//   <div>
//     <select onChange={handleExploreEnitySelect}>
//     <option><i>select option</i></option>
//     <option value="tourist_locations">tourist locations</option>
//     <option value="tourist_categories">tourist categories</option>
//     <option value="states">states</option>
//     <option value="districts">districts</option>
//     <option value="cities_towns">cities/towns</option>
//     <option value="airport">airport(s)</option>
//     <option value="railway_station">railway station(s)</option>
//     <option value="bus_stands">bus stand(s)</option>
//     <option value="hotels">hotels</option>
//     <option value="restaurants">restaurants</option>
//     <option value="bars_and_pubs">bars and pubs</option>
//     <option value="amusement_parks">amusement parks</option>
//     <option value="activities">activities</option>
//     <option value="bike_rentals">bike rentals</option>
//     <option value="malls">malls</option>
//     <option value="local_shopping_markets">local shopping markets</option>
//     </select>
//   </div>

//   <div>
//     <h3>Nearby</h3> 
//   </div>

//   <div>
//     <select onChange={handleDestinationSelect}>
//       <option><i>select option</i></option>
//       <option value="tourist_location">a tourist location</option>
//       <option value="city_town">a city/town</option>
//       <option value="district">a district</option>
//       <option value="state">a state</option>
//     </select>
//   </div>

// </div>

// {/* submit button */}
// <div>
//   <button onClick={()=>{handleSubmit()}}>
//     Discover {exploreEntity}
//   </button>
// </div>