//@@1

//this is how you can import define it:

// MyContext.js
import React, { createContext, useContext, useState } from 'react';

import { server_baseUrl } from '../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';
//
import axios from 'axios';


// Step 1: Create a new context
const CC_Main = createContext();



// Step 2: Create a context provider
export const HotelsContextProvider = ({ children }) => {

  //!States

  //*Map States
  const[lat, setLat] = useState()
  const[lng, setLng] = useState()
  const[zoom, setZoom] = useState(14) 
  const[fitAllLocations, setFitAllLocations] = useState(false)
  const[hotelLat, setHotelLat] = useState()
  const[hotelLng, setHotelLng] = useState()
  const[activeHotelData_map, setActiveHotelData_map] = useState([])
  const[hoverHotelData_map, setHoverHotelData_map] = useState([])
  const[activeHoverHotel, setActiveHoverHotel] = useState()
  const[hotelClickedStatus, setHotelClickedStatus] = useState(false)
  const[closePopup_Status, setClosePopup_Status] = useState(false)
 
  //
  const[freshStart, setFreshStart] = useState()
  const[searchType, setSearchType] = useState("destination")
  const[showMoreCount, setShowMoreCount] = useState(0)
  const[showMoreStatus, setShowMoreStatus] = useState()

  //loading
  const[loadingStatusHotels, setLoadingStatusHotels] = useState()
  
  //track search keyword changes
  const[keywordChangeDetected, setKeywordChangeDetected] = useState()
  const[trackChange_SelectedAmenitiesData, setTrackChange_SelectedAmenitiesData] = useState([])
  const[trackChange_SortHotelData, setTrackChange_SortHotelData] = useState([])
  const[trackChange_HotelRatings, setTrackChange_HotelRatings] = useState()
  const[trackChange_SortHotelPrice, setTrackChange_SortHotelPrice] = useState("relevant")
  const[noPlaceName_SearchTry, setNoPlaceName_SearchTry] = useState()
  //*view more
  const[viewMore_id, setViewMore_id] = useState()
  const[viewMoreStatus, setViewMoreStatus] = useState(false)
  const[activeHotel_PriceData_Arr, setActiveHotel_PriceData_arr] = useState([])

  //*Navigation/Search Tool
  const[hotelCategory, setHotelCategory] = useState("all")
  const[ptExclusiveData, setPtExclusiveData] = useState()
  const[selectedAmenitiesData, setSelectedAmenitiesData] = useState([])
  const[sortHotelData, setSortHotelData] = useState([])
  const[hotelRatings, setHotelRatings] = useState()
  const[sortHotelPrice, setSortHotelPrice] = useState("relevant")
  const[underKm, setUnderKm] = useState("8")

  const[otherAmenitiesWindow, setOtherAmenitiesWindow] = useState()
  const[hotelsRatingsWindow, setHotelsRatingsWindow] = useState()

  const[placeName, setPlaceName] = useState()
  const[placeStateName, setPlaceStateName] = useState()
  const[destinationType, setDestinationType] = useState()
  const[dataLoadedInputType, setDataLoadedInputType] = useState()

  //*Searched Hotel name and id
  const[searchedHotelName, setSearchedHotelName] = useState()
  const[searchedHotelId, setSearchedHotelId] = useState()

  //*store data
  const[hotelData, setHotelData] = useState([])
  const[priceData, setPriceData] = useState([])
  const[bestDealData, setBestDealData] = useState([])
  // relative_unique_id as id, reviewer_profile_link as link, review_date as when, review_text as text, review_meta_info as meta
  const[reviewData, setReviewData] = useState([])
  const[activeReviewData, setActiveReviewData] = useState([])
  const[reviewDataIndex, setReviewDataIndex] = useState([])
  const[review_showMoreTrack, setReview_showMoreTrack] = useState(0)
  // id, hotel_link, hotel_price, hotel_site_link, price, official_site, free_breakfast, free_wifi, free_parking, free_cancellation, free_cancellation_detail
  const[aboutData, setAboutData] = useState([])
  // relative_unique_id as id, about_text, available_features

  //*Temp. store the data when book direct is clicked, and then when get it back...
  
  const[tempHotelData, setTempHotelData] = useState([])
  const[tempPriceData, setTempPriceData] = useState([])
  const[tempAboutData, setTempAboutData] = useState([])
  const[tempBestDealData, setTempBestDealData] = useState([])
  const[tempReviewData, setTempReviewData] = useState([])


  //Prices
  const[activeViewPrices_Limited, setActiveViewPrices_Limited] = useState()
  const[view_prices_popup, setView_prices_popup] = useState(false)



  const componentInitialLoad = async (
    destination_type, destination_name, //with hotels to search | to load the result, with no need for initial user input
    unique_id, navigationId, ai_feature_id//when when from ai tool | handle ai search
  ) => {

    if(destination_type){
      handleInitialLoad_HotelDetails()
    }
    else if(unique_id){
      handleInitialLoad_AI()
    }
    else{
      handleInitialLoad_Fresh()
    }

  }

  //!handle AI - start

  const handleInitialLoad_HotelDetails = () => {

  }

  //!handle AI - end

//*------------------------

  //!handle have hotel details - start


  const handleInitialLoad_AI = () => {

  }
  
  //!handle have hotel details - end

//*------------------------

  //!handle Other/ Fresh - start

  //**Main Function */

  const handleSearchHotels_Place = async (passedStateName, passedPlaceName, passedDestinationType, passedLat, passedLng, inputNameType, passedhotelCategory_temp, passedptExclusiveData, passedUnderKm, passedShowMoreCount, showMoreClicked) => {

  // console.log("handleSearchHotels_Place was called::", "placeName", inputNameType)

  let passedhotelCategory, sortHotelPrice_new;

    setLoadingStatusHotels("hotel_data")

    //hotel category should not be null
    if(!passedhotelCategory_temp){
      // passeds= "all"
      passedhotelCategory = "all"
    }
    else{
      passedhotelCategory = passedhotelCategory_temp;
    }
    
    if(passedDestinationType === "location" && passedDestinationType !== destinationType){
      sortHotelPrice_new = "nearest_first" 
      setSortHotelPrice("nearest_first")
    }
    else{
      sortHotelPrice_new = sortHotelPrice;
    }

    // alert("i was called....")

  //  if(inputNameType){ 
      const response = await axios.post(
        `${server_baseUrl}/csc/api/travel_categories/hotels/get/hotel_details`, 
        {
          passedStateName, passedPlaceName, passedDestinationType, passedLat, passedLng,  selectedAmenitiesData, sortHotelData, hotelRatings, sortHotelPrice_new, passedShowMoreCount,
          //these gets called as soon as the keyword changes, therefore due to the delay in updation of the state, we may fail to pass the latest value to the server, so they need to be passed. Above waits for the search button to be clicked, so no the state being updated with a delay is not an issue
          passedhotelCategory, passedptExclusiveData, passedUnderKm
      })
      // console.log("response.data - handleSearchHotels_Place", response.data)

      if(response.data && (response.data !== "0" || response.data.length > 0)){

        //?Note: you can also also apply the no duplicates add, if previously it has some old data as you have done in the TravelCategories_2_Context

        //?getting price and about data
        const ids = response.data.map(item => item.id).join(', ');

        //get price data
        handleGetOtherdata1(ids, passedStateName)
        //get about data
        handleGetOtherdata2(ids, passedStateName)

        //?other
        setNoPlaceName_SearchTry(false)

        //update the tracker states with the new searched - data has been received, keywords
        updateKeywordTrackers_NewSearchedKeywords()

        //?store the hotel data received from the server 
        if(showMoreClicked){
          handleHotelsData_AddInExisting(response.data)
        }
        else{
          handleHotelsData_AddInNew(response.data)
          setBestDealData([])
          setShowMoreCount(0)

          //to change only if the stateName has changed. And doing so, by taking the agvantage of the delay state update in react.
          if(placeStateName && passedStateName !== placeStateName){
            setReviewData([])
            setReviewDataIndex([])
          }
        }

        //?data load for which input type
        if(inputNameType && inputNameType !== null){
          setDataLoadedInputType(inputNameType)
        }
        
        //?set the status for show more, if there is more data left to show.
        if(response.data.length <= 20){
          setShowMoreStatus(false)
          // console.log("no more show more - less than 20")
        }
        else{
          // setShowMoreCount(showMoreCount+1)
          setShowMoreStatus(true)
        }

        setFitAllLocations(true)
        setLoadingStatusHotels(null)
        
      }
      else{
        alert("error in loading the hotels data. You might try again.")
        // console.log("opps! Error in loading the hotels. You might try again or some time later.")
        setLoadingStatusHotels(null)
      }
    // }
    // else{
      // alert("Please enter a destination or a location first.")
    // }
    // setFitAllLocations(true)
  
  }


  //!removes duplicate data
  const removeDuplicates = (data) => {
    const seenIds = new Set();
    return data.filter(item => {
        if (!seenIds.has(item.id)) {
            seenIds.add(item.id);
            return true; // Keep this item
        }
        return false; // Skip this item
    });
  };

  //*Price Data
  const handleGetOtherdata1 = async (passedIds, passedStateName) => {

    setLoadingStatusHotels("price_data")

    const response = await axios.post(
      `${server_baseUrl}/csc/api/travel_categories/hotels/get/hotel_details/other_2`, 
      {
        passedIds, passedStateName
    })
    // console.log("response.data - handleGetOtherdata1", response.data) 

    if(response.data && (response.data !== "0" || response.data.length > 0)){
      // setPriceData([...response.data])
      setPriceData(removeDuplicates(response.data))
      handleGetBestDealData(passedIds, response.data)
      setLoadingStatusHotels(null)
      // if(hotelCategory === "a" || hotelCategory === "a"){
      // }
      // else if(hotelCategory === "a"){
      //   handleGetBestDealData_A(passedIds, response.data)
      // } setBestDealData
    }
    else{
      // console.log("error getting the otherData 1")
      setLoadingStatusHotels(null)
    }
  }

  const handleHotelsData_AddInExisting = (passedHotelData) => {
    setHotelData([...hotelData, ...passedHotelData])
  }

  const handleHotelsData_AddInNew = (passedHotelData) => {
    setHotelData([...passedHotelData])
  }

  const handleSearchHotels_Keywords = (passedStateName, passedLat, passedLng) => {
   
    //load hotel details
    if(placeName){ 
      handleSearchHotels_Place(placeStateName, placeName, destinationType, lat, lng, null, hotelCategory, ptExclusiveData, underKm)
    }else{
      setNoPlaceName_SearchTry(true)
    }
  }
  
  const handleGetBestDealData = (passedIds, passedPriceData) => {
    // Split the passedIds string into an array of individual IDs
    const idArray = passedIds.split(',').map(id => parseInt(id.trim()));

    // Process each ID to find the best deal data
    const newBestDealData = idArray.flatMap(id => {
        const filteredData = passedPriceData.filter(data => data.id === id);

        if (filteredData.length > 0) {
            const nonZeroPrices = filteredData.filter(data => data.price !== 0);
            if (nonZeroPrices.length > 0) {
                const minPrice = Math.min(...nonZeroPrices.map(data => data.price));
                const bestDeal = nonZeroPrices.find(data => data.price === minPrice);
                return bestDeal ? [bestDeal] : [];
            }
        }
        return [];
    });

    // Update the state with the new best deal data
    // setBestDealData(prevData => [...prevData, ...newBestDealData]);
    setBestDealData(prevData => [
      ...prevData, 
      ...removeDuplicates(newBestDealData)
    ]);
  };



  //*About Data  
  const handleGetOtherdata2 = async (passedIds, passedStateName) => {
    
    setLoadingStatusHotels("about_data")
    const response = await axios.post(
      `${server_baseUrl}/csc/api/travel_categories/hotels/get/hotel_details/other_3`, 
      {
        passedIds, passedStateName
    })
    // console.log("response.data - handleGetOtherdata2", response.data) 

    if(response.data && (response.data !== "0" || response.data.length > 0)){
      // setAboutData([...response.data])
      setAboutData(removeDuplicates(response.data))
      setLoadingStatusHotels(null)
    }
    else{
      // console.log("error getting the otherData 2")
      setLoadingStatusHotels(null)
    }
  }

  // the new values would be updated only when the state is changed.
  const handleGetReviewdData = async (passedId) => {

    
    if(passedId !== viewMore_id){

      setLoadingStatusHotels("reviews_data")

      if(!reviewDataIndex.includes(passedId)){

        const response = await axios.post(
          `${server_baseUrl}/csc/api/travel_categories/hotels/get/hotel_details/get_other_view_more_data`, 
          {
            passedId, placeStateName
        })
        // console.log("response.data - handleGetReviewdData", response.data) 

        if(response.data && (response.data !== "0" || response.data.length > 0)){
          setReviewData([...reviewData, ...response.data])
          setReviewDataIndex([...reviewDataIndex, passedId])

          //?we are not using the back and forth buttons, but not using the showmore...
          //initial load, only uptil 10 - first 10
          // setActiveReviewData([...response.data.slice(0, 10)]);

          setActiveReviewData([...response.data]);

          setReview_showMoreTrack(2)

          setLoadingStatusHotels(null)

        }
        else{
          setLoadingStatusHotels(null)
          // console.log("error getting the view more data")
        }
      }
      else{
        handleShowMore_Reviews(passedId, "new")
      }
    }
  }

  //?handling show more review. 
  //only two button clicks can call these two functions - handleGetReviewdData, handleShowMore_Reviews
  //if view more is clicked, it goes for the "handleGetReviewdData", and check if the data is loaded, if yes, then it send it to the "handleShowMore_Reviews" function. 
  //handleShowMore_Reviews: the main thing to handle is the "review_showMoreTrack". The basic learning is: setting the initial value to 1 if 1. we are getting the show more request from the handleGetReviewdData function (means the data exsist, and can be loaded from the exsisting one). 2. the earlier complete show more was hit, therefore setting the value to "xx" to hide the show more button. 3. the show more value is 0, which would cause issues when * by 10. The value of the review_showMoreTrack should be setting for the next click, as whenever we get the request, we first process the data according to the already exsisting review_showMoreTrack, store it, and then update it; thats why after every initial load in both the functions, the value of the review_showMoreTrack is set to 2

  const handleShowMore_Reviews = (idPassed, dataLoadedTrue) => {
    let count, newActiveReviewData;
    // console.log("idPassed", idPassed, "review_showMoreTrack", review_showMoreTrack)

    if(parseInt(review_showMoreTrack) === 0 || review_showMoreTrack === "xx" || dataLoadedTrue === "new"){
      count = 1 * 10
      // console.log("1*10")
    }
    else{
      count = parseInt(review_showMoreTrack) * 10
      // console.log("pastValue*10")
    }


    newActiveReviewData = reviewData.filter(data => data.id === idPassed).slice(0, count);
    setActiveReviewData(newActiveReviewData);

    //keep the limit of the review loaded uptil 24
    if(reviewData.length <= count*10 || count >= 24){
      setReview_showMoreTrack("xx")
      // console.log("xx")
    }
    else if(dataLoadedTrue === "new"){
      setReview_showMoreTrack(2)
      // console.log("new")
    }
    else{
      setReview_showMoreTrack(review_showMoreTrack + 1)
      // console.log("value+1")
    }
  }

  
  //!------------------
  // const handleSearchHotelName = (namePassed, latPassed, lngPassed, unique_idPassed, reviewCountPassed) => {

  // }


  const updateKeywordTrackers_NewSearchedKeywords = () => {
    
    setTrackChange_SelectedAmenitiesData(selectedAmenitiesData);
    setTrackChange_SortHotelData(sortHotelData);
    setTrackChange_HotelRatings(hotelRatings);
    setTrackChange_SortHotelPrice(sortHotelPrice);

  }

  const handleRevertKeywordsChange = () => {
    setSelectedAmenitiesData(trackChange_SelectedAmenitiesData)
    setSortHotelData(trackChange_SortHotelData)
    setHotelRatings(trackChange_HotelRatings)
    setSortHotelPrice(trackChange_SortHotelPrice)
    //after changing them back, remove the option to search, and revert changes
    setKeywordChangeDetected(false)
  }

  const handleClearAllFilters = () => {
    setSelectedAmenitiesData([])
    setSortHotelData([])
    setHotelRatings()
    setSortHotelPrice()

    // setTrackChange_SelectedAmenitiesData([]);
    // setTrackChange_SortHotelData([]);
    // setTrackChange_HotelRatings();
    // setTrackChange_SortHotelPrice();

    //after changing them back, remove the option to search, and revert changes
    setKeywordChangeDetected(false)

    // if(placeName && hotelData){
    //   handleSearchHotels_Place(placeStateName, placeName, destinationType, lat, lng, null, null, null, null)
    // }
  }

  const handleFreshStart = () => {
    setPlaceName('')
    setPlaceStateName('')
    setHotelData([])
    setSearchType("destination")
    setShowMoreStatus(false)

    //after changing them back, remove the option to search, and revert changes
    setKeywordChangeDetected(false)

    setDataLoadedInputType()
    setPlaceStateName()
    setDestinationType()
    setSearchedHotelName()
    setSearchedHotelId()

    setActiveHotelData_map()
    setViewMore_id()
    setViewMoreStatus(false)

    setHotelCategory("all")
    setPtExclusiveData()    
    setUnderKm()

    setSelectedAmenitiesData([])
    setSortHotelData([])
    setHotelRatings()
    setSortHotelPrice()

    setTrackChange_SelectedAmenitiesData([]);
    setTrackChange_SortHotelData([]);
    setTrackChange_HotelRatings();
    setTrackChange_SortHotelPrice();
  }

  const handleInitialLoad_Fresh = () => {
    setFreshStart(true)
  }

  const handleSearchType = (type) => {
    setSearchType(type)
  }

  //!!location/destination input
  const handleLocationInputClick = async (passedPlaceName, passedPlaceId, passedStateName, passedLat, passedLng, passSamePlaceCheck) => {

    setOtherAmenitiesWindow(false) 
    setHotelsRatingsWindow(false)

    if(passSamePlaceCheck || placeName !== passedPlaceName){
      // console.log("passedStateName", passedStateName)
      setPlaceName(passedPlaceName)
      setPlaceStateName(passedStateName)
      setLat(passedLat)
      setLng(passedLng)
      setHotelLat(passedLat)
      setHotelLng(passedLng)
      setDestinationType("location")
      // setFitAllLocations(true)
      //
      setShowMoreCount(0)
      //send for search
      handleSearchHotels_Place(passedStateName, passedPlaceName, "location", passedLat, passedLng, "location", hotelCategory, ptExclusiveData, underKm)
    }
    else{
      alert("same place name.")
    }
  }

  const handleDestinationInputClick = async (passedPlaceName, passedPlaceType, passedLat, passedLng, passedStateName, passSamePlaceCheck) => {

    if(passSamePlaceCheck || placeName !== passedPlaceName || destinationType !== passedPlaceType){
      // console.log("passedStateName", passedStateName)
      setPlaceName(passedPlaceName)
      setPlaceStateName(passedStateName)
      setLat(passedLat)
      setLng(passedLng)
      setHotelLat(passedLat)
      setHotelLng(passedLng)
      setDestinationType(passedPlaceType)
      // setFitAllLocations(true)
      //
      setShowMoreCount(0)
      //send for search
      handleSearchHotels_Place(passedStateName, passedPlaceName, passedPlaceType, passedLat, passedLng, "destination", hotelCategory, ptExclusiveData, underKm)
    }
    else{
      alert("same place name.")
    }
  }

  const handleShowMoreClicked = async () => {
    if(showMoreStatus){
      const latestShowMoreCount = showMoreCount+1;

      setShowMoreCount(latestShowMoreCount)

      handleSearchHotels_Place(placeStateName, placeName, destinationType, lat, lng, null, hotelCategory, ptExclusiveData, underKm, latestShowMoreCount, true)
    }
    else{
      alert("you have reached the end of the list.")
    }
  }

  const handleHotelNameInputClick = (passedName, passedId) => {
    setSearchedHotelName(passedName)
    setSearchedHotelId(passedId)
  }

  //!
  const handleHotelCategoryClick = async (value) => {
    if(hotelCategory !== value){
      setHotelCategory(value)

      if(placeName){
        handleSearchHotels_Place(placeStateName, placeName, destinationType, lat, lng, null, value, ptExclusiveData, underKm)
      }else{
        setNoPlaceName_SearchTry(true)
      }

    }
    handleCloseAnyOpenWindow()
    //load hotel details
  }



  //
  const handlePTExclusiveClick = async (value) => {
    if(ptExclusiveData !== value){
      setPtExclusiveData(value)

      //load hotel details
      if(placeName){
        handleSearchHotels_Place(placeStateName, placeName, destinationType, lat, lng, null, hotelCategory, value, underKm)
      }else{
        setNoPlaceName_SearchTry(true)
      }

      // alert("1111111111111111")
      setTempHotelData(hotelData)
      setTempPriceData(priceData)
      setTempAboutData(aboutData)
      setTempBestDealData(bestDealData)
      setTempReviewData(reviewData)
    }
    else{

      setPtExclusiveData()

      setHotelData(tempHotelData)
      setPriceData(tempPriceData)
      setAboutData(tempAboutData)
      setBestDealData(tempBestDealData)
      setReviewData(tempReviewData)

      // setBestDealData([])
      // setPriceData([])
      // // await handleClearAllFilters()

      // if(destinationType !== "location"){
      //   handleDestinationInputClick(placeName, destinationType, lat, lng, placeStateName, true)
      // }
      // else{
      //   handleLocationInputClick(placeName, null, placeStateName, lat, lng, true)
      // }

    }
    handleCloseAnyOpenWindow()

 
  }

  //
  const handleAmenitiesClick = async (value) => {

    if(selectedAmenitiesData.includes(value)){
      setSelectedAmenitiesData(selectedAmenitiesData.filter(item => item != value))
      // checkKeywordClick_ForChange_Remove("amenities", value)
    }
    else{
      setSelectedAmenitiesData([...selectedAmenitiesData, value])
      // checkKeywordClick_ForChange_Add("amenities", value)

    }
    // handleCloseAnyOpenWindow()
  }

  //
  const handleSortHotelsClick = async (value) => {

    if(sortHotelData.includes(value)){
      setSortHotelData(sortHotelData.filter(item => item != value))
      // checkKeywordClick_ForChange_Remove("sort_hotels", value)
    }
    else{
      setSortHotelData([...sortHotelData, value])
      // checkKeywordClick_ForChange_Add("sort_hotels", value)

    }
    handleCloseAnyOpenWindow()
  }

  //2_plus, 3_plus, 4_plus, 4.5_plus
  const handleHotelsRatingsClick = async (value) => {
    if(hotelRatings !== value){
      setHotelRatings(value)
      // checkKeywordClick_ForChange_Add("ratings", value)
    }
    else{
      setHotelRatings()
      // checkKeywordClick_ForChange_Remove("ratings", value)
    }
    // handleCloseAnyOpenWindow()
  }

  //price_h_to_l, price_l_to_h, price_relevant
  const handleSortHotelPriceClick = async (value) => {
    if(sortHotelPrice !== value){
      setSortHotelPrice(value)
      // checkKeywordClick_ForChange_Add("hotel_price", value)
    }
    else{
      setSortHotelPrice()
      // checkKeywordClick_ForChange_Remove("hotel_price", value)
    }
    handleCloseAnyOpenWindow()
  }
  
  //5, 8, 12, 20
  const handleUnderKm = async (value) => {
    if(underKm !== value){
      setUnderKm(value)
    }
    // else{
    //   setUnderKm()
    // }
    handleCloseAnyOpenWindow()
    //load hotel details
    if(placeName){
      handleSearchHotels_Place(placeStateName, placeName, destinationType, lat, lng, null, hotelCategory, ptExclusiveData, value)
    }else{
      setNoPlaceName_SearchTry(true)
    }
    
  }

  const handleCloseAnyOpenWindow = () =>{
    setOtherAmenitiesWindow(false)
    setHotelsRatingsWindow(false)
  }
  
  //!handle Other/ Fresh - end

  //!handle hotel map - start

  const handleClickHotel = (passedId, passedLat, passedLng, passedHotelName, minPricePassed) => {
    //
    if(fitAllLocations){
      setFitAllLocations(false)
    }
    else{
      setFitAllLocations(true)
    }
    // setFitAllLocations(true)
    setHotelClickedStatus(true)
    //
    // console.log("passedLat", passedLat)
    // console.log("passedLng", passedLng)
    // setLat(passedLat)
    // setLng(passedLng)
    // setZoom(14)
    setActiveHotelData_map([{id: passedId, lat: passedLat, lng: passedLng, name: passedHotelName, hotel_price: minPricePassed}])
  }

  const handle_Hover_Hotel = (passedId, passedLat, passedLng, passedHotelName, minPricePassed) => {
    //
    if(fitAllLocations){
      setFitAllLocations(false)
    }
    else{
      setFitAllLocations(true)
    }
    // setFitAllLocations(true)
    setHotelClickedStatus(false)
    //
    setActiveHoverHotel(passedId)
    setHoverHotelData_map([{id: passedId, lat: passedLat, lng: passedLng, name: passedHotelName, hotel_price: minPricePassed}])
    // setLat(passedLat)
    // setLng(passedLng)
    // setZoom(12)
    setHotelClickedStatus(false)
    handleClosePopup()
  }

  const handle_HoverOut_Hotel = () => {
    handleClosePopup()
    setActiveHoverHotel()
    setHoverHotelData_map([])
    setActiveHotelData_map([])
  }

  const handleClosePopup = () => {
    if(closePopup_Status){
      setClosePopup_Status(false)
    }
    else{
      setClosePopup_Status(true)
    }
  }



  //!handle hotel map - end

  //!handle view more - start

  // const handleViewMore = (idPassed) => {
  //   setViewMore_id(idPassed)
  // }

  //!handle view more - end

  const testFunctionHotel = () => {
    // console.log("trackChange_SelectedAmenitiesData", trackChange_SelectedAmenitiesData)
    // console.log("selectedAmenitiesData", selectedAmenitiesData)
    // console.log("hoteldata", hotelData)
  }
  
//*------------------------


  // Step 5: Return a provider with value containing state and functions
  return (
    <CC_Main.Provider value={{ 
      //*states
        //loading
        loadingStatusHotels, setLoadingStatusHotels,
        //
        freshStart, setFreshStart, searchType, setSearchType, keywordChangeDetected, setKeywordChangeDetected, dataLoadedInputType, setDataLoadedInputType, placeName, setPlaceName, placeStateName, setPlaceStateName, destinationType, setDestinationType,
        activeHoverHotel, setActiveHoverHotel,
        //show more
        showMoreCount, setShowMoreCount, 
        showMoreStatus, setShowMoreStatus,
        //map
        lat, lng, setLat, setLng, zoom, setZoom, hotelLat, setHotelLat,
        hotelLng, setHotelLng,
        fitAllLocations, setFitAllLocations,
        hotelClickedStatus, setHotelClickedStatus,
        closePopup_Status, setClosePopup_Status,
        //Navigation/Search Tool
        otherAmenitiesWindow, setOtherAmenitiesWindow,
        hotelsRatingsWindow, setHotelsRatingsWindow,
        hotelCategory, setHotelCategory, ptExclusiveData, setPtExclusiveData, selectedAmenitiesData, setSelectedAmenitiesData, sortHotelData, setSortHotelData, hotelRatings, setHotelRatings, sortHotelPrice, setSortHotelPrice, underKm, setUnderKm,
        //hotel name and id
        searchedHotelName, setSearchedHotelName,
        searchedHotelId, setSearchedHotelId,
        //hotel data
        hotelData, setHotelData,
        priceData, setPriceData,
        aboutData, setPriceData,
        bestDealData, setBestDealData,
        reviewData, setReviewData,
        activeReviewData, setActiveReviewData,
        //map
        activeHotelData_map, setActiveHotelData_map,
        hoverHotelData_map, setHoverHotelData_map,
        //view more
        viewMore_id, setViewMore_id,
        viewMoreStatus, setViewMoreStatus, 
        review_showMoreTrack, setReview_showMoreTrack,
        activeHotel_PriceData_Arr, setActiveHotel_PriceData_arr,

        //Prices
        activeViewPrices_Limited, setActiveViewPrices_Limited,
        view_prices_popup, setView_prices_popup,
        
    //*setStates

    //*functions
        //
        componentInitialLoad, handleInitialLoad_HotelDetails, handleInitialLoad_AI, handleInitialLoad_Fresh, handleSearchType, handleSearchHotels_Keywords,
        //show more
        handleShowMoreClicked, handleGetReviewdData, handleShowMore_Reviews,
        //handle destination
        handleDestinationInputClick, handleLocationInputClick, handleHotelNameInputClick,
        //Navigation/Search Tool
        handleHotelCategoryClick, handlePTExclusiveClick, handleAmenitiesClick, handleSortHotelsClick, handleHotelsRatingsClick, handleSortHotelPriceClick, handleUnderKm, 
        // filters
        handleClearAllFilters, handleFreshStart,
        //track changes - search
        trackChange_SelectedAmenitiesData, trackChange_SortHotelData, trackChange_HotelRatings, trackChange_SortHotelPrice, noPlaceName_SearchTry, handleRevertKeywordsChange,
        //map
        handleClickHotel, handle_Hover_Hotel, handle_HoverOut_Hotel, handleClosePopup,
        //test
        testFunctionHotel
    }}>
      {children}
    </CC_Main.Provider>
  );
};

// Step 6: Create a custom hook to consume the context
export const useHotelsContext = () => {
  return useContext(CC_Main);
};




// const checkKeywordClick_ForChange_Add = (keywordType, keyword) => {

//   let AmenitiesChangeDetected, SortHotelsChangeDetected, RatingsChangeDetected, HotelPriceChangeDetected;

//   if(keywordType === "amenities"){
//     // console.log("amenities change was called")
//     if(trackChange_SelectedAmenitiesData ||trackChange_SelectedAmenitiesData.includes(keyword)){
//       AmenitiesChangeDetected = true;
//     }
//     else{
//       AmenitiesChangeDetected = false;
//     }
//   }
//   else if(keywordType === "sort_hotels"){
//     if(!trackChange_SortHotelData.includes(keyword)){
//       SortHotelsChangeDetected = true;
//     }
//     else{
//       SortHotelsChangeDetected = false;
//     }
//   }
//   else if(keywordType === "ratings"){
//     if(trackChange_HotelRatings !== keyword){
//       RatingsChangeDetected = true;
//     }
//     else{
//       RatingsChangeDetected = false;
//     }
//   }
//   else if(keywordType === "hotel_price"){ //hotelPrice
//     // console.log("hotel price - add")
//     if(trackChange_SortHotelPrice !== keyword){
//       HotelPriceChangeDetected = true;
//       // console.log("change detected - hotel_price")
//     }
//     else{
//       HotelPriceChangeDetected = false;
//       // console.log("change not detected - hotel_price")
//     }
//   }

//   if(AmenitiesChangeDetected || SortHotelsChangeDetected || RatingsChangeDetected || HotelPriceChangeDetected){
//     setKeywordChangeDetected(true)
//   }else{
//     setKeywordChangeDetected(false)
//   }

// }

// const checkKeywordClick_ForChange_Remove = (keywordType, keyword) => {

//   let AmenitiesChangeDetected, SortHotelsChangeDetected, RatingsChangeDetected, HotelPriceChangeDetected;

//   if(keywordType === "amenities"){
//     if(trackChange_SelectedAmenitiesData.includes(keyword)){
//       AmenitiesChangeDetected = true;
//     }
//     else{
//       AmenitiesChangeDetected = false;
//     }
//   }
//   else if(keywordType === "sort_hotels"){
//     if(trackChange_SortHotelData.includes(keyword)){
//       SortHotelsChangeDetected = true;
//     }
//     else{
//       SortHotelsChangeDetected = false;
//     }
//   }
//   else if(keywordType === "ratings"){
//     if(trackChange_HotelRatings === keyword){
//       RatingsChangeDetected = true;
//     }
//     else{
//       RatingsChangeDetected = false;
//     }
//   }
//   else if(keywordType === "hotel_price"){ //hotelPrice
//     // console.log("hotel price - remove")
//     if(trackChange_SortHotelPrice === keyword){
//       HotelPriceChangeDetected = true;
//       // console.log("change detected - hotel_price")
//     }
//     else{
//       HotelPriceChangeDetected = false;
//       // console.log("change not detected - hotel_price")
//     }
//   }

//   if(AmenitiesChangeDetected || SortHotelsChangeDetected || RatingsChangeDetected || HotelPriceChangeDetected){
//     setKeywordChangeDetected(true)
//   }else{
//     setKeywordChangeDetected(false)
//   }

// }