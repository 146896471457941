import React, {useState, useEffect, useRef} from 'react'

//import css
import './TripOverview.scss' 

// //import other components
// import AddTripDetails_TO from '../Components/AddTripDetails_TO/AddTripDetails_TO'

//import react icons
import { FaRegCopy } from "react-icons/fa6";

//
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


import {
  AddTripDetails_TO, AddTravellers_TO, Itinerary_TO, SavedPlaces_TO, AddNotes_TO, Map_TO
} from '../Manage/Export/Export_TripOverview'

//importing Global React Context under PlabTrip
import { useValidatePlanTrip } from '../../../Manage - Plan Trip/Context/ValidatePlanTrip';
import { useGlobalNavigationPlanTrip } from '../../../Manage - Plan Trip/Context/GlobalNavigationPlanTrip';

//importing context form Internal Trip Overview
import {useTripOverviewPlanTrip} from '../Manage/Context/TripOverview_Context'
import {useTripOverview_2_PlanTrip} from '../Manage/Context/TripOverview_2_Context';

//Login
import ManageLogin from '../../../../../Manage User Credentials/User Login/ManageLogin';
import { useParams } from 'react-router-dom';
import { TravelCategories_3_CP } from '../../Trip Planning/Manage/Context/TravelCategories_3_Context';
import TouristPlaces_VM from '../../Trip Planning/Main/View More/TouristPlaces_VM';
import { server_baseUrl } from '../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';


function TripOverview() {

  const{shareTripId, stateName} = useParams();

  // use react context
  const {
    //
    setFitAllLocations,
    //
    activeViewSavedType, handleSavedType, 
    //Map
    lat_TO, lng_TO, zoom_TO, 

    destinationLat, destinationLng,
    //

    //share trip
    share_trip_id, setShare_trip_id, shareTrip_loading_status, setShareTrip_loading_status,

    //share trip
    handle_ShareTrip,

  } = useTripOverview_2_PlanTrip()

  const {
    userEmail, tripId, tripName, tripDestinationName, tripDestinationType, 
    // destinationLat, destinationLng
  } = useValidatePlanTrip()

  const {
    setCreateTripStatus, global_SavedDestinations, setGlobal_SavedDestinations,
    viewMoreData_touristPlaces, setViewMoreData_touristPlaces,
    viewMoreData_About, setViewMoreData_About,
    viewMoreData_Review, setViewMoreData_Review,
    viewMoreData_PlaceInfo, setViewMoreData_PlaceInfo,
    activeId_viewMore_touristPlaces, setActiveId_viewMore_touristPlaces,
    //loading status handle
    viewMoreLoading, setViewMoreLoading,
  } = useGlobalNavigationPlanTrip()


  const divRef = useRef(null);
  const[fixedMapStatus, setFixedMapStatus] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // entry.boundingClientRect.top will be negative when the element is above the viewport
        if (entry.boundingClientRect.top < 0) {
          setFixedMapStatus(true);
        } else if (entry.boundingClientRect.top >= 0) {
          setFixedMapStatus(false);
        }
      },
      {
        root: null, // use the viewport as the container
        threshold: 0, // trigger when any part of the element is out of view
      }
    );

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
    };
  }, []);


  //manage share trip popup
  const[shareTrip_popup, setShareTrip_popup] = useState()

  const handleCopyShareTripLink = () => {

    const domain = window.location.hostname;
    const shareLink = `${domain}shared-trip/plan-trip-to-${tripDestinationName}/${share_trip_id}`;

    navigator.clipboard.writeText(shareLink)
    toast.success("copied...", {
      position: "top-right",
      style: {
        top: '50px', 
        right: '0px',
    }
    })
  }


  //!mobile navigation
  const[navigation_CI_Mobile, setNavigation_CI_Mobile] = useState("itinerary")

  //!
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 799); // Initialize with the current width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 799); // Update state on window resize
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div>

      <div className='conatiner_p-TripOverview'> {/* Main Container - 1 */}

        {//*only visible if user is logged in and have create a trip. blockId-2
        userEmail && tripId &&

        <div className='conatiner_s-TripOverview'> {/* Main Container - 2 */}

      
        <div
        className='container-Image-TripOverview'
        >
          <img
          src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/trip_overview-1.jpeg'
          className='image-tripOverview-TripOverview' 
          />
        </div>

        {
          isMobile && 
          <>
            <br></br><br></br>
          </>
        }

          {/*//! Trip Name */}
          <div className='conatiner_p-Heading-TripOverview'>


            {/*//!Heading */}
            <div className='conatiner_p-TripName-TripOverview'>
              <div
              className='heading-Heading-TripOverview'
              >
                {tripName}
              </div>

              {
                !shareTripId &&
                <div>
                  <button 
                  className='container_t-editButton-TripOverview'
                  onClick={()=>{setCreateTripStatus(true)}}
                  >
                    <img 
                    className='image-edit-1-TripOverview'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/edit_icon_2.png'
                    />
                  </button>
                </div>   
              }

            </div> 

            {/*//!Share */}
            {
              !shareTripId &&
            
              <div className='conatiner_p-ShareTrip-TripOverview'
              style={{position:'relative'}}
              >
                <div
                className='heading-ShareTrip-TripOverview'
                >
                  Share Trip
                </div>

                <div>
                  <button className='container_t-shareButton-TripOverview'
                  onMouseOver={()=>{setShareTrip_popup(true)}}
                  onMouseOut={()=>{setShareTrip_popup(false)}}
                  onClick={()=>{handle_ShareTrip()}}
                  >
                    <img 
                    className='image-share-1-TripOverview'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/share_icon_1.png'
                    />
                  </button>
                </div>   

                {
                  (shareTrip_popup ||  shareTrip_loading_status) && 
                  <div
                    onMouseOver={()=>{setShareTrip_popup(true)}}
                    onMouseOut={()=>{setShareTrip_popup(false)}}
                  >
                    {
                      share_trip_id &&
                      <div
                      className='container_p-copyShareLink-TripOverview'
                      >
                        <button
                        onClick={() => {
                          handleCopyShareTripLink()
                        }}
                        className='button-copyShareLink-TripOverview'
                        >
                          <FaRegCopy />

                        </button>

                        <div className='text-1-copyShareLink-TripOverview'>copy link</div>
                      </div>
                    }
                    <button></button>
                    {
                      shareTrip_loading_status &&
                      <div>
                        loading...
                      </div>
                    }
                  </div>
                }
              </div>    
            }

            {/*//!Destination and District Name(s) */}
            <div className='container_p-DestinationName-TripOverview'>
            
              <div
              className='heading-DestinationName-TripOverview'
              >
                # Trip to {tripDestinationName} 
              </div>

              {
                tripDestinationType === "state" && global_SavedDestinations &&
                <div className='container_t-districtNames-TripOverview'>
                  {
                  global_SavedDestinations.map((data, index) => {
                    return(
                      <div className='heading-DistrictName-TripOverview'> 
                        {data} &nbsp;
                      </div>   
                    )
                  })
                  }
                </div>  
              }

            </div>

          </div>


          {
            isMobile && 
            <>
              <br></br><br></br>
            </>
          }

          {
            <div className='MC-AddTripDetails-TripOverview'>
              <AddTripDetails_TO 
              shareTripId={shareTripId}
              />
            </div>
          }

          {
            isMobile && 
            <>
              <br></br><br></br>
            </>
          }

          {
            <div className='MC-AddTravellers-TripOverview'>
              <AddTravellers_TO 
              shareTripId={shareTripId}
              />
            </div>
          }
      
          {
            isMobile && 
            <>
              <br></br><br></br>
            </>
          }

          {
            <div className='MC-AddNotes-TripOverview'>
              <AddNotes_TO 
              tripDestinationName={tripDestinationName}
              shareTripId={shareTripId}
              />
            </div>
          }

          {/*//! */}
          <div ref={divRef}></div>
          {/*//! */}

          {/*//! Website*/}
          {
            !isMobile && 
          
            <div className='MC-SavedPlaceNavigation-TripOverview'>

              <div>
                <button 
                  onClick={()=>{
                    handleSavedType("savedPlaces")
                    setFitAllLocations(true)
                  }}
                  className={activeViewSavedType === "savedPlaces" ? 'button-active-MapNavigation-CreateItinerary' : 'button-unActive-MapNavigation-CreateItinerary'}
                >
                  Saved Places
                </button>
              </div>

              <div>
                <button
                  onClick={()=>{
                    handleSavedType("itinerary")
                    setFitAllLocations(true)
                  }}
                  className={activeViewSavedType === "itinerary" ? 'button-active-MapNavigation-CreateItinerary' : 'button-unActive-MapNavigation-CreateItinerary'}
                >
                  Saved Itinerary
                </button>
              </div>

            </div>
        
          }

          { !isMobile &&
            <div className='MainConatiner-SavedPlaces_Map-TripOverview'>
              {
                activeViewSavedType === "itinerary" &&
                <div className='MainConatiner-Itinerary-TripOverview'>
                  <Itinerary_TO 
                  shareTripId={shareTripId}
                  />
                </div>
              }

              {
                activeViewSavedType === "savedPlaces" &&
                <div className='MainConatiner-SavedPlaces-TripOverview'>
                  <SavedPlaces_TO 
                  shareTripId={shareTripId}
                  stateName = {stateName}
                  />
                </div>
              }

              {/* {
                fixedMapStatus ? 
                <div>true - fixed </div> : <div>false - not fixed</div>
              } */}
              {  
                lat_TO && lng_TO && zoom_TO &&
                activeViewSavedType &&
                <div
                className={!fixedMapStatus ? 'MainConatiner-Map-TripOverview' : 'MainConatiner-Fixed-Map-TripOverview'}
                >
                  <Map_TO />
                </div>
              }

            </div>
          }

            
          {/*//! Mobile  */}
          {
            isMobile &&
            <div
            className='container_p-navigation_CI-CreateItinerary_Mobile'
            >
              <div
              className='container_s-navigation_CI-CreateItinerary_Mobile'
              >
                <button
                onClick={()=>{
                  setNavigation_CI_Mobile("itinerary")
                  setFitAllLocations(true)
                }}
                className={navigation_CI_Mobile === "itinerary" ? 'button-active-navigation_CI-CreateItinerary_Mobile' : 'button-unActive-navigation_CI-CreateItinerary_Mobile'}
                >
                  Itinerary
                </button>

                <button
                onClick={()=>{
                  setNavigation_CI_Mobile("map")
                  setFitAllLocations(true)
                }}
                className={navigation_CI_Mobile === "map" ? 'button-active-navigation_CI-CreateItinerary_Mobile' : 'button-unActive-navigation_CI-CreateItinerary_Mobile'}
                >
                  Map
                </button>

                <button
                onClick={()=>{setNavigation_CI_Mobile("saved_places")}}
                className={navigation_CI_Mobile === "saved_places" ? 'button-active-navigation_CI-CreateItinerary_Mobile' : 'button-unActive-navigation_CI-CreateItinerary_Mobile'}
                >
                  Saved Places
                </button>
              </div>  
            </div>
          } 

          {
            isMobile &&
            
            <div className='MainConatiner-SavedPlaces_Map-TripOverview'>
              {
                navigation_CI_Mobile === "itinerary" &&
                <div className='MainConatiner-Itinerary-TripOverview'>
                  <Itinerary_TO 
                  shareTripId={shareTripId}
                  />
                </div>
              }

              {
                navigation_CI_Mobile === "saved_places" &&
                <div className='MainConatiner-SavedPlaces-TripOverview'>
                  <SavedPlaces_TO 
                  shareTripId={shareTripId}
                  stateName = {stateName}
                  />
                </div>
              }

              {/* {
                fixedMapStatus ? 
                <div>true - fixed </div> : <div>false - not fixed</div>
              } */}
              { navigation_CI_Mobile === "map" &&
                lat_TO && lng_TO && zoom_TO &&
                activeViewSavedType &&
                <div
                // className={!fixedMapStatus ? 'MainConatiner-Map-TripOverview' : 'MainConatiner-Fixed-Map-TripOverview'}

                // className='MainConatiner-Map-TripOverview'
                className='MainConatiner-Map-TripOverview'
                >
                  <Map_TO />
                </div>
              }

            </div>
          }

          {
            !isMobile ?
            <div style={{height:'100px'}}></div>
            :
            <div style={{height:'20px'}}></div>
          }
      
        </div> 

        // *Main Container - 2
        }  {/* //*blockId-2 this would be displayed only when the user is logged in and hav created a trip  */}

        {/* //!not loggedin */}
        {
          !userEmail &&
          <div
          className='conatiner_p-notLogin-CreateItinerary'
          onClick={()=>{setCreateTripStatus(true)}}
          > 

            <button 
            onClick={()=>{setCreateTripStatus(true)}}
            className='button-heading-1-CreateItinerary'
            style={{padding:'6px', borderRadius:'8px'}}
            >
              <div
                className='heading-login-PlanTripHeader'
                >
                    Login to view Itinerary
                </div>    
                {/* <div
                className='container_t-login-PlanTripHeader'
                > */}
                    <ManageLogin />
                {/* </div> */}
            </button>

            <img 
            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/create_itinerary_4.jpeg'
            className='image-notLogin-CreateItinerary'
            />
        
          </div> 
        }



        {/* //!trip not created*/}

        {
          userEmail && !tripId &&
          <div
          className='conatiner_p-notLogin-TripOverview'
          onClick={()=>{setCreateTripStatus(true)}}
          > 

            <button 
            onClick={()=>{setCreateTripStatus(true)}}
            className='button-heading-1-TripOverview'
            >
              Create Your Trip 
            </button>

            <img 
            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/trip_overview_4.jpeg'
            className='image-notLogin-TripOverview'
            />
        
          </div> 
        }


      </div>

      
      {
        viewMoreLoading && 
        <div className="loading-overlay">
          <div className="spinner">
            {/* You can add a loading spinner here */}
          </div>
        </div>
      }

      {
        activeId_viewMore_touristPlaces && 
        <div >
          <TouristPlaces_VM
            //view more tourist places data
            viewMoreData_touristPlaces={viewMoreData_touristPlaces}
            viewMoreData_About={viewMoreData_About}
            viewMoreData_Review={viewMoreData_Review}
            viewMoreData_PlaceInfo={viewMoreData_PlaceInfo}
            //handle active id
            activeId_viewMore_touristPlaces={activeId_viewMore_touristPlaces} setActiveId_viewMore_touristPlaces={setActiveId_viewMore_touristPlaces }
            stateName = {stateName}

            // save/unsave places
            //!depricated
              // handle_UnSave_Places={handle_UnSave_Places}
              // handle_Save_Places={handle_Save_Places}
              // saved_touristPlaces_data={saved_touristPlaces_data}
          />
        </div>  
      }

      <ToastContainer />

    </div>
  )
}

export default TripOverview