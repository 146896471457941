import { initializeApp } from "firebase/app";

import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, firebase } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAyFskWBQPWqZlSjbzLT7LDt7v73G72J24",
    authDomain: "prepare-trip-01.firebaseapp.com",
    projectId: "prepare-trip-01",
    storageBucket: "prepare-trip-01.appspot.com",
    messagingSenderId: "535331772844",
    appId: "1:535331772844:web:be5ca502d3f130c8b8c209"
};
  

// Initialize Firebase  
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();


//handle sign
export const signInWithGoogle = () => {
    return signInWithPopup(auth, provider)
      .then((result) => {
        const name = result.user.displayName;
        const email = result.user.email;
        const profilePic = result.user.photoURL;

        // Create an object with user data
        const userData = {
          name: name,
          email: email,
          profilePic: profilePic,
          loginStatus: true
        };

        // console.log("userData", userData)

        // Convert user data to a JSON string
        const userDataJSON = JSON.stringify(userData);

        // Set the cookie with the user data
        document.cookie = `userData=${encodeURIComponent(userDataJSON)}; max-age=31536000; secure; samesite=strict;`;
        // httponly;

        return result; 
      })
      .catch((error) => {
        console.error(error);
        throw error; 
      });
};



export const signOutWithGoogle = () => {
    // Remove the user data cookie
    document.cookie = 'userData=; max-age=0; secure; samesite=strict;';
  
    // Sign out the user from Firebase
    signOut(auth)
      .then(() => {
        // console.log('User signed out!');
      })
      .catch((error) => {
        console.error("Sign-out error:", error);
      });
  };
  