import React, {useState
} from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./Notes.css";

import axios from "axios";

import { server_baseUrl } from "../../Production Components/Universal Components/Server Side Hit Url/ServerHitUrlPart";

export const Editor = () => {
  const [state, setState] = React.useState({ value: null });
  const handleChange = value => {
    setState({ value });
  };

  const handleSave = async () => {
    const value = state.value;
    const response = axios.post(`${server_baseUrl}/csc/test/savenotes`, {value})
  }
  const [savedNotes, setSavedNotes] = useState([])

  const handleLoadSavedNotes = async () => {
    const id = 5;
    const response = await axios.post(`${server_baseUrl}/csc/test/load/savednotes`, {id})
    // console.log("data", response.data)
    setSavedNotes(response.data[0].note)

  }

  return (
    <div>
      <div className="text-editor">
        <EditorToolbar />
        <ReactQuill
          theme="snow"
          value={state.value}
          onChange={handleChange}
          placeholder={"Write something awesome..."}
          modules={modules}
          formats={formats}
        />
      </div>

      <div>asasas
        {state.value}
      </div>
      <buttton onClick={handleSave}>Save</buttton>
      <button onClick={handleLoadSavedNotes}>handleLoadSavedNotes</button>

      <br></br><br></br><br></br>
      <div className="savedNotes-Editor">
        <pre dangerouslySetInnerHTML={{ __html: savedNotes }} />
      </div>
    </div>
  );
};

export default Editor;