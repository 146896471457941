import React, { useEffect, useState } from 'react';

import axios from "axios";
import Moment from 'moment'; 

import './ManageLogin.css'

//import css
import './ManageLogin';

//firebase sign features
import { signInWithGoogle, signOutWithGoogle } from "./ManageFirebase";

//user credentitals - cookies
import {getCookies_userCredentials, parseUserCredentialsData} from '../../Cookies/LoginCredentitals'
import { server_baseUrl } from '../../Production Components/Universal Components/Server Side Hit Url/ServerHitUrlPart';


function ManageLogin() {

  const [loginStatus, setLoginStatus] = useState(false);
  const [userEmail, setUserEmail] = useState(false);

  //## check whether the user is logged in or not
  useEffect(() => {

    handleGetUserLoginStatus();
   
  }, [])


  const handleGetUserLoginStatus = () => {

    const userDataCookie = getCookies_userCredentials("userData");
    if (userDataCookie) {
      const userData = parseUserCredentialsData(userDataCookie);
      if (userData) {
          setLoginStatus(userData.email);
          setUserEmail(userData.email);
      }
    } else {
      // console.log("User data cookie not found.");
    }

  } 

  //## user login
  const handleLogin = async () => {
    try {
  
      const result = await signInWithGoogle(); 

      const saveCurrentTime = Moment(new Date()).format('YYYY-MM-DD hh:mm:ss A');
    
      const response = await axios.post(`${server_baseUrl}/update/user/sign_in/data`, {result,
        saveCurrentTime
      });
      // console.log("response.data - handleLogin", response.data)
      if(response.data == "1"){
         window.location.reload();
      }else{
        alert("There was some error signing in. Try Again.")
      }

    } catch (error) {
      console.error(error);
    }
  };

  //## user logout 
  const handleLogOut = async () => {
    try {
  
      const result = await signOutWithGoogle(); 

      const saveCurrentTime = Moment(new Date()).format('YYYY-MM-DD hh:mm:ss A');
    
      const response = await axios.post(`${server_baseUrl}/update/user/sign_out/data`, {userEmail,
        saveCurrentTime
      }); 

      if(response.data === 1){
         window.location.reload();
      }else{
        alert("There was some error signing out. Try Again.")
      }

    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div>
       <div className="LoginApp">
        {!loginStatus && (
          <button className="login-with-google-btn" onClick={()=>{handleLogin()}}>
            Sign in with Google
          </button>
        )}

        {loginStatus && (
          <button className="login-with-google-btn" onClick={()=>{handleLogOut()}}>
            Sign Out
          </button>
        )}
      </div>
    </div>
  )
}

export default ManageLogin 