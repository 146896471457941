import React, {useEffect, useState} from 'react'

//import css
import './DestinationCreativeHeader_D.scss'

function DestinationCreativeHeader_D(props) {

  const{tripDestinationName, tripDestinationType, overviewPlaceData} = props;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 400);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 400); // Update state on window resize
    };
  
    window.addEventListener('resize', handleResize);
  
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    // <div>

      <div
       className='container_p-destinationHeading-DestinationCreativeHeader_D'
      >
        <img 
        className='image-destinationHeading-DestinationCreativeHeader_D'
        src='https://images.unsplash.com/photo-1549615567-101937a79124?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' 
        />

        <div className='overlay-imageFilterDiv-DestinationCreativeHeader_D'>
          
        </div>

        <div
        className='container_t-textConatiner-DestinationCreativeHeader_D'
        >
          <div
            className='text-aboutDestination-DestinationCreativeHeader_D'
          >
            {tripDestinationName} 
            <div className='text-2-aboutDestination-DestinationCreativeHeader_D'>
              (a {tripDestinationType} in India) 
            </div>
          </div>

        </div>

        <div
        className='container_t-2-textConatiner-DestinationCreativeHeader_D'
        >
          {
            overviewPlaceData.map((data, index) => {
            
                return(   
                <div key={index}
                className='text-3-aboutDestination-DestinationCreativeHeader_D'
                >   
                  {
                    !isMobile ?
                    <>
                      {data.content_overview
                      ? data.content_overview.split(' ').slice(0, 64).join(' ') +
                      (data.content_overview.split(' ').length > 64 ? '...' : '')
                      : ''}
                    </>
                    :
                    <>
                      {data.content_overview
                      ? data.content_overview.split(' ').slice(0, 40).join(' ') +
                      (data.content_overview.split(' ').length > 40 ? '...' : '')
                      : ''}
                    </>
                  }
                    {/* {data.content_overview.replace(/- /g, '')} */}
                   

                </div>   
                )
            })
          }
        </div>
          

      </div>

    // {/* </div> */}
  )
}

export default DestinationCreativeHeader_D