
// MyContext.js
import React, { createContext, useContext, useState } from 'react';

import axios from 'axios';
import { server_baseUrl } from '../../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';

// Step 1: Create a new context
const Context = createContext();


// Step 2: Create a context provider
export const Context_AiCreateItinerary_2 = ({ children }) => {
    // console.log('MyContextProvider invoked');

//!States

//*View More
const[activeId_viewMore_touristPlaces, setActiveId_viewMore_touristPlaces] = useState()

const[viewMoreData_About, setViewMoreData_About] = useState([])
const[viewMoreData_Review, setViewMoreData_Review] = useState([])
const[viewMoreData_PlaceInfo, setViewMoreData_PlaceInfo] = useState([])


const[viewMoreData_touristPlaces, setViewMoreData_touristPlaces] = useState([])
const[viewMoreLoading, setViewMoreLoading] = useState(false)

//!Functions

const handle_ViewMore_TouristPlaces = async (idPassed, stateNamePassed) => {
    // console.log("hey")

    setViewMoreLoading(true)
    
    const exists = viewMoreData_touristPlaces.some(item => item.id === idPassed);

    if(exists){
        setActiveId_viewMore_touristPlaces(idPassed)
        // console.log("exists")
        setViewMoreLoading(false)
    }else{
        //!ai data get - 1
        const data = await getViewMoreData_TouristPlaces(idPassed, stateNamePassed)

        setViewMoreData_touristPlaces([...viewMoreData_touristPlaces, ...data])
        // console.log("not exist", viewMoreData_touristPlaces)

        //!other data get - 2
        const data2 = await getViewMoreData_2_TouristPlaces(idPassed, stateNamePassed)

        setViewMoreData_About([...viewMoreData_About, ...data2.about])
        setViewMoreData_Review([...viewMoreData_Review, ...data2.review])
        setViewMoreData_PlaceInfo([...viewMoreData_PlaceInfo, ...data2.placeInfo])

        //store viewMore id
        setActiveId_viewMore_touristPlaces(idPassed)

        setViewMoreLoading(false)
    }
    // console.log("activeId_viewMore_touristPlaces", activeId_viewMore_touristPlaces)
}
    


const getViewMoreData_TouristPlaces = async (idPassed, stateName) => {
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/trip_planning/view_more_data`, {idPassed, stateName})

    // console.log("response.data - getViewMoreData_TouristPlaces", response.data)

    if(response.data !== "0"){
        const data = response.data;
        return data;
    }
    else{
        alert("error in getting the details about the place. You can try again.")
        return null;
    }
}

const getViewMoreData_2_TouristPlaces = async (idPassed, stateName) => {
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/trip_planning/multiple_data/view_more_data_2`, {idPassed, stateName})

    // console.log("response.data - getViewMoreData_2_TouristPlaces", response.data)

    if(response.data !== "0"){

        const about = response.data.about_data;
        const review = response.data.review_data;
        const placeInfo = response.data.placeInfo_data;

        return {about, review, placeInfo} ;
    }
    else{
        alert("error in getting the details about the place. You can try again. 22222.")
        return null;
    }
}





  // Step 5: Return a provider with value containing state and functions
  return (
    <Context.Provider value={{ 
        //!States
        activeId_viewMore_touristPlaces, setActiveId_viewMore_touristPlaces,
        viewMoreData_About,
        viewMoreData_Review,
        viewMoreData_PlaceInfo,
        viewMoreData_touristPlaces,
        //!Function(s)
        handle_ViewMore_TouristPlaces,
        viewMoreLoading, setViewMoreLoading
    }}>
      {children}
    </Context.Provider>
  );
};



// Step 6: Create a custom hook to consume the context
export const useAiCreateItinerary_2 = () => {
    return useContext(Context);
  };
  