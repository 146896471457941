
//?This handles the viewMore, save, unsave, click, and any other interaction that involves interacting with the places/context loaded from the Travel Categories

// MyContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

//Import react context
import {useValidatePlanTrip} from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip';
import {useGlobalNavigationPlanTrip} from '../../../../Manage - Plan Trip/Context/GlobalNavigationPlanTrip';

import {TravelCategories_Context} from './TravelCategories_Context';
import { TravelCategories_2_Context } from './TravelCategories_2_Context';


import axios from "axios" 

import { server_baseUrl } from '../../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';
import { useParams } from 'react-router-dom';

const Context = createContext();

export const TravelCategories_3_CP = ({ children }) => { 
    
    const {stateName} = useParams();

    //view more - tourist Places
    // const [activeId_viewMore_touristPlaces, setActiveId_viewMore_touristPlaces] = useState()

    // const [viewMoreData_touristPlaces, setViewMoreData_touristPlaces] = useState([])
    // const [viewMoreData_About, setViewMoreData_About] = useState([])
    // const [viewMoreData_Review, setViewMoreData_Review] = useState([])
    // const [viewMoreData_PlaceInfo, setViewMoreData_PlaceInfo] = useState([])

    //for map
    const [placeClick_ChangeMap, setPlaceClick_ChangeMap] = useState(false)
    const [active_hover_place_map, setActive_hover_place_map] = useState([])


    //use context
    const {userEmail, tripId, tripDestinationType, districtName, cityName } = useValidatePlanTrip()
    const {
        //useEffect Trigger
        trigger_loadOtherData_TravelCategories,
        //
        activeTravelCategory, saved_touristPlaces_data, setSaved_touristPlaces_data, 
        saved_TravelCategoriesPlaces_data, setSaved_TravelCategoriesPlaces_data,
        //loading
        loadingStatus, setLoadingStatus,
        //hotels
        hotelData, setHotelData,
        //
        setLat, setLng, setZoom,
        //map
        fitAllLocations, setFitAllLocations, closePopup_Status, setClosePopup_Status,
    } = TravelCategories_Context()

    const{
        handleGetOtherdata1, handleGetOtherdata2,
        setHotelData_SavedOnes
    } = TravelCategories_2_Context()

    const {
        //view more channel
        channel_viewMore_id, setChannel_viewMore_id,
        viewMoreLoading, setViewMoreLoading,
        //
        viewMoreData_touristPlaces, setViewMoreData_touristPlaces,
        viewMoreData_About, setViewMoreData_About,
        viewMoreData_Review, setViewMoreData_Review,
        viewMoreData_PlaceInfo, setViewMoreData_PlaceInfo,
        activeId_viewMore_touristPlaces, setActiveId_viewMore_touristPlaces,
    } = useGlobalNavigationPlanTrip()
    

    //!States

    //* Map Route
    const[distance_start_lat, setDistance_start_lat] = useState()
    const[distance_start_lng, setDistance_start_lng] = useState()
    const[distance_end_lat, setDistance_end_lat] = useState()
    const[distance_end_lng, setDistance_end_lng] = useState()
    const[distance_start_name, setDistance_start_name] = useState()
    const[distance_end_name, setDistance_end_name] = useState()
    const[loading_optimizedRoute_status, setLoading_optimizedRoute_status] = useState(false)
    const[optimizedItineraryRoute, setOptimizedItineraryRoute] = useState([])


    useEffect(() => {

        if(trigger_loadOtherData_TravelCategories){
           load_savedData_TravelCategories() 
        }
    }, [trigger_loadOtherData_TravelCategories])

    const load_savedData_TravelCategories = async () => {
        //?Note: right now, we are doing for the hotels, you have to do for the rest of the travel categories. 

        const ids_hotels = saved_TravelCategoriesPlaces_data
        .filter(item => item.type === "hotels") // First, filter for hotels
        .map(item => item.id) // Then, map to get the ids
        .join(', '); // Finally, join them into a string

        
        console.log("ids_hotels", ids_hotels)
        console.log("saved_TravelCategoriesPlaces_data", saved_TravelCategoriesPlaces_data)

        if(ids_hotels){
            //context on same file
            loadHotelsData_initial_ForSaved(ids_hotels, stateName)
            //context on other file 
            handleGetOtherdata1(ids_hotels, stateName)
            handleGetOtherdata2(ids_hotels, stateName)
        }
    } 

    //!load saved places data - initial - only for - other than the 'tourist places' travel categories
    const loadHotelsData_initial_ForSaved = async (ids_hotels, stateName) => {

        const response = await axios.post(`${server_baseUrl}/csc/api/travel_categories/hotels/get/hotel_details/plane/saved`, {ids_hotels, stateName}) 

        setLoadingStatus("loading_saved_hotels_data")

        console.log("loadHotelsData_initial_ForSaved", response.data)

        if(response.data && (response.data !== "0" || response.data.length > 0)){
            // setHotelData([...response.data])
            setHotelData(removeDuplicates(response.data));
            setHotelData_SavedOnes(removeDuplicates(response.data));

            setLoadingStatus(null)
        }
        else{
            setLoadingStatus(null)
        }
    }
    const removeDuplicates = (data) => {
        const seenIds = new Set();
        return data.filter(item => {
            if (!seenIds.has(item.id)) {
                seenIds.add(item.id);
                return true; // Keep this item
            }
            return false; // Skip this item
        });
    };



    //!
    const handle_UnSave_Places = async (idPassed, travelCategoryPassed) => {
        
        const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/handle_user_activity_data/remove_sn/tourist_places`, {userEmail, tripId, idPassed, travelCategoryPassed}) 

        if(response.data !== "0"){

            if(!travelCategoryPassed){
                setSaved_touristPlaces_data(saved_touristPlaces_data => {
                    return saved_touristPlaces_data.filter(savedPlace => savedPlace.id !== idPassed);
                });   
            }
            else{
                setSaved_TravelCategoriesPlaces_data(saved_TravelCategoriesPlaces_data => {
                    return saved_TravelCategoriesPlaces_data.filter(savedPlace => 
                      savedPlace.id !== idPassed && savedPlace.type === travelCategoryPassed
                    );
                }); 
            }

            // remove from trip duration, and length
        }else{
            alert("Some issue in removing the location. You can try again.")
        }
    }
    
    //add sn
    const handle_Save_Places = async (idPassed, exploreDurationPassed, namePassed, latPassed, lngPassed, placeDistrict, passedActiveTravelCategory) => {
        // alert("calledss")
        let districtName;
        if(tripDestinationType === "state"){
            districtName = placeDistrict
        }

        const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/handle_user_activity_data/add_sn/tourist_places`, {idPassed, userEmail, tripId, tripDestinationType, stateName, districtName, cityName, passedActiveTravelCategory, namePassed, exploreDurationPassed, latPassed, lngPassed}) 

        if(response.data != "0"){ 
            // setSaved_touristPlaces_data([...setSaved_touristPlaces_data, idPassed])
            // setSaved_touristPlaces_data(prevData => [...prevData, idPassed]);
            
            if(passedActiveTravelCategory === "tourist_places"){
                setSaved_touristPlaces_data(prevData => [...prevData, {
                    id: idPassed,
                    trip_explore_duration: exploreDurationPassed,
                    name: namePassed,
                    lat: latPassed,
                    lng: lngPassed
                }]);
            }
            else{   
                setSaved_TravelCategoriesPlaces_data(prevData => [...prevData, {
                    id: idPassed,
                    type: passedActiveTravelCategory,
                    name: namePassed,
                    lat: latPassed,
                    lng: lngPassed
                }]);
            }
                
            
            // console.log("handle_Save_Places - success", saved_touristPlaces_data)
            // add to trip duration, and length
            
        }else{
            alert("Some issue in saving the location. You can try again.")
        }
    }

    useEffect(()=>{
        handle_ViewMore_TouristPlaces(channel_viewMore_id)
    }, [channel_viewMore_id])

    const handle_ViewMore_TouristPlaces = async (idPassed) => {
        // console.log("hey")
        
        const exists = viewMoreData_touristPlaces.some(item => item.id === idPassed);

        if(exists){
            setActiveId_viewMore_touristPlaces(idPassed)
            // console.log("exists")
        }else{
            setViewMoreLoading(true)
            //!ai data get - 1
            const data = await getViewMoreData_TouristPlaces(idPassed)

            setViewMoreData_touristPlaces([...viewMoreData_touristPlaces, ...data])
            // console.log("not exist", viewMoreData_touristPlaces)

            //!other data get - 2
            const data2 = await getViewMoreData_2_TouristPlaces(idPassed)

            setViewMoreData_About([...viewMoreData_About, ...data2.about])
            setViewMoreData_Review([...viewMoreData_Review, ...data2.review])
            setViewMoreData_PlaceInfo([...viewMoreData_PlaceInfo, ...data2.placeInfo])

            //store viewMore id
            setActiveId_viewMore_touristPlaces(idPassed)

            setViewMoreLoading(false)
        }
        // console.log("activeId_viewMore_touristPlaces", activeId_viewMore_touristPlaces)
    }

    const getViewMoreData_TouristPlaces = async (idPassed) => {
        const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/trip_planning/view_more_data`, {idPassed, stateName})

        // console.log("response.data - getViewMoreData_TouristPlaces", response.data)

        if(response.data != "0"){
            const data = response.data;
            return data;
        }
        else{
            alert("error in getting the details about the place. You can try again.")
            setViewMoreLoading(false)
            return null;
        }
    }

    const getViewMoreData_2_TouristPlaces = async (idPassed) => {
        // alert("called", stateName)
        const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/trip_planning/multiple_data/view_more_data_2`, {idPassed, stateName})

        // console.log("response.data - getViewMoreData_2_TouristPlaces", response.data)

        if(response.data !== "0"){

            const about = response.data.about_data;
            const review = response.data.review_data;
            const placeInfo = response.data.placeInfo_data;

            return {about, review, placeInfo} ;
        }
        else{
            alert("error in getting the details about the place. You can try again. 22222.")
            return null;
        }
    }

    const handle_Click_TouristPlaces = async (idPassed, latPassed, lngPassed, namePassed) => {
        //close all opened popups
        if(closePopup_Status){
            setClosePopup_Status(false)
        }
        else{
            setClosePopup_Status(true)
        }
        //fit all markers
        if(fitAllLocations){
            setFitAllLocations(false)
        }
        else{
            setFitAllLocations(true)
        }

        // // console.log("page clicked - handle_Click_TouristPlaces", idPassed, latPassed, lngPassed)
        setPlaceClick_ChangeMap(true);
        // setActive_hover_place_map([{id: idPassed, lat: latPassed, lng: lngPassed, name:namePassed}])
        //map
        // setLat(latPassed)
        // setLng(lngPassed)
        // setZoom(10)
    }

    const handle_MouseOver_TouristPlaces = async (namePassed, latPassed, lngPassed) => {
        // // console.log("mouse Over: ", namePassed)
        setActive_hover_place_map([{name: namePassed, lat: latPassed, lng: lngPassed}])

        //close all opened popups
        if(closePopup_Status){
            setClosePopup_Status(false)
        }
        else{
            setClosePopup_Status(true)
        }
        //fit all markers
        if(fitAllLocations){
            setFitAllLocations(false)
        }
        else{
            setFitAllLocations(true)
        }
    }
    
    const handle_MouseOut_TouristPlaces = () => {
        // // console.log("mouse Out: ")
        setActive_hover_place_map([])
        
        //close all opened popups
        if(closePopup_Status){
            setClosePopup_Status(false)
        }
        else{
            setClosePopup_Status(true)
        }
    }

    const [testValue, setTestValue] = ("132")
    const handleTest = () => {
        setTestValue("0000000000000000000")
    }



    //!Handle Calculate, route between locations

    const handle_calculate_route = (lat, lng, name, action) => {


        if(action === "start"){
            setClosePopup_Status(true)
            setDistance_start_name(name)
            setDistance_start_lat(lat)
            setDistance_start_lng(lng)
        }
        else if(action === "end"){
            setDistance_end_name(name)
            setDistance_end_lat(lat)
            setDistance_end_lng(lng)
            handleGetDistance(lat, lng);
        }
        else if(action === "clear"){
            setClosePopup_Status(true)
            setOptimizedItineraryRoute(null)
            setDistance_start_name()
            setDistance_end_name()
            setDistance_start_lat()
            setDistance_start_lng()
            setDistance_end_lat()
            setDistance_end_lng()
        }
        else{
            alert("error in getting the route.")
        }

    }


    const handleGetDistance = async (end_lat, end_lng) => {
        setLoading_optimizedRoute_status(true)
        const response = await axios.post(
            `${server_baseUrl}/distance/locations/calculate_distance_time`, 
            {
              distance_start_lat, distance_start_lng, end_lat, end_lng
        })

        console.log("handleGetDistance", response.data)

        
        if(response.data != "0"){   
            setOptimizedItineraryRoute(response.data)
            setClosePopup_Status(true)
            setLoading_optimizedRoute_status(false)
            setDistance_start_lat()
            setDistance_start_lng()
            setDistance_end_lat()
            setDistance_end_lng()
        }
        else{
            alert("error in getting the route.")
            setLoading_optimizedRoute_status(false)
        }

    }



    return (
        <Context.Provider value={{ 
            //hande save/unSave places
            handle_UnSave_Places, handle_Save_Places, 
            //view more - tourist Places
            activeId_viewMore_touristPlaces, setActiveId_viewMore_touristPlaces, 
            viewMoreData_touristPlaces, 
            viewMoreData_About,
            viewMoreData_Review,
            viewMoreData_PlaceInfo,
            setViewMoreData_touristPlaces,
            handle_ViewMore_TouristPlaces, 
            //for map
            placeClick_ChangeMap, setPlaceClick_ChangeMap,
            active_hover_place_map, setActive_hover_place_map,
            handle_Click_TouristPlaces, 
            handle_MouseOver_TouristPlaces, handle_MouseOut_TouristPlaces,
            //*route
            distance_start_lat, distance_start_lng, distance_end_lat, distance_end_lng, optimizedItineraryRoute, distance_start_name, distance_end_name, 
            loading_optimizedRoute_status, setLoading_optimizedRoute_status,
            //
            handle_calculate_route,

            
            //test value 
            testValue, setTestValue, handleTest
        }}>
        {children}
      </Context.Provider>
    )
}



export const TravelCategories_3_Context = () => {
    return useContext(Context);
};