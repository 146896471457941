// MyContext.js
import React, { createContext, useContext, useState } from 'react';

import axios from "axios" 

//Import react context
import {useValidatePlanTrip} from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip';
import {useGlobalNavigationPlanTrip} from '../../../../Manage - Plan Trip/Context/GlobalNavigationPlanTrip';

//import help functions
import {loadDistrict_LatLng, loadTouristPlaces_District, loadPlaceData_City} from './Functions_TravelCategories.js/Function_TC0'

import { server_baseUrl } from '../../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';

// Step 1: Create a new context
const Context = createContext();

// Step 2: Create a context provider
export const TravelCategories_CP = ({ children }) => { 
    // // console.log('TravelCategories_CP invoked');

    //use context 
    const {
        tripDestinationType, tripDestinationName, stateName, districtName, cityName, tripId, userEmail,
        destinationLat, destinationLng
    } = useValidatePlanTrip()

    const {global_SavedDestinations, setGlobal_SavedDestinations} = useGlobalNavigationPlanTrip()

    const [initialLoad_status_touristPlaces, setInitialLoad_status_touristPlaces] = useState(false);
    //
    const[trigger_loadOtherData_TravelCategories, setTrigger_loadOtherData_TravelCategories] = useState(false)

    const [touristPlaces_underDistance, setTouristPlaces_underDistance] = useState("under_6")

    //!loading Status
    const[loadingStatus, setLoadingStatus] = useState()
    
    


    //*tourist places
    const [touristPlacesData, setTouristPlacesData] = useState([])
    //navigate tourist places
    const[navigate_activeTouristPlacesType, setNavigate_activeTouristPlacesType] = useState("must_visit")
    //--display places
    const [mustVisit_touristPlaces_data, setMustVisit_touristPlaces_data] = useState([])
    const [famous_touristPlaces_data, setFamous_touristPlaces_data] = useState([])
    const [interesting_touristPlaces_data, setInteresting_touristPlaces_data] = useState([])
    const[categories_touristPlaces_data, setCategories_touristPlaces_data] = useState([])
    //--save places //!in this context all the saved user data needs to be loaded, thats why, all the saved profile or states would be initialized here
    const [saved_touristPlaces_data, setSaved_touristPlaces_data] = useState([])
    const [saved_TravelCategoriesPlaces_data, setSaved_TravelCategoriesPlaces_data] = useState([])
    const [active_places_map, setActive_places_map] = useState([])
    //--load status - places
    const [placesLoaded_status_touristPlaces, setPlacesLoaded_status_touristPlaces] = useState([])   
    const [districtDataLoaded_status_touristPlaces, setDistrictDataLoaded_status_touristPlaces] = useState([])
    const [cityDataLoaded_status_touristPlaces, setCityDataLoaded_status_touristPlaces] = useState([])
    const [places_categories_dataLoadedStatus, setPlaces_categories_dataLoadedStatus] = useState([])


    //*Hotels //the handling happens in the TravelCategories_2_Content
    const [hotelData, setHotelData] = useState([])
    const [active_hotelsData, setActive_hotelsData] = useState([])
    const [saved_hotels_data, setSaved_hotels_data] = useState([])
    const [districtDataLoaded_status_hotels, setDistrictDataLoaded_status_hotels] = useState([])

    

    //*categories
    const [allCategoriesData_touristPlaces, setAllCategoriesData_touristPlaces] = useState([]) 
    const [activeCategories_touristPlaces, setActiveCategories_touristPlaces] = useState([])
    const [selectedCategory_touristPlaces, setSelectedCategory_touristPlaces] = useState()
    //--load status - categories
    const [categoriesLoaded_status_touristPlaces, setCategoriesLoaded_status_touristPlaces] = useState([])    
    const [cityCategoryDataLoaded_status_touristPlaces, setCityCategoryDataLoaded_status_touristPlaces] = useState([])
    
    //*destinations 
    // ? this are the function used for the destinations -  handleDestinationClick, handle_add_destination, handle_remove_destination, loadDestinations

    const [navigation_ActiveDestination, setNavigation_ActiveDestination] = useState("famous") 
    // famous, two-days, off-beat
    const [destinationsData, setDestinationsData] = useState([]) 
    const [activeDestination, setActiveDestination] = useState() 
    //--destination type available status
    const [famousDestinations, setFamousDestinations] = useState([]) 
    const [twoDaysFamousDestinations, setTwoDaysFamousDestinations] = useState([]) 
    const [offBeatDestinations, setOffBeatDestinations] = useState([]) 
    const [saved_destinations_data, setSaved_destinations_data] = useState([])

    //*Map
    const [lat, setLat] = useState()
    const [lng, setLng] = useState()
    const [zoom, setZoom] = useState()
    const[fitAllLocations, setFitAllLocations] = useState();
    const[closePopup_Status, setClosePopup_Status] = useState();

    //*Handle Navigation 

    //Main Navigation
    const [activeTravelCategory, setActiveTravelCategory] = useState("tourist_places"); 

    const [activeTouristPlaceNavigation, setActiveTouristPlaceNavigation] = useState("all_places")

    const handle_AllPlaces_TP = async () => {

        setActiveTouristPlaceNavigation("all_places")

        if(tripDestinationType === "state"){
            loadTouristPlaces_DestinationChange(activeDestination)
        }
        else if(tripDestinationType === "district"){
            loadTouristPlaces_NavigationChange();
        }
        else{
            handleSet_TP_for_AllPlaces_ForCity(touristPlaces_underDistance, touristPlacesData)

            // console.log("(activeTouristPlaceNavigation)", activeTouristPlaceNavigation)
            // loadCategories_TouristPlaces_ForCity
        }
    }

    const handle_ByCategories_TP = async () => { 

        setActiveTouristPlaceNavigation("categories")

        if(tripDestinationType !== "city"){
            loadTouristPlaces_CategoryChange(selectedCategory_touristPlaces)
        }
        else{
            handleSet_TP_Cat_for_Categories_ForCity(touristPlaces_underDistance, selectedCategory_touristPlaces, touristPlacesData)
        }

        // console.log("handle_ByCategories_TP - selectedCategory_touristPlaces", selectedCategory_touristPlaces, selectedCategory_touristPlaces)
    }

    const handle_Destination_TP = async () => {
        setActiveTouristPlaceNavigation("destinations")
    }

    // const handle_UnderKm_TP = async (passedUnderDistance) => {
    //     setTouristPlaces_underDistance(passedUnderDistance)

    //     if(activeTouristPlaceNavigation === "all_places"){
    //         loadTouristPlaces_City(passedUnderDistance)
    //     }
    //     else{
    //         loadCategories_TouristPlaces_ForCity(passedUnderDistance, touristPlacesData)
    //         loadTouristPlaces_CategoryChange_ForCity(passedUnderDistance)
    //     }
    // }

    const testFun = (passedCategory) => {
        // setActiveTravelCategory(passedCategory)
        // console.log("touristPlacesData", touristPlacesData)
    }

    //! initial loads

    //*State initial Load
    const initialLoad_TouristPlaces_State = async () => {

        if(tripId){
            loadSaved_Destinations(); //the saved state only need the district/destination name
            loadSaved_TouristLocations(); //the concern state only needs the sn/id of the place
        }
        const response = await loadDestinations();
        let initialDestination, tempLat, tempLng;
        if(response !== "0"){

            //?setting the first destination on the Feature/Component load
            initialDestination = response.data[0].district_name;
            setActiveDestination(initialDestination);

            //?setting the first/initial lat and lng for the map
            tempLat = response.data[0].lat;
            tempLng = response.data[0].lng;
            setLat(tempLat)
            setLng(tempLng)
            setZoom(10)

            //?this function would sort the destinations into famous, 2 days, and off-beat... 
            storeDestinations(response.data);

            //?this would store all the destinations in the state
            setDestinationsData(response.data);


            await loadTouristPlaces_DestinationChange(initialDestination);
            
            setInitialLoad_status_touristPlaces(true)

        }else{
            alert("error in loading data. Give it another try!")
        }    
    }

    //*District initial Load
    const initialLoadTouristPlaces_District = async () => {
        // console.log("initialLoadTouristPlaces_District 1111")
        if(tripId){
            loadSaved_TouristLocations(); //the concern district only needs the sn/id of the place (contains - lat, lng, popular_destination, popular_one_day_destination, less_visited_destination, india_division_name, geography_terrain)
            // // console.log("initialLoadTouristPlaces_District 22222")
            // alert("loadSaved_TouristLocations was called!!")
        }
        const response = await loadDistrict_LatLng(districtName);
        let tempLat, tempLng;

        // console.log("response - loadDistrict_LatLng", response)

        //?setting the first/initial lat and lng for the map
        tempLat = response[0].lat;
        tempLng = response[0].lng;

        setLat(tempLat)
        setLng(tempLng)
        setZoom(9)
        // // console.log(1)
        //*loading and storing/sorting tourist places for the district
        const districtTouristPlaces = await loadTouristPlaces_District(districtName, stateName, tripDestinationName, tripDestinationType);

        // console.log("response - loadTouristPlaces_District", districtTouristPlaces)
        // // console.log("2")

        const mustVisitData = districtTouristPlaces.filter(item => item.famous_location_state === 1);

        const famousData = districtTouristPlaces.filter(item => 
            item.famous_location_destination === 1 &&
            item.famous_location_state !== 1
        );

        // // console.log("3")
    
        const interestingData = districtTouristPlaces.filter(item => item.interesting_location_state === 1);

        setMustVisit_touristPlaces_data([...mustVisitData])

        setFamous_touristPlaces_data([...famousData])
    
        setInteresting_touristPlaces_data([...interestingData])

        // // console.log("4")

        //active map
        const newData = [...mustVisitData, ...famousData, ...interestingData];
        setActive_places_map(newData);
        // // console.log("5")
        //all - for categories
        setTouristPlacesData([...touristPlacesData, ...districtTouristPlaces])
        // // console.log("6")
        // console.log("before calling - loadCategories_TouristPlaces_ForDistrict");

        if(mustVisitData && mustVisitData.length > 0){
            setNavigate_activeTouristPlacesType("must_visit")
        }else if(famousData && famousData.length > 0){
            setNavigate_activeTouristPlacesType("famous")
        }else{
            setNavigate_activeTouristPlacesType("interesting")
        }

        //*setting the categories for the district
        await loadCategories_TouristPlaces_ForDistrict(districtName, districtTouristPlaces)
        
        setInitialLoad_status_touristPlaces(true)

        // // console.log("7")
         
    }

    //*City initial Load
    const initialLoadTouristPlaces_City = async () => {
        if(tripId){
            loadSaved_Destinations(); //the saved state only need the district/destination name
            loadSaved_TouristLocations(); //the concern state only needs the sn/id of the place
        }
        // loadTouristPlaces_City("under_6")
        // loadCategories_TouristPlaces_ForCity("under_6")
        handle_UnderKm_TP("under_6")
    }

    //@@load saved destinations/districts 
    const loadSaved_Destinations = async () => {
        const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/load_user_saved_activity/saved_destinatiions`, {userEmail, tripId}) 

        if(response.data !== "0"){
            const tempSavedDestinationsName = response.data.map(item => item.district_name);
            setSaved_destinations_data([...tempSavedDestinationsName])
            setGlobal_SavedDestinations([...tempSavedDestinationsName])
        }else{
            // console.log("error loading the User S. Destinations. You can try again")
            alert("error loading the User S. Destinations. You can try again")
        }
    }

    const loadSaved_TouristLocations = async () => {
        const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/load_user_saved_activity/saved_tourist_locations`, {userEmail, tripId, stateName}) 

        console.log("loadSaved_TouristLocations - userEmail, tripId", userEmail, tripId, response.data)

        if(response.data != "0"){
            // const tempSavedDestinationsName = response.data.map(item => 
            //     item.travel_category_type === "tourist_places"
            // );
            // setSaved_touristPlaces_data([...tempSavedDestinationsName])
            // console.log(response.data)

            const touristPlacesData = response.data.saved_places // Access saved_places array
            .filter(item => item.travel_category_type === "tourist_places")
            .map(item => ({
                id: item.id,
                trip_explore_duration: item.trip_explore_duration,
                name: item.name,
                lat: item.lat,
                lng: item.lng
            }));

            const otherTravelCategories_Places = response.data.saved_places // Access saved_places array
            .filter(item => item.travel_category_type !== "tourist_places")
            .map(item => ({
                id: item.id,
                type: item.travel_category_type,
                name: item.name,
                lat: item.lat,
                lng: item.lng
            }));

            console.log("otherTravelCategories_Places", otherTravelCategories_Places)

            setSaved_touristPlaces_data(touristPlacesData);
            setSaved_TravelCategoriesPlaces_data(otherTravelCategories_Places)

            setTrigger_loadOtherData_TravelCategories(true)

        }else{
            // console.log("error loading the User S. Destinations. You can try again")
            // alert("error loading the User S. Destinations. You can try again")
        }
    }


    //##
    const loadDestinations = async () => {
        const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/load_destination_data_01/get_district_names`, {stateName}) 

        // console.log("response - loadDestinations", response.data)

        return response;
    }

    const storeDestinations = (data) => {
        const famousDestinations = data.filter(items => 
            items.popular_destination === 1
        )
        const popular_one_day_destination = data.filter(items => 
            items.popular_one_day_destination === 1
        )
        const less_visited_destination = data.filter(items => 
            items.less_visited_destination === 1
        )

        // console.log("famousDestinations", famousDestinations, '\n')
        // console.log("popular_one_day_destination", popular_one_day_destination, '\n')
        // console.log("less_visited_destination", less_visited_destination, '\n')

        setFamousDestinations([...famousDestinations])
        setTwoDaysFamousDestinations([...popular_one_day_destination])
        setOffBeatDestinations([...less_visited_destination])
    }

    //@@
    const handleChange_Category_TouristPlaces = async (category) => {
        //state
        setSelectedCategory_touristPlaces(category);
        // function
        loadTouristPlaces_CategoryChange(category);
    } 

    const loadTouristPlaces_CategoryChange = (passedCategory) => {

        // console.log("touristPlacesData - loadTouristPlaces_CategoryChange", touristPlacesData, "passedCategory", passedCategory, "activeDestination", activeDestination)
        
        const tourist_places_data_temp = touristPlacesData
        .filter(
            item => item.destination === activeDestination && 
            item.category === passedCategory
        )

        setCategories_touristPlaces_data([...tourist_places_data_temp])

        setActive_places_map([tourist_places_data_temp])


        // const mustVisitData = touristPlacesData
        // .filter(
        //     item => item.famous_location_state === 1 && 
        //    item.destination === activeDestination && 
        //     item.category === passedCategory
        // )
    
        // const famousData = touristPlacesData
        // .filter(
        //     item => item.famous_location_destination === 1 && 
        //     item.famous_location_state !== 1 &&
        //     item.destination === activeDestination &&
        //     item.category === passedCategory
        // )
    
        // const interestingData = touristPlacesData
        // .filter(
        //     item => item.interesting_location_state === 1 && 
        //     item.destination === activeDestination && 
        //     item.category === passedCategory
        // )
 
        // setMustVisit_touristPlaces_data([...mustVisitData])
    
        // setFamous_touristPlaces_data([...famousData])

        // setInteresting_touristPlaces_data([...interestingData])

        // //active map
        // const newData = [...mustVisitData, ...famousData, ...interestingData];
        // setActive_places_map(newData);

    }

    
    const loadTouristPlaces_CategoryChange_ForCity = (passedUnderDistance) => {

        // // console.log("touristPlacesData - loadTouristPlaces_CategoryChange", touristPlacesData, "passedCategory", passedCategory, "activeDestination", activeDestination)
        // item.category === passedCategory
        const mustVisitData = touristPlacesData
        .filter(
            item => item.famous_location_state === 1 && 
            // item.destination === activeDestination && 
            item.under_km === passedUnderDistance
        )
    
        const famousData = touristPlacesData
        .filter(
            item => item.famous_location_destination === 1 && 
            item.famous_location_state !== 1 &&
            // item.destination === activeDestination &&
            item.under_km === passedUnderDistance
        )
    
        const interestingData = touristPlacesData
        .filter(
            item => item.interesting_location_state === 1 && 
            // item.destination === activeDestination && 
            item.under_km === passedUnderDistance
        )
 
        setMustVisit_touristPlaces_data([...mustVisitData])
    
        setFamous_touristPlaces_data([...famousData])

        setInteresting_touristPlaces_data([...interestingData])

        if(mustVisitData && mustVisitData.length > 0){
            setNavigate_activeTouristPlacesType("must_visit")
        }else if(famousData && famousData.length > 0){
            setNavigate_activeTouristPlacesType("famous")
        }else{
            setNavigate_activeTouristPlacesType("interesting")
        }

        //active map
        const newData = [...mustVisitData, ...famousData, ...interestingData];
        setActive_places_map(newData);
        // console.log("newData - loadTouristPlaces_CategoryChange", newData)

    }

    //add/remove destinations
    const handle_add_destination = async (passedDistrictName, lat, lng) => {

        const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/handle_user_activity_data/add_district/destinations`, {userEmail, tripId, passedDistrictName, stateName}) 

        if(response.data !== "0"){
            setSaved_destinations_data([...saved_destinations_data, passedDistrictName])
            setGlobal_SavedDestinations([...saved_destinations_data, passedDistrictName])
        }else{
            alert("Some issue in saving the destination. You can try again.")
        }
    }

    const handle_remove_destination = async (passedDistrictName) => {
        
        const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/handle_user_activity_data/remove_district/destinations`, {userEmail, tripId, passedDistrictName}) 

        if(response.data !== "0"){
            setSaved_destinations_data((prevDestinations) => {
                return prevDestinations.filter((destination) => destination !== passedDistrictName);
              });
            setGlobal_SavedDestinations((prevDestinations) => {
            return prevDestinations.filter((destination) => destination !== passedDistrictName);
            });
        }else{
            // console.log("Some issue in removing the destination. You can try again.")
            alert("Some issue in removing the destination. You can try again.")
        }

    }

    //@@ handle change - destination - state
    const handleChange_Destination_TouristPlaces = async (districtNamePassed, typePassed) => {
        if(districtNamePassed !== activeDestination){
            if(!typePassed){typePassed = "famous"};

            setNavigation_ActiveDestination(typePassed);
            setActiveDestination(districtNamePassed)

            loadTouristPlaces_DestinationChange(districtNamePassed);
        }
    } 

    

    const loadTouristPlaces_DestinationChange = async (districtNamePassed) => {

        if(!districtDataLoaded_status_touristPlaces.includes(districtNamePassed)){

            // console.log("bot found - loadTouristPlaces_DestinationChange", districtNamePassed)

            const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/load_destination_data_01/get_tourist_places`, {districtNamePassed, stateName, tripDestinationType}) 
            // console.log("response.data - loadTouristPlaces_DestinationChange", response.data)
            if(response.data !== "0"){    

                const tempLat = response.data[0].lat;
                const tempLng = response.data[0].lng;

                setLat(tempLat)
                setLng(tempLng)
                setZoom(7)

                const mustVisitData = response.data.filter(item => item.famous_location_state === 1);

                const famousData = response.data.filter(item => 
                    item.famous_location_destination === 1 &&
                    item.famous_location_state !== 1
                );

                const interestingData = response.data.filter(item => item.interesting_location_state === 1);

                // load categories for the district
                loadCategories_TouristPlaces_ForState(districtNamePassed, response.data)
                
                // console.log("mustVisitData", mustVisitData)
                // display tourist places
                setMustVisit_touristPlaces_data([...mustVisitData])
    
                setFamous_touristPlaces_data([...famousData])
    
                setInteresting_touristPlaces_data([...interestingData])

                //active map
                const newData = [...mustVisitData, ...famousData, ...interestingData];
                setActive_places_map(newData);
                // console.log("newData - loadTouristPlaces_CategoryChange", newData)

                //all - for categories
                setTouristPlacesData([...touristPlacesData, ...response.data])

                if(mustVisitData && mustVisitData.length > 0){
                    setNavigate_activeTouristPlacesType("must_visit")
                }else if(famousData && famousData.length > 0){
                    setNavigate_activeTouristPlacesType("famous")
                }else{
                    setNavigate_activeTouristPlacesType("interesting")
                }

                setDistrictDataLoaded_status_touristPlaces([...districtDataLoaded_status_touristPlaces,districtNamePassed])
    
            }else{
                alert("opps! Error in loading the tourist places. You can try again.")
            }
        }    
            
            else{
                // console.log("touristPlacesData, 000", touristPlacesData)

                
                const mustVisitData = touristPlacesData.filter(item =>
                    item.famous_location_state === 1 &&
                    item.destination === districtNamePassed
                );
                
                const famousData = touristPlacesData.filter(item => 
                    item.famous_location_destination === 1 &&
                    item.famous_location_state !== 1 &&
                    item.destination === districtNamePassed
                );
                
                const interestingData = touristPlacesData.filter(item => 
                    item.interesting_location_state === 1 &&
                    item.destination === districtNamePassed
                );
                
                // load categories for the district
                loadCategories_TouristPlaces_ForState(districtNamePassed)
                
                // display tourist places
                setMustVisit_touristPlaces_data([...mustVisitData])
                
                setFamous_touristPlaces_data([...famousData])
                
                setInteresting_touristPlaces_data([...interestingData])
                
                //active map
                const newData = [...mustVisitData, ...famousData, ...interestingData];
                setActive_places_map(newData);
                // console.log("newData - loadTouristPlaces_CategoryChange", newData)

                if(mustVisitData && mustVisitData.length > 0){
                    setNavigate_activeTouristPlacesType("must_visit")
                }else if(famousData && famousData.length > 0){
                    setNavigate_activeTouristPlacesType("famous")
                }else{
                    setNavigate_activeTouristPlacesType("interesting")
                }
                
                // ?just need to get the destination for 
                const tempLatLng = destinationsData.find(item =>
                    item.district_name === districtNamePassed
                );
                // console.log("tempLatLng", tempLatLng)
                
                if(tempLatLng){
                    const tempLat = tempLatLng.lat;
                    const tempLng = tempLatLng.lng;

                    setLat(tempLat)
                    setLng(tempLng)
                    setZoom(7)
                }
             
            } 
        
    }

    const loadTouristPlaces_NavigationChange = async () => {
        
        const mustVisitData = touristPlacesData.filter(item =>
            item.famous_location_state === 1
        );
        
        const famousData = touristPlacesData.filter(item => 
            item.famous_location_destination === 1 &&
            item.famous_location_state !== 1
        );
        
        const interestingData = touristPlacesData.filter(item => 
            item.interesting_location_state === 1
        );
        
        // display tourist places
        setMustVisit_touristPlaces_data([...mustVisitData])
        
        setFamous_touristPlaces_data([...famousData])
        
        setInteresting_touristPlaces_data([...interestingData])
        
        //active map
        const newData = [...mustVisitData, ...famousData, ...interestingData];
        setActive_places_map(newData);
        // console.log("newData - loadTouristPlaces_CategoryChange", newData)

        if(mustVisitData && mustVisitData.length > 0){
            setNavigate_activeTouristPlacesType("must_visit")
        }else if(famousData && famousData.length > 0){
            setNavigate_activeTouristPlacesType("famous")
        }else{
            setNavigate_activeTouristPlacesType("interesting")
        }

        // ?just need to get the destination for 
        const tempLat = touristPlacesData[0].lat;
        const tempLng = touristPlacesData[0].lng;

        setLat(tempLat)
        setLng(tempLng)
        setZoom(8)
    }

    //*load categories for state
    const loadCategories_TouristPlaces_ForState = (districtNamePassed, dataPassed) => {

        if (!categoriesLoaded_status_touristPlaces.includes(districtNamePassed)) {
            // console.log("new destination - have to categories");
            const categoryDistrictMap = new Map();
        
            dataPassed.forEach(item => {
                if (categoryDistrictMap.has(item.category)) {
                    categoryDistrictMap.get(item.category).add(districtNamePassed);
                } else {
                    categoryDistrictMap.set(item.category, new Set([districtNamePassed]));
                }
            });
        
            const newCategoriesData = [];
            categoryDistrictMap.forEach((districts, category) => {
                const district = Array.from(districts)[0]; // Assuming there's only one district per category
                newCategoriesData.push({ category, district });
            });
        
            // console.log("newCategoriesData", newCategoriesData);
        
            // Store all categories of all destinations, so we don't have to load them again.
            setAllCategoriesData_touristPlaces([...allCategoriesData_touristPlaces, ...newCategoriesData]);
        
            // Complete for one destination
            setCategoriesLoaded_status_touristPlaces([...categoriesLoaded_status_touristPlaces, districtNamePassed]);
            
            // Store for current destination
            const filteredCategories = newCategoriesData.filter(item => item.district === districtNamePassed);
            setActiveCategories_touristPlaces(filteredCategories);
            setSelectedCategory_touristPlaces(newCategoriesData[0].category);
        
            // console.log("filteredCategories", filteredCategories);
        }
        
        else{
            // console.log("completed destination - no need to load categories")
            // console.log("allCategoriesData_touristPlaces", allCategoriesData_touristPlaces)
            const filteredCategories = allCategoriesData_touristPlaces.filter(
                item => item.district === districtNamePassed
            )
            setActiveCategories_touristPlaces(filteredCategories)
            setSelectedCategory_touristPlaces(allCategoriesData_touristPlaces[0].category)
        }
    }


    //*load categories for district
    const loadCategories_TouristPlaces_ForDistrict = (districtNamePassed, dataPassed) => {
        
        // console.log("new destination - have to categories");
        const categoryDistrictMap = new Map();
    
        dataPassed.forEach(item => {
            if (categoryDistrictMap.has(item.category)) {
                categoryDistrictMap.get(item.category).add(districtNamePassed);
            } else {
                categoryDistrictMap.set(item.category, new Set([districtNamePassed]));
            }
        });
    
        const newCategoriesData = [];
        categoryDistrictMap.forEach((districts, category) => {
            const district = Array.from(districts)[0]; // Assuming there's only one district per category
            newCategoriesData.push({ category, district });
        });
    
        // console.log("newCategoriesData", newCategoriesData);
    
        // Store all categories of all destinations, so we don't have to load them again.
        setAllCategoriesData_touristPlaces([...allCategoriesData_touristPlaces, ...newCategoriesData]);
    
        // Complete for one destination
        setCategoriesLoaded_status_touristPlaces([...categoriesLoaded_status_touristPlaces, districtNamePassed]);
        
        // Store for current destination
        const filteredCategories = newCategoriesData.filter(item => item.district === districtNamePassed);
        setActiveCategories_touristPlaces(filteredCategories);
        setSelectedCategory_touristPlaces(newCategoriesData[0].category);
    
        // console.log("filteredCategories", filteredCategories);
        
    }

    //*load categories and tourist places for city //forCity 

    const handle_UnderKm_TP = (passedUnderKm) => {
        setTouristPlaces_underDistance(passedUnderKm)
        load_TouristPlaces_and_CategoriesData_ForCity(passedUnderKm)     
    }

    const load_TouristPlaces_and_CategoriesData_ForCity = async (passedUnderKm) => {
        if(!places_categories_dataLoadedStatus.includes(passedUnderKm)){

            const result = await loadPlaceData_City(stateName, tripDestinationName, tripDestinationName, passedUnderKm)
            
            if(result !== "0"){
                const result2 = await handle_afterLoad_TPData_forCity(result, passedUnderKm);
                
                if(activeTouristPlaceNavigation === "all_places"){
                    handleSet_TP_for_AllPlaces_ForCity(passedUnderKm, result2.updatedData)
                    // console.log("touristPlacesData", result2.updatedData)
                }
                else{
                    handleSet_TP_Cat_for_Categories_ForCity(passedUnderKm,result2.initialCategory, result2.updatedData)
                }
            }
            else{
                alert("error in loading the places for the City.")
            }

            setPlaces_categories_dataLoadedStatus([...places_categories_dataLoadedStatus, passedUnderKm])
        }
        else{
            handle_FoundData_TP_ForCity(selectedCategory_touristPlaces, passedUnderKm)
        }
    }

    
    const handle_FoundData_TP_ForCity = (selectedCategory_touristPlaces, passedUnderKm) => {   

        // console.log("selectedCategory_touristPlaces - handle_FoundData_TP_ForCity", selectedCategory_touristPlaces)
        
        if(activeTouristPlaceNavigation === "all_places"){
            handleSet_TP_for_AllPlaces_ForCity(passedUnderKm, touristPlacesData)
            // console.log("pppplaces", passedUnderKm, touristPlaces_underDistance, touristPlacesData)
        }
        else{ //activeTouristPlaceNavigation === "categories"
            handleSet_TP_Cat_for_Categories_ForCity(passedUnderKm, selectedCategory_touristPlaces, touristPlacesData)
            // console.log("ccccccategories", passedUnderKm, touristPlaces_underDistance, selectedCategory_touristPlaces, touristPlacesData)
        }
    }
        
    const handle_afterLoad_TPData_forCity = async (result, passedUnderDistance) => {

        //--1--set map coordinates
        const tempLat = result[0].lat;
        const tempLng = result[0].lng;
    
        setLat(tempLat)
        setLng(tempLng)
        setZoom(7)

        //--2--store all loaded tourist places data
        const updatedData = result.map(item => ({
            ...item,
            under_km: passedUnderDistance
        }));

        setTouristPlacesData([...touristPlacesData, ...updatedData]);

        //--3--
        const under_kmCityMap = new Map();

        result.forEach(item => {
            if (under_kmCityMap.has(item.category)) {
                under_kmCityMap.get(item.category).add(passedUnderDistance);
            } else {
                under_kmCityMap.set(item.category, new Set([passedUnderDistance]));
            }
        });
        
        const newCategoriesData = [];
        under_kmCityMap.forEach((set, category) => { // Change 'under_km' to 'category' here
            set.forEach(under_km => { // Change 'category' to 'under_km' here
                newCategoriesData.push({ category, under_km }); // Change order of properties here
            });
        });

        const initialCategory = newCategoriesData[0].category;
        setSelectedCategory_touristPlaces(initialCategory);
        
        const initialUnderKm = newCategoriesData[0].under_km;

        // console.log("Initial Category:", initialCategory, "Initial under_km:", initialUnderKm);
        
        // console.log("00000a0a0a0a", "initialCategory", initialCategory, "newCategoriesData", newCategoriesData)
        
        // console.log("newCategoriesData", newCategoriesData);

        // Store all categories of all destinations, so we don't have to load them again.
        setAllCategoriesData_touristPlaces([...allCategoriesData_touristPlaces, ...newCategoriesData]);

        return {updatedData, initialCategory}
    }

    //*
    const handleSet_TP_for_AllPlaces_ForCity = async (passedUnderKm, touristPlacesData) => {
        
        const mustVisitData = touristPlacesData.filter(item => 
            item.famous_location_state === 1 &&
            item.under_km === passedUnderKm
        );

        const famousData = touristPlacesData.filter(item => 
            item.famous_location_destination === 1 &&
            item.famous_location_state !== 1 &&
            item.under_km === passedUnderKm
        );

        const interestingData = touristPlacesData.filter(item => 
            item.interesting_location_state === 1 &&
            item.under_km === passedUnderKm
        );

        setMustVisit_touristPlaces_data([...mustVisitData])
    
        setFamous_touristPlaces_data([...famousData])

        setInteresting_touristPlaces_data([...interestingData])

        if(mustVisitData && mustVisitData.length > 0){
            setNavigate_activeTouristPlacesType("must_visit")
        }else if(famousData && famousData.length > 0){
            setNavigate_activeTouristPlacesType("famous")
        }else{
            setNavigate_activeTouristPlacesType("interesting")
        }

        //active map
        const activeMapData = [...mustVisitData, ...famousData, ...interestingData];
        setActive_places_map(activeMapData);
    }

    //*
    const handleSet_TP_Cat_for_Categories_ForCity = async (passedUnderDistance, passedActiveCategory, passedTouristPlaces) => {
        // console.log("passedUnderDistance", passedUnderDistance, "passedUnderDistance")
        // console.log("allCategoriesData_touristPlaces - handleSet_TP_Cat_for_Categories_ForCity", allCategoriesData_touristPlaces)

        const filteredCategories = allCategoriesData_touristPlaces.filter(
            item => item.under_km === passedUnderDistance
        )
        setActiveCategories_touristPlaces(filteredCategories)
        const initialCat = filteredCategories[0].category;
        setSelectedCategory_touristPlaces(initialCat)

        // console.log('\n', "filteredCategories", filteredCategories, "activeCategories_touristPlaces", activeCategories_touristPlaces, "allCategoriesData_touristPlaces", allCategoriesData_touristPlaces)

        // console.log('\n', "passedUnderDistance", passedUnderDistance, "passedActiveCategory", passedActiveCategory, '\n', '\n', "passedTouristPlaces", passedTouristPlaces, "initialCat", initialCat)
        //load tourist places
        const mustVisitData = passedTouristPlaces.filter(item => 
            item.famous_location_state === 1 &&
            item.under_km === passedUnderDistance &&
            item.category === initialCat
        );

        const famousData = passedTouristPlaces.filter(item => 
            item.famous_location_destination === 1 &&
            item.famous_location_state !== 1 &&
            item.under_km === passedUnderDistance &&
            item.category === initialCat
        );

        const interestingData = passedTouristPlaces.filter(item => 
            item.interesting_location_state === 1 &&
            item.under_km === passedUnderDistance &&
            item.category === initialCat
        );

        setMustVisit_touristPlaces_data([...mustVisitData])
    
        setFamous_touristPlaces_data([...famousData])

        setInteresting_touristPlaces_data([...interestingData])

        if(mustVisitData && mustVisitData.length > 0){
            setNavigate_activeTouristPlacesType("must_visit")
        }else if(famousData && famousData.length > 0){
            setNavigate_activeTouristPlacesType("famous")
        }else{
            setNavigate_activeTouristPlacesType("interesting")
        }

        //active map
        const activeMapData = [...mustVisitData, ...famousData, ...interestingData];
        setActive_places_map(activeMapData);

    }

    //!!!!
    const loadTouristPlaces_City = async (passedUnderDistance) => {
        // console.log("cityDataLoaded_status_touristPlaces", cityDataLoaded_status_touristPlaces, "passedUnderDistance", passedUnderDistance)
        if(!cityDataLoaded_status_touristPlaces.includes(passedUnderDistance)){
            // console.log("111111111111")
            const under_km = passedUnderDistance;
            const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/load_destination_data_01/get_tourist_places`, {stateName, tripDestinationType, tripDestinationName, under_km}) 

            // console.log("response.data - loadTouristPlaces_DestinationChange", response.data)

            if(response.data !== "0"){    

                const tempLat = response.data[0].lat;
                const tempLng = response.data[0].lng;

                setLat(tempLat)
                setLng(tempLng)
                setZoom(7)

                const mustVisitData = response.data.filter(item => item.famous_location_state === 1);

                const famousData = response.data.filter(item => 
                    item.famous_location_destination === 1 &&
                    item.famous_location_state !== 1
                );

                const interestingData = response.data.filter(item => item.interesting_location_state === 1);

                // load categories for the district
                loadCategories_TouristPlaces_ForCity(passedUnderDistance, response.data)
                
                // console.log("mustVisitData", mustVisitData)
                // display tourist places
                setMustVisit_touristPlaces_data([...mustVisitData])
    
                setFamous_touristPlaces_data([...famousData])
    
                setInteresting_touristPlaces_data([...interestingData])

                //active map
                const newData = [...mustVisitData, ...famousData, ...interestingData];
                setActive_places_map(newData);
                // console.log("newData - loadTouristPlaces_CategoryChange", newData)

                if(mustVisitData && mustVisitData.length > 0){
                    setNavigate_activeTouristPlacesType("must_visit")
                }else if(famousData && famousData.length > 0){
                    setNavigate_activeTouristPlacesType("famous")
                }else{
                    setNavigate_activeTouristPlacesType("interesting")
                }

                //all - for categories
                const updatedData = response.data.map(item => ({
                    ...item,
                    under_km: passedUnderDistance
                }));
                
                setTouristPlacesData([...touristPlacesData, ...updatedData]);

                // console.log("the new ", "touristPlacesData", touristPlacesData, "updatedData", updatedData)

                setCityDataLoaded_status_touristPlaces([...cityDataLoaded_status_touristPlaces, passedUnderDistance])


                // console.log("000000000000000000000")
    
            }else{
                alert("opps! Error in loading the tourist places. You can try again.")
            }
        }    
            
            else{
                // console.log("2222222222222")
                // console.log("touristPlacesData, 000", touristPlacesData)

                
                const mustVisitData = touristPlacesData.filter(item =>
                    item.famous_location_state === 1 &&
                    item.under_km === passedUnderDistance
                );
                
                const famousData = touristPlacesData.filter(item => 
                    item.famous_location_destination === 1 &&
                    item.famous_location_state !== 1 &&
                    item.under_km === passedUnderDistance
                );
                
                const interestingData = touristPlacesData.filter(item => 
                    item.interesting_location_state === 1 &&
                    item.under_km === passedUnderDistance
                );
                
                // load categories for the district
                loadCategories_TouristPlaces_ForState(passedUnderDistance, touristPlacesData)
                
                // display tourist places
                setMustVisit_touristPlaces_data([...mustVisitData])
                
                setFamous_touristPlaces_data([...famousData])
                
                setInteresting_touristPlaces_data([...interestingData])
                
                //active map
                const newData = [...mustVisitData, ...famousData, ...interestingData];
                setActive_places_map(newData);
                // console.log("newData - loadTouristPlaces_CategoryChange", touristPlacesData)

                if(mustVisitData && mustVisitData.length > 0){
                    setNavigate_activeTouristPlacesType("must_visit")
                }else if(famousData && famousData.length > 0){
                    setNavigate_activeTouristPlacesType("famous")
                }else{
                    setNavigate_activeTouristPlacesType("interesting")
                }
                
                // ?just need to get the destination for 
                const tempLatLng = touristPlacesData.find(item =>
                    item.under_km === passedUnderDistance
                );
                // console.log("tempLatLng", tempLatLng.lat, tempLatLng.lng)
                
                if(tempLatLng){
                    // console.log("hi")
                    const tempLat = tempLatLng.lat;
                    const tempLng = tempLatLng.lng;

                    setLat(tempLat)
                    setLng(tempLng)
                    setZoom(7)
                }
            } 
    }


    const loadCategories_TouristPlaces_ForCity = (passedUnderDistance, dataPassed) => {
        
        if(!cityDataLoaded_status_touristPlaces.includes(passedUnderDistance)){

            if(!categoriesLoaded_status_touristPlaces.includes(passedUnderDistance)){

                // console.log("new destination - have to categories");
                const under_kmCityMap = new Map();
            
                dataPassed.forEach(item => {
                    if (under_kmCityMap.has(item.under_km)) {
                        under_kmCityMap.get(item.under_km).add(passedUnderDistance);
                    } else {
                        under_kmCityMap.set(item.category, new Set([passedUnderDistance]));
                    }
                });
            
                const newCategoriesData = [];
                under_kmCityMap.forEach((under_km) => {
                    const district = Array.from(under_km)[0]; // Assuming there's only one district per category
                    newCategoriesData.push({ under_km, district});
                });
            
                // console.log("newCategoriesData", newCategoriesData);
            
                // Store all categories of all destinations, so we don't have to load them again.
                setAllCategoriesData_touristPlaces([...allCategoriesData_touristPlaces, ...newCategoriesData]);
            
                // Complete for one destination
                setCategoriesLoaded_status_touristPlaces([...categoriesLoaded_status_touristPlaces, passedUnderDistance]);
                
                // Store for current destination
                const filteredCategories = newCategoriesData.filter(item => item.district === passedUnderDistance);
                setActiveCategories_touristPlaces(filteredCategories);
                setSelectedCategory_touristPlaces(newCategoriesData[0].under_km);
            
                // console.log("filteredCategories", filteredCategories);
            }
            else{
                
                const filteredCategories = allCategoriesData_touristPlaces.filter(
                    item => item.under_km === passedUnderDistance
                )
                setActiveCategories_touristPlaces(filteredCategories)
                setSelectedCategory_touristPlaces(allCategoriesData_touristPlaces[0].category)
            }
        }
        
        else{
            setCityCategoryDataLoaded_status_touristPlaces([...cityCategoryDataLoaded_status_touristPlaces, passedUnderDistance])

            loadTouristPlaces_City(passedUnderDistance)
        }
        
    }


////////////////////////////////////////////////////////////



  // Step 5: Return a provider with value containing state and functions
  return (
    <Context.Provider value={{ 
        //*initial load
        initialLoad_status_touristPlaces, setInitialLoad_status_touristPlaces,
        //useeffect trigger
        trigger_loadOtherData_TravelCategories,
        //loading status
        loadingStatus, setLoadingStatus,

        //*tourist places
            activeTravelCategory, setActiveTravelCategory,
            touristPlaces_underDistance, setTouristPlaces_underDistance,
            touristPlacesData, setTouristPlacesData,
            mustVisit_touristPlaces_data, setMustVisit_touristPlaces_data,
            famous_touristPlaces_data, setFamous_touristPlaces_data,
            interesting_touristPlaces_data, setInteresting_touristPlaces_data,
            categories_touristPlaces_data, setCategories_touristPlaces_data,
            //navigate tourist places types
            navigate_activeTouristPlacesType, setNavigate_activeTouristPlacesType,
            //--save places
            saved_touristPlaces_data, setSaved_touristPlaces_data,
            saved_TravelCategoriesPlaces_data, setSaved_TravelCategoriesPlaces_data,
            active_places_map, setActive_places_map,
            //--load status - places
            placesLoaded_status_touristPlaces, setPlacesLoaded_status_touristPlaces,
            districtDataLoaded_status_touristPlaces, setDistrictDataLoaded_status_touristPlaces, cityDataLoaded_status_touristPlaces, setCityDataLoaded_status_touristPlaces,

        //*Hotels
            hotelData, setHotelData,
            active_hotelsData, setActive_hotelsData,
            saved_hotels_data, setSaved_hotels_data,
            districtDataLoaded_status_hotels, setDistrictDataLoaded_status_hotels,

        //*categories
            allCategoriesData_touristPlaces, setAllCategoriesData_touristPlaces,
            activeCategories_touristPlaces, setActiveCategories_touristPlaces,
            selectedCategory_touristPlaces, setSelectedCategory_touristPlaces,
            //--load status - categories
            categoriesLoaded_status_touristPlaces, setCategoriesLoaded_status_touristPlaces,

        //*destinations
            navigation_ActiveDestination, setNavigation_ActiveDestination,
            // all_places, two-days, off-beat
            destinationsData, setDestinationsData,
            activeDestination, setActiveDestination,
            //--destination type available status
            famousDestinations, setFamousDestinations,
            twoDaysFamousDestinations, setTwoDaysFamousDestinations,
            offBeatDestinations, setOffBeatDestinations,
            saved_destinations_data, setSaved_destinations_data,
            handle_add_destination, handle_remove_destination,
            // also used, but already mentioned: handleChange_Destination_TouristPlaces
        //*Map
            lat, setLat, lng, setLng, zoom, setZoom,
            fitAllLocations, setFitAllLocations,
            closePopup_Status, setClosePopup_Status,
        //*Handle Navigation 
            activeTouristPlaceNavigation, setActiveTouristPlaceNavigation,

        //*FUNCTIONS
            //--navigation 
            handle_AllPlaces_TP, handle_ByCategories_TP, handle_Destination_TP, handle_UnderKm_TP, 
            //--initial load
            initialLoad_TouristPlaces_State, 
            initialLoadTouristPlaces_District,
            initialLoadTouristPlaces_City,
            //--handle change
            handleChange_Category_TouristPlaces, handleChange_Destination_TouristPlaces,loadTouristPlaces_CategoryChange,  
            loadTouristPlaces_DestinationChange, loadTouristPlaces_NavigationChange,loadCategories_TouristPlaces_ForState, 
            testFun

     }}>
      {children}
    </Context.Provider>
  );
};

// Step 6: Create a custom hook to consume the context
export const TravelCategories_Context = () => {
  return useContext(Context);
};


