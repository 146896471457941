import React, {useState} from 'react'

//import scss
import './HowToReach_Initial.scss'

import { useNavigate, useParams, useLocation } from 'react-router-dom';

//import icons
import { CiSearch } from "react-icons/ci";
import { IoAirplane } from "react-icons/io5";
import { FaBusAlt } from "react-icons/fa";
import { GiRailway } from "react-icons/gi";

function HowToReach_Initial() {
  
  const navigate = useNavigate(); 

  const handleNavigate_byAir = () => {
    const firstMode = "air";
    const secondMode = "train";
    const thirdMode = "bus";
    navigate(`/discover-travel-modes/${firstMode}/${secondMode}/${thirdMode}`)
  }

  const handleNavigate_byTrain = () => {
    navigate('/discover-travel-modes/train/air/bus')
  }

  const handleNavigate_byBus = () => {
    navigate('/discover-travel-modes/bus/air/train')
  }

  const[activeHoverTravelMode, setActiveHoverTravelMode] = useState("air")


  return (
    <div
    className='container_p-HowToReach_Initial'
    >

      <div
      className='container_s-HowToReach_Initial'
      >

        <div
        className='text1-HowToReach_Initial'
        >
          How To Reach 
        </div>

        
        <button 
        onClick={()=>{handleNavigate_byAir()}}
        onMouseOver={()=>{setActiveHoverTravelMode("air")}}
        className={activeHoverTravelMode === "air" ? 'container_p-modeButton-HowToReach_Initial' : 'container_p-unactive-modeButton-HowToReach_Initial'}
        >
          {
            activeHoverTravelMode === "air" &&
            <div
            className='text2-HowToReach_Initial'
            >
              Explore Airports
            </div>
          }

          <div
          className='icon1-HowToReach_Initial'
          >
            <IoAirplane />
          </div>

        </button>

      
        <button 
        onClick={()=>{handleNavigate_byTrain()}}
        onMouseOver={()=>{setActiveHoverTravelMode("train")}}
        className={activeHoverTravelMode === "train" ? 'container_p-modeButton-HowToReach_Initial' : 'container_p-unactive-modeButton-HowToReach_Initial'}
        >
          {
            activeHoverTravelMode === "train" &&
            <div
            className='text2-HowToReach_Initial'
            >
              Discover Railway Stations
            </div>
          }

          <div
          className='icon1-HowToReach_Initial'
          >
            <GiRailway />
          </div>

        </button>

      
        <button 
        onClick={()=>{handleNavigate_byBus()}}
        onMouseOver={()=>{setActiveHoverTravelMode("bus")}}
        className={activeHoverTravelMode === "bus" ? 'container_p-modeButton-HowToReach_Initial' : 'container_p-unactive-modeButton-HowToReach_Initial'}
        >
          {
            activeHoverTravelMode === "bus" && 
            <div
            className='text2-HowToReach_Initial'
            >
              Find Bus Stands
            </div>
          }

          <div
          className='icon1-HowToReach_Initial'
          >
            <FaBusAlt />
          </div>

        </button>
        

        <div
        className='icon2-HowToReach_Initial'
        >
          <CiSearch />
        </div>

        {/*// */}
        <img 
        src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/how_to_reach-route-1.png'
        className='img-1-design-HowToReach_Initial'
        />

        <img 
        src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/how_to_reach-route-3.png'
        className='img-2-design-HowToReach_Initial'
        />

        <img 
        src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/how_to_reach-route-2.png'
        className='img-3-design-HowToReach_Initial'
        />


      </div>

    </div>
  )
}

export default HowToReach_Initial