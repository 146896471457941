// MyContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'

import axios from 'axios';
import Moment from 'moment';

import { getCookies_userCredentials, parseUserCredentialsData } from '../../../../../../Cookies/LoginCredentitals';
import { server_baseUrl } from '../../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';

// Step 1: Create a new context
const Context = createContext();


// Step 2: Create a context provider
export const Context_AiCreateItinerary = ({ children }) => {

const {unique_id} = useParams();

const navigate = useNavigate();

const test = () => {
    // console.log("savedPlacesData", savedPlacesData)

    handleInitialLoad()


    // handleInitialLoad()

}
// useEffect(() => {
//     handleGetUserLoginStatus();
// }, [])      

const handleGetUserLoginStatus = () => {

    const userDataCookie = getCookies_userCredentials("userData");
    if (userDataCookie) {
        const userData = parseUserCredentialsData(userDataCookie);
        if (userData) {
            const user_email = userData.email;
            setUserEmail(user_email);
            // console.log("user_email", user_email, "Login status:", userData.loginStatus);
            return user_email
        }
    } else {
        // console.log("User data cookie not found.");
        navigate('/user-not-logged-in/?resolve-please-try-again-after-login')
    }
}

let placeNames_and_Days;

//!states
//*manage
const[useEffectLoaded_status, setUseEffectLoaded_status] = useState(false)
const[userEmail, setUserEmail] = useState()
const[placeIds, setPlaceIds] = useState()
//
const[getRoute_buttonStatus, setGetRoute_buttonStatus] = useState()

//*create trip
const[createTripId, setCreateTripId] = useState()
const[tripName, setTripName] = useState()
const[tripWindowStatus, setTripWindowStatus] = useState(false)

//*prompt
const [prompt, setPrompt] = useState()

//loading
//?in this we set the name of the component that is loading...
const[loadingType, setLoadingType] = useState()


//*Home
const[activeNavigationCategory_ItineraryResult, setActiveNavigationCategory_ItineraryResult] = useState("day_wise")

//*itinerary sequence and route data
const[optimizedItinerary, setOptimizedItinerary] = useState([])
const[optimizedItineraryRoute, setOptimizedItineraryRoute] = useState([])
//?tourist places
const[savedPlacesData, setSavedPlacesData] = useState([]) 

//*Day Wise
const[activeDay, setActiveDay] = useState(1) 
// const[dayCount, setDayCount] = useState()
const[exploreDuration, setExploreDuration] = useState()
const[travelDuration, setTravelDuration] = useState()
const[stateName, setStateName] = useState()
const[districtName, setDistrictName] = useState()
const[destinationName, setDestinationName] = useState()
const[destinationType, setDestinationType] = useState()
const[itineraryDuration, setItineraryDuration] = useState()
const[tripSeason, setTripSeason] = useState()

//*How To Reach
//load status
const[loadReachData_Status, setLoadReachData_Status] = useState(false)
//ai
const[overview_reach, setOverview_reach] = useState()

//air
const[airportData_reach, setAirportData_reach] = useState([])
//railway
const[railwayData_reach, setRailwayData_reach] = useState([])
//bus
const[busData_reach, setBusData_reach] = useState([])


//*Ask Question

//Questions
//?q1
const about_destination_question = `Tell me more about ${destinationName}`
//?q2
const must_know_things_question = `What are some "Must Know Things" for ${destinationName}`
//?q3
const history_question = `What is the history of ${destinationName}`
//?q4
const attraction_question = `Tell me some top attractions to explore in ${destinationName}`
//?q5
const climate_question = `I wish to know about the climate of ${destinationName}`

//?
//?manage
const[activeQuestion, setActiveQuestion] = useState()
const[activeResponseData, setActiveResponseData] = useState()
const[activeResponseData_array, setActiveResponseData_array] = useState([])
const[activeDisplayQuestion, setActiveDisplayQuestion] = useState()


const[array_AskQ, setArray_AskQ] = useState()
//?dynamic questions
const[question_1, setQuestion_1] = useState()
const[question_2, setQuestion_2] = useState()
const[askQuestionInput, setAskQuestionInput] = useState()
const[activeQuestionText, setActiveQuestionText] = useState()
const[questionAskedCount, setQuestionAskedCount] = useState(0)

//?static questions
const[aboutDestinationData, setAboutDestinationData] = useState()
const[mustKnowData, setMustKnowData] = useState()


//?Data
//?dynamic
const[question1_Data, setQuestion1_Data] = useState()
const[question2_Data, setQuestion2_Data] = useState()
//?static
const[answerData_question, setAnswerData_question] = useState([])

//*Map
const[destinationLat, setDestinationLat] = useState()
const[destinationLng, setDestinationLng] = useState()
const[lat, setLat] = useState()
const[lng, setLng] = useState()
const[zoom, setZoom] = useState(6)
const[hoverData, setHoverData] = useState([])

const [hoverLocationData_map, setHoverLocationData_map] = useState([])
const [activeLocationData_map, setActiveLocationData_map] = useState([])
    
    
//!useEffect
useEffect(() => {
    if(!useEffectLoaded_status){

        handleInitialLoad();  
    }
}, [])

let user_email;

// id, plan_trip_id

const checkForSavedItinerary = async (unique_id, user_email) => {

    const response = await axios.post(`${server_baseUrl}/csc/check/saved/create_itinerary`, {unique_id, user_email})

    // console.log("checkForSavedItinerary - response.data", response.data)

    // console.log("---")
    // console.log("Step 1: checkForSavedItinerary was reached")
    // console.log("---")

    return response;
}

const handleInitialLoad = async () => {

    // console.log("---")
    // console.log(`Step 0: handleInitialLoad was reached`)
    // console.log("---")

    user_email = await handleGetUserLoginStatus()

    if(!user_email){
        navigate('/email-not-found')
    }

    // console.log(`Step 0 user_email ${user_email}: handleInitialLoad was reached`)

    const response = await checkForSavedItinerary(unique_id, user_email)

    //saved data found
    if(response.data && response.data != "0" && response.data.length > 0){

        // console.log("---")
        // console.log("Step 2: saved data found - handleInitialLoad")
        // console.log("---")

        //
        setPrompt(response.data[0].prompt)
        setCreateTripId(response.data[0].plan_trip_id)

        setSavedPlacesData(JSON.parse(response.data[0].place_detail))
        setDistrictName(response.data[0].district_name)   
        
        setTripName(response.data[0].plan_trip_name)   

        setOverviewContent_ai(response.data[0].overview)
        // setOverviewBudget(response.data[0].overiew_budget)
        // setOverviewClothes(response.data[0].overview_clothes)
        // setOverviewTravelAround(response.data[0].overview_travel_around)
        //reach

        const reach_overview = response.data[0].reach_overview;
        if(reach_overview){
            setOverview_reach(reach_overview)
            setAirportData_reach(JSON.parse(response.data[0].reach_air))
            setRailwayData_reach(JSON.parse(response.data[0].reach_train))
            setBusData_reach(JSON.parse(response.data[0].reach_bus))
        }
        //AskQuestions
        //?static
        // console.log("response.data[0].answer_about_destination", response.data[0].answer_about_destination)
        setAboutDestinationData(JSON.parse(response.data[0].answer_about_destination))
        setMustKnowData(JSON.parse(response.data[0].answer_must_know))
        //?dynamic
        const question_1_Temp = response.data[0].question_1;
        const question_2_Temp = response.data[0].question_2;

        if(question_1_Temp && question_2_Temp){
            setQuestionAskedCount(2)
            // console.log("2222 - setQuestionAskedCount")
        }
        else if(question_1_Temp || question_2_Temp){
            setQuestionAskedCount(1)
            // console.log("1111 - setQuestionAskedCount(1)")
        }

        setQuestion_1(question_1_Temp)
        setQuestion_2(question_2_Temp)
        setQuestion1_Data(response.data[0].answer_question_1)
        setQuestion2_Data(response.data[0].answer_question_2)

        //map
        setDestinationLat(response.data[0].destination_lat) 
        setDestinationLng(response.data[0].destination_lng)
        setLat(response.data[0].destination_lat)
        setLng(response.data[0].destination_lng)
        setZoom(8)
        //
        //function
        handleSaved_OptimizedItinerary(JSON.parse(response.data[0].itinerary_data))
        //handle get route
        setGetRoute_buttonStatus("show")
    }
    else{ //saved data not found
        if(unique_id && user_email){
            // alert("handleLoadPrompt was called")
            handleLoadPrompt(unique_id, user_email);
            // console.log("unique_id, user_email, unique_id, user_email")
            // console.log("---")
            // console.log(`handleLoadPrompt - Step 2 - user_email ${user_email}: saved data not found - handleInitialLoad -- user_email ${user_email}`)
            // console.log("---")
        }
        else{
            // alert("handleLoadPrompt was not called")
        }
    }

    // console.log(`Step 22222 - user_email ${user_email}: saved data not found - handleInitialLoad -- user_email ${user_email}`)

    // handleLoadPrompt(unique_id, user_email);


    setUseEffectLoaded_status(true)
}

//!Home
const handleActive_ItineraryResultClicked = (passedActive) => {
    setActiveNavigationCategory_ItineraryResult(passedActive)
}


//!AI Prompts
//?main prompt
const handleLoadPrompt = async (unique_id, user_email) => {

    // alert("22222222 - handleLoadPrompt - was called")

    const response =  await axios.post(`${server_baseUrl}/csc/send/api/ai_tools/ai_components/itinerary_ai/prompt`, {unique_id})
    // console.log("handleLoadPrompt", response.data)

    // console.log("---")
    // console.log(`Step 3 - user_email ${user_email}: loading fresh data - handleLoadPrompt`)
    // console.log("---")

    if(response.data !== "0"){   
        const prompt = response.data;
        setPrompt(response.data)
        loadOptimizedItinerary(prompt, user_email)
    }
    else{
        // alert("error in loading some parts of the prompt")
    }
}


//!Load Itinerary Data
//*load places and optimized sequence
const loadOptimizedItinerary = async (prompt, user_email) => {

    // console.log("user_email user_email", user_email)

    const response =  await axios.post(`${server_baseUrl}/csc/send/api/ai_tools/ai_components/itinerary_ai/load_content`, {unique_id})

    // console.log("loadOptimizedItinerary", response.data)
    const passItineraryData = response.data;
    const itineraryResult = response.data.data?.itinerayResult;

    if(itineraryResult && itineraryResult.itineraryArray?.length > 0){   

    const { data } = response.data;
    const { itineraryArray, totalExploreDuration, totalTravelDuration } = data.itinerayResult;
    const state_name = data.state_name;    
    const {destination_name, destination_type, season} = response.data;

    const totalLength = itineraryArray.length;
    const itinerary_duration = itineraryArray[totalLength-1].day; 
    
    // console.log("data.state_name", data.state_name, "state_name", state_name, "destination_name, destination_type, season", destination_name, destination_type, season)

    // console.log()

    setOptimizedItinerary(itineraryArray);
    setExploreDuration(totalExploreDuration);
    setTravelDuration(totalTravelDuration);
    setStateName(state_name);
    setDestinationName(destination_name);
    setDestinationType(destination_type);
    setItineraryDuration(itinerary_duration);
    setTripSeason(season);

    // console.log('\n', "exploreDuration", totalExploreDuration);
    // console.log('\n', "travelDuration", totalTravelDuration);
    // console.log('\n', "stateName", state_name);
    // console.log('\n', "destinationName", destination_name);
    // console.log('\n', "itineraryDuration", itinerary_duration);
    // console.log('\n', "tripSeason", season);

    const Ids = itineraryArray.map(subItem => subItem.sq_source);

    setPlaceIds(Ids);

    // console.log("placeIds", Ids)
    
    //!here we get the lat, lng, and also store the data, the initial step in the saved one...
    
    getDestinationLatLng(destination_name, destination_type, passItineraryData, unique_id, prompt, user_email);

   // console.log("0000000000000000 place_ids, state_name, itineraryArray, destination_name, destination_type, totalExploreDuration, totalTravelDuration, itinerary_duration, season, user_email", Ids, state_name, itineraryArray, destination_name, destination_type, totalExploreDuration, totalTravelDuration, itinerary_duration, season, user_email) 

    // alert("1st loadPlaceDeatils was called!!")
    const timer = setTimeout(() => {
        loadPlaceDeatils(Ids, state_name, itineraryArray, destination_name, destination_type, totalExploreDuration, totalTravelDuration, itinerary_duration, season, user_email);
    }, 2000);
    
    getRoute(Ids, state_name);

    }
    else{
        // alert("error in loading trip places")
    }
}


const handleSaved_OptimizedItinerary = (savedItineraryDataPassed) =>{

    // console.log("I reached here............")
    const { data } = savedItineraryDataPassed;
    const { itineraryArray, totalExploreDuration, totalTravelDuration } = data.itinerayResult;
    const state_name = data.state_name;    
    const { destination_name, destination_type, season } = savedItineraryDataPassed;

    const totalLength = itineraryArray.length;
    const itinerary_duration = itineraryArray[totalLength-1].day; 
    
    // console.log("data.state_name", data.state_name, "itineraryArray", itineraryArray)

    setOptimizedItinerary(itineraryArray);
    setExploreDuration(totalExploreDuration);
    setTravelDuration(totalTravelDuration);
    setStateName(state_name);
    setDestinationName(destination_name);
    setDestinationType(destination_type);
    setItineraryDuration(itinerary_duration);
    setTripSeason(season);

    // console.log('\n', "setOptimizedItinerary", itineraryArray)
    // console.log('\n', "exploreDuration", totalExploreDuration);
    // console.log('\n', "travelDuration", totalTravelDuration);
    // console.log('\n', "stateName", state_name);
    // console.log('\n', "destinationName", destination_name);
    // console.log('\n', "itineraryDuration", itinerary_duration);
    // console.log('\n', "tripSeason", season);

    const Ids = itineraryArray.map(subItem => subItem.sq_source);

    setPlaceIds(Ids);

    // console.log("placeIds", Ids)
}

//*Save Initial Data
const insertInitialData_Save = async (data, unique_id, prompt, user_email, destinationLat, destinationLng) => {
    // console.log("data, unique_id, prompt, user_email", data, unique_id, prompt, user_email)
    const response = await axios.post(`${server_baseUrl}/ncsc/store/initial/data/load/create_ai_itinerary`, {data, unique_id, prompt, user_email, destinationLat, destinationLng});

    // console.log(response.data) 
}

//! create itinerary
const handleViewTrip = (tripNamePassed) => {
    if(destinationType === "city"){

        navigate(`/plan-trip-to-${destinationName}/${districtName}/${stateName}/${tripNamePassed}/${tripName}`)
    }
    else if(destinationType === "district"){
        
        navigate(`/plan-complete-trip/${districtName}/${stateName}/${tripNamePassed}/${tripName}`)
    }
    else if(destinationType === "state"){
        
        navigate(`/plan-complete-trip/state/${stateName}/${tripNamePassed}/${tripName}`)
    }
    else{
        // alert("error in navigation. Please try again.")
    }
}

const handleCreateTrip = async () => {
    try {

        const response = await axios.post(`${server_baseUrl}/csc/openai/create_ai_itinerary/plan_trip`, {
        tripName, destinationName, destinationType, stateName, districtName, savedPlacesData, unique_id, userEmail
        });
        // console.log("CreateTrip", response.data)
        if(response.data === "0"){
          // console.log("error in creating your trip. Please try again.")
        }
        else{
          const tripId = response.data;
        //   // console.log("currentPath", currentPath, "tripId", tripId)
        //   navigate(`${currentPath}${tripId}/${tripName}`);
        //   window.location.reload();
          handleViewTrip(tripId)
        }

    } catch (error) {
        console.error("Error in creating creating the trip");
        // console.log(error)
    }
}

//!load Day wise ai data 
const loadBudgetOverviewData = async () => {

}

//*load the route

const handle_CallGetRoute = () => {
    getRoute(placeIds, stateName)
}

const getRoute = async (ids, state_name) => {
        
    // console.log("getRoute - place_ids", ids)

    setGetRoute_buttonStatus("hide")

    const response = await axios.post(`${server_baseUrl}/ncsc/get/test/route/data/locations/from_api/mapbox`, {ids, state_name});
    
    console.log("getRoute - response.data", response.data)

    if(response.data !== "0"){   
        setOptimizedItineraryRoute(response.data)
    }
    else{
        // alert("error in the route and distance for itinerary.")
    }
}



//!load Place Details
const loadPlaceDeatils = async (place_ids, state_name, itineraryArray, destination_name, destination_type, totalExploreDuration, totalTravelDuration, itinerary_duration, season, user_email) => {

    // alert("2nd in the loadPlaceDeatils was called!!")

    // console.log("place_ids, state_name, itineraryArray, destination_name, destination_type, totalExploreDuration, totalTravelDuration, itinerary_duration, season, user_email", place_ids, state_name, itineraryArray, destination_name, destination_type, totalExploreDuration, totalTravelDuration, itinerary_duration, season, user_email) 
    
    const response = await axios.post(`${server_baseUrl}/csc/send/api/ai_tools/ai_components/itinerary_ai/load_places_data`, {place_ids, state_name, unique_id, user_email})

    // console.log("loadPlaceDeatils", response.data.placeData, state_name)

    if(response.data.placeData !== "0" && response.data.placeData.length > 0){

        const placeDataArray = response.data.placeData;

        const sequence_SavedPlacedata = await handleSetCorrectSequence_PlaceData(placeDataArray, itineraryArray)
        // console.log("sequence_SavedPlacedata", sequence_SavedPlacedata)
        setSavedPlacesData(sequence_SavedPlacedata)
        

        // const place_names = response.data.map(subItem => subItem.name);
        placeNames_and_Days = placeDataArray.map(item => 
            itineraryArray.map(data => {
                if (data.sq_source === item.sn) {
                    return `${item.name} - day ${data.day}`;
                } else {
                    return null; // or simply omit this else case
                }
            }).filter(Boolean) // filter out null values
        ).join(', ');

        // console.log("placeNames_and_Days", placeNames_and_Days)

        setDistrictName(response.data.district_name) 

        //!temp
        //load over view content
        getOverviewContent_Ai(placeNames_and_Days, state_name, destination_name, destination_type, totalExploreDuration, totalTravelDuration, itinerary_duration, season, user_email)
    }
}

const handleSetCorrectSequence_PlaceData = async (placeDataArray, itineraryArray) => {
    // Create a map for quick lookup of placeData by sn
    const placeDataMap = new Map(placeDataArray.map(place => [place.sn, place]));

    // Create the new ordered array based on the sequence in itineraryArray
    const orderedPlaceDataArray = itineraryArray
        .map(itinerary => placeDataMap.get(itinerary.sq_source))
        .filter(Boolean); // Filter out any undefined values in case of mismatches

    // console.log("sequence_SavedPlacedata", orderedPlaceDataArray)

    return orderedPlaceDataArray;
};

const[overviewContent_ai, setOverviewContent_ai] = useState()
const[budgetOverview_ai, setBudgetOverview_ai] = useState([])
const[wearOverview_ai, setWearOverview_ai] = useState([])
const[travelAroundOverview_ai, setTravelAroundOverview_ai] = useState([])
// const[howToReachOverview_ai, setHowToReachOverview_ai] = useState([])


//!Ai Suggestions
const[activeButton_AiSuggestions, setActiveButton_AiSuggestions] = useState('hotel')
//*suggestions data and load status
//hotel
const[hotelData_AiSuggestions, setHotelData_AiSuggestions] = useState([])
const[loadHotelData_Status_AiSuggestions, setLoadHotelData_Status_AiSuggestions] = useState(false)
//restaurant
const[restaurantData_AiSuggestions, setRestaurantData_AiSuggestions] = useState([])
const[loadRestaurantData_Status_AiSuggestions, setLoadRestaurantData_Status_AiSuggestions] = useState(false)


const handleLoadData_AiSuggestions = async (entityTypePassed, setLoadedData) => {
    const response = await axios.post(`${server_baseUrl}/csc/create_itinerary/n_o_ai/get_data/ai_suggestions`, {placeIds, destinationName, destinationType, stateName, entityTypePassed})

    if(response.data !== "0"){
        //not a defined state, just a paramenter. The argument passed has the actual defined setStateName
        setLoadedData(response.data) 
    }
    else{
        // console.log("!!!!!!")
        // console.log("error in loading over view for the itinerary.")
        // console.log("!!!!!!")
    }
}

//!How To Reach 


const handleLoadReachData = async () => {
    // alert("handleLoadReachData")
    const response = await axios.post(`${server_baseUrl}/csc/create_itinerary/n_o_ai/get_data/how_to_reach`, {destinationName, destinationType})

    // console.log("handleLoadReachData", response.data)

    //*Learn about the response columns and more at the bottom

    if(response.data !== "0"){

        const airport_data = response.data[0];
        const railway_data = response.data[1];
        const bus_data = response.data[2];

        // console.log("airport_data, railway_data, bus_data", airport_data, railway_data, bus_data)

        setAirportData_reach(airport_data)
        setRailwayData_reach(railway_data)
        setBusData_reach(bus_data)

        setLoadReachData_Status(true)

        const ids_airport_data = airport_data.map(item => `type ${item.mode_type} in ${item.mode_state_name}`).join(";");

        const ids_railway_data = railway_data.map(item => `station type ${item.mode_name} in ${item.mode_state_name}`).join(";");

        const ids_bus_data = bus_data.map(item => `bus stand ${item.mode_name} and ${item.mode_type} in ${item.mode_state_name}`).join(";");

        //!temp
        getAiOverview_reach(ids_airport_data, ids_railway_data, ids_bus_data, airport_data, railway_data, bus_data)
    }
    else{
        // console.log("!!!!!!")
        // console.log("error in loading reach data handleLoadReachData.")
        // console.log("!!!!!!")
    }
}

const getAiOverview_reach = async (ids_airport_data, ids_railway_data, ids_bus_data, airport_data, railway_data, bus_data) => {


    const response = await axios.post(`${server_baseUrl}/csc/create_itinerary/n_o_ai/get/ai_overiew/reach`, {ids_airport_data, ids_railway_data, ids_bus_data, destinationName, destinationType, stateName, unique_id, userEmail, airport_data, railway_data, bus_data})

    // console.log("getAiOverview_reach", response.data)

    if(response.data !== "0"){
        
        setOverview_reach(response.data)
    }
    else{
        // console.log("!!!!!!")
        // console.log("error in loading ai response for reach.")
        // console.log("!!!!!!")
    }
}


//!ChatGpt Requests -
//?functions list
// getOverviewContent_Ai
// getBudgetOverview_Ai
// getWearOverview_Ai
// getTravelAroundOverview_Ai
// getHowToReachOverview_Ai
// getQuestion1_Ai
// getQuestion2_Ai

//--//!Day Wise Overview

//--//!Ask Question

//*load overview
const getOverviewContent_Ai = async (
    placeNames_and_Days, state_name, destination_name, destination_type, totalExploreDuration, totalTravelDuration, itinerary_duration, season, user_email
) => {
    const response = await axios.post(`${server_baseUrl}/csc/openai/create_ai_itinerary/get/itinerary_overview_content`, {placeNames_and_Days, state_name, destination_name, destination_type, totalExploreDuration, totalTravelDuration, itinerary_duration, season, unique_id, user_email})

    // console.log("response.data - getOverviewContent_Ai", response.data)

    if(response.data !== "0"){
        setOverviewContent_ai(response.data)
    }
    else{
        // console.log("!!!!!!")
        // console.log("error in loading over view for the itinerary.")
        // console.log("!!!!!!")
    }
}

//*handle questions static and dynamic/user asked


const handleSubmit_AskQuestion = async (activeQuestionText) => {

    setLoadingType("ai_question")

    const response = await axios.post(`${server_baseUrl}/csc/openai/create_ai_itinerary/get/answer/user_asked/question`, {activeQuestionText, placeNames_and_Days, stateName, destinationName, destinationType, exploreDuration, travelDuration, itineraryDuration, tripSeason, unique_id, userEmail, questionAskedCount})

    // console.log("response.data - getOverviewContent_Ai", response.data)

    if(response.data && response.data != "0" && response.data != "1"){
        // console.log("1111")
        if(!question_1){
            setQuestionAskedCount(1)
            setQuestion_1(activeQuestionText)
            setQuestion1_Data(response.data)
            setActiveQuestion("question_1")
            setArray_AskQ(false)
            setActiveDisplayQuestion(activeQuestionText)
            setActiveResponseData(response.data)
        }
        else{
            setQuestionAskedCount(2)
            setQuestion_2(activeQuestionText)
            setQuestion2_Data(response.data)
            setActiveQuestion("question_2")
            setArray_AskQ(false)
            setActiveDisplayQuestion(activeQuestionText)
            setActiveResponseData(response.data)
        }
        setLoadingType(false)
    }
    else if(response.data == "1"){
        // console.log("1111")
        // alert("You have used the max limit for asking questions.")
        setLoadingType(false)
    }
    else{
        // console.log("!!!!!!")
        // console.log("error in loading the answer to the user asked question.")
        // console.log("!!!!!!")
        setLoadingType(false)
    }
}


const handleLoadAnswer_AboutDestination = async (typePassed, passedQuestion) => {

    const answer = await loadData_AskQuestion("about_destination");
    if(answer){
        setAboutDestinationData(answer)
        // console.log("answer, typePassed", answer, typePassed, "answer[0].typePassed", answer[0].typePassed)
        setArray_AskQ(false)
        //we need to pass the question in the argument as it would be difficlut to know which of the 4 ...
        setActiveDisplayQuestion(passedQuestion)
        setActiveResponseData(answer[0][typePassed])
    }
} 

const handleLoadAnswer_MustKnow = async (typePassed) => {

    const answer = await loadData_AskQuestion(typePassed);
    if(answer){
        setMustKnowData(answer)

        setArray_AskQ(true)
        setActiveDisplayQuestion(must_know_things_question)
        setActiveResponseData_array(answer)
    }
}


const loadData_AskQuestion = async (questionType_passed) => {

    const response = await axios.post(`${server_baseUrl}/csc/openai/create_ai_itinerary/get/answer/static/question`, {questionType_passed, destinationName, destinationType, stateName, unique_id, userEmail})

    // console.log("response.data - loadData_AskQuestion", response.data)

    if(response.data && response.data !== "0"){
        
      const answer = response.data;

      return answer;

    }
    else{
        // console.log("!!!!!!")
        // console.log("error in loading the answer to the static question.")
        // console.log("!!!!!!")
    }
}




//!Map

//* get lat and lng of the destination

const getDestinationLatLng = async (destinatioName, destinationType, passItineraryData, unique_id, prompt, user_email) => {
    // // console.log("alert - destinatioName && destinationType", destinatioName, destinationType)
    if(destinatioName && destinationType){

        const response = await axios.post(`${server_baseUrl}/csc/validate/plan_trip/get_lat_lng`, {destinatioName, destinationType})

        // console.log("getDestinationLatLng", "destinatioName", destinatioName, "destinationType", destinationType)
        // console.log("getDestinationLatLng - response.data", response.data, "destinatioName, destinationType", destinatioName, destinationType)
        if(response.data !== "0"){
            const lat = response.data[0].lat;
            const lng = response.data[0].lng;
            setDestinationLat(lat) 
            setDestinationLng(lng)

            setLat(lat)
            setLng(lng)

            //* //?insert data    
            const insertStatusResponse = await insertInitialData_Save(passItineraryData, unique_id, prompt, user_email, lat, lng)  

            if(destinationType === "city"){
                setZoom(8)
            }
            else if(destinationType === "district"){
                setZoom(7)
            }
            else{
                setZoom(6)
            }
        }
        else{
        // console.log("error - getDestinationLatLng")
        }
    }
    else{
        // console.log(`empty: destinatioName - ${destinatioName} && destinationType - ${destinationType} `)
    }
}

//*hover 
const handleHoverLocation_Map = (passedLat, passedLng, passedName, passedTravelMode) => {
    setHoverLocationData_map([{lat: passedLat, lng: passedLng, name: passedName, travel_mode: passedTravelMode}])

    setLat(passedLat)
    setLng(passedLng)

    if(passedTravelMode === "air"){
        setZoom(8)
    }
    else if(passedTravelMode === "bus"){
        setZoom(10)
    }
    else if(passedTravelMode === "train"){
        setZoom(10)
    }

}

const handleHoverOffLocation_Map = () => {
    setHoverLocationData_map([])
    setActiveLocationData_map([])
}

const handleClickLocation_Map = (passedLat, passedLng, passedName, passedTravelMode) => {
    setActiveLocationData_map([{lat: passedLat, lng: passedLng, name: passedName, travel_mode: passedTravelMode}])

    setLat(passedLat)
    setLng(passedLng)
    
    if(passedTravelMode === "air"){
        setZoom(10)
    }
    else if(passedTravelMode === "bus"){
        setZoom(12)
    }
    else if(passedTravelMode === "train"){
        setZoom(12)
    }
}


const handleHover = (actionType, lat, lng, name, icon, indexPassed) => {
    // actionType - hover, and clicked
    console.log(`indexPassed ${indexPassed}`)
    if(actionType === "clicked"){
        setLat(lat)
        setLng(lng)
        setZoom(13)
    }

    setHoverData({
        "lat":lat,
        "lng":lng,
        "name":name,
        "icon":icon,
        "id":indexPassed
    })

    // console.log("i was called - handleHover", hoverData)
}



  // Step 5: Return a provider with value containing state and functions
  return (
    <Context.Provider value={{ 
        test, 
        // user_email,
        //!States
        //*manage
        useEffectLoaded_status,
        getRoute_buttonStatus, setGetRoute_buttonStatus,

        //loading
        loadingType, setLoadingType,

        //*prompt
        prompt, 

        //*home
        activeNavigationCategory_ItineraryResult,
        
        //*itinerary sequence and route data
        optimizedItinerary, 
        optimizedItineraryRoute, 

        //*Day Wise
        activeDay, setActiveDay,
        exploreDuration,
        travelDuration,
        stateName,
        destinationName,
        destinationType,
        itineraryDuration,
        tripSeason,
        //?tourist places
        savedPlacesData,

        //*Map
        destinationLat, destinationLng,
        lat, lng, 
        zoom, setZoom,
        hoverData, setHoverData,
        //map
        handleHoverLocation_Map, handleHoverOffLocation_Map, handleClickLocation_Map, 
        hoverLocationData_map, activeLocationData_map,

        //*createTrip
        createTripId,
        tripName, setTripName, tripWindowStatus, setTripWindowStatus,

        //*Open AI
        overviewContent_ai,
        budgetOverview_ai,
        wearOverview_ai,
        travelAroundOverview_ai,

        //*Ask Questions
        array_AskQ, setArray_AskQ,
        activeQuestion, setActiveQuestion,
        activeResponseData, setActiveResponseData,
        activeDisplayQuestion, setActiveDisplayQuestion,
        activeResponseData_array, setActiveResponseData_array, 
        askQuestionInput, setAskQuestionInput,
        activeQuestionText, setActiveQuestionText,
        question_1, setQuestion_1,
        question_2, setQuestion_2,
        questionAskedCount, setQuestionAskedCount,
        question1_Data, setQuestion1_Data,
        question2_Data, setQuestion2_Data,
        answerData_question,
        //
        aboutDestinationData,
        mustKnowData,
            //questions
            about_destination_question,
            must_know_things_question,
            history_question,
            attraction_question,
            climate_question,

        //*How To Reach
        loadReachData_Status,
        overview_reach,
        airportData_reach,
        railwayData_reach,
        busData_reach,
        
        //!Functions
        //*Home
        handleActive_ItineraryResultClicked, handle_CallGetRoute,
        //*Map
        handleHover,
        //*Ask Questions
        handleSubmit_AskQuestion, 
        handleLoadAnswer_AboutDestination, 
        handleLoadAnswer_MustKnow,
        //*How To Reach
        handleLoadReachData,
        //*createTrip
        handleViewTrip, handleCreateTrip,
      
    }}>
      {children}
    </Context.Provider>
  );

};

// Step 6: Create a custom hook to consume the context
export const useAiCreateItinerary = () => {
  return useContext(Context);
};






    //?airport data columns
    // mode_type, mode_name, mode_code, lat, lng, mode_state_name, mode_district
    // {
    //     "mode_type": "international",
    //     "mode_name": "Shaheed Bhagat Singh International Airport",
    //     "mode_code": "IXC",
    //     "lat": 30.66792819,
    //     "lng": 76.78598878,
    //     "mode_state_name": "Chandigarh",
    //     "mode_district": "Chandigarh"
    // },
    //?railway data columns
    // mode_type, mode_category, mode_name, mode_code, lat, lng, mode_state_name, district_name
    // {
    //     "mode_type": "major",
    //     "mode_category": "A1",
    //     "mode_name": "AMBALA CANTT. JN.",
    //     "mode_code": "UMB",
    //     "lat": 30.3377219,
    //     "lng": 76.828023,
    //     "mode_state_name": "HARYANA",
    //     "district_name": "Shimla"
    // }
    //?bus data columns
    // mode_type, mode_name, mode_code, lat, lng, mode_state_name, district_name
    // {
    //     "mode_type": "isbt",
    //     "mode_name": "Amar Shaheed Sukhdev ISBT, Ludhiana",
    //     "mode_code": "ISBT",
    //     "lat": 30.912,
    //     "lng": 75.8573,
    //     "mode_state_name": "Punjab",
    //     "district_name": "Shimla"
    // }