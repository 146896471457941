import React from 'react'

//import scss
import './QuickTravelCategories.scss'

import { useNavigate, useParams, useLocation } from 'react-router-dom';

//import icons
import { MdHotel } from "react-icons/md";
import { IoRestaurant } from "react-icons/io5";
import { RiBeerLine } from "react-icons/ri";

import { FaPlaneDeparture } from "react-icons/fa6";
import { FaTrainTram } from "react-icons/fa6";
import { FaBus } from "react-icons/fa";


function QuickTravelCategories(props) {

    const {handle_AIFeatureClick, handleToastMessage} = props;

    const navigate = useNavigate(); 

    const handleNavigate_AdventureActivities = () => {
      navigate('/discover/Adventure-Activities')
    }
  
    const handleNavigate_AmusementParks = () => {
      navigate('/discover/Amusement-Parks')
    }
  
    const handleNavigate_Bars_and_Pubs = () => {
      navigate('/discover/Bars-and-Pubs')
    }
  
    const handleNavigate_Hotels = () => {
      navigate('/discover/Hotels')
    }
  
    const handleNavigate_Rent = () => {
      navigate('/discover/Rent')
    }
  
    const handleNavigate_Restaurants = () => {
      navigate('/discover/Restaurants')
    }
  
    const handleNavigate_ShoppingMalls = () => {
      navigate('/discover/Shopping-Malls')
    }
  
    const handleNavigate_ShoppingStreet = () => {
      navigate('/discover/Shopping-Street/Street-Market')
    }


    //
    const openPopup = () => {
      document.body.style.overflow = 'hidden'; // Disable scrolling on the body
    };


    const handleNavigate_byAir = () => {
    const firstMode = "air";
    const secondMode = "train";
    const thirdMode = "bus";
    navigate(`/discover-travel-modes/${firstMode}/${secondMode}/${thirdMode}`)
    }

    const handleNavigate_byTrain = () => {
    navigate('/discover-travel-modes/train/air/bus')
    }

    const handleNavigate_byBus = () => {
    navigate('/discover-travel-modes/bus/air/train')
    }


  return (
    <div
    className='container_p-QuickTravelCategories'
    >
      <div
        className='container_s-QuickTravelCategories'
      >

        {/*//! #1 - Create AI Itinerary*/}
        <button
        className='container_p-CAI-QuickTravelCategories'
        onClick={()=>{
          handle_AIFeatureClick("create_itinerary")
          openPopup()
        }}
        >

            <img 
            src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/ai_stars_1.png'
            className='img-start-QuickTravelCategories'
            />

            <div
            className='text-1-QuickTravelCategories'
            >
                Create Trip Itinerary with AI
            </div>

            <img 
            src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/ai-mixed-color-bomb-1.png'
            className='img-colorBomb-QuickTravelCategories'
            />

        </button>


        {/*//! #2 - Travel Categories*/}
        <div
        className='heading-1-UNI-QuickTravelCategories'
        >
            Discover - Travel Categories
        </div>
        <div
        className='conatiner_p-TravelCategory-QuickTravelCategories'
        >
            <button
            className='conatiner_p-button-TravelCategory-QuickTravelCategories'
            onClick={()=>{handleNavigate_Hotels()}}
            >
                <div 
                className='text-1-TravelCategory-QuickTravelCategories'
                >
                    Hotels
                </div>
                <div
                className='icon-1-TravelCategory-QuickTravelCategories'
                >
                    <MdHotel />
                </div>
            </button>

            <button
            className='conatiner_p-button-TravelCategory-QuickTravelCategories'
            // onClick={()=>{handleNavigate_Restaurants()}}
            onClick={()=>{handleToastMessage("Comming Soon", "info")}}
            >
                <div 
                className='text-1-TravelCategory-QuickTravelCategories'
                >
                    Restaurants
                </div>
                <div
                className='icon-1-TravelCategory-QuickTravelCategories'
                >
                    <IoRestaurant />
                </div>
            </button>

            <button
            className='conatiner_p-button-TravelCategory-QuickTravelCategories'
            // onClick={()=>{handleNavigate_Bars_and_Pubs()}}
            onClick={()=>{handleToastMessage("Comming Soon", "info")}}
            >
                <div 
                className='text-1-TravelCategory-QuickTravelCategories'
                >
                    Bars and Pubs
                </div>
                <div
                className='icon-1-TravelCategory-QuickTravelCategories'
                >
                    <RiBeerLine />
                </div>
            </button>

        </div>


<br></br>


        {/*//! #3 - Travel Modes*/}
        <div
        className='heading-1-UNI-QuickTravelCategories'
        >
            Explore Travel Modes
        </div>

        <div
        className='conatiner_p-TravelModes-QuickTravelCategories'
        >
            <button
            className='conatiner_p-button-TravelModes-QuickTravelCategories'
            onClick={()=>{handleNavigate_byAir()}}
            >
                <div
                className='text-1-TravelModes-QuickTravelCategories'
                >
                    Airports
                </div>
                <div
                className='icon-1-TravelModes-QuickTravelCategories'
                >
                    <FaPlaneDeparture />
                </div>
            </button>

            <button
            className='conatiner_p-button-TravelModes-QuickTravelCategories'
            onClick={()=>{handleNavigate_byTrain()}}
            >
                <div
                className='text-1-TravelModes-QuickTravelCategories'
                >
                    Train
                </div>
                <div
                className='icon-1-TravelModes-QuickTravelCategories'
                >
                    <FaTrainTram />
                </div>
            </button>

            <button
            className='conatiner_p-button-TravelModes-QuickTravelCategories'
            onClick={()=>{handleNavigate_byBus()}}
            >
                <div
                className='text-1-TravelModes-QuickTravelCategories'
                >
                    Bus
                </div>
                <div
                className='icon-1-TravelModes-QuickTravelCategories'
                >
                    <FaBus />
                </div>
            </button>

        </div>

      </div>     
    </div>
  )
}

export default QuickTravelCategories