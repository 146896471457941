import L from "leaflet";

// tourist Places
export const active_tourist_places = new L.icon({
iconUrl: "https://i.ibb.co/3FRGDWP/active-tourist-location.png",
iconSize: [25, 25],
iconAnchor: [14, 25],
popupAnchor:[-1.8, -20]
  
});

export const hover_tourist_places = new L.icon({
iconUrl: "https://i.ibb.co/6rYnFtd/gps.png",
iconSize: [25, 25],
iconAnchor: [14, 25],
popupAnchor:[-1.8, -20]
  
});

export const saved_tourist_places = new L.icon({
iconUrl: "https://i.ibb.co/rpms1QB/pin.png",
iconSize: [50, 50],
iconAnchor: [25, 50],
popupAnchor:[-1.8, -20]
  
});





//!New

//*Hotels
//1
export const hotel_1_colored = new L.icon({
iconUrl: "https://pub-683f2710f5c048c786c950689efa99ff.r2.dev/hotel_icon_map_1.png",
iconSize: [30, 30],
iconAnchor: [15, 30],
popupAnchor:[-1.8, -20]
    
});
export const hotel_1_colored_small = new L.icon({
    iconUrl: "https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/red-dot.png",
    iconSize: [16, 16],
    iconAnchor: [8, 16],
    popupAnchor:[-1.8, -20]
        
});

//2
export const hotel_active_1_colored = new L.icon({
iconUrl: "https://pub-683f2710f5c048c786c950689efa99ff.r2.dev/hotel_active_icon_map_1.png",
iconSize: [50, 50],
iconAnchor: [25, 50]
// popupAnchor:[-1.8, -20]
    
});




// tourist_places
// hotels
// restaurants
// bars_and_pubs
// amusement_parks
// adventure_activities
// rent_vehicle
// shopping_malls
// street_market


//! How To Reach Map icons
//*Map Icons
export const map_pin_1 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/pin_1.png",
iconSize: [50, 50],
iconAnchor: [25, 50],
});
export const map_pin_1_s = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/pin_1.png",
iconSize: [30, 30],
iconAnchor: [15, 30],
});

export const map_pin_2 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/pin_2.png",
iconSize: [40, 40],
iconAnchor: [20, 40],
popupAnchor: [0, -30]
});
export const map_pin_2_s = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/pin_2.png",
iconSize: [26, 26],
iconAnchor: [13, 26],
popupAnchor: [0, -30]
});
export const map_pin_3 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/pin_3.png",
iconSize: [40, 40],
iconAnchor: [20, 40],
});

//saved locations
export const saved_location = new L.icon({
    iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/saved_location.png",
    iconSize: [44, 44],
    iconAnchor: [22, 44],
    // popupAnchor:[-1.8, -20]
});

//places
// the one used for saved plans
export const place_1_n = new L.icon({
    iconUrl: "https://i.postimg.cc/Jt4sNpzF/11.png",
    iconSize: [22, 22],
    iconAnchor: [11, 22],
    // popupAnchor:[-1.8, -20]
});
export const place_1_l = new L.icon({
    iconUrl: "https://i.postimg.cc/Jt4sNpzF/11.png",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor:[0, -19]
});


//*Airports
export const airport_dead_1 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/air_dead_1.png",
iconSize: [30, 30],
iconAnchor: [15, 30],
popupAnchor: [0, -30]
});

export const airport_normal_1 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/air_normal_1.png",
iconSize: [40, 40],
iconAnchor: [20, 40],
});

export const airport_normal_Ai_Reach = new L.icon({
    iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/air_normal_1.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
});

export const airport_normal_2 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/air_normal_2.png",
iconSize: [40, 40],
iconAnchor: [20, 40],
});

export const airport_active_1 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/air_active_1.png",
iconSize: [40, 40],
iconAnchor: [20, 40],
});


//*Trains
export const train_dead_1 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/train_dead_1.png",
iconSize: [30, 30],
iconAnchor: [15, 30],
popupAnchor: [0, -30]
});

export const train_normal_1 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/train_normal_1.png",
iconSize: [40, 40],
iconAnchor: [20, 40],
});

export const train_normal_Ai_Reach = new L.icon({
    iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/train_normal_1.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
});

export const train_normal_2 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/train_normal_2.png",
iconSize: [40, 40],
iconAnchor: [20, 40],
});

export const train_active_1 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/train_active_1.png",
iconSize: [40, 40],
iconAnchor: [20, 40],
});

//*Bus
export const bus_dead_1 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/bus_dead_1.png",
iconSize: [30, 30],
iconAnchor: [15, 30],
popupAnchor: [0, -30]
});

export const bus_normal_1 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/bus_normal_1.png",
iconSize: [30, 30],
iconAnchor: [15, 30],
});

export const bus_normal_Ai_Reach = new L.icon({
    iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/bus_normal_1.png",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
});

export const bus_normal_2 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/bus_normal_2.png",
iconSize: [30, 30],
iconAnchor: [15, 30],
});

export const bus_active_1 = new L.icon({
iconUrl: "https://pub-78bbe6c9c806468bb4ea937d820e9168.r2.dev/bus_active_1.png",
iconSize: [30, 30],
iconAnchor: [15, 30],
});


