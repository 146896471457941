import React, { useEffect, useState, useRef } from 'react';

//import scss
import './Footer.scss'

//
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import axios, { Axios } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import {FiSearch} from "react-icons/fi"; 
import {FaMapPin} from "react-icons/fa"; 
import {HiOutlineMap} from "react-icons/hi"; 
import { Helmet } from 'react-helmet';
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";  

import { HiXCircle } from "react-icons/hi"; 
import { BsBookmark } from "react-icons/bs";
import { BsFillBookmarkFill } from "react-icons/bs";

import { Rating } from 'react-simple-star-rating';
// import Login from '../Plan/UserLogin/Login';

import { Button, Slider } from '@mui/material'

import {AiFillLinkedin} from 'react-icons/ai'
import {FaFacebook} from 'react-icons/fa'
import {BsTwitter} from 'react-icons/bs'
import {AiFillInstagram} from 'react-icons/ai'
import {BsYoutube} from 'react-icons/bs'
import {FcReddit} from 'react-icons/fc'
import {RiShareForwardLine} from "react-icons/ri" 
import { PiShootingStarBold } from "react-icons/pi";
import Moment from 'moment' 

import {
  FacebookMessengerIcon, WhatsappIcon, TelegramIcon,  
  FacebookShareButton, TelegramShareButton, WhatsappShareButton,
} from "react-share"; 


function Footer(props) {

  const {userEmail, setManageLoginWindow, scrollToFeedback, activeTripStatus, setActiveTripStatus, setScrollPageTo_AISection, activePlaceSegment, setActivePlaceSegment, feedbackWindow, setFeedbackWindow, handleToastMessage} = props;

  const navigate = useNavigate();

  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const shareUrl =  "https://preparetrip.com/";
 
  const handleUpdateShare = (socialMedia, shareTime) => {
    axios.post("https://us-central1-preparetrip-node-jul01.cloudfunctions.net/app/update/sharecount/mainsite", { socialMedia, userEmail, shareTime })
  }


  const navigate_PrivacyPolicy = () => {
    window.open('/privacy_policy', '_blank')
  }

  const navigate_AboutUse = () => {
    window.open('/about_us', '_blank')
  }

  const handleNavigateTravelModes = (passedTravelMode) => {
    if(passedTravelMode === "air"){
      navigate('discover-travel-modes/air/train/bus')
    }
    else if(passedTravelMode === "bus"){
      navigate('discover-travel-modes/bus/air/train')
    }
    else if(passedTravelMode === "train"){
      navigate('discover-travel-modes/train/air/bus')
    }
  }


  //!Travel Categories
  const handleNavigate_AdventureActivities = () => {
    navigate('/discover/Adventure-Activities')
  }

  const handleNavigate_AmusementParks = () => {
    navigate('/discover/Amusement-Parks')
  }

  const handleNavigate_Bars_and_Pubs = () => {
    navigate('/discover/Bars-and-Pubs')
  }

  const handleNavigate_Hotels = () => {
    navigate('/discover/Hotels')
  }

  const handleNavigate_Rent = () => {
    navigate('/discover/Rent')
  }

  const handleNavigate_Restaurants = () => {
    navigate('/discover/Restaurants')
  }

  const handleNavigate_ShoppingMalls = () => {
    navigate('/discover/Shopping-Malls')
  }

  const handleNavigate_ShoppingStreet = () => {
    navigate('/discover/Shopping-Street/Street-Market')
  }


return (

<footer className='container_p-Footer'>

  <div className='container_s-Footer'>       

    {/*//! #1  */}
    <div className='container-1-Footer'>

      <h4 className='heading-1-Content-Footer'>  
          Contact us
      </h4>    
      <h3 className='content-Footer-Home3'>
        {/* contact@preparetrip.com */}
        <a href="mailto:contact@preparetrip.com" className="email-link"> contact@preparetrip.com</a>
      </h3>

      <div>
        <button className='button-2-Footer'
        onClick={()=>{
          setManageLoginWindow(true)
          openPopup()
        }}
        >
          Manage Account
        </button>
      </div>

      <h4 className='heading-2-Content-Footer'>  
          Search Destination
      </h4>  
      <ul className='ul-1-Footer'>
        <li>
          <button className='button-1-Footer'
            onClick={()=>{
              scrollToFeedback("start_planning")
              setActivePlaceSegment("city")
            }}
          >
            Search Cities
          </button>
        </li>
        <li>
          <button className='button-1-Footer'
            onClick={()=>{
              scrollToFeedback("start_planning")
              setActivePlaceSegment("district")
            }}
          >
            Search Districts
          </button>
        </li>
        <li>
          <button className='button-1-Footer'
            onClick={()=>{
              scrollToFeedback("start_planning")
              setActivePlaceSegment("state")
            }}
          >
            Search States
          </button>
        </li>  
      </ul>
  

      <div>
        <button className='button-2-Footer'
        onClick={()=>{scrollToFeedback("start_planning")}}
        >
          Start Planning
        </button>
      </div>

      <div>
        <button className='button-2-Footer'
        onClick={()=>{setScrollPageTo_AISection("my_ai_trips")}}
        >
          Create Itinerary with AI <PiShootingStarBold />
        </button>
      </div>
      

      <h4 className='heading-2-Content-Footer'>  
        Saved
      </h4>  
      <ul className='ul-1-Footer'>
        <li>
          <button className='button-1-Footer'
          onClick={()=>{
            scrollToFeedback("my_trips")
            setActiveTripStatus("all")
          }}
          >
            Your Saved Trip
          </button>
        </li>
        <li>
          <button className='button-1-Footer'
          onClick={()=>{scrollToFeedback("my_ai_trips")}}
          >
            Your Saved AI Itineraries
          </button>
        </li>
        <li>
          <button className='button-1-Footer'
            onClick={()=>{
              scrollToFeedback("my_trips")
              setActiveTripStatus("progress")
            }}
          >
            Your Trips in Progress
          </button>
        </li>
        <li>
          <button className='button-1-Footer'
            onClick={()=>{
              scrollToFeedback("my_trips")
              setActiveTripStatus("completed")
            }}
          >
            Your Completed Trips
          </button>
        </li>
      </ul>

    </div>  




    {/*//! #2  */}
    <div className='container-2-Footer'>


      <h4 className='heading-2-Content-Footer'>  
        Travel Modes
      </h4>  
      <ul className='ul-1-Footer'>
        <li>
          <button className='button-1-Footer'
            onClick={()=>{handleNavigateTravelModes("air")}}
          >
            Discover Airports
          </button>
        </li>
        <li>
          <button className='button-1-Footer'
            onClick={()=>{handleNavigateTravelModes("train")}}
          >
            Discover Railway Stations
          </button>
        </li>
        <li>
          <button className='button-1-Footer'
            onClick={()=>{handleNavigateTravelModes("bus")}}
          >
            Discover Bus Stands
          </button>
        </li>  
      </ul>



      <h4 className='heading-2-Content-Footer'>  
          Discover Travel Categories
      </h4>  
      <ul className='ul-1-Footer'>
        <li>
          <button className='button-1-Footer'
          onClick={()=>{handleNavigate_Hotels()}}
          >
            {/* Discover  */}
             Hotels
          </button>
        </li>
        <li>
          <button className='button-1-Footer'
            onClick={()=>{handleToastMessage("Comming Soon", "info")}}
          // onClick={()=>{handleNavigate_Restaurants()}}
          >
            {/* Discover  */}
             Restaurants
          </button>
        </li>
        <li>
          <button className='button-1-Footer'
            onClick={()=>{handleToastMessage("Comming Soon", "info")}}
          // onClick={()=>{handleNavigate_Bars_and_Pubs()}}
          >
            {/* Discover  */}
             Bars & Pubs
          </button>
        </li>  
        <li>
          <button className='button-1-Footer'
            onClick={()=>{handleToastMessage("Comming Soon", "info")}}
          // onClick={()=>{handleNavigate_Rent()}}
          >
            {/* Discover  */}
             Rent Vehicle Shops
          </button>
        </li>
        <li>
          <button className='button-1-Footer'
            onClick={()=>{handleToastMessage("Comming Soon", "info")}}
          // onClick={()=>{handleNavigate_AmusementParks()}}
          >
            {/* Discover  */}
             Amusement/Water Parks
          </button>
        </li>  
        <li>
          <button className='button-1-Footer'
            onClick={()=>{handleToastMessage("Comming Soon", "info")}}
          // onClick={()=>{handleNavigate_AdventureActivities()}}
          >
            {/* Discover  */}
             Adventure Activity Providers
          </button>
        </li>  
        <li>
          <button className='button-1-Footer'
            onClick={()=>{handleToastMessage("Comming Soon", "info")}}
          // onClick={()=>{handleNavigate_ShoppingMalls()}}
          >
            {/* Discover  */}
             Shopping Malls
          </button>
        </li>
        <li>
          <button className='button-1-Footer'
            onClick={()=>{handleToastMessage("Comming Soon", "info")}}
          // onClick={()=>{handleNavigate_ShoppingStreet()}}
          >
            {/* Discover  */}
             Local Shopping Markets
          </button>
        </li>
      </ul>

    </div>


    {/*//! #3  */}
    {/* <div className='container3_5_box-Home3'> */}
      
      <div className='container-3-Footer'>

        <div>
          <button 
            className='button-2-Footer'
            onClick={navigate_AboutUse}
          >
            About Us
          </button>
        </div>
        <div>
          <button className='button-2-Footer'
          // feedbackWindow, setFeedbackWindow
          onClick={()=>{
            if(userEmail){
              setFeedbackWindow(true)
              openPopup()
            }
            else{
              toast.error("Please login to give feedback.", {
                position: toast.POSITION.TOP_CENTER
              })
            }
          }}
          > 
            Give Feedback
          </button>  
        </div>

        <div>
          <button className='button-2-Footer'
          onClick={navigate_PrivacyPolicy}
          >
            Privacy Policy
          </button>
        </div>

        <h4 className='heading-2-Content-Footer'>  
          Social Media
        </h4>  

        <div className='container_p-mediaIcons-Footer'>

          <a target="_blank" 
          href="https://www.linkedin.com/company/prepare-trip/" >
            <AiFillLinkedin /> 
          </a> 

          <a target="_blank" href="https://www.facebook.com/people/Prepare-Trip/100089698937921/">
            <FaFacebook /> 
          </a> 

          <a target="_blank" 
          href="https://twitter.com/prepare_trip" >
            <BsTwitter />
          </a>       

          <a target="_blank" 
          href="https://www.instagram.com/prepare_trip/" >
            <AiFillInstagram />
          </a>      

          <a target="_blank" 
          href="https://www.youtube.com/@preparetrip" >
            <BsYoutube />
          </a>    

          <a target="_blank" 
          href="https://www.reddit.com/r/trulyexploreindia/" >
            <FcReddit />
          </a>    

        </div>

        
        <h4 className='heading-2-Content-Footer'>  
          Share us <RiShareForwardLine /> 
        </h4>  

        <div
        className='container_p-share-Footer'
        >

          <WhatsappShareButton url={shareUrl} onClick={()=>{handleUpdateShare("whatsapp", Moment(new Date()).format("YYYY-MM-DD hh:mm:ss A"))}}>

          <WhatsappIcon size={32} round={true}  />

          </WhatsappShareButton>

          <FacebookShareButton url={shareUrl} onClick={()=>{handleUpdateShare("facebook_messenger", Moment(new Date()).format("YYYY-MM-DD hh:mm:ss A"))}}>

          <FacebookMessengerIcon size={32} round={true}  />

          </FacebookShareButton>

          <TelegramShareButton url={shareUrl} onClick={()=>{handleUpdateShare("telegram", Moment(new Date()).format("YYYY-MM-DD hh:mm:ss A"))}}>

          <TelegramIcon size={32} round={true}/>

          </TelegramShareButton>

        </div>

      </div>

    {/* </div> */}

  </div>

  <div className='container4-Footer-Footer'>

    <img 
    className='logo-Home-Footer-Footer' 
    // src='https://i.ibb.co/Z8sybKy/FA-removebg-preview.png'
    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/prepare_trip_logo_1.png' 
    />
    <div className='container_p-copyrightText-Footer'>
    © 2024 Wandercult Preparetrip Private Limited. All rights reserved.
    </div>

  </div>

  <ToastContainer />

</footer>

  )
}

export default Footer