import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

//
import axios from 'axios';

//
import { Helmet } from 'react-helmet' 

//importing react context
//--global - PlanTripHome
import { useValidatePlanTrip } from '../Manage - Plan Trip/Context/ValidatePlanTrip';
import {useGlobalNavigationPlanTrip} from '../Manage - Plan Trip/Context/GlobalNavigationPlanTrip';
//--importing from TripPlanning - local
import { TravelCategories_CP } from '../Plan Trip - Components/Trip Planning/Manage/Context/TravelCategories_Context';



//manage google analytics tracking
import { initGA, trackPageView } from '../../Track Action/Google Tracking/GoogleTrackingAnalytics';
import { userAnalytics_customTrack_VisitsExits } from '../../Track Action/Custom Tracking/CustomTrackingAnalytics';
import {v4 as uuidv4} from 'uuid';



// import other plan trip components
import AboutDestination from '../Plan Trip - Components/About Destination/Main/AboutDestination';
import TripPlanning from '../Plan Trip - Components/Trip Planning/Main/TripPlanning';
import CreateItinerary from '../Plan Trip - Components/Create Itinerary/Main/CreateItinerary';
import TripOverview from '../Plan Trip - Components/Trip Overview/Main/TripOverview';
import PlanTripHeader from '../Plan Trip Header/Main/PlanTripHeader';
// import ManageLogin from '../../../../MoreOptions/ManageLogin';


//user credentitals - cookies 
import {getCookies_userCredentials, parseUserCredentialsData} from '../../../Cookies/LoginCredentitals'
import { TravelCategories_2_CP } from '../Plan Trip - Components/Trip Planning/Manage/Context/TravelCategories_2_Context';
import { TravelCategories_3_CP } from '../Plan Trip - Components/Trip Planning/Manage/Context/TravelCategories_3_Context';

//import css
import './PlanTripHome.scss'
import { Context_aboutDestinationPlanTrip } from '../Plan Trip - Components/About Destination/Manage/Context/AboutDestination_Context';
import { Context_tripOverviewPlanTrip } from '../Plan Trip - Components/Trip Overview/Manage/Context/TripOverview_Context';
import { Context_tripOverview_2_PlanTrip } from '../Plan Trip - Components/Trip Overview/Manage/Context/TripOverview_2_Context';
import { Context_createItinerary_PlanTrip } from '../Plan Trip - Components/Create Itinerary/Manage/Context/CreateItinerary_Context';
import { Context_createItinerary_2_PlanTrip } from '../Plan Trip - Components/Create Itinerary/Manage/Context/CreateItinerary_Context_2';
import { server_baseUrl } from '../../Universal Components/Server Side Hit Url/ServerHitUrlPart';
import ManageLogin from '../../../Manage User Credentials/User Login/ManageLogin';


//@@main function
function PlanTripHome() { 



    const location = useLocation();
    //!Track user visit 
    //
    useEffect(() => {
      initGA();
    }, []);
    //
    useEffect(() => {
      trackPageView(location.pathname);
    }, [location.pathname]);
  
    useEffect(() => {
      // Call this function once when the page is loaded
      const date = Date.now();
      const start_time = Math.floor(date / 1000)
  
      const random_number = Math.floor(Math.random() * 1000000); 
      const concatenatedString = `${date}-${random_number}`;
      const user_temp_id = uuidv4(concatenatedString);  
  
  
      userAnalytics_customTrack_VisitsExits(user_temp_id, userEmail, location.pathname, "enter", null);
  
      // Function to handle page exit
      const handleBeforeUnload = () => {
  
        const end_time = Math.floor(Date.now() / 1000);  // Convert milliseconds to seconds
  
        const stay_duration = end_time-start_time;
  
        userAnalytics_customTrack_VisitsExits(user_temp_id, userEmail, location.pathname, "exit", stay_duration);
      };
  
      // Add event listener to call page exit event when the user tries to close or navigate away
      window.addEventListener("beforeunload", handleBeforeUnload);
  
      // Clean up the event listener when the component is unmounted
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, []); // Empty dependency array ensures this effect runs only once per page load
  
    //!End*----




// using react context
const { 
    validate_city, validate_district, validate_state, stateName_2, setStateName_2, validate_stateMultiDistrict, validate_TripId, tripDestinationName, tripDestinationType, setTripDestinationType, setTripDestinationName, setDestinationLat, setDestinationLng, setDestinationZoom
} = useValidatePlanTrip();

const { activeFeature } = useGlobalNavigationPlanTrip();

const {tripId, tripName, stateName, districtName, cityName, shareTripId} = useParams();

const navigate = useNavigate()  

  const [userEmail, setUserEmail] = useState();
  const [loginStatus, setLoginStatus] = useState()

  //internal
  const [initialLoadDone, setInitialLoadDone] = useState(false)


  //##1   
  useEffect(() => {
    if(!shareTripId){
        handleGetUserLoginStatus();
    }
    // else{
        // loadBasicDetails();
    // }
  }, []) 



//   const loadBasicDetails = async () => {
//     const response = await axios.post(`${server_baseUrl}/csc/trip_overview/load/trip/details/data`, {shareTripId})

//     if(response.data !== "0"){
//       const {user_email, trip_id, state_name, destination_name, destination_type, destination_lat, destination_lng} = response.data[0];

//       tripId = trip_id;
//       stateName = state_name;
//       setUserEmail(user_email)
//       setTripDestinationName(destination_name)
//       setTripDestinationType(destination_type)
//       setDestinationLat(destination_lat) 
//       setDestinationLng(destination_lng) 
//     }
//     else{
//       alert("error in loading the trip data.");
//     }
//   }


  const handleGetUserLoginStatus = () => {
    let tempEmail;
    const userDataCookie = getCookies_userCredentials("userData");
    // console.log("userDataCookie", userDataCookie);
    if (userDataCookie) {
      const userData = parseUserCredentialsData(userDataCookie);
      if (userData) {
          setLoginStatus(userData.email);
          setUserEmail(userData.email);
          tempEmail = userData.email;
          // console.log("Login status:", userData.loginStatus, userData.email, userData.email);
      }
    } else {
      // console.log("User data cookie not found. - handleGetUserLoginStatus");
    }

    if(tripId){
        // console.log("useeffect - trip id was called", "userEmail", userEmail)
        validate_TripId(tripId, tempEmail, tripName);
    }

    setTimeout(() => {
        setInitialLoadDone(true);
    }, 4000);
  }



//##2 validate trip destination details 
useEffect(() => {
    // alert("shareTripId", shareTripId)
    if(!shareTripId){
    // console.log("tripId, tripName, stateName, districtName, cityName", tripId, tripName, stateName, districtName, cityName)
    validate_TripDestinationsData()
    }

}, [stateName, districtName, cityName])

const validate_TripDestinationsData = async() => {

    if(stateName || districtName || cityName){

        if(stateName && districtName && cityName){
            validate_city(stateName, districtName, cityName)
        }

        else if(stateName && districtName){
            validate_district(stateName, districtName)
        }

        else if(stateName){
            validate_state(stateName)
            validate_stateMultiDistrict(stateName);
        }

    }else{
        navigate('error-things_not_found/error_id-pth-0-001/resolve_1-click-on-home-page/resolve_2-share-error-id')
    }
}

// ##3 validate tripId and tripName 
useEffect(() => {
    if(!shareTripId && initialLoadDone && tripId){
        // console.log("useeffect - trip id was called", "userEmail", userEmail)
        validate_TripId(tripId, userEmail)
    }

}, [tripId, tripName, userEmail])


//##4 Main Navigation Component


const [isMobile, setIsMobile] = useState(window.innerWidth <= 899); // Initialize with the current width

useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 899); // Update state on window resize
  };

  window.addEventListener('resize', handleResize);

  // Cleanup event listener on unmount
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);



const upperElement = document.querySelector('.conatiner_p-PlanTripHeader-PlanTripHome');
const lowerElement = document.querySelector('.conatiner_p-OtherComponents-PlanTripHome');

if (upperElement && lowerElement) {
    const upperHeight = upperElement.offsetHeight;
    lowerElement.style.marginTop = `${upperHeight}px`; // Set units (px, rem, etc.)
}




//@@ main return
  return (
    <div>

<Helmet>

<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

<link href="https://fonts.googleapis.com/css2?family=Assistant:wght@200&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Maven+Pro&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap" rel="stylesheet" />

</Helmet>


        <div className='conatiner_p-PlanTripHome'>
            {/* ## plan trip navigation */}

            { 
                (!isMobile && !shareTripId) &&
                <div className='conatiner_p-PlanTripHeader-PlanTripHome'>
                    <div>
                        <PlanTripHeader />
                    </div>
                </div>
            }

            {
                (isMobile && !shareTripId) &&
                <div className='conatiner_p-mobile-PlanTripHeader-PlanTripHome'>
                    <div>
                        <PlanTripHeader />
                    </div>
                </div>
            }
            

            {/* temp */}
            {/* <div className='temp-PlanTripHome'></div> */}

            {/*## plan trip active components */}
            <div className='conatiner_p-OtherComponents-PlanTripHome'>

                <div>

                    {/*  */} 
                    {
                        tripDestinationName && tripDestinationType &&

                        <Context_aboutDestinationPlanTrip>
                            <div>
                                {activeFeature === "about" && <AboutDestination /> }
                            </div>
                        </Context_aboutDestinationPlanTrip>
                    }

                    {/* //!Trip Planning */}
                    {/* //?Tourist Places/ Main Navigation, and loading of places/content */}
                    <TravelCategories_CP> 
                    {/* //? handle loading of places/content for travel categories !== hotels :) */}
                    <TravelCategories_2_CP>
                    {/* //?handles anyting related directly with the loaded places/content */}
                    <TravelCategories_3_CP>  

                        <div>
                            {activeFeature === "trip_plan" && <TripPlanning /> } 
                        </div>

                    </TravelCategories_3_CP>
                    </TravelCategories_2_CP>    
                    </TravelCategories_CP>   

                    {/* //!Create Itinerary */}
                    <Context_createItinerary_PlanTrip>
                        <Context_createItinerary_2_PlanTrip>
                            <div className='component-CreateItinerary-PlanTripHome'>
                                {activeFeature === "create_itinerary" && <CreateItinerary /> }
                            </div>
                        </Context_createItinerary_2_PlanTrip>
                    </Context_createItinerary_PlanTrip>

                    {/* tripDestinationName && tripDestinationType && stateName <br></br>
                    {tripDestinationName} && {tripDestinationType} && {stateName} || {stateName_2} */}

                    {/* (stateName || stateName_2):: //for share trip: this state helps in setting the state, and preventing the confusion between the "stateName" from the params, and the state name in the validate trip */}
                    {/* //!Trip Overview */}
                    {
                        tripDestinationName && tripDestinationType && (stateName || stateName_2) &&
                        (
                            <Context_tripOverviewPlanTrip>
                                {/* //? user trip details, and anything except user saved places data, basically, the action saved in the TripPlanning component */}
                                {/* <TravelCategories_CP> */}
                                    {/*//? saved places, itinerary, and map  */}
                                    <Context_tripOverview_2_PlanTrip>    
                                    {/* <TravelCategories_3_CP> */}
                                        <div>
                                            {activeFeature === "trip_overview" && <TripOverview /> }
                                        </div>
                                    {/* </TravelCategories_3_CP> */}
                                    </Context_tripOverview_2_PlanTrip>
                                {/* </TravelCategories_CP> */}
                            </Context_tripOverviewPlanTrip>
                        )
                    }

                </div>

            </div>

            {/* ## login */}

        </div>

    </div>
  )
}

export default PlanTripHome


