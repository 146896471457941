import React, {useState, useEffect, useRef} from 'react'

//import css
import './DayWise_Mobile.scss';

//importing react context
import { useAiCreateItinerary } from '../../Manage/Context/Ai_CreateItineraryContext';
//?for view more touirst places data...
import { useAiCreateItinerary_2 } from '../../Manage/Context/Ai_CreateItineraryContext_2';

//import icons
import { RxDotsVertical } from "react-icons/rx";
import { RxDotsHorizontal } from "react-icons/rx";
import { IoMdCloseCircle } from "react-icons/io";
import {MdOutlineModeOfTravel} from "react-icons/md";
import { BsStars } from "react-icons/bs";
import { BiTrip } from "react-icons/bi";
import { Rating } from 'react-simple-star-rating';
import { PiMountainsFill } from "react-icons/pi";
import { RiPinDistanceFill } from "react-icons/ri";
import { MdOutlineTimeToLeave } from "react-icons/md";

import TouristPlaces_VM from '../../../../../Plan Trip/Plan Trip - Components/Trip Planning/Main/View More/TouristPlaces_VM';
import Map_Ai_Itinerary from '../Map/Map_Ai_Itinerary';


function DayWise_Mobile() {

  const {
    //
    getRoute_buttonStatus, setGetRoute_buttonStatus,
    //
    handle_CallGetRoute,
    //*itinerary sequence and route data
    optimizedItinerary, 
    optimizedItineraryRoute, 

    //*createTrip
    createTripId,
    tripName, setTripName, tripWindowStatus, setTripWindowStatus,
    handleViewTrip, handleCreateTrip,

    //
    handleActive_ItineraryResultClicked,

    //*Day Wise
    activeDay, setActiveDay,
    exploreDuration,
    travelDuration,
    stateName,
    destinationName,
    itineraryDuration,
    tripSeason,
    //? tourist places
    savedPlacesData,
    //map
    //map
    lat, lng,
    setHoverData,
    handleHover
  } = useAiCreateItinerary()

  const {
    //!States
    activeId_viewMore_touristPlaces, setActiveId_viewMore_touristPlaces,
    viewMoreData_About,
    viewMoreData_Review,
    viewMoreData_PlaceInfo,
    viewMoreData_touristPlaces,
    viewMoreLoading, setViewMoreLoading,
    //!Function(s)
    handle_ViewMore_TouristPlaces
  } = useAiCreateItinerary_2()

  const [dayIdIndex, setDayIdIndex] = useState([]);

  useEffect(() => {
    const indices = [];
    const daySet = new Set();

    optimizedItinerary.forEach((item, index) => {
      if (!daySet.has(item.day)) {
        daySet.add(item.day);
        indices.push(index);
      }
    });

    setDayIdIndex(indices);
  }, [optimizedItinerary]);


  const parseTimings = (data) => {
    // Remove the "Suggest new hours" part
    const cleanedData = data.replace('Suggest new hours', '');

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const regex = new RegExp(`(${days.join('|')})`, 'g');
    const parsedTimings = cleanedData.split(regex).filter(Boolean);

    // Combine the day names with their corresponding timings
    const formattedTimings = [];
    for (let i = 1; i < parsedTimings.length; i += 2) {
      formattedTimings.push(parsedTimings[i - 1] + ' ' + parsedTimings[i]);
    }

    return formattedTimings;
  };

  const[viewTimmingId, setViewTimmingId] = useState()

  const handleMouseOver = (index) => {
    // console.log("Hovered index:", index);
    setViewTimmingId(index);
  };


  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
      setTripWindowStatus(false);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  const convertToHoursAndMinutes = (decimalHours) => {
    const hours = Math.floor(decimalHours); // Extract the integer part (hours)
    const minutes = Math.round((decimalHours - hours) * 60); // Convert fractional part to minutes
    return `${hours} hour${hours !== 1 ? 's' : ''} and ${minutes} minute${minutes !== 1 ? 's' : ''}`;
  };

  const roundToTwoDecimals = (number) => {
    return number.toFixed(2); // Round to two decimal places and return as a string
  };
  
  const modified_state_name = stateName && stateName.toLowerCase().replace(/\s+/g, '_');


//!scroll - active on the map - place in the center

// const [hoverData, setHoverData] = useState(null);
const containerRef = useRef(null);
const containerRefs = useRef([]);

const isElementInCenter = (element, container) => {
  if (!container) return false;

  const rect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  
  // Calculate element's center relative to the container
  const elementCenter = (rect.left + rect.right) / 2 - containerRect.left;
  const containerWidth = containerRect.width;
  
  // Define center range relative to the container
  const centerThreshold = containerWidth * 0.25;
  const containerCenter = containerWidth / 2;
  
  return elementCenter >= (containerCenter - centerThreshold) && elementCenter <= (containerCenter + centerThreshold);
};

useEffect(() => {
  const handleScroll = () => {
    // if (!containerRef.current) return;

    containerRefs.current.forEach((element, index) => {
      if (isElementInCenter(element, containerRef.current)) {
        // Trigger hover when the element is in the center
        const placeData = savedPlacesData[index];
        handleHover("clicked", placeData.lat, placeData.lng, placeData.name, "iconName", index);
      } else {
        setHoverData(null); // Remove hover if not centered
      }
    });
  };

  const container = containerRef.current;
  if (container) {
    container.addEventListener('scroll', handleScroll);
  }

  return () => {
    if (container) {
      container.removeEventListener('scroll', handleScroll); // Cleanup
    }
  };
}, [savedPlacesData]);


  



  return (
    <div>


      <div 
        className='popup-background-1-Entities_TD'
        onClick={()=>{
          handleActive_ItineraryResultClicked("day_wise")
          closePopup()
        }}
      >
        {/* Close */}
      </div>

      <div className='popup_p-3-HowToReach_Mobile'>
      <div className='popup_s-addNote-HowToReach_Mobile'>



      {
        (lat && lng) &&
        <div
        className='container_p-map-DayWise_Mobile'
        >

          <div 
          className='container_s-map-DayWise_Mobile'
          >
            <Map_Ai_Itinerary />
          </div>

        </div>
      }

      
        <br></br>

        {/* <button
          className='button-close-DayWise_Mobile'
          onClick={()=>{
            handleActive_ItineraryResultClicked("day_wise")
            closePopup()
          }}
        >
          <IoMdCloseCircle />
        </button> */}

        <div
        ref={containerRef}
        className='conatiner_p-Places-DayWise_Mobile'
        // className='container_p-Main-Content-HowToReach_Mobile'
        >

          <button
            className='button-close-DayWise_Mobile'
            onClick={()=>{
              handleActive_ItineraryResultClicked("day_wise")
              closePopup()
            }}
          >
            <IoMdCloseCircle />
          </button>


          {
            (optimizedItinerary && savedPlacesData) && 
            optimizedItinerary.map((data, index) => {
              // if(data.day === activeDay){
                return(
                  <div key={index}
                  className='conatiner_s-Places-DayWise_Mobile'
                  > 

                    {/* {handleTid(data.day)} */}

                    {/* {
                      dayIdIndex.includes(index) &&

                      <div
                      className='heading-1-Day-DayWise_Mobile'
                      >
                        Day {data.day}
                      </div>
                    } */}

                    <div
                    className='container-Gap_Dist_Dur-DayWise_Mobile'
                    >

                      {/*//*Container #1  */}
                      {
                        index > 0 &&
                        <div
                        className='icon-dottedLine-Places-DayWise_Mobile'
                        >
                          <RxDotsHorizontal />
                        </div>
                      }


                      {optimizedItineraryRoute && 
                      
                        optimizedItineraryRoute.map((route, route_index) => {
                          if(index > 0 && route_index === index-1){
                            return(
                              <div 
                              className='container_p-Dist_Dur-DayWise_Mobile'
                              >
                                <div
                                className='container_t-1-Dist_Dur-DayWise_Mobile'
                                >
                                  <RiPinDistanceFill /> &nbsp;
                                  {roundToTwoDecimals(route.d1)} kms
                                </div>
                                
                                <div
                                className='container_t-2-Dist_Dur-DayWise_Mobile'
                                >
                                  <MdOutlineTimeToLeave /> &nbsp;
                                  {convertToHoursAndMinutes(route.dr1)}
                                </div>

                              </div>
                            )
                          }
                        })
                      }

                      {
                        index > 0 &&
                        <div
                        className='icon-dottedLine-Places-DayWise_Mobile'
                        >
                          <RxDotsHorizontal /> 
                        </div>
                      }

                    </div>

                    {
                      dayIdIndex.includes(index) &&

                      <div
                      className='heading-1-Day-DayWise_Mobile'
                      >
                        Day {data.day}
                      </div>
                    }

                    {/*//*Container #2  */}
                    {
                      <div
                      className='text-1-number-Places-DayWise_Mobile'
                      >
                        {index+1}.

                        {/* duration: {data.duration}  <br></br><br></br>
                              distance: {data.distance} <br></br><br></br> */}

                      </div>
                    }

                    {/*//*Container #3  */}
                    {
                      savedPlacesData.map((placeData, index2) => {
                        if(data.sq_source === placeData.sn){
                          return(
                            <div key={index2}
                            ref={(el) => (containerRefs.current[index2] = el)}
                            className='conatiner_p-Info-Places-DayWise_Mobile'
                            onMouseOver={()=>{handleHover("clicked", placeData.lat, placeData.lng, placeData.name, "iconName", index)}}
                            onMouseOut={()=>{setHoverData()}}
                            >

                              {/*//*Images and content #3.1 */}
                              <div
                              className='conatiner_s-1-Info-Places-DayWise_Mobile'
                              >

                                {/*//Images and ... #3.1.1 */}
                                <div
                                className='conatiner_t-1-Info-Places-DayWaise'
                                >

                                  {/* {`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${placeData.sn}-01.jpg`} */}

                                  <img 
                                    className="image-Card-Places-DayWise" 
                                    // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/create_itinerary_5.jpeg'

                                    src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${placeData.sn}-01.jpg`}
                                    onError={(e) => { e.target.src = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/create_itinerary_5.jpeg'; }}

                                    alt="Image 1" 
                                  />

                                  <div
                                  className='conatiner_t-ratings_review-SavedPlacesCI'
                                  >

                                    <div className='heading-rating-DayWise'>
                                      ({placeData.rating})
                                    </div>

                                    <div 
                                    className='heading-2-placeName-SavedPlacesCI'
                                    >
                                        <Rating
                                          initialValue={placeData.rating}
                                          size={18}
                                          emptyColor="#ccc"
                                          fillColor="gold"
                                          strokeColor="gold"
                                          strokeWidth={0}
                                          allowFraction={true}
                                          readonly
                                        />  
                                    </div>

                                    <div className='heading-3-placeName-SavedPlacesCI'>
                                      {
                                        placeData.review_count ? placeData.review_count.toLocaleString('en-IN') : 'N/A'
                                      }
                                    </div>

                                  </div>  

                                </div>

                                {/*//Images and ... #3.1.2 */}
                                <div
                                className='conatiner_t-2-Info-Places-DayWaise'
                                >
                                  <div className="heading-1-placeName-info-Places-DayWaise_Mobile">
                                    {placeData.name}
                                  </div>

                                  <div
                                  className='text-1-category-info-Places-DayWaise_Mobile'
                                  >
                                    #{placeData.category}
                                  </div>

                                  {
                                    (placeData.explore_duration && placeData.explore_duration != "") &&
                                    <div 
                                    className='text-1-exploreDuration-info-Places-DayWaise_Mobile'
                                    >
                                      Explore in: {placeData.explore_duration} hrs
                                    </div>
                                  }

                                  {
                                    placeData.timings &&
                                    <button
                                    onMouseOver={()=>{setViewTimmingId(index)}}
                                    onMouseOut={()=>{setViewTimmingId(null)}}
                                    className='button-viewTimming-DayWaise_Mobile'
                                    >
                                      view timmings
                                    </button>
                                  }
                                  
                                  {  viewTimmingId !== null && (viewTimmingId === index) && 
                                      placeData.timings &&
                                      <div
                                      className='container_p-timming-DayWaise'
                                      >

                                        <div
                                        className='heading-1-DayWaise'
                                        > 
                                          Timmings
                                        </div> 

                                        <div
                                        className='conatiner_t-timming-DayWaise'
                                        >
                                        {parseTimings(placeData.timings).map((timing, index) => (
                                            <div key={index}
                                            className='time-timming-DayWaise'
                                            >
                                                {timing}
                                            </div>
                                        ))}
                                        </div>

                                      </div> 
                                  }  


                                  {/*//View More  */}
                                  <button className="button-viewMore-card-DayWaise_Mobile" 
                                    onClick={(e) => {
                                      e.stopPropagation(); 
                                      handle_ViewMore_TouristPlaces(placeData.sn, stateName);
                                    }}>
                                        View More
                                  </button>

                                  {/* <div
                                  className='conatiner_s-2-Info-Places-DayWaise'
                                  >
                                    <button className="button-viewMore-card-SavedPlacesCI" 
                                    onClick={(e) => {
                                      e.stopPropagation(); 
                                      // handle_ViewMore_TouristPlaces(data.sn);
                                    }}>
                                        View More
                                    </button>
                                  </div> */}

                                </div>


                              </div>

                            </div>   
                          )
                        }
                      })
                    }
                  </div>   
                )
              // }
            })
          }
        </div>

       </div>
      </div>

      <div style={{width:'200px'}}></div>

      {
        viewMoreLoading && 
        <div className="loading-overlay">
          <div className="spinner">
            {/* You can add a loading spinner here */}
          </div>
        </div>
      }

      {
        activeId_viewMore_touristPlaces &&
    
        <TouristPlaces_VM 
          viewMoreData_touristPlaces={viewMoreData_touristPlaces}
          viewMoreData_About={viewMoreData_About}
          viewMoreData_Review={viewMoreData_Review}
          viewMoreData_PlaceInfo={viewMoreData_PlaceInfo}
          activeId_viewMore_touristPlaces={activeId_viewMore_touristPlaces}
          setActiveId_viewMore_touristPlaces={setActiveId_viewMore_touristPlaces}
          stateName={stateName}
        />

      }

    </div>
  )
}

export default DayWise_Mobile