import axios from "axios"

import { server_baseUrl } from "../../../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart"


export const loadDistrict_LatLng = async (districtName) => {
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/load_destination_data_01/load_district_lat_lng`, {districtName}) 

    // console.log("loadDistrict_LatLng", response.data, "districtName", districtName)

    if(response.data !== "0"){
        const result = response.data;   
        return result;
    }else{
        alert("error in with the initial load of the district. You can try again.")
    }
}


export const loadTouristPlaces_District = async (districtNamePassed, stateName, tripDestinationName, tripDestinationType) => {
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/load_destination_data_01/get_tourist_places`, {districtNamePassed, stateName, tripDestinationName, tripDestinationType}) 

    if(response.data !== "0"){  
        return response.data;
    }else{
        alert("error in loading tourist places for the district. You can try again.")
    }
}

export const loadPlaceData_City = async (stateName, tripDestinationType, tripDestinationName, under_km) => {

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/load_destination_data_01/get_tourist_places`, {stateName, tripDestinationType, tripDestinationName, under_km}) 

    if(response.data !== "0"){  
        return response.data;
    }else{
        alert("error in loading tourist places for the district. You can try again.")
    }
}

