import React, {useState, useEffect} from 'react'

//import css
import './CreateItinerary.scss'
import './CreateItinerary_Mobile.scss'

//import react context
import {useCreateItineraryPlanTrip} from '../Manage/Context/CreateItinerary_Context';

//import other Trip Planning Global react context
import {useValidatePlanTrip} from '../../../Manage - Plan Trip/Context/ValidatePlanTrip';
import {useGlobalNavigationPlanTrip} from '../../../Manage - Plan Trip/Context/GlobalNavigationPlanTrip';

//import internal components
import {ItineraryAI_CI, Itinerary_CI, Saved_Places_CI, Map_CI} from '../Manage/Export/Export_CreateItinerary';

//Login
import ManageLogin from '../../../../../Manage User Credentials/User Login/ManageLogin';

import { Helmet } from 'react-helmet'; 


function CreateItinerary() {

  
  //*use react conext
  const {
    //!States
    //Initial load - loading the saved itinerary data
    loadDataStatus_Itinerary,
    //Map
    activePlace_MapData,
    lat_CI, lng_CI, zoom_CI,
    //
    activeViewSavedType,
    //
    setFitAllLocations,
    
    //!Functions
    //Map
    handleMap_HoverClick,
    //
    handleActiveSavedType, 
    //useEffect
    loadSavedTripItinerary
    
  } = useCreateItineraryPlanTrip();
  
  //*using other Trip Planning context
  const {
    userEmail, tripId, tripName, tripDestinationName, tripDestinationType, 
    destinationLat, destinationLng
  } = useValidatePlanTrip();
  
  const {
    setCreateTripStatus
  } = useGlobalNavigationPlanTrip()
  
  //!Initial load
  useEffect(() => {
    if(!loadDataStatus_Itinerary && userEmail && tripId){
        loadSavedTripItinerary()
        // console.log("i was called!!")
    }
    else{
      // console.log("i was not called!!")
    }
  }, [userEmail, tripId])

  //!mobile navigation
  const[navigation_CI_Mobile, setNavigation_CI_Mobile] = useState("itinerary")

  //!
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 799); // Initialize with the current width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 799); // Update state on window resize
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <div>

<Helmet>

<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Assistant:wght@200&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Maven+Pro&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />
<link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

</Helmet>

      { !isMobile &&
        (userEmail && tripId) && 

        <div className='container_p-CreateItinerary'>    
          
          {/*//! Container 1 */}
          <div className='container_p-LeftConatiner-CreateItinerary'>
          </div> 

          {/*//! Container 2 */}
          {
            loadDataStatus_Itinerary &&
            <div className='container_p-MainItineraryContainer-CreateItinerary'>
              <Itinerary_CI />
            </div>
          }

          {/*//! Container 3 */}
          <div className='container_p-Map_SavedPlaces-CreateItinerary'>
          
            <div className='fixedContainer-Map_SavedPlaces-CreateItinerary'>

              <div className='container_p-NavigationButtons-Map-CreateItinerary'>
                
                <div>
                  <button onClick={()=>{
                    handleActiveSavedType("map")
                    setFitAllLocations(true)
                  }}
                  className={activeViewSavedType === "map" ? 'button-active-MapNavigation-CreateItinerary' : 'button-unActive-MapNavigation-CreateItinerary'}
                  >
                    Map
                  </button>
                </div>

                <div>
                  <button onClick={()=>{
                    handleActiveSavedType("saved_places")
                    setFitAllLocations(true)
                  }}
                  className={activeViewSavedType === "saved_places" ? 'button-active-MapNavigation-CreateItinerary' : 'button-unActive-MapNavigation-CreateItinerary'}
                  >
                    Saved Places 
                  </button>
                </div>
                
              </div>    

              {
                activeViewSavedType === "saved_places" &&
                <div className='component-SavedPlaces-CreateItinerary'>
                  <Saved_Places_CI /> 
                </div>  
              }
              {
                activeViewSavedType === "map" && 
                lat_CI && lng_CI && zoom_CI 
                &&
                  <div className='component-Map-CreateItinerary'>
                    <Map_CI />
                  </div>  
              }

            </div>
          </div>   

        </div>
      }


      {/*//! Mobile  */}
      { userEmail &&
        isMobile &&
        <div
        className='container_p-navigation_CI-CreateItinerary_Mobile'
        >
          <div
          className='container_s-navigation_CI-CreateItinerary_Mobile'
          >
            <button
            onClick={()=>{
              setNavigation_CI_Mobile("itinerary")
              setFitAllLocations(true)
            }}
            className={navigation_CI_Mobile === "itinerary" ? 'button-active-navigation_CI-CreateItinerary_Mobile' : 'button-unActive-navigation_CI-CreateItinerary_Mobile'}
            >
              Itinerary
            </button>

            <button
            onClick={()=>{
              setNavigation_CI_Mobile("map")
              setFitAllLocations(true)
            }}
            className={navigation_CI_Mobile === "map" ? 'button-active-navigation_CI-CreateItinerary_Mobile' : 'button-unActive-navigation_CI-CreateItinerary_Mobile'}
            >
              Map
            </button>

            <button
            onClick={()=>{setNavigation_CI_Mobile("saved_places")}}
            className={navigation_CI_Mobile === "saved_places" ? 'button-active-navigation_CI-CreateItinerary_Mobile' : 'button-unActive-navigation_CI-CreateItinerary_Mobile'}
            >
              Saved Places
            </button>
          </div>  
        </div>
      }

      { isMobile &&
        (userEmail && tripId) && 

        <div className='container_p-CreateItinerary'>  

          {/*//! Container 2 */}
          { navigation_CI_Mobile === "itinerary" &&
            loadDataStatus_Itinerary &&
            <div className='container_p-MainItineraryContainer-CreateItinerary'>
              <Itinerary_CI />
            </div>
          }

          {/*//! Container 3 */}
          <div className='container_p-Map_SavedPlaces-CreateItinerary'>  

              { navigation_CI_Mobile === "saved_places" &&
                // activeViewSavedType === "saved_places" &&
                <div className='component-SavedPlaces-CreateItinerary'>
                  <Saved_Places_CI /> 
                </div>  
              }
              { navigation_CI_Mobile === "map" &&
                // activeViewSavedType === "map" && 
                lat_CI && lng_CI && zoom_CI 
                &&
                  <div className='component-Map-CreateItinerary'>
                    <Map_CI />
                  </div>  
              }

          </div>   

        </div>
      }


      {/* //!not loggedin */}
      {/* {
        !userEmail &&
        <div> 
          Login to veiw your trip
          <button>
            <ManageLogin />
          </button>
        </div> 
      } */}
      
      {/* //!trip not created*/}
      {
        !userEmail &&
        <div
        className='conatiner_p-notLogin-CreateItinerary'
        onClick={()=>{setCreateTripStatus(true)}}
        > 

          <button 
          onClick={()=>{setCreateTripStatus(true)}}
          className='button-heading-1-CreateItinerary'
          style={{padding:'6px', borderRadius:'8px'}}
          >
             <div
              className='heading-login-PlanTripHeader'
              >
                  Login to view/create your Itinerary
              </div>    
              {/* <div
              className='container_t-login-PlanTripHeader'
              > */}
                  <ManageLogin />
              {/* </div> */}
          </button>

          <img 
          src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/create_itinerary_5.jpeg'
          className='image-notLogin-CreateItinerary'
          />
      
        </div> 
      }


      {/* //!trip not created*/}
      {
        userEmail && !tripId &&
        <div
        className='conatiner_p-notLogin-CreateItinerary'
        onClick={()=>{setCreateTripStatus(true)}}
        > 

          <button 
          onClick={()=>{setCreateTripStatus(true)}}
          className='button-heading-1-CreateItinerary'
          >
            Create Your Trip 
          </button>

          <img 
          src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/create_itinerary_5.jpeg'
          className='image-notLogin-CreateItinerary'
          />
      
        </div> 
      }

      <div
      className='footer-bottomSpace-CreateItinerary'
      ></div>


    </div>
  )
}

export default CreateItinerary