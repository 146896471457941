  //@@ queries

   // first prompt
   export const get_prompt_1 = [
    "Select a travel mode",
    "Select your prefrence",
    "Choose the location you'd like to travel to:"
  ];

  
  // second propmt
  export const get_prompt_2 = [
    "Select the destination you wish to travel",
    "Enter the destination:",
    "Select city/district/states:",
    "Where do you wish to travel?",
    "Let's goooo! Tell me your destination."
  ];


 
