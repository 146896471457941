import React from 'react'

//import scss
import './About_D.scss'

//importing react context
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip'
import { useAboutDestinationPlanTrip } from '../../Manage/Context/AboutDestination_Context'


function About_D() {

// using react context
const {
    destinationLat, destinationLng, destinationZoom, tripDestinationName, tripDestinationType
} = useValidatePlanTrip()

const {
    //Overview
    overviewPlaceData, aboutPlaceData, foodPlaceData, mustKnowThingsPlace, travelModesData,
} = useAboutDestinationPlanTrip()

 
  return (
    <div className='conatiner_p-About_D'>


    {/* //* About */}

    { overviewPlaceData && (

    <div>

        {/* overview design */}
        {/* <div className='conatiner_p-overview-header-About_D'>

            <img    
            className='image-1-overview-header-About_D'
            src='https://www.tripsavvy.com/thmb/OsKgvjBK0s0MehTtrxOaSjQvgJA=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-515750293-56a3c4095f9b58b7d0d3a097.jpg' 
            />

            <img
            className='image-1-overview-header-About_D'
            src='https://dynamic-media-cdn.tripadvisor.com/media/photo-o/17/2c/33/5f/at-the-time-of-summers.jpg?w=500&h=-1&s=1' 
            />

            <img
            className='image-1-overview-header-About_D'
            src='https://elginhall.com/blog/wp-content/uploads/2022/07/1-DALHOUSIE.png' 
            />

        </div>      */}

        <div className='conatiner_p-Overview-About_D'>
            {
            overviewPlaceData.map((data, index) => {
             
                return(   
                 <div key={index}>   

                    {/* <div className='heading-0-Overview-About_D'>
                        About {tripDestinationName}
                    </div> */}

                    {
                        aboutPlaceData.map(dataAbout => {
                            return(
                                <ul>
                                    <li 
                                    className='content-1-Overview-About_D' dangerouslySetInnerHTML={{ __html: dataAbout.content.replace(/\n/g, '<br />') }} />
                                    <br></br>
                                </ul>    
                            )
                        })
                    }

                    {/* //!History */}
                    {/* history design */}
                    <div className='conatiner_p-history-header-About_D'>

                        <img
                        className='image-1-history-header-About_D'
                        src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/history_image_2.jpeg' 
                        />

                    </div>  

                    <div
                    className='heading-history-Overview-About_D'
                    >
                        History
                    </div>


                    <div className='content-1-Overview-About_D' >   
                        {data.content_history.replace(/- /g, '')}
                    </div>

                    {/* //!Attraction Overview */}

                    {/* attractions design */}
                    <div className='conatiner_p-history-header-About_D'>

                        <img
                        className='image-1-history-header-About_D'
                        src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/tourist_attractions_image_2.jpeg' 
                        />

                    </div>  

                    <div
                    className='heading-attractions-Overview-About_D'
                    >
                        Attractions 
                    </div>

                    <div className='content-1-Overview-About_D' dangerouslySetInnerHTML={{ __html: data.content_attractions.replace(/- |\n/g, '<br />') }} />

                    {/* //!Climate Overview */}

                    {/* cliamte design */}
                    <div className='conatiner_p-history-header-About_D'>

                        <img
                        className='image-1-history-header-About_D'
                        src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/climate_image_3.jpeg' 
                        />

                    </div>  
                    <div
                    className='heading-climate-Overview-About_D'
                    >
                        Climate 
                    </div>

                    <div className='content-1-Overview-About_D' >   
                        {data.content_climate.replace(/- /g, '')}
                    </div>

                 </div>   
                )
            })
            }
        </div> 

    </div>
    )}


    {/* //* Food Data */}

    { foodPlaceData && (

        <div className='box1-OverViewPlace'>

            {/* Food design */}
            <div className='conatiner_p-history-header-About_D'>

            <img
            className='image-1-history-header-About_D'
            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/food_image_2.jpeg' 
            />

            </div>  

            <div className='heading-food-About_D'>
                Famous Things to Eat in {tripDestinationName}
            </div> 

            <div className='container_t-food-About_D'>
                {
                foodPlaceData.map((data, index) => {
                
                    return(   
                    <div key={index}
                    className='card-food-About_D'
                    >      
                        <div
                        className='heading-3-Overview-About_D'
                        >
                            {data.dish_name} ({data.dish_type})
                        </div>   

                        <div
                        className='content-2-Overview-About_D'
                        >
                            {data.dish_brief_description}
                        </div>     

                    </div>   
                    )
                })
                }
            </div> 

        </div>
    )}




    {/* //* Must Know Things Data */}

    { mustKnowThingsPlace && (

        <div className='box1-OverViewPlace'>

            {/* Food design */}
            <div className='conatiner_p-history-header-About_D'>

                <img
                className='image-1-history-header-About_D'
                src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/must_know_things_2.jpeg' 
                />

            </div>  

            <div className='heading-mustKnow-About_D'>
                Must Know Things in {tripDestinationName}
            </div> 

            <div className='famousPlacesButtons-box3-1'>
                {
                mustKnowThingsPlace.map((data, index) => {
                
                    return(   
                    <ul key={index}>   
                        <li
                        className='content-1-Overview-About_D' 
                        >     
                            <div 
                            className='heading-2-Overview-About_D'
                            >
                                {data.content_heading}: 
                            </div>
                            <div 
                            className='content-2-2-Overview-About_D' 
                            >
                                {data.content}
                            </div>
                        </li>

                    </ul>   
                    )
                })
                }
            </div> 

        </div>
    )}



    {/* //* Travel Modes */}

    { travelModesData && (

        <div className='box1-OverViewPlace'>

            {/* Travel Modes design */}
            <div className='conatiner_p-history-header-About_D'>

            <img
            className='image-1-history-header-About_D'
            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/travel_modes_image_1.jpeg' 
            />

            </div>  

            <div className='heading-travelMode-About_D'>
                Explore Travel Modes in {tripDestinationName}
            </div> 
            <div className='heading-1-Overview-About_D'>
                {
                travelModesData.map((data, index) => {
                
                    return(  
                    <ul key={index}>   
                        <li
                        className='content-1-Overview-About_D' 
                        >     
                            <div 
                            className='heading-2-Overview-About_D'
                            >
                                {data.content_heading}: 
                            </div>
                            <div 
                            className='content-2-2-Overview-About_D' 
                            >
                                {data.content}
                            </div>
                        </li>
                    </ul>    
                    )
                })
                }
            </div> 
        </div>
    )}

    </div>
  )
}

export default About_D