import React from 'react'

// import scss
import './Review_VM.scss'

//
import ReactMarkdown from 'react-markdown';

//import react icon
import { FcGoogle } from "react-icons/fc";

function Review_VM(props) {

  const{viewMoreData_PlaceInfo, activeId_viewMore_touristPlaces, viewMoreData_Review, viewMoreData_About} = props;

  return (
    <div>
  
      <div> 

      {
        viewMoreData_PlaceInfo.map((placeData, index) => {
          if(placeData.id === activeId_viewMore_touristPlaces){
            return(
              <div
              style={{alignItems:'start', textAlign:'start', marginLeft:'4.6%'}}
              >

                <div
                className='heading-1-data-Review_VM'
                style={{fontSize:'18px'}}
                >
                    {/* # Top Tags & Keywords */}
                    # Key Highlights
                </div>  

                <div
                className='conatiner_p-keywords-Review_VM'
                >

                  {
                    viewMoreData_About.map((aboutData, index) => {
                      if(aboutData.id === activeId_viewMore_touristPlaces && aboutData.brief_about){
                        return(
                          <div key={index}
                        className='heading-keywords-Review_VM'
                        > 
                            #{aboutData.content}
                        </div>   
                        )
                    }
                    })
                  }

                  { placeData.review_main_keywords &&
                    JSON.parse(placeData.review_main_keywords).map((keywords, index) => {
                      if(index > 0){
                        return(
                        <div
                        className='heading-keywords-Review_VM'
                        >
                          #{keywords.keyword}
                        </div>   
                        )
                      }
                    })
                  }
                  
                </div> 

              </div>
            )
          }
        })
      }  

      </div> 

      <div
      className='conatiner_p-data-Review_VM'
      >
        <div
        className='heading-1-data-Review_VM'
        style={{fontSize:'18px', marginLeft:'4.6%'}}
        >
          User Reviews
        </div>
        {
          viewMoreData_Review.map((reviewData, index) => {
            if(reviewData.id === activeId_viewMore_touristPlaces){
              return(
                <div key={index}
                className='conatiner_s-data-Review_VM'
                >

                  {/*//? reviewer info */}
                  <div
                  className='conatiner_p-reviewer-data-Review_VM'
                  >
                    <div
                    className='heading-1-data-Review_VM'
                    >
                      <i>review from</i>
                    </div> 
                    <a
                    className='link-1-data-Review_VM'
                    href={reviewData.reviewer_profile_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                      {reviewData.reviewer_name}
                    </a>  
                    <div
                    className='icon-1-data-Review_VM'
                    >
                      <FcGoogle />
                    </div> 
                  </div>

                  {/*//? Content */}
                  <div
                  className='heading-2-data-Review_VM'
                  >
                    {reviewData.review_text}
                  </div>  

                  {/*//? meta data */}
                  <div
                  className='conatiner_p-metaData-data-Review_VM'
                  >
                    {
                    JSON.parse(reviewData.review_meta_info).map((metaData, index) => {
                      return(
                        <div key={index}
                        className='conatiner_s-metaData-data-Review_VM'
                        >
                          {metaData.heading}: {metaData.data}
                        </div>   
                      )
                    })
                    }
                  </div> 

                </div>   
              )
            }
          })
        }
      </div>  
    </div>
  )
}

export default Review_VM