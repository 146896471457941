import React, { useState, useEffect } from 'react';

import axios from "axios" 

//import css
import './TouristPlaces.scss'

//icons
import { IoMdCloseCircle } from "react-icons/io";


import Famous from './Components/Place Cards/Famous';
import Interesting from './Components/Place Cards/Interesting';
import MustVisit from './Components/Place Cards/MustVisit';

//
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

//importing react context
//--TravelCategories_Context
import {TravelCategories_Context} from '../../../Manage/Context/TravelCategories_Context'
import {TravelCategories_2_Context} from '../../../Manage/Context/TravelCategories_2_Context'
import {TravelCategories_3_Context} from '../../../Manage/Context/TravelCategories_3_Context'
//--global components
import {useValidatePlanTrip} from '../../../../../Manage - Plan Trip/Context/ValidatePlanTrip'
import Map from '../../Map/Map';



function TouristPlaces() {
    
    const mustVisit_Test = () => {
        // console.log("mustVisit_touristPlaces_data", mustVisit_touristPlaces_data)
    }

    const test = () => {
        // console.log("mustVisit_touristPlaces_data", mustVisit_touristPlaces_data)
    }

    //get states and functions from react context
    
    const {tripDestinationType, cityName, districtName, stateName, tripDestinationName, stateMultipleDistricts, tripId} = useValidatePlanTrip();

    const { 
        initialLoad_status_touristPlaces,  initialLoad_TouristPlaces_State, initialLoadTouristPlaces_District,
        initialLoadTouristPlaces_City,
        navigate_activeTouristPlacesType, setNavigate_activeTouristPlacesType,
        saved_touristPlaces_data, setSaved_touristPlaces_data,
        activeTouristPlaceNavigation, activeCategories_touristPlaces, handle_AllPlaces_TP, handle_ByCategories_TP, handle_Destination_TP, handle_UnderKm_TP, loadTouristPlaces_City, touristPlaces_underDistance, categories_touristPlaces, selectedCategory_touristPlaces, handleChange_Category_TouristPlaces, mustVisit_touristPlaces_data, famous_touristPlaces_data, interesting_touristPlaces_data, activeDestination, setActiveTouristPlaceNavigation,
        categories_touristPlaces_data, setCategories_touristPlaces_data,
        testFun,

        //
        lat, lng
    } = TravelCategories_Context();

    const {districtCity_data, handleLoad_CityDestination_Data, manage_DestinationsClick_InNearby, cityDestinationsLoaded_nonState_status} = TravelCategories_2_Context();

    const {
        handle_Save_Places, handle_UnSave_Places,
        handle_Click_TouristPlaces, handle_MouseOver_TouristPlaces, handle_MouseOut_TouristPlaces, handle_ViewMore_TouristPlaces
    } = TravelCategories_3_Context();


    const openPopup = () => {
        document.body.style.overflow = 'hidden'; // Disable scrolling on the body
    };

    const closePopup = () => {
    document.body.style.overflow = 'auto'; // Enable scrolling on the body
    };

    
    useEffect(() => {
        // alert("i was called - ")
        if(!initialLoad_status_touristPlaces){
            if(tripDestinationType === "state"){
                initialLoad_TouristPlaces_State();
            }
            else if(tripDestinationType === "district"){
                initialLoadTouristPlaces_District();
                // alert("i was called too!! ")
            }
            else if(tripDestinationType === "city"){
                initialLoadTouristPlaces_City();
            }
            else{
                alert("Opps! Some error. You can try again.")
            }
        }
        
    }, [])

    //
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 849);

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 849); // Update state on window resize
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup event listener on unmount
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

   const[mobile_viewMapStatus_TP, setMobile_viewMapStatus_TP] = useState(false)


  return (
    <div>
{/* 
<MustVisit 
                        mustVisit_touristPlaces_data={mustVisit_touristPlaces_data}
                        passedZeros_forUnique={"0"}
                        stateName={stateName}
                    /> */}

        {
            isMobile &&
            <div
            className='container_p-view_hotels-Hotels_Mobile'
            >
                <button
                // className='continer_p-0-Hotels'
                className='button-view_hotels-Hotels_Mobile'
                onClick={()=>{
                    setMobile_viewMapStatus_TP(true)
                }}
                >
                  <img 
                  src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/map_view_hotels-1.png'
                  className='img-view_hotels-Mobile-TouristPlaces'
                  />
                </button>
                <button
                    className='button-2-view_hotels-Hotels_Mobile'
                    onClick={()=>{
                        toast.success("Use a laptop or a pc to get a premium view.", {
                            position: toast.POSITION.TOP_CENTER
                          })
                    }}
                >
                    View Map & Places
                </button>
            </div>            
        }

        {(isMobile && mobile_viewMapStatus_TP && lat && lng) &&
          <div className='continer_p-Map-Mobile-TouristPlaces'> {/*div-2*/}

            <button
              className='button-close-Mobile-TouristPlaces'
              onClick={()=>{
                setMobile_viewMapStatus_TP(false)
                closePopup()
              }}
            >
              <IoMdCloseCircle />
            </button>

            <div className='continer_s-Map-Mobile-TouristPlaces'>
              <Map />
            </div>

          </div> 
        }

        <div className='conatiner_p-heading-TravelCategory-TouristPlaces'>
            <div className='heading-TravelCategory-TouristPlaces'>
                Tourist Places
            </div>    
        </div>

        <div className='container_p-Navigation-TouristPlaces'>
            <button onClick={()=>{handle_AllPlaces_TP("all_places")}}
            className={activeTouristPlaceNavigation === "all_places"  ? 'heading-active-Navigation-TouristPlaces' :
            'heading-unActive-Navigation-TouristPlaces'
            }
            >
                Discover Places
            </button> 

            <button onClick={()=>{handle_ByCategories_TP("categories")}}
            className={activeTouristPlaceNavigation === "categories"  ? 'heading-active-Navigation-TouristPlaces' :
            'heading-unActive-Navigation-TouristPlaces'
            }
            >
                by Categories
            </button>

            <button onClick={()=>{
                if (tripDestinationType === "state" || !cityDestinationsLoaded_nonState_status) {
                    manage_DestinationsClick_InNearby(
                        tripDestinationType,
                        activeDestination,
                        cityName,
                        districtName,
                        stateName
                    );
                }
                setActiveTouristPlaceNavigation("destinations");
            }}
            className={activeTouristPlaceNavigation === "destinations"  ? 'heading-active-Navigation-TouristPlaces' :
            'heading-unActive-Navigation-TouristPlaces'
            }
            >
                Destinations    
            </button>

        </div>

        <div>
        {
            (tripDestinationType === "city" && (activeTouristPlaceNavigation === "all_places" || activeTouristPlaceNavigation === "categories")) &&
            (
                <div>
                    <button onClick={()=>{handle_UnderKm_TP("under_6")}}
                    className={touristPlaces_underDistance === "under_6"  ? 'button-active-underKm-PlaceCards04' : 'button-unactive-underKm-PlaceCards04'}>
                        Under 6 km
                    </button>

                    <button onClick={()=>{handle_UnderKm_TP("under_12")}}
                    className={touristPlaces_underDistance === "under_12"  ? 'button-active-underKm-PlaceCards04' : 'button-unactive-underKm-PlaceCards04'}>
                        Under 12 km
                    </button>

                    <button onClick={()=>{handle_UnderKm_TP("under_25")}}
                    className={touristPlaces_underDistance === "under_25"  ? 'button-active-underKm-PlaceCards04' : 'button-unactive-underKm-PlaceCards04'}>
                        Under 25 km
                    </button>
                </div>    
            )
        }
        </div>

        <br></br>

            { 
                activeTouristPlaceNavigation === "categories"  && 
                (
                <div className='container_p-Categories-TouristPlaces'>

                    <div className='container_s-Categories-TouristPlaces'>
                
                    {        
                    activeCategories_touristPlaces.map((data, index) => {
                        return(
                            <div className='conatiner_t-SingleCategory-TouristPlaces'>
                                {
                                    data.category === selectedCategory_touristPlaces ? 

                                    (
                                        <button onClick={() => handleChange_Category_TouristPlaces(data.category)}
                                        className='button-active-Category-TouristPlaces'
                                        >
                                        {data.category}
                                        </button>
                                    )

                                    :

                                    (
                                        <button onClick={() => handleChange_Category_TouristPlaces(data.category)}
                                        className='button-unActive-Category-TouristPlaces'
                                        >
                                        {data.category}
                                        </button>
                                    )
                                }
                            </div>    
                        )
                    })
                    }
                
                    </div>
                </div>
                )
            }
        
        {/* ## tourist places */} 

        {
            (isMobile && activeTouristPlaceNavigation === "all_places") &&
            <div>
                {
                    (mustVisit_touristPlaces_data && mustVisit_touristPlaces_data.length > 0) &&
                    <button
                    onClick={()=>{setNavigate_activeTouristPlacesType("must_visit")}}   
                    className={navigate_activeTouristPlacesType === "must_visit" ? 'button-active-navigateTouristPlaces-TouristPlaces' : 'button-unActive-navigateTouristPlaces-TouristPlaces'}
                    >
                        Must Visit Places
                    </button>
                }
                {
                    (famous_touristPlaces_data && famous_touristPlaces_data.length > 0) &&
                    <button
                    onClick={()=>{setNavigate_activeTouristPlacesType("famous")}}
                    className={navigate_activeTouristPlacesType === "famous" ? 'button-active-navigateTouristPlaces-TouristPlaces' : 'button-unActive-navigateTouristPlaces-TouristPlaces'}
                    >
                        Famous Places
                    </button>
                }
                {
                    (interesting_touristPlaces_data && interesting_touristPlaces_data.length > 0) &&
                    <button
                    onClick={()=>{setNavigate_activeTouristPlacesType("interesting")}}
                    className={navigate_activeTouristPlacesType === "interesting" ? 'button-active-navigateTouristPlaces-TouristPlaces' : 'button-unActive-navigateTouristPlaces-TouristPlaces'}
                    >
                        Interesting Places
                    </button>
                }
            </div>
        }

      {
        activeTouristPlaceNavigation !== "destinations" &&
        (

        <div>

        { ((activeTouristPlaceNavigation === "categories") && (!isMobile || (isMobile && navigate_activeTouristPlacesType === "must_visit")) && categories_touristPlaces_data &&categories_touristPlaces_data.length > 0) && 
           (
            <div>

                {/* <div className='container_t-placeType-TouristPlaces'> 
                    <div className='heading-placeType-TouristPlaces'> 
                        Must Visit Places
                    </div>
                </div>  */}

                <div>

                    <MustVisit 
                        mustVisit_touristPlaces_data={categories_touristPlaces_data}
                        //for the number on the map
                        passedZeros_forUnique={"0"}
                        stateName={stateName}
                    />
                </div>
            </div>    
           ) 
        }

        { ((activeTouristPlaceNavigation === "all_places") && (!isMobile || (isMobile && navigate_activeTouristPlacesType === "must_visit")) && mustVisit_touristPlaces_data &&mustVisit_touristPlaces_data.length > 0) && 
           (
            <div>

                <div className='container_t-placeType-TouristPlaces'> 
                    <div className='heading-placeType-TouristPlaces'> 
                        Must Visit Places
                    </div>
                </div> 

                <div>

                    <MustVisit 
                        mustVisit_touristPlaces_data={mustVisit_touristPlaces_data}
                        //for the number on the map
                        passedZeros_forUnique={"0"}
                        stateName={stateName}
                    />
                </div>
            </div>    
           ) 
        }

        {
            ((activeTouristPlaceNavigation === "all_places") &&(!isMobile || (isMobile && navigate_activeTouristPlacesType === "famous")) && famous_touristPlaces_data &&famous_touristPlaces_data.length > 0) &&
           (
            <div>
                  
                <div className='container_t-placeType-TouristPlaces'> 
                    <div className='heading-placeType-TouristPlaces'> 
                        Famous Places
                    </div>
                </div> 

                <div 
                // className='container_p-2-cards-TouristPlace-MustVisit'
                >
                    <MustVisit 
                        mustVisit_touristPlaces_data={famous_touristPlaces_data}
                        passedZeros_forUnique={"00"}
                        stateName={stateName}
                    />
                </div>
            </div>    
           ) 
        }

        {
            ((activeTouristPlaceNavigation === "all_places") &&(!isMobile || (isMobile && navigate_activeTouristPlacesType === "interesting")) && interesting_touristPlaces_data &&interesting_touristPlaces_data.length > 0) &&
           (
            <div>

                <div className='container_t-placeType-TouristPlaces'> 
                    <div className='heading-placeType-TouristPlaces'> 
                        Interesting Places
                    </div>
                </div> 

                <div
                // className='container_p-2-cards-TouristPlace-MustVisit'
                >
                    <MustVisit 
                        mustVisit_touristPlaces_data={interesting_touristPlaces_data}
                        passedZeros_forUnique={"000"}
                        stateName={stateName}
                    />
                </div>                        
            </div>    
           ) 
        }

    <div style={{height:'200px'}}></div>
    
        </div>
        )}
        {
            (activeTouristPlaceNavigation === "destinations" && districtCity_data.length > 0) &&
            (   
                <div>
                    <div>
                        {
                            tripDestinationType === "city" ?
                            <div className='text-destination-TouristPlaces'>
                                Near by City/Towns
                            </div> 
                            : 
                            <div className='text-destination-TouristPlaces'>
                                Explore City/Town in {tripDestinationName}
                            </div>        
                        }
                    </div>
                    <div>
                        {
                            districtCity_data.map((data, index) => {
                                if((tripDestinationType === "state" && data.destination === activeDestination) || (tripDestinationType === "district" || tripDestinationType === "city")){
                                return(
                                    <button
                                    className='container_s-Destinations-TouristPlaces'
                                    onClick={()=>handle_Click_TouristPlaces(data.id, data.lat, data.lng)}
                                    onMouseOver={()=>handle_MouseOver_TouristPlaces(data.city, data.lat, data.lng)}
                                    onMouseOut={()=>handle_MouseOut_TouristPlaces()}
                                    >
                                        {data.city}
                                    </button>    
                                )
                                }
                            })
                        }
                    </div>   

                    <div style={{height:'200px'}}></div>

                </div>
            )
        }
           <ToastContainer />
    </div>
  )
}

export default TouristPlaces






