import React, {useState} from 'react'

//import css
import './AddNotes_TO.scss'
import './AddNotes_TO_Popup.scss'


//importing context form Internal Trip Overview
import {useTripOverviewPlanTrip} from '../../Manage/Context/TripOverview_Context'

//import data
import { notesHeadingIdeas, notesEmojis } from '../../Manage/Data/Data_1_TripOverview'
import TipTap_ReadOnly from '../../../../Global Tool - Plan Trip/Notes/TestingLexical/TipTap_ReadOnly'
import Notes_Tool from '../../../../Global Tool - Plan Trip/Notes/Notes_Tool'

//import icons
import { IoMdCloseCircle } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
// import { FaRegHandPointer } from "react-icons/fa6";

function AddNotes_TO(props) {

  const {tripDestinationName, shareTripId} = props;

  // use react context
  const {
    test,
    //!States
    //*notes data
    //--data
    notesData,
    //--window/popup
    notesWindowStatus, setNotesWindowStatus,
    editNotesStatus, setEditNotesStatus,  
    //
    trackNotesId, setTrackNotesId, 
    activeNotesId, setActiveNotesId,
    activeNotesHeading, setActiveNotesHeading,
    activeNotesEmoji, setActiveNotesEmoji,
    activeNotesText, setActiveNotesText,
    //for udpate notes - track if changes are made or not
    temp_ActiveNotesText_forEdit,
    temp_NotesHeading_forEdit,
    temp_ActiveNotesEmoji_forEdit,
  
    //!Functions
    handleAddNewNote, handleEditNotes, handleSaveNotes, handleUpdateNotes, handleDeleteNote,

    
  } = useTripOverviewPlanTrip()
  
  const[saveChanges_status, setSaveChanges_status] = useState(true)

  const[activePopup, setActivePopup] = useState();

  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
      setActivePopup(null);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };

  const handleActiveNotesChange_Temp = (value) => {
    setActiveNotesText(value)
  }

  const[headingHoverStatus_limited, setHeadingHoverStatus_limited] = useState(false)
  const[emojiHoverStatus_limited, setEmojiHoverStatus_limited] = useState(false)

  //after editing and updating the new note, the latest one is not visible, so we refresh it...
  const[displayLatestNote, setDisplayLatestNote] = useState(true)

  const handleDisplayUpdatedNotes = () => {
    setDisplayLatestNote(false);
    setTimeout(() => {
      setDisplayLatestNote(true);
    }, 200);
  };

  //!tripNotes update button visibility 
  const[updateNoteButton, setUpdateNoteButton] = useState(false)

  const[hoverHeadingIdeas_Status, setHoverHeadingIdeas_Status] = useState()

  return (
    <div
    className='conatiner_p-AddNotes_TO'
    >

      <div className='heading-1-TripDetails-AddNotes_TO'>
        Your Trip Notes
      </div>


      {
        !shareTripId &&
        <div 
        className='container_p-heading-tripDetails-AddNotes_TO'
        onClick={()=>{
          handleAddNewNote()
          setActivePopup("add_note")
        }}
        >  

          <div className='heading-TripDetails-AddNotes_TO'>
            Add Notes
          </div>

          <button 
            className='button-edit-tripDetails-AddNotes_TO'
          > 
            <img 
              className='image-TripDetails-AddNotes_TO'
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_3.png'
            />
          </button>
        </div>
      }




      {/*//! Display Notes  */}
      <div
      className='container_p-displayNotes-AddNotes_TO'
      >
        {/* all saved notes heading - you can select the one whuch you wish to view more about */}
        {
          notesData && notesData.length > 0 && 
          (
            <div
            className='container_p-headings-displayNotes-AddNotes_TO'
            >
            {
              notesData.map((data, index) => {
                return(
                  <button key={index}
                    onClick={(e)=>{
                      e.stopPropagation()
                      setActiveNotesId(data.id)
                    }}
                    className={activeNotesId === data.id ? 'conatiner_t-active-notesHeading-displayNotes-AddNotes_TO' : 'conatiner_t-unActive-notesHeading-displayNotes-AddNotes_TO'}
                  >
                    <div
                    className='icon-1-notesHeading-displayNotes-AddNotes_TO'
                    >
                      {data.emoji}
                    </div>  

                    <div
                    className='heading-1-notesHeading-displayNotes-AddNotes_TO'
                    >
                      {data.heading} 
                    </div>    

                  </button>  
                )
              })
            }
          </div>
          )
        }

        {/* display saved notes - the one which is active */}
        {
          notesData && notesData.length > 0 && 
          (
            notesData.map((data, index) => {
              if(activeNotesId === data.id){
                return(
                  <div key={index}
                  className='container_p-singleNote-displayNotes-AddNotes_TO'
                  > 

                  {
                    !shareTripId &&
                  
                    <div
                    className='container_t-manage-singleNote-displayNotes-AddNotes_TO'
                    >
                      <button 
                        onClick={()=>{
                          handleEditNotes(data.id, data.emoji, data.heading, data.note)
                          openPopup()
                          setActivePopup("add_note")
                        }}
                        className='button-edit-singleNote-displayNotes-AddNotes_TO'
                      >
                        <div
                        className='text-edit-singleNote-displayNotes-AddNotes_TO'
                        >
                          edit
                        </div>
                        <img
                          className='image-singleNote-displayNotes-AddNotes_TO'
                          src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/edit_icon_1.png'
                        />
                      </button>
                      <button 
                      onClick={()=>handleDeleteNote()}
                      className='button-delete-singleNote-displayNotes-AddNotes_TO'
                      >
                        <div
                        className='text-delete-singleNote-displayNotes-AddNotes_TO'
                        >
                          delete
                        </div>
                        <img
                          className='image-singleNote-displayNotes-AddNotes_TO'
                          src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/delete_icon_1.png'
                        />
                      </button>
                    </div>  
                  }

                    {
                      data.note && displayLatestNote && 
                      <div
                      className='container_p-note-singleNote-displayNotes-AddNotes_TO'
                      >
                        <div
                        className='container_t-note-singleNote-displayNotes-AddNotes_TO'
                        >
                          <TipTap_ReadOnly 
                            content={data.note}
                          />
                        </div>
                      </div>  
                    }    
                  
                  </div>  
                )
              }
            })
          )
        }

        {
          !notesData || ( notesData && notesData.length === 0) && 
            <div
            className='container_p-headings-displayNotes-AddNotes_TO'
            >
              No Notes found for you {tripDestinationName} trip.
            </div> 
        }      

      </div>


      {/* adding a note */}
      {/*//! Popup */}
      { activePopup === "add_note" && notesWindowStatus && 
        (
          <div>
            
            <div className='popup-background-1-Entities_TD'
              onClick={()=>{
              // handleTripDetails_CloseWindow_SaveChanges()
              closePopup()
              setUpdateNoteButton(false)
              }}> 
            </div>
                
            <div className='popup_p-2-addNote-AddNotes_TO_Popup'>
             <div className='popup_s-2-addNote-AddNotes_TO_Popup'>

              <div
              className='heading-heading-note-AddNotes_TO_Popup'
              >
                Add/Update Note
              </div>  

              {/* //*#1 */}
              <div
              className='conatiner_p-heading-AddNotes_TO_Popup'
              >

                {
                  activeNotesEmoji &&
                  <div
                  className='conatiner_t-1-heading-AddNotes_TO_Popup'
                  >
                    {activeNotesEmoji}
                  </div>   
                }

                <div 
                className='conatiner_t-2-heading-AddNotes_TO_Popup'
                >
                  <input 
                    type='text' 
                    value={activeNotesHeading} 
                    onChange={(event)=>{
                      setActiveNotesHeading(event.target.value)
                      setUpdateNoteButton(true)
                    }} 
                    placeholder='give a heading to your note'
                    maxLength={60}
                    className='input-heading-AddNotes_TO_Popup'
                  />
                </div>

                {
                  activeNotesHeading && 
                  <div
                  className='conatiner_t-3-heading-AddNotes_TO_Popup'
                  >
                  {activeNotesHeading.length}/60
                  </div>
                }

              </div>

              {/* //*#2 */} 
              <div
              className='conatiner_p-headingIdeas-AddNotes_TO_Popup'
              >

                {/* --heading ideas */}
                <div
                className='conatiner_t-hoverBox-headingIdeas-AddNotes_TO_Popup'
                >
                  <button 
                    onMouseOver={()=>{
                      setHeadingHoverStatus_limited(true)
                      setHoverHeadingIdeas_Status(true)
                    }}
                    onMouseOut={()=>{
                      setHeadingHoverStatus_limited(false)
                      setHoverHeadingIdeas_Status(false)
                    }}
                    className='button-hoverBox-headingIdeas-AddNotes_TO_Popup'
                  >
                    get Heading Ideas 
                  </button>
                  <div
                   className='icon-hoverBox-headingIdeas-AddNotes_TO_Popup'
                  >
                   {/* <FaRegHandPointer /> */}
                    <img
                    className='img-hoverBox-headingIdeas-AddNotes_TO_Popup'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/hover_icon_1.png'
                    />
                  </div>   
                </div> 

                {
                  hoverHeadingIdeas_Status && headingHoverStatus_limited &&
                  <div
                    onMouseOver={()=>{
                      setHeadingHoverStatus_limited(true)
                      setHoverHeadingIdeas_Status(true)
                    }}
                    onMouseOut={()=>{
                      setHeadingHoverStatus_limited(false)
                      setHoverHeadingIdeas_Status(false)
                    }}
                    className='conatiner_p-popup-headingIdeas-AddNotes_TO_Popup'
                    >
                    {
                      notesHeadingIdeas.map((data, index) => {
                        return(
                          <button key={index}
                            onClick={()=>{
                              setActiveNotesHeading(data.heading)
                              setActiveNotesEmoji(data.emoji)
                              setUpdateNoteButton(true)
                              setHoverHeadingIdeas_Status(false)
                            }}
                            className='button-popup-headingIdeas-AddNotes_TO_Popup'
                          >
                            {data.heading} {data.emoji}
                          </button>  
                        )
                      })
                    }
                  </div>  
                }

              </div>

              {/*//! Delete Note */}
              {
                editNotesStatus &&
                <div 
                className='button-delete-manageNote-AddNotes_TO_Popup'
                >
                  <button 
                  onClick={()=>{
                    handleDeleteNote()
                    closePopup()
                  }}
                  className='image-2-delete-manageNote-AddNotes_TO_Popup'
                  style={{border:'none', background:'none'}}
                  >
                    <RiDeleteBinLine />
                  </button>    
                </div>
              }  

              {/*//! Manage Note  */}
              {/* //*#4 */}
              <div
              className='conatiner_p-manageNote-AddNotes_TO_Popup'
              >

                <div 
                className='conatiner_s-manageNote-AddNotes_TO_Popup'
                >
                  {
                    // activeNotesHeading && 
                    activeNotesText && !editNotesStatus &&
                    <div>
                      <button 
                      onClick={()=>{
                        handleSaveNotes()
                        closePopup()
                      }}
                      className='button-manage-manageNote-AddNotes_TO_Popup'
                      >
                        Save Note
                      </button>
                    </div> 
                  }

                  { 
                  editNotesStatus && updateNoteButton &&
                  (
                    (activeNotesText !== temp_ActiveNotesText_forEdit) ||
                    // (activeNotesHeading !== temp_NotesHeading_forEdit) ||
                    (activeNotesEmoji !== temp_ActiveNotesEmoji_forEdit)
                  ) &&
                    <div>
                      <button 
                      onClick={()=>{
                      handleUpdateNotes()
                      handleDisplayUpdatedNotes()
                      closePopup()
                      }}
                      className='button-manage-manageNote-AddNotes_TO_Popup'
                      >
                        Update Note
                      </button>
                    </div>     
                  }

                  <div>

                    <button 
                      onClick={()=>{
                      setNotesWindowStatus(false)
                      setUpdateNoteButton(false)
                      closePopup()
                      }}
                      className='button-cancel-manageNote-AddNotes_TO_Popup'
                    >
                      {/* <IoMdCloseCircle /> */}
                      Cancel
                    </button>

                  </div>  

                </div>  
              </div>

              {/*//! Edit Note  */}
              <div
              onClick={()=>{
                setUpdateNoteButton(true)
              }}
              >
                  <Notes_Tool 
                    userInputText={activeNotesText}
                    handleUserInputText={handleActiveNotesChange_Temp}
                    placeholder={"Write something awesome..."}
                    wordLimit={1500}
                  />
              </div>   

              <div style={{height:'100px'}}></div>   
  
             </div>
            </div>

          </div>  
        )
      }
      <br></br>


    </div>
  )
}

export default AddNotes_TO















//Add emojs. We have an array for the emojis. You can add from that.
{/* --heading emojis */}
{/* <div>
  <button
    onMouseOver={()=>setEmojiHoverStatus_limited(true)}
    onMouseOut={()=>setEmojiHoverStatus_limited(false)}
  >
    Emojis 
  </button>
</div> 

{
  emojiHoverStatus_limited &&
  <div
  onMouseOver={()=>setEmojiHoverStatus_limited(true)}
  onMouseOut={()=>setEmojiHoverStatus_limited(false)}
  >
    {
      notesEmojis.map((data, index) => {
        return(
          <button key={index}
          onClick={()=>{setActiveNotesEmoji(data)}}
          >
            {data}
          </button>  
        )
      })
    }
  </div>  
}   */}