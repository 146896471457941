import React from 'react';
import { Helmet } from "react-helmet";

function Restaurants() {
  return (
    <div>
      {/* <Helmet>
        <title>Restaurant FAQ</title>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "How can I make a reservation?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You can make a reservation by calling the restaurant directly or using our online booking system available on the website."
                }
              }, {
                "@type": "Question",
                "name": "Do you offer vegan options?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we offer a variety of vegan options. Check our menu or ask the staff for recommendations."
                }
              }, {
                "@type": "Question",
                "name": "What are your operating hours?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our restaurant is open from 8 AM to 10 PM, Monday through Sunday."
                }
              }]
            }
          `}
        </script>
      </Helmet> */}
      {/* <h2>Restaurant FAQ</h2>
      <h3>How can I make a reservation?</h3>
      <p>You can make a reservation by calling the restaurant directly or using our online booking system available on the website.</p>
      
      <h3>Do you offer vegan options?</h3>
      <p>Yes, we offer a variety of vegan options. Check our menu or ask the staff for recommendations.</p>
      
      <h3>What are your operating hours?</h3>
      <p>Our restaurant is open from 8 AM to 10 PM, Monday through Sunday.</p> */}
    </div>
  );
}

export default Restaurants;
