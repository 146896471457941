import React, { useEffect, useRef, useState } from 'react'

//import css
import './Map_D.scss'

//!Currenlty, only used in the Reach_D component

//importing react context
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip'
import { useAboutDestinationPlanTrip } from '../../Manage/Context/AboutDestination_Context'

// importing map
import {MapContainer, Tooltip, TileLayer, Marker, Popup, useMapEvent, useMap, useMapEvents, circle, Circle, GeoJSON} from 'react-leaflet';


//!delete
// import { defaultMarker, defaultMarker3 } from '../../../../../../../Plan/ActualPlanning/defaultMarker';


// importing icons 
import {active_tourist_places, hover_tourist_places, saved_tourist_places, map_pin_1, map_pin_2, map_pin_3, place_1_n, place_1_l, airport_normal_1, train_normal_1, bus_normal_1} from '../../../../Manage - Plan Trip/Icons/MapIcons'


function Map_D() { 

// using react context
const {
    tripDestinationName, destinationLat, destinationLng, destinationZoom,   lat, lng, zoom
} = useValidatePlanTrip()

const {
    activeTravelMode, 
    travelMode_Air_Data, travelMode_Train_Data, travelMode_Bus_Data, 
    //
    activeReachPlace_Map, 
    activeCenterLat, setActiveCenterLat,
    activeCenterLng, setActiveCenterLng,
    activeCenterZoom, setActiveCenterZoom
} = useAboutDestinationPlanTrip()

const mapRef = useRef(null);

    //for the very initial time we get the destination lat and destination long and destination zoom from the validate file and here the moment we get it we set the data in the active center laten log
    useEffect(() => {
    setActiveCenterLat(destinationLat)
    setActiveCenterLng(destinationLng)
    setActiveCenterZoom(destinationZoom)
    }, [destinationLat, destinationLng, destinationZoom])

    //handle map - drag
    useEffect(() => {
        if (mapRef.current){
        mapRef.current.setView([activeCenterLat, activeCenterLng, activeCenterZoom]);
        }
    }, [activeCenterLat, activeCenterLng, activeCenterZoom])


  return (

    <MapContainer  
        className="conatiner_p-Map-Map_D" 
        center = {[destinationLat, destinationLng]} 
        zoom={destinationZoom} 
        ref={mapRef}
    > 

    <TileLayer 
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' 
    />

    {/*//! destination */}
    {
        destinationLat && destinationLng &&
        (
            <Marker
            position={[destinationLat, destinationLng]}
            zoom={destinationZoom}
                //! delete
            icon={map_pin_2} 
            >
                <Tooltip 
                direction="bottom" 
                permanent className='hoverToolTipOverall'
                >
                    {tripDestinationName}
                </Tooltip>       
            </Marker>
        )
    }

    {/*//! Active Mode Place*/}
    {
        activeReachPlace_Map &&
        activeReachPlace_Map.map((data, indec) => {
            return(

            <Marker
            position={[data.lat, data.lng]}
            zoom={destinationZoom}
                //! delete
            icon={map_pin_2} 
            >
                <Tooltip 
                direction="bottom" 
                permanent className='hoverToolTipOverall'
                >
                    {data.name}
                </Tooltip>       
            </Marker>
            )
        })
    }

    {/*//! air */}
    {
        activeTravelMode === "air" && activeTravelMode && 
        (<>
        
            {
                travelMode_Air_Data.map((data, index) => {
                    if(data.mode_type === "international"){
                    return(
                        <Marker position={[(data.mode_lat), (data.mode_lng)]} zoom={6} icon={airport_normal_1}>

                        <Popup  style={{width:"1000%"}} closeOnEscapeKey={false}>
                            {data.mode_name} <br></br>
                            {data.mode_code} <br></br>
                        </Popup>    

                        </Marker>    
                    )
                    } 
                })
            }

            {
                travelMode_Air_Data.map((data, index) => {
                    if(data.mode_type === "domestic"){
                    return(
                        <Marker position={[(data.mode_lat), (data.mode_lng)]} zoom={6}icon={airport_normal_1}>
                            <Popup>
                                {data.mode_name} <br></br>
                                {data.mode_code} <br></br>
                                in {data.mode_district}, {data.mode_state_name}. <br></br>
                                G reviews: {data.mode_g_review}
                                <br></br>
                                Approx {data.distance} kms away.d
                                <br></br>
                                <br></br>
                            </Popup>
                        </Marker>    
                    )
                    } 
                })
            }
        </>)
    }


    {/*//! train */}
    {
        activeTravelMode === "train" && activeTravelMode === "train" && 
        (<>

        {
            travelMode_Train_Data.map((data, index) => {
                if(data.mode_type === "major"){
                return(
                    <Marker position={[(data.mode_lat), (data.mode_lng)]} zoom={6}icon={train_normal_1}>

                        <Popup  style={{width:"1000%"}} closeOnEscapeKey={false}>

                            {data.mode_name} <br></br>
                            {data.mode_code} <br></br> 
                        </Popup>
                    </Marker>    
                )
                } 
            })
        }

        {
            travelMode_Train_Data.map((data, index) => {
                if(data.mode_type === "minor"){
                return(
                    <Marker position={[(data.mode_lat), (data.mode_lng)]} zoom={6}icon={train_normal_1}>
                        <Popup  style={{width:"1000%"}} closeOnEscapeKey={false}>
                        {data.mode_name} <br></br>
                        {data.mode_code} <br></br> 
                        </Popup>
                        
                    </Marker>    
                )
                } 
            })
        }
        
        </>)
    }

    {/*//! bus */}
    {
        activeTravelMode === "bus" && activeTravelMode === "bus" && 
        (<>
            {
                travelMode_Bus_Data.map((data, index) => {
                    if(data.mode_type === "isbt"){
                    return(
                        <Marker position={[(data.mode_lat), (data.mode_lng)]} zoom={6}icon={bus_normal_1}>
                            <Popup  style={{width:"1000%"}} closeOnEscapeKey={false}>
                            {data.mode_name} <br></br>
                            </Popup>
                        </Marker>    
                    )
                    } 
                })
            }

            {
                travelMode_Bus_Data.map((data, index) => {
                    if(data.mode_type === "cbs"){
                    return(
                        <Marker position={[(data.mode_lat), (data.mode_lng)]} zoom={6}icon={bus_normal_1}>
                            <Popup  style={{width:"1000%"}} closeOnEscapeKey={false}>
                            {data.mode_name} <br></br>
                            </Popup>
                        </Marker>    
                    )
                    }
                })
            }

            {
                travelMode_Bus_Data.map((data, index) => {
                    if(data.mode_type === "local"){
                    return(
                        <Marker position={[(data.mode_lat), (data.mode_lng)]} zoom={6}icon={bus_normal_1}>
                            <Popup  style={{width:"1000%"}} closeOnEscapeKey={false}>
                            {data.mode_name} <br></br>
                            </Popup>
                        </Marker>    
                    )
                    } 
                })
            }

        </>)
    }

    </MapContainer>

  )
}

export default Map_D