import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'

//import css
import './SavedAiItineraries.scss'

//
import { useNavigate } from 'react-router-dom';

//import react icons
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

//
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

//
import ManageLogin from '../../../../Manage User Credentials/User Login/ManageLogin';
import { server_baseUrl } from '../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';


function SavedAiItineraries(props) {

  //@@ 
  const navigate = useNavigate();

  const {userEmail} = props;

  const[userSavedAiItineraryData, setUserSavedAiItineraryData] = useState([])
  const[savedPlan_status, setSavedPlan_status] = useState(false)


  useEffect(() => {

    if(!savedPlan_status && userEmail){
      loadUserSavedAiItineraries()
    }

  }, [])


  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current){
      scrollContainerRef.current.scrollBy({ left: -500, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 500, behavior: 'smooth' })
    }
  };

  const loadUserSavedAiItineraries = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/home/load/user_saved/ai_itinerary`, {userEmail})

    //id, created_date, prompt, overview, email, plan_trip_id, plan_trip_name, destination_lat, destination_lng

    // console.log("data - loadUserSavedPlans", response.data)
    
    if(response.data !== "0"){
      setUserSavedAiItineraryData(response.data)
      setSavedPlan_status(true)
    }
    else{
      // console.log("there is some error loading your saved ai itineraries")
    }

  }


  const handleSavedItineraryClicked = async (itineraryId_passed) => {
    
    navigate(`ai-tool/create-itinerary-by-ai/${itineraryId_passed}`)

  }

  //delete ai itinerary
  const[deleteItineraryAsk, setDeleteItineraryAsk] = useState()
  const[activeSItineraryId, setActiveSItineraryId] = useState()

  const handleDeleteSavedAiItinerary = async (itineraryId_passed) => {
   
    const response = await axios.post(`${server_baseUrl}/csc/home/load/user_saved/ai_itinerary/remove`, {itineraryId_passed, userEmail})

    // console.log("response.data", response.data)

    if(response.data && response.data == "1"){

      const udpatedItinerary = userSavedAiItineraryData.filter(itinerary => itinerary.id !== itineraryId_passed);

      setUserSavedAiItineraryData(udpatedItinerary);

      toast.success("Removed successfully.", {
        position: toast.POSITION.TOP_CENTER
      })

      handleDeleteEffect();
    }
    else{
      toast.error("Error in removing the itinerary. Please try again.", {
        position: toast.POSITION.TOP_CENTER
      })
    }
  }
  

  const[showSavedItineraries, setShowSavedItineraries] = useState(true);

  const handleDeleteEffect = () => {
    setShowSavedItineraries(null);
    setTimeout(() => {
      setShowSavedItineraries(true);
    }, 200);
  };


  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
      // setAskQuestionInput(false);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  return (
  <div className='container_p-SavedAiItineraries'>
    <div className='container_s-SavedAiItineraries'>

      {/*//!Container #1 */}
      <div className='container-text-SavedAiItineraries'>
        <div
        className='heading-1-SavedAiItineraries'
        >
          Your AI Itineraries
        </div>

        <img 
          src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/itinerary_saved_1.png'
          className='img-1-bg-text-SavedAiItineraries'
        />
      </div>

      {
        !userEmail && 
        <div
        className='container_p-LoginBefore-SavedAiItineraries'
        >
          <h4
          className='text-1-LoginBefore-SavedAiItineraries'
          >
            Login to see your saved AI Itineraries.
          </h4>
          <ManageLogin /> 
        </div>
      }

      { (userEmail &&
        userSavedAiItineraryData && userSavedAiItineraryData.length > 0) ?
        <div
        className='container_p-itineraryContent-SavedAiItineraries'
        >

          {
            userSavedAiItineraryData.length > 2 &&
            <button
            // className='scroll-images-TouristPlaces_VM'
            className='scroll-content-SavedAiItineraries'
            onClick={scrollLeft}
            >
              <FaChevronLeft />
            </button>  
          }

          <div
            className='container_s-itineraryContent-SavedAiItineraries'
            ref={scrollContainerRef}
          >

            {/*//delete effect */}
            {
              !showSavedItineraries &&
              <div className="placeholder-container">
                {[...Array(3)].map((_, index) => (
                  <div className="placeholder-box" key={index}>
                    <div className="placeholder-text"></div>
                  </div>
                ))}
              </div>
            }

            
            {deleteItineraryAsk &&
              <div class="overlay">
                <div class="popup">
                  <h3>Are you sure you want to delete this itinerary?</h3>
                  <div class="buttons">
                    <button
                      class="cancel-button"
                      onClick={() => {
                        setDeleteItineraryAsk(false);
                        closePopup();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      class="confirm-button"
                      onClick={() => {
                        handleDeleteSavedAiItinerary(activeSItineraryId);
                        setDeleteItineraryAsk(false);
                        closePopup();
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            }




            { showSavedItineraries &&
            userSavedAiItineraryData.map((data, index) => {
              return(
                <button key={index}
                onClick={()=>handleSavedItineraryClicked(data.id)}
                className='container_t-itineraryContent-SavedAiItineraries'
                >


                  <img 
                  src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/delete_icon_1.png'
                  className='img-delete-SavedAiItineraries'
                  onClick={(e)=>{
                    // handleDeleteSavedAiItinerary(data.id)
                    setDeleteItineraryAsk(true)
                    openPopup()
                    setActiveSItineraryId(data.id)
                    e.stopPropagation(); 
                  }}
                  />  

                  <img 
                  src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/text-background-saved-itineraries-1.png'
                  className='img-1-bg-itineraryContent-SavedAiItineraries'
                  />

                  <div
                    className='container_t-content-itineraryContent-SavedAiItineraries'
                  >

                    <div
                    className='text-1-itineraryContent-SavedAiItineraries'
                    // style={{zIndex:'20'}}
                    >
                      {data.prompt} ?
                    </div>

                    {
                      data.overview &&
                      <div
                      className='text-2-itineraryContent-SavedAiItineraries'
                      >
                        {data.overview.split('').slice(0, 100)}
                        {data.overview.split(' ').length > 100 ? '...' : ''}
                      </div>
                    }

                    <br></br>

                    {
                      data.action_time &&
                      <div
                      className='container_t-createOn-savedTrips-SavedPlans'
                      >
                        <div
                        className='text-1-createOn-savedTrips-SavedPlans'
                        >
                          created on:
                        </div>
                        <div
                          className='text-2-createOn-savedTrips-SavedPlans'
                        >
                          {data.action_time} 
                          {/* (Mon) 4th July 2024 */}
                        </div>
                      </div>
                    }

                   

                  </div>

                </button>   
              )
            })
            }
          </div>

          {  userSavedAiItineraryData.length > 2 &&
            <button
            className='scroll-content-SavedAiItineraries'
            onClick={scrollRight}
            >
              <FaChevronRight />
            </button>
          }

        </div>  
        
        :
        
        userEmail &&
        <div class="no-itineraries-message">
          You haven't created any AI itineraries yet.
        </div>
        
      }

      <ToastContainer />

    </div>
  </div>
  )
}

export default SavedAiItineraries