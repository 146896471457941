import React, {useState} from 'react'

//import scss
import './DayInfo_Itinerary_TO.scss'
import './Itinerary_TO_Components.scss'

//import icon
import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineEdit } from "react-icons/ai";
import { TbEdit } from "react-icons/tb";
import { IoMdCloseCircle } from "react-icons/io";
import { IoMdRemoveCircle } from "react-icons/io";

//toptap - rick text editor
import TipTap_ReadOnly from '../../../../../Global Tool - Plan Trip/Notes/TestingLexical/TipTap_ReadOnly';



function DayInfo_Itinerary_TO(props) {

  const {itineraryData, visibility_type, different_design, topic_type, topic_id, tripDocumentsData} = props;

  const[activeViewMore, setActiveViewMore] = useState(false)
  const[activeNote, setActiveNote] = useState([])
  
  const[activeDocumentId, setActiveDocumentId] = useState()

  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
    setActiveViewMore(false);
    setActiveViewMore('')
    document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  const handleImageLink = (type) => {
    let link;
    if(type === "Day Overview"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/travel_around_icon_2.png'
    }
    else if(type === "Travelling Around"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/rent_vehicle_2_icon_1.png'
    }
    else if(type === "Clothes Overview"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/clothes_icon.png'
    }
    return link;
  }


  const handleGetCurrency = (currencyName) => {
    let currency;

    if(currencyName === "inr"){
      currency = '₹'
    }
    else if(currencyName === "dollar"){
      currency = '$'
    }

    return currency;

  }


  function formatNumberWithCommas(number) {
    if (isNaN(number)) {
      return 0; // Handle non-numeric values gracefully
  }

    const parts = number.toString().split('.');
    let wholePart = parts[0];

    // Reverse the string to handle commas from the last digit
    wholePart = wholePart.split('').reverse().join('');

    // Insert commas after every group of 2 digits, but not at the end
    wholePart = wholePart.replace(/(\d{2})(?=(\d{2})+(?!\d))/g, '$1,');

    // Reverse the string back to normal order
    wholePart = wholePart.split('').reverse().join('');

    // Adjust the first comma for the first three digits group
    if (wholePart.length > 6) {
        wholePart = wholePart.replace(/(\d+),/, '$1,');
    }

    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    return wholePart + decimalPart;
  }


  return (
    <div className='container_p-Itinerary_TO_Components'>

    {
      !different_design && itineraryData &&
  
      itineraryData.map((data, index) => {
        if(data.topic_id === topic_id){
        return (
          <div key={index}
          className='container_s-Itinerary_TO_Components'
          >

            {/*//! #1  */}
            {/* topic type and delete topic */}
            <div className='conatiner_p-head-Itinerary_TO_Components'>
  
                <div
                className='conatiner_s-1-head-Itinerary_TO_Components'
                >
                  <div className='text-head-Itinerary_TO_Components'>
                    {data.display_name}
                  </div>

                  <div>
                    <img
                      src={handleImageLink(data.display_name)}
                      className='icon-checklist-Itinerary_TO_Components'
                    />
                  </div>
                </div> 

            </div>


            {/* //! #3  */}
            {/* Note */}
            {
              (data.notes_data) &&
              <div>

                {/* Container - 1 | Note Menu */}
                <div className='conatiner_p-addNote-Itinerary_TO_Components'>

                  <button
                  onClick={()=>{{
                    setActiveViewMore(true)
                    openPopup()
                  }}}
                  className='button-addNote-Itinerary_TO_Components'
                  style={{marginLeft:'-0px'}}
                  >
                    View full note 
                  </button>

                </div>  

                <button 
                onClick={()=>{{
                  setActiveViewMore(true)
                  openPopup()
                }}}
                className='container_p-note-singleNote-displayNotes-Note_TD'
                >
                
                  <div style={{ background: 'white' }}
                    className='container_t-note-singleNote-displayNotes-Itinerary_TO_Components'>
                    <TipTap_ReadOnly content={data.notes_data} />
                  </div>

                </button>

              </div>
            }

            {/*//!popup */}
            {
              activeViewMore && 
              <div>

                <div className='popup-background-1-Entities_TD'
                  onClick={()=> closePopup()}
                >
                </div>

                <div className='popup_p-addNote-Entities_TO_Popup'>
                  <div className='popup_s-addNote-Entities_TO_Popup'>

                    <button
                      onClick={()=>{
                        closePopup()
                        }}
                      className='button-close-popup-AddTripDetails_TO'
                    >
                      <IoMdCloseCircle />
                    </button>

                    <div className='input-1-description-Itinerary_TO_Components'>
                      {data.description}
                    </div>

                    <div style={{ background: 'white' }}
                      className='container_t-note-popup-Itinerary_TO_Components'
                    >
                      <TipTap_ReadOnly content={data.notes_data} />
                    </div>

                  </div> 
                </div>    

              </div>
            }

          </div> 
        )
        }
      })
    }


    {/*//! Budget  #2 */}
    { 
      different_design && itineraryData &&

      itineraryData.map((data, index) => {
        if(data.topic_id === topic_id){
          return(
            topic_type === "day_budget" ? 
            (
              <div className='container_p-Entities_TD'>

                <div
                className='conatiner_s-1-head-Itinerary_TO_Components'
                >
                  <div className='text-head-Itinerary_TO_Components'>
                    {data.display_name}
                  </div>
    
                  <div>
                    <img
                      src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/budget_icon_1.png'
                      className='icon-checklist-Itinerary_TO_Components'
                    />
                  </div>
                </div> 


                <div className='container_p-Data-DayInfo_Itinerary_TO'>

                  {/*//* Conatiner for Budget and Trip Status */}
                  <div 
                  className='container_s-1-Data-DayInfo_Itinerary_TO'
                  >

                    {/*//* Budget */}
                    {
                      (data.budget_number && data.budget_currency)
                      &&
                      <div className='container_p-Budget-Itinerary_TO_Components'>

                        {
                          data.display_name !== 'Hotel'
                            ?
                            <div className='heading-1-budgetData-Itinerary_TO_Components'>
                              Budget
                            </div>
                            :
                            <div className='heading-1-budgetData-Itinerary_TO_Components'>
                              Per Night
                            </div>
                        }

                        <div className='container_t-budgetData-Itinerary_TO_Components'>
                          <div className='container_t-data-1-budget-Itinerary_TO_Components'>
                            {/* {data.budget_currency} */}
                            {
                              data.budget_currency ?
                                handleGetCurrency(data.budget_currency)
                                :
                                <div>xx</div>
                            }
                          </div>
                          <div className='container_t-data-1-budget-Itinerary_TO_Components'>
                            {
                              data.budget_number ?
                                formatNumberWithCommas(data.budget_number)
                                :
                                <div>xx</div>
                            }
                          </div>
                          {
                            data.budget_meta_info &&
                            <div className='container_t-data-2-budget-Itinerary_TO_Components'>
                              #{data.budget_meta_info}
                            </div>
                          }
                        </div>

                      </div>
                    }

                  </div>
                </div>


                {/* //! #3  */}
                {/* Note */}
                {
                  (data.notes_data) &&
                  <div>

                    {/* Container - 1 | Note Menu */}
                    <div className='conatiner_p-addNote-Itinerary_TO_Components'>

                      <button
                      onClick={()=>{{
                        setActiveViewMore(true)
                        openPopup()
                      }}}
                      className='button-addNote-Itinerary_TO_Components'
                      style={{marginLeft:'-0px'}}
                      >
                        View full note 
                      </button>

                    </div>  

                    <button 
                    onClick={()=>{{
                      setActiveViewMore(true)
                      openPopup()
                    }}}
                    className='container_p-note-singleNote-displayNotes-Note_TD'
                    >
                    
                      <div style={{ background: 'white' }}
                        className='container_t-note-singleNote-displayNotes-Itinerary_TO_Components'>
                        <TipTap_ReadOnly content={data.notes_data} />
                      </div>

                    </button>

                  </div>
                }

                {/*//!popup */}
                {
                  activeViewMore && 
                  <div>

                    <div className='popup-background-1-Entities_TD'
                      onClick={()=> closePopup()}
                    >
                    </div>

                    <div className='popup_p-addNote-Entities_TO_Popup'>
                      <div className='popup_s-addNote-Entities_TO_Popup'>

                        <button
                          onClick={()=>{
                            closePopup()
                            }}
                          className='button-close-popup-AddTripDetails_TO'
                        >
                          <IoMdCloseCircle />
                        </button>

                        <div className='input-1-description-Itinerary_TO_Components'>
                          {data.description}
                        </div>

                        <div style={{ background: 'white' }}
                          className='container_t-note-popup-Itinerary_TO_Components'
                        >
                          <TipTap_ReadOnly content={data.notes_data} />
                        </div>

                      </div> 
                    </div>    

                  </div>
                }    

              </div> 
            )    
            
            :

            // {/*//! Trip Documents  #3 */}
            ( //topic_type === "day_trip_documents" ? 
              <div className='container_p-Checklist_TD'>

                <div
                className='conatiner_s-1-head-Itinerary_TO_Components'
                >
                  <div className='text-head-Itinerary_TO_Components'>
                    {data.display_name}
                  </div>
    
                  <div>
                    <img
                      src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/documents_icon_1.png'
                      className='icon-checklist-Itinerary_TO_Components'
                    />
                  </div>
                </div>   

                  {
                    tripDocumentsData && 
                    <div className='conatiner_p-Documents-TripDocuments'>
                    {
                    tripDocumentsData.map((TDData, TDIndex) => {
                      if(TDData.topic_id === topic_id){
                        return(
                          <div
                          className='conatiner_s-Documents-TripDocuments'
                          > 

                          <div
                          className='conatiner_p-1-Document-TripDocuments'
                          >

                            <div
                            className='conatiner_t-1_1-deleteButton-Document-TripDocuments'
                            >

                              <div
                              className='heading-1-Heading-Document-TripDocuments'
                              >
                                {TDData.heading}
                              </div> 

          
                              <div
                              className='conatiner_t-link_open-Document-TripDocuments'
                              > 

                                {
                                  TDData.link &&
                                  <div
                                  className='link-Document-TripDocuments'
                                  > 
                                    {
                                      TDData.link.split(' ').slice(0, 50).join(' ') + (TDData.link.split(' ').length > 50 ? '...' : '')
                                    }
                                  </div> 
                                }

                                <div>
                                  {TDData.link ? (
                                    <a
                                      href={TDData.link} 
                                      target="_blank" 
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                      src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/share_icon_1.png'
                                      className='img-link-Document-TripDocuments'
                                      />
                                    </a>
                                  ) : (
                                    <span
                                    className='text-1-notFound-Documents-TripDocuments'
                                    >
                                      (Link is not available)
                                    </span>
                                  )}
                                </div>    

                              </div> 

                              <div> 
                                {
                                  TDData.id_documents === activeDocumentId ?
                                  <button
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setActiveDocumentId();
                                    }}
                                    className='button-detail-Documents-TripDocuments'
                                  >
                                    hide detail
                                  </button>
                                :
                                  <button 
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setActiveDocumentId(TDData.id_documents);
                                    }}
                                    className='button-detail-Documents-TripDocuments'
                                  >
                                    view detail
                                  </button>
                                }
                              </div>     

                            </div>  

                            {/* <div
                            className='conatiner_t-1_2-deleteButton-Document-TripDocuments'
                            >
                              <div> 
                                <button 
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleDocument_DeleteColumn(TDData.id_documents, TDData.day_id, TDData.topic_id)
                                    setSaveChangesStatus(true)
                                  }}
                                  className='button-icon-delete-Document-TripDocuments'
                                  >
                                  <RiDeleteBin2Fill />
                                </button>
                              </div>    
                            </div>        */}

                          </div>   


                          {/*//! Container 2  */}
                          {/* document detail detail */}
                          {
                            TDData.id_documents === activeDocumentId && TDData.description ? 
                            <div
                            className='conatiner_p-2-Document-TripDocuments'
                            > 
                              <div
                              className='heading-detail-Documents-TripDocuments'
                              >
                                Description
                              </div>
                              <div
                              className='content-detail-Documents-TripDocuments'
                              >
                                {TDData.description}  
                              </div>     
                            </div>   
                            :
                            TDData.id_documents === activeDocumentId &&
                            <div
                            className='text-2-notFound-Documents-TripDocuments'
                            >
                              no details found
                            </div>  
                          }


                          </div> 
                        )
                      }
                    })
                  }
                  </div> 
                  }  

                {/* </div>  */}

              </div> 
            ) 
            
      )}
      })
    }





    </div> 
  )
}

export default DayInfo_Itinerary_TO