import React from 'react'

//import css
import './QnA_D.scss'

//importing react context
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip'
import { useAboutDestinationPlanTrip } from '../../Manage/Context/AboutDestination_Context'


function QnA_D() {
    
    // using react context
    const {
        destinationLat, destinationLng, destinationZoom, tripDestinationName, tripDestinationType
    } = useValidatePlanTrip()
    
    const {
        //Qna
        qnaPlaceData
    } = useAboutDestinationPlanTrip()


  return (
    <div className='conatiner_p-question-About_D'>

    { qnaPlaceData && (

        <div>

            {/* history design */}
            <div className='conatiner_p-qna-header-About_D'>

                <img
                className='image-1-qna-header-About_D'
                src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/qna_image_2.jpeg'
                />

            </div>  

            <div className='heading-0-question-About_D'>
                QnA for {tripDestinationName}
            </div> 

<br></br>

            {/* <div className='famousPlacesButtons-box3-1'> */}
            {
            qnaPlaceData.map((data, index) => {
                
                return(   
                <div key={index}>       
                    <div 
                    className='heading-question-About_D'>
                        Q: {data.content_question}
                    </div>     

                    <div 
                    className='content-1-question-About_D'
                    dangerouslySetInnerHTML={{ __html:  data.content_answer }} />
                    
                    {/* <div 
                    className='content-1-question-About_D'
                    >
                        A: {data.content_answer}
                    </div> */}
                </div>
                )
            })
            }
            {/* </div>  */}

        </div>
    )}

    </div>
  )
}

export default QnA_D