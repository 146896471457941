  //@@ queries
  // first propmt
  export const get_prompt_1 = [
    "Please choose the type of nearby options you'd like to explore:",
    "Select the category of places you're interested in discovering nearby:",
    "Pick a category to explore nearby:",
    "Which category of nearby options are you looking to explore?",
    "Choose the type of nearby attractions you're interested in:",
    "What kind of places are you hoping to find nearby?",
    "Choose what you're looking for nearby:",
    "Select what interests you nearby:",
    "Pick a category of places to explore:",
    "What type of places are you seeking nearby:",
    "Please choose a category of nearby options:"
  ];


  // second prompt
  export const get_prompt_3 = [
    "Now, please specify the location around which you'd like to explore:",
    "Select the area you want to explore the chosen category around:",
    "Choose the location you'd like to explore nearby:",
    "Where would you like to search for the selected category?",
    "Specify the area where you want to find the chosen category of options:",
    "Please select the region you'd like to explore the selected category in:",
    "Now, select where you want to explore:",
    "Pick the area you're interested in:",
    "Choose the location you want to explore around:",
    "Where do you want to search for these options:",
    "Select the area you're focusing on:"
  ];



    //first prompt - options
    const entity_options = [
        "tourist locations",
        "tourist categories",
        "states",
        "districts",
        "cities/towns",
        "airport(s)",
        "railway station(s)",
        "bus stand(s)",
        "hotels",
        "restaurants",
        "bars and pubs",
        "amusement parks",
        "activities",
        "bike rentals",
        "malls",
        "local shopping markets"
      ]
    
      const entity_destination_options = [
        "tourist location",
        "city/town",
        "district",
        "state"
      ]