import React, {useEffect, useRef} from 'react'

//import scss
import './AISection.scss'
import ManageLogin from '../../../../Manage User Credentials/User Login/ManageLogin';


function AISection(props) {

  const {userEmail, handle_AIFeatureClick, scrollPageTo_AISection, setScrollPageTo_AISection} = props;

  //
  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };


    //scroll page to...
    const my_ai_trips = useRef(null);

    useEffect(() => {
      scrollToFeedback(scrollPageTo_AISection)
    }, [scrollPageTo_AISection])
  
  
    const scrollToFeedback = (scrollPageTo) => {
  
      if(scrollPageTo === "my_ai_trips"){
        my_ai_trips.current.scrollIntoView({ behavior: 'smooth' });
      }

      setScrollPageTo_AISection(false)
  
    };


  return (
    <div>
     
      <div
      ref={my_ai_trips}
      >

        {/*//!#1 - Create Itinerary */}
        <div
        className='container_p-createItinerary-AISection'
        >
          <button 
          className='container_s-Search_TravelCategories'
          onClick={()=>{
            handle_AIFeatureClick("create_itinerary")
            openPopup()
          }}
          >
            <img 
            className='img-createItinerary-AISection'
            src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/home-create_itinerary-travelCategory-1.png'
            />

            <div
            className='container_p-metaData-createItinerary-AISection'
            >
              <div
              className='container_s-metaData-createItinerary-AISection'
              >
                <button 
                className='button-1-metaData-createItinerary-AISection'
                >
                  # Optimized Route
                </button>
                <button
                className='button-1-metaData-createItinerary-AISection'
                >
                  # Ask Questions
                </button>
                <button
                className='button-1-metaData-createItinerary-AISection'
                >
                  # Travel Plan
                </button>
                <button
                className='button-1-metaData-createItinerary-AISection'
                >
                  # About Destination
                </button>
              </div>
            </div>
            
          </button>   
        </div>

        <br></br>


        {/*//!#2 - Later */}
        {/* <div>
          <button 
          className='className' 
          onClick={()=>{handle_AIFeatureClick("everything_famous")}}
          >
            Everything Famous
          </button>   
        </div> */}
        


        {/* <div>
          <button 
          className='className' 
          onClick={()=>{handle_AIFeatureClick("nearby")}}
          >
            Near by
          </button>   
        </div> */}

        
          {/* <button className='className' onClick={()=>{handle_AIFeatureClick("decide_destination")}}>Decide Destination</button>    */}

          {/* <div>
            <button className='className' onClick={()=>{handle_AIFeatureClick("everything_famous")}}>Everything Famous</button>   
          </div>  
          
          <button className='className' onClick={()=>{handle_AIFeatureClick("how_to_reach")}}>How To Rech</button>   

          <button className='className' onClick={()=>{handle_AIFeatureClick("nearby")}}>Near by</button>  */}

      </div>  

    </div>
  )
}

export default AISection