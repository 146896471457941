import React, {useEffect, useRef, useState} from 'react'

//!importing css
import './Overview_D.scss'
//*top 10
//tourist places
import './Overview_D_Top10.scss'
//hotel
import './OverviewComponents/Hotels_Top10.scss'
//more - after top 10...
import './Overview_D-3.scss'
import './Overview_D-4.scss'

//rating
import { Rating } from 'react-simple-star-rating';

//import icon
import {AiOutlineLeft} from 'react-icons/ai'
import {AiOutlineRight} from 'react-icons/ai'

//importing react context
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip'
import { useAboutDestinationPlanTrip } from '../../Manage/Context/AboutDestination_Context'
import Hotels_Top10 from './OverviewComponents/Hotels_Top10';
import TouristPlaces_Top10 from './OverviewComponents/TouristPlaces_Top10';


function Overview_D() {
    
    // using react context
    const {
        destinationLat, destinationLng, destinationZoom, tripDestinationName, tripDestinationType, stateName
    } = useValidatePlanTrip()
    
    const {
        //loading
        loadingStatus_AboutDestination,
        //Overview
        overviewPlaceData, placeDurationData, seasonRankData, exploreSeasonData, monthTempData,
        //top 10
        famousPlaces_T10_Data, hotel_T10_Data, hotel_about_data,
        //nearby
        city_nearbyCitiesData, district_districtDestinationsData, district_nearbyDistrictData, state_famousDistrict, state_famousDistrictDestinations, state_nearbyStates,  
        //
        loadData_OverviewScroll,
        // 
        activeSeasonClick, handleSeasonClick,
        //
        trackLoadedData, trackLoadedData_OverviewInside,
        //track loaded data
        loaded_nearby, setLoaded_nearby,
        setloaded_monthWiseTemp, setLoaded_monthWiseTemp,
        setloaded_10_famousPlaces, setLoaded_10_famousPlaces,
        setloaded_10_famousHotels, setLoaded_10_famousHotels

    } = useAboutDestinationPlanTrip()


    const modified_state_name = stateName && stateName.toLowerCase().replace(/\s+/g, '_');

    
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 799);

    useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 799); // Update state on window resize
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);


    //!1
    const divRef_nearby = useRef(null);
    let isLoaded_nearby = false;

    useEffect(() => {
        const handleScroll = () => {
            if (!isLoaded_nearby && !loaded_nearby && divRef_nearby.current) {
                const bounding = divRef_nearby.current.getBoundingClientRect();
                const isIntersecting = bounding.top < window.innerHeight && bounding.bottom >= 0;
                if (isIntersecting) {
                    // Call your function when the div is visible
                    // console.log("Nearby Div is visible");
                    loadData_OverviewScroll("nearby")
                    isLoaded_nearby = true;
                    setLoaded_nearby(true);
                    // Call your function here
                    // YourFunction();
                } else {
                    // console.log("Div is not visible");
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    //!2
    const divRef_monthWiseTemp = useRef(null);
    let isLoaded_monthWiseTemp = false;

    useEffect(() => {
        const handleScroll = () => {
            if(!isLoaded_monthWiseTemp && !setloaded_monthWiseTemp && divRef_monthWiseTemp.current){
                const bounding = divRef_monthWiseTemp.current.getBoundingClientRect();
                const isIntersecting = bounding.top < window.innerHeight && bounding.bottom >= 0;
                if (isIntersecting) {
                    // Call your function when the div is visible
                    // console.log("monthWiseTemp Div is visible");
                    loadData_OverviewScroll("monthWiseTemp")
                    isLoaded_monthWiseTemp = true;
                    setLoaded_monthWiseTemp(true)
                    // Call your function here
                    // YourFunction();
                }else {
                    // console.log("Div is not visible");
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    //!3
    const divRef_10_famousPlaces = useRef(null);
    let isLoaded_10_famousPlaces = false;

    useEffect(() => {
        const handleScroll = () => {
            if (!isLoaded_10_famousPlaces && !setloaded_10_famousPlaces && divRef_10_famousPlaces.current) {
                const bounding = divRef_10_famousPlaces.current.getBoundingClientRect();
                const isIntersecting = bounding.top < window.innerHeight && bounding.bottom >= 0;
                if (isIntersecting) {
                    // Call your function when the div is visible
                    // console.log("top10_famousPlaces Div is visible");
                    loadData_OverviewScroll("top10_famousPlaces")
                    isLoaded_10_famousPlaces = true;
                    setLoaded_10_famousPlaces(true)
                    // Call your function here
                    // YourFunction();
                } else {
                    // console.log("Div is not visible");
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    //!4
    const divRef_10_famousHotels = useRef(null);
    let isLoaded_10_famousHotels = false;

    useEffect(() => {
        const handleScroll = () => {
            if (!isLoaded_10_famousHotels && !setloaded_10_famousHotels && divRef_10_famousHotels.current) {
                const bounding = divRef_10_famousHotels.current.getBoundingClientRect();
                const isIntersecting = bounding.top < window.innerHeight && bounding.bottom >= 0;
                if (isIntersecting) {
                    // Call your function when the div is visible
                    // console.log("top10_famousHotels Div is visible");
                    loadData_OverviewScroll("top10_famousHotels")

                    isLoaded_10_famousHotels = true;
                    setLoaded_10_famousHotels(true)
                    // Call your function here
                    // YourFunction();
                } else {
                    // console.log("Div is not visible");
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    

    // showActiveScroll
    const[showActiveScroll, setShowActiveScroll]=useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowActiveScroll(true);
        }, 6000); // 6000 milliseconds = 6 seconds

        // return () => clearTimeout(timeout);
    }, []);


    //!scroll
    const containerRef = useRef()

    const scrollLeft = () => {
        if(containerRef.current){
            containerRef.current.scrollBy({
                left: -750,
                behavious: 'smooth',
            })
        }
    }

    const scrollRight = () => {
        if(containerRef.current){
            containerRef.current.scrollBy({
                left: 750,
                behavious: 'smooth',
            })
        }
    }


  return (
    <div className='container_p-main-Overview_D'>

        { loadingStatus_AboutDestination &&
            // <div
            // className='loading-1-data-PromptAndOverview'
            // >
            //   Loading...
            // </div>
            <div className="overlay">
                <div className="spinner-container">
                    <div className="spinner"></div>
                    <div
                    className='loading-1-data-PromptAndOverview'
                    >
                        <p>Loading...</p>
                    </div>
                </div>
            </div>
        }

        {/* Overview_D
 <br></br>
    <button onClick={test}>A void button</button>
    {
        trackLoadedData_OverviewInside.map(data => {
            return(
                <div>
                    {data} <br></br>
                </div>     
            )
        })
    } <br></br> */}
    
    {/* //* Overview */}
    {/* { overviewPlaceData && (

        <div className='container_t-OverViewPlace-Overview_D'>

            <div className='heading-text-1-OverViewPlace-Overview_D'>
                Overview
            </div> 


            <div 
            // className='famousPlacesButtons-box3-1'
            >
                {
                overviewPlaceData.map((data, index) => {
                
                    return(   
                    <div key={index}
                    className='content-text-1-OverViewPlace-Overview_D'
                    >   
                        {data.content_overview.replace(/- /g, '')}
                    </div>   
                    )
                })
                }
            </div> 

        </div>
        
        ) 
    } */}


    {/* //* bestTime Overview */}
    { seasonRankData && exploreSeasonData ? (

    <div 
    className='container_p-bestTime-Overview_D'
    >

        <div
        className='container_s-imageText-bestTime-Overview_D'
        >
            <div
            className='container_t-bestTime-Overview_D'
            >
                <img 
                className='image-bestTime-Overview_D'
                src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/about_destination_6.png'
                />
            </div>     
            <div className='heading-bestTime-Overview_D'>
                Best Time to Explore
            </div> 
        </div>     

        <div className='container_s-content-bestTime-Overview_D'>

            {
                seasonRankData.map((data, index) => {
                return exploreSeasonData.map((seasonData) => {

                        if(seasonData.content_season.toLowerCase() === data.best_season_1.toLowerCase()){
                            return(
                                <div 
                                style={{alignItems:'start', textAlign:'start'}}
                                >
                                    {
                                        !isMobile ?
                                    
                                    <div
                                    className='content-1-bestTime-Overview_D'>
                                       
                                        {/* {data.best_season_1} 
                                        &nbsp; */}

                                        {
                                            data.best_season_1 === "Summer" ?
                                            <img className='icon-season-bestTime-AboutDestination'
                                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/summer.png'
                                            />    
                                            : 
                                            data.best_season_1 === "Winter" ?
                                            <img className='icon-season-bestTime-AboutDestination'
                                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/winter.png'
                                            />  
                                            :
                                            data.best_season_1 === "Monsoon" ?
                                            <img className='icon-season-bestTime-AboutDestination'
                                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/monsoon-2.png'
                                            />   
                                            :
                                            data.best_season_1 === "Post Monsoon" ?
                                            <img className='icon-season-bestTime-AboutDestination'
                                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/post-monsoon.png'
                                            />    
                                            :
                                            null
                                        }
                                         &nbsp;
                                         {data.best_season_1} 
                                         &nbsp;
                                         is the best time to explore {tripDestinationName}
                                    </div> 

                                    :

                                    <div
                                    className='content-1-bestTime-Overview_D'
                                    style={{textAlign:'center'}}
                                    >
                                       
                                        {
                                            data.best_season_1 === "Summer" ?
                                            <img className='icon-season-bestTime-AboutDestination'
                                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/summer.png'
                                            />    
                                            : 
                                            data.best_season_1 === "Winter" ?
                                            <img className='icon-season-bestTime-AboutDestination'
                                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/winter.png'
                                            />  
                                            :
                                            data.best_season_1 === "Monsoon" ?
                                            <img className='icon-season-bestTime-AboutDestination'
                                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/monsoon-2.png'
                                            />   
                                            :
                                            data.best_season_1 === "Post Monsoon" ?
                                            <img className='icon-season-bestTime-AboutDestination'
                                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/post-monsoon.png'
                                            />    
                                            :
                                            null
                                        }
                                        &nbsp;
                                        {data.best_season_1}
                                        &nbsp;
                                        is the best time to explore {tripDestinationName}
                                    </div> 


                                    }
                                    
            
                                    <div className='content-2-bestTime-aboutDestination'>
                                        {seasonData.content_brief_overview} 
                                    </div>    
                                     
                                    {/* <br></br> */}

                                    {/* <div
                                    className='content-3-bestTime-aboutDestination'>
                                        {seasonData.content_what_to_expect} 
                                    </div> */}

                                    <div className='content-3-bestTime-aboutDestination'>
                                        <ul>
                                            {seasonData.content_what_to_expect.split('.').map((sentence, index) => 
                                            sentence.trim() && 
                                            <li key={index}
                                            style={{fontSize:'16px'}}
                                            >
                                                {sentence.trim()}.
                                            </li>
                                            )}
                                        </ul>
                                    </div>


                                </div>    
                            )
                        }

                    })
                })

            }

        </div> 

    </div>

    ) : null
    }



    {/* //* Place Duration */}
    {/* //?Hidden for now. Why? The data mihgt be too estimated, and the real user data/plan can have significant divergence.  */}
    { false && placeDurationData && (

        <div className='container_p-duration-aboutDestination'>

            <div className='container_s-image-duration-aboutDestination'>
                <div
                className='container_t--image-duration-aboutDestination'
                >
                    <img 
                    className='image-duration-aboutDestination'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/about_destination_4.jpg'
                    />
                </div>     
            </div> 

            <div
              style={{alignItems:'start', textAlign:'start'}}
            >
                <div className='heading-duration-aboutDestination'>
                    Duration To Explore
                </div> 

                {
                placeDurationData.map((data, index) => {
                
                    return(   
                    <ul key={index}
                    style={{alignItems:'start', textAlign:'start'}}
                    >  

                        {
                            data.duration_most_famous_places &&

                            <li className='content-1-duration-aboutDestination'>
                                <b>{data.duration_most_famous_places - 1} - {data.duration_most_famous_places}</b> days to explore most-famous/must-visit places
                            </li>
                        }

                        {
                            data.duration_famous_places &&

                            <li className='content-1-duration-aboutDestination'>
                                <b>{data.duration_famous_places - 1} - {data.duration_famous_places}</b> days to explore famous places
                            </li>
                        }

                        {
                            data.duration_places &&

                            <li className='content-1-duration-aboutDestination'>
                                <b>{data.duration_places - 1} - {data.duration_places}</b> days to explore all the tourist places
                            </li>
                        }

                        
                    </ul>   
                    )
                })
                }
            </div>     

        </div>
    )}



    {/* //!4-scrollLoadData */}
    {showActiveScroll && !trackLoadedData_OverviewInside.includes("top10_famousPlaces") &&
        <div 
            id="top10_famousPlaces"
            ref={divRef_10_famousPlaces}
            style={{ display: trackLoadedData_OverviewInside.includes("top10_famousPlaces") ? "none" : "block" }}
        > 
            {/* <b>Trigger</b> Load top10_famousPlaces Data */}
        </div>
    }


    {/* <div
    style={{width:'70%'}}
    >
        <div className='container_t-1'>

            hi this is the name

        </div>

        <div className='container_t-2'>

            
        { famousPlaces_T10_Data.data1 &&
            famousPlaces_T10_Data.data1.map((data, index) => {
            return(
            <div
            className='card-place-Overview_D_Top10' 
            >

                <br></br>
                <img />

                <div>
                    {data.name} 
                </div> 

            </div>

                )
            }
        )}

        </div>
    </div>
 */}
    {/* <br></br><br></br> */}


{/* <div style={{width:'100%'}}> */}
    {/* //!Top 10 */}

    {/* //!Tourist Places */}
    {/* //* famousPlaces_T10_Data */}

    <div className='container_p-places-top10-Overview_D_Top10'>

        <div className='container_s-places-top10-Overview_D_Top10'>

            <div className='heading-places-Overview_D_Top10'>
                Everything Famous
            </div> 

            <img 
            className='image-places-Overview_D_Top10'
            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/about_destination_2.png'
            />
        
        </div>  

        {/* //! Tourist Places - 1 */}
        { famousPlaces_T10_Data && famousPlaces_T10_Data.data1 && 

            (
                <TouristPlaces_Top10
                famousPlaces_T10_Data={famousPlaces_T10_Data}
                tripDestinationType={tripDestinationType}
                tripDestinationName={tripDestinationName}
                stateName={stateName}
                />
            )

        }

        <br></br>
        <br></br>
     
        {/* //! Hotels - 2 */}
        { hotel_T10_Data && hotel_T10_Data.length > 0 && 

            (
                <Hotels_Top10 
                hotel_T10_Data={hotel_T10_Data}
                hotel_about_data={hotel_about_data}
                tripDestinationType={tripDestinationType}
                />
            )

        }

    </div>



    {/* //!4-scrollLoadData */}
    {famousPlaces_T10_Data && famousPlaces_T10_Data.data1 && famousPlaces_T10_Data.data1.length > 0  && !trackLoadedData_OverviewInside.includes("top10_famousHotels") &&  
        <div 
        id="top10_famousHotels"
        ref={divRef_10_famousHotels}
        style={{ display: trackLoadedData_OverviewInside.includes("nearby") ? "none" : "block" }}
        > 
            {/* <b>Trigger</b> Load top10_famousHotels Data */}
        </div>
    }
  
        

        {/* //!4-scrollLoadData */}
        {showActiveScroll && !trackLoadedData_OverviewInside.includes("monthWiseTemp") &&
            <div 
                id="monthWiseTemp"
                ref={divRef_monthWiseTemp}
                style={{ display: trackLoadedData_OverviewInside.includes("nearby") ? "none" : "block" }}
            > 
                {/* <b>Trigger</b> Load monthWiseTemp Data */}
            </div>
        }

        {/* //* Explore 4 Seasons  */}

        { seasonRankData && exploreSeasonData && (

        // <div className='conatiner_p-exploreSeason-Overview_D-3'>    

        <div className='conatiner_p-exploreSeason-Overview_D_3'>

            {/*//!Container 1 start */}
            <div 
            className='container_t-1-SeasonExplore-Overview_D_3'
            >
                <div className='heading-SeasonExplore-Overview_D_3'>
                    Explore Different Seasons
                </div> 

                <div
                className='image-SeasonExplore-Overview_D_3'
                />

            </div>  
            {/*//!Container 1 end */}

            {/* //!content container 2 start */}
            <div
            className='container_t-2-SeasonExplore-Overview_D_3'
            >

                <div className='heading-content-SeasonExplore-Overview_D_3'>
                    {tripDestinationName} in
                </div>   
                
                {
                seasonRankData.map((data, index) => { 
                    return(
                        <div
                        className='conatiner_t-buttons-SeasonExplore-Overview_D_3'
                        >
                        {
                            data.best_season_1 &&
                            <div>
                                <button 
                                onClick={()=>handleSeasonClick(data.best_season_1)}
                                className={activeSeasonClick.toLowerCase() === data.best_season_1.toLowerCase() ? 'button-active-exploreSeason-Overview_D-3' : 'button-unActive-exploreSeason-Overview_D-3'}
                                >
                                    {data.best_season_1}
                                </button>  
                            </div>
                        }

                        {
                            data.best_season_2 &&
                            <div>
                                <button 
                                onClick={()=>handleSeasonClick(data.best_season_2)}
                                className={activeSeasonClick.toLowerCase() === data.best_season_2.toLowerCase() ? 'button-active-exploreSeason-Overview_D-3' : 'button-unActive-exploreSeason-Overview_D-3'}
                                >
                                    {data.best_season_2}
                                </button>  
                            </div>
                        }

                        {
                            data.best_season_3 &&
                            <div>
                                <button 
                                onClick={()=>handleSeasonClick(data.best_season_3)}
                                className={activeSeasonClick.toLowerCase() === data.best_season_3.toLowerCase() ? 'button-active-exploreSeason-Overview_D-3' : 'button-unActive-exploreSeason-Overview_D-3'}
                                >
                                    {data.best_season_3}
                                </button>  
                            </div>
                        }

                        {
                            data.avoid_season_1 &&
                            <div>
                            <button 
                            onClick={()=>handleSeasonClick(data.avoid_season_1)}
                            className={activeSeasonClick.toLowerCase() === data.avoid_season_1.toLowerCase() ? 'button-active-exploreSeason-Overview_D-3' : 'button-unActive-exploreSeason-Overview_D-3'}
                            >
                                {data.avoid_season_1}
                            </button>  
                            </div>
                        }

                        {
                            data.avoid_season_2 &&
                            <div>
                            <button 
                            onClick={()=>handleSeasonClick(data.avoid_season_2)}
                                className={activeSeasonClick.toLowerCase() === data.avoid_season_2.toLowerCase() ? 'button-active-exploreSeason-Overview_D-3' : 'button-unActive-exploreSeason-Overview_D-3'}
                            >
                                {data.avoid_season_2}
                            </button>  
                            </div>
                        }

                        </div>
                    )
                })
                }

                <div className='content-SeasonExplore-Overview_D_3'
                >

                {
                exploreSeasonData.map((seasonData, index) => {

                    if(seasonData.content_season === activeSeasonClick.toLowerCase()){
                        return(
                            <div id="parentContainer">

                                {
                                    seasonData.content_brief_overview &&
                                    <div
                                     className='container_s-content-seasonExplore-Overview_D'
                                    >
                                        <div 
                                        className='heading-content-seasonExplore-Overview_D'
                                        >
                                        Overview
                                        </div>   

                                        <div
                                        className='text-content-seasonExplore-Overview_D'
                                        >
                                            {seasonData.content_brief_overview} 
                                        </div>  
                                    </div>    
                                }

                                {
                                    seasonData.content_what_to_expect &&
                                    <div
                                       className='container_s-content-seasonExplore-Overview_D'
                                    >
                                        <div
                                        className='heading-2-content-seasonExplore-Overview_D'
                                        >
                                            What to Expect?
                                        </div>   

                                        <div
                                        className='text-content-seasonExplore-Overview_D'
                                        >
                                            {seasonData.content_what_to_expect} 
                                        </div>

                                    </div>    
                                }

                                
                                {
                                    seasonData.content_temperature_variation &&
                                    <div
                                       className='container_s-content-seasonExplore-Overview_D'
                                    >
                                        <div
                                        className='heading-content-seasonExplore-Overview_D'
                                        >
                                            Temperature Variation
                                        </div>   

                                        <div
                                        className='text-content-seasonExplore-Overview_D'
                                        >
                                            {seasonData.content_temperature_variation} 
                                        </div> 

                                    </div>    
                                }

                                {
                                    seasonData.content_cautions &&
                                    <div
                                       className='container_s-content-seasonExplore-Overview_D'
                                    >
                                        <div
                                        className='heading-3-content-seasonExplore-Overview_D'
                                        >
                                            Caution!
                                        </div>   

                                        <div
                                        className='text-content-seasonExplore-Overview_D'
                                        >
                                            {seasonData.content_cautions} 
                                        </div>  
                                    </div>    
                                }

                                {/* {seasonData.content_brief_overview} 
                                <br></br>
                                {seasonData.content_what_to_expect}
                                <br></br>
                                {seasonData.content_cautions} 
                                <br></br>
                                {seasonData.content_temperature_variation} */}

                            </div>    
                        )
                    }
                        
                })   
                }
                </div>    

            </div>
            {/* //!content container 2 end */}

        </div> //!main end
        )}

    

        {/* //* Month Wise Data  */}

        {/* //!4-scrollLoadData */}
        { monthTempData && (

        <div className='conatiner_p-monthWise-Overview_D_3'>

            {/*//!Container 1 start */}
            <div 
            className='container_t-1-monthWise-Overview_D_3'
            >
                <div className='heading-SeasonExplore-Overview_D_3'>
                    Month Wise Temperature
                </div> 

                <div
                className='image-monthWise-Overview_D_3'
                />

            </div>  
            {/*//!Container 1 end */}

            <div className='container_t-2-monthWise-Overview_D_3'> 

                {
                    monthTempData.map((data, index) => {

                        return(
                            <div className='container_t-cards-monthWise-Overview_D_3'
                            >
                                
                                <div>
                                    <div className='heading-monthName-monthWise-Overview_D_3'> 
                                        {data.month_name} 
                                    </div>     
                                </div>

                                <div>
                                    <div
                                    className='heading-2-monthName-monthWise-Overview_D_3'
                                    >
                                        Day Temperature    
                                    </div> 

                                    <div
                                     className='content-2-monthName-monthWise-Overview_D_3'
                                    > 
                                        {data.day_temperature} 
                                    </div> 

                                </div>   

                                <div>
                                    <div
                                    className='heading-2-monthName-monthWise-Overview_D_3'
                                    >
                                        Night Temperature    
                                    </div> 

                                    <div
                                    className='content-2-monthName-monthWise-Overview_D_3'
                                    > 
                                        {data.night_temperature} 
                                    </div> 
                                </div> 

                                <div>
                                    <div
                                    className='heading-2-monthName-monthWise-Overview_D_3'
                                    >
                                        Rainfall
                                    </div>  

                                    <div
                                    className='content-2-monthName-monthWise-Overview_D_3'
                                    >
                                        {data.rainfall}
                                    </div>       
                                </div> 

                                <div
                                 className='content-3-monthName-monthWise-Overview_D_3'
                                >
                                    {data.conclusion}
                                </div>      

                            </div>    
                        )
                    
                    })
                }
            </div>

        </div>
        )}



        {/* //* Scroll */}
     
            {showActiveScroll && !trackLoadedData_OverviewInside.includes("nearby") &&
                <div 
                    id="nearby"
                    ref={divRef_nearby}
                    style={{ display: trackLoadedData_OverviewInside.includes("nearby") ? "none" : "block" }}
                > 
                    {/* <b>Trigger</b> Load Nearby Data */}
                </div>
            }


        {/* //! Nearby  */}
  
        {/* //* Famous Destinations Heading */}

        <div
        className='conatiner_p-Destinations-Overview_D_4'
        >

            <div
             className='conatiner_t-1-Images-Destinations-Overview_D_4'
            >
                <div>
                    <img 
                    className='image-1-Destinations-Overview_D_4'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/fd_2.png'
                    />
                </div>
                <div>
                    <img 
                    className='image-2-Destinations-Overview_D_4'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/fd_4.jpg'
                    />
                    <img 
                    className='image-3-Destinations-Overview_D_4'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/fd_1.png'
                    />
                </div>
            </div>

            <div className='conatiner_t-2-heading-Destinations-Overview_D_4'>
                {/* <div
                className='heading-Destinations-Overview_D_4'
                >   
                </div> */}
                    <div className='heading-1-Destinations-Overview_D_4'>
                        Famous Destinations in
                    </div>
                    <div className='heading-2-Destinations-Overview_D_4'>
                        {tripDestinationName}
                    </div>
            </div>

            <div className='conatiner_t-3-Image-Destinations-Overview_D_4'>
                <img 
                className='image-4-Destinations-Overview_D_4'
                src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/fd_3.png'
                />
            </div>
        </div>

        {/* //!State   */}
        { tripDestinationType === "state"  && state_famousDistrict && (

            <div className='container_p-district-state-Overview_D_4'>

                <div className='heading-district-state-Overview_D_4'>
                    Famous Districts
                </div> 

                <div className='container_s-districts-state-Overview_D_4'>
                {
                    state_famousDistrict.map((data, index) => {
                        // if(data.destination !== tripDestinationName){
                        return(
                            <div className='card-district-state-Overview_D_4'>

                                <div className='conatiner_t-image-card-district-state-Overview_D_4'>

                                    <div className='overvlay-card-district-state-Overview_D_4'></div>

                                    <img
                                    className='image-card-district-state-Overview_D_4'
                                    // src='https://assets.cntraveller.in/photos/655f4f28e50319e6aa871702/16:9/w_6000,h_3375,c_limit/1418760845'
                                    
                                    src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${index+4}-02.jpg`}
                                    onError={(e) => { e.target.src = 'https://assets.cntraveller.in/photos/655f4f28e50319e6aa871702/16:9/w_6000,h_3375,c_limit/1418760845'; }}
                                    />

                                </div>     

                                <div className='heading-1-card-district-state-Overview_D_4'>
                                    {data.district_name} 
                                </div>     
                                
                                {/* <div className='text-1-card-district-state-Overview_D_4'>
                                    It would take around {data.famous_day_duration} days to explore the district.
                                </div> */}
                                
                                <div
                                className='content-1-card-district-state-Overview_D_4'
                                >
                                    {/* {data.content_overview} */}

                                    {data.content_overview
                                    ? data.content_overview.split(' ').slice(0, 20).join(' ') +
                                    (data.content_overview.split(' ').length > 20 ? '...' : '')
                                    : ''}
                                </div>    

                            </div>    
                        )

                    })
                }
                </div>
            </div>
        )}

        
        { tripDestinationType === "state" && state_famousDistrictDestinations && state_famousDistrictDestinations.length > 0 && (

            <div className='container_p-district-state-Overview_D_4'>

                <div className='heading-district-state-Overview_D_4'>
                    Famous City/Towns in {tripDestinationName}
                </div> 

                <div className='container_s-districts-state-Overview_D_4'>
                {
                    state_famousDistrictDestinations.map((data, index) => {
                        // if(data.destination !== tripDestinationName){
                        return(
                            <div className='card-district-state-Overview_D_4'>

                                <div className='conatiner_t-image-card-district-state-Overview_D_4'>

                                    <div className='overvlay-card-district-state-Overview_D_4'></div>

                                    <img
                                    className='image-card-district-state-Overview_D_4'
                                    // src='https://www.pelago.co/img/products/IN-India/sandakphu-over-night-trek/32ea3c6c-1a89-4cd8-982b-5e253efe5c90_sandakphu-over-night-trek-large.jpg' 

                                    src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${index+30}-02.jpg`}
                                    onError={(e) => { e.target.src = 'https://www.pelago.co/img/products/IN-India/sandakphu-over-night-trek/32ea3c6c-1a89-4cd8-982b-5e253efe5c90_sandakphu-over-night-trek-large.jpg'; }}
                                    />

                                </div>     

                                <div className='heading-1-card-district-state-Overview_D_4'>
                                    {data.destination} 
                                </div>     
                                
                                {/* <div className='text-1-card-district-state-Overview_D_4'>
                                    It would take around {data.famous_day_duration} days to explore the district.
                                </div> */}
                                
                                <div
                                className='content-1-card-district-state-Overview_D_4'
                                >
                                    {/* {data.content_overview} */}

                                    {data.content_overview
                                    ? data.content_overview.split(' ').slice(0, 20).join(' ') +
                                    (data.content_overview.split(' ').length > 20 ? '...' : '')
                                    : ''}
                                </div>    

                            </div>    
                        )

                    })
                }
                </div>

            </div>

        )}



        { tripDestinationType === "state" && state_nearbyStates && (

            <div className='container_p-district-state-Overview_D_4'>

                <div className='heading-district-state-Overview_D_4'>
                    Nearby States
                </div> 

                <div className='container_s-districts-state-Overview_D_4'>
                {
                    state_nearbyStates.map((data, index) => {
                        // if(data.destination !== tripDestinationName){
                        return(
                            <div className='card-district-state-Overview_D_4'>

                                <div className='conatiner_t-image-card-district-state-Overview_D_4'>

                                    <div className='overvlay-card-district-state-Overview_D_4'></div>

                                    <img
                                    className='image-card-district-state-Overview_D_4'
                                    // src='https://i.ibb.co/bvCCn25/Night-Trek-Kalsubai-Peak.jpg'

                                    src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${index+2}-01.jpg`}
                                    onError={(e) => { e.target.src = 'https://plus.unsplash.com/premium_photo-1676218968741-8179dd7e533f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'; }} 
                                    />

                                </div>     

                                <div className='heading-1-card-district-state-Overview_D_4'>
                                    {data.nearby_state_name}
                                </div>     
                                
                                {/* <div className='text-1-card-district-state-Overview_D_4'>
                                    It would take around {data.famous_day_duration} days to explore the district.
                                </div> */}
                                
                                <div
                                className='content-1-card-district-state-Overview_D_4'
                                >
                                    {data.content_overview
                                    ? data.content_overview.split(' ').slice(0, 20).join(' ') +
                                    (data.content_overview.split(' ').length > 20 ? '...' : '')
                                    : ''}
                                </div>    

                            </div>    
                        )

                    })
                }
                </div>

                {/* {
                    state_nearbyStates.map(data => {
                        return(
                            <div> <br></br>
                            {data.nearby_state_name}
                                <br></br>
                                {data.content_overview}

                          
                            </div>    
                        )
                    })
                } */}

            </div>

        )}

        
        {/* //! City  */}

        { tripDestinationType === "city" && city_nearbyCitiesData && city_nearbyCitiesData.length > 0 && (

        <div className='container_p-district-state-Overview_D_4'>

            <div className='heading-district-state-Overview_D_4'>
                Nearby City/Town/Villages
            </div> 

            <div className='container_s-districts-state-Overview_D_4'>
            {
                city_nearbyCitiesData.map((data, index) => {
                    return (
                        <div key={index}
                        className='card-district-state-Overview_D_4'
                        >

                            <div className='conatiner_t-image-card-district-state-Overview_D_4'>

                                <div className='overvlay-card-district-state-Overview_D_4'></div>

                                <img
                                className='image-card-district-state-Overview_D_4'
                                // src='https://assets.cntraveller.in/photos/655f4f28e50319e6aa871702/16:9/w_6000,h_3375,c_limit/1418760845' 

                                src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${index+4}-01.jpg`}
                                onError={(e) => { e.target.src = 'https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/image-random_use.jpeg'; }}

                                />

                            </div>   

                            <div 
                            className='heading-1-card-district-state-Overview_D_4'
                            >
                                {data.nearby_destination}
                            </div>

                            <div
                            className='text-1-card-district-state-Overview_D_4'
                            >
                                {data.nearby_distance}km far from {tripDestinationName}.
                            </div>

                            <div
                            className='content-1-card-district-state-Overview_D_4'
                            >
                                {data.content_overview
                                ? data.content_overview.split(' ').slice(0, 20).join(' ') +
                                (data.content_overview.split(' ').length > 20 ? '...' : '')
                                : ''}
                            </div>    
                            
                        </div>
                    );
                })
            }
            </div>
        </div>
        )}

        {/* //! District  */}

        { tripDestinationType === "district" && district_districtDestinationsData && district_districtDestinationsData.length > 0 && (

        <div className='container_p-district-state-Overview_D_4'>

            <div className='heading-district-state-Overview_D_4'>
                Destinations in {tripDestinationName}
            </div> 
                
            <div className='container_s-districts-state-Overview_D_4'>    
            {
                district_districtDestinationsData.map((data, index) => {
                    if(data.destination !== tripDestinationName){
                    return(
                        <div className='card-district-state-Overview_D_4'>

                            <div className='conatiner_t-image-card-district-state-Overview_D_4'>

                                <div className='overvlay-card-district-state-Overview_D_4'></div>

                                <img
                                className='image-card-district-state-Overview_D_4'
                                // src='https://assets.cntraveller.in/photos/655f4f28e50319e6aa871702/16:9/w_6000,h_3375,c_limit/1418760845' 

                                src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${index+10}-01.jpg`}
                                onError={(e) => { e.target.src = 'https://plus.unsplash.com/premium_photo-1676218968741-8179dd7e533f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'; }}
                                />

                            </div>  

                            <div
                            className='heading-1-card-district-state-Overview_D_4'
                            >
                                {data.destination} 
                            </div>    

                            {/* <div className='text-1-card-district-state-Overview_D_4'>
                                It would take around {data.famous_day_duration} days to explore the district.
                            </div> */}

                        
                            <div
                            className='content-1-card-district-state-Overview_D_4'
                            >
                                {/* {data.content_overview} */}

                                {data.content_overview
                                ? data.content_overview.split(' ').slice(0, 20).join(' ') +
                                (data.content_overview.split(' ').length > 20 ? '...' : '')
                                : ''}
                            </div>  

                        </div>    
                    )
                    }
                })
            }
            </div>
        </div>
        )}

        { tripDestinationType === "district" && district_nearbyDistrictData && district_nearbyDistrictData.length > 0 && (

        <div className='container_p-district-state-Overview_D_4'>

            <div className='heading-district-state-Overview_D_4'>
                Nearby Districts
            </div> 

            <div className='container_s-districts-state-Overview_D_4'>
            {
                district_nearbyDistrictData.map((data, index) => {
                    return(
                        <div
                        className='card-district-state-Overview_D_4'
                        >

                            <div className='conatiner_t-image-card-district-state-Overview_D_4'>

                                <div className='overvlay-card-district-state-Overview_D_4'></div>

                                <img
                                className='image-card-district-state-Overview_D_4'
                                // src='https://assets.cntraveller.in/photos/655f4f28e50319e6aa871702/16:9/w_6000,h_3375,c_limit/1418760845' 

                                src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${index}-01.jpg`}
                                onError={(e) => { e.target.src = 'https://plus.unsplash.com/premium_photo-1676218968741-8179dd7e533f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'; }}
                                />

                            </div>  

                            <div
                            className='heading-1-card-district-state-Overview_D_4'
                            >
                                {data.nearby_district_name}
                            </div>   

                            {
                                data.nearby_district_state_name !== tripDestinationName &&
                                <div className='text-1-card-district-state-Overview_D_4'>
                                    in {data.nearby_district_state_name}
                                </div> 
                            }

                            <div
                            className='content-1-card-district-state-Overview_D_4'
                            >
                                {data.content_overview
                                ? data.content_overview.split(' ').slice(0, 20).join(' ') +
                                (data.content_overview.split(' ').length > 20 ? '...' : '')
                                : ''}
                            </div>    

                        </div>    
                    )
                })
            }
            </div>

        </div>  
        )}




    </div>
  )
}

export default Overview_D









// return (
//   <div>
//     <div id="monthWiseTemp">Load Month Wise Temperature Data</div>
//     <div id="top10_famousPlaces">Load Top 10 Famous Places</div>
//     <div id="top10_famousHotels">Load Top 10 Famous Hotels</div>
//     <div id="nearby">Load Nearby Data</div>
//   </div>
// );
