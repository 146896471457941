import React, {useState, useRef} from 'react'

//importing css
import './TopicSelector_TD.scss'

//import react context
import {useCreateItineraryPlanTrip} from '../../../../Manage/Context/CreateItinerary_Context';

function TopicSelector_TD(props) {

  const{activeItineraryNavigation, setActiveItineraryNavigation, isMobile} = props;

  const {
    dayData, activeDayId, itinerary_AddColumn, itineraryData,

    //*Status
    saveChangesStatus, setSaveChangesStatus,
    
  } = useCreateItineraryPlanTrip()

  const containerRef = useRef(null);

  const handleScrollDay = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    }, 100); // Delay by 100 milliseconds
  };
  

  const[hoverActive_Entity, setHoverActive_Entity] = useState()
  const[hoverActive_DayInfo, setHoverActive_DayInfo] = useState()

  const handleImageLink = (type) => {
    let link;

    if(type === "Tourist Place"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/tourist_place_icon_1.png'
    }
    else if(type === "Hotel"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/hotel_icon_1.png'
    }
    else if(type === "Restaurant"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/restaurant_icon_3.png'
    }
    else if(type === "Bars/Pubs"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/bar_and_pub_icon_3.png'
    }
    else if(type === "Activity"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/activity_icon_3.png'
    }
    else if(type === "Amusement/Water Park"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/amusement_park_icon_3.png'
    }
    else if(type === "Shopping Mall"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/shopping_mall_icon_1.png'
    }
    else if(type === "Street Shopping"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/street_market_icon_3.png'
    }
    else if(type === "Rent Vehicle"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/rent_vehicle_4_icon_1.png'
    }

    return link;
  }

  const handleImageLink_dayInfo = (type) => {
    let link;

    if(type === "Day Overview"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/travel_around_icon_2.png'
    }
    else if(type === "Budget For the Day"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/budget_icon_2.png'
    }
    else if(type === "Travelling Around"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/rent_vehicle_2_icon_1.png'
    }
    else if(type === "Clothes Overview"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/clothes_icon.png'
    }
    else if(type === "Documents Needed"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/documents_icon_1.png'
    }

    return link;
  }

  // const[activeItineraryNavigation, setActiveItineraryNavigation] = useState("entity")

  return (
    <div>
        {
        dayData && 
        <div className='conatiner_p-Navigation-Itinerary_TO'>


          <div
          // style={{position:'relative'}}
          > 


            {/*//!  */}
            <div
            className='container_p-button-addMore-TopicSelector_TD'
            >
              <button
              onClick={()=>{setActiveItineraryNavigation("entity")}}
              className={activeItineraryNavigation === "entity" ? 'button-active-Navigation-Itinerary_TO' : 'button-unActive-Navigation-Itinerary_TO'}
              >
                # Places
              </button>

              <div 
              onMouseOver={()=>setHoverActive_Entity(true)}
              onMouseOut={()=>setHoverActive_Entity(false)}
              className={hoverActive_Entity ? 'conatiner_t-active-dropdownButton-TopicSelector_TD' : 'conatiner_t-dropdownButton-TopicSelector_TD'}
              >
                {/* <button
                className='button-dropdown-Entity-TopicSelector_TD'
                >
                  Add Entity
                </button> */}

                <img 
                  className='image-icon-1-TopicSelector_TD'
                  src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_2.png'
                />
              </div> 

            </div>


            

            {
              hoverActive_Entity &&
              <div
              className='hover-container_p-TopicSelector_TD'
              onMouseOver={()=>setHoverActive_Entity(true)}
              onMouseOut={()=>setHoverActive_Entity(false)}
              >

                {/* <div
                  className='dropdown-Entity-TopicSelector_TD'
                > */}

                  {/*//*1  */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  >

                    {/* <img 
                    className='image-icon-1-TopicSelector_TD'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                    /> */}
                    
                    <button 
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_Entity(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("entity")
                      itinerary_AddColumn(activeDayId, "entity", "tourist_place", null, null, "day", 10, false, "Tourist Place")
                    }}
                    >
                      add Tourist Place + 
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/tourist_place_icon_1.png'
                    src={handleImageLink("Tourist Place")}
                    />

                  </div>   

                  {/*//*2  */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  >

                    <button
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_Entity(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("entity")
                      itinerary_AddColumn(activeDayId, "entity", "hotel", null, null, "day", 10, false, "Hotel")
                    }}
                    >
                      add Hotel + 
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    src={handleImageLink("Hotel")}
                    />

                  </div>  

                  {/*//*3 */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  >
                    {/* <img 
                    className='image-icon-1-TopicSelector_TD'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                    /> */}

                    <button
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_Entity(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("entity")
                      itinerary_AddColumn(activeDayId, "entity", "restaurant", null, null, "day", 10, false, "Restaurant")
                    }}
                    >
                      add Restaurant + 
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                     src={handleImageLink("Restaurant")}
                    />

                  </div>  

                  {/*//*4  */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  >
                    {/* <img 
                    className='image-icon-1-TopicSelector_TD'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                    /> */}

                    <button
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_Entity(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("entity")
                      itinerary_AddColumn(activeDayId, "entity", "bar_and_pub", null, null, "day", 10, false, "Bars/Pubs")
                    }}
                    >
                      add Bar/Pub + 
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                     src={handleImageLink("Bars/Pubs")}
                    />

                  </div>  

                  {/*//*5  */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  >
                    {/* <img 
                    className='image-icon-1-TopicSelector_TD'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                    /> */}

                    <button
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_Entity(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("entity")
                      itinerary_AddColumn(activeDayId, "entity", "activity", null, null, "day", 10, false, "Activity")
                    }}
                    >
                      add Activity + 
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                     src={handleImageLink("Activity")}
                    />

                  </div>  

                  {/*//*6  */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  >
                    {/* <img 
                    className='image-icon-1-TopicSelector_TD'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                    /> */}

                    <button
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_Entity(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("entity")
                      itinerary_AddColumn(activeDayId, "entity", "amusement_or_water_park", null, null, "day", 10, false, "Amusement/Water Park")
                    }}
                    >
                      add Amusement/Water Park + 
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                     src={handleImageLink("Amusement/Water Park")}
                    />

                  </div>  

                  {/*//*7  */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  >
                    {/* <img 
                    className='image-icon-1-TopicSelector_TD'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                    /> */}

                    <button
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_Entity(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("entity")
                      itinerary_AddColumn(activeDayId, "entity", "shopping_mall", null, null, "day", 10, false, "Shopping Mall")
                    }}
                    >
                      add Shopping Mall + 
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                     src={handleImageLink("Shopping Mall")}
                    />
                  </div>  

                  {/*//*8  */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  >
                    {/* <img 
                    className='image-icon-1-TopicSelector_TD'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                    /> */}

                    <button
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_Entity(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("entity")
                      itinerary_AddColumn(activeDayId, "entity", "street_shopping", null, null, "day", 10, false, "Street Shopping")
                    }}
                    >
                      add Street Shopping + 
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                     src={handleImageLink("Street Shopping")}
                    />
                  </div>  

                  {/*//*9  */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  >
                    {/* <img 
                    className='image-icon-1-TopicSelector_TD'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                    /> */}

                    <button
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_Entity(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("entity")
                      itinerary_AddColumn(activeDayId, "entity", "rent_vehicle", null, null, "day", 10, false, "Rent Vehicle")
                    }}
                    >
                      add Rent Vehicles + 
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                     src={handleImageLink("Rent Vehicle")}
                    />
                  </div>  

                {/* </div>  */}
              </div>   
            }

          </div>  
    

          {/*//!Day Info */}
          
          {/*//!  */}
          {
            !isMobile &&
          
            <div
            className='container_p-button-addMore-TopicSelector_TD'
            >
              <button
              onClick={()=>{setActiveItineraryNavigation("day_info")}}
              className={activeItineraryNavigation === "day_info" ? 'button-active-Navigation-Itinerary_TO' : 'button-unActive-Navigation-Itinerary_TO'}
              >
                # Add Documents
              </button>

              <div 
              // onMouseOver={()=>setHoverActive_DayInfo(true)}
              // onMouseOut={()=>setHoverActive_DayInfo(false)}

              onClick={()=>{
                setHoverActive_DayInfo(false)
                handleScrollDay()
                setActiveItineraryNavigation("day_info")
                itinerary_AddColumn(activeDayId, "day_info", "day_trip_documents", null, null, "day", 1, true, "Documents Needed")
              }}
              disabled={
                itineraryData.some(data => data.day_id === activeDayId && data.topic_type === "day_trip_documents")
              }


              className={hoverActive_DayInfo ? 'conatiner_t-active-dropdownButton-TopicSelector_TD' : 'conatiner_t-dropdownButton-TopicSelector_TD'}
              >
                {/* <button
                className='button-dropdown-Entity-TopicSelector_TD'
                >
                  Add Day Info
                </button> */}

                <img 
                  className='image-icon-1-TopicSelector_TD'
                  src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_2.png'
                />

              </div> 

            </div>
          }



          <div
          // style={{position:'relative'}}
          >

              {/* <div 
              onMouseOver={()=>setHoverActive_DayInfo(true)}
              onMouseOut={()=>setHoverActive_DayInfo(false)}
              className={hoverActive_DayInfo ? 'conatiner_t-active-dropdownButton-TopicSelector_TD' : 'conatiner_t-dropdownButton-TopicSelector_TD'}
              >
                <button
                className='button-dropdown-Entity-TopicSelector_TD'
                >
                  Add Day Info
                </button>

                <img 
                  className='image-icon-1-TopicSelector_TD'
                  src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_2.png'
                />

              </div>  */}


              { hoverActive_DayInfo &&
                <div
                onMouseOver={()=>setHoverActive_DayInfo(true)}
                onMouseOut={()=>setHoverActive_DayInfo(false)}
                className='hover-2-container_p-TopicSelector_TD'
                > 

                  {/*//*1  */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  > 
                    <button
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_DayInfo(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("day_info")
                      itinerary_AddColumn(activeDayId, "day_info", "day_overview", null, null, "day", 1, false, "Day Overview")
                    }}
                    disabled={
                      itineraryData.some(data => data.day_id === activeDayId && data.topic_type === "day_overview")
                    }
                    >
                      add Day Overview +
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    src={handleImageLink_dayInfo("Day Overview")}
                    />

                  </div>   

                  {/*//*2  */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  > 
                    <button
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_DayInfo(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("day_info")
                      itinerary_AddColumn(activeDayId, "day_info", "day_budget", null, null, "day", 1, true, "Budget For the Day")
                    }}
                    disabled={
                      itineraryData.some(data => data.day_id === activeDayId && data.topic_type === "day_budget")
                    }
                    >
                      add Budget Overview +
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    src={handleImageLink_dayInfo("Budget For the Day")}
                    />

                  </div>  
                  
                  {/*//*3  */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  > 
                    <button
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_DayInfo(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("day_info")
                      itinerary_AddColumn(activeDayId, "day_info", "travel_around", null, null, "day", 1, false, "Travelling Around")
                    }}
                    disabled={
                      itineraryData.some(data => data.day_id === activeDayId && data.topic_type === "travel_around")
                    }
                    >
                      add Travelling around + 
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    src={handleImageLink_dayInfo("Travelling Around")}
                    />
                    
                  </div>  

                  {/*//*4  */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  > 
                    <button
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_DayInfo(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("day_info")
                      itinerary_AddColumn(activeDayId, "day_info", "what_to_wear", null, null, "day", 1, false, "Clothes Overview")
                    }}
                    disabled={
                      itineraryData.some(data => data.day_id === activeDayId && data.topic_type === "what_to_wear")
                    }
                    >
                      add What to Wear +
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    src={handleImageLink_dayInfo("Clothes Overview")}
                    />

                  </div>  

                  {/*//*5  */}
                  <div
                  className='conatiner_t-topicButton-TopicSelector_TD'
                  > 
                    <button
                    className='button-Entity-TopicSelector_TD'
                    onClick={()=>{
                      setHoverActive_DayInfo(false)
                      handleScrollDay()
                      setActiveItineraryNavigation("day_info")
                      itinerary_AddColumn(activeDayId, "day_info", "day_trip_documents", null, null, "day", 1, true, "Documents Needed")
                    }}
                    disabled={
                      itineraryData.some(data => data.day_id === activeDayId && data.topic_type === "day_trip_documents")
                    }
                    >
                      add Documents Needed +
                    </button>

                    <img 
                    className='image-icon-1-TopicSelector_TD'
                    src={handleImageLink_dayInfo("Documents Needed")}
                    />

                  </div>

                </div>   
              }  

          </div>   


          {/*//!Notes*/}
          {/* <div
          className='conatiner_t-2-dropdownButton-TopicSelector_TD'
            onClick={()=>{
              handleScrollDay()
              setActiveItineraryNavigation("note")
              itinerary_AddColumn(activeDayId, "note", "simple_note", null, null, "day", 10, false, "Note")
            }}
          > 

            <button
            className='button-2-dropdown-Entity-TopicSelector_TD'
            >
              Add Notes
            </button>

            <img 
              className='image-icon-1-TopicSelector_TD'
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_2.png'
            />

          </div>   */}



            
            {/*//!  */}
            <div
            className='container_p-button-addMore-TopicSelector_TD'
            >
              <button
              onClick={()=>{setActiveItineraryNavigation("note")}}
              className={activeItineraryNavigation === "note" ? 'button-active-Navigation-Itinerary_TO' : 'button-unActive-Navigation-Itinerary_TO'}
              >
                # Day Notes
              </button>

              <div
              className='conatiner_t-2-dropdownButton-TopicSelector_TD'
                onClick={()=>{
                  handleScrollDay()
                  setActiveItineraryNavigation("note")
                  itinerary_AddColumn(activeDayId, "note", "simple_note", null, null, "day", 10, false, "Note")
                }}
              > 

                {/* <button
                className='button-2-dropdown-Entity-TopicSelector_TD'
                >
                  Add Notes
                </button> */}

                <img 
                  className='image-icon-1-TopicSelector_TD'
                  src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_2.png'
                />

              </div>  

            </div>



            {/*//!  */}
            <div
            className='container_p-button-addMore-TopicSelector_TD'
            >
              
              <button
              onClick={()=>{setActiveItineraryNavigation("checklist")}}
              className={activeItineraryNavigation === "checklist" ? 'button-active-Navigation-Itinerary_TO' : 'button-unActive-Navigation-Itinerary_TO'}
              >
                # Day Checklist
              </button>


              {/*//!Checklist */} 
              <div
                className='conatiner_t-2-dropdownButton-TopicSelector_TD'
                onClick={()=>{
                  handleScrollDay()
                  setActiveItineraryNavigation("checklist")
                  itinerary_AddColumn(activeDayId, "checklist", "simple_checklist", null, null, "day", 10, false, "Checklist")
                }}
              > 

                {/* <button
                className='button-2-dropdown-Entity-TopicSelector_TD'
                >
                  Add Checklist
                </button> */}

                <img 
                  className='image-icon-1-TopicSelector_TD'
                  src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_2.png'
                />

              </div>  

            </div>




          {/*//!Checklist */} 
          {/* <div
            className='conatiner_t-2-dropdownButton-TopicSelector_TD'
            onClick={()=>{
              handleScrollDay()
              setActiveItineraryNavigation("checklist")
              itinerary_AddColumn(activeDayId, "checklist", "simple_checklist", null, null, "day", 10, false, "Checklist")
            }}
          > 

            <button
            className='button-2-dropdown-Entity-TopicSelector_TD'
            >
              Add Checklist
            </button>

            <img 
              className='image-icon-1-TopicSelector_TD'
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_2.png'
            />

          </div>   */}


        </div>  
        // main container
      }
    </div>
  )
}

export default TopicSelector_TD