import React, {useState, useEffect, useRef} from 'react'

//importing css
import './Entities_TD.scss'
import './Entities_TD_Popup.scss'
import './GoogleSearchPlaces.scss'

//import react context
import {useCreateItineraryPlanTrip} from '../../../../../../Manage/Context/CreateItinerary_Context';
import {useCreateItinerary_2_PlanTrip} from '../../../../../../Manage/Context/CreateItinerary_Context_2';

import Notes_Tool from '../../../../../../../../Global Tool - Plan Trip/Notes/Notes_Tool';
import TipTap_ReadOnly from '../../../../../../../../Global Tool - Plan Trip/Notes/TestingLexical/TipTap_ReadOnly';

//import icon
import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineEdit } from "react-icons/ai";
import { TbEdit } from "react-icons/tb";
import { IoMdRemoveCircle } from "react-icons/io";
import { IoMdCloseCircle } from "react-icons/io";
import AiItinerary_Chat from '../../../AiItinerary_Chat/AiItinerary_Chat';


function Entities_TD(props) {

  const {visibility_type, different_design, topic_type, topic_id} = props;
  
  const{saveChangesStatus, setSaveChangesStatus}=props;

  const {
    showSavedData_Status, setShowSavedData_Status, active_savedPlacesData_itineraryTempHide, itineraryData, hoverTopicId,
    //
    loadingPlaceName_status, setLoadingPlaceName_status,
    //
    activeAddTopicDetailType, setActiveAddTopicDetailType,
    //
    handleQuill_NoteInput, textLength, handleTextLength, handleDayInfo_FitinMaxLimit_NoteInput,
    handleActiveWondow_Notes, activeWindow_Notes, hideNote_Temp, setHideNote_Temp, handleHideNoteTemp, 

    //
    activeDayId, setActiveDayId,
    activeTopicId, setActiveTopicId,

    //map
    fitAllLocations, setFitAllLocations,
    hoverItineraryData, setHoverItineraryData,
    placeClicked_map, setPlaceClicked_map,

    //google search 
    placeNamesSugesstions, setPlaceNamesSugesstions,
    
    //google search
    handleGetSuggestions, handleSuggestionNameClicked, handleInput_PlaceName,


    //!Functions   
    handleMap_HoverClick, handleDisplaySavedPlaces,
    //*handle select from saved places data

    //!Overall
    itinerary_DeleteColumn,

    //!--------------
    //!Entity-01
    handleShowSavedData, active_savedPlacesData_itinerary,
    //*Handle Itinerary Input Data 
    //?name
    handleName_SavedPlaceSelected, handleName_Input,
    //note
    handleNote_NoteStatus,
    handleNote_NoteInput,
    handleNote_RemoveNote,
    handleNote_ClearNote,
    //?
    handleVisitType,
    //?budget
    handleBudget_Status,
    handleBudget_RemoveBudget,
    handleBudget_InputBudget,
    handleBudget_InputCurrency,
    handleBudget_InputType, 
    //?time interval 
    handleTimeInterval_Status,
    handleTimeInterval_RemoveTimeInterval,
    handleStartTimeChange,
    handleEndTimeChange, 
    startTimeData, endTimeData
  } = useCreateItineraryPlanTrip()



  const {
    //!States

    //track AI questions asked
    askQuestionCount_day,

    //*navigate
    navigate_aiItineraryChat, setNavigate_aiItineraryChat,
    aiItineraryChat_window, setAiItineraryChat_window,
    
  } = useCreateItinerary_2_PlanTrip()


  // const active_savedPlacesData_itinerary = [
  //   {"name": "Place A", "id":"1"}, {"name":"Place B", "id":"1"}, {"name":"Place C", "id":"1"}, {"name":"Place D", "id":"1"}, {"name":"Place E", "id":"1"}
  // ]


  const calculateDuration = (startTime, endTime) => {
    const start = new Date(`01/01/2022 ${startTime}`);
    const end = new Date(`01/01/2022 ${endTime}`);
    const durationMs = end - start;
    const durationMinutes = Math.abs(Math.round(durationMs / 60000));
    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;

    let durationString = '';
    if (hours > 0) {
      durationString += `${hours} hour${hours !== 1 ? 's' : ''}`;
    }
    if (minutes > 0) {
      durationString += `${durationString.length > 0 ? ' ' : ''}${minutes} minute${minutes !== 1 ? 's' : ''}`;
    }

    return durationString.length > 0 ? durationString : '0 minutes';
  };


  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
      setActiveAddTopicDetailType(null);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  const handleImageLink = (type) => {
    let link;

    if(type === "Tourist Place"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/tourist_place_icon_1.png'
    }
    else if(type === "Hotel"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/hotel_icon_1.png'
    }
    else if(type === "Restaurant"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/restaurant_icon_3.png'
    }
    else if(type === "Bars/Pubs"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/bar_and_pub_icon_3.png'
    }
    else if(type === "Activity"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/activity_icon_3.png'
    }
    else if(type === "Amusement/Water Park"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/amusement_park_icon_3.png'
    }
    else if(type === "Shopping Mall"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/shopping_mall_icon_1.png'
    }
    else if(type === "Street Shopping"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/street_market_icon_3.png'
    }
    else if(type === "Rent Vehicle"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/rent_vehicle_4_icon_1.png'
    }

    return link;
  }

  const handleGetCurrency = (currencyName) => {
    let currency;

    if(currencyName === "inr"){
      currency = '₹'
    }
    else if(currencyName === "dollar"){
      currency = '$'
    }

    return currency;
  }


  //after editing and updating the new note, the latest one is not visible, so we refresh it...
  const[displayLatestNote, setDisplayLatestNote] = useState(true)

  const handleDisplayUpdatedNotes = () => {
    setDisplayLatestNote(false);
    setTimeout(() => {
      setDisplayLatestNote(true);
    }, 200);
  };



  function formatNumberWithCommas(number) {
    if (isNaN(number)) {
      return 0; // Handle non-numeric values gracefully
  }

    const parts = number.toString().split('.');
    let wholePart = parts[0];

    // Reverse the string to handle commas from the last digit
    wholePart = wholePart.split('').reverse().join('');

    // Insert commas after every group of 2 digits, but not at the end
    wholePart = wholePart.replace(/(\d{2})(?=(\d{2})+(?!\d))/g, '$1,');

    // Reverse the string back to normal order
    wholePart = wholePart.split('').reverse().join('');

    // Adjust the first comma for the first three digits group
    if (wholePart.length > 6) {
        wholePart = wholePart.replace(/(\d+),/, '$1,');
    }

    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    return wholePart + decimalPart;
  }

  //handle closing of the google search places container:
  const containerRef = useRef(null);
  const[displaySearchResult, setDisplaySearchResult] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setDisplaySearchResult(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className='container_p-Entities_TD'>
     
      { 
        !different_design && itineraryData &&

        itineraryData.map((data, index) => {
          if(data.topic_id === topic_id){
            return(
              <div key={index}
              onMouseOver={()=>{handleMap_HoverClick(data.topic_id, data.lat, data.lng, data.display_name, index, data.name)}}
              onMouseOut={()=>{setHoverItineraryData([])}}
              onClick={()=>{setPlaceClicked_map(data.topic_id)}}
              style={{position:'relative'}}
              >

                <div className='text-index-Entities_TD'>{index}</div>
                
                {/*//! #1  */}
                {/* topic type and delete topic */}
                <div
                className='conatiner_p-head-Checklist_TD'
                > 

                  <div
                  className='conatiner_t-displayName_Icon-head-Checklist_TD'
                  >
                    <div
                    className='text-head-Checklist_TD'
                    >
                      {data.display_name}
                    </div> 

                    <div>
                      <img
                      // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/note_icon_6.png'
                      src={handleImageLink(data.display_name)}
                      className='icon-checklist-Checklist_TD'
                      />
                    </div>      
                  </div> 

                  <div
                  style={{display:'flex', flexDirection:'row'}}
                  >

                    { data.display_name !== "Hotel" &&
                    (data.saved_place_id || data.name) &&

                      <div
                      className='container_p-tripStatus-Entities_TD'
                      > 

                        <div
                        className='heading-1-tripStatus-Entities_TD'
                        >
                          Trip Status
                        </div>   
                      
                        <div
                        className="container_p-select-tripStatus-Entities_TD"
                        >
                          {/* <div
                          className='heading-1-tripStatus-Entities_TD'
                          >
                            Trip Status
                          </div>    */}

                          <select
                            value={data.topic_visit_type}
                            onChange={(event)=>{
                              handleVisitType(event, data.topic_id, data.day_id)
                              setSaveChangesStatus(true)
                            }}
                            className="select-tripStatus-Entities_TD"
                          >
                            <option value={null} >
                              Select
                            </option>

                            <option value="must_visit">
                              Must Visit
                            </option>

                            <option value="interested">
                              Interested
                            </option>

                            <option value="may_be">
                              May Be
                            </option>

                            <option value="not_sure">
                              Not Sure
                            </option>
                          </select>
                        </div>   
                        

                        {/* //*Indication for must visit */}
                        {
                          data.topic_visit_type === "must_visit" &&
                          <div>
                            ⭐
                          </div>   
                        }

                      </div>  

                    }

                    <button 
                      onClick={()=>{
                        itinerary_DeleteColumn(data.topic_id, data.day_id)
                        setSaveChangesStatus(true)
                      }}
                      className='icon-delete-Checklist_TD'
                    >
                      <RiDeleteBin5Line />
                    </button>
                  </div>     

                </div>  
            
                {/* <div> */}
                {/*//! #2  */}
                {/* input name or add from saved places */}

                <div
                //  ref={containerRef}
                >

                  {/*//? Input Name */}
                  {/* {
                    !data.saved_place_id &&
                    <div>
                      <input 
                      value={data.name}
                      onChange={(event)=>{
                        handleName_Input(event, data.topic_id, data.day_id)
                        setSaveChangesStatus(true)
                      }}
                      placeholder={`enter ${data.display_name} name ...`}
                      maxLength={50}
                      type='text'
                      className='input-1-description-Note_TD'
                      />
                    </div>    
                  }  */}

                  {
                    !data.saved_place_id &&
                    <div>
                      <input 
                        // value={data.name}
                        value={data.name && data.name.split(',')[0]}
                        onChange={(event)=>{
                          handleName_Input(event, data.topic_id, data.day_id)
                        }}
                        onClick={()=>{setDisplaySearchResult(true)}}
                        placeholder={`enter ${data.display_name} name ...`}
                        maxLength={50}
                        type='text'
                        className='input-1-searchPlaceName-GoogleSearchPlaces'
                        disabled={data.name_source === "google"}
                      />
                    </div>    
                  } 

                  {
                    displaySearchResult && activeTopicId === data.topic_id &&
                    <div className='input-1-results-searchPlaceName-GoogleSearchPlaces'
                    ref={containerRef}
                    >
                      
                      {loadingPlaceName_status === "no places found" && 
                        <div className='no-results'>
                          No places found
                        </div>
                      }

                      {loadingPlaceName_status === "loading" && 
                        <div className='loading'>
                          Loading names...
                        </div>
                      }

                      <div className='info-text'>
                        You won't be able to edit the name, once selected.
                      </div>

                      {placeNamesSugesstions && placeNamesSugesstions.length > 0 &&
                        placeNamesSugesstions.map((gData, gIndex) => {
                          return (
                            <button 
                              key={gIndex}
                              className='place-suggestion-button'
                              onClick={() => {
                                handleSuggestionNameClicked(gData.description, gData.place_id, data.topic_id, data.day_id)
                                setDisplaySearchResult(false)
                              }}
                            >
                              {gData.description}
                            </button>
                          );
                        })
                      }
                    </div>
                  }




                  {
                    data.display_name === "Tourist Place"  && !data.saved_place_id && !data.name &&
                    <div
                    className='text-or-addSaved-Entities_TD'
                    >
                      or
                    </div> 
                  }

                  {/*//? From saved places/hotel/... */}
                  {/*//? On the hover, the  active_savedPlacesData_itinerary, active_savedPlacesData_itinerary are suppsed to have the data of the save place/entity, and the detail of the place/entity... right now it only handles for the tourist places...*/}
                  {
                    !data.name && data.display_name === "Tourist Place" && 
                    <button 
                    onMouseOver={()=>{
                      setShowSavedData_Status(true)
                      handleDisplaySavedPlaces(data.topic_type, data.topic_id)
                    }}
                    onMouseOut={()=>{
                      setShowSavedData_Status(false)
                    }}
                    className='container_p-addSaved-Entities_TD'
                    >
                      <div
                      className='container_s-1-addSaved-Entities_TD'
                      >
                        add from Saved Places 
                      </div>
                      {
                        (showSavedData_Status && active_savedPlacesData_itinerary && data.topic_id === hoverTopicId) ?
                        <div
                        className='container_s-2-addSaved-hover-Entities_TD'
                        >
                          { active_savedPlacesData_itinerary.length > 0 &&
                            active_savedPlacesData_itinerary.map((savedData, index) => {
                              return(
                                <button 
                                onClick={()=>{
                                  handleName_SavedPlaceSelected(data.topic_id, data.day_id, savedData.name, data.id, savedData.lat, savedData.lng)
                                  setSaveChangesStatus(true)
                                }}
                                onMouseOver={()=>{handleMap_HoverClick(data.topic_id, savedData.lat, savedData.lng, data.display_name, index, savedData.name)}}
                                onMouseOut={()=>{setHoverItineraryData([])}}
                                className='conatiner_t-singleSavedEntity-hover-Entities_TD'
                                >
                                  {/* {data.topic_id}, {data.day_id}, {savedData.name}, {savedData.id}, {data.lat}, {data.lng} */}
                                  {savedData.name}
                                </button>
                              )
                            }) 
                            // : 
                            // <div>You are gonna die...</div>
                          }
                        </div>   
                        //? this is temp. The above one is enough to handle all  
                        :
                        showSavedData_Status && !active_savedPlacesData_itinerary && data.topic_id === hoverTopicId ?
                        <div>
                          No Saved {data.display_name} found
                          .
                          .
                          .
                          .
                        </div>   
                        : null
                      }
                    </button>
                  }

                </div>   

                      
                {/*//? Part of the above, this is concerned with the saved place, as once you select it, it would be visible here   */}
                {
                  (data.saved_place_id || data.name_source === "google") && data.name &&
                  active_savedPlacesData_itinerary.map((savedData, index) => {
                  if(savedData.id === data.saved_place_id){  
                    return(
                    <div
                    className='input-1-description-Note_TD'
                    >
                      {data.name} 
                      {/* {data.saved_place_id} */}
                    </div>    
                    )
                  }
                  })
                }  

                {/* {data.name} */}


                {/*//! #3  */}
                {/* add note, budget, time/duration */}
                <div
                className='container_p-addButtons-Entities_TD'
                >

                  {/*//? Add Note  */}
                  {
                    (data.saved_place_id || data.name) && (!data.notes_data) ?
                    <div>
                      <button
                      onClick={()=>{
                        handleNote_NoteStatus(data.topic_id, data.day_id, true)
                        setActiveAddTopicDetailType("add_note")
                        // openPopup()
                        handleActiveWondow_Notes(data.topic_id, data.day_id)
                        handleHideNoteTemp()
                        setHideNote_Temp(false)
                        setActiveAddTopicDetailType("add_note")
                        openPopup()
                        setSaveChangesStatus(true)
                      }}
                      className='button-note-addButton-Entities_TD'
                      >
                        Add Note
                      </button>
                    </div>   

                    :

                    <div
                    className='container_p-entity_meta_buttons-Entities_TD'
                    >
                      <button
                      onClick={()=>{
                        handleNote_NoteStatus(data.topic_id, data.day_id, true)
                        setActiveAddTopicDetailType("add_note")
                        // openPopup()
                        handleActiveWondow_Notes(data.topic_id, data.day_id)
                        handleHideNoteTemp()
                        setHideNote_Temp(false)
                        setActiveAddTopicDetailType("add_note")
                        openPopup()
                        setSaveChangesStatus(true)
                      }}
                      className='button-note-addButton-Entities_TD'
                      >
                        View Note 
                      </button>

                      <button
                        onClick={()=>{
                          handleNote_RemoveNote(data.topic_id, data.day_id)}
                        }
                        className='button-icon-delete-Entities_TD'
                      >
                        <RiDeleteBin5Line />
                      </button>

                    </div>  
                  }

                  {/*//? Add Budget  */}
                  {
                    (data.saved_place_id || data.name) && (!data.budget_currency || !data.budget_number) ?
                    <div>
                      <button
                      onClick={()=>{
                        handleBudget_Status(data.topic_id, data.day_id, true)
                        setActiveAddTopicDetailType("add_budget")
                        openPopup()
                      }}
                      className='button-budget-addButton-Entities_TD'
                      >
                        {
                          data.display_name !== "Hotel" 
                          ?
                          <div>
                            Add Budget 
                          </div>
                          :
                          <div>
                            Add Per Night Rate
                          </div>
                        }
                      </button>
                    </div>   

                    :

                    <div
                    className='container_p-entity_meta_buttons-Entities_TD'
                    >
                      <button
                      onClick={()=>{
                        handleBudget_Status(data.topic_id, data.day_id, true)
                        setActiveAddTopicDetailType("add_budget")
                        openPopup()
                      }}
                      className='button-budget-addButton-Entities_TD'
                      >
                        {
                          data.display_name !== "Hotel" 
                          ?
                          <div>
                            View Budget 
                          </div>
                          :
                          <div>
                            View Per Night Rate
                          </div>
                        }
                      </button>
                      <button
                        onClick={()=>{handleBudget_RemoveBudget(data.topic_id, data.day_id, false)}}
                        className='button-icon-delete-Entities_TD'
                      >
                        <RiDeleteBin5Line />
                      </button>
                    </div> 

                  }

                  {/*//? Add Time Interval  */}
                  {
                    // (data.saved_place_id || data.name) && !data.add_time_interval_status &&
                    (data.saved_place_id || data.name) && !data.time_start ?
                    <div>
                      <button
                      onClick={()=>{
                        handleTimeInterval_Status(data.topic_id, data.day_id, true)
                        setActiveAddTopicDetailType("add_time_interval")
                        openPopup()
                      }}
                      className='button-timeInterval-addButton-Entities_TD'
                      >
                        Add Time Interval
                      </button>
                    </div>   

                    :

                    <div
                    className='container_p-entity_meta_buttons-Entities_TD'
                    >
                      <button
                      onClick={()=>{
                        handleTimeInterval_Status(data.topic_id, data.day_id, true)
                        setActiveAddTopicDetailType("add_time_interval")
                        openPopup()
                      }}
                      className='button-timeInterval-addButton-Entities_TD'
                      >
                        View Time Interval
                      </button>
                      <button
                      onClick={()=>{handleTimeInterval_RemoveTimeInterval(data.topic_id, data.day_id, false)}}
                      className='button-icon-delete-Entities_TD'
                      >
                        <RiDeleteBin5Line />
                      </button>
                    </div> 
                  }

                </div>  


                {/*//! #5  */}
                {/* Main Conatiner for Budget, Trip Status, and Time Interval */}
              { false &&
                  (data.saved_place_id || data.name) &&
             
                <div
                className='container_p-Data-Entities_TD_new'
                > 

                  {/*//* Conatiner for Budget and Trip Status */}
                  <div 
                  // className='container_s-1-Data-Entities_TD'
                  className={data.notes_data ? 'container_s-1-Data-Entities_TD' : 'container_s-1-noNote-Data-Entities_TD'}
                  >

                    {/*//* Budget */}
                    {
                      (data.budget_number && data.budget_currency)
                      && 
                      <div
                      className='container_p-Budget-Entities_TD'
                      >

                        {
                          data.display_name !== 'Hotel'
                          ?
                          <div
                          className='heading-1-budgetData-Entities_TD'
                          > 
                            Expense
                          </div>   
                          :
                          <div
                          className='heading-1-budgetData-Entities_TD'
                          > 
                            Per Night
                          </div>   
                        }

                        <div 
                        className='container_t-budgetData-Entities_TD'
                        > 
                          <div
                          className='container_t-data-1-budget-Entities_TD'
                          >
                            {/* {data.budget_currency} */}
                            {
                              data.budget_currency ?
                              handleGetCurrency(data.budget_currency) 
                              :
                              <dv>xx</dv>
                            }
                          </div>
                          <div
                          className='container_t-data-1-budget-Entities_TD'
                          >
                            {
                              data.budget_number ?
                              formatNumberWithCommas(data.budget_number) 
                              :
                              <dv>xx</dv>
                            }
                          </div>
                          {
                            data.budget_meta_info && 
                          <div
                          className='container_t-data-2-budget-Entities_TD'
                          >
                            #{data.budget_meta_info}
                          </div>  
                          }
                        </div> 

                        <div
                        className='container_t-icons-budget-Entities_TD'
                        >
                          <button
                          onClick={()=>{
                            setActiveAddTopicDetailType("add_budget")
                            openPopup()
                          }}
                          className='button-icon-edit-Entities_TD'
                          >
                            <AiOutlineEdit />
                          </button>

                          <button
                          onClick={()=>{handleBudget_RemoveBudget(data.topic_id, data.day_id, false)}}
                          className='button-icon-delete-Entities_TD'
                          >
                            <IoMdRemoveCircle />
                          </button>

                        </div> 

                      </div> 

                    }

                    {/*//* Container for Time Interval */}
                    {
                      (data.time_start || data.time_end) &&
                      <div
                      className='container_p-timeInterval-Entities_TD'
                      > 

                        {/* time interval text and edit delete B */}
                        <div
                        className='conatiner_s-1-timeInterval-Entities_TD'
                        >

                          <div
                          className='heading-1-timeInterval-Entities_TD'
                          >
                            Time Interval
                          </div>  

                          <div>
                            <button
                            onClick={()=>{
                              setActiveAddTopicDetailType("add_time_interval")
                              openPopup()
                            }}
                            className='button-icon-edit-Entities_TD'
                            >
                              <AiOutlineEdit />
                            </button>

                            {/* //*Remove Time Interval */}
                            {
                              data.add_time_interval_status &&
                              // <div>
                              <button
                              onClick={()=>{handleTimeInterval_RemoveTimeInterval(data.topic_id, data.day_id, false)}}
                              className='button-icon-delete-Entities_TD'
                              >
                                <RiDeleteBin5Line />
                              </button>
                              // {/* </div>    */}
                            }
                          </div> 

                        </div>  
                      
                        {/* time interval */}
                        <div
                        className='container_p-time-timeInterval-Entities_TD'
                        >

                            {data.time_start ? 
                            <div
                            className='text-time-timeInterval-Entities_TD'
                            >
                              {data.time_start}
                            </div>   
                            :
                            <div
                            className='text-time-timeInterval-Entities_TD'
                            >
                              00:00 
                            </div>    
                            } 
                              - 
                            {data.time_end ? 
                            <div
                            className='text-time-timeInterval-Entities_TD'
                            >
                              {data.time_end}
                            </div>   
                            :
                            <div
                            className='text-time-timeInterval-Entities_TD'
                            >
                              00:00 
                            </div>    
                            }
                            
                        </div>    

                        {/* total duration */}
                        <div> 
                          {
                            data.time_start && data.time_end && 
                            (
                              <ul>
                                <li
                                className='content-time-timeInterval-Entities_TD'
                                >
                                Total Duration: {calculateDuration(data.time_start, data.time_end)}
                                </li>
                              </ul>   
                            )
                          }
                        </div> 

                      </div> 
                     
                    } 

                  </div>  
            

                  {/*//!##22  */}

                    {/* //! #4  */}
                    {/* Note */}

                    {
                      // (data.add_notes_status || (data.notes_data)) &&
                      (data.notes_data) &&

                      <div
                      className='container_s-2-Data-Entities_TD'
                      >
                      {/* <div> */}

                        {/* Container - 1 | Note Menu */}
                        <div
                        className='conatiner_s-1-addNote-Entities_TD'
                        > 

                          <div
                          className='heading-1-addNote-Entities_TD'
                          >
                            Note 
                          </div> 

                          {/*//* Edit Button */}
                          {
                            !(activeAddTopicDetailType === "add_note" &&
                            !hideNote_Temp && activeWindow_Notes.topic_id === data.topic_id && activeWindow_Notes.day_id === data.day_id) &&
                            <div>
                              <button
                                onClick={()=>{
                                  handleActiveWondow_Notes(data.topic_id, data.day_id)
                                  handleHideNoteTemp()
                                  setHideNote_Temp(false)
                                  setActiveAddTopicDetailType("add_note")
                                  openPopup()
                                  setSaveChangesStatus(true)
                                }}
                                className='button-icon-edit-Entities_TD'
                              >
                                <AiOutlineEdit />
                              </button>
                            </div>   
                          }

                          {/*//* Remove Note */}
                          {
                            (data.saved_place_id || data.name) && (data.add_notes_status || data.notes_data) &&
                            <div>
                              <button
                                onClick={()=>{
                                  handleNote_RemoveNote(data.topic_id, data.day_id)}
                                }
                                className='button-icon-delete-Entities_TD'
                              >
                                <RiDeleteBin5Line />
                              </button>
                            </div>   
                          }

                        </div>   

                        {
                          !(activeAddTopicDetailType === "add_note" &&
                          !hideNote_Temp && activeWindow_Notes.topic_id === data.topic_id && activeWindow_Notes.day_id === data.day_id) &&

                          <button
                            onClick={()=>{
                              handleActiveWondow_Notes(data.topic_id, data.day_id)
                              handleHideNoteTemp()
                              setHideNote_Temp(false)
                              setActiveAddTopicDetailType("add_note")
                              openPopup()
                              setSaveChangesStatus(true)
                            }}
                          className='container_p-note-singleNote-displayNotes-Note_TD'
                          >

                            { 
                              !data.notes_data &&
                              <div>
                                Click to Enter Notes...
                              </div>
                            }

                            { displayLatestNote && data.notes_data &&
                              <div style={{background:'white'}}
                              className='container_t-note-singleNote-displayNotes-Note_TD'
                              >
                                <TipTap_ReadOnly
                                  content={data.notes_data}
                                />
                              </div>
                            }  

                            {
                              data.notes_data &&
                              <div className='conatiner_p-addNote-Note_TD'>

                                <button
                                onClick={()=>{{
                                  handleActiveWondow_Notes(data.topic_id, data.day_id)
                                  handleHideNoteTemp()
                                  setHideNote_Temp(false)
                                  setActiveAddTopicDetailType("add_note")
                                  openPopup()
                                  setSaveChangesStatus(true)
                                }}}
                                className='button-addNote-Note_TD'
                                style={{marginLeft:'-0px'}}
                                >
                                  View full note 
                                </button>
        
                              </div>  
                            }

                          </button>

                        }

                      {/* </div>   */}
                    </div> 
                    }   

                </div>
              }    


{/*//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!11  */}

                {/*//! Handle Popups...  */}


                {/*//* #1 Notes  */}
                { activeAddTopicDetailType === "add_note" &&
                  (data.saved_place_id || data.name) && data.add_notes_status &&
              
                  <div>

                  { 
                    (
                    !hideNote_Temp && activeWindow_Notes.topic_id === data.topic_id && activeWindow_Notes.day_id === data.day_id) &&

                    <div >

                      <div className='popup-background-1-Entities_TD'
                      onClick={()=>closePopup()}>
                      </div>

                      <div className='popup_p-addNote-Entities_TO_Popup'>
                        <div className='popup_s-addNote-Entities_TO_Popup'>

                    <button
                    className='container-askAi-DaySelector_TD'
                    style={{width:'fit-content', marginLeft:'10px'}}
                    onClick={()=>{
                      setNavigate_aiItineraryChat(true)
                      // openPopup()
                    }}
                    >
                      <div className='text-trackAiQCount-DaySelector_TD'
                      >
                        {askQuestionCount_day}/20
                      </div>

                      <div
                      className='button-askAi-DaySelector_TD'
                      >  
                        Ask Ai
                      </div>

                      <img
                        src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/ai_chatbot-2.png'
                        className='img-iButton-AiItinerary_Chat' 
                      />
                    </button>
              


                        <div
                        style={{position:'relative'}}
                        >
                          <button 
                          onClick={()=>closePopup()}
                          className='button-close-popup-Note_TD'
                          >
                            {/* Close */}
                            <IoMdCloseCircle />
                          </button>
                        </div>   

                        <div> 
                          <Notes_Tool
                          userInputText={data.notes_data}
                          topic_id={data.topic_id}
                          day_id={data.day_id}
                          handleUserInputText={handleQuill_NoteInput}
                          placeholderPassed={"enter notes for the budget..."}
                          textLength={textLength}
                          handleTextLength={handleTextLength}
                          handleDayInfo_FitinMaxLimit_NoteInput={handleDayInfo_FitinMaxLimit_NoteInput}
                          wordLimit={1500}
                          setSaveChangesStatus={setSaveChangesStatus}
                          />
                        </div> 

                      </div> 
                     </div>     
                    </div>
                 
                  }

                  </div>
                }

                {/*//* #2 Budget  */}
                {
                  activeAddTopicDetailType === "add_budget" &&
                  (data.saved_place_id || data.name) && data.add_budget_status &&

                  <div>

                    <div className='popup-background-1-Entities_TD'
                    onClick={()=>closePopup()}>
                    </div>

                    <div className='popup_p-2-Entities_TO_Popup'>
                      <div className='popup_s-addNote-Entities_TO_Popup'>


                    <button
                    className='container-askAi-DaySelector_TD'
                    style={{width:'fit-content'}}
                    onClick={()=>{
                      setNavigate_aiItineraryChat(true)
                      // openPopup()
                    }}
                    >

                      <div className='text-trackAiQCount-DaySelector_TD'
                      >
                        {askQuestionCount_day}/20
                      </div>

                      <div
                      className='button-askAi-DaySelector_TD'
                      >  
                        Ask Ai
                      </div>

                      <img
                        src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/ai_chatbot-2.png'
                        className='img-iButton-AiItinerary_Chat' 
                      />
                    </button>
              

                    <div key={index}
                    className='conatiner_p-Duration-popup-AddTripDetails_TO'
                    >

                      {
                        data.display_name !== "Hotel" 
                        ? 

                        <div
                        className='heading-1-budget-Entities_TO_Popup'
                        >
                          Add Budget
                        </div> 
                      :
                        <div
                        className='heading-1-budget-Entities_TO_Popup'
                        >
                          Add Per Night rate
                        </div> 
                      }

                      <br></br>

                      <div
                      className='conatiner_t-Duration_Date-popup-AddTripDetails_TO'
                      >
                        <div
                        className='container_t-Duration-popup-AddTripDetails_TO'
                        > 
                          <input 
                          value={data.budget_number}
                          onChange={(event)=>{
                            // if(data.budget_number && data.budget_number.length < 8){
                              handleBudget_InputBudget(event, data.topic_id, data.day_id, data.budget_meta_info)
                              setSaveChangesStatus(true)
                            // }
                          }}
                          placeholder={data.display_name !== "Hotel" ? 'enter budget...' : 'enter per night rate...'}
                          maxLength={7}
                          type='number'
                          className='input-1-popup-AddTripDetails_TO'
                          /> 
                        </div>

                        {/*//?Input Budget Currency */}
                        <div>
                          <select
                            value={data.budget_currency ? data.budget_currency : "select currency"}
                            // value={data.budget_currency}
                            onChange={(event)=>{
                              handleBudget_InputCurrency(event, data.topic_id, data.day_id)
                              setSaveChangesStatus(true)
                            }}
                            className='select-popup-AddTripDetails_TO'
                          >
                            <option value={null} disabled>
                              Select
                            </option>

                            <option value="dollar"
                            className='option-popup-AddTripDetails_TO'
                            >
                              $
                            </option>

                            <option value="inr"
                            className='option-popup-AddTripDetails_TO'
                            >
                              ₹
                            </option>

                          </select>
                        </div>

                      </div>

                              <br></br>
                    
                    <div>
                      {
                        data.budget_number && 
                        (
                        <div>
                          <button 
                          onClick={()=>{handleBudget_InputType("confirmed", data.topic_id, data.day_id)}}
                          className={data.budget_meta_info === "confirmed" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                          >
                            Confirmed
                          </button>

                          <button 
                          onClick={()=>handleBudget_InputType("aprox", data.topic_id, data.day_id)}
                          className={data.budget_meta_info === "aprox" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                          >
                            Aprox
                          </button>

                          <button 
                          onClick={()=>handleBudget_InputType("can_change", data.topic_id, data.day_id)}
                          className={data.budget_meta_info === "can_change" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                          >
                            Can Change. (Planning For a future trip)
                          </button>
                        </div> 
                        )
                      }

                      {/* {
                        (
                          <button 
                          onClick={()=>handleBudget_InputType("not_yet_decided", data.topic_id, data.day_id)}
                          className={data.budget_meta_info === "not_yet_decided" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                          >
                            Not Yet Decided
                          </button>
                        )
                      } */}

              

                    </div>

                  </div>  
                  
         

                    
                        
                      </div>
                    </div>  
                  </div>     

                }   

                {/*//* #3 Time Interval  */}
                {
                  activeAddTopicDetailType === "add_time_interval" &&
                  (data.saved_place_id || data.name) && data.add_time_interval_status &&

                  <div >

                    <div className='popup-background-1-Entities_TD'
                    onClick={()=>closePopup()}>
                    </div>

                    <div className='popup_p-3-Entities_TO_Popup'>
                      <div className='popup_s-addNote-Entities_TO_Popup'>


                    <button
                    className='container-askAi-DaySelector_TD'
                    style={{width:'fit-content'}}
                    onClick={()=>{
                      setNavigate_aiItineraryChat(true)
                      // openPopup()
                    }}
                    >

                      <div className='text-trackAiQCount-DaySelector_TD'
                      >
                        {askQuestionCount_day}/20
                      </div>

                      <div
                      className='button-askAi-DaySelector_TD'
                      >  
                        Ask Ai
                      </div>

                      <img
                        src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/ai_chatbot-2.png'
                        className='img-iButton-AiItinerary_Chat' 
                      />
                    </button>
              


                    { data.add_time_interval_status &&
                        (data.saved_place_id || data.name) && 

                        <div className="create-itinerary-ai">

                          <div
                          className='heading-1-timeInterval-Entities_TO_Popup'
                          >Interval 1</div> 
                          <select
                            value={data.time_start}
                            onChange={(event) => {
                              handleStartTimeChange(event, data.topic_id, data.day_id, event.target.selectedIndex)
                              setSaveChangesStatus(true)
                            }}
                            className='select-1-timeInterval-Entities_TO_Popup'
                          >
                            <option>Select Start Time</option>
                            {startTimeData.map((timeData, index) => (
                              <option key={index} value={timeData}>
                                {timeData} 
                              </option>
                            ))}
                          </select>

                          {
                            data.time_start &&
                            <div>
                              <div
                              className='heading-2-timeInterval-Entities_TO_Popup'
                              >Interval 2</div> 
                              <select
                                value={data.time_end} 
                                onChange={(event)=>{
                                  handleEndTimeChange(event, data.topic_id, data.day_id)
                                  setSaveChangesStatus(true)
                                }}
                                className='select-1-timeInterval-Entities_TO_Popup'
                              >
                                <option>Select End Time</option>
                                {
                                  startTimeData.map((timeData, index) => {
                                    if(index > data.time_index-1){
                                      return(
                                        <option value={timeData}>
                                          {timeData}
                                        </option>  
                                      )
                                    }
                                  })
                                }
                              </select>
                            </div>   
                          }

                          {
                            data.time_start && data.time_end && 
                            (
                              <ul>
                                <li
                                className='content-time-timeInterval-Entities_TD_Mobile'
                                >
                                Total Duration: {calculateDuration(data.time_start, data.time_end)}
                                </li>
                              </ul>   
                            )
                          }

                        </div>
                      }

                    </div> 
                    
                   </div>    
                  </div>      
                  
                }

{/*  */}

                
           


                {/* </div>   */}

              </div>  
            )
            }
          })  
      }

    </div>
  )
}

export default Entities_TD



