import React, { useEffect, useState, useRef } from 'react'

//import scss
import './SavedPlans.scss'

import axios from 'axios'

//
import { useNavigate } from 'react-router-dom';

//import react icons
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

//
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ManageLogin from '../../../../Manage User Credentials/User Login/ManageLogin';
import { server_baseUrl } from '../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';


function SavedPlans(props){

  //@@ 
  const navigate = useNavigate();

  const {userEmail, activeTripStatus, setActiveTripStatus} = props;

  const[userSavedTripsData, setUserSavedTripsData] = useState([])
  const[userSavedTrips_MetaData_1, setUserSavedTrips_MetaData_1] = useState([])
  const[userSavedTrips_MetaData_2, setUserSavedTrips_MetaData_2] = useState([])
  const[savedPlan_status, setSavedPlan_status] = useState(false)

  useEffect(() => {

    if(!savedPlan_status && userEmail){
      loadUserSavedPlans()
    }

  }, [])


  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -500, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 500, behavior: 'smooth' })
    }
  };

  
  const loadUserSavedPlans = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/home/load/user_saved_plans`, {userEmail})

    //column in the user_created_trips table: trip_id, trip_id, trip_name, destination_type, state_name, district_name, city_name, created_date

    // console.log("data - loadUserSavedPlans", response.data)

    if(response.data !== "0"){
      setUserSavedTripsData(response.data)

      loadMetaInfo();
    }
    else{
      // console.log("there is some error loading your saved plans")
    }

  }

  const loadMetaInfo = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/home/load/user_saved_plans/meta_info`, {userEmail})

    // console.log("data - loadMetaInfo", response.data)

    if(response.data !== "0"){
      
      //getting distinct travel categories, and their count
      setUserSavedTrips_MetaData_1(response.data[0])

      //getting distinct districts name - only for state
      setUserSavedTrips_MetaData_2(response.data[1])
    }
    else{
      // console.log("there is some error loading your saved plans")
    }

  }

  const handleSavedPlanClicked = async (trip_id, trip_name, destination_type, state_name, district_name, city_name) => {
    
    if(destination_type === "city"){
      navigate(`plan-trip-to-${city_name}/${district_name}/${state_name}/${trip_id}/${trip_name}`)
       
    }
    else if(destination_type === "district"){
      
      navigate(`plan-complete-trip/${district_name}/${state_name}/${trip_id}/${trip_name}`)

    }
    else if(destination_type === "state"){
      navigate(`plan-complete-trip/state/${state_name}/${trip_id}/${trip_name}`)
    }
    else(
      navigate("error-in-loading-the-saved-plan-clicked/resolve?try-refresing")
    )
  }

  const handleActiveTripStatus = (tripStatus) => {
    if(tripStatus !== activeTripStatus){
      setActiveTripStatus(null);
      setTimeout(() => {
          setActiveTripStatus(tripStatus);
      }, 200);
    }
  };


  const getTravelCategoryDisplayName = (namePassed) => {

    let displayName;

    if(namePassed === "tourist_places"){
      displayName = "Tourist Places"
    }
    else if(namePassed === "Hotel"){
      displayName = "Hotels"
    }
    else if(namePassed === "restaurants"){
      displayName = "Restaurants"
    }
    else if(namePassed === "bars_and_pubs"){
      displayName = "Bars and Pubs"
    }
    else if(namePassed === "amusement_parks"){
      displayName = "Amusement Parks"
    }
    else if(namePassed === "adventure_activities"){
      displayName = "Adventure Activities"
    }
    else if(namePassed === "rent_vehicle"){
      displayName = "Rent Vehicles"
    }
    else if(namePassed === "shopping_malls"){
      displayName = "Shopping Malls"
    }
    else if(namePassed === "street_market"){
      displayName = "Street Market Shopping"
    }

    return displayName;
  }

 

  //delete ai itinerary
  const[deleteItineraryAsk, setDeleteItineraryAsk] = useState()
  const[activeSItineraryId, setActiveSItineraryId] = useState()

  const handleDeleteSavedAiItinerary = async (tripId_passed) => {
    
    const response = await axios.post(`${server_baseUrl}/csc/home/load/user_saved_plans/remove`, {tripId_passed, userEmail})

    // console.log("response.data", response.data)

    if(response.data && response.data == "1"){

      const udpatedTrip = userSavedTripsData.filter(itinerary => itinerary.trip_id !== tripId_passed);

      setUserSavedTripsData(udpatedTrip);

      toast.success("Removed successfully.", {
        position: toast.POSITION.TOP_CENTER
      })

      handleActiveTripStatus(activeTripStatus);
    }
    else{
      toast.error("Error in removing the itinerary. Please try again.", {
        position: toast.POSITION.TOP_CENTER
      })
    }
  }
    

  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
      // setAskQuestionInput(false);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };

  return (
    <div className='container_p-SavedPlans'>
      <div className='container_s-SavedPlans'>


        {/*//!Container #1 */}
        <div className='conatiner-text-SavedPlans'>
          <div
          className='heading-1-SavedPlans'
          >
            Your Saved Trips
          </div>
        </div>


        {/*//!Container #2 */}
        <div
        className='container_t-navigation-tripStatus-SavedPlans'
        >
          <button
            className={activeTripStatus === "all" ? 'button-active-navigation-tripStatus-SavedPlans' : 'button-unActive-navigation-tripStatus-SavedPlans'}
            onClick={()=>{handleActiveTripStatus("all")}}
          >
            All 
          </button>
          <button
            className={activeTripStatus === "progress" ? 'button-active-navigation-tripStatus-SavedPlans' : 'button-unActive-navigation-tripStatus-SavedPlans'}
            onClick={()=>{handleActiveTripStatus("progress")}}
          >
            In-progress 
          </button>
          <button
            className={activeTripStatus === "completed" ? 'button-active-navigation-tripStatus-SavedPlans' : 'button-unActive-navigation-tripStatus-SavedPlans'}
            onClick={()=>{handleActiveTripStatus("completed")}}
          >
            Completed Trip
          </button>
        </div>


        {
          !userEmail && 
          <div
          className='container_p-LoginBefore-SavedAiItineraries'
          >
            <h4
            className='text-1-LoginBefore-SavedAiItineraries'
            >
              Login to access your saved Trip Plans.
            </h4>
            <ManageLogin />
          </div>
        }

{/* userEmail:{userEmail} */}

        {/*//!Container #3 */}
        { 
          // userEmail &&
          userSavedTripsData && userSavedTripsData.length > 0 ?
          <div
          className='container_p-savedTrips-SavedPlans'
          >
            { userSavedTripsData.length > 2 &&
              <button
              className='scroll-images-TouristPlaces_VM'
              onClick={scrollLeft}
              >
                <FaChevronLeft />
              </button>  
            }

            {
              !activeTripStatus &&
              <div className="placeholder-container">
                {[...Array(3)].map((_, index) => (
                  <div className="placeholder-box" key={index}>
                    <div className="placeholder-text"></div>
                  </div>
                ))}
              </div>
            }

            {deleteItineraryAsk &&
              <div class="overlay">
                <div class="popup">
                  <h3>Are you sure you want to delete this itinerary?</h3>
                  <div class="buttons">
                    <button
                      class="cancel-button"
                      onClick={(e) => {
                        setDeleteItineraryAsk(false);
                        e.stopPropagation(); 
                        closePopup();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      class="confirm-button"
                      onClick={(e) => {
                        handleDeleteSavedAiItinerary(activeSItineraryId);
                        setDeleteItineraryAsk(false);
                        e.stopPropagation(); 
                        closePopup();
                      }}
                    >
                      Confirm 
                    </button>
                  </div>
                </div>
              </div>
            }

            <div
            className='container_s-savedTrips-SavedPlans'
            ref={scrollContainerRef}
            >
              {
                userSavedTripsData.map((data, index) => {
                  if(
                    (activeTripStatus === "all")
                    || 
                    (activeTripStatus === "progress" && data.plan_trip_status === "progress")
                    ||
                    (activeTripStatus === "completed" && data.plan_trip_status === "completed")
                  ){
                  return(
                    <button key={index}
                    className='container_t-savedTrips-SavedPlans'
                    onClick={()=>handleSavedPlanClicked(data.trip_id, data.trip_name, data.destination_type, data.state_name, data.district_name, data.city_name)}
                    >

                      <img 
                        src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/delete_icon_1.png'
                        className='img-delete-SavedPlans'
                        onClick={(e)=>{
                          // handleDeleteSavedAiItinerary(data.trip_id)
                          e.stopPropagation(); 
                          setDeleteItineraryAsk(true)
                          openPopup()
                          setActiveSItineraryId(data.trip_id)
                        }}
                      />  


                      <img 
                      // src='https://www.hdwallpapers.in/download/red_yellow_orange_mountain_national_park_hd_nature-HD.jpg'
                      
                        src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${data.state_name.toLowerCase().replace(/\s+/g, '_')}-${1}-01.jpg`}
                        onError={(e) => { e.target.src = 'https://www.hdwallpapers.in/download/red_yellow_orange_mountain_national_park_hd_nature-HD.jpg'; }}
                        // onError={(e) => { e.target.src = `https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${data.state_name.toLowerCase().replace(/\s+/g, '_')}-${3}-01.jpg`; }}
                      className='img_1-savedTrips-SavedPlans'
                      />

                   
                      {/*//?#1 */}
                      <div
                      className='text-1-tripName-savedTrips-SavedPlans'
                      >
                        {data.trip_name}
                      </div>


                      {/*//?#2 */}
                      <div
                      className='container_t-metaInfo-savedTrips-SavedPlans'
                      >

                        <div
                        className='text-1-metaInfo-savedTrips-SavedPlans'
                        >
                          #{data.destination_type}
                        </div>

                        {
                          data.city_name &&
                          <div
                          className='text-1-metaInfo-savedTrips-SavedPlans'
                          >
                            #{data.city_name}
                          </div>
                        }

                        {
                          (data.district_name && data.city_name !== data.district_name) &&
                          <div
                          className='text-1-metaInfo-savedTrips-SavedPlans'
                          >
                            #{data.district_name}
                          </div>
                        }

                        {
                          data.state_name &&
                          <div
                          className='text-1-metaInfo-savedTrips-SavedPlans'
                          >
                            #{data.state_name}
                          </div>
                        }

                        

                      </div>   

                      { userSavedTrips_MetaData_1 &&
                        userSavedTrips_MetaData_1.map((metaData1, index_2) => {
                          if(data.trip_id === metaData1.trip_id){
                            return(
                              <div key={index_2}
                              className='text-1-metaInfo-savedTrips-SavedPlans'
                              >
                                {getTravelCategoryDisplayName(metaData1.travel_category_type)} - {metaData1.count}
                              </div>
                            )
                          }
                        })
                      }

                      {/*//?#3 */}
                      <div
                      className='container_t-createOn-savedTrips-SavedPlans'
                      >
                        <div
                        className='text-1-createOn-savedTrips-SavedPlans'
                        >
                          created on: 
                        </div>
                        <div
                        className='text-2-createOn-savedTrips-SavedPlans'
                        >
                          {data.created_date}
                        </div>
                      </div>


                      {/* 
                      { data.destination_type === "state" && userSavedTrips_MetaData_2 &&
                        userSavedTrips_MetaData_2.map((metaData2, index) => {
                          if(data.trip_id === metaData2.trip_id){
                            return(
                              <div key={index}>
                                {metaData2.district_name}
                              </div>
                            )
                          }
                        })
                      } */}

                    </button>   
                  )
                }
                })
              }
            </div>  

            { userSavedTripsData.length > 2 &&
              <button
                className='scroll-images-TouristPlaces_VM'
                onClick={scrollRight}
              >
                <FaChevronRight />
              </button>
            }

          </div>

          :

          userEmail &&
          <div class="no-itineraries-message">
            You haven't created any Trip Plans yet.
          </div>
        }

      </div>

      <ToastContainer />

    </div>
  )
}

export default SavedPlans