
//?This handles the navigation, innner categories, and loading of the data all categories, except Tourist Places

// MyContext.js
import React, { createContext, useContext, useState } from 'react';

import axios from "axios" 
import { server_baseUrl } from '../../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';

//using react context
import { TravelCategories_Context } from './TravelCategories_Context';
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip';



const Context = createContext();

export const TravelCategories_2_CP = ({ children }) => { 


  //use context 
  const {
    tripDestinationType, tripDestinationName, stateName, districtName, cityName, tripId, userEmail,
    destinationLat, destinationLng
  } = useValidatePlanTrip()

  // using react context 
  const {
    //loading status
    loadingStatus, setLoadingStatus,
    //all destinations - in case of a state
    destinationsData, setDestinationsData,
    //active
    activeDestination, setActiveDestination,
    //hotels
    hotelData, setHotelData,
    active_hotelsData, setActive_hotelsData,
    saved_hotels_data, setSaved_hotels_data,
    districtDataLoaded_status_hotels, setDistrictDataLoaded_status_hotels,
    //map
    fitAllLocations, setFitAllLocations, closePopup_Status, setClosePopup_Status,
    lat, lng, zoom, setLat, setLng, setZoom
  } = TravelCategories_Context();


  const [districtCity_data, setDistrictCity_data] = useState([])

  const [track_forStateDistrctLoadedDestinations, setTrack_forStateDistrctLoadedDestinations] = useState([])
  const [nonStateCityDataLoaded, setNonStateCityDataLoaded] = useState(false)

  const [cityDestinationsLoaded_nonState_status, setCityDestinationsLoaded_nonState_status] = useState(false)
  const [lastActiveDestination, setLastActiveDestination] = useState()


  const manage_DestinationsClick_InNearby = async (tripDestinationType, activeDestination, cityName, districtName, stateName) => {

    // console.log("cityName", cityName, "222222222222222222222222", "activeDestination", activeDestination, "lastActiveDestination", lastActiveDestination)

    // console.log("setDistrictCity_data", districtCity_data)

    if(!activeDestination || (activeDestination !== lastActiveDestination)){

      if(!track_forStateDistrctLoadedDestinations.includes(activeDestination)){
        handleLoad_CityDestination_Data(tripDestinationType, cityName, districtName, stateName, activeDestination)
        setLastActiveDestination(activeDestination)
      }
    }
  }

  // const handleSet_CityDestination_Data = async (activeDestination) => {
      
  // }

  const handleLoad_CityDestination_Data = async (tripDestinationType, cityName, districtName, stateName, activeDestination) => {

    // // console.log("cityName", cityName, "222222222222222222222222")

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/load_destination_data_01/load_city_towns/in_nearby`, {tripDestinationType, cityName, districtName, stateName, activeDestination})
    // console.log('\n', "response.data - handleLoad_CityDestination_Data@#", response.data, '\n')
    if(response.data !== "0"){
      
      const updatedData = response.data.map(item => ({
        ...item,
        destination: activeDestination
      }));
      
      setDistrictCity_data([...districtCity_data, ...updatedData])

      setTrack_forStateDistrctLoadedDestinations([...track_forStateDistrctLoadedDestinations, activeDestination])

      setCityDestinationsLoaded_nonState_status(true)
    }
    else{
      alert("error in loading the city/towns. You can try again.")
    }
  }


  //!Hotels

  // "passedStateName": "goa",
  // "passedPlaceName": "goa",
  // "passedDestinationType": "district",
  // "passedLat": "15.2993",
  // "passedLng": "74.1240",
  // "passedShowMoreCount": 10

  const[showMoreCount, setShowMoreCount] = useState(0)
  const[showMoreStatus, setShowMoreStatus] = useState()

  const[hotelInternalNavigation, setHotelInternalNavigation] = useState()
  //
  const[hoverHotelData_map, setHoverHotelData_map] = useState([])
  
  //
  const[viewMoreStatus, setViewMoreStatus] = useState()
  const[activeHotelData_map, setActiveHotelData_map] = useState([])
  const[activeHoverHotel, setActiveHoverHotel] = useState()
  const[view_prices_popup, setView_prices_popup] = useState()
  const[viewMore_id, setViewMore_id] = useState()
  const[activeViewPrices_Limited, setActiveViewPrices_Limited] = useState()
  const[activeHotel_PriceData_arr, setActiveHotel_PriceData_arr] = useState([])

  // hotelCategory, sortHotelPrice,
  // handleHotelCategoryClick,  handleSortHotelPriceClick

  const[hotelCategory, setHotelCategory] = useState("all")
  const[sortHotelPrice, setSortHotelPrice] = useState("relevant")

  //!
  const handleHotelCategoryClick = async (value) => {
    if(hotelCategory !== value){
      setHotelCategory(value)

      handle_loadHotelsData(
        showMoreCount, false, value
      )
    }
    //load hotel details
  }
  //price_h_to_l, price_l_to_h, price_relevant
  const handleSortHotelPriceClick = async (value) => {
    if(sortHotelPrice !== value){
      setSortHotelPrice(value)
      handle_loadHotelsData(showMoreCount, false, hotelCategory, value)
    }
    else{
      setSortHotelPrice()
      // checkKeywordClick_ForChange_Remove("hotel_price", value)
    }
  }



  const[hotelData_SavedOnes, setHotelData_SavedOnes] = useState([])
  const[aboutData, setAboutData] = useState([])
  const[priceData, setPriceData] = useState([])

  //best deal data
  const[bestDealData, setBestDealData] = useState([])

  //review data
  const[reviewData, setReviewData] = useState([])
  const[reviewDataIndex, setReviewDataIndex] = useState([])
  const[activeReviewData, setActiveReviewData] = useState([[]])
  const[review_showMoreTrack, setReview_showMoreTrack] = useState(0)


  const handleShowMoreClicked = async () => {
    if(showMoreStatus){
      const latestShowMoreCount = showMoreCount+1;

      setShowMoreCount(latestShowMoreCount)

      handle_loadHotelsData(latestShowMoreCount, true)
    }
    else{
      alert("you have reached the end of the list.")
    }
  }

  const[trackHotelLoadedData, setTrackHotelLoadedData] = useState([])
  const[hotelData_MultipleDistricts, setHotelData_MultipleDistricts] = useState()

  //Functions
  const handle_loadHotelsData = async (passedShowMoreCount, showMoreClicked, passedhotelCategory_temp, sortHotelPrice_new, passedDistrictName, latPassed, lngPassed) => {
   
    let passedStateName, passedPlaceName, passedDestinationType, passedLat, passedLng;

    console.log(tripDestinationType, "asas", "passedShowMoreCount, passedDistrictName, trackHotelLoadedData", passedShowMoreCount, passedDistrictName, trackHotelLoadedData)

    if(tripDestinationType === "state" && passedShowMoreCount === 0){
      const status = trackHotelLoadedData.some(item => item === passedDistrictName)

      if (status && tripDestinationType === "state") {
        const isHotelLoaded = trackHotelLoadedData.some(item => item === passedDistrictName);
        
        if (isHotelLoaded) {
          // Use `filter` to get hotels for the passed district
          const temp_hotelData = hotelData_MultipleDistricts.filter(item => item.hotel_district === passedDistrictName);
          setHotelData(temp_hotelData);
          return;
        }
      }
      else if(status){
        return;
      }
    }


    // alert("i was called...")

    //?Depricated for Tourist Places
    //*for tourist locations 
    if(latPassed && lngPassed){
      passedStateName = stateName;
      passedPlaceName = passedDistrictName;
      passedDestinationType = "district"; 
      passedLat = latPassed;
      passedLng = lngPassed;
    }
    //*for destinations
    else{
      if(tripDestinationType !== "state"){
        passedStateName = stateName;
        passedPlaceName = tripDestinationName;
        passedDestinationType = tripDestinationType; 
        passedLat = destinationLat;
        passedLng = destinationLng;
      }else{
        passedStateName = stateName;
        passedPlaceName = activeDestination;
        passedDestinationType = "district"; 
  
        const destination = destinationsData.find(data => data.district_name === activeDestination);
        
        if (destination) {
          passedLat = destination.lat;
          passedLng = destination.lng;
        }else{
          passedLat = destinationLat;
          passedLng = destinationLng;
        }
      }
    }

    setLoadingStatus("hotels_data")

    let passedhotelCategory;
     //hotel category should not be null
     if(!passedhotelCategory_temp){
      passedhotelCategory= "all"
    }
    else{
      passedhotelCategory = passedhotelCategory_temp;
    }

    const response = await axios.post(`${server_baseUrl}/csc/api/travel_categories/hotels/get/hotel_details`, {passedStateName, passedPlaceName, passedDestinationType, passedLat, passedLng, passedShowMoreCount, passedhotelCategory, sortHotelPrice_new}) 

    console.log("handle_loadHotelsData", "response.data", response.data)

    if(response.data && (response.data !== "0" || response.data.length > 0)){

      //making sure no duplicate data is added, incase the data is already loaded for the saved hotels
      const fetched_hotelData = response.data;

      const combined_hotel_data = [...fetched_hotelData, ...hotelData_SavedOnes];


      const filtered_fetched_hotelData = removeDuplicates(combined_hotel_data)

      //?getting price and about data
      const ids = filtered_fetched_hotelData.map(item => item.id).join(', ');

      //get price data
      handleGetOtherdata1(ids, passedStateName)
      //get about data
      handleGetOtherdata2(ids, passedStateName)

    
      //?store the hotel data received from the server 
      if(showMoreClicked){
        handleHotelsData_AddInExisting(filtered_fetched_hotelData)
      }
      else{
        handleHotelsData_AddInNew(filtered_fetched_hotelData)
        setBestDealData([])
        setShowMoreCount(0)

        //to change only if the stateName has changed. And doing so, by taking the agvantage of the delay state update in react.
        // if(placeStateName && passedStateName !== placeStateName){
        //   setReviewData([])
        //   setReviewDataIndex([])
        // }
      }

      
      //?set the status for show more, if there is more data left to show.
      if(response.data.length <= 20){
        setShowMoreStatus(false)
        // console.log("no more show more - less than 20")
      }
      else{
        // setShowMoreCount(showMoreCount+1)
        setShowMoreStatus(true)
      }

      setFitAllLocations(true)
      setLoadingStatus(null)

      //store the data to prevent reloading of the hotels data
      setTrackHotelLoadedData([...trackHotelLoadedData, passedDistrictName]);
      setHotelData_MultipleDistricts([
        ...hotelData_MultipleDistricts, // Keep the existing data
        ...fetched_hotelData.map(item => ({
          ...item, // Spread the existing properties of each item
          hotel_district: passedDistrictName // Add the new column 'hotel_district'
        }))
      ]);

      
    }
    else{
      alert("error in loading the hotels data.")
      // console.log("opps! Error in loading the hotels. You might try again or some time later.")
      setLoadingStatus(null)
    }

  }


  const handleHotelsData_AddInExisting = (passedHotelData) => {

    const combinedData = [...hotelData, ...passedHotelData]
    
    setHotelData(removeDuplicates(combinedData))
  }

  const handleHotelsData_AddInNew = (passedHotelData) => {
    setHotelData([...passedHotelData])
  }


  //!removes duplicate data
  const removeDuplicates = (data) => {
    const seenIds = new Set();
    return data.filter(item => {
        if (!seenIds.has(item.id)) {
            seenIds.add(item.id);
            return true; // Keep this item
        }
        return false; // Skip this item
    });
  };

  //*Price Data
  const handleGetOtherdata1 = async (passedIds, passedStateName) => {

    setLoadingStatus("price_data")

    const response = await axios.post(
      `${server_baseUrl}/csc/api/travel_categories/hotels/get/hotel_details/other_2`, 
      {
        passedIds, passedStateName
      })
    // console.log("response.data - handleGetOtherdata1", response.data) 

    if(response.data && (response.data !== "0" || response.data.length > 0)){

      console.log("handleGetOtherdata1", "response.data", response.data)

      setPriceData(removeDuplicates(response.data))
      handleGetBestDealData(passedIds, response.data)
      setLoadingStatus(null)
    }
    else{
      // console.log("error getting the otherData 1")
      setLoadingStatus(null)
    }
  }


  const handleGetOtherdata2 = async (passedIds, passedStateName) => {
    
    setLoadingStatus("about_data")

    const response = await axios.post(
      `${server_baseUrl}/csc/api/travel_categories/hotels/get/hotel_details/other_3`, 
      {
        passedIds, passedStateName
      })
    // console.log("response.data - handleGetOtherdata2", response.data) 

    if(response.data && (response.data !== "0" || response.data.length > 0)){

      console.log("handleGetOtherdata2", "response.data", response.data)

      setAboutData(removeDuplicates(response.data))
      setLoadingStatus(null)
    }
    else{
      // console.log("error getting the otherData 2")
      setLoadingStatus(null)
    }
  }


  const handleGetBestDealData = (passedIds, passedPriceData) => {
    // Split the passedIds string into an array of individual IDs
    const idArray = passedIds.split(',').map(id => parseInt(id.trim()));

    // Process each ID to find the best deal data
    const newBestDealData = idArray.flatMap(id => {
        const filteredData = passedPriceData.filter(data => data.id === id);

        if (filteredData.length > 0) {
            const nonZeroPrices = filteredData.filter(data => data.price !== 0);
            if (nonZeroPrices.length > 0) {
                const minPrice = Math.min(...nonZeroPrices.map(data => data.price));
                const bestDeal = nonZeroPrices.find(data => data.price === minPrice);
                return bestDeal ? [bestDeal] : [];
            }
        }
        return [];
    });

    // Update the state with the new best deal data
    setBestDealData(prevData => [
      ...prevData, 
      ...removeDuplicates(newBestDealData)
    ]);
  };



  // the new values would be updated only when the state is changed.
  const handleGetReviewdData = async (passedId) => {
  
    if(passedId !== viewMore_id){

      setLoadingStatus("reviews_data")

      if(!reviewDataIndex.includes(passedId)){

        const placeStateName = stateName;

        const response = await axios.post(
          `${server_baseUrl}/csc/api/travel_categories/hotels/get/hotel_details/get_other_view_more_data`, 
          {
            passedId, placeStateName
          })
        // console.log("response.data - handleGetReviewdData", response.data) 

        if(response.data && (response.data !== "0" || response.data.length > 0)){
          setReviewData([...reviewData, ...response.data])
          setReviewDataIndex([...reviewDataIndex, passedId])

          //?we are not using the back and forth buttons, but not using the showmore...
          //initial load, only uptil 10 - first 10
          // setActiveReviewData([...response.data.slice(0, 10)]);

          setActiveReviewData([...response.data]);

          // setReview_showMoreTrack(2)

          setLoadingStatus(null)

        }
        else{
          setLoadingStatus(null)
          // console.log("error getting the view more data")
        }
      }
      else{
        // handleShowMore_Reviews(passedId, "new")
        alert("no review found")
      }
    }
  }

  const[hotelClickedStatus, setHotelClickedStatus] = useState()

  //
  const handle_Hover_Hotel = (passedId, passedLat, passedLng, passedHotelName, minPricePassed) => {
    
    // if(fitAllLocations){
    //   setFitAllLocations(false)
    // }
    // else{
    //   setFitAllLocations(true)
    // }

    setHotelClickedStatus(false)
    //
    setActiveHoverHotel(passedId)
    setHoverHotelData_map([{id: passedId, lat: passedLat, lng: passedLng, name: passedHotelName, hotel_price: minPricePassed}])
    setLat(passedLat)
    setLng(passedLng)
    // setZoom(12)
    setHotelClickedStatus(false)
    handleClosePopup()
  }


  const handleClosePopup = () => {
    if(closePopup_Status){
      setClosePopup_Status(false)
    }
    else{
      setClosePopup_Status(true)
    }
  }


  const handle_HoverOut_Hotel = () => {
    handleClosePopup()
    setActiveHoverHotel()
    setHoverHotelData_map([])
    setActiveHotelData_map([])
  }



  //!
  const handleClickHotel = (passedId, passedLat, passedLng, passedHotelName, minPricePassed) => {
    //
    // if(fitAllLocations){
    //   setFitAllLocations(false)
    // }
    // else{
    //   setFitAllLocations(true)
    // }

    setHotelClickedStatus(true)
    setZoom(12)
  
    setActiveHotelData_map([{id: passedId, lat: passedLat, lng: passedLng, name: passedHotelName, hotel_price: minPricePassed}])
  }
 



    return (
        <Context.Provider value={{ 
          districtCity_data, setDistrictCity_data,
          cityDestinationsLoaded_nonState_status,
          manage_DestinationsClick_InNearby,
          handleLoad_CityDestination_Data,
          //!Hotels
          //
          handle_loadHotelsData,
          handleClickHotel, handleGetReviewdData, handleShowMoreClicked, handle_Hover_Hotel, handle_HoverOut_Hotel,
          //
          handleGetOtherdata1, handleGetOtherdata2,
          //
          viewMoreStatus, setViewMoreStatus,
          //
          hotelCategory, sortHotelPrice,
          handleHotelCategoryClick, handleSortHotelPriceClick,
          //data
          hotelData_SavedOnes, setHotelData_SavedOnes,
          aboutData, setAboutData,
          priceData, setPriceData,
          bestDealData, setBestDealData,
          reviewData, setReviewData,
          reviewDataIndex, setReviewDataIndex,
          activeReviewData, setActiveReviewData,
          review_showMoreTrack, setReview_showMoreTrack,
          //
          hotelInternalNavigation, setHotelInternalNavigation,
          //
          hotelClickedStatus, setHotelClickedStatus,
          hoverHotelData_map, setHoverHotelData_map,
          //
          activeHoverHotel, setActiveHoverHotel,
          view_prices_popup, setView_prices_popup,
          showMoreStatus, setShowMoreStatus,
          viewMore_id, setViewMore_id,
          activeViewPrices_Limited, setActiveViewPrices_Limited,
          activeHotel_PriceData_arr, setActiveHotel_PriceData_arr
        }}>
        {children}
      </Context.Provider>
    )
}

export const TravelCategories_2_Context = () => {
    return useContext(Context);
  };