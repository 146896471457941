import React, {useEffect, useState} from 'react'

// import css 
import './CreateTrip.scss';

//importing react context
import { useValidatePlanTrip } from '../../Manage - Plan Trip/Context/ValidatePlanTrip';
import {useGlobalNavigationPlanTrip} from '../../Manage - Plan Trip/Context/GlobalNavigationPlanTrip';
import { MdOutlineEditLocationAlt } from "react-icons/md";

function CreateTrip() {

// using react context
const { tripId, tripName } = useValidatePlanTrip();
const { createTripStatus, setCreateTripStatus, handleTripName, handleCreateUpdateTrip, handleUpdateTripPlanStatus, tripProgressStatus, track_limit_progressStatus } = useGlobalNavigationPlanTrip();

const [isMobile, setIsMobile] = useState(window.innerWidth <= 899);

useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 899); // Update state on window resize
  };

  window.addEventListener('resize', handleResize);

  // Cleanup event listener on unmount
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);


  return (
    <div
    // className='conatiner_p-CreateTrip'
    >
       
        <div>
            {tripId && 
                <div
                className='conatiner_p-nameIcon-CreateTrip'
                onClick={()=>setCreateTripStatus(true)}
                >   
                    {
                        !isMobile ?

                        <>
                            <button
                            className='button-heading-nameIcon-CreateTrip'
                            >
                                Manage Trip Name
                            </button>
                        
                            <img
                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/trip_name_icon_1.png'
                            className='icon-add-Checklist_TD'
                            />
                        </>
                        :
                        <>
                            <MdOutlineEditLocationAlt />
                        </>
                    }

                    {/* </div> */}
                </div>    
            }

            {!tripId && 
                <div
                className='conatiner_p-nameIcon-CreateTrip'
                >
                    <button onClick={()=>setCreateTripStatus(true)}
                    className='button-heading-nameIcon-CreateTrip'
                    >
                        {
                            !isMobile ? 
                            <>
                                Create My Trip 
                            </>
                            :
                            <>
                                <MdOutlineEditLocationAlt />
                            </>
                        }
                    </button>
                </div>    
            }
        </div>



        {
            (createTripStatus) && 
            (
            <div>

                <div className='popup-background-1-CreateTrip'
                    onClick={()=>setCreateTripStatus(false)}
                >
                </div>


                <div className='container_p-popup-CreateTrip'>
                    {/* <div className='container_s-popup-CreateTrip'> */}

                    {!tripId ? 
                    
                    (
                        <div
                        className='container_s-popup-CreateTrip'
                        >
                            <div
                            className='heading-1-popup-CreateTrip'
                            >
                                Hi, 
                                let's create your trip 
                            </div>     
                        
                            <input 
                                placeholder='enter trip name' 
                                value={tripName} 
                                onChange={handleTripName} 
                                maxlength={40}
                                className='input-1-popup-CreateTrip'
                            />

                            {
                                tripName &&
                                <div
                                className='length-input-1-popup-CreateTrip'
                                >
                                    {tripName.length}/40
                                </div> 
                            }

                         
                            {
                                tripName &&
                                <button 
                                className='button-1-popup-CreateTrip'
                                onClick={()=>{handleCreateUpdateTrip(tripName)}}
                                >
                                    Done
                                </button>
                            }
                        </div>
                    )
                    
                    :   

                    (
                        <div
                        className='container_s-popup-CreateTrip'
                        >
                            <div
                            className='heading-1-popup-CreateTrip'
                            >
                                Edit Your Trip Name
                            </div>    

                            <input 
                                value={tripName} 
                                onChange={handleTripName} 
                                maxlength={100}
                                className='input-1-popup-CreateTrip'
                            />
                          
                            <button 
                            onClick={()=>{handleCreateUpdateTrip()}}
                            className='button-1-popup-CreateTrip'
                            >
                                Update New Name
                            </button>

                        </div>
                    )
                    }


                    {
                        // tripProgressStatus &&
                        <div
                        className='conatiner_p-tripProgressStatus'
                        >
                            <div
                            className='text-1-tripProgressStatus'
                            >
                                Trip Planning Staus
                            </div>

                            <button
                            onClick={()=>{handleUpdateTripPlanStatus("progress")}}
                            className={tripProgressStatus === "progress" ? 'active-tripProgressStatus-CreateTrip' : 'unActive-tripProgressStatus-CreateTrip'}
                            >
                                <div>In Progress</div>
                            </button>

                            <button
                            onClick={()=>{handleUpdateTripPlanStatus("completed")}}
                            className={tripProgressStatus === "completed" ? 'active-tripProgressStatus-CreateTrip' : 'unActive-tripProgressStatus-CreateTrip'}
                            >
                                <div>Completed</div>
                            </button>

                        </div>
                    }

                       
                    {/* </div>   */}
                </div>
                <div onClick={()=>{setCreateTripStatus(false)}} className='popupOverlay-DecideDestination'></div>
            </div>
            )
        }
    </div>
  )
}

export default CreateTrip