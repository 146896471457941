import { React, useState, useRef, useEffect } from 'react';

import {CreateItinerary_AI, DecideDestination_AI, EverythingFamous_AI, HowToReach_AI, NearBy_AI} from '../Manage/Export/Manage_AiComponents'

function AiComponentsQuery(props) {

  const {activeAIFeature, handle_CloseAIFeature, userEmail} = props;

  return (
    <div>

      {activeAIFeature === "create_itinerary" && <CreateItinerary_AI handle_CloseAIFeature={handle_CloseAIFeature} userEmail={userEmail} />}

      {activeAIFeature === "decide_destination" && <DecideDestination_AI />}

      {activeAIFeature === "everything_famous" && <EverythingFamous_AI />}

      {activeAIFeature === "how_to_reach" && <HowToReach_AI />}
      
      {activeAIFeature === "nearby" && <NearBy_AI />}

      

    </div>
  )
}

export default AiComponentsQuery