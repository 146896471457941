import React, { useEffect, useState, useRef } from 'react';

//@@ import css
//new
import './Home_new.css';
//temp - delete me
import './Home.scss';
import './Home2.css';
import './Home3.css';

//@@ import conponents
//?Travel Categories
import {Hotels} from '../../Travel Categories/Manage/Export/Export_TravelCategories'
 
//?Home
// import {SearchTool, AISection, ExploreCountry, DiscoverRandom, Search_TravelCategories} from '../Manage Home/Export - Home/ExportHome'

//manage google analytics tracking
import { initGA, trackPageView } from '../../Track Action/Google Tracking/GoogleTrackingAnalytics';
import { userAnalytics_customTrack_VisitsExits } from '../../Track Action/Custom Tracking/CustomTrackingAnalytics';
import {v4 as uuidv4} from 'uuid';


import SearchTool from '../Home Components/Search Tool/SearchTool';
import AISection from '../Home Components/Ai Section/AISection';
// import ExploreCountry from '../Home Components/Explore Country/ExploreCountry';
import DiscoverRandom from '../Home Components/Discover Random/DiscoverRandom';
import Search_TravelCategories from '../Home Components/Search - Travel Categories/Search_TravelCategories';
import HowToReach_Initial from '../Home Components/How To Reach/HowToReach_Initial';
import SavedPlans from '../Home Components/Saved Plans/SavedPlans';
import SavedAiItineraries from '../Home Components/Saved Ai Itineraries/SavedAiItineraries';

import AiComponentsQuery from '../../AI Components/Main - AI Components/Main/AiComponents_Query';
import Feedback_1 from '../../Universal Components/Feedback/Type_1/Main - Type 1/Feedback_1';
import Footer from '../../Universal Components/Footer/Main - Footer/Footer';
import Header from '../../Universal Components/Header/Main - Header/Header';

//@@import others
import { Helmet } from 'react-helmet';

//user credentitals - cookies
import {getCookies_userCredentials, parseUserCredentialsData} from '../../../Cookies/LoginCredentitals'
import ManageLogin from '../../../Manage User Credentials/User Login/ManageLogin';
import QuickTravelCategories from '../Home Components/Quick Travel Categories/QuickTravelCategories';
import { useLocation } from 'react-router-dom';

//
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';



//@@ main function
function Home() { 

  const location = useLocation();
  //!Track user visit 
  //
  useEffect(() => {
    initGA();
  }, []);
  //
  useEffect(() => {
    trackPageView(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    // Call this function once when the page is loaded
    const date = Date.now();
    const start_time = Math.floor(date / 1000)

    const random_number = Math.floor(Math.random() * 1000000); 
    const concatenatedString = `${date}-${random_number}`;
    const user_temp_id = uuidv4(concatenatedString);  


    userAnalytics_customTrack_VisitsExits(user_temp_id, userEmail, location.pathname, "enter", null);

    // Function to handle page exit
    const handleBeforeUnload = () => {

      const end_time = Math.floor(Date.now() / 1000);  // Convert milliseconds to seconds

      const stay_duration = end_time-start_time;

      userAnalytics_customTrack_VisitsExits(user_temp_id, userEmail, location.pathname, "exit", stay_duration);
    };

    // Add event listener to call page exit event when the user tries to close or navigate away
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures this effect runs only once per page load

  //!End*----


  //##1-start
    // handle ai feature/component click
    
    const [activeAIFeature, setActiveAIFeature] = useState(false);
    const[manageLoginWindow, setManageLoginWindow] = useState()
    const[activeTripStatus, setActiveTripStatus] = useState("all")
    const [activePlaceSegment, setActivePlaceSegment] = useState("district")
    //city district state
    const[feedbackLoadedStatus, setFeedbackLoadedStatus] = useState()
    const[feedbackGivenStatus, setFeedbackGivenStatus] = useState()
    const[feedbackWindow, setFeedbackWindow] = useState()

    //saved place data
    const[userSavedTripsData, setUserSavedTripsData] = useState([])



    const handleAIFeatureClick = (featureName) => {
      setActiveAIFeature(featureName)
    }

    const handleCloseAIFeature = () => {
      setActiveAIFeature(false)
    }

  //##-1-end

  //##2  
  const [userEmail, setUserEmail] = useState();
  const [loginStatus, setLoginStatus] = useState()

  useEffect(() => {
    handleGetUserLoginStatus();
  }, [])

  const handleGetUserLoginStatus = () => {
    let tempEmail;
    const userDataCookie = getCookies_userCredentials("userData");
    // console.log("userDataCookie", userDataCookie);
    if (userDataCookie) {
      const userData = parseUserCredentialsData(userDataCookie);
      if (userData) {
          setLoginStatus(userData.email);
          setUserEmail(userData.email);
          tempEmail = userData.email;
          // console.log("Login status:", userData.loginStatus, userData.email, userData.email);
      }
    } else {
      // console.log("User data cookie not found. - handleGetUserLoginStatus");
    }

  }

  const[manageWaitlistLoaded, setManageWaitlistLoaded] = useState(false)

  const[scrollPageTo_TravelCategories, setScrollPageTo_TravelCategories] = useState()
  const[scrollPageTo_AISection, setScrollPageTo_AISection] = useState()

  //scroll page to...
  const start_planning = useRef(null);
  const my_trips = useRef(null);
  const my_ai_trips = useRef(null);
  const travel_modes = useRef(null);


  const scrollToFeedback = (scrollPageTo) => {

    if(scrollPageTo === "start_planning"){
      start_planning.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if(scrollPageTo === "my_trips"){
      my_trips.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if(scrollPageTo === "my_ai_trips"){
      my_ai_trips.current.scrollIntoView({ behavior: 'smooth' });
    }
    else if(scrollPageTo === "travel_modes"){
      travel_modes.current.scrollIntoView({ behavior: 'smooth' });
    }

  };

  //Handle the toaster message
  const handleToastMessage = (message, type) => {

    if(type === "success"){
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER
      })
    }
    else if(type === "info"){
      toast.info(message, {
        position: toast.POSITION.TOP_CENTER
      })
    }
    else{
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER
      })
    }

  }



  //!handles the ai url: 

  useEffect(() => {
    if (location.pathname.includes("plan-trip-with-ai/ai-trip-planner")) {
      handleAIFeatureClick("create_itinerary");
    }
  }, [location]);




  //@@ main return
  return (
    <div>

{/* <button>take me to feedback</button> */}

<Helmet>   

  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link href="https://fonts.googleapis.com/css2?family=Assistant:wght@400&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />

  <link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

  <link href="https://fonts.googleapis.com/css2?family=Concert+One&display=swap" rel="stylesheet"/>

  <link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap" rel="stylesheet" />


  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "WebPage",
      "name": "Prepare Trip - Plan Your Perfect Journey",
      "description": "Prepare Trip helps you plan your perfect journey with AI. Discover destinations, compare hotel prices, and create customized itineraries.",
      "url": "https://www.preparetrip.com/",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://www.preparetrip.com/"
      },
      "about": {
        "@type": "Thing",
        "name": "AI Trip Planner",
        "description": "Start your trip planning with our AI Trip Planner, where you can make changes to your trip. Get personalized itineraries, how to reach, QnAs, and more.",
        "url": "https://www.preparetrip.com/plan-trip-with-ai/ai-trip-planner"
      },
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://www.preparetrip.com/search?query={search_term}",
        "query-input": "required name=search_term"
      },
      "hasPart": [
        {
          "@type": "WebPage",
          "name": "Discover Hotels. Compare. Get the Best Deal.",
          "description": "Find the best deal by comparing different hotel categories like Budget, Comfort, and Premium. Book directly and pay zero commission fees.",
          "url": "https://www.preparetrip.com/discover/Hotels"
        },
        {
          "@type": "WebPage",
          "name": "Plan a Trip to Any Country",
          "description": "Prepare Trip makes it easy to plan a trip to any country with custom itineraries, accommodation options, and travel guides.",
          "url": "https://www.preparetrip.com/stayrevolution/platform-to-get-direct-bookings"
        }
      ]
    })}
  </script>


</Helmet>


      {/*//!Header  #1*/}
      <header>
        <Header 
        userEmail={userEmail}
        manageWaitlistLoaded={manageWaitlistLoaded}
        setManageWaitlistLoaded={setManageWaitlistLoaded}
        scrollToFeedback={scrollToFeedback}
        setScrollPageTo_AISection={setScrollPageTo_AISection}
        manageLoginWindow={manageLoginWindow} 
        setManageLoginWindow={setManageLoginWindow}
        />
      </header>
      
      {/*//*Image - Home Page ##2  */}
      {/* <div
      className='container_p-Image_HomePage-Home'
      >

        <img
        className='img_HomePage-Home'
        src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/home_page_image_2.jpeg'
        />

      </div>   */}

   {/*//!Search Tool  #2*/}
      <div
      ref={start_planning}
      >
        <SearchTool 
        activePlaceSegment={activePlaceSegment} 
        setActivePlaceSegment={setActivePlaceSegment}
        />
      </div>

<br></br>

      {/*//!Quick Travel Categories  #3.2*/}
      <div>
        <QuickTravelCategories 
        handle_AIFeatureClick={handleAIFeatureClick}
        handleToastMessage={handleToastMessage}
        /> 
      </div>



      {/*//!Saved Ai Itineraries #8*/}
      {activeAIFeature && (
      <div>
        <AiComponentsQuery 
          activeAIFeature={activeAIFeature} 
          handle_CloseAIFeature={handleCloseAIFeature}
          userEmail={userEmail}
        />
      </div>
      )}

      <div
      ref={my_trips}
      ></div>

      {
        userEmail ?
        <SavedPlans 
        activeTripStatus={activeTripStatus} 
        setActiveTripStatus={setActiveTripStatus}
        userEmail={userEmail}
        />

        :
        
        <div className='container_p-SavedPlans'>
          <div className='container_s-SavedPlans'>

            <div className='conatiner-text-SavedPlans'>
              <div
              className='heading-1-SavedPlans'
              >
                Your Saved Trips
              </div>
            </div>

            <div
            className='container_t-navigation-tripStatus-SavedPlans'
            >
              <button
                className={activeTripStatus === "all" ? 'button-active-navigation-tripStatus-SavedPlans' : 'button-unActive-navigation-tripStatus-SavedPlans'}
                // onClick={()=>{handleActiveTripStatus("all")}}
              >
                All 
              </button>
              <button
                className={activeTripStatus === "progress" ? 'button-active-navigation-tripStatus-SavedPlans' : 'button-unActive-navigation-tripStatus-SavedPlans'}
                // onClick={()=>{handleActiveTripStatus("progress")}}
              >
                In-progress 
              </button>
              <button
                className={activeTripStatus === "completed" ? 'button-active-navigation-tripStatus-SavedPlans' : 'button-unActive-navigation-tripStatus-SavedPlans'}
                // onClick={()=>{handleActiveTripStatus("completed")}}
              >
                Completed Trip
              </button>
            </div>

            <div
            className='container_p-LoginBefore-SavedAiItineraries'
            >
              <h4
              className='text-1-LoginBefore-SavedAiItineraries'
              >
                Login to access your saved Trip Plans.
              </h4>
              <ManageLogin />
            </div>

          </div> 
        </div>    

      }

      {/* {
        userEmail && userSavedTripsData ?
        <SavedPlans 
        activeTripStatus={activeTripStatus} 
        setActiveTripStatus={setActiveTripStatus}
        userEmail={userEmail}
        userSavedTripsData={userSavedTripsData}
        setUserSavedTripsData={setUserSavedTripsData}
        />
        :
        <SavedPlans 
        activeTripStatus={activeTripStatus} 
        setActiveTripStatus={setActiveTripStatus}
        userEmail={userEmail}
        />
      } */}

      {/*//!Saved Trip Plans #6*/}
      {/* {userEmail ?
        <SavedPlans 
        userEmail={userEmail}
        activeTripStatus={activeTripStatus} 
        setActiveTripStatus={setActiveTripStatus}
        />
        :
        <div className='SavedPlans-LoginConatiner-Home'>
          <div>
            Login to see your saved Plans 
          </div>
          <div>
            <ManageLogin />
          </div>
        </div>        
      } */}


      {/* <div className='SavedPlans-MainContainer-Home'>
      </div> */}


      {/*//!Saved Ai Itineraries #7*/}
      {/* <div className='SavedPlans-MainContainer-Home'>
      </div> */}

      <div
      ref={my_ai_trips}
      ></div>
      {
        userEmail ?
        <SavedAiItineraries 
          userEmail={userEmail}
        />

        :

        <div className='container_p-SavedAiItineraries'>
          <div className='container_s-SavedAiItineraries'>

            <div className='container-text-SavedAiItineraries'>
              <div
              className='heading-1-SavedAiItineraries'
              >
                Your AI Itineraries
              </div>

              <img 
                src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/itinerary_saved_1.png'
                className='img-1-bg-text-SavedAiItineraries'
              />
            </div>

            <div
            className='container_p-LoginBefore-SavedAiItineraries'
            >
              <h4
              className='text-1-LoginBefore-SavedAiItineraries'
              >
                Login to see your saved AI Itineraries.
              </h4>
              <ManageLogin />
            </div>

          </div> 
        </div>    
      }

      {/* {userEmail ?
        <SavedAiItineraries 
        userEmail={userEmail}
        />
        :
        <div className='SavedPlans-LoginConatiner-Home'>
          <div>
            Login to see your saved Plans 
          </div>
          <div>
            <ManageLogin />
          </div>
        </div>        
      } */}

      {/*//!Ai Tools #5*/}
      <div>
        <AISection 
        handle_AIFeatureClick={handleAIFeatureClick}
        scrollPageTo_AISection={scrollPageTo_AISection}
        setScrollPageTo_AISection={setScrollPageTo_AISection}
        />
      </div>

<br></br>

      {/*//!How To Reach  #4*/}
      <div
      ref={travel_modes}
      >
        <HowToReach_Initial />
      </div>
      <br></br>

      <br></br>
      {/*//!Travel Categories  #3*/}
      <div>
        <Search_TravelCategories 
        setScrollPageTo_TravelCategories={setScrollPageTo_TravelCategories}
        setScrollPageTo_AISection={setScrollPageTo_AISection}
        handleToastMessage={handleToastMessage}
        /> 
      </div>
      <br></br>


      {/*//!Saved Ai Itineraries #9*/}
      {/* <div>
        <ExploreCountry />
      </div> */}
<br></br>

      {/*//!Saved Ai Itineraries #10*/}
      {/* <div>
        <DiscoverRandom />
      </div> */}
<br></br>

      {/*//!Saved Ai Itineraries #11*/}
      {
        userEmail &&
        <div>
          <Feedback_1 
          userEmail={userEmail}
          feedbackLoadedStatus={feedbackLoadedStatus}
          setFeedbackLoadedStatus={setFeedbackLoadedStatus}
          feedbackGivenStatus={feedbackGivenStatus}
          setFeedbackGivenStatus={setFeedbackGivenStatus}
          feedbackWindow={feedbackWindow}
          setFeedbackWindow={setFeedbackWindow}
          />
        </div>
      }

<br></br>

      {/*//!Saved Ai Itineraries #12*/}  
      <footer>
        <Footer 
        userEmail={userEmail}
        setManageLoginWindow={setManageLoginWindow}
        scrollToFeedback={scrollToFeedback}
        activeTripStatus={activeTripStatus} 
        setActiveTripStatus={setActiveTripStatus}
        setScrollPageTo_AISection={setScrollPageTo_AISection}
        activePlaceSegment={activePlaceSegment} 
        setActivePlaceSegment={setActivePlaceSegment}
        feedbackWindow={feedbackWindow}
        setFeedbackWindow={setFeedbackWindow}
        handleToastMessage={handleToastMessage}
        />
      </footer>


      {/* {loginStatus && <div>Loged in {userEmail}</div>}
    {!loginStatus && <div>NOT</div>} */}

    <ToastContainer />

    </div>
  )
}

export default Home;



