import React, {useRef, useEffect} from 'react'

//import css
import './Map_HowToReach_Mobile.scss';

// importing map
import {MapContainer, Tooltip, TileLayer, Marker, Popup, useMapEvent, useMap, useMapEvents, circle, Circle, GeoJSON} from 'react-leaflet'

//
import L from "leaflet";

// importing icons 
import { GrLocationPin } from "react-icons/gr";

import {
  // active_tourist_places, hover_tourist_places, saved_tourist_places
  airport_dead_1, airport_normal_1, airport_normal_2, airport_active_1,
  train_dead_1, train_normal_1, train_normal_2, train_active_1, 
  bus_dead_1, bus_normal_1, bus_normal_2, bus_active_1, 
  map_pin_1, map_pin_2, map_pin_3
} from '../../../../Plan Trip/Manage - Plan Trip/Icons/MapIcons'



// import react context 
import {useHowToReachContext} from '../../Manage/Context/HowToReachContext_Mobile'



function Map_HowToReach_Mobile(props) {

  const mapRef = useRef(null);
  const {lat, lng, zoom} = props;
   //handle map - drag

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView([lat, lng], zoom);
    }
  }, [lat, lng, zoom])



  //import from useContext 
  const {
    airContent, trainContent, busContent, 
    destinationName, destinationType,
    activeMode,
    air_PlaceName, train_PlaceName, bus_PlaceName, 
    hoverLocationData_map, activeLocationData_map, 
    activeTrainDestination, activeAirDestination, activeBusDestination,
    // lat, lng, zoom
    fitAllLocations, setFitAllLocations
  } = useHowToReachContext();



  const handleHoverIcon = (passedTravelMode) => {

    let passActive;
  
    if(passedTravelMode === "air"){
      passActive = airport_active_1;
    }
    else if(passedTravelMode === "bus"){
      passActive = bus_active_1;
    }
    else if(passedTravelMode === "train"){
      passActive = train_active_1;
    }
  
    return passActive;
  }

  const accessToken = 'pk.eyJ1IjoicmlzaGFiaDY5IiwiYSI6ImNseHU5dTNuMzBnaGYycXNkZTRhODUxaTIifQ.o7XiAXhwt0J2lk9cQfLRrw';


  //locations
  const handleFitAllLocations = () => {
    const map = mapRef.current;
    if (map) {
      // Select the appropriate content based on activeMode
      let activeContent = [];
      if (activeMode === "air") {
        activeContent = [...airContent];
      } else if (activeMode === "train") {
        activeContent = [...trainContent];
      } else if (activeMode === "bus") {
        activeContent = [...busContent];
      } else {
        activeContent = [...airContent, ...trainContent, ...busContent]; // If all modes should be considered
      }
  
      // Filter out locations without lat/lng and fit bounds
      const allLocations = activeContent.filter(loc => loc.lat && loc.lng);
      if (allLocations.length > 0) {
        const bounds = L.latLngBounds(allLocations.map(loc => [loc.lat, loc.lng]));
        map.fitBounds(bounds);
      }
    }
    setFitAllLocations(false);
  };
  
  useEffect(() => {
    handleFitAllLocations(); 
  }, [fitAllLocations, airContent, trainContent, busContent, activeMode]);
  


  // const closeAllPopups = () => {
  //   const map = mapRef.current;
  //   if (map) {
  //     map.eachLayer((layer) => {
  //       if (layer instanceof L.Popup) {
  //         layer.close();
  //       }
  //     });
  //   }
  //   setClosePopup_Status(false)
  // };

  // useEffect(() => {
  //   closeAllPopups()
  // }, [closePopup_Status])


  return (
    <div>

        <MapContainer  
          className="Map-Map_HowToReach" 
          center = {[lat, lng]} 
          zoom={zoom} 
          ref={mapRef}
        > 

        <TileLayer 
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' 
        />
        {/* <TileLayer
          url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${accessToken}`}
          attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
        /> */}

        {/* //* Air */}
        {
          airContent && activeMode === "air" && 
          (
            airContent.map((data, index) => {
              return(
              <Marker
                key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={airport_normal_1}
              >

                <Popup
                className='container_p-Cards-Air-Reach_D_Mobile'
                >
                  <div className='text-1-air-Map_HowToReach_Mobile'>
                      {data.mode_name}
                  </div>

                  <div className='text-2-air-Map_HowToReach_Mobile'>
                      Code: {data.mode_code}
                  </div>

                  <div className='text-3-air-Map_HowToReach_Mobile'>
                      <div className='text-3-1-air-Map_HowToReach_Mobile'>
                          in {data.mode_district} 
                      </div>
                      {data.mode_state_name && 
                      <div className='text-3-1-air-Map_HowToReach_Mobile'>
                          , {data.mode_state_name}.
                      </div>
                      }
                  </div>

                  {
                  data.mode_g_review &&
                  <div className='text-4-air-Map_HowToReach_Mobile'>
                      G reviews: ({data.mode_g_review.toLocaleString('en-IN')})
                  </div>
                  }     
                  
              
                  { data.distance &&
                  destinationType !== "state" ?
                  <div className='text-5-air-Map_HowToReach_Mobile'>
                      <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                  </div> :
                  null
                  }
                </Popup>

              </Marker>
              )
            })
          )
        }

        {/* //* Train */}
        {
          trainContent && activeMode === "train" && 
          (
            trainContent.map((data, index) => {
              return(
              <Marker
                key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={train_dead_1}
              >
                {/* <Popup>Hello!!</Popup> */}

                <Popup
                className='container_p-Cards-Air-Map_HowToReach_Mobile'
                >
                  <div
                   className='card-Air-Map_HowToReach_Mobile'
                  >
                    <div className='text-1-air-Map_HowToReach_Mobile'>
                        {data.mode_name}
                    </div>

                    <div className='text-2-air-Map_HowToReach_Mobile'>
                        Code: {data.mode_code}
                    </div>

                    <div className='text-3-air-Map_HowToReach_Mobile'>
                      <div className='text-3-1-air-Map_HowToReach_Mobile'>
                          in {data.mode_state_name} 
                      </div>
                    </div>

                    { data.distance &&
                      destinationType !== "state" ?
                      <div className='text-5-air-Map_HowToReach_Mobile'>
                        <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                      </div> :
                        null
                    }
                  </div>
                </Popup>

              </Marker>
              )
            })
          )
        }

        {/* //* Bus */}
        {
          bus_PlaceName && activeMode === "bus" && 
          (
            busContent.map((data, index) => {
              return(
              <Marker
                key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={bus_normal_1}
              >
                  {/* <Tooltip 
                    direction="bottom" permanent
                    className='hoverToolTipOverall'
                  >
                    {data.mode_name}     
                  </Tooltip> */}
                  <Popup
                  className='container_p-Cards-Air-Reach_D_Mobile'
                  >
                    <div className='text-1-air-Reach_D'>
                      {data.mode_name}
                    </div>

                    {
                      destinationType === "state" ?
                      <div className='text-3-1-air-Reach_D'>
                          in {data.mode_state_name}.
                      </div> 
                      : null    
                    }
                    
                    { data.distance &&
                    destinationType !== "state" ?
                    <div className='text-5-air-Reach_D'>
                        <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                    </div> :
                    null
                    }

                  </Popup>
              </Marker>
              )
            })
          )
        }

        {/* active destination - the one we searched nearby */}

        {activeTrainDestination && 
        (
            activeTrainDestination.map((data, index) => {
              return(
                <Marker
                  key={index}
                  position={[(data.lat), (data.lng)]}
                  zoom={zoom}
                  icon={map_pin_1}
                >
                  <Tooltip 
                    direction="bottom" permanent
                    className='hoverToolTipOverall'
                  >
                    {destinationName}     
                  </Tooltip>

                </Marker>
              )
            })
        )
        }

        {activeAirDestination && 
        (
          
          activeAirDestination.map((data, index) => {
            return(
              <Marker
                key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={map_pin_1}
              >
                <Tooltip 
                  direction="bottom" permanent
                  className='hoverToolTipOverall'
                >
                  {destinationName}     
                </Tooltip>

              </Marker>
            )
          })
          
        )
        }

        {activeBusDestination && 
        (
          
          activeBusDestination.map((data, index) => {
              return(
                <Marker
                  key={index}
                  position={[(data.lat), (data.lng)]}
                  zoom={zoom}
                  // icon={`active_${activeTravelCategory}`}
                  // eventHandlers={{
                  //   click:()=>handleGetPopupDetails("active", data.id, data.type)
                  // }}
                  icon={map_pin_1}
                >
                  <Tooltip 
                    direction="bottom" permanent
                    className='hoverToolTipOverall'
                  >
                    {destinationName}     
                  </Tooltip>

                </Marker>
              )
            })
          
        )
        }

        {/* //* active/clicked location  */}
        {/* { activeLocationData_map.length > 0 &&
          activeLocationData_map.map((data, index) => {
            return(
              <Marker
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
              >

                <Tooltip 
                  direction="bottom" permanent
                  className='hoverToolTipOverall'
                >
                  {data.name}     
                </Tooltip>

              </Marker>
            )
          })
        } */}

        
        {/* //* hover location  */}
        { hoverLocationData_map.length > 0 &&
          hoverLocationData_map.map((data, index) => {
            return(
              <Marker
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={handleHoverIcon(activeMode)}
                // icon={airport_active_1}
              >
                <Tooltip 
                  direction="bottom" permanent
                  className='hoverToolTipOverall'
                >
                  {data.name}     
                </Tooltip>

              </Marker>
            )
          })
        }

        </MapContainer>

    </div>
  )
}

export default Map_HowToReach_Mobile