  
//   const userData = {
//     name: name,
//     email: email,
//     profilePic: profilePic,
//     loginStatus: true
//   };

  // Function to get the value of a cookie by its name
  export const getCookies_userCredentials = (cookieName) => {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return null;
  }
  

  // Function to parse the user data from the cookie value
  export const parseUserCredentialsData = (cookieValue) => {
    try {
        return JSON.parse(decodeURIComponent(cookieValue));
    } catch (error) {
        console.error("Error parsing user data:", error);
        return null;
    }
  }

