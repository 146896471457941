import React, { useState } from 'react'

//importing css
import './Note_TD.scss'

// import react icons
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdCloseCircle } from "react-icons/io";

//import react context
import {useCreateItineraryPlanTrip} from '../../../../../../Manage/Context/CreateItinerary_Context';
import {useCreateItinerary_2_PlanTrip} from '../../../../../../Manage/Context/CreateItinerary_Context_2';

import Notes_Tool from '../../../../../../../../Global Tool - Plan Trip/Notes/Notes_Tool';
import TipTap_ReadOnly from '../../../../../../../../Global Tool - Plan Trip/Notes/TestingLexical/TipTap_ReadOnly';


function Note_TD(props){

  const {visibility_type, different_design, topic_type, topic_id, isMobile} = props;

  const {
    itineraryData, 
    handleNote_ClearNote,
    itinerary_DeleteColumn, handleNote_Description, 
    //active notes window
    handleQuill_NoteInput, textLength, handleTextLength, handleDayInfo_FitinMaxLimit_NoteInput,
    handleActiveWondow_Notes, activeWindow_Notes, hideNote_Temp, setHideNote_Temp, handleHideNoteTemp, 

    //*Status
    saveChangesStatus, setSaveChangesStatus,
  } = useCreateItineraryPlanTrip()

  const {
    //!States

    //track AI questions asked
    askQuestionCount_day,

    //*navigate
    navigate_aiItineraryChat, setNavigate_aiItineraryChat,
    aiItineraryChat_window, setAiItineraryChat_window,
    
  } = useCreateItinerary_2_PlanTrip()

  const[activeAddTopicDetailType, setActiveAddTopicDetailType] = useState()
  
  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
      setActiveAddTopicDetailType(null);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };

  //after editing and updating the new note, the latest one is not visible, so we refresh it...
  const[displayLatestNote, setDisplayLatestNote] = useState(true)

  const handleDisplayUpdatedNotes = () => {
    setDisplayLatestNote(false);
    setTimeout(() => {
      setDisplayLatestNote(true);
    }, 200);
  };

  const test = () => {
    // console.log("itineraryData", itineraryData)
  }

  
  return (
    <div className='container_p-Checklist_TD'>
  
      { 
        !different_design && itineraryData &&

        itineraryData.map((data, index) => {
          if(data.topic_id === topic_id){
            return(
              <div key={index}>

                {/* {
                  isMobile &&
                  <div style={{marginTop:'22px'}}></div>
                } */}

                <div
                className='conatiner_p-head-Checklist_TD'
                > 

                  <div
                  className='conatiner_t-displayName_Icon-head-Checklist_TD'
                  >
                    <div
                    className='text-head-Checklist_TD'
                    >
                      {data.display_name}
                    </div> 

                    <div>
                      <img
                      src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/note_icon_6.png'
                      className='icon-checklist-Checklist_TD'
                      />
                    </div>      
                  </div> 

                  <div>
                    <button 
                      onClick={()=>{
                        itinerary_DeleteColumn(data.topic_id, data.day_id)
                        setSaveChangesStatus(true)
                      }}
                      className='icon-delete-Checklist_TD'
                    >
                      <RiDeleteBinLine />
                    </button>
                  </div>     

                </div>   


                
                {/*//? Input*/}
                <div> 
                  <input 
                    value={data.description}
                    onChange={(event)=>{
                      handleNote_Description(event, data.topic_id, data.day_id)
                      setSaveChangesStatus(true)
                    }}
                    placeholder='enter description... (Max length: 50)'
                    maxLength={50}
                    type='text'
                    // className='input-1-description-Note_TD'
                    className='input-1-searchPlaceName-GoogleSearchPlaces'
                  />
                </div>


                {
                  data.notes_data &&
                  <div>
                    <button
                    onClick={()=>{
                      handleNote_ClearNote(data.topic_id, data.day_id)
                      setSaveChangesStatus(true)
                    }}
                    className='button-clearNote-Note_TD'
                    >
                      Clear Note
                    </button>
                  </div>   
                }


                {/*//* start - handle notes */}
                <div>
 
                  <button
                    onClick={()=>{
                      handleActiveWondow_Notes(data.topic_id, data.day_id)
                      handleHideNoteTemp()
                      setHideNote_Temp(false)
                      setActiveAddTopicDetailType("add_note_no_limit")
                      openPopup()
                      setSaveChangesStatus(true)
                    }}
                    className='container_p-note-singleNote-displayNotes-Note_TD'
                  >

                    { 
                      !data.notes_data &&
                      <div>
                        Click to Enter Notes...
                      </div>
                    }

                    { displayLatestNote  && data.notes_data &&
                      <div style={{background:'white'}}
                      className='container_t-note-singleNote-displayNotes-Note_TD'
                      >
                        <TipTap_ReadOnly
                          content={data.notes_data}
                        />
                      </div>
                    }  

                  </button>  

                </div>

               
                {/*//! Popup */}
                {
                  activeAddTopicDetailType === "add_note_no_limit" &&
                
                  <div>

                    <div className='popup-background-1-Entities_TD'
                      onClick={()=>{
                        closePopup()
                        handleDisplayUpdatedNotes()
                      }}
                    >
                    </div>

                    {/* <div className='popup-addNote-Entities_TD'> */}
                    <div className='popup_p-addNote-AddNotes_TO_Popup'>
                      <div className='popup_s-addNote-AddNotes_TO_Popup'>



                      <button
                      className='container-askAi-DaySelector_TD'
                      style={{width:'fit-content', marginLeft:'10px', alignSelf:'start'}}
                      onClick={()=>{
                        setNavigate_aiItineraryChat(true)
                        // openPopup()
                      }}
                      >

                        <div className='text-trackAiQCount-DaySelector_TD'
                        >
                          {askQuestionCount_day}/20
                        </div>

                        <div
                        className='button-askAi-DaySelector_TD'
                        >  
                          Ask Ai
                        </div>

                        <img
                          src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/ai_chatbot-2.png'
                          className='img-iButton-AiItinerary_Chat' 
                        />
                      </button>


                        <div>
                          <button 
                            onClick={()=>{
                              closePopup()
                              handleDisplayUpdatedNotes()
                            }}
                            // className='button-close-popup-Note_TD'
                            className='button-close-popup-AddNotes_TO_Popup'
                          >
                            <IoMdCloseCircle />
                          </button>
                        </div>   


                        <div> 
                          <Notes_Tool
                          userInputText={data.notes_data}
                          topic_id={data.topic_id}
                          day_id={data.day_id}
                          handleUserInputText={handleQuill_NoteInput}
                          placeholderPassed={"enter notes for the budget..."}
                          textLength={textLength}
                          handleTextLength={handleTextLength}
                          handleDayInfo_FitinMaxLimit_NoteInput={handleDayInfo_FitinMaxLimit_NoteInput}
                          wordLimit={1500}
                          setSaveChangesStatus={setSaveChangesStatus}
                          />
                        </div> 

                        <div style={{height:'200px'}}></div>

                      </div> 
                    </div>

                  </div>

                }

              </div>  
            )
          }
        })
        
      }  

    </div>
  )
}

export default Note_TD