// MyContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'

import axios from 'axios';

// import cookies 
import { getCookies_userCredentials, parseUserCredentialsData } from '../../../../Cookies/LoginCredentitals';
import { server_baseUrl } from '../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';

// Step 1: Create a new context
const Context = createContext();


// Step 2: Create a context provider
export const Context_validatePlanTrip = ({ children }) => {

  const navigate = useNavigate();

  const {shareTripId} = useParams()

  //##1 plan trip global states
  const [tripId, setTripId] = useState(null)
  const [tripName, setTripName] = useState()
  const [stateName, setStateName] = useState()
  const [districtName, setDistrictName] = useState()
  const [cityName, setCityName] = useState()

  const [tripDestinationType, setTripDestinationType] = useState()
  const [tripDestinationName, setTripDestinationName] = useState()
  const [stateMultipleDistricts, setStateMultipleDistricts] = useState()

  const [userEmail, setUserEmail] = useState();

  //destination lat and lng
  const[destinationLat, setDestinationLat] = useState()
  const[destinationLng, setDestinationLng] = useState()
  const[destinationZoom, setDestinationZoom] = useState(7)


  useEffect(() => {
    if(!shareTripId){
      handleGetUserLoginStatus();
    }
    else{
      loadBasicDetails();
    }

  }, [])      

  const handleGetUserLoginStatus = () => {

    const userDataCookie = getCookies_userCredentials("userData");
    if (userDataCookie) {
      const userData = parseUserCredentialsData(userDataCookie);
      if (userData) {
          setUserEmail(userData.email);
          // console.log("Login status:", userData.loginStatus);
      }
    } else {
      // console.log("User data cookie not found.");
    }

  }  
  

  //!Share Trip
  const loadBasicDetails = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/api/plan_trip/share_trip/get_details`, {shareTripId})

    // console.log("shareTripId", shareTripId, "response", response.data)

    if(response.data !== "0"){
      const {user_email, trip_id, state_name, destination_name, destination_type, trip_name, destination_lat, destination_lng} = response.data[0];

      setTripId(trip_id);
      setStateName(state_name);
      setStateName_2(state_name); //for share trip: this state helps in setting the state, and preventing the confusion between the "stateName" from the params, and the state name in the validate trip s
      setTripName(trip_name)
      setUserEmail(user_email)
      setTripDestinationName(destination_name)
      setTripDestinationType(destination_type)
      setDestinationLat(destination_lat) 
      setDestinationLng(destination_lng) 
    }
    else{
      alert("error in loading the trip data.");
    }
  }

  const[stateName_2, setStateName_2] = useState()

  

    //*##1 get lat and lng of the destination

    const getDestinationLatLng = async (destinatioName, destinationType) => {

      if(destinatioName && destinationType){

        const response = await axios.post(`${server_baseUrl}/csc/validate/plan_trip/get_lat_lng`, {destinatioName, destinationType})

        // console.log("getDestinationLatLng", "destinatioName", destinatioName, "destinationType", destinationType)
        // console.log("getDestinationLatLng - response.data", response.data)
        if(response.data !== "0"){
          setDestinationLat(response.data[0].lat) 
          setDestinationLng(response.data[0].lng)
        }
        else{
          // console.log("error - getDestinationLatLng")
        }
      }
      else{
        // console.log(`empty: destinatioName - ${destinatioName} && destinationType - ${destinationType} `)
      }
    }

 

  //##2 validate trip destination details

  const validate_city = async (stateNamePassed, districtNamePassed, cityNamePassed) => {

    // console.log("validate_city was called", stateNamePassed, districtNamePassed, cityNamePassed)
    const response = await axios.post(`${server_baseUrl}/csc/validate/plan_trip/validate_plan_trip/validate_city`, {stateNamePassed, districtNamePassed, cityNamePassed})

    // console.log("validate_city - response.data", response.data)

    if( response.data !== "0"){
      setStateName(stateNamePassed)
      setDistrictName(districtNamePassed) 
      setCityName(cityNamePassed)
      setTripDestinationType("city")
      setTripDestinationName(cityNamePassed)
      //load lat and lng
      getDestinationLatLng(cityNamePassed, "city")
    }
    else{
      // navigate('/error-things_not_found/error_id-pth-0-002/resolve_1-click-on-home-page/resolve_2-share-error-id')
      // console.log("error - validate_city")
    }
  } 


  const validate_district = async (stateNamePassed, districtNamePassed) => {

    const response = await axios.post(`${server_baseUrl}/csc/validate/plan_trip/validate_plan_trip/validate_district`, {stateNamePassed, districtNamePassed})

    if(response.data !== "0"){  
      setStateName(stateNamePassed)
      setDistrictName(districtNamePassed)
      setTripDestinationType("district")
      setTripDestinationName(districtNamePassed)
      //load lat and lng
      getDestinationLatLng(districtNamePassed, "district")
    }
    else{
      navigate('/error-things_not_found/error_id-pth-0-003/resolve_1-click-on-home-page/resolve_2-share-error-id')
    }

  }


  const validate_state = async (stateNamePassed) => {

    const response = await axios.post(`${server_baseUrl}/csc/validate/plan_trip/validate_plan_trip/validate_state`, {stateNamePassed})
    // console.log("stateNamePassed", stateNamePassed)
    if(response.data !== "0"){
      setStateName(stateNamePassed)
      setTripDestinationType("state")
      setTripDestinationName(stateNamePassed)
      //load lat and lng
      getDestinationLatLng(stateNamePassed, "state")
    }
    else{
      // navigate('/error-things_not_found/error_id-pth-0-004/resolve_1-click-on-home-page/resolve_2-share-error-id')
      // console.log("error - und/error_id-pth-0-004")
    }

  }

  const validate_stateMultiDistrict = async (stateNamePassed) => {

    const response = await axios.post(`${server_baseUrl}/csc/validate/plan_trip/validate_plan_trip/validate_state_multiple_districts`, {stateNamePassed})

    if( response.data === 1){
      setStateMultipleDistricts(true)
    }
    else{
      // navigate('/error-things_not_found/error_id-pth-0-005/resolve_1-click-on-home-page/resolve_2-share-error-id')
      setStateMultipleDistricts(false)
    }

  }


//##3 validate tripId

const validate_TripId = async (tripIdPassed, userEmail, tripNamePassed) => {

  const response = await axios.post(`${server_baseUrl}/csc/validate/plan_trip/validate_plan_trip/validate_trip_id`, {tripIdPassed, userEmail, tripNamePassed})
  // console.log("response.data - validate_TripId", response.data);
  if( response.data.length > 0 && response.data !== 0){
    setTripId(response.data[0].trip_id)
    setTripName(response.data[0].trip_name)

  }
  else{
    // console.log("error in validate_TripId")
    navigate('/error-things_not_found/error_id-pth-0-006/resolve_1-click-on-home-page/resolve_2-share-error-id')
  }

}

const getPlanTripProgressStatus = () => {

}


  // Step 5: Return a provider with value containing state and functions
  return (
    <Context.Provider value={{ 
      tripId, tripName, setTripName, stateName, districtName, cityName, tripDestinationType, setTripDestinationType, tripDestinationName, setTripDestinationName, stateMultipleDistricts, userEmail, 
      //
      validate_city, validate_district, validate_state, validate_stateMultiDistrict, validate_TripId,
      //global lat, lng, and zoom for the detsination
      destinationLat, destinationLng, destinationZoom,
      setDestinationLat, setDestinationLng, setDestinationZoom,

      //
      stateName_2, setStateName_2,

    }}>
      {children}
    </Context.Provider>
  );

};

// Step 6: Create a custom hook to consume the context
export const useValidatePlanTrip = () => {
  return useContext(Context);
};

