export const MonthsName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

export const Weeks = [
    "1st Week", 
    "2nd Week", 
    "3rd Week", 
    "4th Week",
    "5th Week"
]

export const notesHeadingIdeas_old = [
    "Budget",
    "Itinerary",
    "Packing List",
    "Clothing",
    "Accommodation",
    "Transportation",
    "Activities",
    "Food",
    "Restaurants",
    "Weather",
    "Safety Tips",
    "Shopping",
    "Emergency Contacts",
    "Local Attractions",
    "Health & Medicine",
    "Important Documents",
    "Gifts & Souvenirs",
    "Travel Insurance",
    "Language & Phrases",
    "Cultural Tips",
    "Entertainment",
    "Technology & Gadgets",
    "Photo Spots",
    "Workout & Fitness",
    "Laundry Services",
    "Miscellaneous"
];

export const notesHeadingIdeas = [
    { heading: "Budget", emoji: "💰" },
    { heading: "Itinerary", emoji: "🗺️" },
    { heading: "Packing List", emoji: "📦" },
    { heading: "Clothing", emoji: "👗" },
    { heading: "Accommodation", emoji: "🏨" },
    { heading: "Transportation", emoji: "🚗" },
    { heading: "Activities", emoji: "🎨" },
    { heading: "Food", emoji: "🍴" },
    { heading: "Restaurants", emoji: "🍽️" },
    { heading: "Weather", emoji: "☀️" },
    { heading: "Safety Tips", emoji: "🛡️" },
    { heading: "Shopping", emoji: "🛍️" },
    { heading: "Emergency Contacts", emoji: "📞" },
    { heading: "Local Attractions", emoji: "🏰" },
    { heading: "Health & Medicine", emoji: "💊" },
    { heading: "Important Documents", emoji: "📑" },
    { heading: "Gifts & Souvenirs", emoji: "🎁" },
    { heading: "Travel Insurance", emoji: "🛡️" },
    { heading: "Language & Phrases", emoji: "🗣️" },
    { heading: "Cultural Tips", emoji: "🎎" },
    { heading: "Entertainment", emoji: "🎉" },
    { heading: "Technology & Gadgets", emoji: "📱" },
    { heading: "Photo Spots", emoji: "📸" },
    { heading: "Workout & Fitness", emoji: "🏋️" },
    { heading: "Laundry Services", emoji: "🧺" },
    { heading: "Miscellaneous", emoji: "🗂️" }
];

  
export const notesEmojis = [
    "💸",  // Budget
    "🗺️",  // Itinerary
    "🧳",  // Packing List
    "👕",  // Clothing
    "🏨",  // Accommodation
    "🚗",  // Transportation
    "🎢",  // Activities
    "🍴",  // Food
    "🍽️",  // Restaurants
    "🌤️",  // Weather
    "🛡️",  // Safety Tips
    "🛍️",  // Shopping
    "📞",  // Emergency Contacts
    "🏛️",  // Local Attractions
    "💊",  // Health & Medicine
    "📑",  // Important Documents
    "🎁",  // Gifts & Souvenirs
    "🛡️",  // Travel Insurance
    "🗣️",  // Language & Phrases
    "🎎",  // Cultural Tips
    "🎬",  // Entertainment
    "💻",  // Technology & Gadgets
    "📸",  // Photo Spots
    "🏋️",  // Workout & Fitness
    "🧺",  // Laundry Services
    "📝"   // Miscellaneous
];


// export const notesHeadingIdeas = [
//     "Budget 💸",
//     "Itinerary 🗺️",
//     "Packing List 🧳",
//     "Clothing 👕",
//     "Accommodation 🏨",
//     "Transportation 🚗",
//     "Activities 🎢",
//     "Food 🍴",
//     "Restaurants 🍽️",
//     "Weather 🌤️",
//     "Safety Tips 🛡️",
//     "Shopping 🛍️",
//     "Emergency Contacts 📞",
//     "Local Attractions 🏛️",
//     "Health & Medicine 💊",
//     "Important Documents 📑",
//     "Gifts & Souvenirs 🎁",
//     "Travel Insurance 🛡️",
//     "Language & Phrases 🗣️",
//     "Cultural Tips 🎎",
//     "Entertainment 🎬",
//     "Technology & Gadgets 💻",
//     "Photo Spots 📸",
//     "Workout & Fitness 🏋️",
//     "Laundry Services 🧺",
//     "Miscellaneous 📝"
// ];
