import React from 'react'
import './ContactUs.css'
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from 'react-router-dom';
function ContactUs() {
  return (
    <div>
      {/* <button className='contactUs-backButton'> Back Home</button> */}
      <Link to='/' className='contactUs-backButton'><AiOutlineArrowLeft/></Link>
        <div className='contactUs-mainContainer'>
         <div className='contactUs-lineCss'> Feel free to connect! 🙂</div>
          <br></br> 
          <b>CEO</b> <tab></tab> <tab></tab> <i>rishabhtyagi069@gmail</i>  <br></br> <br></br>
          <b>CTO</b> <i>atultyagi118@gmail.com</i>
{/* 
          <span>CEO:{' '}<a href="mailto:yourcompany@gmail.com">rishabhtyagi069@gmail</a></span> <br></br>
          <span>CTO:{' '}<a href="mailto:yourcompany@gmail.com">atultyagi118@gmail.com</a></span> */}

        </div>
       
    </div>
  )
}

export default ContactUs