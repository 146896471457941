import React, {useState, useEffect} from 'react'

//import scss
import './Hotel_ViewMore_PT.scss'
import './Hotel_ViewMore_Mobile_PT.scss'

// import react context
import { TravelCategories_2_Context } from '../../../../Manage/Context/TravelCategories_2_Context'; 

//import react icons
import { FaCircleChevronLeft } from "react-icons/fa6";
import { FaCircleChevronRight } from "react-icons/fa6";
import { IoMdCloseCircle } from "react-icons/io";
import { FcGoogle } from "react-icons/fc";
import { TravelCategories_Context } from '../../../../Manage/Context/TravelCategories_Context';


function Hotel_ViewMore_PT(props) {

  //use react context 
  const {
    hotelData
  } = TravelCategories_Context()

  const {
    //hotel data
    priceData, aboutData, activeReviewData, reviewData,
    //view more 
    viewMore_id, setViewMore_id,
    viewMoreStatus, setViewMoreStatus, 
    review_showMoreTrack, setReview_showMoreTrack,
    //
    handleShowMore_Reviews,
    //loading
    loadingStatus,
    loadingStatusHotels, setLoadingStatusHotels
  } = TravelCategories_2_Context();




  const closePopup = () => {
    setViewMoreStatus(false);
    setReview_showMoreTrack(0);
    document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  const[totalReviewCount_Limited, setTotalReviewCount_Limited] = useState(0)
  const[activeReviewId, setActiveReviewId] = useState(0)

  useEffect(() => {
    setTotalReviewCount_Limited(activeReviewData.length);
    setActiveReviewId(0)
  }, [])

  const handleNextReview = () => {

    const id_temp = activeReviewId + 1;

    if(id_temp < totalReviewCount_Limited){
        setActiveReviewId(id_temp)
    }
    else{
        setActiveReviewId(0)
    }

  }

  const handlePreviousReview = () => {

    const id_temp = activeReviewId - 1 ;
    
    if(id_temp !== -1){
        setActiveReviewId(id_temp)
    }
    else{
        setActiveReviewId(0)
    }

  }



  const [isMobile, setIsMobile] = useState(window.innerWidth <= 799); // Initialize with the current width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 799); // Update state on window resize
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <div>


        <div 
        onClick={()=>{
            // setViewMoreStatus(false)
            // setReview_showMoreTrack(0)
            closePopup()
        }}
        className='popup-background-1-Hotel_ViewMore'
        >
        </div>

        <div 
        // className='popup_p-2-addNote-Hotel_ViewMore'
        className={isMobile ? 'popup_p-2-addNote-Hotel_ViewMore_Mobile' : 'popup_p-2-addNote-Hotel_ViewMore'}
        >
          <div className='popup_s-2-addNote-Hotel_ViewMore'>

        <button
            className='button-close-DayWise_Mobile'
            onClick={()=>{
            // setNavigation_content_map("onlyMap")
            closePopup()
            }}
        >
            <IoMdCloseCircle />
        </button>

        {/* {
            loadingStatusHotels === "reviews_data" && 
            <div>
                <b>Reviews are loading</b>
            </div>
        } */}

        {
        // (loadingStatus === "hotels_data") &&
        loadingStatus && 
        <div className="overlay">
            <div className="spinner-container">
            <div className="spinner"></div>
            <p>Loading Hotels...</p>
            </div>
        </div>
        }


        { activeReviewData && activeReviewData.length > 0 && viewMore_id &&
            hotelData.map((data, index) => {
                if(data.id === viewMore_id){
                    return(
                        <div 
                        style={{zIndex:'1000000'}}
                        >
                            
                            {data.name &&
                                <div
                                className='heading-1-heading-Hotel_ViewMore'
                                >
                                    {data.name}
                                </div>
                            }
                                <br></br>
                            {
                            data.address &&
                            <div>

                                <div
                                className='heading-1-Overview_VM'
                                >
                                    Address
                                </div>

                                <div
                                className='heading-2-Overview_VM'
                                >  
                                    {data.address}
                                </div>

                            </div>   
                            }
                            
                            <div
                            style={{display:'flex', flexDirection:'row', alignItems:'center', textAlign:'center', width:'100%', justifyContent:'space-around', marginTop: '12px'}}
                            >

                                {
                                data.tel &&
                                <div>

                                    <div
                                    className='heading-1-Overview_VM'
                                    >
                                        Phone Number
                                    </div> 

                                    <div
                                    className='heading-2-Overview_VM'
                                    >   
                                        {data.tel}
                                    </div>

                                </div>   
                                }

                                {
                                data.website_link &&
                                <div
                                // className='conatiner_t-3-Overview_VM'
                                style={{marginTop:'4px'}}
                                >


                                    <div
                                    className='conatiner_t-3-Overview_VM'
                                    >
                                        <a
                                        href={data.website_link} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className='conatiner_p-GoogleUrl-Overview_VM'
                                        >
                                            {
                                            data.official_site ?
                                            <div
                                            className='heading-1-Overview_VM'
                                            >
                                            Official Website Site
                                            </div>
                                            :
                                            <div
                                            className='heading-1-Overview_VM'
                                            >
                                            Website Link
                                            </div>
                                            }

                                            <img
                                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/share_icon_1.png'
                                            className='img-link-Document-TripDocuments'
                                            />
                                        </a>    
                                    </div>    

                                </div>   
                                }

                                {
                                data.url &&
                                <div
                                style={{marginTop:'8px'}}
                                >

                                    <div
                                    className='conatiner_t-3-Overview_VM'
                                    >
                                        <a
                                        href={data.url} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className='conatiner_p-GoogleUrl-Overview_VM'
                                        >

                                            <div
                                            className='heading-1-Overview_VM'
                                            >  
                                                See on Google
                                            </div>

                                            <img
                                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/share_icon_1.png'
                                            className='img-link-Document-TripDocuments'
                                            />

                                        </a>    
                                    </div>    

                                </div>   
                                }

                            </div>


                            <div className='container_p-Reviews-Hotel_ViewMore'>
                                {activeReviewData && activeReviewData.length > 0 &&
                                    activeReviewData.map((data, index_review) => {
                                    return (
                                        <div className='container_s-Reviews-Hotel_ViewMore' key={index_review}>

                                        {/* Review Header */}
                                        <div className='container_t-2-Reviews-Hotel_ViewMore'>
                                            <div className='conatiner_p-reviewer-data-Review_VM'>
                                            
                                            {/* Reviewer Name and Link */}
                                            <div className='heading-1-data-Review_VM'>
                                                <i>Review from</i>
                                            </div>
                                            <a
                                                className='link-1-data-Review_VM'
                                                href={data.reviewer_profile_link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {data.reviewer_name}
                                            </a>
                                            
                                            {/* Reviewer Icon */}
                                            <div className='icon-1-data-Review_VM'>
                                                <FcGoogle />
                                            </div>
                                            
                                            {/* Review Date */}
                                            <div className='heading-1-reviewData-data-Review_VM'>
                                                <i>{data.review_date}</i>
                                            </div>
                                            </div>

                                            {/* Review Content */}
                                            <div className='heading-2-data-Review_VM'>
                                            {data.text}
                                            </div>
                                        </div>

                                        </div>
                                    );
                                    })
                                }
                            </div>


                        </div>    
                    )
                }
            })
        }  

         </div> 
        </div>  

    </div>
  )
}

export default Hotel_ViewMore_PT



// *Show more
{/* {
    activeReviewId === totalReviewCount_Limited && review_showMoreTrack !== "xx" &&
    (
        <div>
            <button 
                onClick={()=>{
                    handleShowMore_Reviews(viewMore_id)
                    handleNextReview()
                }}
            >
                Show More
            </button>
        </div>    
    )
} */}





//old code for reviews. with scroll, where we were just displaying the single review
       
// <div
// className='container_p-Reviews-Hotel_ViewMore'
// >
//     { activeReviewData && activeReviewData.length > 0 &&
//     activeReviewData.map((data, index_review) => {
//         if(data.id === viewMore_id && index_review === activeReviewId){
//             return(
//                 <div
//                 className='container_s-Reviews-Hotel_ViewMore'
//                 >

//     {/*//!#1  */}
//     {
//         index_review > 0 &&
//         <button
//         className='container_t-1-Reviews-Hotel_ViewMore'
//         onClick={()=>{handlePreviousReview(index_review)}}
//         >
//             <FaCircleChevronLeft />
//         </button>
//     }

//     {/*//!#2  */}
//     <div
//     className='container_t-2-Reviews-Hotel_ViewMore'
//     >
//         <div
//         className='conatiner_p-reviewer-data-Review_VM'
//         >
//             <div
//             className='heading-1-data-Review_VM'
//             >
//             <i>review from</i>
//             </div> 
//             <a
//             className='link-1-data-Review_VM'
//             href={data.reviewer_profile_link}
//             target="_blank"
//             rel="noopener noreferrer"
//             >
//             {data.reviewer_name}
//             </a>  
//             <div
//             className='icon-1-data-Review_VM'
//             >
//                 <FcGoogle />
//             </div> 
//             <div
//             className='heading-1-reviewData-data-Review_VM'
//             >
//                 <i>{data.review_date}</i>
//             </div>    
//         </div>

//         {/*//? Content */}
//         <div
//         className='heading-2-data-Review_VM'
//         >
//             {data.text}
//         </div>  

//         {/*//? meta data */}
//         {
//             data.review_meta_info &&
    
//             <div
//             className='conatiner_p-metaData-data-Review_VM'
//             >
//                 {
//                 JSON.parse(data.review_meta_info).map((metaData, index) => {
//                 return(
//                     <div key={index}
//                     className='conatiner_s-metaData-data-Review_VM'
//                     >
//                     {metaData.heading}: {metaData.data}
//                     </div>   
//                 )
//                 })
//                 }
//             </div> 
//         }

//     </div>  

//     {/*//!#3 */}
//     {
//         index_review < totalReviewCount_Limited &&
//         <button
//         className='container_t-3-Reviews-Hotel_ViewMore'
//         onClick={()=>{handleNextReview(index_review)}}
//         >
//             <FaCircleChevronRight />
//         </button>
//     }


//                 </div>    
//                 )
//             }
//         })
//     }
    
// </div>   




//!old Code
// import React, {useState} from 'react'

// // import react context
// import {useHotelsContext} from '../../Manage/Context/HotelsContext'

// function Hotel_ViewMore(props) {

//   //use react context 
//   const {
//     //hotel data
//     hotelData, priceData, aboutData, activeReviewData, reviewData,
//     //view more 
//     viewMore_id, setViewMore_id,
//     viewMoreStatus, setViewMoreStatus, 
//     review_showMoreTrack, setReview_showMoreTrack,
//     //
//     handleShowMore_Reviews
//   } = useHotelsContext();


//   const closePopup = () => {
//     setViewMoreStatus(false);
//     setReview_showMoreTrack(0);
//     document.body.style.overflow = 'auto'; // Enable scrolling on the body
//   };


//   return (
//     <div>

//         <div 
//         onClick={()=>{
//             // setViewMoreStatus(false)
//             // setReview_showMoreTrack(0)
//             closePopup()
//         }}
//         className='popup-background-1-Entities_TD'
//         >
//         </div>

//         <div className='popup_p-2-addNote-AddNotes_TO_Popup'>
//           <div className='popup_s-2-addNote-AddNotes_TO_Popup'>

//         { viewMore_id &&
//             hotelData.map((data, index) => {
//                 if(data.id === viewMore_id){
//                     return(
//                         <div>
//                             {data.name}
//                             {data.url}

//                             <b>Price</b>
//                             {
//                                 priceData.map((priceData, priceIndex) => {
//                                     if(data.id === priceData.id){
//                                         return(
//                                             <div>
//                                                 <div>
//                                                     <div>
//                                                         <i>name</i>
//                                                         {priceData.hotel_name} 
//                                                     </div>  

//                                                     <div>
//                                                         <i>price</i>
//                                                         {priceData.hotel_price}
//                                                     </div>  

//                                                     { priceData.hotel_link &&
//                                                         <div>
//                                                         <a 
//                                                             href={priceData.hotel_link}
//                                                             target="_blank" 
//                                                             rel="noopener noreferrer"
//                                                         >
//                                                             {
//                                                             priceData.official_site ?
//                                                             <div>
//                                                                 Visit Official Site
//                                                             </div>
//                                                             :
//                                                             <div>
//                                                                 Visit Site
//                                                             </div>
//                                                             }
//                                                         </a>
//                                                         </div>
//                                                     }

//                                                     {
//                                                         priceData.free_breakfast && 
//                                                         <div>
//                                                         Free Breakfast
//                                                         </div>  
//                                                     }
//                                                     {
//                                                         priceData.free_wifi && 
//                                                         <div>
//                                                         Free Wi-Fi
//                                                         </div>  
//                                                     }
//                                                     {
//                                                         priceData.free_parking && 
//                                                         <div>
//                                                         Free Parking
//                                                         </div>  
//                                                     }
//                                                     {
//                                                         priceData.free_cancellation && 
//                                                         <div>
//                                                         Free Cancellation
//                                                         </div>  
//                                                     }
//                                                     {
//                                                         priceData.free_cancellation_detail && 
//                                                         <div>
//                                                         until {priceData.free_cancellation_detail}
//                                                         </div>  
//                                                     }
//                                                 </div> 
//                                                 <br></br>
//                                                 <br></br>    
//                                             </div>    
//                                         )
//                                     }
//                                 })
//                             }

//                             { aboutData.length > 0 &&
//                                 <div>
//                                     <div>
//                                         <b>About</b>
//                                     </div>    
//                                     <div>
//                                     {
//                                         aboutData.map((aboutData, aboutIndex) => {
//                                             if(data.id === aboutData.id){
//                                                 return(
//                                                     <div key={aboutIndex}>

//                                                         <div>

//                                                             <div>
//                                                                 <i>{aboutData.about_text}</i>
//                                                             </div>   

//                                                             {
//                                                                 aboutData.available_features && 
//                                                                 <div>
//                                                                     {JSON.parse(aboutData.available_features).map((item, index) => (
//                                                                         <div key={index}><b>{item}</b></div>
//                                                                     ))}
//                                                                 </div>
//                                                             }

                                                            
//                                                         </div>    
//                                                     </div>     
//                                                 )
//                                             }
//                                         })
//                                     }
//                                     </div>
//                                 </div>
//                             }
//                             {
//                                 activeReviewData && activeReviewData.length > 0 &&
//                                  // relative_unique_id as id, reviewer_profile_link as link, review_date as when, review_text as text, review_meta_info as meta
//                                 <div> 
//                                     <div>
//                                         <b>Reviews {review_showMoreTrack}</b>
//                                     </div>    
//                                     {
//                                         activeReviewData.map((data, index) => {
//                                             if(data.id === viewMore_id){
//                                                 return(
//                                                     <div>
//                                                          <b>{index}</b>   
//                                                         <div>
//                                                             <a
//                                                             href={priceData.link}
//                                                             target="_blank" 
//                                                             rel="noopener noreferrer"
//                                                             >
//                                                                 Profile Like
//                                                             </a>
//                                                         </div>    

//                                                         <div>
//                                                             <i>{data.ago_data}</i>
//                                                         </div>    

//                                                         <div>
//                                                             {data.text}
//                                                         </div>     

//                                                     </div>    
//                                                 )
//                                             }
//                                         })
//                                     }
//                                     {
//                                         review_showMoreTrack !== "xx" &&
//                                         (
//                                             <div>
//                                                 <button onClick={()=>{handleShowMore_Reviews(viewMore_id)}}>
//                                                     Show More
//                                                 </button>
//                                             </div>    
//                                         )
//                                     }
//                                 </div> 
//                             }
//                         </div>    
//                     )
//                 }
//             })
//         }  

//          </div> 
//         </div>  

//     </div>
//   )
// }

// export default Hotel_ViewMore