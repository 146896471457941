// MyContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'

import axios from 'axios';
import Moment from 'moment';


//importing react context
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip';
import { useCreateItineraryPlanTrip } from './CreateItinerary_Context';
import { server_baseUrl } from '../../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';


// Step 1: Create a new context
const Context = createContext();


// Step 2: Create a context provider
export const Context_createItinerary_2_PlanTrip = ({ children }) => {

  const navigate = useNavigate();

  // using react context
  const { 
    userEmail, tripId, tripName, 
    //trip details
    tripDestinationName, tripDestinationType, stateName, districtName, cityName, destinationLat, destinationLng, destinationZoom 
  } = useValidatePlanTrip();

  const{
 
  } = useCreateItineraryPlanTrip()



  //!states
  //*manage 
  const[aiItinerary_initialLoad_Status, setAiItinerary_initialLoad_Status] = useState(false)
  const[sentActive_status, setSentActive_status] = useState(false)
  //track
  const[askQuestionCount_day, setAskQuestionCount_day] = useState(0)
  
  //*data
  const[aiItineraryData, setAiItineraryData] = useState([])
  const[currentQuestion, setCurrentQuestion] = useState([]);
  const[currentAnswer, setCurrentAnswer] = useState([]);
  const[aiItinerary_Id, setAiItinerary_Id] = useState(0)
  const[last_2_conversation, setLast_2_conversation] = useState([])

  //*navigate
  const[navigate_aiItineraryChat, setNavigate_aiItineraryChat] = useState();
  const[aiItineraryChat_window, setAiItineraryChat_window] = useState();

  

  //!functions


  useEffect(() => {
    if(!aiItinerary_initialLoad_Status && userEmail && tripId){
      handleLoadAiItinerary();
    }
  }, [userEmail, tripId])

  
  const handleLoadAiItinerary = async () => {
    
    // console.log("userEmail, tripId", userEmail, tripId)

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/ai_itinerary_chat/load_data`, {userEmail, tripId})
    
    // console.log("handleLoadAiItinerary", response.data)
    
    if(response.data != "1" && response.data.length > 0){
      const lastRow = response.data.length-1;
      setAiItineraryData(response.data);
      setAiItinerary_Id(response.data[lastRow].id)
      //
      setAiItinerary_initialLoad_Status(true)

      //
      handleSetQuestionTrackCount(response.data)
    }
    else if(response.data == "1"){
      setAskQuestionCount_day(20)
      alert("you have exhasued the limit for asking questions ina day.")
    }
    else{
      // alert("some error in loading the answer. You might try again after sometime.")
    }
    
  }


  const test = () => {
    handleSetQuestionTrackCount(aiItineraryData);
  }

  const handleSetQuestionTrackCount = (data) => {

    // console.log("data", data)

    const date_short = new Date();
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = date_short.toLocaleDateString('en-GB', options);

    const count = data.filter(item => formattedDate == item.day_action_time).length;

    // console.log(count, "count", formattedDate, "formattedDate")

    setAskQuestionCount_day(count)

  }

  
  const handleInput_CurrentQuestion = (event) => {
    setCurrentQuestion(event.target.value)
  }

  
  const handleAskQuestion = () => {

    setSentActive_status(true)
    setCurrentQuestion('')
    setCurrentAnswer('')

    setAiItineraryData((prevData) => [
      ...prevData, 
      // {answer: currentAnswer, question: currentQuestion, id:aiItinerary_Id+1}
      {question: currentQuestion, id:aiItinerary_Id+1}
    ]);

    handleAskAiQuestion();

  }
  
  
  const handleAskAiQuestion = async () => {

    // console.log("userEmail, tripId, currentQuestion, aiItinerary_Id, last_2_conversation", userEmail, tripId, currentQuestion, aiItinerary_Id, last_2_conversation)

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/ai_itinerary_chat/ask_insert_data`, {userEmail, tripId, currentQuestion, aiItinerary_Id, last_2_conversation})
    
    // console.log("handleAskAiQuestion", response.data)

    if(response.data != "1"){

      const id = aiItinerary_Id+1;

      await animateText(response.data, setCurrentAnswer, id)

      setAiItinerary_Id(id)

      setTimeout(() => {
        // setSentActive_status(false)
        handleStoreLast2Conversations(currentQuestion, response.data)
      }, 2000);

      setAskQuestionCount_day(askQuestionCount_day+1)
    }
    else if(response.data == "1"){
      setAskQuestionCount_day(21)
      alert("you have exhasued the limit for asking questions ina day.")
      setSentActive_status(false)
    }
    else{
      // alert("some error in loading the answer. You might try again after sometime.")
      setSentActive_status(false)
    }
  }


  function animateText(randomEntity_temp, setRandomPromt1, idPassed) {
    let index = 0;
    const intervalId = setInterval(() => {
      const currentWord = randomEntity_temp.substring(0, index + 1);
      // setRandomPromt1(currentWord); 

      setAiItineraryData((prevData) =>
        prevData.map((item) =>
          item.id === idPassed ? { ...item, answer: currentWord } : item
        )
      );
      
      index++;
  
      if (index >= randomEntity_temp.length) {
        clearInterval(intervalId);
        setSentActive_status(false)
      }
      
    }, 8);  
 
    return;

  }



  const handleStoreLast2Conversations = (latestQuestionPassed, latestAnswerPassed) => {
    const lastTwoRows = aiItineraryData.slice(-2);
    let temp_last_2_conversation = lastTwoRows.map((item) => ({
      question: limitWords(item.question || '', 60),
      answer: limitWords(item.answer || '', 60),
    }));
  
    if (latestQuestionPassed || latestAnswerPassed) {
      temp_last_2_conversation.push({
        question: limitWords(latestQuestionPassed || '', 60),
        answer: limitWords(latestAnswerPassed || '', 60),
      });
    }
  
    // Ensure that we only keep the last two conversations
    if (temp_last_2_conversation.length > 2) {
      temp_last_2_conversation = temp_last_2_conversation.slice(-2);
    }
  
    setLast_2_conversation(temp_last_2_conversation);
  };
  

  const limitWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };
  

  //* i button
  const[iButtonMessage, setIButtonMessage] = useState(false)

  //
  const handleIButton = (status, type) => {

    const message = handleGetMessage(type)

    setIButtonMessage(message)
      
  }

  const handleGetMessage = (type) => {

    let returnMessage;

    if(type === "message"){
      returnMessage = "You are on a free version. It has a memory of the last two conversations. It has a limit of 20 messages per day."
    }

    return returnMessage;
  }




  
  // Step 5: Return a provider with value containing state and functions
  return (
    <Context.Provider value={{ 

      //!States

      //*manage
      aiItinerary_initialLoad_Status, setAiItinerary_initialLoad_Status,
      sentActive_status, setSentActive_status,

      //track
      askQuestionCount_day, setAskQuestionCount_day,

      //i button
      iButtonMessage, setIButtonMessage, 
      handleIButton,

      //*data
      aiItineraryData, setAiItineraryData,
      currentQuestion, setCurrentQuestion,
      currentAnswer, setCurrentAnswer,
      aiItinerary_Id, setAiItinerary_Id,
      last_2_conversation, setLast_2_conversation,

      //*navigate
      navigate_aiItineraryChat, setNavigate_aiItineraryChat,
      aiItineraryChat_window, setAiItineraryChat_window,
    
      
      //!Functions   
      handleInput_CurrentQuestion,
      handleAskQuestion, handleLoadAiItinerary,

      //
      test

      
    }}>
      {children}
    </Context.Provider>
  );

};

// Step 6: Create a custom hook to consume the context
export const useCreateItinerary_2_PlanTrip = () => {
  return useContext(Context);
};



