import React, {useRef, useEffect, useState} from 'react'

//import css
import './Map_Hotels.scss';

import { Rating } from 'react-simple-star-rating';

// importing map
import {MapContainer, Tooltip, TileLayer, Marker, Popup, useMapEvent, useMap, useMapEvents, circle, Circle, GeoJSON} from 'react-leaflet'

import L from "leaflet";

// import react context
import {useHotelsContext} from '../../Manage/Context/HotelsContext'

//importing react context
import { saved_tourist_places, hotel_1_colored, hotel_active_1_colored, map_pin_1, hotel_1_colored_small } from '../../../../Plan Trip/Manage - Plan Trip/Icons/MapIcons';


function Map_Hotels(props) {

  const mapRef = useRef(null);

  const {lat, lng, zoom} = props;
   //handle map - drag
   useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView([lat, lng], zoom);
    }
  }, [lat, lng, zoom])

  const {
    hotelData, bestDealData,
    setLat, setLng, setZoom,
    //
    activeHotelData_map, hoverHotelData_map,
    //
    setViewMore_id, setViewMoreStatus, viewMoreStatus,
    //
    fitAllLocations, setFitAllLocations,
    hotelClickedStatus, setHotelClickedStatus,
    closePopup_Status, setClosePopup_Status,
    handleGetReviewdData, underKm, destinationType,
    //selected location/destination
    hotelLat, hotelLng, placeName, placeStateName,
    // Prices
    view_prices_popup, setView_prices_popup,
    activeViewPrices_Limited, setActiveViewPrices_Limited
  } = useHotelsContext()

  const accessToken = 'pk.eyJ1IjoicmlzaGFiaDY5IiwiYSI6ImNseHU5dTNuMzBnaGYycXNkZTRhODUxaTIifQ.o7XiAXhwt0J2lk9cQfLRrw';

  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const handleFitAllLocations = () => {
    const map = mapRef.current;
    if (map) {
      const allLocations = [
        ...hotelData,
        ...hoverHotelData_map,
        { lat: hotelLat, lng: hotelLng }
      ].filter(loc => loc.lat && loc.lng); // Filter out invalid locations
      if (allLocations.length > 0) {
        const bounds = L.latLngBounds(allLocations.map(loc => [loc.lat, loc.lng]));
        map.fitBounds(bounds);
      }
    }
    setFitAllLocations(false)
    closeAllPopups()
  };

  useEffect(() => {
    handleFitAllLocations() 
  }, [fitAllLocations])

  // const handleFit

  const handleLocateDestination = () => {
    //?Since we are not setting the lad and long to any other value other than units initially routed so when it was initially loaded the data stored was for hotel lat and hotel room and since then since we are just using setfit or object like setfit or location state this is not going to change of us we need to change it to another value and then again change to make it move over there
    setZoom(14)
    setLat(hotelLat+1)
    setLng(hotelLng+1)

    setTimeout(() => {
      setLat(hotelLat)
      setLng(hotelLng)
    }, 100);
  }


  // const popupRef = useRef(null);
  useEffect(() => {

    closeAllPopups()
    
  }, [closePopup_Status])

  const closeAllPopups = () => {
    const map = mapRef.current;
    if (map) {
      map.eachLayer((layer) => {
        if (layer instanceof L.Popup) {
          layer.close();
        }
      });
    }
  };


  return (
    <div
    className='conatiner_p-map-Map_Hotels'
    >

      <div
      className='container_p-buttons-Map_Hotels'
      >

        <button
        onClick={()=>{handleFitAllLocations()}}
        className='button-fitAll-Map_Hotels'
        >
          Fit All
        </button>

        <button
        onClick={()=>{handleLocateDestination()}}
        className='button-fitAll-Map_Hotels'
        >
          Destination
        </button>

        <button 
        onClick={()=>{closeAllPopups()}}
        className='button-fitAll-Map_Hotels'
        >Close All Popup</button>

        {/* hotelLat:{hotelLat}
        hotelLng:{hotelLng} */}

      </div>



      <MapContainer  
        className="conatiner_p-map-Map_Hotels" 
        center = {[lat, lng]} 
        zoom={7} 
        ref={mapRef}
      > 

      <TileLayer 
        // url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${accessToken}`}
        // attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' 
      />

      {/* { underKm &&
        <Circle 
          center={[lat, lng]} 
          radius={parseInt(underKm)*1000} 
          //fillColor="brown" // Fill color
          color="brown" // Border color
          fillOpacity={0} // Fill opacity
          weight={2} // Border weight
          opacity={1} // Border opacity
        />  
      } */}


        {
            hotelLat && hotelLng && 
          (
            <Marker
              position={[(hotelLat), (hotelLng)]}
              zoom={zoom}
              icon={map_pin_1}
            >

              <Tooltip 
                direction="bottom" permanent
                className='container-tooltip-destination-Map_Hotels'
              >
                <div
                className='text-tooltip-destination-Map_Hotels'
                >
                  {placeName}
                </div>
              </Tooltip>

            </Marker>
          )
        }




        {/* //* active/clicked location  */}
        {hoverHotelData_map && hoverHotelData_map.length > 0 &&
          hoverHotelData_map.map((data, index) => {
            return(
              <Marker
                key={index}
                position={[data.lat, data.lng]}
                icon={hotel_active_1_colored}
                // icon={map_pin_1}
                className='special-marker'
              >
                <Tooltip
                  direction="bottom"
                  permanent
                  className='container-tooltip-active_hover-Map_Hotels'
                >

                  <div
                  className='text-1-tooltip-active_hover-Map_Hotels'
                  >
                    <img 
                    src="https://pub-683f2710f5c048c786c950689efa99ff.r2.dev/hotel_icon_map_1.png"
                    className='icon-1-tooltip-active_hover-Map_Hotels'
                    />

                    {data.name.length > 12 ? `${data.name.slice(0, 12)}...` : data.name}
                  </div>

                  <div
                  className='text-2-tooltip-active_hover-Map_Hotels'
                  >
                    ₹ {data.hotel_price}
                  </div>

                </Tooltip>
              </Marker>
            )
          })
        }




        {/* //*!! hotels - cards  */}
        { 
          hotelData.map((data, index) => {
            return(
              <Marker
              key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                // icon={hotel_1_colored}
                icon={hoverHotelData_map && hoverHotelData_map.length > 0 ? hotel_1_colored_small : hotel_1_colored}
              >
                <Tooltip 
                  direction="bottom" permanent
                  className='container-tooltip-hotels-Map_Hotels'
                >
                  { !hotelClickedStatus && 
                    data.has_price && 
                    bestDealData.map((priceData, index) => {
                      if(priceData.id === data.id){
                        return(
                          <div
                          className='container-hotels-Map_Hotels'
                          >
                            <div
                            className='text-1-hotels-Map_Hotels'
                            >
                              {data.name.length > 12 ? `${data.name.slice(0, 12)}...` : data.name}
                            </div>
                          
                            <div
                            className='text-2-hotels-Map_Hotels'
                            >
                              {priceData.hotel_price}
                            </div>
                        
                          </div>
                          )
                      }
                    })
                  }
                </Tooltip>

                <Popup
                style={{width:'120px'}}
                >

                  <div
                  className='text-1-hotels-popup-Map_Hotels'
                  >
                    {data.name} 
                  </div>

                  <div
                    className='container_t-1-ratings-popup-Map_Hotels'
                  >

                    {/* <div
                    className='text-1-hotels-popup-Map_Hotels'
                    >
                      {data.name} 
                    </div> */}

                    {
                      data.rating && 
                      <div
                      className='heading-2-Map_Hotels'
                      >
                        {data.rating}
                      </div>                  
                    }

                    <Rating
                      initialValue={data.rating}
                      size={16}
                      emptyColor="#ccc"
                      fillColor="gold"
                      strokeColor="gold"
                      strokeWidth={0}
                      allowFraction={true}
                      readonly
                    />  

                    { data.reviewCount &&
                      <div
                      className='heading-3-Map_Hotels'
                      >
                        ({data.reviewCount})   
                      </div>
                    }

                  </div>  

                  {/*//! */}
                  <div>

                    <div
                      className='line-1-hotels-popup-Map_Hotels'
                    ></div>

                    <div
                      className='container-button-hotels-popup-Map_Hotels'
                    >
                      <button 
                        className='button-1-hotels-popup-Map_Hotels'
                        onClick={(e)=>{
                          e.stopPropagation();
                          openPopup()
                          setViewMore_id(data.id)
                          setViewMoreStatus(true)
                          handleGetReviewdData(data.id)
                        }}
                      >
                        View Info & Reviews
                      </button>

                      {
                        data.has_price && 
                        bestDealData.map((priceData, index) => {
                          if(priceData.id === data.id){
                            return(
                            <button 
                              className='button-1-hotels-popup-Map_Hotels'
                              onClick={(e)=>{
                                e.stopPropagation();
                                openPopup()
                                setView_prices_popup(true)
                                setActiveViewPrices_Limited(data.id)
                              }}
                            >
                              View Prices
                            </button>
                            )
                          }
                        })
                      }

                    </div>

                  </div>


                  {/* <div
                    className='container_p-deal-hotels-popup-Map_Hotels'
                  >  

                    <div
                    className='heading-1-deal-hotels-popup-Map_Hotels'
                    >#Best Deal</div>
                    <div>
                      {priceData.hotel_name}
                    </div>

                    <div
                    className='heading-2-deal-hotels-popup-Map_Hotels'
                    >
                      {priceData.hotel_price}
                    </div>

                    { priceData.hotel_link &&
                      <div>
                        <a 
                          href={priceData.hotel_link}
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          {
                            priceData.official_site ?
                            <div>
                              Visit Official Site
                            </div>
                            :
                            <div>
                              Visit Site
                            </div>
                          }
                        </a>
                      </div>
                    }

                    {
                      priceData.free_breakfast && 
                      <div>
                        Free Breakfast
                      </div>  
                    }
                    {
                      priceData.free_wifi && 
                      <div>
                        Free Wi-Fi
                      </div>  
                    }
                    {
                      priceData.free_parking && 
                      <div>
                        Free Parking
                      </div>  
                    }
                    {
                      priceData.free_cancellation && 
                      <div>
                        Free Cancellation
                      </div>  
                    }
                    {
                      priceData.free_cancellation_detail && 
                      <div>
                        {priceData.free_cancellation_detail}
                      </div>  
                    }

                  </div> */}

                </Popup>

              </Marker>
            )
          })
        }


        
        
        {/* //* hover location 
        { hoverLocationData_map.length > 0 &&
          hoverLocationData_map.map((data, index) => {
            return(
              <Marker
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                // icon={`active_${activeTravelCategory}`}
                // eventHandlers={{
                //   click:()=>handleGetPopupDetails("active", data.id, data.type)
                // }}
              >
                <Tooltip 
                  direction="bottom" permanent
                  className='hoverToolTipOverall'
                >
                  {data.name}     
                </Tooltip>

              </Marker>
            )
          })
        } */}

      </MapContainer>

    </div>
  )
}

export default Map_Hotels





// {  hotelData && hotelData.length > 0 &&
//   hotelData.map((data, index) => {
//     return(
//       <Marker
//         position={[(data.lat), (data.lng)]}
//         zoom={zoom}
//         // icon={hotel_1_colored}
//         icon={hoverHotelData_map && hoverHotelData_map.length > 0 ? hotel_1_colored_small : hotel_1_colored}
//       >
//         <Tooltip 
//           direction="bottom" permanent
//           className='container-tooltip-hotels-Map_Hotels'
//         >
//           {
//             data.has_price && 
//             bestDealData.map((priceData, index) => {
//               if(priceData.id === data.id){
//                 return(
//                   <div
//                   className='container-hotels-Map_Hotels'
//                   >
//                     <div
//                     className='text-1-hotels-Map_Hotels'
//                     >
//                       {data.name.length > 12 ? `${data.name.slice(0, 12)}...` : data.name}
//                     </div>
                  
//                     <div
//                     className='text-2-hotels-Map_Hotels'
//                     >
//                       {priceData.hotel_price}
//                     </div>
                
//                   </div>
//                   )
//               }
//             })
//           }
//         </Tooltip>

//         <Popup>

//           <div>
//             {data.name} 
//           </div>

//           <div>
//             {data.reviewCount}   
//           </div>

//           {
//             data.has_price && 
//             bestDealData.map((priceData, index) => {
//               if(priceData.id === data.id){
//                 return(
//                   <div>

//                   <b><u>Best Deal</u></b>
//                   <div>
//                     <i>name</i>
//                     {priceData.hotel_name}
//                   </div>

//                   <div>
//                     <i>price</i>
//                     {priceData.hotel_price}
//                   </div>

//                   { priceData.hotel_link &&
//                     <div>
//                       <a 
//                         href={priceData.hotel_link}
//                         target="_blank" 
//                         rel="noopener noreferrer"
//                       >
//                         {
//                           priceData.official_site ?
//                           <div>
//                             Visit Official Site
//                           </div>
//                           :
//                           <div>
//                             Visit Site
//                           </div>
//                         }
//                       </a>
//                     </div>
//                   }

//                   {
//                     priceData.free_breakfast && 
//                     <div>
//                       Free Breakfast
//                     </div>  
//                   }
//                   {
//                     priceData.free_wifi && 
//                     <div>
//                       Free Wi-Fi
//                     </div>  
//                   }
//                   {
//                     priceData.free_parking && 
//                     <div>
//                       Free Parking
//                     </div>  
//                   }
//                   {
//                     priceData.free_cancellation && 
//                     <div>
//                       Free Cancellation
//                     </div>  
//                   }
//                   {
//                     priceData.free_cancellation_detail && 
//                     <div>
//                       {priceData.free_cancellation_detail}
//                     </div>  
//                   }

//                   <div>
//                     <button 
//                       onClick={(e)=>{
//                         e.stopPropagation();
//                         setViewMore_id(data.id)
//                         setViewMoreStatus(true)
//                         handleGetReviewdData(data.id)
//                         // setReview_showMoreTrack(0)
//                       }}
//                     >
//                       View More
//                     </button>

//                     <button 
//                       onClick={(e)=>{
//                         e.stopPropagation();
//                         openPopup()
//                         setView_prices_popup(true)
//                         setActiveViewPrices_Limited(data.id)
//                       }}
//                     >
//                       View Prices
//                     </button>

//                   </div>

//                   </div>
//                 )
//               }
//             })
//           } 

//         </Popup>

//       </Marker>
//     )
//   })
// }

