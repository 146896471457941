import React from 'react'

//import scss
import './Checklist_Itinerary_TO.scss'

function Checklist_Itinerary_TO(props) {

  const {itineraryData, visibility_type, different_design, topic_type, topic_id, checkListData} = props;

  return (
    <div
    className='container_p-Itinerary_TO_Components'
    >

      {  
        !different_design && itineraryData &&

        itineraryData.map((data, index) => {
          if(data.topic_id === topic_id){
            return(
              <div>

                {/*//! #1  */}
                {/* topic type and delete topic */}
                <div className='conatiner_p-head-Itinerary_TO_Components'>
      
                  {/* <div className='conatiner_t-displayName_Icon-head-Itinerary_TO_Components'> */}
                    <div
                    className='conatiner_s-1-head-Itinerary_TO_Components'
                    >
                      <div className='text-head-Itinerary_TO_Components'>
                        {data.display_name}
                      </div>

                      <div>
                        <img
                          src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/note_icon_6.png'
                          className='icon-checklist-Itinerary_TO_Components'
                        />
                      </div>
                    </div> 

                  {/* </div> */}
                </div>

                {/*//! #2  */}
                <div className='input-1-description-Itinerary_TO_Components'>
                  {data.description}
                  {/* {data.saved_place_id} */}
                </div>

                {/*//! Checklist */}
                <div
                className='container_p-Popup-Checklist_TD'
                >
                  {
                    checkListData && 
                    checkListData.map((CLData, TDIndex) => {
                      if(CLData.topic_id === topic_id){
                        return(
                          <div key={TDIndex}
                          className={CLData.description ? 'container_s-active-Popup-Checklist_TD' : 'container_s-unActivePopup-Checklist_TD'}
                          >
                            {/*//* Conatiner 1 */}
                            <div
                            className='container_t-1-Popup-Checklist_TD'
                            >
                              {/* <div> */}
                              {
                                CLData.check_status ?
                                (
                                <div>
                                  <div
                                    className='button-check-Popup-Checklist_TD'
                                  >
                                    ✔️
                                  </div>
                                </div> 
                                )
                                :
                                (
                                <div>
                                  <div
                                    disabled={!CLData.description}
                                    className={CLData.description ? 'button-active-unCheck-Popup-Checklist_TD' : 'button-unActive-unCheck-Popup-Checklist_TD'}
                                  >
                                    
                                  </div>
                                </div> 
                                )
                              }

                              <div
                              style={{width:'86%'}}
                              className='input-1-checklist-description-Popup-Checklist_TD'
                              > 
                                {CLData.description}
                              </div> 

                              {/* </div> */}

                            </div>   

                          </div>  
                        )
                      }
                    })
                  }
                </div>



              </div>    
            )
          }  
        })
      }    


    </div>
  )
}

export default Checklist_Itinerary_TO