import React, {useEffect, useState} from 'react'

//import css
import './Search_Tool_Hotel_PT.scss'
import './Search_Tool_2_Hotel_PT.scss'

//import search tool
// import {Search_Locations, Search_AllDestinations, Search_Hotels} from '../../../../Universal Components/Search/Manage Search/Export/Export_Search'

//
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

// import {useHotelsContext} from '../../Manage/Context/HotelsContext'
import { IoMdCloseCircle } from "react-icons/io";
import { MdOutlinePool } from "react-icons/md";
import { TbParking } from "react-icons/tb";
import { MdOutlineRoomService } from "react-icons/md";
import { IoRestaurantOutline } from "react-icons/io5";
import { IoOptionsSharp } from "react-icons/io5";
import { MdOutlineFreeCancellation } from "react-icons/md";
import { IoRibbonOutline } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaRegStarHalf } from "react-icons/fa";
import { GiStarFormation } from "react-icons/gi";
import { TbSortAscendingNumbers } from "react-icons/tb";
import { TbSortDescendingNumbers } from "react-icons/tb";
import { GrRevert } from "react-icons/gr";
import { MdManageSearch } from "react-icons/md";
import { TiDeleteOutline } from "react-icons/ti";

//importing components 
//?importing home internal components
// import {OtherAmenities} from '../../Manage/Export/Export_Hotels'

// import react context
import { TravelCategories_2_Context } from '../../../../Manage/Context/TravelCategories_2_Context'
import Map from '../../../Map/Map';


//import icons

function Search_Tool_Hotel_PT(props) {

    const{lat, lng, tripDestinationType} = props;

    //use react context 
    const {
        // values
        hotelCategory, sortHotelPrice,
        handleHotelCategoryClick,  handleSortHotelPriceClick,
        //
        hotelInternalNavigation, setHotelInternalNavigation
    } = TravelCategories_2_Context()

    const[sortDataWindow, setSortDataWindow] = useState(false)


    // !

    const openPopup = () => {
        document.body.style.overflow = 'hidden'; // Disable scrolling on the body
    };

    const closePopup = () => {
    document.body.style.overflow = 'auto'; // Enable scrolling on the body
    };

    //
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 849);

    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth <= 849); // Update state on window resize
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on unmount
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    const[mobile_viewMapStatus_Hotel, setMobile_viewMapStatus_Hotel] = useState(false)


  return (
    <div>

        {
            isMobile &&
            <div
            className='container_p-view_hotels-Hotels_Mobile'
            >
                <button
                // className='continer_p-0-Hotels'
                className='button-view_hotels-Hotels_Mobile'
                onClick={()=>{
                    setMobile_viewMapStatus_Hotel(true)
                }}
                >
                  <img 
                  src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/map_view_hotels-1.png'
                  className='img-view_hotels-Mobile-TouristPlaces'
                  />
                </button>
                <button
                    className='button-2-view_hotels-Hotels_Mobile'
                    onClick={()=>{
                        toast.success("Use a laptop or a pc to get a premium view.", {
                            position: toast.POSITION.TOP_CENTER
                          })
                    }}
                >
                    View Map & Places
                </button>
            </div>            
        }

        {(isMobile && mobile_viewMapStatus_Hotel && lat && lng) &&
          <div className='continer_p-Map-Mobile-TouristPlaces'> {/*div-2*/}

            <div 
            onClick={()=>{
                setMobile_viewMapStatus_Hotel(false)
                closePopup()
            }}
            className='popup-hotel-tp-background-1-Entities_TD'
            >
            </div>

            <button
              className='button-close-Hotel-Mobile-TouristPlaces'
              onClick={()=>{
                setMobile_viewMapStatus_Hotel(false)
                closePopup()
              }}
            >
              <IoMdCloseCircle />
            </button>

            <div className='continer_s-Map-Mobile-TouristPlaces'
            style={{zIndex:'1000'}}
            >
              <Map />
            </div>

          </div> 
        }

   
        <div
        // className='container-navigation_hotels-Search_Tool_Hotel_PT'
        className={ hotelInternalNavigation === "saved" ? 'container-saved-navigation_hotels-Search_Tool_Hotel_PT' : 'container-navigation_hotels-Search_Tool_Hotel_PT'}
        >
            <button 
                className='button-navigation_hotels-Search_Tool_Hotel_PT'
                onClick={()=>{setHotelInternalNavigation("saved")}}
            >
               View Saved Hotels
            </button>
            { hotelInternalNavigation === "saved" &&

                <button
                    className='button-2-navigation_hotels-Search_Tool_Hotel_PT'
                    onClick={()=>{setHotelInternalNavigation(false)}}
                    >
                    <TiDeleteOutline />
                </button>
            }
        </div>


      {/*//!Navigation 1 */}
        { tripDestinationType !== "state" &&
            hotelInternalNavigation !== "saved" &&
            <div>
                <div
                className='container_p-navigation_1-Search_Tool'
                >
                    <button
                    onClick={()=>{handleHotelCategoryClick("all")}}
                    className={hotelCategory === "all" ? 'conatiner_t-active-navigation_1-Search_Tool' : 'conatiner_t-unActive-navigation_1-Search_Tool'}
                    >
                    <div
                    className={hotelCategory === "all" ? 'heading-1-active-navigation_1-Search_Tool' : 'heading-1-unActive-navigation_1-Search_Tool'}
                    >
                        All <i></i>
                    </div>

                    <img 
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/type_a_hotel_icon_1.png'
                    className='img-1-navigation_1-Search_Tool'
                    />
                    </button>

                    <button
                    onClick={()=>{handleHotelCategoryClick("a")}}
                    className={hotelCategory === "a" ? 'conatiner_t-active-navigation_1-Search_Tool' : 'conatiner_t-unActive-navigation_1-Search_Tool'}
                    >
                    <div
                    className={hotelCategory === "a" ? 'heading-1-active-navigation_1-Search_Tool' : 'heading-1-unActive-navigation_1-Search_Tool'}
                    >
                        Travel Focused <i></i>
                        {/* Travel Focused <i>(less than 3000)</i> */}
                    </div>
                    
                    <img 
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/type_a_hotel_icon_2.png'
                    className='img-1-navigation_1-Search_Tool'
                    />
                    </button>

                    <button
                    onClick={() => {handleHotelCategoryClick("b") }}
                    className={hotelCategory === "b" ? 'conatiner_t-active-navigation_1-Search_Tool' : 'conatiner_t-unActive-navigation_1-Search_Tool'}
                    >
                    <div
                        className={hotelCategory === "b" ? 'heading-1-active-navigation_1-Search_Tool' : 'heading-1-unActive-navigation_1-Search_Tool'}
                    >
                        {/* Budget and Comfort <i>(between 3000 - 7000)</i> */}
                        Budget and Comfort <i></i>
                    </div>
                    
                    <img 
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/type_b_hotel_icon_1.png'
                    className='img-1-navigation_1-Search_Tool'
                    />

                    {/* {hotelCategory === "b" &&
                        <div>
                        X
                        </div>
                    } */}
                    </button>

                    <button
                    onClick={() => {handleHotelCategoryClick("c") }}
                    className={hotelCategory === "c" ? 'conatiner_t-active-navigation_1-Search_Tool' : 'conatiner_t-unActive-navigation_1-Search_Tool'}
                    >
                    <div
                        className={hotelCategory === "c" ? 'heading-1-active-navigation_1-Search_Tool' : 'heading-1-unActive-navigation_1-Search_Tool'}
                    >
                        {/* Premium Stay <i>(7000+)</i> */}
                        Premium Stay <i></i>
                    </div>
                    
                    <img 
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/premeium_hotel_icon_2.png'
                    className='img-1-navigation_1-Search_Tool'
                    />

                    {/* {hotelCategory === "c" &&
                        <div>
                        X
                        </div>
                    } */}
                    </button>

                </div>
            </div>
        }
        
        <br></br>
        
        {
            hotelInternalNavigation !== "saved" &&
            <div
            className='container-sortPrice-Search_Tool_2'
            >

                <button
                className='heading-1-navigation_3-Search_Tool_2'
                onMouseOver={()=>{setSortDataWindow(true)}}
                onMouseOut={()=>{setSortDataWindow(false)}}
                >
                    Filter Price
                </button>

            </div>
        }


        {/*//!Navigation 3 */}
        {/* <div
        className='container_p-navigation_3-Search_Tool_2'
        onMouseOver={()=>{setSortDataWindow(true)}}
        onMouseOut={()=>{setSortDataWindow(false)}}
        > */}

        { 
            sortDataWindow && 
            
            //{/*//! Container #2 */}
            <div
            className='conatiner_p-2-0-navigation_3-Search_Tool_2_Hotel_PT'
            onMouseOver={()=>{setSortDataWindow(true)}}
            onMouseOut={()=>{setSortDataWindow(false)}}
            >

                <div 
                className='conatiner_p-2-navigation_3-Search_Tool_2'
                > 



                {/*//!3  */}
                <div 
                className='conatiner_t-2-1-navigation_3-Search_Tool_2'
                >

                    <div
                    className='heading-1-active-amenity-Search_Tool'
                    >
                    Price
                    </div>

                    <button
                    onClick={() => {handleSortHotelPriceClick("price_h_to_l") }}
                    className={sortHotelPrice === "price_h_to_l" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
                    >
                    <div
                    className={sortHotelPrice === "price_h_to_l" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                    >
                        High to Low
                    </div>
                    <div
                    className='icon-1-amenity-Search_Tool'
                    >
                        <TbSortDescendingNumbers />
                    </div>
                    {
                        sortHotelPrice === "price_h_to_l" &&
                        <div
                        className='icon-2-amenity-Search_Tool'
                        >
                        <IoMdCloseCircle />
                        </div>
                    }
                    </button>

                    <button
                    onClick={() => {handleSortHotelPriceClick("price_l_to_h") }}
                    className={sortHotelPrice === "price_l_to_h" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
                    >
                    <div
                    className={sortHotelPrice === "price_l_to_h" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                    >
                        Low to High
                    </div>
                    <div
                    className='icon-1-amenity-Search_Tool'
                    >
                        <TbSortAscendingNumbers />
                    </div>
                    {
                        sortHotelPrice === "price_l_to_h" &&
                        <div
                        className='icon-2-amenity-Search_Tool'
                        >
                        <IoMdCloseCircle />
                        </div>
                    }
                    </button>

                    <button
                    onClick={() => {handleSortHotelPriceClick("relevant") }}
                    className={sortHotelPrice === "relevant" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
                    >
                    <div
                    className={sortHotelPrice === "relevant" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                    >
                        Relevant Hotels
                    </div>
                    <img 
                    className='img-1-amenity-Search_Tool'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/relevant_hotel_icon_1.png'
                    />
                    {
                        sortHotelPrice === "relevant" &&
                        <div
                        className='icon-2-amenity-Search_Tool'
                        >
                        <IoMdCloseCircle />
                        </div>
                    }
                    </button>

                    <button
                    onClick={() => {handleSortHotelPriceClick("most_reviewed") }}
                    className={sortHotelPrice === "most_reviewed" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
                    >
                    <div
                    className={sortHotelPrice === "most_reviewed" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                    >
                        Most Reviewed
                    </div>
                    <img 
                    className='img-1-amenity-Search_Tool'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/most_reviewed_icon_2.png'
                    />
                    {
                        sortHotelPrice === "most_reviewed" &&
                        <div
                        className='icon-2-amenity-Search_Tool'
                        >
                        <IoMdCloseCircle />
                        </div>
                    }
                    </button>   

                    
                    <button
                    onClick={() => {handleSortHotelPriceClick("nearest_first") }}
                    className={sortHotelPrice === "nearest_first" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
                    >
                    <div
                    className={sortHotelPrice === "nearest_first" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                    >
                        Nearest First
                    </div>
                    <img 
                    className='img-1-amenity-Search_Tool'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/nearest_icon_1.png'
                    />
                    {
                        sortHotelPrice === "nearest_first" &&
                        <div
                        className='icon-2-amenity-Search_Tool'
                        >
                        <IoMdCloseCircle />
                        </div>
                    }
                    </button>   

                </div>  

                </div> 
            </div>  
        }
      
    {/* </div> */}

        <ToastContainer />

    </div>
  )
}

export default Search_Tool_Hotel_PT

