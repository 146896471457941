import React, {useEffect, useState} from 'react'

//import scss
import './Hotels.scss'
import './Hotels_Mobile.scss'

//
import {useLocation, useParams} from 'react-router-dom';

import { Helmet } from 'react-helmet'

//manage google analytics tracking
import { initGA, trackPageView } from '../../../Track Action/Google Tracking/GoogleTrackingAnalytics';
import { userAnalytics_customTrack_VisitsExits } from '../../../Track Action/Custom Tracking/CustomTrackingAnalytics';
import {v4 as uuidv4} from 'uuid';

//icons
import { IoMdCloseCircle } from "react-icons/io";


//importing components 
//?importing home internal components
import {Map_Hotels, Content_Hotels, Search_Tool, Hotels_Heading, Hotel_ViewMore} from '../Manage/Export/Export_Hotels'

//importing from react context
import {useHotelsContext} from '../../Hotels/Manage/Context/HotelsContext'
import ViewPrices_CH from '../Components/Content Hotels/View Prices/ViewPrices_CH';


function Hotels() {
  
//import from useContext 
  const {
    componentInitialLoad, 
    //map
    lat, lng, zoom, 
    //
    viewMoreStatus,
    setViewMoreStatus,
    //map
    setFitAllLocations,
    //data
    hotelData,
    // Prices
    view_prices_popup, setView_prices_popup, setActiveViewPrices_Limited,
    //
    activeHotel_PriceData_Arr, setActiveHotel_PriceData_arr,
    //loading
    loadingStatusHotels, setLoadingStatusHotels, 
  } = useHotelsContext();

  
  // useEffect(() => {


  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
    setView_prices_popup(false);
    setActiveViewPrices_Limited()
    document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  
  const location = useLocation();
  //!Track user visit 
  //
  useEffect(() => {
    initGA();
  }, []);
  //
  useEffect(() => {
    trackPageView(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    // Call this function once when the page is loaded
    const date = Date.now();
    const start_time = Math.floor(date / 1000)

    const random_number = Math.floor(Math.random() * 1000000); 
    const concatenatedString = `${date}-${random_number}`;
    const user_temp_id = uuidv4(concatenatedString);  


    userAnalytics_customTrack_VisitsExits(user_temp_id, null, location.pathname, "enter", null);

    // Function to handle page exit
    const handleBeforeUnload = () => {

      const end_time = Math.floor(Date.now() / 1000);  // Convert milliseconds to seconds

      const stay_duration = end_time-start_time;

      userAnalytics_customTrack_VisitsExits(user_temp_id, null, location.pathname, "exit", stay_duration);
    };

    // Add event listener to call page exit event when the user tries to close or navigate away
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures this effect runs only once per page load

  //!End*----



  //*get User Search Hotel Details
  const {destination_type, destination_name} = useParams();

  //*AI Tools - 
  //1. quick ai itinerary;
  //*ai feature id - 
  //nearby: nb-01; everything famous: ef-01 
  //*navigationId - 
  //nb-05 - Nearby hotels & //ef-04 - Top Rated Hotels
  const {unique_id, navigationId, ai_feature_id} = useParams();

  //Mobile Navigation - map and content
  const[navigation_map_content, setNavigation_map_content] = useState("content")
  

  useEffect(() => {

    componentInitialLoad(
      destination_type, destination_name, 
      unique_id, navigationId, ai_feature_id
    );

  }, [])


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 799); // Initialize with the current width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 799); // Update state on window resize
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>

<Helmet>   

<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Assistant:wght@400&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap" rel="stylesheet" />

</Helmet>

      <div
      className='conatiner_p-component-content_map-Hotels'
      >

        { (!isMobile && (lat && lng)) &&

          <div
          className='continer_p-0-Hotels'
          ></div>
        }

        {/* { isMobile &&

          <div>
            <button
            // className='continer_p-0-Hotels'
            onClick={()=>{setNavigation_map_content("map")}}
            >
              View Map
            </button>

            <button
            // className='continer_p-0-Hotels'
            onClick={()=>{setNavigation_map_content("content")}}
            >
              View Hotels
            </button>
          </div>
        } */}

        {
          ((isMobile && navigation_map_content === "content") || !isMobile) && 
       
          <div
          // className='continer_p-1-Hotels'
          className={isMobile ?'continer_p-1-Hotels_Mobile' : 'continer_p-1-Hotels'}
          >

            { unique_id &&
              (
                <div>
                  <Hotels_Heading 
                  unique_id={unique_id} 
                  navigationId={navigationId} 
                  ai_feature_id={ai_feature_id} 
                  prompt={prompt}
                  />
                </div>
              )
            }

            {/*//!Dynamic - Web */}
            {
              (!unique_id && !isMobile) &&
              (
                <div
                className='conatiner_p-heading-Hotels'
                >  
                  
                  <div
                  className='conatiner_s-2-heading-Hotels'
                  >

                    <div
                    className='heading-1-heading-Hotels'
                    > 
                      Discover Hotels
                    </div>

                    <div
                    className='heading-2-heading-Hotels'
                    > 
                      Best For You
                    </div>
                  
                    <div
                    className='heading-3-heading-Hotels'
                    >
                      Hotel Hunt Simplified
                    </div>

                  </div>  

                  <div
                  className='conatiner_s-1-heading-Hotels'
                  >
                    <img 
                    src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/hotel_image_2_no_bg.png'
                    
                    className='img-1-heading-Hotels'
                    />
                  </div>   

                </div>  
              )
            }

            {/*//!Dynamic - App */}
            {
              (isMobile) &&
              (
                <div
                className='conatiner_p-heading-Hotels'
                >  
                  
                  {/* <div
                  className='conatiner_s-2-heading-Hotels'
                  >

                    <div
                    className='heading-1-heading-Hotels'
                    > 
                      Discover Hotels
                    </div>

                    <div
                    className='heading-2-heading-Hotels'
                    > 
                      Best For You
                    </div>
                  
                    <div
                    className='heading-3-heading-Hotels'
                    >
                      Hotel Hunt Simplified
                    </div>

                  </div>   */}

                  <div
                  className='conatiner_s-1-heading-Hotels'
                  >
                    <img 
                    src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/hotel_image_2_no_bg.png'
                    
                    className='img-1-heading-Hotels'
                    />

                     <div
                    className='heading-1-heading-Hotels'
                    > 
                      Discover Hotels
                    </div>

                    <div
                    className='heading-2-heading-Hotels'
                    > 
                      Best For You
                    </div>

                  </div>   

                </div>  
              )
            }


            <div
              className='conatiner_p-2-heading-Hotels'
            >
              <div
              className='text-1-note-heading-Hotels'
              >
                <i> <b>Note:</b> This is just a hotel discovery tool. We are not responsible for your booking done offline or online. We do are best to provide correct info; there could be some wrong info.</i>
              </div>   
            </div>
            
            <div>
              <Search_Tool />
            </div>


            { (isMobile && hotelData && hotelData.length > 0) &&

              <div 
              // className='container_p-navigation_map_content-Hotels_Mobile'
              >
                <button
                // className='continer_p-0-Hotels'
                className='button-view_hotels-Hotels_Mobile'
                onClick={()=>{
                  setNavigation_map_content("map")
                  setFitAllLocations(true)
                }}
                >
                  <img 
                  src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/map_view_hotels-1.png'
                  className='img-view_hotels-Hotels_Mobile'
                  />
                </button>

                {/* <button
                onClick={()=>{setNavigation_map_content("content")}}
                >
                  View Hotels
                </button> */}
              </div>
            }

            <div
            className='component-content-Hotels'
            style={{zIndex:'100000000'}}
            >
              <Content_Hotels />
            </div>

            <div style={{height:'100px'}}></div>
            
          </div>

        }


        { !isMobile && 
          (lat && lng) &&
        
          <div
          className='continer_p-2-Hotels'
          >

            <div
            className='component-map-Hotels'
            >
              <Map_Hotels lat={lat} lng={lng} zoom={zoom} />
            </div>

          </div> 
        }

        {/*//!Mobile */}
        { (isMobile && navigation_map_content === "map") && 
          (lat && lng) &&
        
          <div
          // className='continer_p-2-Hotels'
          className={isMobile ? 'continer_p-2-Hotels_Mobile' : 'continer_p-2-Hotels'}
          >

            {/* { isMobile &&
              <div className='container_p-navigation_map_content-Hotels_Mobile'>
                <button
                className='button-view_hotels-2-Hotels_Mobile'
                onClick={()=>{setNavigation_map_content("content")}}
                >
                  View Hotels
                </button>
              </div>
            } */}

            <button
              className='button-close-Hotels_Mobile'
              onClick={()=>{
                setNavigation_map_content("content")
                closePopup()
              }}
            >
              <IoMdCloseCircle />
            </button>

            <div
            className='component-map-Hotels_Mobile'
            >
              <Map_Hotels lat={lat} lng={lng} zoom={zoom} />
            </div>

          </div> 
        }

      </div> 


        {
          viewMoreStatus && 
            <div>
              <Hotel_ViewMore />
            </div>
        }

        {
          (view_prices_popup && activeHotel_PriceData_Arr)  && 
          <ViewPrices_CH 
            hotel_name={activeHotel_PriceData_Arr[0]?.hotel_name}
            ptExclusiveData={activeHotel_PriceData_Arr[0]?.ptExclusiveData}
            priceData={activeHotel_PriceData_Arr[0]?.priceData}
            data_id={activeHotel_PriceData_Arr[0]?.data_id}
            data_has_price={activeHotel_PriceData_Arr[0]?.data_has_price}
            openPopup={openPopup}
            closePopup={closePopup}
            setView_prices_popup={setView_prices_popup}
            bestDealData={activeHotel_PriceData_Arr[0]?.bestDealData}
            loadingStatusHotels={loadingStatusHotels}
            setLoadingStatusHotels={setLoadingStatusHotels}
          />
        }
 
        

    </div>
  )
}

export default Hotels










//?old heading:

// {
//   !unique_id && 
//   (
//     <div
//     className='conatiner_p-heading-Hotels'
//     >

//       <img 
//       src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/hotel_image_2_no_bg.png'
   
//       className='img-1-heading-Hotels'
//       />

//       <div
//       className='heading-1-heading-Hotels'
//       > 
//         Discover Hotels
//       </div>

//       <div
//       className='heading-2-heading-Hotels'
//       > 
//         Best For You
//       </div>
    
//       <div
//       className='heading-3-heading-Hotels'
//       >
//         Hotel Hunt Simplified
//       </div>

//     </div>  
//   )
// }

// //!Heading
// .conatiner_p-heading-Hotels{
//   width: 100%;
//   // height: 20%;
//   margin-top: 1%;
//   align-items: center;
//   text-align: center;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   position: relative;
//   overflow: hidden;
// }
// .heading-1-heading-Hotels{
//   font-family: $font-6;
//   font-size: 22px;
//   font-weight: bold;
//   color: rgb(225, 63, 4);
//   margin-top: 2%;
// }
// .heading-2-heading-Hotels{
//   font-family: $font-6;
//   font-size: 18px;
//   color: rgb(79, 153, 20);
//   margin-top: 2%;
// }
// .heading-3-heading-Hotels{
//   margin-top: 6px;
//   font-family: $font-5;
//   font-size: 16px;
//   color: rgb(255, 81, 0);
//   margin-top: 2%;
// }
// .img-1-heading-Hotels{
//   // width: 100%;
//   width: 300px;
//   height: 200px;
//   // height: 100%;
//   // object-fit: cover;
//   // border: 1px solid orangered;
//   border-radius: 8px;
//   // position: absolute;
//   z-index: -2;
// }


