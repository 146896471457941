import React, { useEffect, useState, useRef } from 'react';

//import scss
import './SearchTool.scss'

import axios, { Axios } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import {FiSearch} from "react-icons/fi"; 
import {FaMapPin} from "react-icons/fa"; 
import {HiOutlineMap} from "react-icons/hi"; 
import { Helmet } from 'react-helmet';
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";  

import { HiXCircle } from "react-icons/hi"; 
import { BsBookmark } from "react-icons/bs";
import { BsFillBookmarkFill } from "react-icons/bs";

import { Rating } from 'react-simple-star-rating';
// import Login from '../Plan/UserLogin/Login';

import { Button, Slider } from '@mui/material'

import {AiFillLinkedin} from 'react-icons/ai'
import {FaFacebook} from 'react-icons/fa'
import {BsTwitter} from 'react-icons/bs'
import {AiFillInstagram} from 'react-icons/ai'
import {BsYoutube} from 'react-icons/bs'
import {FcReddit} from 'react-icons/fc'

import Moment from 'moment' 
import { server_baseUrl } from '../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';


function SearchTool(props) {

  const {activePlaceSegment, setActivePlaceSegment} = props;

  const navigate = useNavigate();

  // useEffect(() => {

  // }, [])
  const containerRef = useRef(null);

  const[displaySearchResult, setDisplaySearchResult] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setDisplaySearchResult(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  const handlePlaceSegemnt = (segment) => {

    const response = axios.get("")
    setActivePlaceSegment(segment)

  }

  const [searchInput, setSearchInput] = useState()
  const [placesData, setPlacesData] = useState([])

  const handleUserInputSearch = async (event) => {

    setSearchInput(event.target.value)

    const userSearchTemp = event.target.value;

    const response = await axios.post(`${server_baseUrl}/csc/main_search_destination/places/user_search/destinations`, {userSearchTemp, activePlaceSegment})

    setPlacesData(response.data)
    // console.log("response.data", response.data)  
  }

  const handlePlaceSearchClick = (destination_type, placeName, placeDistrictName, placeStateName, placeId, placeLat, placeLng) => {

    if(destination_type === "destination"){
      navigate(`/plan-trip-to-${placeName}/${placeDistrictName}/${placeStateName}/?lat=${placeLat}&lng=${placeLng}`)
    }
    else if(destination_type === "district"){
      navigate(`/plan-complete-trip/${placeName}/${placeStateName}/?lat=${placeLat}&lng=${placeLng}`)
    }
    else if(destination_type === "state"){
      navigate(`/plan-complete-trip/state/${placeName}/?lat=${placeLat}&lng=${placeLng}`)
    }
    else{
      alert("opps, something seems wrong!!")
    }

  }
  

  return (
    <div
    className='container_p-SearchTool'
    >
      {/* {activePlaceSegment} */}
      <div
      className='container_s-SearchTool'
      >

        <img 
        src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/search-green-mountain-line.png'
        className='img-mountain-search-SearchTool'
        alt='mountain'
        />

    
        <div
        className='container_p-SearchText-SearchTool'
        >

          <h2
          className='heading-search-SearchTool'
          >
            Start Planning
          </h2>

          <img 
          src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/ai_stars_2.png'
          className='img-stars-search-SearchTool'
          alt='stars showing artificial intelligence (ai) powered search'
          />

        </div>   
{/* {activePlaceSegment} */}

        <div 
        className='container_p-OtherStates-SearchTool'
        >
          <div
          className='container_s-india-SearchTool'
          >
            <div 
            className='text-1-OtherStates-SearchTool'
            >
              India
            </div>
            <img 
            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/indian_flag-1.png'
            className='img-OtherStates-SearchTool' 
            />
          </div>
          <div
            className='text-2-OtherStates-SearchTool' 
          >
            adding more countries soon...
          </div>
        </div>

        <div
        className='container_p-searchInput-SearchTool'
        ref={containerRef}
        >

          <div>
            <input value={searchInput} 
            // placeholder=
            // {
            //   activePlaceSegment === "city" ?
            //   'Shimla, Dalhouise, Mahabaleshwar...' 
            //   :
            //   activePlaceSegment === "district" ?
            //   'Chamba, Jaipur, Munnar...' 
            //   :
            //   'Delhi, Tamil Nadu, Ladakh...' 
            // }
            placeholder="Shimla, Jaipur, Tamil Nadu..."
            
            onClick={()=>{setDisplaySearchResult(true)}}
            onChange={handleUserInputSearch} 
            className='input-searchInput-SearchTool'
            />
          </div>  

          <div>
            <img 
            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/search_icon_2.png'
            className='img-search-search-SearchTool'
            alt='search destination icon'
            />
          </div>


          
          { displaySearchResult && searchInput && placesData && placesData.length > 0 &&
            <div
            className='container_p-popup-searchDestinations-searchTool'
            >
            {
              placesData.map((placeData, index) => {
                return(
                  <button key={index}
                  className='container_s-popup-searchDestinations-searchTool'
                  onClick={()=>handlePlaceSearchClick(placeData.destination_type, placeData.place_name, placeData.district_name, placeData.state_name, placeData.place_id, placeData.place_lat, placeData.place_lng)}
                  >
                    <div
                    className='text-1-popup-searchDestinations-searchTool'
                    >
                      {placeData.place_name} 
                    </div>

                    <div
                    className='text-2-popup-searchDestinations-searchTool'
                    >
                      {
                        placeData.destination_type === "destination" ?
                        <div>
                          &nbsp; a city/town
                        </div>
                        :
                        <div>
                          &nbsp; a {placeData.destination_type}
                        </div>
                      }
                      {/* , {placeData.district_name}  */}
                    </div>

                    {/* {
                      placeData.district_name && placeData.place_name !== placeData.district_name &&
                      <div
                      className='text-2-popup-searchDestinations-searchTool'
                      >
                        , {placeData.district_name} 
                      </div>
                    } */}

                    {
                      placeData.destination_type === "destination" && placeData.state_name !== placeData.district_name ?
                      <div
                      className='text-3-popup-searchDestinations-searchTool'
                      >
                        &nbsp; in {placeData.district_name}, {placeData.state_name} 
                      </div>
                      :
                      <div
                      className='text-3-popup-searchDestinations-searchTool'
                      >
                        &nbsp; in {placeData.state_name} 
                      </div>
                    }

                    {/* {
                      placeData.state_name && placeData.place_name !== placeData.state_name &&
                      <div
                      className='text-3-popup-searchDestinations-searchTool'
                      >
                        &nbsp; in {placeData.state_name}
                      </div>
                    } */}

                  </button>
                )
              })
            }
            </div>
          }
      
        </div>  

        
        {/* <div
        className='conatiner_p-searchDestination-SearchTool'
        >
          <button onClick={()=>{
            handlePlaceSegemnt("city")
            setSearchInput('')
          }}
          className={activePlaceSegment === 'city' ? 'button-active-searchDestination-SearchTool' : 'button-unactive-searchDestination-SearchTool'}
          >
            Search City/Town  
          </button>
          <button onClick={()=>{
            handlePlaceSegemnt("district")
            setSearchInput('')
          }}
          className={activePlaceSegment === 'district' ? 'button-active-searchDestination-SearchTool' : 'button-unactive-searchDestination-SearchTool'}
          >
            Search District
          </button>
          <button onClick={()=>{
            handlePlaceSegemnt("state")
            setSearchInput('')
          }}
          className={activePlaceSegment === 'state' ? 'button-active-searchDestination-SearchTool' : 'button-unactive-searchDestination-SearchTool'}
          >
            Search State
          </button>
        </div> */}

        <div
        className='conatiner_p-meta_details-searchTools'
        >
          <div
          className='button-meta_details-searchTools'
          >
            # Create Detailed Itinerary
          </div>
          <div
          className='button-meta_details-searchTools'
          >
            # How To Reach
          </div>
          <div
          className='button-meta_details-searchTools'
          >
            # About Destination
          </div>
          <div
          className='button-meta_details-searchTools'
          >
            # Best Time To Visit
          </div>
          <div
          className='button-meta_details-searchTools'
          >
            # Guide
          </div>
          <div
          className='button-meta_details-searchTools'
          >
            # Share Trip
          </div>
        </div>

    

      </div>  

    </div>
  )
}

export default SearchTool