// MyContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'

import axios from 'axios';
import Moment from 'moment';

//
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



//importing react context
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip';
import { server_baseUrl } from '../../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';


// Step 1: Create a new context
const Context = createContext();


// Step 2: Create a context provider
export const Context_createItinerary_PlanTrip = ({ children }) => {

  const navigate = useNavigate();

  const{stateName} = useParams()

  // using react context
  const { 
    userEmail, tripId, tripName, 
    //trip details
    tripDestinationName, tripDestinationType, districtName, cityName, destinationLat, destinationLng, destinationZoom 
  } = useValidatePlanTrip();



  //!states
  //*manage 
  const[useEffectDataLoaded2, setUseEffectDataLoaded2] = useState(false)
  const[loadDataStatus_Itinerary, setLoadDataStatus_Itinerary] = useState(false)
  const[loadDataStatus_SavedPlaces, setLoadDataStatus_SavedPlaces] = useState(false)
  const[hideNote_Temp, setHideNote_Temp] = useState(true)
  const[noteLimitExceeded_Error, setNoteLimitExceeded_Error] = useState(false)

  //*Status
  const[saveChangesStatus, setSaveChangesStatus] = useState(false);

  //*Map
  const[lat_CI, setLat_CI] = useState(destinationLat)
  const[lng_CI, setLng_CI] = useState(destinationLng)
  const[zoom_CI, setZoom_CI] = useState(destinationZoom)
  const[activePlace_MapData, setActivePlace_MapData] = useState([])
  const[fitAllLocations, setFitAllLocations] = useState(false)

  //*navigation
  //-main
  const[activeViewSavedType, setActiveViewSavedType] = useState("map") 
  //-saved places
  const[savedPlacesNavigation, setSavedPlacesNavigation] = useState("location")
  //handle active notes window
  const [activeWindow_Notes, setActiveWindow_Notes] = useState({
    "topic_id":0,
    "day_id":0,
  })
  const[textLength, setTextLength] = useState(0)



  //*Itinerary
  //--store data
  const[itineraryData, setItineraryData] = useState([{"id":-1}])
  const[activeAddTopicDetailType, setActiveAddTopicDetailType] = useState()

  //*Day 
  const[dayData, setDayData] = useState([
    {"unique_id": 1,
    "day_id":1}
  ])
  const[dayIdCount, setDayIdCount] = useState(1)
  const[showDates_ActiveId, setShowDates_ActiveId] = useState()

  //*Saved Places
  //--store data
  const[savedPlacesData, setSavedPlacesData] = useState([])
  const[placesInfo, setPlacesInfo] = useState([])

  //!delete me
  const test = () => {
    handleSaveTripItinerary()
    // console.log('\n', "itineraryData", itineraryData, '\n')
    // console.log('\n', "checkListData", checkListData, '\n')
    // console.log('\n', "tripDocumentsData", tripDocumentsData, '\n')
    // console.log('\n', "dayData", dayData, '\n')
  }

  // //!Initial load
  // done in the CreateItinerary component
  // useEffect(() => {
  //   if(!loadDataStatus_Itinerary && userEmail && tripId){

  //       loadSavedTripItinerary()
  //       // console.log("i was called!!")
  //   }
  //   else{
  //     // console.log("i was not called!!")
  //   }
  // }, [userEmail, tripId])

  useEffect(() => {
    //
    if(destinationLat && destinationLng && destinationZoom){
      loadSavedPlaces()
      
      setLat_CI(destinationLat)
      setLng_CI(destinationLng)
      setZoom_CI(destinationZoom)
    }
  }, destinationLat, destinationLng, destinationZoom)

  
  //!Navigate 
  //saved place type
  const handleActiveSavedType = (passedType) => {

    setActiveViewSavedType(passedType)

    //data for the itinerary has already been loaded. 
    if(passedType === "saved_places" && !loadDataStatus_SavedPlaces){
      loadSavedPlaces()
    } 

  }

  const handleActiveWondow_Notes = (topic_idPassed, day_idPassed, idPassed) => {
    setActiveWindow_Notes({
      "topic_id":topic_idPassed,
      "day_id":day_idPassed,
      "id":idPassed
    })
  }

  const handleHideNoteTemp = () => {
    // Set hideNote_Temp to false
    setHideNote_Temp(true);
  
    // After 1 second, set hideNote_Temp back to true
    setTimeout(() => {
      // setHideNote_Temp(false);
    }, 1000); // 1000 milliseconds = 1 second
  };

  const handleTextLength = (passedLength) => {
    // console.log("handleTextLength", passedLength)
    setTextLength(passedLength)
    // setNoteLimitExceeded_Error(true)
  }    

  //!Trip Documents
  const[temp_td_heading, set_temp_td_heading] = useState()
  const[temp_td_link, set_temp_td_link] = useState()
  const[temp_td_description, set_temp_td_description] = useState()
  const[temp_td_newOld, set_temp_td_newOld] = useState() 
  const[temp_td_id, set_temp_td_id] = useState()
  //!Places

  //*load data
  //::API Location - Trip Planning > LoadUserSavedActivity  
  const loadSavedPlaces = async () => {
    // console.log("stateNamstateName", stateName)
    
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/load_user_saved_activity/saved_tourist_locations`, {userEmail, tripId, stateName})
    
    // console.log("loadSavedPlaces", response.data)

    if(response.data && response.data !== "0" && response.data.saved_places.length > 0){
      setSavedPlacesData(response.data.saved_places)
      setPlacesInfo(response.data.places_data)
      //
      setLoadDataStatus_SavedPlaces(true)

      // setFitAllLocations(true)
    }
    else{
      // setFitAllLocations(true)
    }
  }
  

  //!Map
  const handleMap_HoverClick = (topicIdPassed, latPassed, lngPassed, displayNamePassed, indexPassed, namePassed) => {

    if(fitAllLocations){
      setFitAllLocations(false)
    }
    else{
      setFitAllLocations(true)
    }

    if(latPassed && lngPassed && namePassed){
      setHoverItineraryData([{
        id: topicIdPassed, lat:latPassed, lng:lngPassed, displayName:displayNamePassed, index:indexPassed, name:namePassed
      }])
    }

    setPlaceClicked_map(false) 
  }


  //!Itinerary

  //*load data
  const loadSavedTripItinerary = async () => {
    
    const response = await axios.post(`${server_baseUrl}/csc/handle_itinerary/1/load/saved/itinerary`, {userEmail, tripId})
    
    // console.log("loadSavedTripItinerary", response.data)

    if(response.data !== "0" && response.data.length > 0){
      // Parsing JSON strings back to JavaScript arrays or objects
      const dayData = JSON.parse(response.data[0].day_data);
      const itineraryData = JSON.parse(response.data[0].itinerary_data);
      const checkListData = JSON.parse(response.data[0].check_list_data);
      const tripDocumentsData = JSON.parse(response.data[0].documents_data);
      const tripAiItineraryData = JSON.parse(response.data[0].ai_itinerary_chat);
      const topicIdCount = response.data[0].topic_id_count;

      // Setting the parsed data to the respective states
      setDayData(dayData);
      setItineraryData(itineraryData);
      setCheckListData(checkListData);
      setTripDocumentsData(tripDocumentsData);
      setTopicIdCount(topicIdCount);
      // setAiItineraryData(tripAiItineraryData)

      //
      setLoadDataStatus_Itinerary(true)

      setFitAllLocations(true)
    }
    else{
      // console.log("no saved itinerary found")
      setLoadDataStatus_Itinerary(true)
      setFitAllLocations(true)
    }
  }

  //*insert data
  const handleSaveTripItinerary = async () => {
    
    const response = await axios.post(`${server_baseUrl}/csc/handle_itinerary/1/insert/itinerary_data`, {
      userEmail, tripId,
      dayData,
      itineraryData,
      checkListData, 
      tripDocumentsData,
      topicIdCount
    })
    
    // console.log("handleSaveTripItinerary", response.data)

    if(response.data !== "0"){
      // console.log("saved")
      setSaveChangesStatus(false)

      toast.success("Successfully saved", {
        position: "top-right", // Positions it in the top right
        style: {
          top: '50px', // Adjust this value to increase or decrease the margin from the top
          right: '0px',
        }
      }); 

    }
    else{
      // console.log("some issue saving the data.")
      toast.error("Data not saved. There is some issue. You might try again.", {
        position: "top-right", // Positions it in the top right
        style: {
          top: '50px', // Adjust this value to increase or decrease the margin from the top
          right: '0px',
        }
      }); 
    }
  }

  //!#0
  //*Day Selector

  //?add day
  const handleAddDay = () => {

    const uniqueId = dayIdCount+1;
    const dayId = dayData.length+1;
    
    setDayData([...dayData, {
      "unique_id": uniqueId,
      "day_id":dayId
    }])

    setActiveDayId(dayId)

    setDayIdCount(uniqueId)
  }

  //?delete day
  const handleDeleteDay = (unique_idPassed, day_idPassed) => {
    // Filter out the row with the unique_id matching unique_idPassed

    // console.log("handleDeleteDay", unique_idPassed, day_idPassed);

    const filteredRows = dayData.filter(row => row.unique_id !== unique_idPassed);
  
    // Update day_id for rows where day_id is greater than day_idPassed
    const updatedRows = filteredRows.map(row => {
      if (row.day_id > day_idPassed) {
        return { ...row, day_id: row.day_id};
      }
      return row;
    });
  
    // Update the state with the modified data
    setDayData(updatedRows);

    //
    const updatedRows_itinerary = filteredRows.filter(row => row.day_id !== day_idPassed);

    setItineraryData(updatedRows_itinerary)
  };

  //?edit day
  const handleEdit_AddDate_Day = (event, unique_idPassed) => {

    // console.log("handleEdit_AddDateDay was clicked", unique_idPassed, event.target.value)

    const updatedData = {"date":event.target.value}

    const updatedRows = dayData.map(row =>
      (row.day_id === unique_idPassed)
      ? { ...row, ...updatedData } : row
    );
    
    setDayData(updatedRows)
    // setShowDates_ActiveId()
  }

  //?show dates for the active day
  const handleShowDates = (unique_idPassed) => {
    
    // console.log("handleShowDates was clicked", unique_idPassed)

    setShowDates_ActiveId(unique_idPassed)
  }
  
  //!#1
  //?Itinerary Data

  //*add column - itinerary data
  const itinerary_AddColumn = (day_id_Passed, topic_category_Passed, topic_type_Passed, topic_icon_name_Passed, drag_id_Passed, visibility_type_Passed, add_limit_Passed, different_design_Passed, display_namePassed) => {

    const newRow = {
      "day_id": day_id_Passed,
      "topic_id": topicIdCount+1,
      "topic_category": topic_category_Passed,
      "topic_type": topic_type_Passed,
      "topic_icon_name": topic_icon_name_Passed,
      "drag_id": drag_id_Passed,
      "visibility_type": visibility_type_Passed,
      "add_limit": add_limit_Passed,
      "different_design": different_design_Passed, 
      "budget_currency": "inr", 
      "display_name": display_namePassed
    };
    setTopicIdCount(topicIdCount+1)
    setItineraryData([...itineraryData, newRow]);

    setSaveChangesStatus(true)
  }

  //*edit column 
  const itinerary_EditColumn = (passed_NoteId, passed_DayId, updatedData) => {

    // console.log("passed_DayId passed_NoteId", passed_DayId, passed_NoteId)

    const updatedRows = itineraryData.map(row =>
      (row.day_id === passed_DayId && row.topic_id === passed_NoteId)  
      ? { ...row, ...updatedData } : row
    );
    // console.log("updatedRows", updatedRows)
    setItineraryData(updatedRows)
  }

  //*delete column 
  const itinerary_DeleteColumn = (passed_NoteId, passed_DayId) => {

    // console.log("itinerary_DeleteColumn - passed_NoteId, passed_DayId", passed_NoteId, passed_DayId)

    const filteredRows = itineraryData.filter(row => 
      !(row.day_id === passed_DayId && row.topic_id === passed_NoteId)
    );

    setItineraryData(filteredRows)
  }

  //!#2
  //?TripDocuments Data

  //*add column - tripDocuments
  const handleDocument_addDocument = (topic_id_Passed, day_id_Passed, id_documentsPassed) => {
    
    if(temp_td_heading || temp_td_link || temp_td_description){

      let newRow; 

      let id = id_documentsPassed+1;
      if(!id){
        id = 1;
      }

      if(temp_td_newOld === "new"){
        newRow = {
          "topic_id": topic_id_Passed,
          "day_id": day_id_Passed,
          "id_documents":id, 
          "heading":temp_td_heading, 
          "description":temp_td_description,
          "link":temp_td_link
        };
      }
      else{
        newRow = { //temp_td_newOld === "old"
          "topic_id": topic_id_Passed,
          "day_id": day_id_Passed,
          "id_documents":temp_td_id, 
          "heading":temp_td_heading, 
          "description":temp_td_description,
          "link":temp_td_link
        };
      }

      //here we create a reference in the main itineradyData to the trip documents data, and the all the documents are been stored in a seperate itinerary data.
      if(temp_td_newOld === "new"){
        setTripDocumentsData([...tripDocumentsData, newRow]);

        //update the trackid for the checklist in the itineraryData
        const updatedData = {"track_documents":id}
        
        itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
      }
      else{ //temp_td_newOld === "old"
        // console.log("old trip document")

        // console.log("day_id_Passed", day_id_Passed, "topic_id_Passed", topic_id_Passed, "id_documentsPassed", id_documentsPassed)

        const updatedRows = tripDocumentsData.map(row =>
          (row.day_id === day_id_Passed && row.topic_id === topic_id_Passed && row.id_documents === temp_td_id)  
          ? { ...row, ...newRow } : row
        );
        // console.log("updatedRows", updatedRows, tripDocumentsData)
        setTripDocumentsData(updatedRows)

      }

    }
    else{
      // console.log("not saved!!")
    }

  }

  //*delete column 
  const handleDocument_DeleteColumn = (documents_IdPassed, passed_DayId, passed_NoteId) => {

    // console.log("documents_IdPassed, passed_DayId, passed_NoteId", documents_IdPassed, passed_DayId, passed_NoteId)

    const filteredRows = tripDocumentsData.filter(row => 
      !(row.day_id == passed_DayId && row.topic_id === passed_NoteId && row.id_documents === documents_IdPassed)
    );
    
    setTripDocumentsData(filteredRows)
  }

  //*add column - tripDocuments
  const handleDocument_addDocument_tempHalT = (topic_id_Passed, day_id_Passed, id_documentsPassed) => {
    
    let id = id_documentsPassed+1;
    if(!id){
      id = 1;
    }
    const newRow = {
      "topic_id": topic_id_Passed,
      "day_id": day_id_Passed,
      "id_documents":id
    };
    
    setTripDocumentsData([...tripDocumentsData, newRow]);

    //update the trackid for the checklist in the itineraryData
    const updatedData = {"track_documents":id}
    
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //*edit column 
  const handleDocument_EditColumn = (documents_IdPassed, passed_NoteId, passed_DayId, updatedData) => {

    // console.log("handleDocument_EditColumn: passed_DayId passed_NoteId", passed_DayId, passed_NoteId)

    const updatedRows = tripDocumentsData.map(row =>
      (row.day_id === passed_DayId && row.topic_id === passed_NoteId && row.id_documents === documents_IdPassed)  
      ? { ...row, ...updatedData } : row
    );
    // console.log("updatedRows", updatedRows)
    setTripDocumentsData(updatedRows)
  }



  //!#3
  //?CheckList Data

  //*add column - checklist
  const handleChecklist_addChecklist = (topic_id_Passed, day_id_Passed, id_checkListPassed) => {

    let id = id_checkListPassed+1;
    if(!id){
      id = 1;
    }
    const newRow = {
      "topic_id": topic_id_Passed,
      "day_id": day_id_Passed,
      "id_check_list":id
    };
    
    setCheckListData([...checkListData, newRow]);

    //update the trackid for the checklist in the itineraryData
    const updatedData = {"track_check_list":id,}
    
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //*edit column 
  const handleCheckList_EditColumn = (checklist_IdPassed, passed_NoteId, passed_DayId, updatedData) => {

    // console.log("passed_DayId passed_NoteId", passed_DayId, passed_NoteId)

    const updatedRows = checkListData.map(row =>
      (row.day_id === passed_DayId && row.topic_id === passed_NoteId && row.id_check_list === checklist_IdPassed)  
      ? { ...row, ...updatedData } : row
    );
    // console.log("updatedRows", updatedRows)
    setCheckListData(updatedRows)
  }

  //*delete column 
  const handleCheckList_DeleteColumn = (checklist_IdPassed, passed_DayId, passed_NoteId) => {

    const filteredRows = checkListData.filter(row => 
      !(row.day_id === passed_DayId && row.topic_id === passed_NoteId && row.id_check_list === checklist_IdPassed)
    );
    
    setCheckListData(filteredRows)
  }


  //!--------------
  //!Entity-01
  //?handle other things

  const[hoverTopicId, setHoverTopicId] = useState()
  const[active_savedPlacesData_itinerary, setActive_savedPlacesData_itinerary] = useState([])

  const handleDisplaySavedPlaces = (topic_typePassed, topic_idPassed) => {
    // tourist_place, hotel, restaurant, bar_and_pub, activity, amusement_or_water_park, shopping_mall, street_shopping, rent_vehicle
    // console.log("handleDisplaySavedPlaces was called")
    if(topic_typePassed === "tourist_place"){
      setActive_savedPlacesData_itinerary(savedPlacesData)
    }
    else{
      setActive_savedPlacesData_itinerary()
    }
    setHoverTopicId(topic_idPassed)
  }

  //*handle show saved places places data
  const handleShowSavedData = (topic_type_Passed, topic_category_Passed, topic_id_Passed) => {
    setHoverTopicId(topic_id_Passed)
  }

  //*Handle saved places for any entity selected
  const handleName_SavedPlaceSelected = async (topic_id_Passed, day_id_Passed, name_Passed, id_Passed, latPassed, lngPassed) => {

    const updatedData = {name: name_Passed, saved_place_id: id_Passed, lat:latPassed, lng:lngPassed}

    // console.log('updatedData - handleName_SavedPlaceSelected', updatedData)

    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //*Handle Name Input
  // const handleName_Input = async (event, topic_id_Passed, day_id_Passed) => {

  //   const updatedData = {"name": event.target.value, "add_notes_status":false}

  //   // console.log('updatedData - handleName_Input', updatedData)

  //   itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  // }

  // const debouncedInputValue = useDebounce(inputValue, 2000);

  // useEffect(() => {
  //   if (debouncedInputValue) {
  //       handleGetSuggestions(debouncedInputValue);
  //   }
  // }, [debouncedInputValue]);

  const [timer, setTimer] = useState(null);

  const handleName_Input = async (event, topic_id_Passed, day_id_Passed) => {

    setActiveTopicId(topic_id_Passed)

    // Clear the previous timer
    if (timer) {
        clearTimeout(timer);
    }

    // Set a new timer
    const newTimer = setTimeout(() => {
        setLoadingPlaceName_status("loading")
        handleGetSuggestions(event.target.value);
    }, 2000); // 2-second delay

    setTimer(newTimer);

    // handleGetSuggestions(event.target.value);

  }
  
  
  const[placeNamesSugesstions, setPlaceNamesSugesstions] = useState([])

  const handleGetSuggestions = async (inputPlaceName) => {

    const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/google/suggestions/get_places`, {stateName, inputPlaceName, userEmail, tripId})

    // console.log("handleGetSuggestions", response.data, "response.data.predictions", response.data.predictions);

    if(response.data != "1"){
      setPlaceNamesSugesstions(response.data.predictions)
      setLoadingPlaceName_status(false)
    }
    else if(response.data == "1"){
      setLoadingPlaceName_status("no places found")
      setLoadingPlaceName_status(false)

      toast.error("No related place name found.", {
        position: "top-right", // Positions it in the top right
        style: {
          top: '50px', // Adjust this value to increase or decrease the margin from the top
          right: '0px',
        }
      }); 
    }
    else{
      toast.error("Error in suggesting place names. Try again with a different name.", {
        position: "top-right", // Positions it in the top right
        style: {
          top: '50px', // Adjust this value to increase or decrease the margin from the top
          right: '0px',
        }
      }); 
    }
  }


  const handleSuggestionNameClicked = async (clickedPlaceName, clickedPlaceId, topic_id_Passed, day_id_Passed) => {

    const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/google/detail/get_place_info`, {stateName, clickedPlaceId, userEmail, tripId})

    // console.log("handleSuggestionNameClicked", response.data);

    if(response.data != "1"){
      const { lat, lng } = response.data.results[0].geometry.location;
      // console.log("Latitude:", lat, "Longitude:", lng);

      // console.log("lat, lng", lat, lng)

      handleInput_PlaceName(clickedPlaceName, lat, lng, topic_id_Passed, day_id_Passed)
    }
    else if(response.data == "1"){
      toast.success("No related place name found.", {
        position: "top-right", // Positions it in the top right
        style: {
          top: '50px', // Adjust this value to increase or decrease the margin from the top
          right: '0px',
        }
      }); 
    }
    else{
      toast.error("Error in suggesting place names. Try again with a different name.", {
        position: "top-right", // Positions it in the top right
        style: {
          top: '50px', // Adjust this value to increase or decrease the margin from the top
          right: '0px',
        }
      }); 
    }
  }


  const handleInput_PlaceName = async (clickedPlaceName, latPassed, lngPassed, topic_id_Passed, day_id_Passed) => {
    // console.log("clickedPlaceName, latPassed, lngPassed, topic_id_Passed, day_id_Passed", clickedPlaceName, latPassed, lngPassed, topic_id_Passed, day_id_Passed)
    //
    setSaveChangesStatus(true)

    const updatedData = {name: clickedPlaceName, add_notes_status:false, name_source:"google", lat:latPassed, lng:lngPassed}

    setPlaceNamesSugesstions([])

    // console.log('updatedData - handleInput_PlaceName', updatedData)

    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }
  




  //*Note Status 
  const handleNote_NoteStatus = async (topic_id_Passed, day_id_Passed, statusPassed) => {

    const updatedData = {"add_notes_status": statusPassed}
    
    // console.log('updatedData - handleNote_NoteStatus', updatedData)
    
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //*Note Input
  const handleNote_NoteInput = async (event, topic_id_Passed, day_id_Passed, statusPassed) => {

    const updatedData = {"notes_data":event.target.value}

    // console.log('updatedData - handleNote_NoteInput', updatedData)
    
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //*Remove Note
  const handleNote_RemoveNote = async (topic_id_Passed, day_id_Passed) => {

    const updatedData = {"notes_data":null, "add_notes_status": false}

    // console.log('updatedData - handleNote_RemoveNote', updatedData)
    
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //*Clear Note
  const handleNote_ClearNote = async (topic_id_Passed, day_id_Passed) => {

    const updatedData = {"notes_data":'', "add_notes_status":true}
  
    // console.log('updatedData - handleNote_ClearNote', updatedData)
      
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //*Visit Type Select (must_visit, interested, may_be, not_sure)
  const handleVisitType = async (event, topic_id_Passed, day_id_Passed) => {

    const updatedData = {"topic_visit_type":event.target.value}
  
    // console.log('updatedData - handleVisitType', updatedData)
      
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //*Budget Status 
  const handleBudget_Status = async (topic_id_Passed, day_id_Passed, statusPassed) => {

    const updatedData = {"add_budget_status": true}
    
    // console.log('updatedData - handleNote_NoteStatus', updatedData)
    
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //*Remove Budget
  const handleBudget_RemoveBudget = async (topic_id_Passed, day_id_Passed) => {

    const updatedData = {"add_budget_status":false, "budget_number": null, "budget_currency": null, "budget_meta_info": null}

    // console.log('updatedData - handleBudget_RemoveBudget', updatedData)
    
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //*Input Budget Number
  const handleBudget_InputBudget = (event, topic_id_Passed, day_id_Passed, passedMetaType) => {

    let updatedData; 

    if(event.target.value.length < 8){

      if(passedMetaType === "not_yet_decided"){
        updatedData = {"budget_number":event.target.value, "budget_meta_info":''}
      }
      else{
        updatedData = {"budget_number":event.target.value}
      }
    // const updatedData = {"budget_number":event}

    // const final = formatIndianStyle(updatedData);
  
    // console.log('updatedData - handleBudget_InputBudget', updatedData)
      
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
    }
  }

  // const formatIndianStyle = (value) => {
  //   if (!value) return ''; // Return empty string if value is not provided

  //   // Remove existing commas and non-numeric characters
  //   const numericValue = value.toString().replace(/[^0-9]/g, '');

  //   // Initialize the formatted value with the last three digits
  //   let formattedValue = numericValue.slice(-3);

  //   // Iterate over the remaining digits, adding commas after every two digits
  //   for (let i = numericValue.length - 3; i > 0; i -= 2) {
  //       const start = Math.max(i - 2, 0);
  //       formattedValue = numericValue.slice(start, i) + (i !== 2 ? ',' : '') + formattedValue;
  //   }

  //   return formattedValue;  
  // };

  //*Input Budget Currency
  const handleBudget_InputCurrency = (event, topic_id_Passed, day_id_Passed) => {

    const updatedData = {"budget_currency":event.target.value}
  
    // console.log('updatedData - handleBudget_InputCurrency', updatedData)
      
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //*Input Budget Type/Meta Info
  const handleBudget_InputType = (data, topic_id_Passed, day_id_Passed) => {
    // const updatedData = {"budget_meta_info":event.target.value}
    let updatedData;

    if(data !== "not_yet_decided"){
      updatedData = {"budget_meta_info":data}
    }
    else{
      updatedData = {"budget_meta_info":data, "budget_number": ''}
    }

    // console.log(topic_id_Passed, day_id_Passed, "topic_id_Passed, day_id_Passed", "itineraryData", itineraryData, 'updatedData - handleBudget_InputType', updatedData)
      
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

//*Time Interval 

  //*Budget Status 
  const handleTimeInterval_Status = async (topic_id_Passed, day_id_Passed, statusPassed) => {

    const updatedData = {"add_time_interval_status": true}
    
    // console.log('updatedData - handleTimeInterval_Status', updatedData)
    
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //*Remove Budget
  const handleTimeInterval_RemoveTimeInterval = async (topic_id_Passed, day_id_Passed) => {

    const updatedData = {"add_time_interval_status":false, "time_start": null, "time_end": null}

    // console.log('updatedData - handleTimeInterval_RemoveTimeInterval', updatedData)
    
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  const startTimeData = [];
  const endTimeData = [];

  for (let hour = 0; hour <= 23; hour++) {
      for (let minute = 0; minute <= 50; minute += 10) {
        const currentHour = hour;
        const amPm = currentHour < 12 ? 'am' : 'pm';
        const formattedHour = currentHour % 12 === 0 ? 12 : currentHour % 12;
        const formattedTime = `${formattedHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${amPm}`;
        startTimeData.push(formattedTime);
      }
  }

  const handleStartTimeChange = (event, topic_id_Passed, day_id_Passed, indexPassed) => {
    const selectedStartTime = event.target.value;
    const updatedData = { time_start: selectedStartTime, time_end: '', "time_index": indexPassed };

    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData);

    // endTimeData.push(...startTimeData.slice(selectedStartTime))
    // console.log("selectedStartTime", selectedStartTime, "endTimeData", endTimeData)
  };

  const handleEndTimeChange = (event, topic_id_Passed, day_id_Passed) => {
    const selectedEndTime = event.target.value;
    const updatedData = { time_end: selectedEndTime };
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData);
  };



  //!--------------
  //!Day Info-02

  //*Note Input
  const handleQuill_NoteInput = async (value, topic_id_Passed, day_id_Passed) => {

    // console.log("value, topic_id_Passed, day_id_Passed", value, topic_id_Passed, day_id_Passed)
    
    const updatedData = {"notes_data":value}

    // console.log('updatedData - handleNote_NoteInput', updatedData)
    
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }


  const handleDayInfo_FitinMaxLimit_NoteInput = async () => {
    setTimeout(() => {
      setItineraryData(itineraryData.map(item => 
        item.id === -1 ? { ...item, "refresh": true } : item
      ));
      // setNoteLimitExceeded_Error(false)
    }, 200);
  };
  
  


  //!--------------
  //!Note-03 
  //?the note input for this topic category is being handled by 'handleQuill_NoteInput' function 

  //*Note Description
  const handleNote_Description = async (event, topic_id_Passed, day_id_Passed) => {

    const updatedData = {"description":event.target.value}

    // console.log('updatedData - handleDayInfo_Description', updatedData)
    
    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //?
  //*####Trip Documents
  
  //*add description 
  const handleDocuments_addDescription = async (event, id_documentsPassed, topic_id_Passed, day_id_Passed) => {

    const updatedData = {"description":event.target.value}

    // console.log('updatedData - handleDocuments_Description', updatedData)
    
    handleDocument_EditColumn(id_documentsPassed, topic_id_Passed, day_id_Passed, updatedData)

    itinerary_EditColumn(topic_id_Passed, day_id_Passed, updatedData)
  }

  //*add link
  const handleDocuments_addLink = async (event, id_documentsPassed, topic_id_Passed, day_id_Passed) => {

    const updatedData = {"link":event.target.value}

    // console.log('updatedData - handleDocuments_Description', updatedData)
    
    handleDocument_EditColumn(id_documentsPassed, topic_id_Passed, day_id_Passed, updatedData)
  }


  //!--------------
  //!Checklist-04

  //*note check status - check status
  const handleCheckList_CheckStatus = async (checkStatusPassed, id_checkListPassed, topic_id_Passed, day_id_Passed) => {

    const updatedData = {"check_status": checkStatusPassed}

    // console.log('updatedDatac - handleNote_CheckStatus', updatedData)
    
    handleCheckList_EditColumn(id_checkListPassed, topic_id_Passed, day_id_Passed, updatedData)
  }

  //*add description 
  const handleCheckList_addDescription = async (event, id_checkListPassed, topic_id_Passed, day_id_Passed, descriptionPassed) => {

    let updatedData; 

    if(!event.target.value){
      updatedData = {"description":'', check_status: false}
    }
    else{
      updatedData = {"description":event.target.value}
    }

    // console.log('updatedData - handleDocuments_Description', updatedData, "event.target.value", event.target.value)
    
    handleCheckList_EditColumn(id_checkListPassed, topic_id_Passed, day_id_Passed, updatedData)
  }



  
  const[showSavedData_Status, setShowSavedData_Status] = useState()
  const[checkListData, setCheckListData] = useState([])
  const[tripDocumentsData, setTripDocumentsData] = useState([])

  const[activeDayId, setActiveDayId] = useState(1)
  const[activeTopicId, setActiveTopicId] = useState()
  const[loadingPlaceName_status, setLoadingPlaceName_status] = useState()

  const[hoverItineraryData, setHoverItineraryData] = useState([])
  const[placeClicked_map, setPlaceClicked_map] = useState(false)

  // const handleHoverItinerary = (latPassed, lngPassed, displayNamePassed, indexPassed, namePassed) => {
  //   setHoverItineraryData([{
  //     lat:latPassed, lng:lngPassed, displayName:displayNamePassed, index:indexPassed, name:namePassed
  //   }])
  // }

  // const[dayCount, setDayCount] = useState([])
  const[topicIdCount, setTopicIdCount] = useState(0)

  // Step 5: Return a provider with value containing state and functions
  return (
    <Context.Provider value={{ 
      test,
      //!States
      //navigate
      activeViewSavedType, savedPlacesNavigation,
      //active notes window
      activeWindow_Notes, hideNote_Temp, setHideNote_Temp, textLength, setTextLength, noteLimitExceeded_Error, setNoteLimitExceeded_Error,
      //status
      loadDataStatus_Itinerary, loadDataStatus_SavedPlaces, saveChangesStatus, setSaveChangesStatus,
      loadingPlaceName_status, setLoadingPlaceName_status,

      //
      activeDayId, setActiveDayId,

      //
      activeTopicId, setActiveTopicId,


      //google search 
      placeNamesSugesstions, setPlaceNamesSugesstions,

      //map
      fitAllLocations, setFitAllLocations,
      hoverItineraryData, setHoverItineraryData,
      placeClicked_map, setPlaceClicked_map,
   

      
      //day 
      dayData,
      dayIdCount,
      showDates_ActiveId, setShowDates_ActiveId,
      
      //Map
      lat_CI, lng_CI, zoom_CI,
      
      //Saved Places
      savedPlacesData, placesInfo, active_savedPlacesData_itinerary,
      
      //*Itinerary
      //?Day Selector
      // dayCount, setDayCount,
      activeDayId, setActiveDayId,
      //?Topic Selector
      itinerary_AddColumn,
      //?Topic Detail 
      //data
      itineraryData, checkListData, tripDocumentsData,
      activeAddTopicDetailType, setActiveAddTopicDetailType,
      //show saved places data
      showSavedData_Status, setShowSavedData_Status, hoverTopicId,
      
      //!Functions   
      //map
      handleMap_HoverClick,
      
      //
      handleActiveSavedType,
      //useEffect
      //*handle select from saved places data
      loadSavedTripItinerary,
      //*save itinerary
      handleSaveTripItinerary,

      //!Overall
      itinerary_DeleteColumn,

      handleDocument_addDocument,
      handleDocument_DeleteColumn,
      handleChecklist_addChecklist,
      handleCheckList_DeleteColumn,

      //!Day-00 
      handleAddDay,
      handleDeleteDay,
      handleEdit_AddDate_Day,
      handleShowDates,

      //!--------------
      //!Entity-01
      handleShowSavedData, handleDisplaySavedPlaces,
      //*Handle Itinerary Input Data 
      //?name
      handleName_SavedPlaceSelected, handleName_Input,
      //note
      handleNote_NoteStatus,
      handleNote_NoteInput,
      handleNote_RemoveNote,
      handleNote_ClearNote,
      //?
      handleVisitType,
      //?budget
      handleBudget_Status,
      handleBudget_RemoveBudget,
      handleBudget_InputBudget,
      handleBudget_InputCurrency,
      handleBudget_InputType,
      //?time interval 
      handleTimeInterval_Status,
      handleTimeInterval_RemoveTimeInterval,
      handleStartTimeChange,
      handleEndTimeChange,
      startTimeData, endTimeData,
      //
      handleQuill_NoteInput, handleDayInfo_FitinMaxLimit_NoteInput,
      handleDocuments_addDescription,
      handleDocuments_addLink,
      handleCheckList_CheckStatus,
      handleCheckList_addDescription,
      handleNote_Description,
      //active notes window
      handleActiveWondow_Notes, handleHideNoteTemp, handleTextLength,

      //google search
      handleGetSuggestions, handleSuggestionNameClicked, handleInput_PlaceName,

      //!--------------
      //!Day Info-02
      temp_td_heading, set_temp_td_heading,
      temp_td_link, set_temp_td_link,
      temp_td_description, set_temp_td_description,
      temp_td_newOld, set_temp_td_newOld,
      temp_td_id, set_temp_td_id,

      //!--------------
      //!Note-03

      //!--------------
      //!Checklist-04

    }}>
      {children}

      <ToastContainer />

    </Context.Provider>
  );

};

// Step 6: Create a custom hook to consume the context
export const useCreateItineraryPlanTrip = () => {
  return useContext(Context);
};



