//This would be used to custom track the user visits, and exsits, and many times google analytics do not work on few platforms like brave, and also for some user custom restrictions to tracking their activties.

import axios from "axios"
import { server_baseUrl } from "../../Universal Components/Server Side Hit Url/ServerHitUrlPart"

export const userAnalytics_customTrack_VisitsExits = async (user_temp_id, email, path, type, stay_duration) => {

    const response = await axios.post(`${server_baseUrl}/t_u_a/send/analystics/visit_exit`, {user_temp_id, email, path, type, stay_duration})

    //no need to log the error, as 
}



