import React, {useState} from 'react'

//importing css
import './DayInfo_TD.css'
import './Budget_DayInfo_TD.scss'
import './TripDocuments_DayInfo_TD.scss'

//import icon
import { RiDeleteBin5Line } from "react-icons/ri";
import { RiDeleteBinLine } from "react-icons/ri";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { IoMdCloseCircle } from "react-icons/io";
import { MdInsertLink } from "react-icons/md";
import { TbPencil } from "react-icons/tb";
import { AiOutlineEdit } from "react-icons/ai";
import { TbEdit } from "react-icons/tb";
import { IoMdRemoveCircle } from "react-icons/io";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

//importing "DayInfo_TD" component in "Itinerary_CI" component internal componenets
import { 
  //Topic Details
  Budget_02_TD, TripDocuments_02_TD
} from '../../../../../../Manage/Export/Export_CreateItinerary'

//import react context
import {useCreateItineraryPlanTrip} from '../../../../../../Manage/Context/CreateItinerary_Context';
import {useCreateItinerary_2_PlanTrip} from '../../../../../../Manage/Context/CreateItinerary_Context_2';

import Notes_Tool from '../../../../../../../../Global Tool - Plan Trip/Notes/Notes_Tool';
import TipTap_ReadOnly from '../../../../../../../../Global Tool - Plan Trip/Notes/TestingLexical/TipTap_ReadOnly';


function DayInfo_TD(props){

  const {visibility_type, different_design, topic_id, topic_type} = props;

  const{saveChangesStatus, setSaveChangesStatus}=props;

  const {
  
    itineraryData, tripDocumentsData,
    itinerary_DeleteColumn,

    //
    activeAddTopicDetailType, setActiveAddTopicDetailType,
    //
    handleNote_ClearNote,
    handleBudget_InputBudget,
    handleBudget_InputCurrency,
    handleBudget_InputType,
    handleQuill_NoteInput,
    handleDocument_addDocument,
    handleDocument_DeleteColumn,
    handleDocuments_addDescription,
    handleDocuments_addLink,
    //active notes window
    handleActiveWondow_Notes, handleDayInfo_FitinMaxLimit_NoteInput, activeWindow_Notes, hideNote_Temp, setHideNote_Temp, handleHideNoteTemp,
    textLength, setTextLength, handleTextLength,
    noteLimitExceeded_Error, setNoteLimitExceeded_Error,
    //trip documents
    temp_td_heading, set_temp_td_heading,
    temp_td_link, set_temp_td_link,
    temp_td_description, set_temp_td_description,
    temp_td_newOld, set_temp_td_newOld, 
    temp_td_id, set_temp_td_id
   
  } = useCreateItineraryPlanTrip()

  const {
    //!States

    //track AI question
    askQuestionCount_day,

    //*navigate
    navigate_aiItineraryChat, setNavigate_aiItineraryChat,
    aiItineraryChat_window, setAiItineraryChat_window,
    
  } = useCreateItinerary_2_PlanTrip()


  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
      setActiveAddTopicDetailType(null);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };

  const[activeDocumentId, setActiveDocumentId] = useState()

  //after editing and updating the new note, the latest one is not visible, so we refresh it...
  const[displayLatestNote, setDisplayLatestNote] = useState(true)

  const handleDisplayUpdatedNotes = () => {
    setDisplayLatestNote(false);
    setTimeout(() => {
      setDisplayLatestNote(true);
    }, 200);
  };
  
  const handleImageLink = (type) => {
    let link;
    if(type === "Day Overview"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/travel_around_icon_2.png'
    }
    else if(type === "Travelling Around"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/rent_vehicle_2_icon_1.png'
    }
    else if(type === "Clothes Overview"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/clothes_icon.png'
    }
    return link;
  }


  const handleGetCurrency = (currencyName) => {
    let currency;

    if(currencyName === "inr"){
      currency = '₹'
    }
    else if(currencyName === "dollar"){
      currency = '$'
    }

    return currency;

  }


  function formatNumberWithCommas(number) {
    if (isNaN(number)) {
      return 0; // Handle non-numeric values gracefully
  }

    const parts = number.toString().split('.');
    let wholePart = parts[0];

    // Reverse the string to handle commas from the last digit
    wholePart = wholePart.split('').reverse().join('');

    // Insert commas after every group of 2 digits, but not at the end
    wholePart = wholePart.replace(/(\d{2})(?=(\d{2})+(?!\d))/g, '$1,');

    // Reverse the string back to normal order
    wholePart = wholePart.split('').reverse().join('');

    // Adjust the first comma for the first three digits group
    if (wholePart.length > 6) {
        wholePart = wholePart.replace(/(\d+),/, '$1,');
    }

    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    return wholePart + decimalPart;
  }

  return (
    <div
    // className='container_p-Entities_TD'
    >
      {/* //*Budget For the Day, Trip Documents, Day Overview, Travel Around, What to wear */}
      

      { 
        !different_design && itineraryData &&

        itineraryData.map((data, index) => {
          if(data.topic_id === topic_id){
            return(
              <div key={index}
              className='container_p-Checklist_TD'
              >

                <div
                className='conatiner_p-head-Checklist_TD'
                > 

                  <div
                  className='conatiner_t-displayName_Icon-head-Checklist_TD'
                  >
                    <div
                    className='text-head-Checklist_TD'
                    >
                      {data.display_name}
                    </div> 

                    <div>
                      <img
                      // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/note_icon_6.png'
                      src={handleImageLink(data.display_name)}
                      className='icon-checklist-Checklist_TD'
                      />
                    </div>      
                  </div> 

                  <div>
                    <button 
                      onClick={()=>{
                        itinerary_DeleteColumn(data.topic_id, data.day_id)
                        setSaveChangesStatus(true)
                      }}
                      className='icon-delete-Checklist_TD'
                    >
                      <RiDeleteBinLine />
                    </button>
                  </div>     

                </div>  


                {
                  data.notes_data &&
                  <div>
                    <button
                    onClick={()=>{
                      handleNote_ClearNote(data.topic_id, data.day_id)
                      setSaveChangesStatus(true)
                    }}
                    className='button-clearNote-Note_TD'
                    >
                      Clear Note
                    </button>
                  </div>   
                }


                <div>
 
                  <button
                  onClick={()=>{
                    handleActiveWondow_Notes(data.topic_id, data.day_id)
                    handleHideNoteTemp()
                    setHideNote_Temp(false)
                    setActiveAddTopicDetailType("add_day_info_note")
                    openPopup()
                    setSaveChangesStatus(true)
                  }}
                  className='container_p-note-singleNote-displayNotes-Note_TD'
                  >

                    { 
                      !data.notes_data &&
                      <div>
                        Click to Enter Notes...
                      </div>
                    }

                    { displayLatestNote  && data.notes_data &&
                      <div style={{background:'white'}}
                      className='container_t-note-singleNote-displayNotes-Note_TD'
                      >
                        <TipTap_ReadOnly
                          content={data.notes_data}
                        />
                      </div>
                    }  

                  </button>  

                </div>


                {/* {
                  data.notes_data &&
                  <div>
                    <button
                    onClick={()=>{
                      handleNote_ClearNote(data.topic_id, data.day_id)
                      setSaveChangesStatus(true)
                    }}
                    >
                      Clear Note
                    </button>
                  </div>   
                } */}


                {/*//! Popup */}
                {
                  activeAddTopicDetailType === "add_day_info_note" &&
                  (!hideNote_Temp && activeWindow_Notes.topic_id === data.topic_id && activeWindow_Notes.day_id === data.day_id) &&
                
                  <div>

                    <div className='popup-background-1-Entities_TD'
                    onClick={()=>{
                      closePopup()
                      handleDisplayUpdatedNotes()
                    }}>
                    </div>

                    {/* <div className='popup-addNote-Entities_TD'> */}

                    <div className='popup_p-addNote-AddNotes_TO_Popup'>
                      <div className='popup_s-addNote-AddNotes_TO_Popup'>



                    <button
                    className='container-askAi-DaySelector_TD'
                    style={{width:'fit-content', marginLeft:'10px', alignSelf:'start' }}
                    onClick={()=>{
                      setNavigate_aiItineraryChat(true)
                      // openPopup()
                    }}
                    >
                      <div className='text-trackAiQCount-DaySelector_TD'>
                        {askQuestionCount_day}/20
                      </div>

                      <div
                      className='button-askAi-DaySelector_TD'
                      >  
                        Ask Ai
                      </div>

                      <img
                        src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/ai_chatbot-2.png'
                        className='img-iButton-AiItinerary_Chat' 
                      />
                    </button>


                      <div>
                        <button 
                        onClick={()=>{
                          closePopup()
                          handleDisplayUpdatedNotes()
                        }}
                        className='button-close-popup-AddNotes_TO_Popup'
                        >
                          <IoMdCloseCircle />
                        </button>
                      </div>   

                      <div> 
                        <Notes_Tool
                        userInputText={data.notes_data}
                        topic_id={data.topic_id}
                        day_id={data.day_id}
                        handleUserInputText={handleQuill_NoteInput}
                        placeholderPassed={"enter notes for the budget..."}
                        textLength={textLength}
                        handleTextLength={handleTextLength}
                        handleDayInfo_FitinMaxLimit_NoteInput={handleDayInfo_FitinMaxLimit_NoteInput}
                        wordLimit={1500}
                        setSaveChangesStatus={setSaveChangesStatus}
                        />
                      </div> 

                    </div> 

                   </div>

                  </div>

                }
              
              </div>
            )
          }
        })
      }   

      {/*//! Budget  #2 */}
      { 
        different_design && itineraryData &&

        itineraryData.map((data, index) => {
          if(data.topic_id === topic_id){
            return(
              topic_type === "day_budget" ? 
              (
                <div className='container_p-Entities_TD'>
                  

                {/*//! #1  */}
                {/* topic type and delete topic */}
                <div
                className='conatiner_p-head-Checklist_TD'
                > 
                  <div
                  className='conatiner_t-displayName_Icon-head-Checklist_TD'
                  >

                    <div
                    className='text-head-Checklist_TD'
                    >
                      {data.display_name}
                    </div> 

                    <div>
                      <img
                      src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/budget_icon_1.png'
                      className='icon-checklist-Checklist_TD'
                      />
                    </div>   

                  </div>   

                  <div>
                    <button 
                      onClick={()=>{
                        itinerary_DeleteColumn(data.topic_id, data.day_id)
                        setSaveChangesStatus(true)
                      }}
                      className='icon-delete-Checklist_TD'
                    >
                      <RiDeleteBin5Line />
                    </button>
                  </div>   

                </div> 

                              <br></br>

                  {
                    (data.budget_number ||
                    data.budget_meta_info) 
                    ?
                    <div>

                      {/*//* Budget */}
                      {
                        (data.budget_number && data.budget_currency)
                        && 
                        <div
                        className='container_p-Budget-Entities_TD'
                        style={{width:"70%"}}
                        >

                          <div
                          className='heading-1-budgetData-Entities_TD'
                          > 
                            Budget
                          </div>   

                          <div 
                          className='container_t-budgetData-Entities_TD'
                          > 
                            <div
                            className='container_t-data-1-budget-Entities_TD'
                            >
                              {/* {data.budget_currency} */}
                              {
                                data.budget_currency ?
                                handleGetCurrency(data.budget_currency) 
                                :
                                <dv>xx</dv>
                              }
                            </div>
                            <div
                            className='container_t-data-1-budget-Entities_TD'
                            >
                              {
                                data.budget_number ?
                                formatNumberWithCommas(data.budget_number) 
                                :
                                <dv>xx</dv>
                              }
                            </div>
                            {
                              data.budget_meta_info && 
                            <div
                            className='container_t-data-2-budget-Entities_TD'
                            >
                              #{data.budget_meta_info}
                            </div>  
                            }
                          </div> 

                          <div
                          className='container_t-icons-budget-Entities_TD'
                          >
                            <button
                            onClick={()=>{
                              setActiveAddTopicDetailType("add_budget")
                              openPopup()
                            }}
                            className='button-icon-edit-Entities_TD'
                            >
                              <AiOutlineEdit />
                            </button>

                            {/* <button
                            onClick={()=>{handleBudget_RemoveBudget(data.topic_id, data.day_id, false)}}
                            className='button-icon-delete-Entities_TD'
                            >
                              <IoMdRemoveCircle />
                            </button> */}

                          </div> 

                        </div> 

                      }
                    </div>
                    :

                    <div
                    style={{marginLeft:'16px', marginTop:'-12px'}}
                    >
                      <button
                        onClick={()=>{
                          setActiveAddTopicDetailType("add_day_info_budget")
                          openPopup()
                        }}
                        className='button-budget-addButton-Entities_TD'
                      >
                        Add Budget
                      </button>
                    </div> 
                  }  

                  {
                     (data.budget_number ||
                      data.budget_meta_info) && 
                      <div>

                        <div
                        className='conatiner_s-1-addNote-Entities_TD'
                        > 

                          <div
                          className='heading-1-addNote-Entities_TD'
                          >
                            Note 
                          </div> 

                          {/*//* Edit Button */}
                          {
                            !(activeAddTopicDetailType === "add_note" &&
                            !hideNote_Temp && activeWindow_Notes.topic_id === data.topic_id && activeWindow_Notes.day_id === data.day_id) &&
                            <div>
                              <button
                                onClick={()=>{
                                  handleActiveWondow_Notes(data.topic_id, data.day_id)
                                  handleHideNoteTemp()
                                  setHideNote_Temp(false)
                                  setActiveAddTopicDetailType("add_day_info_budget_note")
                                  openPopup()
                                  setSaveChangesStatus(true)
                                }}
                                className='button-icon-edit-Entities_TD'
                              >
                                <AiOutlineEdit />
                              </button>
                            </div>   
                          }

                          {/*//* Remove Note */}
                          {
                            (data.saved_place_id || data.name) && (data.add_notes_status || data.notes_data) &&
                            <div>
                              <button
                                // onClick={()=>{
                                //   handleNote_RemoveNote(data.topic_id, data.day_id)}
                                // }
                                className='button-icon-delete-Entities_TD'
                              >
                                <RiDeleteBin5Line />
                              </button>
                            </div>   
                          }

                        </div>  

                      <button
                        onClick={()=>{
                          handleActiveWondow_Notes(data.topic_id, data.day_id)
                          handleHideNoteTemp()
                          setHideNote_Temp(false)
                          setActiveAddTopicDetailType("add_day_info_budget_note")
                          openPopup()
                          setSaveChangesStatus(true)
                        }}
                        className='container_p-note-singleNote-displayNotes-Note_TD'
                      >

                        { 
                          !data.notes_data &&
                          <div>
                            Click to Enter Notes...
                          </div>
                        }

                        { displayLatestNote && data.notes_data &&
                          <div style={{background:'white'}}
                          className='container_t-note-singleNote-displayNotes-Note_TD'
                          >
                            <TipTap_ReadOnly
                              content={data.notes_data}
                            />
                          </div>
                        }  

                      </button>

                    </div>
                  }

                  {/*//! Popup  */}
                  {
                    activeAddTopicDetailType === "add_day_info_budget" &&
                
                    <div>

                      <div className='popup-background-1-Entities_TD'
                      onClick={()=>closePopup()}>
                      </div>

                      <div className='popup_p-2-Entities_TO_Popup'>
                        <div className='popup_s-addNote-Entities_TO_Popup'>


                    <button
                    className='container-askAi-DaySelector_TD'
                    style={{width:'fit-content', marginLeft:'10px'}}
                    onClick={()=>{
                      setNavigate_aiItineraryChat(true)
                      // openPopup()
                    }}
                    >

                      <div className='text-trackAiQCount-DaySelector_TD'
                      >
                        {askQuestionCount_day}/20
                      </div>

                      <div
                      className='button-askAi-DaySelector_TD'
                      >  
                        Ask Ai
                      </div>

                      <img
                        src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/ai_chatbot-2.png'
                        className='img-iButton-AiItinerary_Chat' 
                      />
                    </button>

                    <br></br>


                        <div
                        className='heading-1-budget-Entities_TO_Popup'
                        >
                          Enter Your Budget For the Day
                        </div> 
                                  <br></br>
                        <div
                        className='conatiner_t-Duration_Date-popup-AddTripDetails_TO'
                        >
                          {/*//?Input Budget Number */}
                          <div
                          className='container_t-Duration-popup-AddTripDetails_TO'
                          > 
                            <input 
                            value={data.budget_number}
                            onChange={(event)=>{
                              // if(data.budget_number && data.budget_number.length < 8){
                                handleBudget_InputBudget(event, data.topic_id, data.day_id, data.budget_meta_info)
                                setSaveChangesStatus(true)
                              // }
                            }}
                            placeholder='enter budget...'
                            maxLength={7}
                            type='number'
                            className='input-1-popup-AddTripDetails_TO'
                            /> 
                          </div>


                          {/*//?Input Budget Currency */}
                          <div>
                            <select
                              value={data.budget_currency ? data.budget_currency : "select currency"}
                              // value={data.budget_currency}
                              onChange={(event)=>{
                                handleBudget_InputCurrency(event, data.topic_id, data.day_id)
                                setSaveChangesStatus(true)
                              }}
                              className='select-popup-AddTripDetails_TO'
                            >
                              <option value={null} disabled>
                                Select
                              </option>

                              <option value="dollar"
                              className='option-popup-AddTripDetails_TO'
                              >
                                $
                              </option>

                              <option value="inr"
                              className='option-popup-AddTripDetails_TO'
                              >
                                ₹
                              </option>

                            </select>
                          </div>

                        </div>  

<br></br>
                          {/*//?Input Budget Type/Meta Info */}
                          <div>
                            {
                              data.budget_number && 
                              (
                              <div>
                                <button 
                                onClick={()=>{handleBudget_InputType("confirmed", data.topic_id, data.day_id)}}
                                className={data.budget_meta_info === "confirmed" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                                >
                                  Confirmed
                                </button>

                                <button 
                                onClick={()=>handleBudget_InputType("aprox", data.topic_id, data.day_id)}
                                className={data.budget_meta_info === "aprox" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                                >
                                  Aprox
                                </button>

                                <button 
                                onClick={()=>handleBudget_InputType("can_change", data.topic_id, data.day_id)}
                                className={data.budget_meta_info === "can_change" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                                >
                                  Can Change. (Planning For a future trip)
                                </button>
                              </div> 
                              )
                            }

                            {
                              (
                                <button 
                                onClick={()=>handleBudget_InputType("not_yet_decided", data.topic_id, data.day_id)}
                                className={data.budget_meta_info === "not_yet_decided" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                                >
                                  Not Yet Decided
                                </button>
                              )
                            }

                          </div>

                        {/* </div>    */}
                        
                      </div>   
                      </div>
                    </div> 
                  
                  }

                  {
                    activeAddTopicDetailType === "add_day_info_budget_note" &&
                    (!hideNote_Temp && activeWindow_Notes.topic_id === data.topic_id && activeWindow_Notes.day_id === data.day_id) &&
                  
                    <div>

                      <div className='popup-background-1-Entities_TD'
                        onClick={()=>{
                          closePopup()
                          handleDisplayUpdatedNotes()
                        }}
                      >
                      </div>

                      <div className='popup_p-addNote-Entities_TO_Popup'>
                        <div className='popup_s-addNote-Entities_TO_Popup'>


                        <div>
                          <button 
                            onClick={()=>{
                              closePopup()
                              handleDisplayUpdatedNotes()
                            }}
                            className='button-close-popup-Note_TD'
                          >
                            {/* Close */}
                            <IoMdCloseCircle />
                          </button>
                        </div>   

                        <div> 
                          <Notes_Tool
                          userInputText={data.notes_data}
                          topic_id={data.topic_id}
                          day_id={data.day_id}
                          handleUserInputText={handleQuill_NoteInput}
                          placeholderPassed={"enter notes for the budget..."}
                          textLength={textLength}
                          handleTextLength={handleTextLength}
                          handleDayInfo_FitinMaxLimit_NoteInput={handleDayInfo_FitinMaxLimit_NoteInput}
                          wordLimit={1500}
                          setSaveChangesStatus={setSaveChangesStatus}
                          />
                        </div> 

                      </div> 
                     </div>  
                    </div>

                  }

                </div>
              ) 
              :

              // {/*//! Trip Documents  #3 */}

              ( //topic_type === "day_trip_documents" ? 
                <div className='container_p-Checklist_TD'>


                  {/*//! topic type and delete topic */}
                  <div
                  className='conatiner_p-head-Checklist_TD'
                  > 
                    <div
                    className='conatiner_t-displayName_Icon-head-Checklist_TD'
                    >
                      <div
                      className='text-head-Checklist_TD'
                      >
                        {data.display_name}
                      </div> 

                      <div>
                        <img
                        src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/documents_icon_1.png'
                        className='icon-checklist-Checklist_TD'
                        />
                      </div>      
                    </div> 

                    <div>
                      <button 
                        onClick={()=>{
                          itinerary_DeleteColumn(data.topic_id, data.day_id)
                        }}
                        className='icon-delete-Checklist_TD'
                      >
                        <RiDeleteBinLine />
                      </button>
                    </div>     

                  </div>  


                  {/*//! Add documents button  */}
                  <div>
                    <button 
                      onClick={()=>{
                        setActiveAddTopicDetailType("add_trip_documents")

                        set_temp_td_newOld("new")
                        set_temp_td_heading('')
                        set_temp_td_link('')
                        set_temp_td_description('')
                        set_temp_td_id('')

                        openPopup()
                      }}
                      className='conatiner_p-addCheckList-Checklist_TD'
                    >
                      <div
                      className='text-addCheckList-Checklist_TD'
                      >
                        Add Document
                      </div>

                      <div>
                        <img
                          src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_2.png'
                          className='icon-add-Checklist_TD'
                        />
                      </div>

                    </button>
                  </div>   



                  {/*//! the document container */}
                  {
                    tripDocumentsData && 
                    <div className='conatiner_p-Documents-TripDocuments'>
                    {
                    tripDocumentsData.map((TDData, TDIndex) => {
                      if(TDData.topic_id === topic_id){
                        return(
                        // {/* the document conatiner */}
                        <div
                        className='conatiner_s-Documents-TripDocuments'
                        onClick={()=>{
                          // handleDocument_addDocument("old", data.topic_id, data.day_id, data.track_documents)
                          setActiveAddTopicDetailType("add_trip_documents")

                          set_temp_td_newOld("old")
                          set_temp_td_heading(TDData.heading)
                          set_temp_td_link(TDData.link)
                          set_temp_td_description(TDData.description)
                          set_temp_td_id(TDData.id_documents)

                          openPopup()
                        }}
                        > 

                          {/*//! Container 1  */}
                          {/* heading, link, link open, view more, delete  */}
                          <div
                          className='conatiner_p-1-Document-TripDocuments'
                          >

                            <div
                            className='conatiner_t-1_1-deleteButton-Document-TripDocuments'
                            >

                              <div
                              className='heading-1-Heading-Document-TripDocuments'
                              >
                                {TDData.heading}
                              </div> 

          
                              <div
                              className='conatiner_t-link_open-Document-TripDocuments'
                              > 

                                {
                                  TDData.link &&
                                  <div
                                  className='link-Document-TripDocuments'
                                  > 
                                    {
                                      TDData.link.split(' ').slice(0, 50).join(' ') + (TDData.link.split(' ').length > 50 ? '...' : '')
                                    }
                                  </div> 
                                }

                                <div>
                                  {TDData.link ? (
                                    <a
                                      href={TDData.link} 
                                      target="_blank" 
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                      src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/share_icon_1.png'
                                      className='img-link-Document-TripDocuments'
                                      />
                                    </a>
                                  ) : (
                                    <span
                                    className='text-1-notFound-Documents-TripDocuments'
                                    >
                                      (Link is not available)
                                    </span>
                                  )}
                                </div>    

                              </div> 

                              <div> 
                                {
                                  TDData.id_documents === activeDocumentId ?
                                  <button
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setActiveDocumentId();
                                    }}
                                    className='button-detail-Documents-TripDocuments'
                                  >
                                    hide detail
                                  </button>
                                :
                                  <button 
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setActiveDocumentId(TDData.id_documents);
                                    }}
                                    className='button-detail-Documents-TripDocuments'
                                  >
                                    view detail
                                  </button>
                                }
                              </div>     

                            </div>  

                            <div
                            className='conatiner_t-1_2-deleteButton-Document-TripDocuments'
                            >
                              <div> 
                                <button 
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleDocument_DeleteColumn(TDData.id_documents, TDData.day_id, TDData.topic_id)
                                    setSaveChangesStatus(true)
                                  }}
                                  className='button-icon-delete-Document-TripDocuments'
                                  >
                                  <RiDeleteBin2Fill />
                                </button>
                              </div>    
                            </div>       

                          </div>   


                          {/*//! Container 2  */}
                          {/* document detail detail */}
                          {
                            TDData.id_documents === activeDocumentId && TDData.description ? 
                            <div
                            className='conatiner_p-2-Document-TripDocuments'
                            > 
                              <div
                              className='heading-detail-Documents-TripDocuments'
                              >
                                Description
                              </div>
                              <div
                              className='content-detail-Documents-TripDocuments'
                              >
                                {TDData.description}  
                              </div>     
                            </div>   
                            :
                            TDData.id_documents === activeDocumentId &&
                            <div
                            className='text-2-notFound-Documents-TripDocuments'
                            >
                              no details found
                            </div>  
                          }

                        </div>    

                        )
                      }
                    })
                  }
                  </div>
                  }

                  
                  {/*//! Popup for trip documents  */}
                  {
                    activeAddTopicDetailType === "add_trip_documents" &&
                  
                    <div> 
                        
                      <div className='popup-background-1-Entities_TD'
                      onClick={()=>{
                        closePopup()
                        handleDocument_addDocument(data.topic_id, data.day_id, data.track_documents)
                        setActiveDocumentId()
                      }}>
                      </div>

                      {/* <div className='popup-addNote-popup-TripDocuments'> */}
                      <div className='popup_p-addNote-AddNotes_TO_Popup'>
                        <div className='popup_s-addNote-AddNotes_TO_Popup'>


                        <div
                        className='message-text-popup-TripDocuments'
                        >
                          #Hack: If you have any PDFs, screenshots, or documents in other formats, please upload them on your google drive and share the link here. We are still developing the feature to help you upload documents in different formats. Thanks :)
                        </div>   


                        <button
                        className='container-askAi-DaySelector_TD'
                        style={{width:'fit-content', marginLeft:'20px', alignSelf:'start'}}
                        onClick={()=>{
                          setNavigate_aiItineraryChat(true)
                          // openPopup()
                        }}
                        >

                          <div className='text-trackAiQCount-DaySelector_TD'>
                            {askQuestionCount_day}/20
                          </div>

                          <div
                          className='button-askAi-DaySelector_TD'
                          >  
                            Ask Ai
                          </div>

                          <img
                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/ai_chatbot-2.png'
                            className='img-iButton-AiItinerary_Chat' 
                          />
                        </button>
                        

                        <button 
                        onClick={()=>{
                          closePopup()
                          handleDocument_addDocument(data.topic_id, data.day_id, data.track_documents)
                          setActiveDocumentId()
                        }}
                        className='button-close-popup-TripDocuments'
                        >
                          <IoMdCloseCircle />
                        </button>

                        {/* heading, link, link open, view more, delete  */}
                        <div>

                          {/* <div>
                            <input 
                              value={temp_td_heading}
                              onChange={(event)=>{
                                set_temp_td_heading(event.target.value)
                                setSaveChangesStatus(true)
                              }}
                              placeholder='enter heading...'
                              maxLength={50}
                              type='text'
                              className='input-1-description-TripDocuments'
                            />
                          </div>  */}

                          <div> 
                            <div
                            style={{position:'relative'}}
                            > 
                              <input 
                                value={temp_td_heading}
                                onChange={(event)=>{
                                  set_temp_td_heading(event.target.value)
                                  setSaveChangesStatus(true)
                                }}
                                placeholder='enter heading...'
                                maxLength={50}
                                type='text'
                                className='input-1-description-popup-TripDocuments'
                              />
                              <div
                                className='container_t-linkName-popup-TripDocuments'
                              >
                                <div
                                className='text-1-linkName-popup-TripDocuments'
                                >
                                  Enter Title
                                </div>   
                                <div
                                className='icon-link-linkName-popup-TripDocuments'
                                >
                                  {/* <MdInsertLink /> */}
                                  {/* <TbPencilMinus /> */}
                                  <TbPencil />
                                </div>
                              </div>  
                            </div> 
                          </div> 

                          <div> 
                            <div
                            style={{position:'relative'}}
                            > 
                              <input 
                                value={temp_td_link}
                                onChange={(event)=>{
                                  set_temp_td_link(event.target.value)
                                  setSaveChangesStatus(true)
                                }}
                                placeholder='enter link...'
                                maxLength={500}
                                type='url'
                                className='input-1-description-popup-TripDocuments'
                              />
                              <div
                                className='container_t-linkName-popup-TripDocuments'
                              >
                                <div
                                className='text-1-linkName-popup-TripDocuments'
                                >
                                  Enter Link
                                </div>   
                                <div
                                className='icon-link-linkName-popup-TripDocuments'
                                >
                                  <MdInsertLink />
                                </div>
                              </div>  
                            </div> 
                          </div> 

                          <div
                          className='text-1-description-popup-TripDocuments'
                          > 
                            Link Description
                          </div> 

                          <div> 

                            {
                              temp_td_description &&
                              <div
                              className='conatiner_t-3-heading-TripDocuments'
                              >
                                {temp_td_description.length}/500
                              </div>   
                            }

                            <div> 
                              <textarea 
                                value={temp_td_description}
                                onChange={(event) => {
                                  set_temp_td_description(event.target.value)
                                  setSaveChangesStatus(true)
                                }}
                                placeholder='enter description...'
                                maxLength={500}
                                type='text'
                                className='input-2-description-popup-TripDocuments'
                              />
                            </div> 
                          </div>      

                        </div>   

                      </div>

                     </div>

                    </div>   
                  } 


                </div>  
              )
            )
          }
        })
      } 

      {/*//*Trip Documents*/}
      {/* { different_design && topic_type === "trip_documents" &&
        <div className='ItineraryTripDocumentsRow-Itinerary_CI'>
          trip_documents
          <TripDocuments_02_TD />
        </div>    
      } */}

      {/*//*Budget Info*/}
      {/* { different_design && topic_type === "budget_for_the_day" &&
        <div className='ItineraryBudgetInfoRow-Itinerary_CI'>
          budget_for_the_day
          <Budget_02_TD />
        </div>    
      } */}

    </div>
  )
}

export default DayInfo_TD

















         {/* //* start - handle quill notes
                <div>

                  {
                    (!hideNote_Temp && activeWindow_Notes.topic_id === data.topic_id && activeWindow_Notes.day_id === data.day_id) ?
                    
                    <div>

                      <div>
                        <Notes_Tool
                        userInputText={data.notes_data}
                        topic_id={data.topic_id}
                        day_id={data.day_id}
                        handleUserInputText={handleQuill_NoteInput}
                        placeholderPassed={"enter notes for the budget..."}
                        textLength={textLength}
                        handleTextLength={handleTextLength}
                        handleDayInfo_FitinMaxLimit_NoteInput={handleDayInfo_FitinMaxLimit_NoteInput}
                        wordLimit={3000}
                        />
                      </div> 

                      <div>

                        <div>
                          {textLength}/3000
                          TextLength: {textLength}
                          notes_data: {data.notes_data && data.notes_data.length}
                        </div>   

                      </div> 

                    </div>  
                    :
                    <div
                    onClick={()=>{
                      handleActiveWondow_Notes(data.topic_id, data.day_id)
                      handleHideNoteTemp()
                      setHideNote_Temp(false)
                    }}
                    >
                      {
                        !data.notes_data && 
                        <div>
                          Click Here To Add Notes
                        </div>  
                      }

                      <pre dangerouslySetInnerHTML={{ __html: data.notes_data}} />

                    </div>  
                  }

                </div> */}