import React, {useState, useEffect} from 'react'

//import scss
import './Hotels_TP.scss'

//importing context
import { useValidatePlanTrip } from '../../../../../Manage - Plan Trip/Context/ValidatePlanTrip';
import { useGlobalNavigationPlanTrip } from '../../../../../Manage - Plan Trip/Context/GlobalNavigationPlanTrip';
import { TravelCategories_2_Context } from '../../../Manage/Context/TravelCategories_2_Context'
import { TravelCategories_Context } from '../../../Manage/Context/TravelCategories_Context';


import {useLocation, useParams} from 'react-router-dom';
import { Helmet } from 'react-helmet'

//
import { Rating } from 'react-simple-star-rating';

//icons
import { IoMdCloseCircle } from "react-icons/io";
import { FaCircleChevronLeft } from "react-icons/fa6";
import { FaCircleChevronRight } from "react-icons/fa6";
import { FaWifi } from "react-icons/fa";
import { BsBookmarkPlus } from "react-icons/bs";
import { BsFillBookmarkDashFill } from "react-icons/bs";
import { MdPets } from "react-icons/md";
import { MdOutlineLocalBar } from "react-icons/md";
import { MdOutlineFreeBreakfast } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { MdAccessible } from "react-icons/md";
import { FaSwimmingPool } from "react-icons/fa";
import { MdSpa } from "react-icons/md";
import { MdOutlineLocalLaundryService } from "react-icons/md";
import { MdOutlineSoupKitchen } from "react-icons/md";
import { MdOutlineAirportShuttle } from "react-icons/md";
import { MdOutlinePool } from "react-icons/md";
import { TbParking } from "react-icons/tb";
import { MdOutlineRoomService } from "react-icons/md";
import { IoRestaurantOutline } from "react-icons/io5";
import { IoOptionsSharp } from "react-icons/io5";
import { MdOutlineFreeCancellation } from "react-icons/md";
import { IoRibbonOutline } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaRegStarHalf } from "react-icons/fa";
import { GiStarFormation } from "react-icons/gi";
import { TbSortAscendingNumbers } from "react-icons/tb";
import { TbSortDescendingNumbers } from "react-icons/tb";
import { GrRevert } from "react-icons/gr";
import { MdManageSearch } from "react-icons/md";
import { GoLinkExternal } from "react-icons/go";

//
import ViewPrices_CH from '../../../../../../Travel Categories/Hotels/Components/Content Hotels/View Prices/ViewPrices_CH';
import Hotel_ViewMore_PT from './View More/Hotel_ViewMore_PT';
import Search_Tool_Hotel_PT from './Search_Tool_Hotel_PT/Search_Tool_Hotel_PT';



//
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { TravelCategories_3_Context } from '../../../Manage/Context/TravelCategories_3_Context';


function Hotels() {
  
  //temp
  // const[activeHoverHotel, setActiveHoverHotel] = useState()
  // const[view_prices_popup, setView_prices_popup] = useState()
  // const[viewMore_id, setViewMore_id] = useState()
  // const[activeViewPrices_Limited, setActiveViewPrices_Limited] = useState()
  // const[activeHotel_PriceData_arr, setActiveHotel_PriceData_arr] = useState([])


  //using react context
  const {
    loadingStatus,
    hotelData, setHotelData,
    //--save places
    saved_touristPlaces_data, setSaved_touristPlaces_data,
    saved_TravelCategoriesPlaces_data, setSaved_TravelCategoriesPlaces_data, lat, lng
  } = TravelCategories_Context();
  //using react context
  const {
    handle_loadHotelsData, priceData, bestDealData, showMoreStatus, handleClickHotel, handleGetReviewdData, handleShowMoreClicked, viewMoreStatus, setViewMoreStatus, handle_Hover_Hotel, handle_HoverOut_Hotel,
    //
    activeHoverHotel, setActiveHoverHotel,
    view_prices_popup, setView_prices_popup,
    viewMore_id, setViewMore_id,
    activeViewPrices_Limited, setActiveViewPrices_Limited,setActiveHotel_setViewMoreStatusPriceData_arr,
    //
    activeHotel_PriceData_arr, setActiveHotel_PriceData_arr, hoverHotelData_map, setHoverHotelData_map,
    //
    hotelInternalNavigation, setHotelInternalNavigation
  } = TravelCategories_2_Context();

  const {
    //save places
    handle_UnSave_Places, handle_Save_Places
  } = TravelCategories_3_Context();

  const {
    setCreateTripStatus
  } = useGlobalNavigationPlanTrip();

  const {
    tripDestinationName, tripDestinationType, destinationLat, destinationLng, stateName, userEmail, tripId
  } = useValidatePlanTrip();


  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
    setView_prices_popup(false);
    // setActiveViewPrices_Limited()
    document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  const modified_state_name = stateName ? stateName.toLowerCase().replace(/\s+/g, '_') : "himachal_pradesh";


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 799); // Initialize with the current width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 799); // Update state on window resize
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  //image
  const[hoverIcon, setHoverIcon] = useState()

  const[trackerArray_Image, setTrackerArray_Image] = useState([])
  //trackerArray has two columns: id, and id_index

  const handleNextReview = (passedId, passedCurrentHotelIndex) => {

    // console.log("passedId, passedCurrentHotelIndex", passedId, passedCurrentHotelIndex, "trackerArray_Image", trackerArray_Image)

    const newTrackerIndex = passedCurrentHotelIndex + 1;

    const trackerIndex = trackerArray_Image.findIndex(
      (tracker) => tracker.id === passedId
    );


    if (trackerIndex !== -1) {
      // Tracker exists, update it

      setTrackerArray_Image(prevArray =>
        prevArray.map(row =>
          row.id === passedId ? { ...row, id_index: newTrackerIndex } : row
        )
      );
      
    } else {
      // Tracker does not exist, add a new one
      const newTrackerArray = [...trackerArray_Image];

      newTrackerArray.push({ id: passedId, id_index: newTrackerIndex });

      setTrackerArray_Image(newTrackerArray);
    }
  };


  const handlePreviousReview = (passedId, passedCurrentHotelIndex) => {
    const newTrackerIndex = passedCurrentHotelIndex - 1;

    //i would like to update the row where the id = passedID, and set the id_index = newTrackerIndex; this is the state: setTrackerArray_Image

    setTrackerArray_Image(prevArray =>
      prevArray.map(row =>
        row.id === passedId ? { ...row, id_index: newTrackerIndex } : row
      )
    );
  }

  // setShowMoreCount, setShowMoreStatus

  return (
    <div>

      {/* <button onClick={()=>{handle_loadHotelsData(showMoreCount, false, "all", sortHotelPrice, data.district_name)}}>handle_loadHotelsData</button> */}

<Helmet>   

<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Assistant:wght@400&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap" rel="stylesheet" />

</Helmet>


<div>

{
  // (loadingStatus === "hotels_data") &&
  loadingStatus && 
  <div className="overlay">
    <div className="spinner-container">
      <div className="spinner"></div>
      <p>Loading Hotels...</p>
    </div>
  </div>
}

{/* <div>
  <Search_Tool_Hotel_PT />
</div> */}



{ 
  hotelData && hotelData.length > 0 && 
  <div
  className='container_p-cards-TouristPlace-Hotels_TP'
  >
    {
      // tripDestinationType !== "state" &&
      <div
      className='card-searchTool-Hotels_TP'
      >
        <Search_Tool_Hotel_PT 
        tripDestinationType={tripDestinationType}
        lat={lat}
        lng={lng}
        />
      </div>
    }
    
  {
    hotelData.map((data, index) => {
      if(
        (hotelInternalNavigation !== "saved") 
        || 
        (hotelInternalNavigation === "saved" &&  saved_TravelCategoriesPlaces_data.some(savedItem => savedItem.id === data.id && savedItem.type === "hotels"))
      )
      return(
      <div
      className='card-TouristPlace-Hotels_TP'
      >

          {
            activeViewPrices_Limited === data.id && view_prices_popup &&  
            <ViewPrices_CH 
              hotel_name={data.name}
              priceData={priceData}
              data_id={data.id}
              data_has_price={data.has_price}
              openPopup={openPopup}
              closePopup={closePopup}
              setView_prices_popup={setView_prices_popup}
              bestDealData={bestDealData}
            />
          }




        <button key={index}
          className='conatiner_t-HotelCard-Hotels_TP'
          onClick={(e)=>{
            // (e) => {
              e.stopPropagation();
            handleClickHotel(data.id, data.lat, data.lng, data.hotel_name, data.min_price)
          }}
          onMouseOver={()=>{handle_Hover_Hotel(data.id, data.lat, data.lng, data.name, data.min_price)}}
          onMouseOut={()=>{handle_HoverOut_Hotel()}}
        >

          {/*//* Container 1 */}
          <div
          className='conatiner_t-1-Content-HotelCard-Content_Hotels'
          >
            
            <div
            className='conatiner_t-1-2-Content-HotelCard-Content_Hotels'
            >

              {
                !(trackerArray_Image.find(item => data.id === item.id)) &&
                <div>
                  <img 
                    className="image-HotelCard-Content_Hotels"

                    src={`https://pub-1f27b1db05f7459b866f03a58efd795e.r2.dev/${modified_state_name}-${data.ai_auto}-1.jpg`}
                    onError={(e) => { e.target.src = 'https://media.istockphoto.com/id/157287159/nl/foto/picture-of-a-mountain-top-from-above-the-clouds.jpg?s=612x612&w=0&k=20&c=9yWFtDxEGmhSna10oNZxnZ6l5umKL_OUBfvbopJslZo='; }}

                    alt="Image 1" 
                  />

                    <div
                      className='conatiner_p-imageIcons-HotelCard-Content_Hotels'
                    >  
                      <button 
                      onClick={()=>{handleNextReview(data.id, 0)}}
                      className="icon-right-image-HotelCard-Content_Hotels"
                      >
                        <FaCircleChevronRight />
                      </button>
                    </div>

                </div>
              }


              {
                trackerArray_Image.map((imageId, index_image) => {
                  if(imageId.id === data.id){
                    return(
                      <div>
                        <img 
                        className="image-HotelCard-Content_Hotels" 
                        // src={`https://pub-0f2bf997623e48308fe1f940228f477e.r2.dev/${modified_state_name}-${data.ai_auto}-${index_image+1}.jpg`}
                        src={`https://pub-1f27b1db05f7459b866f03a58efd795e.r2.dev/${modified_state_name}-${data.ai_auto}-${imageId.id_index+1}.jpg`}
                        onError={(e) => { e.target.style.display = 'none'; }}
                        />

                        {/* https://pub-0f2bf997623e48308fe1f940228f477e.r2.dev/${modified_state_name}-${data.ai_auto}-${index_image+1}.jpg */}
                        {/* 
                        https://pub-0f2bf997623e48308fe1f940228f477e.r2.dev/west_bengal-3133-3.jpg
                        https://pub-0f2bf997623e48308fe1f940228f477e.r2.dev/west_bengal-10-1.jpg */}

                        <div
                        className='conatiner_p-imageIcons-HotelCard-Content_Hotels'
                        >  
                          {
                            imageId.id_index > 0 &&
                            <button 
                            onClick={()=>{handlePreviousReview(data.id, imageId.id_index)}}
                            className="icon-left-image-HotelCard-Content_Hotels"
                            // disabled={imageId.id_index > 0}
                            >
                              {/* Previous {imageId.id_index} */}
                              <FaCircleChevronLeft />
                            </button>
                          }

                          {
                            imageId.id_index < 4 &&
                            <button 
                            onClick={()=>{handleNextReview(data.id, imageId.id_index)}}
                            className="icon-right-image-HotelCard-Content_Hotels"
                            >
                              {/* Next {imageId.id_index} */}
                              <FaCircleChevronRight />
                            </button>
                          }
                        </div>

                      </div>
                    )
                  }
                })
              }

            </div>

            {
              data.min_price &&
              <div
              className='container_p-minPrice-Content_Hotels'
              >
                <div
                className='text-1-minPrice-Content_Hotels'
                >
                  <i>starting at</i>
                </div>

                <div
                className='text-2-minPrice-Content_Hotels'
                >
                  ₹
                  {/* {data.min_price} */}
                  {data.min_price.toLocaleString('en-IN')}
                </div>
              </div>
            }


{/* saved_TravelCategoriesPlaces_data, setSaved_TravelCategoriesPlaces_data, */}

            {/* bookmark */}
            { 
               saved_TravelCategoriesPlaces_data && saved_TravelCategoriesPlaces_data.some(item => item.id === data.id && item.type === "hotels")
                ?
              (
                <button 
                style={{border:'none', background:'none', margin:'12px 0px 0px 0px'}}
                // className='container_t-icon-card-TouristPlace-MustVisit'
                 onClick={(e) => {
                  e.stopPropagation();
                  handle_UnSave_Places(data.id, "hotels");
                }}> 
                  <div className='icon-card-TouristPlace-MustVisit'>
                    <BsFillBookmarkDashFill />
                  </div>   
                </button>   
              )
              :
              (
                <button 
                style={{border:'none', background:'none', margin:'12px 0px 0px 0px'}}
                // className='container_t-icon-card-TouristPlace-MustVisit'
                 onClick={(e) => {
                  e.stopPropagation(); 
                  if(userEmail && tripId){
                    handle_Save_Places(data.id, null, data.name, data.lat, data.lng, data.district, "hotels");
                  }
                  else if(!userEmail){
                    // setLoginWindow_limited(true)
                    toast.error("Please login!", {
                      position: toast.POSITION.TOP_CENTER
                    })
                  }
                  else{
                    // setCreateTripWindow_limited(true)
                    setCreateTripStatus(true)
                  }
                  }}
                >
                  <div className='icon-card-TouristPlace-MustVisit'>
                    <BsBookmarkPlus />
                  </div>   
                </button> 
              )
            }




            {
              <button
              className='heading-1-reviews-Content_Hotels'
                onClick={(e)=>{
                  e.stopPropagation();
                  setViewMore_id(data.id)
                  setViewMoreStatus(true)
                  handleGetReviewdData(data.id)
                  openPopup()
                  // setReview_showMoreTrack(0)
                }}
              >
                View Info & Reviews
              </button>
            }

            {/* { data.url &&
              <div
                className='conatiner_t-3-Overview_VM'
              >
                <a
                href={data.url} 
                target="_blank" 
                rel="noopener noreferrer"
                className='conatiner_p-GoogleUrl-Overview_VM'
                >
                    <div
                    className='heading-1-Overview_VM'
                    >
                      See on Google
                    </div>

                    <img
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/share_icon_1.png'
                    className='img-link-Document-TripDocuments'
                    />

                </a>    
              </div>   
            } */}

            
          </div> 

{/*//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  */}
          {/*//* Container 2 */}
          <div
          className='conatiner_t-2-Content-HotelCard-Content_Hotels'
          >

            {/*//Hotel Heading */}
            <div
            className='container_p-heading-Content_Hotels'
            >
              
              <div
              className='container_t-1-Content_Hotels'
              >
                <div
                className='heading-1-Content_Hotels'
                >
                  {data.name}
                </div>
              </div>

              <div
              className='container_t-1-Content_Hotels'
              >
              
                {
                  data.rating && 
                  <div
                  className='heading-2-Content_Hotels'
                  >
                    {data.rating}

                  </div>                  
                }

                <Rating
                  initialValue={data.rating}
                  size={16}
                  emptyColor="#ccc"
                  fillColor="gold"
                  strokeColor="gold"
                  strokeWidth={0}
                  allowFraction={true}
                  readonly
                />  

                {
                  data.reviewCount && 
                  <div
                  className='heading-3-Content_Hotels'
                  >
                    ({
                      data.reviewCount ? data.reviewCount.toLocaleString('en-IN') : 'N/A'
                    })
                  </div>                  
                }
              {/* </div> */}
            
              {/* <div
              className='container_t-2-Content_Hotels'
              > */}
                {
                  data.hotel_star && 
                  <div
                  className='text-1-Content_Hotels'
                  >
                    {data.hotel_star} ★ hotel
                  </div>                  
                }

                {/* {
                  data.category && 
                  <div
                  className='text-2-Content_Hotels'
                  >
                    #{data.category}Category
                  </div>                  
                } */}

              </div>

            </div>

{/* <br></br> */}
            {/*//Hotel Amenities //*#2 */}
            <div
            className='container_p-amenities-Content_Hotels'
            >

              {
                data.free_cancellation === 1 && 
                <div
                onMouseOver={()=>{setHoverIcon("free_cancellation")}}
                onMouseOut={()=>{setHoverIcon("")}}
                className='icon-1-amenities-Content_Hotels'
                >
                  <MdOutlineFreeCancellation />

                  {
                    activeHoverHotel === data.id && hoverIcon === "free_cancellation" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Free Cancellation
                    </div>
                  }

                </div>  
              }
              {
                data.wifi === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("wifi")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <FaWifi />

                  {
                    activeHoverHotel === data.id && hoverIcon === "wifi" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Wifi
                    </div>
                  }

                </div>  
              }
              {
                data.pool === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("pool")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <MdOutlinePool />

                  {
                    activeHoverHotel === data.id && hoverIcon === "pool" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Pool
                    </div>
                  }

                </div>  
              }
              {
                data.parking === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("parking")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <TbParking />

                  {
                    activeHoverHotel === data.id && hoverIcon === "parking" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Parking
                    </div>
                  }
                </div>  
              }
              {
                data.room_service === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("room_service")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <MdOutlineRoomService />

                  {
                    activeHoverHotel === data.id && hoverIcon === "room_service" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Room Service
                    </div>
                  }
                </div>  
              }
              {
                data.restaurant === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("restaurant")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <IoRestaurantOutline />

                  {
                    activeHoverHotel === data.id && hoverIcon === "restaurant" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Restaurant
                    </div>
                  }
                </div>  
              }
              {
                data.bar === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("bar")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <MdOutlineLocalBar />

                   {
                    activeHoverHotel === data.id && hoverIcon === "bar" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Bar
                    </div>
                  }
                </div>  
              }
              {
                data.air_conditioned === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("air_conditioned")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <TbAirConditioning />

                   {
                    activeHoverHotel === data.id && hoverIcon === "air_conditioned" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Air Conditioned
                    </div>
                  }
                </div>  
              }
              {
                data.breakfast === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("breakfast")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <MdOutlineFreeBreakfast />

                  {
                    activeHoverHotel === data.id && hoverIcon === "breakfast" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Breakfast
                    </div>
                  }
                </div>  
              }
              {
                data.airport_shuttle === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("airport_shuttle")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <MdOutlineAirportShuttle />

                   {
                    activeHoverHotel === data.id && hoverIcon === "airport_shuttle" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Airport Shuttle
                    </div>
                  }
                </div>
              }
              {
                data.kitchen === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("kitchen")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <MdOutlineSoupKitchen />

                   {
                    activeHoverHotel === data.id && hoverIcon === "kitchen" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      In-room Kitchen
                    </div>
                  }
                </div>  
              }
              {
                data.laundry_service === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("laundry_service")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <MdOutlineLocalLaundryService />

                   {
                    activeHoverHotel === data.id && hoverIcon === "laundry_service" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Laundry Service
                    </div>
                  }
                </div>  
              }
              {
                data.accessible_w === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("accessible_w")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <MdAccessible />

                   {
                    activeHoverHotel === data.id && hoverIcon === "accessible_w" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Accessible
                    </div>
                  }
                </div>  
              }
              {
                data.outdoor_pool === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("outdoor_pool")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <FaSwimmingPool />

                   {
                    activeHoverHotel === data.id && hoverIcon === "outdoor_pool" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Outdoor Pool
                    </div>
                  }
                </div>
              }
              {
                data.spa === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("spa")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <MdSpa />

                   {
                    activeHoverHotel === data.id && hoverIcon === "spa" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Spa
                    </div>
                  }
                </div>  
              }
              {
                data.pet_friendly === 1 && 
                <div
                className='icon-1-amenities-Content_Hotels'
                onMouseOver={()=>{setHoverIcon("pet_friendly")}}
                onMouseOut={()=>{setHoverIcon("")}}
                >
                  <MdPets />

                   {
                    activeHoverHotel === data.id && hoverIcon === "pet_friendly" &&
                    <div
                    className='hover-text-icon-amenities-Content_Hotels'
                    >
                      Pet Friendly
                    </div>
                  }
                </div>
              }

            </div>

           <br></br>


            {/* //content containers //*#3 */}
            {/* //!Book Direct: price-22 */}

            {/* {
              priceData && 

              <div 
              className='container_p-bookDirect-Content_Hotels'
              >

                <div>
                  <b>Book Direct</b>
                </div>

                <div>
                  {
                    priceData.map((priceData, priceIndex) => {
                      if(priceData.id === data.id && priceData.official_site === 1){
                        return(
                          <div>

                            <div>
                              <i>name</i>
                              {priceData.hotel_name} 
                            </div>  

                            <div>
                              <i>price</i>
                              {priceData.hotel_price}
                            </div>  

                            { priceData.hotel_link &&
                              <div>
                                <a 
                                  href={priceData.hotel_link}
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                >
                                  {
                                    priceData.official_site ?
                                    <div>
                                      Visit Official Site
                                    </div>
                                    :
                                    <div>
                                      Visit Site
                                    </div>
                                  }
                                </a>
                              </div>
                            }

                            {
                              priceData.free_breakfast && 
                              <div>
                                Free Breakfast
                              </div>  
                            }
                            {
                              priceData.free_wifi && 
                              <div>
                                Free Wi-Fi
                              </div>  
                            }
                            {
                              priceData.free_parking && 
                              <div>
                                Free Parking
                              </div>  
                            }
                            {
                              priceData.free_cancellation && 
                              <div>
                                Free Cancellation
                              </div>  
                            }
                            {
                              priceData.free_cancellation_detail && 
                              <div>
                                {priceData.free_cancellation_detail}
                              </div>  
                            }
                          </div>   
                        )
                      }
                    })
                  }
                </div>

              </div>
            } */}

{/* <div className='test-Content_Hotels'> */}


            {/* //content containers //*#4 */}
            {/* //!Best Deal: price-11 */} 
            {/* {
                data.has_price && 
                bestDealData.map((priceData, index) => {
                  if(priceData.id === data.id){
                    return(
                    <div
                      className='container_p-bestDeal-Content_Hotels'
                    >

                      <b><u>Best Deal</u></b>
                      <div>
                        {priceData.hotel_name}
                      </div>

                      <div>
                        {priceData.hotel_price}
                      </div>

                      <div
                      className='conatiner_t-metaData-bestDeal-Content_Hotels'
                      >   

                        { priceData.hotel_link &&
                          <div>
                            <a 
                              href={priceData.hotel_link}
                              target="_blank" 
                              rel="noopener noreferrer"
                            >
                              {
                                priceData.official_site ?
                                <div>
                                  Visit Official Site
                                </div>
                                :
                                <div>
                                  Visit Site
                                </div>
                              }
                            </a>
                          </div>
                        }

                        {
                          priceData.free_breakfast && 
                          <div>
                            Free Breakfast
                          </div>  
                        }
                        {
                          priceData.free_wifi && 
                          <div>
                            Free Wi-Fi
                          </div>  
                        }
                        {
                          priceData.free_parking && 
                          <div>
                            Free Parking
                          </div>  
                        }
                        {
                          priceData.free_cancellation && 
                          <div>
                            Free Cancellation
                          </div>  
                        }
                        {
                          priceData.free_cancellation_detail && 
                          <div>
                            {priceData.free_cancellation_detail}
                          </div>  
                        }

                      </div>

                    </div>
                    )
                  }
                })
            } */}



            {/* //content containers //*#5 */}
            {/* //!Other Prices: price-33 */}
            {
              priceData && priceData.some(item => item.id === data.id)
              &&
              <div 
              className='container_p-otherPrices-Content_Hotels'
              >

                <div
                className='container_s-cards-otherPrices-Content_Hotels'
                >


                  {/*//! Book Direct  */}
                  {
                    priceData && 
                    priceData.map((priceData, index) => {
                    if(priceData.id === data.id && priceData.official_site === 1){
                      return(
                      <div
                      className='container_s-card-bestDeal-Content_Hotels'
                      >
                        <div
                        className='heading-1-bestDeal-Content_Hotels'
                        >
                          #Book Direct
                        </div>  

                        <div
                        className='text-1-bestDeal-Content_Hotels'
                        >
                          {priceData.hotel_name}
                        </div>

                        {
                          priceData.hotel_price && 

                          <div
                          className='text-2-bestDeal-Content_Hotels'
                          >
                            {priceData.hotel_price}
                          </div>
                        }


                        { priceData.hotel_link &&
                          // <div>
                          <a 
                            href={priceData.hotel_link}
                            target="_blank" 
                            rel="noopener noreferrer"
                            className='link-1-MetaData-otherPrices-Content_Hotels'
                          >
                            {
                              priceData.official_site ?
                              <div>
                                Visit Official Site <GoLinkExternal />
                              </div>
                              :
                              <div>
                                Visit Site <GoLinkExternal />
                              </div>
                            }

                          </a>
                          // {/* </div> */}
                        }

                        <div
                          // className='conatiner_t-metaData-bestDeal-Content_Hotels'
                          className='container_t-MetaData-otherPrices-Content_Hotels'
                        >   

                          {
                            priceData.free_breakfast && 
                            <div
                            className='conatiner_t-amenities-otherPrices-Content_Hotels'
                            >
                              <div
                              className='icon-1-amenities-otherPrices-Content_Hotels'
                              >
                                <MdOutlineFreeBreakfast /> 
                              </div>

                              <div
                                className='text-1-amenities-otherPrices-Content_Hotels'
                              >
                                Free Breakfast
                              </div>
                            </div>  
                          }

                          {
                            priceData.free_wifi && 
                            <div
                            className='conatiner_t-amenities-otherPrices-Content_Hotels'
                            >
                              <div
                              className='icon-1-amenities-otherPrices-Content_Hotels'
                              >
                                <FaWifi /> 
                              </div>

                              <div
                                className='text-1-amenities-otherPrices-Content_Hotels'
                              >
                                Free Wifi
                              </div>
                            </div>  
                          }

                          {
                            priceData.free_parking && 
                            <div
                            className='conatiner_t-amenities-otherPrices-Content_Hotels'
                            >
                              <div
                              className='icon-1-amenities-otherPrices-Content_Hotels'
                              >
                                <TbParking /> 
                              </div>

                              <div
                                className='text-1-amenities-otherPrices-Content_Hotels'
                              >
                                Free Parking
                              </div>
                            </div>  
                          }

                          {/* {
                            priceData.free_cancellation_detail && 
                            <div
                            className='conatiner_t-amenities-otherPrices-Content_Hotels'
                            >

                              <div
                              className='icon-1-amenities-otherPrices-Content_Hotels'
                              >
                                <MdOutlineFreeCancellation /> 
                              </div>

                              <div
                              className='text-1-amenities-otherPrices-Content_Hotels'
                              >
                                Free Cancellation {priceData.free_cancellation_detail}
                              </div>

                            </div>  
                          } */}

                        </div>

                      </div>
                      )
                      }
                    })
                  }




                  {/*//! Best Deal */}
                  {
                    data.has_price && 
                    bestDealData.map((priceData, index_price) => {
                    if(priceData.id === data.id ){
                      return(
                      <div key={index_price}
                      className='container_s-card-bestDeal-Content_Hotels'
                      >
                        <div
                        className='heading-1-bestDeal-Content_Hotels'
                        >
                          #Best Deal
                        </div>  

                        <div
                        className='text-1-bestDeal-Content_Hotels'
                        >
                          {priceData.hotel_name}
                        </div>

                        {
                          priceData.hotel_price && 

                          <div
                          className='text-2-bestDeal-Content_Hotels'
                          >
                            {priceData.hotel_price}
                          </div>
                        }


                        { priceData.hotel_link &&
                          // <div>
                          <a 
                            href={priceData.hotel_link}
                            target="_blank" 
                            rel="noopener noreferrer"
                            className='link-1-MetaData-otherPrices-Content_Hotels'
                          >
                            {
                              priceData.official_site ?
                              <div>
                                Visit Official Site <GoLinkExternal />
                              </div>
                              :
                              <div>
                                Visit Site <GoLinkExternal />
                              </div>
                            }

                          </a>
                          // {/* </div> */}
                        }

                        <div
                          // className='conatiner_t-metaData-bestDeal-Content_Hotels'
                          className='container_t-MetaData-otherPrices-Content_Hotels'
                        >   

                          {
                            priceData.free_breakfast && 
                            <div
                            className='conatiner_t-amenities-otherPrices-Content_Hotels'
                            >
                              <div
                              className='icon-1-amenities-otherPrices-Content_Hotels'
                              >
                                <MdOutlineFreeBreakfast /> 
                              </div>

                              <div
                                className='text-1-amenities-otherPrices-Content_Hotels'
                              >
                                Free Breakfast
                              </div>
                            </div>  
                          }

                          {
                            priceData.free_wifi && 
                            <div
                            className='conatiner_t-amenities-otherPrices-Content_Hotels'
                            >
                              <div
                              className='icon-1-amenities-otherPrices-Content_Hotels'
                              >
                                <FaWifi /> 
                              </div>

                              <div
                                className='text-1-amenities-otherPrices-Content_Hotels'
                              >
                                Free Wifi
                              </div>
                            </div>  
                          }

                          {
                            priceData.free_parking && 
                            <div
                            className='conatiner_t-amenities-otherPrices-Content_Hotels'
                            >
                              <div
                              className='icon-1-amenities-otherPrices-Content_Hotels'
                              >
                                <TbParking /> 
                              </div>

                              <div
                                className='text-1-amenities-otherPrices-Content_Hotels'
                              >
                                Free Parking
                              </div>
                            </div>  
                          }

                          {/* {
                            priceData.free_cancellation_detail && 
                            <div
                            className='conatiner_t-amenities-otherPrices-Content_Hotels'
                            >

                              <div
                              className='icon-1-amenities-otherPrices-Content_Hotels'
                              >
                                <MdOutlineFreeCancellation /> 
                              </div>

                              <div
                              className='text-1-amenities-otherPrices-Content_Hotels'
                              >
                                Free Cancellation {priceData.free_cancellation_detail}
                              </div>

                            </div>  
                          } */}

                        </div>

                      </div>
                      )
                      }
                    })
                  }




                  {
                  priceData.map((priceData, priceIndex) => {
                    if(
                      // priceData.id === data.id
                      // 1>0
                      (priceData.id === data.id )
                      ||
                      (priceData.id === data.id && priceData.official_site !== 1) 
                    ){
                      return(
                        <div key={priceIndex}
                        className='container_s-card-otherPrices-Content_Hotels'
                        >

                          <div
                          className='text-1-otherPrices-Content_Hotels'
                          >
                            {priceData.hotel_name} 
                          </div>  

                          {
                            priceData.hotel_price && 
                            <div
                            className='text-2-otherPrices-Content_Hotels'
                            >
                              {priceData.hotel_price}
                            </div>  
                          }

                          { priceData.hotel_link &&
                            // <div>
                            <a 
                              href={priceData.hotel_link}
                              target="_blank" 
                              rel="noopener noreferrer"
                              className='link-1-MetaData-otherPrices-Content_Hotels'
                            >
                              {
                                priceData.official_site ?
                                <div>
                                  Visit Official Site <GoLinkExternal />
                                </div>
                                :
                                <div>
                                  Visit Site <GoLinkExternal />
                                </div>
                              }

                            </a>
                            // {/* </div> */}
                          }
                          
                          {/*//Amenities  */}
                          <div
                            className='container_t-MetaData-otherPrices-Content_Hotels'
                          >

                            {
                              priceData.free_breakfast && 
                              <div
                              className='conatiner_t-amenities-otherPrices-Content_Hotels'
                              >
                                <div
                                className='icon-1-amenities-otherPrices-Content_Hotels'
                                >
                                  <MdOutlineFreeBreakfast /> 
                                </div>

                                <div
                                  className='text-1-amenities-otherPrices-Content_Hotels'
                                >
                                  Free Breakfast
                                </div>
                              </div>  
                            }

                            {
                              priceData.free_wifi && 
                              <div
                              className='conatiner_t-amenities-otherPrices-Content_Hotels'
                              >
                                <div
                                className='icon-1-amenities-otherPrices-Content_Hotels'
                                >
                                  <FaWifi /> 
                                </div>

                                <div
                                  className='text-1-amenities-otherPrices-Content_Hotels'
                                >
                                  Free Wifi
                                </div>
                              </div>  
                            }

                            {
                              priceData.free_parking && 
                              <div
                              className='conatiner_t-amenities-otherPrices-Content_Hotels'
                              >
                                <div
                                className='icon-1-amenities-otherPrices-Content_Hotels'
                                >
                                  <TbParking /> 
                                </div>

                                <div
                                  className='text-1-amenities-otherPrices-Content_Hotels'
                                >
                                  Free Parking
                                </div>
                              </div>  
                            }

                            {/* {
                              priceData.free_cancellation_detail && 
                              <div
                              className='conatiner_t-amenities-otherPrices-Content_Hotels'
                              >

                                <div
                                className='icon-1-amenities-otherPrices-Content_Hotels'
                                >
                                  <MdOutlineFreeCancellation /> 
                                </div>

                                <div
                                className='text-1-amenities-otherPrices-Content_Hotels'
                                >
                                  Free Cancellation {priceData.free_cancellation_detail}
                                </div>

                              </div>  
                            } */}

                          </div>


                        </div>   
                      )
                    }
                  })
                }

               </div>
                {/*//! */}

                {/*//!  */}
                {/* <br></br> */}

                {/*//! View Price #Popup - Start*/}
                <div
                onClick={()=>{
                  openPopup()
                  setView_prices_popup(true)
                  setActiveViewPrices_Limited(data.id)
                  setActiveHotel_PriceData_arr([
                    {
                      hotel_name: data.name,
                      priceData: priceData,
                      data_id: data.id,
                      data_has_price: data.has_price,
                      // openPopup: openPopup,
                      // closePopup: closePopup,
                      // setView_prices_popup: setView_prices_popup,
                      bestDealData: bestDealData
                    }
                  ]);
                }}
                className='conatiner_p-viewPrice-Popup-Content_Hotels_2'
                >
                  <button
                  className='button-1-viewPrice-Popup-Content_Hotels_2'
                  >
                    View Prices
                  </button>

                </div>

                {/*//! View Price #Popup - End*/}

              </div>
            }
{/* </div> */}
            {/* //content containers //*#6 */}
            {/* <div
            className='container_p-viewMore-Content_Hotels'
            >
              <button 
                onClick={(e)=>{
                  e.stopPropagation();
                  setViewMore_id(data.id)
                  setViewMoreStatus(true)
                  handleGetReviewdData(data.id)
                  openPopup()
                  // setReview_showMoreTrack(0)
                }}
              >
                View More
              </button>
            </div> */}

          </div>
        </button>  
        </div>
      )
    })
  }
  </div>
}

{/* <button 
    onClick={()=>{handleShowMoreClicked()}}
    className='text-1-ShowMore-Content_Hotels_2'
    >
      Show More
    </button> */}
    
{ tripDestinationType !== "state" && hotelInternalNavigation !== "saved" &&
  showMoreStatus && 
  <div>
    <button 
    onClick={()=>{handleShowMoreClicked()}}
    className='text-1-ShowMore-Content_Hotels_2'
    >
      Show More
    </button>
  </div>  
}

{/* view more */}
{/* {
  viewMoreStatus && 
  <div>
    <Hotel_ViewMore />
  </div>
} */}

<div style={{height:'60px'}}>

</div>

</div>

    { viewMoreStatus &&
      <Hotel_ViewMore_PT />
    }

    <ToastContainer />

    </div>
  )
}

export default Hotels
























// import React from 'react'
// import './Hotels_TP.scss'
// function Hotels() {
//   return (
//     <div
//     className='container_p-cards-TouristPlace-MustVisit'
//     >
     

//       <div
// className='card-TouristPlace-MustVisit'>

//       Hotels


//       HotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotels

//       HotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotels

//       HotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotels


//       HotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotels

//       HotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotelsHotels

//     </div>



//     </div>
//   )
// }

// export default Hotels