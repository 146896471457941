import React from 'react'

//importing css
import './Checklist_TD.scss'

//import react context
import {useCreateItineraryPlanTrip} from '../../../../../../Manage/Context/CreateItinerary_Context';

// import react icons
import { RiDeleteBinLine } from "react-icons/ri";

function Checklist_TD(props) {

  const {visibility_type, different_design, topic_type, topic_id, isMobile} = props;

  const{saveChangesStatus, setSaveChangesStatus}=props;

  const {
    itineraryData, 
    itinerary_DeleteColumn,
    //
    handleChecklist_addChecklist,
    checkListData,
    handleCheckList_CheckStatus,
    handleCheckList_addDescription,
    handleCheckList_DeleteColumn,
    handleDocuments_addDescription
  } = useCreateItineraryPlanTrip()
  
  return (
    <div className='container_p-Checklist_TD'>

      {
        isMobile &&
        <div style={{marginTop:'12px'}}></div>
      }

      {  
        !different_design && itineraryData &&

        itineraryData.map((data, index) => {
          if(data.topic_id === topic_id){
            return(
              <div>

                <div
                className='conatiner_p-head-Checklist_TD'
                > 

                  <div
                  className='conatiner_t-displayName_Icon-head-Checklist_TD'
                  >
                    <div
                    className='text-head-Checklist_TD'
                    >
                      {data.display_name}
                    </div> 

                    <div>
                      <img
                      src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/checklist_icon_1.png'
                      className='icon-checklist-Checklist_TD'
                      />
                    </div>      
                  </div> 

                  <div>
                    <button 
                      onClick={()=>{itinerary_DeleteColumn(data.topic_id, data.day_id)}}
                      className='icon-delete-Checklist_TD'
                    >
                    <RiDeleteBinLine />
                    </button>
                  </div>     

                </div>   

                {/* //*Note Description */}
                {/*//? Input*/}
                <div> 
                  <input 
                    value={data.description}
                    onChange={(event)=>{
                      handleDocuments_addDescription(event, data.id_documents, data.topic_id, data.day_id)
                      setSaveChangesStatus(true)
                    }}
                    placeholder='enter description... (Max length: 50)'
                    maxLength={50}
                    type='text'
                    // className='input-1-description-Checklist_TD'
                    className='input-1-searchPlaceName-GoogleSearchPlaces'
                  />
                </div>

                <div>
                  <button 
                    onClick={()=>{handleChecklist_addChecklist(data.topic_id, data.day_id, data.track_check_list)}}
                    className='conatiner_p-addCheckList-Checklist_TD'
                  >
                    <div
                    className='text-addCheckList-Checklist_TD'
                    >
                      Add Checklist
                    </div>

                    <div>
                      <img
                        src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_1.png'
                        className='icon-add-Checklist_TD'
                      />
                    </div>

                  </button>
                </div>   

                {/*//! Popup - Checklist */}
                <div
                className='container_p-Popup-Checklist_TD'
                >
                  {
                    checkListData && 
                    checkListData.map((CLData, TDIndex) => {
                      if(CLData.topic_id === topic_id){
                        return(
                          <div
                          className={CLData.description ? 'container_s-active-Popup-Checklist_TD' : 'container_s-unActivePopup-Checklist_TD'}
                          >
                            {/*//* Conatiner 1 */}
                            <div
                            className='container_t-1-Popup-Checklist_TD'
                            >
                              {/* <div> */}
                              {
                                CLData.check_status ?
                                (
                                <div>
                                  <button
                                    onClick={()=>{
                                      handleCheckList_CheckStatus(false, CLData.id_check_list, CLData.topic_id, CLData.day_id)
                                      setSaveChangesStatus(true)
                                    }}
                                    className='button-check-Popup-Checklist_TD'
                                  >
                                    ✔️
                                  </button>
                                </div> 
                                )
                                :
                                (
                                <div>
                                  <button
                                    onClick={()=>{
                                      handleCheckList_CheckStatus(true, CLData.id_check_list, CLData.topic_id, CLData.day_id)
                                      setSaveChangesStatus(true)
                                    }}
                                    disabled={!CLData.description}
                                    className={CLData.description ? 'button-active-unCheck-Popup-Checklist_TD' : 'button-unActive-unCheck-Popup-Checklist_TD'}
                                  >
                                    
                                  </button>
                                </div> 
                                )
                              }

                              <div
                              style={{width:'86%'}}
                              > 
                                <input 
                                  value={CLData.description}
                                  onChange={(event)=>{
                                    handleCheckList_addDescription(event, CLData.id_check_list, CLData.topic_id, CLData.day_id, CLData.description)
                                    setSaveChangesStatus(true)
                                  }}
                                  placeholder='Maxlength - 50'
                                  maxLength={50}
                                  type='text'
                                  className='input-1-checklist-description-Popup-Checklist_TD'
                                />
                              </div> 

                              {/* </div> */}

                            </div>   
                            
                            {/*//* Conatiner 1  */}
                            <div>
                              <button 
                                onClick={()=>{
                                  handleCheckList_DeleteColumn(CLData.id_check_list, CLData.day_id, CLData.topic_id)
                                  setSaveChangesStatus(true)
                                }}
                                className='icon-delete-Popup-Checklist_TD'
                              >
                                <RiDeleteBinLine />
                              </button>
                            </div>   

                          </div>  
                        )
                      }
                    })
                  }
                </div>
                
              </div>   
            )
          }
        })
      }

    </div>
  )
}

export default Checklist_TD










