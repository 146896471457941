import React, {useState} from 'react'

//import scss
import './Note_Itinerary_TO.scss'

//toptap - rick text editor
import TipTap_ReadOnly from '../../../../../Global Tool - Plan Trip/Notes/TestingLexical/TipTap_ReadOnly';

//import icon
import { IoMdCloseCircle } from "react-icons/io";



function Note_Itinerary_TO(props) {

  const {itineraryData, visibility_type, different_design, topic_type, topic_id} = props;

  const[activeViewMore, setActiveViewMore] = useState(false)
  
  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
    setActiveViewMore(false);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };

  return (
    <div
    className='container_p-Itinerary_TO_Components'
    >

      { 
        !different_design && itineraryData &&

        itineraryData.map((data, index) => {
          if(data.topic_id === topic_id){
            return(
              <div key={index}
              className='container_s-Itinerary_TO_Components'
              >


              {/*//! #1  */}
              {/* topic type and delete topic */}
              <div className='conatiner_p-head-Itinerary_TO_Components'>
    
                {/* <div className='conatiner_t-displayName_Icon-head-Itinerary_TO_Components'> */}
                  <div
                  className='conatiner_s-1-head-Itinerary_TO_Components'
                  >
                    <div className='text-head-Itinerary_TO_Components'>
                      {data.display_name}
                    </div>

                    <div>
                      <img
                        src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/note_icon_6.png'
                        className='icon-checklist-Itinerary_TO_Components'
                      />
                    </div>
                  </div> 

                {/* </div> */}

              </div>

              
              {/*//! #2  */}
              <div className='input-1-description-Itinerary_TO_Components'>
                {data.description}
                {/* {data.saved_place_id} */}
              </div>

              
              {/* //! #3  */}
              {/* Note */}
              {
                (data.notes_data) &&
                <div>

                  {/* Container - 1 | Note Menu */}
                  <div className='conatiner_p-addNote-Itinerary_TO_Components'>

                    <button
                    onClick={()=>{{
                      setActiveViewMore(true)
                      openPopup()
                    }}}
                    className='button-addNote-Itinerary_TO_Components'
                    style={{marginLeft:'-0px'}}
                    >
                      View full note 
                    </button>

                  </div>  

                  <button 
                  onClick={()=>{{
                    setActiveViewMore(true)
                    openPopup()
                  }}}
                  className='container_p-note-singleNote-displayNotes-Note_TD'
                  >
                  
                    <div style={{ background: 'white' }}
                      className='container_t-note-singleNote-displayNotes-Itinerary_TO_Components'>
                      <TipTap_ReadOnly content={data.notes_data} />
                    </div>

                  </button>

                </div>
              }

              {/*//!popup */}
              {
                activeViewMore && 
                <div>

                  <div className='popup-background-1-Entities_TD'
                    onClick={()=> closePopup()}
                  >
                  </div>

                  <div className='popup_p-addNote-Entities_TO_Popup'>
                    <div className='popup_s-addNote-Entities_TO_Popup'>

                      <button
                        onClick={()=>{
                          closePopup()
                          }}
                        className='button-close-popup-AddTripDetails_TO'
                      >
                        <IoMdCloseCircle />
                      </button>

                      <div className='input-1-description-Itinerary_TO_Components'>
                        {data.description}
                      </div>

                      <div style={{ background: 'white' }}
                        className='container_t-note-popup-Itinerary_TO_Components'
                      >
                        <TipTap_ReadOnly content={data.notes_data} />
                      </div>

                    </div> 
                  </div>    

                </div>
              }




              </div> 
            )
          }
        })  
      }        
              

    </div>
  )
}

export default Note_Itinerary_TO