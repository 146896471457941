import React, {useState, useEffect, useRef} from 'react'

//import css
import './DayWise.scss';

//importing react context
import { useAiCreateItinerary } from '../../../Create Itinerary - Result/Manage/Context/Ai_CreateItineraryContext';
//?for view more touirst places data...
import { useAiCreateItinerary_2 } from '../../../Create Itinerary - Result/Manage/Context/Ai_CreateItineraryContext_2';

//import icons
import { RxDotsVertical } from "react-icons/rx";
import { IoMdCloseCircle } from "react-icons/io";
import {MdOutlineModeOfTravel} from "react-icons/md";
import { BsStars } from "react-icons/bs";
import { BiTrip } from "react-icons/bi";
import { Rating } from 'react-simple-star-rating';
import { PiMountainsFill } from "react-icons/pi";
import { RiPinDistanceFill } from "react-icons/ri";
import { MdOutlineTimeToLeave } from "react-icons/md";

import TouristPlaces_VM from '../../../../../Plan Trip/Plan Trip - Components/Trip Planning/Main/View More/TouristPlaces_VM';


function DayWise() {

  const {
    //
    getRoute_buttonStatus, setGetRoute_buttonStatus,
    //
    handle_CallGetRoute,
    //*itinerary sequence and route data
    optimizedItinerary, 
    optimizedItineraryRoute, 

    //*createTrip
    createTripId,
    tripName, setTripName, tripWindowStatus, setTripWindowStatus,
    handleViewTrip, handleCreateTrip,

    //*Day Wise
    activeDay, setActiveDay,
    exploreDuration,
    travelDuration,
    stateName,
    destinationName,
    itineraryDuration,
    tripSeason,
    //? tourist places
    savedPlacesData,
    //map
    setHoverData,
    handleHover
  } = useAiCreateItinerary()

  const {
    //!States
    activeId_viewMore_touristPlaces, setActiveId_viewMore_touristPlaces,
    viewMoreData_About,
    viewMoreData_Review,
    viewMoreData_PlaceInfo,
    viewMoreData_touristPlaces,
    viewMoreLoading, setViewMoreLoading,
    //!Function(s)
    handle_ViewMore_TouristPlaces
  } = useAiCreateItinerary_2()

  const [dayIdIndex, setDayIdIndex] = useState([]);

  useEffect(() => {
    const indices = [];
    const daySet = new Set();

    optimizedItinerary.forEach((item, index) => {
      if (!daySet.has(item.day)) {
        daySet.add(item.day);
        indices.push(index);
      }
    });

    setDayIdIndex(indices);
  }, [optimizedItinerary]);


  const parseTimings = (data) => {
    // Remove the "Suggest new hours" part
    const cleanedData = data.replace('Suggest new hours', '');

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const regex = new RegExp(`(${days.join('|')})`, 'g');
    const parsedTimings = cleanedData.split(regex).filter(Boolean);

    // Combine the day names with their corresponding timings
    const formattedTimings = [];
    for (let i = 1; i < parsedTimings.length; i += 2) {
      formattedTimings.push(parsedTimings[i - 1] + ' ' + parsedTimings[i]);
    }

    return formattedTimings;
  };

  const[viewTimmingId, setViewTimmingId] = useState()

  const handleMouseOver = (index) => {
    // console.log("Hovered index:", index);
    setViewTimmingId(index);
  };


  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
      setTripWindowStatus(false);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  const convertToHoursAndMinutes = (decimalHours) => {
    const hours = Math.floor(decimalHours); // Extract the integer part (hours)
    const minutes = Math.round((decimalHours - hours) * 60); // Convert fractional part to minutes
    return `${hours} hour${hours !== 1 ? 's' : ''} and ${minutes} minute${minutes !== 1 ? 's' : ''}`;
  };

  const roundToTwoDecimals = (number) => {
    return number.toFixed(2); // Round to two decimal places and return as a string
  };
  
  const modified_state_name = stateName && stateName.toLowerCase().replace(/\s+/g, '_');


//!scroll - active on the map - place in the center

// const [hoverData, setHoverData] = useState(null);
const containerRefs = useRef([]);

  const isElementInCenter = (element) => {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // Check if the element's center is in the middle of the viewport
    return rect.top >= windowHeight / 3 && rect.bottom <= (2 * windowHeight) / 3;
  };

  useEffect(() => {
    const handleScroll = () => {
      containerRefs.current.forEach((element, index) => {
        if (isElementInCenter(element)) {
          // Trigger hover when the element is in the center
          const placeData = savedPlacesData[index];
          handleHover("clicked", placeData.lat, placeData.lng, placeData.name, "iconName", index);
        } else {
          setHoverData(null); // Remove hover if not centered
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup
    };
  }, [savedPlacesData]);




  //is mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initialize with the current width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 699); // Update state on window resize
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <div>

      { !isMobile && 
        // getRoute_buttonStatus === "show" &&
        <div>
          {
            getRoute_buttonStatus === "show" &&
            <button onClick={()=>handle_CallGetRoute()}
            className='button-optimizedRoute-DayWise'
            > 
              <BsStars /> Get Optimized Route <BiTrip />
            </button>
          }
          &nbsp; &nbsp;

          {
            createTripId ? 
            <button
            className='button-createItinerary-DayWise'
            onClick={()=>{handleViewTrip(createTripId)}}
            >
              {/* Create Trip <MdOutlineModeOfTravel /> */}
              View Your Trip - {tripName} <PiMountainsFill />
            </button>
            :
            <button
            className='button-createItinerary-DayWise'
            onClick={()=>{
              setTripWindowStatus(true)
              openPopup()
            }}
            >
              Create Trip <MdOutlineModeOfTravel />
            </button>
          }

        </div>
      }


      {
        tripWindowStatus && 
        <div>

          <div className='popup-background-1-Entities_TD'
            onClick={()=>closePopup()}>
          </div>

          <div className='popup_p-3-Entities_TO_Popup'>
            <div className='popup_s-addNote-Entities_TO_Popup'>

              <div
              className='text-1-createI-DayWise'
              >
                Create around your itinerary, where you can add/remove more places, create itinerary, and more...
              </div>  

              <div>
                <input 
                onChange={(event)=>{setTripName(event.target.value)}}
                value={tripName}
                type='text'
                maxLength={20}
                className='input-createI-DayWise'
                />
              </div>   

                {
                  tripName && 
                  <div
                  className='input-length-DayWise'
                  >
                    {tripName.length}/20
                  </div>
                }

              <div>
                { tripName &&
                  <button 
                  onClick={()=>{handleCreateTrip()}}
                  className='button-done-DayWise'
                  >
                    Done
                  </button>
                }
                <button 
                onClick={()=>{closePopup()}}
                className='button-close-DayWise'
                >
                  Close 
                </button>
              </div>  

            </div>
          </div>  
        </div>   
      }    


      
      <br></br>

      <div
      className='conatiner_p-Places-DayWaise'
      >
        {
          (optimizedItinerary && savedPlacesData) && 
          optimizedItinerary.map((data, index) => {
            // if(data.day === activeDay){
              return(
                <div key={index}
                className='conatiner_s-Places-DayWaise'
                > 

                  {/* {handleTid(data.day)} */}

                  {
                    dayIdIndex.includes(index) &&

                    <div
                    className='heading-1-Day-DayWise'
                    >
                      Day {data.day}
                    </div>
                  }

                  {/* {
                    handleTid(data.day) ? (
                      <div className='heading-1-Day-DayWise' key={data.day}>
                        {data.day}
                      </div>
                    ) : null
                  } */}

                  
                  {/* {data.day} */}

                  {/*//*Container #1  */}
                  {
                    index > 0 &&
                    <div
                    className='icon-dottedLine-Places-DayWaise'
                    >
                      <RxDotsVertical />
                    </div>
                  }

                  {/* {data.source} {data.d1} {data.dr1} */}

                  {optimizedItineraryRoute && 
                  
                    optimizedItineraryRoute.map((route, route_index) => {
                      if(index > 0 && route_index === index-1){
                        return(
                          <div 
                          className='container_p-Dist_Dur-DayWise'
                          >
                            <div
                            className='container_t-1-Dist_Dur-DayWise'
                            >
                              <RiPinDistanceFill /> &nbsp;
                              {roundToTwoDecimals(route.d1)} kms
                            </div>
                            
                            <div
                            className='container_t-2-Dist_Dur-DayWise'
                            >
                              <MdOutlineTimeToLeave /> &nbsp;
                              {convertToHoursAndMinutes(route.dr1)}
                            </div>

                          </div>
                        )
                      }
                    })
                  }

                  {
                    index > 0 &&
                    <div
                    className='icon-dottedLine-Places-DayWaise'
                    >
                      <RxDotsVertical /> 
                    </div>
                  }

                  {/*//*Container #2  */}
                  {
                    <div
                    className='text-1-number-Places-DayWaise'
                    >
                      {index+1}.

                      {/* duration: {data.duration}  <br></br><br></br>
                            distance: {data.distance} <br></br><br></br> */}

                    </div>
                  }

                  {/*//*Container #3  */}
                  {
                    savedPlacesData.map((placeData, index2) => {
                      if(data.sq_source === placeData.sn){
                        return(
                          <div key={index2}
                          ref={(el) => (containerRefs.current[index2] = el)}
                          className='conatiner_p-Info-Places-DayWaise'
                          onMouseOver={()=>{handleHover("clicked", placeData.lat, placeData.lng, placeData.name, "iconName", index)}}
                          onMouseOut={()=>{setHoverData()}}
                          >

                            {/*//*Images and content #3.1 */}
                            <div
                            className='conatiner_s-1-Info-Places-DayWaise'
                            >

                              {/*//Images and ... #3.1.1 */}
                              <div
                              className='conatiner_t-1-Info-Places-DayWaise'
                              >

                              {/* {`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${placeData.sn}-01.jpg`} */}

                                <img 
                                  className="image-Card-Places-DayWise_Web" 
                                  src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${placeData.sn}-01.jpg`}
                                  onError={(e) => { e.target.src = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/create_itinerary_5.jpeg'; }}

                                  alt="Image 1" 
                                />

                                <div
                                className='conatiner_t-ratings_review-SavedPlacesCI'
                                >

                                  <div className='heading-rating-DayWise'>
                                    ({placeData.rating})
                                  </div>

                                  <div 
                                  className='heading-2-placeName-SavedPlacesCI'
                                  >
                                      <Rating
                                        initialValue={placeData.rating}
                                        size={18}
                                        emptyColor="#ccc"
                                        fillColor="gold"
                                        strokeColor="gold"
                                        strokeWidth={0}
                                        allowFraction={true}
                                        readonly
                                      />  
                                  </div>

                                  <div className='heading-3-placeName-SavedPlacesCI'>
                                    {
                                      placeData.review_count ? placeData.review_count.toLocaleString('en-IN') : 'N/A'
                                    }
                                  </div>

                                </div>  

                              </div>  

                              {/*//Images and ... #3.1.2 */}
                              <div
                              className='conatiner_t-2-Info-Places-DayWaise'
                              >
                                <div className="heading-1-placeName-info-Places-DayWaise">
                                  {placeData.name}
                                </div>

                                <div
                                className='text-1-category-info-Places-DayWaise'
                                >
                                  #{placeData.category}
                                </div>

                                {
                                  (placeData.explore_duration && placeData.explore_duration != "") &&
                                  <div 
                                  className='text-1-exploreDuration-info-Places-DayWaise'
                                  >
                                    Explore in: {placeData.explore_duration} hrs
                                  </div>
                                }

                                {
                                  placeData.timings &&
                                  <button
                                  onMouseOver={()=>{setViewTimmingId(index)}}
                                  onMouseOut={()=>{setViewTimmingId(null)}}
                                  className='button-viewTimming-DayWaise'
                                  >
                                    view timmings
                                  </button>
                                }
                                
                {  viewTimmingId !== null && (viewTimmingId === index) && 
                    placeData.timings &&
                    <div
                    className='container_p-timming-DayWaise'
                    >

                      <div
                      className='heading-1-DayWaise'
                      > 
                        Timmings
                      </div> 

                      <div
                      className='conatiner_t-timming-DayWaise'
                      >
                      {parseTimings(placeData.timings).map((timing, index) => (
                          <div key={index}
                          className='time-timming-DayWaise'
                          >
                              {timing}
                          </div>
                      ))}
                      </div>

                    </div> 
                }  


                                {/*//View More  */}
                                <button className="button-viewMore-card-DayWaise" 
                                  onClick={(e) => {
                                    e.stopPropagation(); 
                                    handle_ViewMore_TouristPlaces(placeData.sn, stateName);
                                  }}>
                                      View More
                                </button>

                                {/* <div
                                className='conatiner_s-2-Info-Places-DayWaise'
                                >
                                  <button className="button-viewMore-card-SavedPlacesCI" 
                                  onClick={(e) => {
                                    e.stopPropagation(); 
                                    // handle_ViewMore_TouristPlaces(data.sn);
                                  }}>
                                      View More
                                  </button>
                                </div> */}

                              </div>


                            </div>

                          </div>   
                        )
                      }
                    })
                  }
                </div>   
              )
            // }
          })
        }
      </div>

      <div style={{height:'200px'}}></div>

      {
        viewMoreLoading && 
        <div className="loading-overlay">
          <div className="spinner">
            {/* You can add a loading spinner here */}
          </div>
        </div>
      }

      {
        activeId_viewMore_touristPlaces &&
    
        <TouristPlaces_VM 
          viewMoreData_touristPlaces={viewMoreData_touristPlaces}
          viewMoreData_About={viewMoreData_About}
          viewMoreData_Review={viewMoreData_Review}
          viewMoreData_PlaceInfo={viewMoreData_PlaceInfo}
          activeId_viewMore_touristPlaces={activeId_viewMore_touristPlaces}
          setActiveId_viewMore_touristPlaces={setActiveId_viewMore_touristPlaces}
          stateName={stateName}
        />

      }

    </div>
  )
}

export default DayWise