import React, {useRef, useState, useEffect} from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

// importing map
import {MapContainer, Tooltip, TileLayer, Marker, Popup, useMapEvent, useMap, useMapEvents, circle, Circle, GeoJSON, Polyline} from 'react-leaflet'

//
import L from "leaflet";

//
import { Rating } from 'react-simple-star-rating';

//import css
import './Map.scss'

// importing icons 
import {
  active_tourist_places, hover_tourist_places, saved_tourist_places, map_pin_1, map_pin_2, map_pin_2_s, map_pin_3, place_1_n, place_1_l, saved_location,
  //for hotels
  hotel_1_colored, hotel_active_1_colored, hotel_1_colored_small 
} from '../../../../Manage - Plan Trip/Icons/MapIcons'

//importing react context
//--TravelCategories_Context
import {TravelCategories_Context} from '../../Manage/Context/TravelCategories_Context'
import {TravelCategories_2_Context} from '../../Manage/Context/TravelCategories_2_Context'
import {TravelCategories_3_Context} from '../../Manage/Context/TravelCategories_3_Context'
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip'


const openPopup = () => {
  document.body.style.overflow = 'hidden'; // Disable scrolling on the body
};

const accessToken = 'pk.eyJ1IjoicmlzaGFiaDY5IiwiYSI6ImNseHU5dTNuMzBnaGYycXNkZTRhODUxaTIifQ.o7XiAXhwt0J2lk9cQfLRrw';

function Map() {

  const mapRef = useRef(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  //using react context
  const {
    active_places_map, saved_touristPlaces_data, activeTravelCategory, lat, lng, zoom, setLat, setLng, setZoom, touristPlacesData, activeTouristPlaceNavigation, activeDestination, setActiveTravelCategory,
    //
    saved_TravelCategoriesPlaces_data,

    //  
    mustVisit_touristPlaces_data, famous_touristPlaces_data, interesting_touristPlaces_data, categories_touristPlaces_data,

    //map
    fitAllLocations, setFitAllLocations, closePopup_Status, setClosePopup_Status,

    //hotel
    hotelData, setHotelData,
    active_hotelsData, setActive_hotelsData,
    saved_hotels_data, setSaved_hotels_data,
    districtDataLoaded_status_hotels, setDistrictDataLoaded_status_hotels,
  } = TravelCategories_Context();

  const {
    districtCity_data,
    //
    sortHotelPrice,
    //hotels
    hoverHotelData_map, hotelClickedStatus, bestDealData, setViewMoreStatus, handleGetReviewdData, setActiveViewPrices_Limited, setView_prices_popup, setViewMore_id,
    //
    hotelInternalNavigation, handle_loadHotelsData
  } = TravelCategories_2_Context();
  
  const {
    active_hover_place_map, handle_UnSave_Places, handle_ViewMore_TouristPlaces,
    // 
    //*route
    distance_start_lat, distance_start_lng, distance_end_lat, distance_end_lng, optimizedItineraryRoute, distance_start_name, distance_end_name, 
    loading_optimizedRoute_status, setLoading_optimizedRoute_status,
    //
    handle_calculate_route,

  } = TravelCategories_3_Context();

  const {tripDestinationName, tripDestinationType, destinationLat, destinationLng} = useValidatePlanTrip();
  
  //handle map - drag
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView([lat, lng]);
    }
  }, [lat, lng, zoom])



  //locations
  const handleFitAllLocations = () => {
    const map = mapRef.current;
    if (map) {
      const allLocations = [
        // ...active_hover_place_map,
        // ...districtCity_data,
        ...active_places_map,
        // { lat: destinationLat, lng: destinationLng }
      ].filter(loc => loc.lat && loc.lng); // Filter out invalid locations
      if (allLocations.length > 0) {
        const bounds = L.latLngBounds(allLocations.map(loc => [loc.lat, loc.lng]));
        map.fitBounds(bounds);
      }
    }
    setFitAllLocations(false)
    closeAllPopups()
  };

  useEffect(() => {
    handleFitAllLocations(); 
  }, [fitAllLocations])

  //locations
  const handleFitAllLocations_2 = () => {
    const map = mapRef.current;
    if (map && activeTravelCategory === "hotels") {
      const allLocations = [
        // ...active_hover_place_map,
        // ...districtCity_data,
        ...hotelData,
        // { lat: destinationLat, lng: destinationLng }
      ].filter(loc => loc.lat && loc.lng); // Filter out invalid locations
      if (allLocations.length > 0) {
        const bounds = L.latLngBounds(allLocations.map(loc => [loc.lat, loc.lng]));
        map.fitBounds(bounds);
      }
    }

    if (map && activeTravelCategory === "tourist_places") {
      const allLocations = [
        // ...active_hover_place_map,
        // ...districtCity_data,
        ...active_places_map,
        // { lat: destinationLat, lng: destinationLng }
      ].filter(loc => loc.lat && loc.lng); // Filter out invalid locations
      if (allLocations.length > 0) {
        const bounds = L.latLngBounds(allLocations.map(loc => [loc.lat, loc.lng]));
        map.fitBounds(bounds);
      }
    }
    setFitAllLocations(false)
    closeAllPopups()
  };

  useEffect(() => {
    handleFitAllLocations_2(); 
  }, [activeTravelCategory, activeDestination])


  //close
  const closeAllPopups = () => {
    const map = mapRef.current;
    if (map) {
      map.eachLayer((layer) => {
        if (layer instanceof L.Popup) {
          layer.close();
        }
      });
    }
    setClosePopup_Status(false)
  };

  useEffect(() => {
    closeAllPopups()
  }, [closePopup_Status])




  const GradientPolyline2 = ({ coordinates }) => {
    const borderColor = '#FFFFFF'; // Dark blue border color
    const interiorColor = '#408FFF'; // Light blue interior color

    return (
        <>
            {/* Dark blue border */}
            <Polyline
                positions={coordinates}
                weight={5} // Thickness for the border
                color={borderColor}
                lineJoin="round"
                lineCap="round"
            />
            {/* Light blue interior */}
            <Polyline
                positions={coordinates}
                weight={4} // Thickness for the interior
                color={interiorColor}
                lineJoin="round"
                lineCap="round"
            />
        </>
    );
  };


  return(
    <div>

      <div
      className='conatiner_p-data-map'
      >

        <button
        onClick={()=>{handleFitAllLocations()}}
        className='button-fitAll-Map_Hotels'
        >
          Fit All
        </button>

        <button 
        onClick={()=>{closeAllPopups()}}
        className='button-fitAll-Map_Hotels'
        >Close All Popup</button>

        {
          loading_optimizedRoute_status &&
          <div className="spinner-container">
            <div className="spinner"></div> {/* Subtle loading animation */}
          </div>
        }


        {
          optimizedItineraryRoute && optimizedItineraryRoute[0] && 
          <div className="route-info-container">

            {distance_start_name && distance_end_name &&
              <div className="distance-info">
                Distance between  <br></br>
                <u>{distance_start_name.split(" ").slice(0, 2).join(" ")}</u> -
                <u> 
                {distance_end_name.split(" ").slice(0, 2).join(" ")}</u>:
              </div>
            }
          
            {optimizedItineraryRoute[0].d1.toFixed(2) &&
              <div className="route-detail">
                <div className="route-label"># Distance</div>
                <div className="route-value">{optimizedItineraryRoute[0].d1.toFixed(2)} kms</div>
              </div>
            }
          
            {optimizedItineraryRoute[0].dr1.toFixed(2) &&
              <div className="route-detail">
                <div className="route-label"># Duration</div>
                <div className="route-value">{optimizedItineraryRoute[0].dr1.toFixed(2)} hrs</div>
              </div>
            }
          
            <button className="clear-btn"
              onClick={() => handle_calculate_route(null, null, null, "clear")}>
              Clear
            </button>
          
          </div>
        
        }


        {/* hotelLat:{hotelLat}
        hotelLng:{hotelLng} */}

      </div>
      
      <MapContainer  
        // className={activeTravelCategory !== "tourist_places" ? 'Map-2-TripPlanning-Map' : 'Map-TripPlanning-Map'}
        className="Map-TripPlanning-Map" 
        center = {[lat, lng]} 
        zoom={7} 
        ref={mapRef}

        // dragging={true}
        // inertia={true}
        // inertiaDeceleration={100}
        // inertiaMaxSpeed={20000000}
        // easeLinearity={0.1}
      > 

      {/* <TileLayer 
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' 
      /> */}

      <TileLayer
        url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${accessToken}`}
        attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
      />





        {/*//* Display Route */}
        {
          optimizedItineraryRoute && optimizedItineraryRoute.map(data => {
          const processCoordinates = (coordinates) => {
              try {
                  return (coordinates).map(coord => [coord[1], coord[0]]);
              } catch (error) {
                  console.error('Error parsing coordinates:', error);
                  return null;
              }
          };

          const processedCoordinates = processCoordinates(data.crd2 ? data.crd2 : data.crd1);

            return processedCoordinates && (

                <GradientPolyline2 coordinates={processedCoordinates} />

            );
          })
        }


        {
            destinationLat && destinationLng && 
          (
            <Marker
              position={[(destinationLat), (destinationLng)]}
              // zoom={zoom}
              icon={map_pin_1}
            >

              <Tooltip 
                direction="bottom" permanent
                className='container-tooltip-destination-Map_Hotels'
              >
                <div
                className='text-tooltip-destination-Map_Hotels'
                >
                  {tripDestinationName}
                </div>
              </Tooltip>

            </Marker>
          )
        }

        {/* hover places/active categories/selected categories/  */}        
        { (active_hover_place_map) &&
          active_hover_place_map.map((data, index) => {
            return(
              <Marker
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={saved_location}
                zIndexOffset={199999999}
              >

                <Tooltip 
                  direction="bottom" permanent
                  className='hoverToolTipOverall'
                >
                  {data.name}
                </Tooltip>

              </Marker>
            )
          })
        } 


        {/* {
            (saved_destinations_data && saved_destinations_data.length > 0) && 
          (
            <Marker
              position={[(destinationLat), (destinationLng)]}
              // zoom={zoom}
              icon={map_pin_1}
            >

              <Tooltip 
                direction="bottom" permanent
                className='container-tooltip-destination-Map_Hotels'
              >
                <div
                className='text-tooltip-destination-Map_Hotels'
                >
                  {tripDestinationName}
                </div>
              </Tooltip>

            </Marker>
          )
        } */}
        

        {/* { (saved_destinations_data && saved_destinations_data.length > 0) &&
          saved_destinations_data.map((data, index) => {
            return(
              <Marker key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                // icon={map_pin_2}
              >
                <Popup>
                  <div className="popup-content-popup-map">
                    <div className="name-popup-map">{data.name}</div>
                    
                    <button 
                      className="view-more-btn-popup-map"
                      onClick={() => {
                        handle_ViewMore_TouristPlaces(data.sn);
                        openPopup();
                      }}
                    >
                      View More
                    </button>

                    {data.name !== distance_start_name && (
                      <div className="distance-section-popup-map">
                        <div className="find-distance-text-popup-map">Find Distance:</div>
                        {!distance_start_lat ? (
                          <button
                            className="start-btn-popup-map"
                            onClick={() => handle_calculate_route(data.lat, data.lng, data.name, "start")}
                          >
                            Start
                          </button>
                        ) : (
                          <button
                            className="find-distance-btn-popup-map"
                            onClick={() => handle_calculate_route(data.lat, data.lng, data.name, "end")}
                          >
                            Find Distance
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </Popup>

                <Tooltip 
                direction="left" 
                permanent 
                className='customIcon-ToolTip-active'     
                >
                  0{index+1}
                </Tooltip>  

              </Marker>
            )
          })
        } */}


        { activeTouristPlaceNavigation === "categories" && activeTravelCategory === "tourist_places" && categories_touristPlaces_data &&
          categories_touristPlaces_data.map((data, index) => {
            return(
              saved_touristPlaces_data.some((item=> item.id === data.sn))
              ?
              null 
              :
              <Marker key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={map_pin_2_s}
              >
                <Popup>
                  <div className="popup-content-popup-map">
                    <div className="name-popup-map">{data.name}</div>
                    
                    <button 
                      className="view-more-btn-popup-map"
                      onClick={() => {
                        handle_ViewMore_TouristPlaces(data.sn);
                        openPopup();
                      }}
                    >
                      View More
                    </button>

                    {data.name !== distance_start_name && (
                      <div className="distance-section-popup-map">
                        <div className="find-distance-text-popup-map">Find Distance:</div>
                        {!distance_start_lat ? (
                          <button
                            className="start-btn-popup-map"
                            onClick={() => handle_calculate_route(data.lat, data.lng, data.name, "start")}
                          >
                            Start
                          </button>
                        ) : (
                          <button
                            className="find-distance-btn-popup-map"
                            onClick={() => handle_calculate_route(data.lat, data.lng, data.name, "end")}
                          >
                            Find Distance
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </Popup>

                <Tooltip 
                direction="bottom" 
                permanent 
                className='customIcon-ToolTip-active'     
                >
                  0{index+1}
                </Tooltip>  

              </Marker>
            )
          })
        }



        { activeTouristPlaceNavigation !== "categories" && activeTravelCategory === "tourist_places" && mustVisit_touristPlaces_data &&
          mustVisit_touristPlaces_data.map((data, index) => {
            return(
              saved_touristPlaces_data.some((item=> item.id === data.sn))
              ?
              null 
              :
              <Marker key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={map_pin_2_s}
              >
                <Popup>
                  <div className="popup-content-popup-map">
                    <div className="name-popup-map">{data.name}</div>
                    
                    <button 
                      className="view-more-btn-popup-map"
                      onClick={() => {
                        handle_ViewMore_TouristPlaces(data.sn);
                        openPopup();
                      }}
                    >
                      View More
                    </button>

                    {data.name !== distance_start_name && (
                      <div className="distance-section-popup-map">
                        <div className="find-distance-text-popup-map">Find Distance:</div>
                        {!distance_start_lat ? (
                          <button
                            className="start-btn-popup-map"
                            onClick={() => handle_calculate_route(data.lat, data.lng, data.name, "start")}
                          >
                            Start
                          </button>
                        ) : (
                          <button
                            className="find-distance-btn-popup-map"
                            onClick={() => handle_calculate_route(data.lat, data.lng, data.name, "end")}
                          >
                            Find Distance
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </Popup>

                <Tooltip 
                direction="bottom" 
                permanent 
                className='customIcon-ToolTip-active'     
                >
                  0{index+1}
                </Tooltip>  

              </Marker>
            )
          })
        }
        

        {activeTouristPlaceNavigation !== "categories" &&  activeTravelCategory === "tourist_places" && 
          famous_touristPlaces_data &&
          famous_touristPlaces_data.map((data, index) => {
            return(
              saved_touristPlaces_data.some((item=> item.id === data.sn))
              ?
              null 
              :
              <Marker key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={map_pin_2_s}
              >
                <Popup>
                  <div className="popup-content-popup-map">
                    <div className="name-popup-map">{data.name}</div>
                    
                    <button 
                      className="view-more-btn-popup-map"
                      onClick={() => {
                        handle_ViewMore_TouristPlaces(data.sn);
                        openPopup();
                      }}
                    >
                      View More
                    </button>

                    {data.name !== distance_start_name && (
                      <div className="distance-section-popup-map">
                        <div className="find-distance-text-popup-map">Find Distance:</div>
                        {!distance_start_lat ? (
                          <button
                            className="start-btn-popup-map"
                            onClick={() => handle_calculate_route(data.lat, data.lng, data.name, "start")}
                          >
                            Start
                          </button>
                        ) : (
                          <button
                            className="find-distance-btn-popup-map"
                            onClick={() => handle_calculate_route(data.lat, data.lng, data.name, "end")}
                          >
                            Find Distance
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </Popup>

                <Tooltip 
                direction="bottom" 
                permanent 
                className='customIcon-ToolTip-active'     
                >
                  00{index+1}
                </Tooltip>  

              </Marker>
            )
          })
        }


        {/* //places that are not saved */}
        { activeTouristPlaceNavigation !== "categories" &&  activeTravelCategory === "tourist_places" &&
          interesting_touristPlaces_data &&
          interesting_touristPlaces_data.map((data, index) => {
            return(
              saved_touristPlaces_data.some((item=> item.id === data.sn))
              ?
              null 
              :
              <Marker key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={map_pin_2_s}
              >
                <Popup>
                  <div className="popup-content-popup-map">
                    <div className="name-popup-map">{data.name}</div>
                    
                    <button 
                      className="view-more-btn-popup-map"
                      onClick={() => {
                        handle_ViewMore_TouristPlaces(data.sn);
                        openPopup();
                      }}
                    >
                      View More
                    </button>

                    {data.name !== distance_start_name && (
                      <div className="distance-section-popup-map">
                        <div className="find-distance-text-popup-map">Find Distance:</div>
                        {!distance_start_lat ? (
                          <button
                            className="start-btn-popup-map"
                            onClick={() => handle_calculate_route(data.lat, data.lng, data.name, "start")}
                          >
                            Start
                          </button>
                        ) : (
                          <button
                            className="find-distance-btn-popup-map"
                            onClick={() => handle_calculate_route(data.lat, data.lng, data.name, "end")}
                          >
                            Find Distance
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </Popup>

                <Tooltip 
                direction="bottom" 
                permanent 
                className='customIcon-ToolTip-active'     
                >
                  000{index+1}
                </Tooltip>  
        
              </Marker>
            )
          })
        }




        {/* //! Saved Tourist Places */}
        { saved_touristPlaces_data &&
          saved_touristPlaces_data.map((savedPlace, index) => {
            return(

              <Marker
                position={[(savedPlace.lat), (savedPlace.lng)]}
                zoom={zoom}
                icon={place_1_l} 
              > 
                <Popup>
                  <div className='popup-content-popup-map'>
                    <div className="name-popup-map">
                      {savedPlace.name}
                    </div>

                    <button 
                      className="view-more-btn-popup-map"
                      onClick={()=>{handle_ViewMore_TouristPlaces(savedPlace.id)}}
                    >
                      View More
                    </button>

                    <div>
                      <button className='PlaceCardsBookmarkDiv-PlaceCardsCss02'
                      style={{border:'none', background:'none'}}
                      onClick={(e) => {
                        e.stopPropagation();
                        handle_UnSave_Places(savedPlace.id);
                      }}>
                          <img src="https://i.ibb.co/VC5LGZb/save-bookmark.png" alt="Image 1" className='PlaceCardsBookmark-PlaceCardsCss02'
                          style={{height:'20px', width:'20px'}}
                          />
                      </button> 
                    </div>  

                    {/*//Calculate Distance, Duration, and route   */}
                    {
                      savedPlace.name !== distance_start_name &&
                      <div
                      className="distance-section-popup-map"
                      >
                        <div
                        className="find-distance-text-popup-map"
                        >
                          Find Distance:
                        </div>
                        { !distance_start_lat && savedPlace.name !== distance_start_name ?
                          <button
                          onClick={()=>{handle_calculate_route(savedPlace.lat, savedPlace.lng, savedPlace.name, "start")}}
                          className="start-btn-popup-map"
                          >
                            Start
                          </button>
                          :

                          <button 
                          onClick={()=>{handle_calculate_route(savedPlace.lat, savedPlace.lng, savedPlace.name, "end")}}
                          className="find-distance-btn-popup-map"
                          >
                            Find Distance
                          </button>
                        }
                      </div>
                    }

                    {/* {
                      <button
                      onClick={()=>{handle_loadHotelsData(0, null, "all", sortHotelPrice, savedPlace.lat, savedPlace.lng)}}
                      >
                        Find Saved Hotels
                      </button>
                    } */}

                  </div>
                </Popup>

                {/* {
                  savedPlace.name && 
                  <Tooltip 
                  direction="right" 
                  permanent 
                  className='savedPlaceName-ToolTip-active'     
                  >
                    {savedPlace.name.substring(0, 3)}...
                  </Tooltip>  
                }     */}

            </Marker>  
            )
          })
        }



        { (activeTouristPlaceNavigation === "destinations" && districtCity_data.length > 0) &&
          districtCity_data.map((data, index) => {
            if((tripDestinationType === "state" && data.destination === activeDestination) || (tripDestinationType === "district" || tripDestinationType === "city")){
            return(
              <Marker
              key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={place_1_n} 
              > 
                {/* <Popup>
                  <div>
                    <div>
                      {data.city}
                    </div>
                  </div>
                </Popup> */}

                <Tooltip 
                  direction="bottom" permanent
                  className='hoverToolTipOverall'
                >
                  {data.city}     
                </Tooltip>

            </Marker>  
            )}
          })
        }


        
        {/*//! Destinations*/}

        {/*//!1 */}
        {/* //* active/clicked location  */}
        {hoverHotelData_map && hoverHotelData_map.length > 0 &&
          hoverHotelData_map.map((data, index) => {
            return(
              <Marker
                key={index}
                position={[data.lat, data.lng]}
                icon={hotel_active_1_colored}
                // icon={map_pin_1}
                className='special-marker'
              >
                <Tooltip
                  direction="bottom"
                  permanent
                  className='container-tooltip-active_hover-Map_Hotels'
                >

                  <div
                  className='text-1-tooltip-active_hover-Map_Hotels'
                  >
                    <img 
                    src="https://pub-683f2710f5c048c786c950689efa99ff.r2.dev/hotel_icon_map_1.png"
                    className='icon-1-tooltip-active_hover-Map_Hotels'
                    />

                    {data.name.length > 12 ? `${data.name.slice(0, 12)}...` : data.name}
                  </div>

                  <div
                  className='text-2-tooltip-active_hover-Map_Hotels'
                  >
                    ₹ {data.hotel_price}
                  </div>

                </Tooltip>
              </Marker>
            )
          })
        }

        

        {/*//!2 */}
        { 
        // activeTravelCategory === "hotels" && hotelData && hotelData.length > 0 
        hotelData && hotelData.length > 0 
        &&
          hotelData.map((data, index) => {
            if(
              (activeTravelCategory === "hotels" && hotelInternalNavigation !== "saved") 
              || 
              (saved_TravelCategoriesPlaces_data.some(savedItem => savedItem.id === data.id && savedItem.type === "hotels"))
            ){
            return(
              <Marker
              key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                // icon={hotel_1_colored}
                icon={hoverHotelData_map && hoverHotelData_map.length > 0 ? hotel_1_colored_small : hotel_1_colored}
              >
                {
                  activeTravelCategory === "hotels" &&
                  <Tooltip 
                    direction="bottom" permanent
                    className='container-tooltip-hotels-Map_Hotels'
                  >
                    { !hotelClickedStatus && 
                      data.has_price && 
                      bestDealData.map((priceData, index) => {
                        if(priceData.id === data.id){
                          return(
                            <div
                            className='container-hotels-Map_Hotels'
                            >
                              <div
                              className='text-1-hotels-Map_Hotels'
                              >
                                {data.name.length > 12 ? `${data.name.slice(0, 12)}...` : data.name}
                              </div>
                            
                              <div
                              className='text-2-hotels-Map_Hotels'
                              >
                                {priceData.hotel_price}
                              </div>
                          
                            </div>
                            )
                        }
                      })
                    }
                  </Tooltip>
                }

                <Popup
                style={{width:'120px'}}
                >

                  <div
                  className='text-1-hotels-popup-Map_Hotels'
                  >
                    {data.name} 
                  </div>

                  <div
                    className='container_t-1-ratings-popup-Map_Hotels'
                  >

                    {/* <div
                    className='text-1-hotels-popup-Map_Hotels'
                    >
                      {data.name} 
                    </div> */}

                    {
                      data.rating && 
                      <div
                      className='heading-2-Map_Hotels'
                      >
                        {data.rating}
                      </div>                  
                    }

                    <Rating
                      initialValue={data.rating}
                      size={16}
                      emptyColor="#ccc"
                      fillColor="gold"
                      strokeColor="gold"
                      strokeWidth={0}
                      allowFraction={true}
                      readonly
                    />  

                    { data.reviewCount &&
                      <div
                      className='heading-3-Map_Hotels'
                      >
                        ({data.reviewCount})   
                      </div>
                    }

                  </div>  

                  {/*//! */}
                  <div>

                    <div
                      className='line-1-hotels-popup-Map_Hotels'
                    ></div>

                    <div
                      className='container-button-hotels-popup-Map_Hotels'
                    >
                      <button 
                        className='button-1-hotels-popup-Map_Hotels'
                        onClick={(e)=>{
                          e.stopPropagation();
                          openPopup()
                          setViewMore_id(data.id)
                          setViewMoreStatus(true)
                          handleGetReviewdData(data.id)
                          setActiveTravelCategory("hotels")
                        }}
                      >
                        View Info & Reviews
                      </button>

                      {
                        data.has_price && 
                        bestDealData.map((priceData, index) => {
                          if(priceData.id === data.id){
                            return(
                            <button 
                              className='button-1-hotels-popup-Map_Hotels'
                              onClick={(e)=>{
                                e.stopPropagation();
                                openPopup()
                                setView_prices_popup(true)
                                setActiveViewPrices_Limited(data.id)
                                setActiveTravelCategory("hotels")
                              }}
                            >
                              View Prices
                            </button>
                            )
                          }
                        })
                      }

                    </div>

                    {/*//Calculate Distance, Duration, and route   */}
                    {
                      // (saved_TravelCategoriesPlaces_data.some(savedItem => savedItem.id === data.id && savedItem.type === "hotels"))
                      // && data.name !== distance_start_name &&
                      //?Note:for now we are allowing the distance calculation for each hotel. But soon either we need to put a limit or restrict to just the saved hotels...
                      data.name !== distance_start_name &&
                      <div
                      className="distance-section-popup-map"
                      >
                        <div
                        className="find-distance-text-popup-map"
                        >
                          Find Distance:
                        </div>
                        { !distance_start_lat && data.name !== distance_start_name ?
                          <button
                          onClick={()=>{handle_calculate_route(data.lat, data.lng, data.name, "start")}}
                          className="start-btn-popup-map"
                          >
                            Start
                          </button>
                          :
                          <button 
                          onClick={()=>{handle_calculate_route(data.lat, data.lng, data.name, "end")}}
                          className="find-distance-btn-popup-map"
                          >
                            Find Distance
                          </button>
                        }
                      </div>
                    }

                  </div>

                </Popup>

              </Marker>
            )
          }
          })
        }






        </MapContainer>

      {/* )} */}

    </div>
  )
}

export default Map
