// MyContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'

import axios from 'axios';
import Moment from 'moment';


//importing react context
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip';

import { server_baseUrl } from '../../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';


// Step 1: Create a new context
const Context = createContext();


// Step 2: Create a context provider
export const Context_aboutDestinationPlanTrip = ({ children }) => {

  const navigate = useNavigate();

  // using react context
  const { userEmail, tripId, tripName, 
    //trip details
    tripDestinationName, tripDestinationType, stateName, districtName, cityName, destinationLat, destinationLng
  } = useValidatePlanTrip();


useEffect(() => {

    if(tripDestinationName && tripDestinationType && useEffectDataLoaded){
        loadExplorePlaceData(tripDestinationName, tripDestinationType)

        setUseEffectDataLoaded(false)

        loadMonthTemp()
        loadFamousPlacesData()
        // loadHotelsData()
    }

}, [])

useEffect(() => {

    if(destinationLat && destinationLng && tripDestinationName && tripDestinationType && useEffectDataLoaded_2){
        loadHotelsData()

        setUseEffectDataLoaded_2(false)
    }

}, [destinationLat, destinationLng])


  //!states
   
  //*Naviagtion
  const[activeAboutCategory, setActiveAboutCategory] = useState("overview")
  const[activeSeasonClick, setActiveSeasonClick] = useState()
  const[activeTravelMode, setActiveTravelMode] = useState("air")
  //*useEffectDataLoaded Status
  const[useEffectDataLoaded, setUseEffectDataLoaded] = useState(true)
  const[useEffectDataLoaded_2, setUseEffectDataLoaded_2] = useState(true)
  //
  const[trackLoadedData, setTrackLoadedData] = useState([])

  //Loading Status
  const[loadingStatus_AboutDestination, setLoadingStatus_AboutDestination] = useState()  
  
  //*Overview
  const[overviewPlaceData, setOverviewPlaceData] = useState([])
  const[seasonRankData, setSeasonRankData] = useState([])
  const[exploreSeasonData, setExploreSeasonData] = useState([])
  const[monthTempData, setMonthTempData] = useState([])
    //--most_famous, famous, all_cat
  const[famousFor_DistrictDestination, setFamousFor_DistrictDestination] = useState()
  const[famousFor_Cat_DistrictDestination, setFamousFor_Cat_DistrictDestination] = useState([])
  const[placeDurationData, setPlaceDurationData] = useState([])
  const[famousPlaces_T10_Data, setFamousPlaces_T10_Data] = useState([])
  const[hotel_T10_Data, setHotel_T10_Data] = useState([])
  const[hotel_about_data, setHotel_about_data] = useState([])

  //*Nearby
    //--City
  const[city_nearbyCitiesData, setCity_nearbyCitiesData] = useState([])
    //--District
  const[district_districtDestinationsData, setDistrict_districtDestinationsData] = useState([])
  const[district_nearbyDistrictData, setDistrict_nearbyDistrictData] = useState([])
    //--State
  const[state_famousDistrict, setState_famousDistrict] = useState([])
  const[state_famousDistrictDestinations, setState_famousDistrictDestinations] = useState([])
  const[state_nearbyStates, setState_nearbyStates] = useState([])

  //*About  
  const[aboutPlaceData, setAboutPlaceData] = useState([])
  const[foodPlaceData, setFoodPlaceData] = useState([])
  const[mustKnowThingsPlace, setMustKnowThingsPlace] = useState([])
  const[travelModesData, setTravelModesData] = useState([])

  //*Qna
  const[qnaPlaceData, setQnaPlaceData] = useState([])

  //*Reach
  const[travelMode_Air_Data, setTravelMode_Air_Data] = useState([])
  const[travelMode_Train_Data, setTravelMode_Train_Data] = useState([])
  const[travelMode_Bus_Data, setTravelMode_Bus_Data] = useState([])

  //Track Data Loaded Status
  const[loaded_nearby, setLoaded_nearby] = useState(false)
  const[setloaded_monthWiseTemp, setLoaded_monthWiseTemp] = useState(false)
  const[setloaded_10_famousPlaces, setLoaded_10_famousPlaces] = useState(false)
  const[setloaded_10_famousHotels, setLoaded_10_famousHotels] = useState(false)

   

  //!Initial load : 1-Overview, 2-About, 3-QnA, 4-Reach

  const handleMainCategoryClick = (categoryName, passedReachModeType) => {
    
    //setting the main category of the component
    setActiveAboutCategory(categoryName);

    let reachModeType;

    reachModeType = passedReachModeType ? passedReachModeType : "air";

    if (!trackLoadedData.includes(categoryName)){
        
        if(categoryName === "overview"){
            loadExplorePlaceData()
            setTrackLoadedData([...trackLoadedData, categoryName])
        }
        else if(categoryName === "about"){
            loadAboutPlaceData()
            setTrackLoadedData([...trackLoadedData, categoryName])
        }
        else if(categoryName === "qna"){
            loadQnaData()
            setTrackLoadedData([...trackLoadedData, categoryName])
        }
        else if(categoryName === "reach"){
            loadReachData(reachModeType)
            setTrackLoadedData([...trackLoadedData, categoryName])
            setTrackLoadedData([...trackLoadedData, reachModeType])
        }
        else{
            // console.log('/error-loading-about-info-for-the-destination?resolve?try-again')
            // navigate('/error-loading-about-info-for-the-destination?resolve?try-again')
        }
    }
  } 

  //!1-Overview
  
  const loadExplorePlaceData = () => {

    loadOverviewPlace() //this loads further functions

    loadSeasonRank()


    //? data to be loaded on overview scroll
    // loadMonthTemp()

    loadExploreSeason()

    //load for all destinations (handles at the backend)

    //!deprecated - famous for...
    // loadFamousForData()

    loadDurationData()

    setTrackLoadedData([...trackLoadedData, "overview"])

    //? data to be loaded on overview scroll
    //Near by
    // if(tripDestinationType === "city"){
    //     loadCities_nearbyCities()
    // }
    // else if(tripDestinationType === "district"){
    //     loadDistrict_districtDestinations()
    //     loadDistrict_nearbyDistricts()
    // }
    // else{
    //     loadState_famousDistrict()
    //     loadState_famousDistrictDestinations()
    //     loadState_nearby()
    // }

    //? data to be loaded on overview scroll
    //Top 10

    // loadFamousPlacesData()

    // loadHotelsData()

  }

  //*handling the process, of loading the data on the overview page, as the user scrolls down. The data is not to display anuthing to the user, but to just load the data. On the frontend, we are just using the "trackLoadedData_OverviewInside" if to load the data or not.  The "activeOverviewCategory" could be used in future, where teh user can view the categories of the overview component, and as a user clicks any button, we also need to handle that the previous also getts loaded.
  
  // monthWiseTemp, top10_famousPlaces, top10_famousHotels, nearby
  const[trackLoadedData_OverviewInside, setTrackLoadedData_OverviewInside] = useState([])

  const[activeOverviewCategory, setActiveOverviewCategory] = useState()

  const loadData_OverviewScroll = (entityName) => {

    // setActiveOverviewCategory(entityName)
    // console.log("loadData_OverviewScroll was called.", entityName, "trackLoadedData_OverviewInside", trackLoadedData_OverviewInside)

    // console.log('\n', '\n', trackLoadedData_OverviewInside, '\n', '\n')
    setLoadingStatus_AboutDestination("overview_data")
    if(!trackLoadedData_OverviewInside.includes(entityName)){
        
        if(entityName === "monthWiseTemp"){
            // loadMonthTemp()
        }
        else if(entityName === "top10_famousPlaces"){
            // loadFamousPlacesData()
        }
        else if(entityName === "top10_famousHotels"){
            // loadHotelsData()
        }
        else if(entityName === "nearby"){
            //Nearby
            // alert("i iiiii")
            if(tripDestinationType === "city"){
                loadCities_nearbyCities()
            }
            else if(tripDestinationType === "district"){
                loadDistrict_districtDestinations()
                loadDistrict_nearbyDistricts()
            }
            else if(tripDestinationType === "state"){
                // console.log('\n', "xxxxxxxxxxxxx", "i was called", "stateName", stateName, "tripDestinationName", tripDestinationName, "tripDestinationType", tripDestinationType, '\n')

                loadState_famousDistricts()
                loadState_famousDestinations()
                loadState_nearbyState()
            }
            else{
                // console.log("'something-went-wrong-loadHotelAbout'")
            }
        }
        // console.log("i was executed", entityName)
        setTrackLoadedData_OverviewInside([...trackLoadedData_OverviewInside, entityName])
        setLoadingStatus_AboutDestination(false)
    }
    else{
        setLoadingStatus_AboutDestination(false)
    }

  }

  const loadOverviewPlace = async () => {
    setLoadingStatus_AboutDestination("overview_data")
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/place_overview`, {tripDestinationName,
    tripDestinationType})
    // console.log("tripDestinationName", tripDestinationName,
        // "tripDestinationType", tripDestinationType)
    // console.log("loadOverviewPlace", response.data)
    if(response.data.length > 0){
        setOverviewPlaceData(response.data)
        setLoadingStatus_AboutDestination(false)
    } 
    else{
        setLoadingStatus_AboutDestination(false)
    }
    
  }


  const loadSeasonRank = async () => {
    setLoadingStatus_AboutDestination("overview_data")
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/season_rank`, {tripDestinationName, tripDestinationType})

    // console.log("loadSeasonRank", response.data)
    if(response.data.length > 0){
        setSeasonRankData(response.data)
        setActiveSeasonClick(response.data[0].best_season_1)
        setLoadingStatus_AboutDestination(false)
    } 
    else{
        setLoadingStatus_AboutDestination(false)
    }
  }


  const loadMonthTemp = async () => {
    setLoadingStatus_AboutDestination("overview_data")
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/month_wise_temp`, {tripDestinationName,
    tripDestinationType, districtName})

    // console.log("loadMonthTemp", response.data)

    if(response.data.length > 0){
        setMonthTempData(response.data)
        setLoadingStatus_AboutDestination(false)
    }
    else{
        setLoadingStatus_AboutDestination(false)
    } 
    
  }

  
  const loadExploreSeason = async () => {
    setLoadingStatus_AboutDestination("overview_data")
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/explore_season`, {tripDestinationName,
    tripDestinationType, districtName})

    // console.log("loadExploreSeason", response.data)
    if(response.data.length > 0){
        setExploreSeasonData(response.data)
        setActiveSeasonClick(response.data[0].content_season)
        setLoadingStatus_AboutDestination(false)
    } 
    else{
        setLoadingStatus_AboutDestination(false)
    }
    
  }

  const handleSeasonClick = (passedSeason) => {
    setActiveSeasonClick(passedSeason)
    // console.log("handleSeasonClick - passedSeason ", passedSeason)
  }
  //!

  const loadFamousForData = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/load_famous_for`, {cityName, districtName, stateName, tripDestinationType})

    // console.log('\n', "cityName, districtName, stateName", cityName, districtName, stateName, '\n')

    // console.log("loadFamousForData", response.data)
    
    let famousArray = [];
    let categoryType;

    if(response.data.length > 0 && response.data !== "0"){
        
        if(response.data[0].most_famous){
            famousArray = response.data[0].most_famous.split(';').map(item => item.trim());
            categoryType = "most_famous"
        }else if(response.data[0].famous){
            famousArray = response.data[0].famous.split(';').map(item => item.trim());
            categoryType = "famous"
        }else if(response.data[0].all_cat){
            famousArray = response.data[0].all_cat.split(';').map(item => item.trim());
            categoryType = "all_cat"
        }

        setFamousFor_DistrictDestination(famousArray);
        setFamousFor_Cat_DistrictDestination(categoryType);
    }
    else{
        // console.log("error - loadFamousForData")
        alert("error in loading some data. - loadFamousForData")
    }
         
  }

  //?
  const loadDurationData = async () => {

    setLoadingStatus_AboutDestination("duration_data")

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/load_duration_data`, {cityName, districtName, stateName, tripDestinationType})

    // console.log("loadDurationData", response.data)

    if(response.data !== "0"){
        setPlaceDurationData(response.data)
        setLoadingStatus_AboutDestination(false)
    }
    else{
        // console.log("error - loadDurationData")
        // alert("error in loading some data.")
        setLoadingStatus_AboutDestination(false)
    }

  }

  //!Top 10

  //?
  const loadFamousPlacesData = async () => {

    setLoadingStatus_AboutDestination("famous_places")

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/top_10/famous_places_data`, {tripDestinationName, tripDestinationType, stateName, districtName, cityName})

    // console.log("loadFamousPlacesData", response.data)

    if(response.data !== "0"){
        
        //data1, dataAbout2
        setFamousPlaces_T10_Data(response.data)
        setLoadingStatus_AboutDestination(false)

        // console.log("loadFamousPlacesData data1", response.data.data1)
    }
    else{
        // console.log("error - loadHotelsData")
        setLoadingStatus_AboutDestination(false)
        // alert("error in loading some data.")
    }
  }

  //?
  const loadHotelsData = async () => {

    setLoadingStatus_AboutDestination("famous_hotels")

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/top_10/hotels_data`, {tripDestinationName, tripDestinationType, stateName, districtName, cityName, destinationLat, destinationLng})

    // console.log("loadHotelsData", response.data)

    if(response.data !== "0" && response.data.length > 0){
        
        //data1, dataAbout2
        setHotel_T10_Data(response.data)

        //?getting about data
        const ids = response.data.map(item => item.id).join(', ');
        // console.log("calling loadHotelAbout")
        loadHotelAbout(ids, stateName)
        setLoadingStatus_AboutDestination(false)
    }
    else{
        // console.log("error - loadHotelsData")
        // alert("error in loading some data.")
        setLoadingStatus_AboutDestination(false)
    }
  }

  //*About Data  
  const loadHotelAbout = async (passedIds, passedStateName) => {
    
    setLoadingStatus_AboutDestination("hotel_about")

    const response = await axios.post(
      `${server_baseUrl}/csc/api/travel_categories/hotels/get/hotel_details/other_3`, 
      {
        passedIds, passedStateName
    })
    // console.log("response.data - handleGetOtherdata2", response.data) 

    if(response.data && (response.data !== "0" || response.data.length > 0)){
        setHotel_about_data([...response.data])
        setLoadingStatus_AboutDestination(false)
    }
    else{
      // console.log("error getting the otherData 2")
        setLoadingStatus_AboutDestination(false)
    }
  }

  //*Near by 
  //!

    // if(tripDestinationType === "city"){
    //     loadCities_nearbyCities()
    // }
    // else if(tripDestinationType === "district"){
    //     loadDistrict_districtDestinations()
    //     loadDistrict_nearbyDistricts()
    // }
    // else if(tripDestinationType === "state"){
    //     // console.log("loadHotelAbout was called.")
    //     // loadState_famousDistricts()
    //     // loadState_famousDestinations()
    //     // loadState_nearbyState()
    // }
    // else{
    //     // navigate('something-went-wrong-loadHotelAbout')
    //     // console.log('something-went-wrong-loadHotelAbout')

    // }

//City
const loadCities_nearbyCities = async () => {

    setLoadingStatus_AboutDestination("nearby_cities")
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/nearby_destinations`, {cityName})

    // console.log("loadCities_nearbyCities", response.data)
    if(response.data.length > 0){
        setCity_nearbyCitiesData(response.data)
        setLoadingStatus_AboutDestination(false)
    }
    else{
        setLoadingStatus_AboutDestination(false)
    }
    
}


//District
const loadDistrict_districtDestinations = async () => {

    setLoadingStatus_AboutDestination("districts_cities")

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/nearby_district_destinations`, {districtName, stateName})

    // console.log("loadDistrict_districtDestinations", response.data) 
    if(response.data.length > 0){
        setDistrict_districtDestinationsData(response.data) 
        setLoadingStatus_AboutDestination(false)
    }
    else{
        setLoadingStatus_AboutDestination(false)
    }
    
} 

const loadDistrict_nearbyDistricts = async () => {

    setLoadingStatus_AboutDestination("nearby_districts")

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/nearby_districts`, {districtName, stateName})

    // console.log("loadDistrict_nearbyDistricts", response.data)
    if(response.data.length > 0){
        setDistrict_nearbyDistrictData(response.data)
        setLoadingStatus_AboutDestination(false)
    }
    else{
        setLoadingStatus_AboutDestination(false)
    }
    
}


//State

const loadState_famousDistricts = async () => {

    setLoadingStatus_AboutDestination("nearby_districts")

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/famous_districts`, {stateName})

    // console.log("loadState_famousDistrict", response.data)
    if(response.data.length > 0){
        setState_famousDistrict(response.data)
        setLoadingStatus_AboutDestination(false)
    }
    else{
        setLoadingStatus_AboutDestination(false)
    }
}

  
const loadState_famousDestinations = async () => {

    setLoadingStatus_AboutDestination("nearby_data")

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/famous_destinations`, {stateName})

    // console.log("loadState_famousDistrictDestinations", response.data)
    if(response.data.length > 0){
        setState_famousDistrictDestinations(response.data)
        setLoadingStatus_AboutDestination(false)
    }
    else{
        setLoadingStatus_AboutDestination(false)
    }
}

const loadState_nearbyState = async () => {

    setLoadingStatus_AboutDestination("nearby_data")

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination/nearby_state`, {stateName})

    // console.log("loadState_nearby", response.data)
    if(response.data.length > 0){
        setState_nearbyStates(response.data)
        setLoadingStatus_AboutDestination(false)
    } 
    else{
        setLoadingStatus_AboutDestination(false)
    }
}


  //!2-About

  const loadAboutPlaceData = () => {

    loadAboutPlace(tripDestinationName, tripDestinationType) 

    loadFoodPlace(tripDestinationName, tripDestinationType) 

    loadMustKnowThingsPlace(tripDestinationName, tripDestinationType)
                                               
    loadTravelModes(tripDestinationName, tripDestinationType)  
  }

  const loadAboutPlace = async (tempActivePlaceName,
    tempActivePlaceType) => {

    setLoadingStatus_AboutDestination("about_place")

    const response = await axios.post(`${server_baseUrl}/csc/load/about/depth`, {tempActivePlaceName,
    tempActivePlaceType})

    // console.log("loadAboutPlace", response.data)
    if(response.data.length > 0){
        setAboutPlaceData(response.data)
        setLoadingStatus_AboutDestination(false)
    } 
    else{
        setLoadingStatus_AboutDestination(false)
    }
    
  }

  
const loadFoodPlace = async (tempActivePlaceName,
    tempActivePlaceType) => {
    setLoadingStatus_AboutDestination("about_data")
    const response = await axios.post(`${server_baseUrl}/csc/load/food_place/data`, {tempActivePlaceName,
    tempActivePlaceType, districtName})

    // console.log("loadFoodPlace", response.data)
    if(response.data.length > 0){
        setFoodPlaceData(response.data)
        setLoadingStatus_AboutDestination(false)
    } 
    else{
        setLoadingStatus_AboutDestination(false)
    }
    
}

const loadMustKnowThingsPlace = async (tempActivePlaceName,
    tempActivePlaceType) => {
    setLoadingStatus_AboutDestination("about_data")
    const response = await axios.post(`${server_baseUrl}/csc/load/must/know/things`, {tempActivePlaceName,
    tempActivePlaceType})

    // console.log("loadMustKnowThingsPlace", response.data)
    if(response.data.length > 0){
        setMustKnowThingsPlace(response.data)
        setLoadingStatus_AboutDestination(false)
    } 
    else{
        setLoadingStatus_AboutDestination(false)
    }
    
}

const loadTravelModes = async (tempActivePlaceName,
    tempActivePlaceType) => {
    setLoadingStatus_AboutDestination("about_data")
    const response = await axios.post(`${server_baseUrl}/csc/load/travel/modes/data`, {tempActivePlaceName,
    tempActivePlaceType, districtName})

    // console.log("loadTravelModes", response.data)
    if(response.data.length > 0){
        setTravelModesData(response.data)
        setLoadingStatus_AboutDestination(false)
    } 
    else{
        setLoadingStatus_AboutDestination(false)
    }
}


  //!3-QnA

  const loadQnaData = () => {
    // code-011-1      
    loadQnaPlace(tripDestinationName, tripDestinationType);
  }

  const loadQnaPlace = async (tempActivePlaceName,
    tempActivePlaceType) => {

    setLoadingStatus_AboutDestination("qna_data")

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination_2/qna_data`, {tripDestinationName, tripDestinationType})

    // console.log("loadQnaPlace", response.data)

    if(response.data !== "0"){
        setQnaPlaceData([...response.data])
        setLoadingStatus_AboutDestination(false)
    } 
    else{
        setLoadingStatus_AboutDestination(false)
    }
  }


  //!4-Reach

  const loadReachData = (reachModeType) => {

    setActiveTravelMode(reachModeType)

    if(!trackLoadedData.includes(reachModeType)){
        if(reachModeType === "bus"){
            loadBusModes(tripDestinationName, tripDestinationType)
            setTrackLoadedData([...trackLoadedData, reachModeType])
        }
        else if(reachModeType === "train"){
            loadTrainModes(tripDestinationName, tripDestinationType) 
            setTrackLoadedData([...trackLoadedData, reachModeType])
        }
        else{
            loadAirModes(tripDestinationName, tripDestinationType) 
            setTrackLoadedData([...trackLoadedData, reachModeType])
        }    
    }     
    else{
        // console.log("error in loadReachData")
    }      
  }


  const loadAirModes = async (tripDestinationName, tripDestinationType) => {

    setLoadingStatus_AboutDestination("air_modes")
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination_2/airports`, {tripDestinationName, tripDestinationType})

    // console.log("loadAirModes", response.data)
    if(response.data !== "0"){
        setTravelMode_Air_Data(response.data)
        setLoadingStatus_AboutDestination(false)
    } 
    else{
        alert("error loading the airports")
        setLoadingStatus_AboutDestination(false)
    }
    
  }
  

const loadTrainModes = async (tripDestinationName, tripDestinationType) => {
    setLoadingStatus_AboutDestination("train_modes")
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination_2/train`, {tripDestinationName, tripDestinationType})

    // console.log("loadTrainModes", response.data)
    if(response.data !== "0"){
        setTravelMode_Train_Data(response.data)
        setLoadingStatus_AboutDestination(false)
    } 
    else{
        alert("error loading the Railway Stations")
        setLoadingStatus_AboutDestination(false)
    }
}

const loadBusModes = async (tripDestinationName, tripDestinationType) => {

    setLoadingStatus_AboutDestination("bus_modes")
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/about_destination_2/bus`, {tripDestinationName, tripDestinationType})

    // console.log("loadBusModes", response.data)
    if(response.data !== "0"){
        setTravelMode_Bus_Data(response.data)
        setLoadingStatus_AboutDestination(false)
    } 
    else{
        alert("error loading the Bus Stands")
        setLoadingStatus_AboutDestination(false)
    }
}

//!5-Map
const[activeReachPlace_Map, setActiveReachPlace_Map] = useState([])
const[activeCenterLat, setActiveCenterLat] = useState()
const[activeCenterLng, setActiveCenterLng] = useState()
const[activeCenterZoom, setActiveCenterZoom] = useState()

const handleReachClickMap = async (mode_lat, mode_lng, modeType, modeName, clickStatus) => {
    setActiveReachPlace_Map([{
        lat:mode_lat, lng:mode_lng, mode:modeType, name:modeName 
    }])
    if(clickStatus){
        setActiveCenterLat(mode_lat)
        setActiveCenterLng(mode_lng)
        setActiveCenterZoom(8)
    }
}



  // Step 5: Return a provider with value containing state and functions
  return (
    <Context.Provider value={{ 
        //Navigation
        activeAboutCategory, useEffectDataLoaded, activeTravelMode, activeOverviewCategory, trackLoadedData, trackLoadedData_OverviewInside,
        //Overview
        overviewPlaceData, seasonRankData, exploreSeasonData, monthTempData, famousFor_DistrictDestination, famousFor_Cat_DistrictDestination, placeDurationData, famousPlaces_T10_Data, hotel_T10_Data, hotel_about_data,
        //Loading Status
        loadingStatus_AboutDestination, setLoadingStatus_AboutDestination,
        activeSeasonClick, setActiveSeasonClick, 
        //Nearby
            //--City
        city_nearbyCitiesData,
            //--District
        district_districtDestinationsData,
        district_nearbyDistrictData,
            //--State
        state_famousDistrict,
        state_famousDistrictDestinations,
        state_nearbyStates,
        //About
        aboutPlaceData,
        foodPlaceData,
        mustKnowThingsPlace,
        travelModesData,
        //Qna
        qnaPlaceData,
        //Reach
        travelMode_Air_Data,
        travelMode_Train_Data,
        travelMode_Bus_Data,
        //map
        activeReachPlace_Map, setActiveReachPlace_Map, 
        //track loaded data
        loaded_nearby, setLoaded_nearby,
        setloaded_monthWiseTemp, setLoaded_monthWiseTemp,
        setloaded_10_famousPlaces, setLoaded_10_famousPlaces,
        setloaded_10_famousHotels, setLoaded_10_famousHotels,
        //Map
        activeCenterLat, setActiveCenterLat,
        activeCenterLng, setActiveCenterLng,
        activeCenterZoom, setActiveCenterZoom,

        //*Functions
        //navigation
        handleMainCategoryClick, loadReachData, loadData_OverviewScroll,
        //map
        handleReachClickMap, 
        //
        handleSeasonClick
    }}>
      {children}
    </Context.Provider>
  );

};

// Step 6: Create a custom hook to consume the context
export const useAboutDestinationPlanTrip = () => {
  return useContext(Context);
};



