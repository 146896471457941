import React, { useState } from 'react'

//import scss
import './Entity_Itinerary_TO.scss'
import './Itinerary_TO_Components.scss'

//import icon
import { RiDeleteBin5Line } from "react-icons/ri";
import { AiOutlineEdit } from "react-icons/ai";
import { TbEdit } from "react-icons/tb";
import { IoMdCloseCircle } from "react-icons/io";
import { IoMdRemoveCircle } from "react-icons/io";

//toptap - rick text editor
import TipTap_ReadOnly from '../../../../../Global Tool - Plan Trip/Notes/TestingLexical/TipTap_ReadOnly';


function Entity_Itinerary_TO(props) {

  const {
    itineraryData, visibility_type, different_design, topic_type, topic_id,

    //map
    handleMap_HoverClick, setHoverItineraryData, setPlaceClicked_map
  } = props;

  const[activeViewMore, setActiveViewMore] = useState(false)
  const[activeNote, setActiveNote] = useState([])
  
  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
    setActiveViewMore(false);
    setActiveViewMore('')
    document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(`01/01/2022 ${startTime}`);
    const end = new Date(`01/01/2022 ${endTime}`);
    const durationMs = end - start;
    const durationMinutes = Math.abs(Math.round(durationMs / 60000));
    const hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes % 60;

    let durationString = '';
    if (hours > 0) {
      durationString += `${hours} hour${hours !== 1 ? 's' : ''}`;
    }
    if (minutes > 0) {
      durationString += `${durationString.length > 0 ? ' ' : ''}${minutes} minute${minutes !== 1 ? 's' : ''}`;
    }

    return durationString.length > 0 ? durationString : '0 minutes';
  };


  const handleImageLink = (type) => {
    let link;

    if(type === "Tourist Place"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/tourist_place_icon_1.png'
    }
    else if(type === "Hotel"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/hotel_icon_1.png'
    }
    else if(type === "Restaurant"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/restaurant_icon_3.png'
    }
    else if(type === "Bars/Pubs"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/bar_and_pub_icon_3.png'
    }
    else if(type === "Activity"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/activity_icon_3.png'
    }
    else if(type === "Amusement/Water Park"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/amusement_park_icon_3.png'
    }
    else if(type === "Shopping Mall"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/shopping_mall_icon_1.png'
    }
    else if(type === "Street Shopping"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/street_market_icon_3.png'
    }
    else if(type === "Rent Vehicle"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/rent_vehicle_4_icon_1.png'
    }

    return link;
  }

  const handleGetCurrency = (currencyName) => {
    let currency;

    if(currencyName === "inr"){
      currency = '₹'
    }
    else if(currencyName === "dollar"){
      currency = '$'
    }

    return currency;
  }


  
  function formatNumberWithCommas(number) {
    if (isNaN(number)) {
      return 0; // Handle non-numeric values gracefully
  }

    const parts = number.toString().split('.');
    let wholePart = parts[0];

    // Reverse the string to handle commas from the last digit
    wholePart = wholePart.split('').reverse().join('');

    // Insert commas after every group of 2 digits, but not at the end
    wholePart = wholePart.replace(/(\d{2})(?=(\d{2})+(?!\d))/g, '$1,');

    // Reverse the string back to normal order
    wholePart = wholePart.split('').reverse().join('');

    // Adjust the first comma for the first three digits group
    if (wholePart.length > 6) {
        wholePart = wholePart.replace(/(\d+),/, '$1,');
    }

    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';

    return wholePart + decimalPart;
  }


  return (
    <div className='container_p-Itinerary_TO_Components'>
    {
      !different_design && itineraryData &&
  
      itineraryData.map((data, index) => {
        if(data.topic_id === topic_id){
        return (
          <div key={index}
          className='container_s-Itinerary_TO_Components'

          onMouseOver={()=>{handleMap_HoverClick(data.topic_id, data.lat, data.lng, data.display_name, index, data.name)}}
          onMouseOut={()=>{setHoverItineraryData([])}}
          onClick={()=>{setPlaceClicked_map(data.topic_id)}}
          
          style={{position:'relative'}}
          >

            <div className='text-index-Itinerary_TO_Components'>
              {index}
            </div>
  
            {/*//! #1  */}
            {/* topic type and delete topic */}
            <div className='conatiner_p-head-Itinerary_TO_Components'>
  
              <div
              className='conatiner_s-1-head-Itinerary_TO_Components'
              >
                <div className='text-head-Itinerary_TO_Components'>
                  {data.display_name}
                </div>
  
                <div>
                  <img
                    src={handleImageLink(data.display_name)}
                    className='icon-checklist-Itinerary_TO_Components'
                  />
                </div>
              </div> 

              <div className='container_p-tripStatus-Itinerary_TO_Components'>

                {/* <div className='heading-1-tripStatus-Itinerary_TO_Components'>
                  #TripStatus
                </div> */}

                <div className="heading-2-tripStatus-Itinerary_TO_Components">
                  {/* {data.topic_visit_type} */}
                  {data.topic_visit_type &&
                    data.topic_visit_type.split('_')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')
                  }
                </div>

                {/* //*Indication for must visit */}
                {
                  data.topic_visit_type === "must_visit" &&
                  <div
                  className='emoji-tripStatus-Itinerary_TO_Components'
                  >
                    ⭐
                  </div>
                }

              </div> 
  
            </div>

  
            {/*//! #2  */}
            <div className='text-placeName-Itinerary_TO_Components'>
              {data.name && data.name.split(',')[0]}
            </div>


            {/* //! #3 
            {/* Note */}
            {
              (data.notes_data) &&
              <div>

                {/* Container - 1 | Note Menu */}
                <div className='conatiner_p-addNote-Itinerary_TO_Components'>

                  <div className='heading-1-addNote-Itinerary_TO_Components'>
                    {/* Note */}
                    <b>Note</b>
                  </div>

                  <button
                  onClick={()=>{{
                    setActiveViewMore(true)
                    setActiveNote(data.notes_data)
                    openPopup()
                  }}}
                  className='button-addNote-Itinerary_TO_Components'
                  >
                    View full note 
                  </button>

                </div>  

                {/* <button className='container_p-note-singleNote-displayNotes-Note_TD'>
                
                  <div style={{ background: 'white' }}
                    className='container_t-note-singleNote-displayNotes-Itinerary_TO_Components'>
                    <TipTap_ReadOnly content={data.notes_data} />
                  </div>

                </button> */}

              </div>
            } 



            {/* //!5  */}
            {/*//* Container for Time Interval */}
            { 
              (data.time_start || data.time_end) &&
              <div className='container_s-2-Data-Itinerary_TO_Components'>
                
                <div className='heading-1-timeInterval-Itinerary_TO_Components'>
                  Time Interval
                </div>

                {/* time interval */}
                <div className='container_p-time-timeInterval-Itinerary_TO_Components'>

                  {data.time_start ?
                    <div className='text-time-timeInterval-Itinerary_TO_Components'>
                      {data.time_start}
                    </div>
                    :
                    <div className='text-time-timeInterval-Itinerary_TO_Components'>
                      00:00
                    </div>
                  }
                  -
                  {data.time_end ?
                    <div className='text-time-timeInterval-Itinerary_TO_Components'>
                      {data.time_end}
                    </div>
                    :
                    <div className='text-time-timeInterval-Itinerary_TO_Components'>
                      00:00
                    </div>
                  }

                </div>

                {/* total duration */}
                <div
                className='container_t-3-timeInterval-Itinerary_TO_Components'
                >
                  {
                    data.time_start && data.time_end &&
                    (
                      <ul>
                        <li className='content-time-timeInterval-Itinerary_TO_Components'>
                          {/* Total Duration: {calculateDuration(data.time_start, data.time_end)} */}
                          {calculateDuration(data.time_start, data.time_end)}
                        </li>
                      </ul>
                    )
                  }
                </div>

              </div>
            } 
          
  


            {/*//!popup */}
            {
              activeViewMore && 
              <div>

                <div className='popup-background-1-Entities_TD'
                  onClick={()=> closePopup()}
                >
                </div>

                <div className='popup_p-addNote-Entities_TO_Popup'>
                  <div className='popup_s-addNote-Entities_TO_Popup'>

                    <button
                      onClick={()=>{
                        closePopup()
                        }}
                      className='button-close-popup-AddTripDetails_TO'
                    >
                      <IoMdCloseCircle />
                    </button>

                    <div style={{ background: 'white' }}
                      className='container_t-note-popup-Itinerary_TO_Components'
                    >
                      <TipTap_ReadOnly content={activeNote} />
                    </div>

                  </div> 
                </div>    

              </div>
            }

            {/*//! #4  */}
            {/* Main Conatiner for Budget, Trip Status, and Time Interval */}
            <div className='container_p-Data-Itinerary_TO_Components'>

              {/*//* Conatiner for Budget and Trip Status */}
              <div className='container_s-1-Data-Itinerary_TO_Components'>

                {/*//* Budget */}
                {
                  (data.budget_number && data.budget_currency)
                  &&
                  <div className='container_p-Budget-Itinerary_TO_Components'>

                    {
                      data.display_name !== 'Hotel'
                        ?
                        <div className='heading-1-budgetData-Itinerary_TO_Components'>
                          Budget
                        </div>
                        :
                        <div className='heading-1-budgetData-Itinerary_TO_Components'>
                          Per Night
                        </div>
                    }

                    <div className='container_t-budgetData-Itinerary_TO_Components'>
                      <div className='container_t-data-1-budget-Itinerary_TO_Components'>
                        {/* {data.budget_currency} */}
                        {
                          data.budget_currency ?
                            handleGetCurrency(data.budget_currency)
                            :
                            <div>xx</div>
                        }
                      </div>
                      <div className='container_t-data-1-budget-Itinerary_TO_Components'>
                        {
                          data.budget_number ?
                            formatNumberWithCommas(data.budget_number)
                            :
                            <div>xx</div>
                        }
                      </div>
                      {
                        data.budget_meta_info &&
                        <div className='container_t-data-2-budget-Itinerary_TO_Components'>
                          #{data.budget_meta_info}
                        </div>
                      }
                    </div>

                  </div>
                }

                </div>
  
               
              </div>
  
            </div>
        )
      }
      })
    }
    </div>
  
  )
}

export default Entity_Itinerary_TO