//@@ queries

// first prompt
export const get_prompt_1 = [
  "Enter your destination:",
  "You can enter any destination in India: city, town, or district.",
  "Let's go! What's the destination?"
];

// second prompt
export const prompt_select_categories_1 = [
  "Here are the types of attractions in",
  "Where would you like to find famous sights?"
];

export const prompt_select_categories_2 = [
  "You can select interesting categories or use the easy select option.",
  "Choose the categories. Not sure? Try the easy select options."
];

export const prompt_when = [
  "When are you planning to visit",
  "Great! Now tell us when you'd like to explore",
  "In which season are you planning your trip to"
];

export const prompt_duration = [
  "Awesome! How many days are you planning? (Max 10 days)",
  "What's the duration of your trip? (Max 10 days)",
  "Lastly, how long is the itinerary? (Max 10 days)"
];
