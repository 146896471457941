import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';

//import css
import './Ai_CreateItinerary.scss'

import axios from 'axios';

import { Helmet } from 'react-helmet'


//manage google analytics tracking
import { initGA, trackPageView } from '../../../../Track Action/Google Tracking/GoogleTrackingAnalytics';
import { userAnalytics_customTrack_VisitsExits } from '../../../../Track Action/Custom Tracking/CustomTrackingAnalytics';
import {v4 as uuidv4} from 'uuid';


//importing react context
import { useAiCreateItinerary } from '../../Create Itinerary - Result/Manage/Context/Ai_CreateItineraryContext';

//import react icon
import { MdOutlineModeOfTravel } from "react-icons/md";

//import internal components 
import {
  Ai_Suggestions, 
  Ask_Questions, 
  DayWise, 
  HowToReach, 
  Map_Ai_Itinerary,
  PromptAndOverview,
} from '../Manage/Export/Ai_ItineraryExport'
import HowToReach_Mobile from '../Components/How To Reach/HowToReach_Mobile';
import DayWise_Mobile from '../Components/Day Wise/DayWise_Mobile';

function Ai_CreateItinerary() {


  const location = useLocation();
  //!Track user visit 
  //
  useEffect(() => {
    initGA();
  }, []);
  //
  useEffect(() => {
    trackPageView(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    // Call this function once when the page is loaded
    const date = Date.now();
    const start_time = Math.floor(date / 1000)

    const random_number = Math.floor(Math.random() * 1000000); 
    const concatenatedString = `${date}-${random_number}`;
    const user_temp_id = uuidv4(concatenatedString);  


    userAnalytics_customTrack_VisitsExits(user_temp_id, null, location.pathname, "enter", null);

    // Function to handle page exit
    const handleBeforeUnload = () => {

      const end_time = Math.floor(Date.now() / 1000);  // Convert milliseconds to seconds

      const stay_duration = end_time-start_time;

      userAnalytics_customTrack_VisitsExits(user_temp_id, null, location.pathname, "exit", stay_duration);
    };

    // Add event listener to call page exit event when the user tries to close or navigate away
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures this effect runs only once per page load

  //!End*----


  
  const {
    test, 
    //
    getRoute_buttonStatus, setGetRoute_buttonStatus,
    //
    handle_CallGetRoute,
    // user_email,
    //*states
    //?home
    activeNavigationCategory_ItineraryResult,
    //?Map
    lat, lng, zoom, hoverData,

    //*createTrip
    createTripId,
    tripName, setTripName, tripWindowStatus, setTripWindowStatus,

    //*functions
    //?home 
    handleActive_ItineraryResultClicked,
    //?createTrip
    handleViewTrip, handleCreateTrip,
  } = useAiCreateItinerary()
  

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initialize with the current width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 699); // Update state on window resize
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
    document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  return (
    <div>  
  
  
  <Helmet>   

<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Assistant:wght@400&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap" rel="stylesheet" />

</Helmet>


    <div
      className='conatiner_p-component-content_map-Hotels'
    >

      { (lat && lng) &&

      <div
      className='continer_p-0-Ai_CreateItinerary'
      ></div>
      }


      {/*//!#2 */}
      <div
        className='continer_p-1-Ai_CreateItinerary'
      >


        <div
        className='conatiner_p-heading-Ai_CreateItinerary'
        >  

          <div
          // className='conatiner_s-1-heading-Hotels'
          >
            <div
            className='heading-1-heading-Ai_CreateItinerary'
            > 
              Ai Trip Itinerary
            </div>

            <img 
            src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/ai_itinerary_1.jpeg'
            // src='https://designerapp.officeapps.live.com/designerapp/document.ashx?path=/13024f48-7b53-45b7-b047-9e027c3f85c4/DallEGeneratedImages/dalle-b7119bc2-9cb4-45cd-ac96-753733b2d9340251680997139821325600.jpg&dcHint=JapanEast&fileToken=9a4ac465-6c99-48b2-97af-37ce2ac14020'

            // https://designerapp.officeapps.live.com/designerapp/document.ashx?path=/d427e476-136f-454a-9071-ff9fb462c162/DallEGeneratedImages/dalle-9976fba9-7bf1-47ef-9ae5-a4348addccd60251681068742642360200.jpg&dcHint=IndiaCentral&fileToken=14ef67e1-604a-4640-8535-f1116fb91402
            
            className='img-1-heading-Ai_CreateItinerary'
            />
          </div>   

        </div>  

{/* 
        <div>
          {
            createTripId ?

            <button onClick={()=>{handleViewTrip(createTripId)}}>
              View Your Trip - {tripName}
            </button> 

            :

            <button onClick={()=>{setTripWindowStatus(true)}}> 
              Create Trip 
            </button>   
          }
        </div> */}

        {/* {
          tripWindowStatus && 
          <div>
            <div>
              <input 
              onChange={(event)=>{setTripName(event.target.value)}}
              value={tripName}
              type='text'
              maxLength={50}
              />
            </div>   
            <div>
              <button onClick={()=>{setTripWindowStatus(false)}}>
                close 
              </button>
              <button onClick={()=>{handleCreateTrip()}}>
                Done
              </button>
            </div>  
          </div>   
        }     */}
  

        {/* //* Main Itinerary Content and Map */}
        <div className='conatiner_p-Content-Ai_CreateItinerary'> 

          <div>
            <PromptAndOverview />
          </div>

          <div className='container_p-NavigationButtons-Ai_CreateItinerary'>
            
            <button
            className={activeNavigationCategory_ItineraryResult === "day_wise" ? 'heading-Active-Day-Ai_CreateItinerary' : 'heading-unActive-Day-Ai_CreateItinerary'}
            onClick={()=>{handleActive_ItineraryResultClicked("day_wise")}}
            >
              Itinerary
            </button>

            { isMobile &&
              <button
              className={activeNavigationCategory_ItineraryResult === "day_wise_map" ? 'heading-Active-Day-Ai_CreateItinerary' : 'heading-unActive-Day-Ai_CreateItinerary'}
              onClick={()=>{
                handleActive_ItineraryResultClicked("day_wise_map")
                if(getRoute_buttonStatus === "show"){
                  handle_CallGetRoute()
                }
                isMobile && openPopup()
              }}
              >
                Map - Itinerary
              </button>
            }

            {/* <button
            className={activeNavigationCategory_ItineraryResult === "ai_suggestions" ? 'heading-Active-Day-Itinerary_TO' : 'heading-unActive-Day-Itinerary_TO'}
            onClick={()=>{handleActive_ItineraryResultClicked("ai_suggestions")}}
            >
              Ai Suggestions
            </button> */}

            <button
            className={activeNavigationCategory_ItineraryResult === "how_to_reach" ? 'heading-Active-Day-Ai_CreateItinerary' : 'heading-unActive-Day-Ai_CreateItinerary'}
            onClick={()=>{
              handleActive_ItineraryResultClicked("how_to_reach")
              isMobile && openPopup()
            }}
            >
              How To Reach
            </button>

            <button
            className={activeNavigationCategory_ItineraryResult === "ask_questions" ? 'heading-Active-Day-Ai_CreateItinerary' : 'heading-unActive-Day-Ai_CreateItinerary'}
            onClick={()=>{handleActive_ItineraryResultClicked("ask_questions")}}
            >
              Ask Questions
            </button>
{/* 
            <button
            className='button-createItinerary-Ai_CreateItinerary'
            >
              Create Trip <MdOutlineModeOfTravel />
            </button> */}

          </div> 

          <div>

            {
              activeNavigationCategory_ItineraryResult === "day_wise" && 
              // <div>
              //   <DayWise />
              // </div>   
                // isMobile ? (
                //   <div>
                //     <DayWise_Mobile />
                //   </div>
                // ) : (
                <div>
                  <DayWise />
                </div>
                // )
            }

            {
              activeNavigationCategory_ItineraryResult === "day_wise_map" && 
      
                <div>
                  <DayWise_Mobile />
                </div>
                
            }

            {
              activeNavigationCategory_ItineraryResult === "ai_suggestions" && 
              <div>
                <Ai_Suggestions />
              </div>   
            }


            {activeNavigationCategory_ItineraryResult === "how_to_reach" && (
              isMobile ? (
                <div>
                  <HowToReach_Mobile />
                </div>
              ) : (
                <div>
                  <HowToReach />
                </div>
              )
            )}


            {
              activeNavigationCategory_ItineraryResult === "ask_questions" && 
              <div>
                <Ask_Questions />
              </div>   
            }
          </div>  


        </div>

      </div>

        {/*//? Map Ai Itinerary */}
        {/* {hoverData.lat} */}
        {
          (lat && lng) &&
          <div
          className='container_p-map-Ai_CreateItinerary'
          >

            <div 
            className='container_s-map-Ai_CreateItinerary'
            >
              <Map_Ai_Itinerary />
            </div>

          </div>
        }

    </div> 

  </div>
  )
}


export default Ai_CreateItinerary



