import React from 'react'
import './AboutUs.css'
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from 'react-router-dom';

function AboutUs() {
  return (
    <div>
      {/* <button className='contactUs-backButton'> Back Home</button> */}
      <Link to='/' className='aboutUs-backButton'><AiOutlineArrowLeft/></Link>
      <div className='aboutUs-mainContainer'>
         {/* <div className='aboutUs-lineCss'> Hey,<br></br> we promise to update this page soon, before 10th Oct. We are just 2 developers doing everything. <br></br> <br></br>Anyways, to connect you can visit "contact us" page.
         <br></br> <br></br>
         Cheers 🙂</div> */}

        <div className='aboutUs-lineCss'> Hi, we are making in-country and foriegn trip planning easy, less time-consuming, and efficient. <br></br>
        <a href='https://www.linkedin.com/in/rishabhtyagi01/' target="_blank">CEO's Linkedin</a> <br></br>
        <a href='https://www.linkedin.com/in/rishabhtyagi01/' target="_blank">CTO's Linkedin</a>
 
         <br></br> <br></br>
         Cheers 🙂</div>
         

        </div>  
       
    </div>
  )
}

export default AboutUs