import React, { useState, useEffect } from 'react'

import axios from 'axios';

import { server_baseUrl } from '../../Server Side Hit Url/ServerHitUrlPart';

function Search_States(props) {

  const {handleSearchResultClick} = props;

  // useEffect(() => {
  //   handleSearch
  // }, [])

  const [userInput, setUserInput] = useState()
  const [inputResult, setInputResult] = useState([])

  const handleUserInput = async (event) => {
    const inputValue = event.target.value;

    if(inputValue){

      //set the user input
      setUserInput(event.target.value)

      const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/nearby_ai/get_state_name`, {inputValue})
      
      // console.log("handleUserInput", response.data);
      
      if(response.data.length > 0){
        setInputResult([...response.data])
      }else{
        setInputResult([])
      }
    }
  }

  return (
    <div>Search_States
      <div>
        <input 
          placeholder='search state...' 
          value={userInput}
          onChange={handleUserInput}   
        />
      </div>

      { inputResult &&
          inputResult.map((data, index) => {
            return(
              <div>
                <div>
                  <button onClick={()=>{handleSearchResultClick(data.name, data.type, data.lat, data.lng)}}>
                    {data.name}
                  </button>
                  &nbsp; <br></br>
                </div>
              </div>  
            )
        })
      }

    </div>
  )
}

export default Search_States