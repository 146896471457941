import React from 'react'

//import css
import './Saved_Places_CI.scss'

//import react context
import {useCreateItineraryPlanTrip} from '../../../../../Plan Trip/Plan Trip - Components/Create Itinerary/Manage/Context/CreateItinerary_Context';
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip';

function Saved_Places_CI() {


  const {
    stateName
  } = useValidatePlanTrip()

  const {
    savedPlacesData, placesInfo
  } = useCreateItineraryPlanTrip()

  
  const modified_state_name = stateName.toLowerCase().replace(/\s+/g, '_');

  return (
    <div className='conatiner_p-SavedPlacesCI'>
      
      <div className='conatiner_s-SavedPlacesCI'
      style={{overflow:'scroll'}}
      >

{/* //? Here, when adding hotels and more, you need to add use a more unibversal term in place of "savedPlacesData" where your respective saved entity places would be soaved too...*/}

        {/*//! Saved Tourist Places  */}
        { savedPlacesData && placesInfo && 
            savedPlacesData.map((data) => {
              return(
                placesInfo.map((placeData, index) => {
                  if(data.id === placeData.id){
                  return(
                    <div key={index}
                    className='conatiner_p-card-SavedPlacesCI'
                    // className='card-TouristPlace-SavedPlacesCI'
                    >
                      <img 
                        className="image-Card-TouristPlace-SavedPlacesCI" 
                        // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/create_itinerary_5.jpeg'
                        src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${data.id}-01.jpg`}
                        alt="Image 1" 
                      />

                      <div className="heading-placeName-TouristPlace-SavedPlacesCI">
                        {placeData.name}
                      </div>

                      <div className="container_t-placeName-TouristPlace-MustVisit">

                        <div className='heading-2-placeName-TouristPlace-SavedPlacesCI'>
                        ({parseFloat(placeData.rating).toFixed(1)}) &nbsp; 
                        </div>

                        <div className='heading-3-placeName-TouristPlace-SavedPlacesCI'>
                          {
                            placeData.review_count ? placeData.review_count.toLocaleString('en-IN') : 'N/A'
                          }
                        </div>

                        <div
                        className='heading-4-card-placeName-TouristPlace-SavedPlacesCI'
                        >
                          #{placeData.category}
                        </div>

                      </div>


                      {
                        (placeData.explore_duration && placeData.explore_duration != "") &&
                        <div 
                        className='heading-4-card-duration-TouristPlace-SavedPlacesCI'
                        >
                          Explore in: {placeData.explore_duration} hrs
                        </div>
                      }



                      {/* <div 
                      className="container_t-2-placeName-TouristPlace-SavedPlacesCI"
                      >

                        <div
                        className='heading-4-card-placeName-TouristPlace-SavedPlacesCI'
                        >
                          #{placeData.category}
                        </div>

                      </div> */}


                      {/* <div className='PlaceCardsLineDiv-PlaceCardsCss02'>
                        <div className='line-card-SavedPlacesCI'></div> 
                      </div> */}

                      {/* <div className='PlaceCardsButtonDiv-PlaceCardsCss02'>
                        <button className="button-viewMore-card-SavedPlacesCI" 
                        onClick={(e) => {
                          e.stopPropagation(); 
                          // handle_ViewMore_TouristPlaces(data.sn);
                        }}>
                            View More
                        </button>
                      </div> */}

                    </div>
                  )
                }
                })
              )
            })
        }

        <div style={{height:'100px'}}></div>

      </div>
    </div>
  )
}

export default Saved_Places_CI