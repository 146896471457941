import React, {useRef} from 'react'

//import css
import './Hotels_Top10.scss'

//import icon
import {AiOutlineLeft} from 'react-icons/ai'
import {AiOutlineRight} from 'react-icons/ai'

//
import StarRatingComponent from 'react-star-rating-component';


function Hotels_Top10(props) {

    const{hotel_T10_Data, hotel_about_data, tripDestinationType} = props;

    //!scroll
    const containerRef = useRef()

    const scrollLeft = () => {
        if(containerRef.current){
            containerRef.current.scrollBy({
                left: -750,
                behavious: 'smooth',
            })
        }
    }

    const scrollRight = () => {
        if(containerRef.current){
            containerRef.current.scrollBy({
                left: 750,
                behavious: 'smooth',
            })
        }
    }

  return (
    <div 
    className='container_p-places-Overview_D_Top10'
    >

    {/* <div className='container_p-places-top10-Overview_D_Top10'> */}

    {/* //!Tourist Places - Start */}
    {/* <div
    className='container_p-places-Overview_D_Top10'
    > */}

        <div
        className='container_t-1-imgHead-places-Hotel_Top10'
        >
            <div
            // className='container_t-image-places-Overview_D_Top10'
            >
                <img 
                className='container_t-image-places-Hotel_Top10'
                src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/hotel_image_1_no_bg.png'
                />
            </div>     
            <div className='heading-bestTime-Hotel_Top10'>
                Famous Hotels
            </div> 
        </div>  

        <div className='container_t-2-main-places-Hotel_Top10'>

            <button 
            className='scroll-button-left-Hotel_Top10'
            onClick={scrollLeft}
            >
                <AiOutlineLeft />
            </button>

        
            <div 
            className='container_t-Content-places-Hotel_Top10'
            ref={containerRef}
            >

                {
                hotel_T10_Data.map((data, index) => {
                return(
                <div
                className='card-place-Hotel_Top10' 
                >
                
                    <div 
                    style={{width:'100%'}}
                    >   

                    <img 
                    className='image-place-card-Hotel_Top10'
                    src='https://dynamic-media-cdn.tripadvisor.com/media/photo-o/21/97/e2/ae/hotel-exterior.jpg?w=700&h=-1&s=1'
                    />

                    <div
                    className='heading-placeName-card-Hotel_Top10'
                    >
                        {data.name} 
                    </div>    


                    <div
                    className='star-card-Hotel_Top10'
                    >

                        <StarRatingComponent
                        name="rating"
                        starCount={5}
                        value={data.rating}
                        editing={false}
                        starColor="gold" // This sets the color for the filled stars
                        emptyStarColor="#ccc" // This sets the color for the empty stars
                        />  

                        <div style={{fontSize:'18px'}}>
                            &nbsp; {data.rating}
                        </div> 

                    </div>

                    <div>
                    {
                        (data.reviewCount) ? 
                        <div className='reviewCount-card-Hotel_Top10'>
                            ({data.reviewCount.toLocaleString('en-IN')})
                        </div> 
                        : 'N/A'
                    }
                    </div>


                    {
                        data.min_price && 
                        <div className='text-card-Hotel_Top10'>
                                <i>starting from...</i>
                                ₹ {data.min_price.toLocaleString('en-IN')}
                        </div>
                    }


                    
                    { hotel_about_data &&
                        <div
                        className='text-about-hotels-card-Hotel_Top10'
                        >
                            {
                                hotel_about_data.map(dataAbout => {
                                    if(dataAbout.id === data.id && dataAbout.about_text){
                                        return( 
                                            <div className='content-overview-card-Hotel_Top10'>    
                                                {dataAbout.about_text
                                                ? dataAbout.about_text.split(' ').slice(0, 50).join(' ') +
                                                (dataAbout.about_text.split(' ').length > 50 ? '...' : '')
                                                : ''}
                                            </div>
                                        )
                                    }
                                })
                            }                    
                        </div>   
                    } 

                    ...


                    
                    {
                        hotel_about_data.map(dataAbout => {
                            if(dataAbout.id === data.id){
                                return(
                                    <div>

                                        {
                                            dataAbout.available_features && 
                                            <div className='container_t-icons-hotels-card-Hotel_Top10'>
                                                {JSON.parse(dataAbout.available_features).map((item, index) => (
                                                    <div 
                                                    key={index}
                                                    className='icon-hotels-card-Hotel_Top10'
                                                    >
                                                        #{item}
                                                    </div>
                                                ))}
                                            </div>
                                        }

                                    </div>     
                                )
                            }
                        })
                    }


                    </div>    

                </div>
            )}
            )}

        </div>

        <button
        className='scroll-button-right-Hotel_Top10'
        onClick={scrollRight}
        >
            <AiOutlineRight />
        </button>

        </div>


    {/* </div> */}

    {/* </div> */}
    {/* //!Hotels - End     */}


    </div>
  )
}

export default Hotels_Top10