import React, {useState} from 'react'

// import scss
import './Overview_VM.scss'

//
import ReactMarkdown from 'react-markdown';

function Overview_VM(props) {

  const{viewMoreData_PlaceInfo, viewMoreData_About, activeId_viewMore_touristPlaces} = props;  

  const parseTimings = (data) => {
    // Remove the "Suggest new hours" part
    const cleanedData = data.replace('Suggest new hours', '');

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const regex = new RegExp(`(${days.join('|')})`, 'g');
    const parsedTimings = cleanedData.split(regex).filter(Boolean);

    // Combine the day names with their corresponding timings
    const formattedTimings = [];
    for (let i = 1; i < parsedTimings.length; i += 2) {
      formattedTimings.push(parsedTimings[i - 1] + ' ' + parsedTimings[i]);
    }

    return formattedTimings;
  };

  return (
    <div>

    { viewMoreData_PlaceInfo && 
        viewMoreData_PlaceInfo.map((placeData, index) => {
        if(placeData.id === activeId_viewMore_touristPlaces){
            return(
            <div>

                {
                    placeData.timings &&
                    <div>

                        <div
                        className='heading-1-Overview_VM'
                        > 
                        Timmings
                        </div> 

                        <div
                        className='conatiner_t-timming-Overview_VM'
                        >
                        {parseTimings(placeData.timings).map((timing, index) => (
                            <div key={index}
                            className='time-timming-Overview_VM'
                            >
                                {timing}
                            </div>
                        ))}
                        </div>

                    </div> 
                }  

                    <br></br>

                {
                placeData.explore_duration &&
                <div>

                    <div
                    className='heading-1-Overview_VM'
                    >
                        Explore Duration
                    </div>

                    <div
                    className='heading-2-Overview_VM'
                    >  
                        {placeData.explore_duration} hours
                    </div>

                </div>   
                }

                <br></br>

                {
                placeData.address &&
                <div>

                    <div
                    className='heading-1-Overview_VM'
                    >
                        Address
                    </div>

                    <div
                    className='heading-2-Overview_VM'
                    >  
                        {placeData.address}
                    </div>

                </div>   
                }

                <br></br>

                {
                placeData.tel &&
                <div>

                    <div
                    className='heading-1-Overview_VM'
                    >
                        Phone Number
                    </div> 

                    <div
                    className='heading-2-Overview_VM'
                    >   
                        {placeData.tel}
                    </div>

                </div>   
                }

                <br></br>

                {
                placeData.website_link &&
                <div
                // className='conatiner_t-3-Overview_VM'
                style={{marginTop:'4px'}}
                >

                    <div
                    className='heading-1-Overview_VM'
                    >
                        Website Link
                    </div>

                    <div
                     className='conatiner_t-3-Overview_VM'
                    >
                        <a
                        href={placeData.website_link} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className='conatiner_p-GoogleUrl-Overview_VM'
                        >

                            <div
                            className='link-1-Overview_VM'
                            >  
                                {placeData.website_link}
                            </div>

                            <img
                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/share_icon_1.png'
                            className='img-link-Document-TripDocuments'
                            />

                        </a>    
                    </div>    

                </div>   
                }

                <br></br>

                {
                placeData.url &&
                <div
                style={{marginTop:'8px'}}
                >

                    <div
                    className='heading-1-Overview_VM'
                    >
                        See on Google
                    </div>

                    <div
                     className='conatiner_t-3-Overview_VM'
                    >
                        <a
                        href={placeData.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className='conatiner_p-GoogleUrl-Overview_VM'
                        >

                            <div
                            className='link-1-Overview_VM'
                            >  
                                {placeData.url}
                            </div>

                            <img
                            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/share_icon_1.png'
                            className='img-link-Document-TripDocuments'
                            />

                        </a>    
                    </div>    

                </div>   
                }

            </div>
            )
        }
        })
    }  


    </div>
  )
}

export default Overview_VM