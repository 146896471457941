import React, { useState, useEffect, useRef } from 'react'

//import css
import './HowToReach.scss';
import './HowToReach_Mobile.scss';

//importing react context
import { useAiCreateItinerary } from '../../Manage/Context/Ai_CreateItineraryContext';

//
import ReactMarkdown from 'react-markdown';

//import react icons
import { FaPlaneDeparture } from "react-icons/fa";
import { GiRailRoad } from "react-icons/gi";
import { FaBusAlt } from "react-icons/fa";
import { GrLocationPin } from "react-icons/gr";
import { IoMdCloseCircle } from "react-icons/io";
import Map_Ai_Itinerary from '../Map/Map_Ai_Itinerary';

function HowToReach_Mobile() {

  const {
    //!states
    loadReachData_Status,
    overview_reach,
    airportData_reach,
    railwayData_reach,
    busData_reach,
    //!functions
    handleLoadReachData, 
    destinationName, destinationType,
    handleActive_ItineraryResultClicked,
    //map
    lat, lng,
    //map
    handleHoverLocation_Map, handleHoverOffLocation_Map,
    handleClickLocation_Map
  } = useAiCreateItinerary()
  
  useEffect(() => {

    if(!loadReachData_Status && !overview_reach){
      handleLoadReachData()
    }

  }, [])


  const [isExpanded, setIsExpanded] = useState(false);

  // Define a character limit for "Read More"
  const charLimit = 100;
  
  // Toggle between showing full content or truncated content
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };


  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
    document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };

  //!states
  const[activeStatus_HTR, setActiveStatus_HTR] = useState(false)
  const[navigation_active_HTR_M, setNavigation_active_HTR_M] = useState("airport")




  //!scroll - active on the map - place in the center

  // const [hoverData, setHoverData] = useState(null);
  const containerRef = useRef(null);
  const containerRefs = useRef([]);

  const isElementInCenter = (element, container) => {
    if (!container) return false;

    const rect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    
    // Calculate element's center relative to the container
    const elementCenter = (rect.left + rect.right) / 2 - containerRect.left;
    const containerWidth = containerRect.width;
    
    // Define center range relative to the container
    const centerThreshold = containerWidth * 0.25;
    const containerCenter = containerWidth / 2;
    
    return elementCenter >= (containerCenter - centerThreshold) && elementCenter <= (containerCenter + centerThreshold);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      containerRefs.current.forEach((element, index) => {
        if (isElementInCenter(element, containerRef.current)) {
          // Trigger click when the element is in the center
          const data = airportData_reach[index];
          handleClickLocation_Map(data.lat, data.lng, data.mode_name, "air");
        } else {
          // Optional: Handle hover off if needed
          handleHoverOffLocation_Map();
        }
      });
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll); // Cleanup
      }
    };
  }, [airportData_reach]);


  return (
    <div>

      <div 
        className='popup-background-1-Entities_TD'
        onClick={()=>{
          handleActive_ItineraryResultClicked("day_wise")
          closePopup()
        }}
      >
        {/* Close */}
      </div>

      <div className='popup_p-3-HowToReach_Mobile'>
       <div className='popup_s-addNote-HowToReach_Mobile'>

        {/* <button
          className='button-close-HowToReach_Mobile'
          onClick={()=>{
            handleActive_ItineraryResultClicked("day_wise")
            closePopup()
          }}
        >
          <IoMdCloseCircle />
        </button> */}



    {
      (lat && lng) &&
      <div
      className='container_p-map-HowToReach_Mobile'
      >

        <div 
        className='container_s-map-HowToReach_Mobile'
        >
          <Map_Ai_Itinerary />
        </div>

      </div>
    }


    {/* <div
    className='container_p-Main-Content-HowToReach_Mobile'
    > */}

        <button
          // className='button-close-HowToReach_Mobile'
          className='button-close-DayWise_Mobile'
          onClick={()=>{
            handleActive_ItineraryResultClicked("day_wise")
            closePopup()
          }}
        >
          <IoMdCloseCircle />
        </button>


        {/*//! Navigation */}
        <div
        className='container_p-navigation-HowToReach_Mobile'
        >

          <button
          className='button-active-aiOverview-HTR_M'
          onClick={()=>{setActiveStatus_HTR(true)}}
          >
            View Ai Overview
          </button>

          <button
          className={navigation_active_HTR_M === 'airport' ? 'button-active-navigation-HTR_M' : 'button-unActive-navigation-HTR_M'}
          onClick={()=>{setNavigation_active_HTR_M("airport")}}
          >
            Airports
          </button>

          <button
          className={navigation_active_HTR_M === 'train' ? 'button-active-navigation-HTR_M' : 'button-unActive-navigation-HTR_M'}
          onClick={()=>{setNavigation_active_HTR_M("train")}}
          >
            Railway Stations
          </button>

          <button
          className={navigation_active_HTR_M === 'bus' ? 'button-active-navigation-HTR_M' : 'button-unActive-navigation-HTR_M'}
          onClick={()=>{setNavigation_active_HTR_M("bus")}}
          >
            Bus Stands
          </button>

        </div>

        {
          overview_reach ?
          
          activeStatus_HTR && 
          <div>

            {/*//! Close*/}
            <div 
              className='popup-background-1-Entities_TD'
              onClick={()=>{
                setActiveStatus_HTR(false)
              }}
            >
              {/* Close */}
            </div>


            {/*//! Content */}
            <div className='popup_p-3-2-HowToReach_Mobile'>
             <div className='popup_s-2-addNote-HowToReach_Mobile'>

              <div
                className='content-1-data-HowToReach'
              >

                <button
                  className='button-2-close-HowToReach_Mobile'
                  onClick={()=>{
                    setActiveStatus_HTR(false)
                  }}
                >
                  <IoMdCloseCircle />
                </button>

                <ReactMarkdown>
                  {overview_reach}
                </ReactMarkdown>

              </div>  

             </div> 
            </div>

          </div> 
          :
          <div
          className='loading-1-data-PromptAndOverview'
          >
            Loading AI Overview ...
          </div>
        }


        {/* <div>  */}

        {
          navigation_active_HTR_M === "airport" && airportData_reach && 

          <div
          // className='container_p-Cards-Air-HowToReach_Mobile'
          ref={containerRef}
          className='conatiner_p-Places-HowToReach_Mobile'
          >

            {
              airportData_reach.map((data, index) => {
                return(
                  <button key={index}
                  className='card-Air-HowToReach_Mobile'
                  ref={(el) => (containerRefs.current[index] = el)}
                  onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name, "air")}}
                  onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name, "air")}}
                  onMouseOut={()=>{handleHoverOffLocation_Map()}}
                  >
                      <div className='text-1-air-Reach_D'>
                          {data.mode_name}
                      </div>
  
                      <div className='text-2-air-Reach_D'>
                          Code: {data.mode_code}
                      </div>
  
                      <div className='text-3-air-Reach_D'>
                          <div className='text-3-1-air-Reach_D'>
                              in {data.mode_district} 
                          </div>
                          {data.mode_state_name && 
                          <div className='text-3-1-air-Reach_D'>
                              , {data.mode_state_name}.
                          </div>
                          }
                      </div>
  
                      {
                      data.mode_g_review &&
                      <div className='text-4-air-Reach_D'>
                          G reviews: ({data.mode_g_review.toLocaleString('en-IN')})
                      </div>
                      }     
                      
                  
                      { data.distance &&
                      destinationType !== "state" ?
                      <div className='text-5-air-Reach_D'>
                          <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                      </div> :
                      null
                      }

                      {
                        data.mode_type && 
                        <div className='text-3-1-air-Reach_D'>
                          #{data.mode_type} 
                        </div>
                      }
                      
                  </button> 
                )
              })
            }
          </div>  

        }

        
        {
          navigation_active_HTR_M === "train" && railwayData_reach && 
          <div> 

            {/* <div
            className='heading-1-mode-Reach_D'
            > 
              Raiway Stations
            </div>    */}

            <div
            className='conatiner_p-2-Places-HowToReach_Mobile'
            >
              
              {
                railwayData_reach.map((data, index) => {
                  return(
                    <button
                    className='card-Air-HowToReach_Mobile'
                    onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name, "train")}}
                    onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name, "train")}}
                    onMouseOut={()=>{handleHoverOffLocation_Map()}}
                    >
        
                      <div className='text-1-air-Reach_D'>
                          {data.mode_name}
                      </div>
        
                      <div className='text-2-air-Reach_D'>
                          Code: {data.mode_code}
                      </div>
        
                      <div className='text-3-air-Reach_D'>
                          <div className='text-3-1-air-Reach_D'>
                              in {data.mode_state_name} 
                          </div>
                          {/* {data.mode_state_name && 
                          <div className='text-3-1-air-Reach_D'>
                              , {data.mode_state_name}.
                          </div>
                          } */}
                      </div>
        
                      {/* {
                          destinationType !== 'state' ?
                          <div>
                              in {data.mode_state_name}.
                          </div>    
                          : 
                          destinationName !== data.mode_state_name ?
                          <div>
                              in {data.mode_state_name}.
                          </div> 
                          : null    
                      } */}
        
                        { data.distance &&
                          destinationType !== "state" ?
                          <div className='text-5-air-Reach_D'>
                            <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                          </div> :
                            null
                        }

                        {/* {
                          data.mode_type && data.mode_type === "major" || (data.mode_type === "A" || data.mode_type === "A1" || data.mode_type === "B") &&
                          <div className='text-3-1-air-Reach_D'>
                            # A Major Train Station
                          </div>
                        } */}


                    </button> 
                  )
                })
              }
            </div>  
            
          </div>   
        }

        
        {
          navigation_active_HTR_M === "bus" && busData_reach && 
          <div> 

            {/* <div
            className='heading-1-mode-Reach_D'
            > 
              Bus Stands
            </div>    */}

            <div
            className='conatiner_p-2-Places-HowToReach_Mobile'
            >
              {
                busData_reach.map((data, index) => {
                  return(
                    <button
                    className='card-Air-HowToReach_Mobile'
                    onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name, "bus")}}
                    onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name, "bus")}}
                    onMouseOut={()=>{handleHoverOffLocation_Map()}}
                    >
                      <div className='text-1-air-Reach_D'>
                          {data.mode_name}
                      </div>

                      {
                          destinationType === "state" ?
                          <div className='text-3-1-air-Reach_D'>
                              in {data.mode_state_name}.
                          </div> 
                          : null    
                      }
                      
                      { data.distance &&
                      destinationType !== "state" ?
                      <div className='text-5-air-Reach_D'>
                          <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                      </div> :
                      null
                      }

                    </button>
                  )
                })
              }
            </div>  
            
          </div>   
        }

      {/* </div>   */}
      
  {/* </div>     */}

      </div> 
      </div>
      
    </div>
  )
}

export default HowToReach_Mobile