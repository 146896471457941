import React, {useState, useEffect} from 'react'

//import scss
import './ViewPrices_CH.scss'
import './ViewPrices_CH_Mobile.scss'

//import icons
import { FaCircleChevronLeft } from "react-icons/fa6";
import { FaCircleChevronRight } from "react-icons/fa6";
import { FaWifi } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { MdOutlineLocalBar } from "react-icons/md";
import { MdOutlineFreeBreakfast } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { MdAccessible } from "react-icons/md";
import { FaSwimmingPool } from "react-icons/fa";
import { MdSpa } from "react-icons/md";
import { MdOutlineLocalLaundryService } from "react-icons/md";
import { MdOutlineSoupKitchen } from "react-icons/md";
import { MdOutlineAirportShuttle } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import { MdOutlinePool } from "react-icons/md";
import { TbParking } from "react-icons/tb";
import { MdOutlineRoomService } from "react-icons/md";
import { IoRestaurantOutline } from "react-icons/io5";
import { IoOptionsSharp } from "react-icons/io5";
import { MdOutlineFreeCancellation } from "react-icons/md";
import { IoRibbonOutline } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaRegStarHalf } from "react-icons/fa";
import { GiStarFormation } from "react-icons/gi";
import { TbSortAscendingNumbers } from "react-icons/tb";
import { TbSortDescendingNumbers } from "react-icons/tb";
import { GrRevert } from "react-icons/gr";
import { MdManageSearch } from "react-icons/md";
import { GoLinkExternal } from "react-icons/go";


function ViewPrices_CH(props) {

    const{openPopup, closePopup, setView_prices_popup, ptExclusiveData, priceData, data_id, data_has_price, bestDealData, hotel_name, loadingStatusHotels, setLoadingStatusHotels} = props;


    const [isMobile, setIsMobile] = useState(window.innerWidth <= 799); // Initialize with the current width

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 799); // Update state on window resize
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup event listener on unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


  return (
    <div>

    <div 
      onClick={()=>{
          setView_prices_popup(false)
          closePopup()
      }}
      className='popup-background-1-Entities_TD'
    >
    </div>
  
    <div 
        // className='popup_p-2-addNote-ViewPrices_CH'
       className={isMobile ? 'popup_p-2-addNote-ViewPrices_CH_Mobile' : 'popup_p-2-addNote-ViewPrices_CH'}
    >
      <div className='popup_s-2-addNote-ViewPrices_CH'>



        <button
            className='button-close-DayWise_Mobile'
            onClick={()=>{
            // setNavigation_content_map("onlyMap")
            closePopup()
            }}
        >
            <IoMdCloseCircle />
        </button>


        {/* {
            loadingStatusHotels === "price_data" && 
            <div>
                <b>Prices are loading</b>
            </div>
        } */}

        {
            (loadingStatusHotels === "price_data") &&
            <div className="overlay">
                <div className="spinner-container">
                    <div className="spinner"></div>
                    <p>Loading...</p>
                </div>
            </div>
        }


  
        <div className='heading-1-heading-ViewPrices_CH'>
          {hotel_name}
        </div>
  
        
        {priceData ? (
          <div className='#container_p-otherPrices-ViewPrices_CH'>
            <div className='#container_s-cards-otherPrices-ViewPrices_CH'>

              {/*//!#1  */}
              {data_has_price && bestDealData.map((priceData, index) => {
                if (priceData.id === data_id) {
                  return (
                    <div className='container_s-card-bestDeal-ViewPrices_CH' key={index}>


                      <div className='heading-1-bestDeal-ViewPrices_CH'>
                        #Best Deal
                      </div>
                    
                      <div
                    //   className='conatiner_t-content-bestDeals-ViewPrices_CH'
                      className={isMobile ? 'conatiner_t-content-bestDeals-ViewPrices_CH_Mobile' : 'conatiner_t-content-bestDeals-ViewPrices_CH'}
                      >
                      
                        <div className='text-1-bestDeal-ViewPrices_CH'>
                            {priceData.hotel_name}
                        </div>
    
                        {isMobile && <div style={{marginTop:'10px'}}></div>}

                        {priceData.hotel_price && (
                            <div className='text-2-bestDeal-ViewPrices_CH'>
                            {priceData.hotel_price}
                            </div>
                        )}
    
                        {isMobile && <div style={{marginTop:'10px'}}></div>}

                        {priceData.hotel_link && (
                            <a 
                            href={priceData.hotel_link}
                            target="_blank" 
                            rel="noopener noreferrer"
                            className='link-1-MetaData-bestDeal-ViewPrices_CH'
                            >
                                {
                                    priceData.official_site ?
                                    <div
                                    className='text-1-officialLink-bestDeal-ViewPrices_CH'
                                    >
                                        Visit Official Site <GoLinkExternal />
                                    </div> 
                                    :
                                    <div
                                    className='text-1-link-bestDeal-ViewPrices_CH'
                                    >
                                        Visit Site <GoLinkExternal />
                                    </div>    
                                }
                            </a>
                        )}
    
                        {isMobile && <div style={{marginTop:'6px'}}></div>}

                        <div className='container_t-MetaData-bestDeal-ViewPrices_CH'>  

                            {priceData.free_breakfast && (
                            <div className='container_t-amenities-otherPrices-ViewPrices_CH'>
                                <div className='icon-1-amenities-otherPrices-ViewPrices_CH'>
                                <MdOutlineFreeBreakfast /> 
                                </div>
                                <div className='text-1-amenities-otherPrices-ViewPrices_CH'>
                                Free Breakfast
                                </div>
                            </div>
                            )}
    
                            {priceData.free_wifi && (
                            <div className='container_t-amenities-otherPrices-ViewPrices_CH'>
                                <div className='icon-1-amenities-otherPrices-ViewPrices_CH'>
                                <FaWifi /> 
                                </div>
                                <div className='text-1-amenities-otherPrices-ViewPrices_CH'>
                                Free Wifi
                                </div>
                            </div>
                            )}
    
                            {priceData.free_parking && (
                            <div className='container_t-amenities-otherPrices-ViewPrices_CH'>
                                <div className='icon-1-amenities-otherPrices-ViewPrices_CH'>
                                <TbParking /> 
                                </div>
                                <div className='text-1-amenities-otherPrices-ViewPrices_CH'>
                                Free Parking
                                </div>
                            </div>
                            )}
    
                            {/*//? TEMP: Hidding till me get the latest data */}
                            {/* {priceData.free_cancellation_detail && (
                            <div className='container_t-amenities-otherPrices-ViewPrices_CH'>
                                <div className='icon-1-amenities-otherPrices-ViewPrices_CH'>
                                <MdOutlineFreeCancellation /> 
                                </div>
                                <div className='text-1-amenities-otherPrices-ViewPrices_CH'>
                                Free Cancellation {priceData.free_cancellation_detail}
                                </div>
                            </div>
                            )} */}
                            
                        </div>

                        </div>   

                    </div>
                  );
                }
              })}




              {/*//!#2 */}
              {priceData && priceData.map((priceData, index) => {
                if (priceData.id === data_id && priceData.official_site) {
                  return (
                    <div className='container_s-card-bestDeal-ViewPrices_CH' key={index}>


                      <div className='heading-1-bestDeal-ViewPrices_CH'>
                        #Book Direct
                      </div>
                    
                      <div
                      className='conatiner_t-content-bestDeals-ViewPrices_CH'
                      >
                      
                        <div className='text-1-bestDeal-ViewPrices_CH'>
                            {priceData.hotel_name}
                        </div>
    
                        {priceData.hotel_price && (
                            <div className='text-2-bestDeal-ViewPrices_CH'>
                            {priceData.hotel_price}
                            </div>
                        )}
    
                        {priceData.hotel_link && (
                            <a 
                            href={priceData.hotel_link}
                            target="_blank" 
                            rel="noopener noreferrer"
                            className='link-1-MetaData-bestDeal-ViewPrices_CH'
                            >
                                {
                                    priceData.official_site ?
                                    <div
                                    className='text-1-officialLink-bestDeal-ViewPrices_CH'
                                    >
                                        Visit Official Site <GoLinkExternal />
                                    </div> 
                                    :
                                    <div
                                    className='text-1-link-bestDeal-ViewPrices_CH'
                                    >
                                        Visit Site <GoLinkExternal />
                                    </div>    
                                }
                            </a>
                        )}
    
                        <div className='container_t-MetaData-bestDeal-ViewPrices_CH'>  

                            {priceData.free_breakfast && (
                            <div className='container_t-amenities-otherPrices-ViewPrices_CH'>
                                <div className='icon-1-amenities-otherPrices-ViewPrices_CH'>
                                <MdOutlineFreeBreakfast /> 
                                </div>
                                <div className='text-1-amenities-otherPrices-ViewPrices_CH'>
                                Free Breakfast
                                </div>
                            </div>
                            )}
    
                            {priceData.free_wifi && (
                            <div className='container_t-amenities-otherPrices-ViewPrices_CH'>
                                <div className='icon-1-amenities-otherPrices-ViewPrices_CH'>
                                <FaWifi /> 
                                </div>
                                <div className='text-1-amenities-otherPrices-ViewPrices_CH'>
                                Free Wifi
                                </div>
                            </div>
                            )}
    
                            {priceData.free_parking && (
                            <div className='container_t-amenities-otherPrices-ViewPrices_CH'>
                                <div className='icon-1-amenities-otherPrices-ViewPrices_CH'>
                                <TbParking /> 
                                </div>
                                <div className='text-1-amenities-otherPrices-ViewPrices_CH'>
                                Free Parking
                                </div>
                            </div>
                            )}
    
                            {/*//? TEMP: Hidding till me get the latest data */}
                            {/* {priceData.free_cancellation_detail && (
                            <div className='container_t-amenities-otherPrices-ViewPrices_CH'>
                                <div className='icon-1-amenities-otherPrices-ViewPrices_CH'>
                                <MdOutlineFreeCancellation /> 
                                </div>
                                <div className='text-1-amenities-otherPrices-ViewPrices_CH'>
                                Free Cancellation {priceData.free_cancellation_detail}
                                </div>
                            </div>
                            )} */}
                            
                        </div>

                        </div>   

                    </div>
                  );
                }
              })}





                {/*//!#3 */}
                <div
                className='container_p-card-otherPrices-ViewPrices_CH'
                >       

                {priceData && priceData.map((priceData, priceIndex) => {
                    if ( !priceData.official_site && 
                    (
                        (!ptExclusiveData && priceData.id === data_id) ||
                        (ptExclusiveData && priceData.id === data_id && priceData.official_site !== 1)
                    )
                    ) {
                    return (
                        <div key={priceIndex} className='container_s-card-otherPrices-ViewPrices_CH'>
                        <div className='text-1-otherPrices-ViewPrices_CH'>
                            {priceData.hotel_name} 
                        </div>  
    
                        {priceData.hotel_price && (
                            <div className='text-2-otherPrices-ViewPrices_CH'>
                            {priceData.hotel_price}
                            </div>
                        )}
    
                        {priceData.hotel_link && (
                            <a 
                            href={priceData.hotel_link}
                            target="_blank" 
                            rel="noopener noreferrer"
                            className='link-1-MetaData-otherPrices-ViewPrices_CH'
                            >
                            
                                {
                                    priceData.official_site ?
                                    <div
                                    className='text-1-officialLink-bestDeal-ViewPrices_CH'
                                    >
                                        Visit Official Site <GoLinkExternal />
                                    </div> 
                                    :
                                    <div
                                    className='text-1-link-bestDeal-ViewPrices_CH'
                                    >
                                        Visit Site <GoLinkExternal />
                                    </div>    
                                }

                            </a>
                        )}
    
                        <div className='container_t-MetaData-otherPrices-ViewPrices_CH'>
                            {priceData.free_breakfast && (
                            <div className='container_t-amenities-otherPrices-ViewPrices_CH'>
                                <div className='icon-1-amenities-otherPrices-ViewPrices_CH'>
                                <MdOutlineFreeBreakfast /> 
                                </div>
                                <div className='text-1-amenities-otherPrices-ViewPrices_CH'>
                                Free Breakfast
                                </div>
                            </div>
                            )}
    
                            {priceData.free_wifi && (
                            <div className='container_t-amenities-otherPrices-ViewPrices_CH'>
                                <div className='icon-1-amenities-otherPrices-ViewPrices_CH'>
                                <FaWifi /> 
                                </div>
                                <div className='text-1-amenities-otherPrices-ViewPrices_CH'>
                                Free Wifi
                                </div>
                            </div>
                            )}
    
                            {priceData.free_parking && (
                            <div className='container_t-amenities-otherPrices-ViewPrices_CH'>
                                <div className='icon-1-amenities-otherPrices-ViewPrices_CH'>
                                <TbParking /> 
                                </div>
                                <div className='text-1-amenities-otherPrices-ViewPrices_CH'>
                                Free Parking
                                </div>
                            </div>
                            )}
    

                            {/*//? TEMP: Hidding till me get the latest data */}
                            {/* {priceData.free_cancellation_detail && (
                            <div className='container_t-amenities-otherPrices-ViewPrices_CH'>
                                <div className='icon-1-amenities-otherPrices-ViewPrices_CH'>
                                <MdOutlineFreeCancellation /> 
                                </div>
                                <div className='text-1-amenities-otherPrices-ViewPrices_CH'>
                                Free Cancellation {priceData.free_cancellation_detail}
                                </div>
                            </div>
                            )} */}

                        </div>
                        </div>
                    );
                    }
                })}

                </div>

  
            </div>
          </div>
        )
        :
        <div>
            No price info found. You would have to contact the hotel to know the prices.
        </div>    
        }
  
      </div>
    </div>     
  
  </div>
  
  )
}

export default ViewPrices_CH