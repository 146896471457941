import React, {useEffect, useState} from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';

//for generating unique id
import { v4 as uuidv4 } from 'uuid';

//importing scss
import './HowToReach_Main_Mobile.scss'

import { Helmet } from 'react-helmet'

//importing arrays
import {get_prompt_1, get_prompt_2} from './Prompts_HowToReach_Mobile'


//manage google analytics tracking
import { initGA, trackPageView } from '../../../Track Action/Google Tracking/GoogleTrackingAnalytics';
import { userAnalytics_customTrack_VisitsExits } from '../../../Track Action/Custom Tracking/CustomTrackingAnalytics';


//import react icons
import { FaPlaneDeparture } from "react-icons/fa";
import { GiRailRoad } from "react-icons/gi";
import { IoMdCloseCircle } from "react-icons/io";
import { FaBusAlt } from "react-icons/fa";
import { GrLocationPin } from "react-icons/gr";

// import other tools 
import axios from 'axios';
import Air from '../Components/Content/Air/Air_Mobile';
import Bus from '../Components/Content/Bus/Bus_Mobile';
import Train from '../Components/Content/Train/Train_Mobile';
import Map_HowToReach from '../Components/Map/Map_HowToReach_Mobile';

//import search tool
import {Search_Locations, Search_AllDestinations} from '../../../Universal Components/Search/Manage Search/Export/Export_Search'

// import react context 
import {useHowToReachContext} from '../Manage/Context/HowToReachContext_Mobile'
import Header_Reach from '../Components/Header/Header_Reach_Mobile';
import Search_Destinations_HTR from '../Components/Search/Search_Destinations_HTR';
import Search_Locations_HTR from '../Components/Search/Search_Locations_HTR';



function HowToReach_Main_Mobile() {


  const location = useLocation();
  //!Track user visit 
  //
  useEffect(() => {
    initGA();
  }, []);
  //
  useEffect(() => {
    trackPageView(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    // Call this function once when the page is loaded
    const date = Date.now();
    const start_time = Math.floor(date / 1000)

    const random_number = Math.floor(Math.random() * 1000000); 
    const concatenatedString = `${date}-${random_number}`;
    const user_temp_id = uuidv4(concatenatedString);  


    userAnalytics_customTrack_VisitsExits(user_temp_id, null, location.pathname, "enter", null);

    // Function to handle page exit
    const handleBeforeUnload = () => {

      const end_time = Math.floor(Date.now() / 1000);  // Convert milliseconds to seconds

      const stay_duration = end_time-start_time;

      userAnalytics_customTrack_VisitsExits(user_temp_id, null, location.pathname, "exit", stay_duration);
    };

    // Add event listener to call page exit event when the user tries to close or navigate away
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures this effect runs only once per page load

  //!End*----





  //import from useContext 
  const {
    //loading
    loadingStatusTravelModes, setLoadingStatusTravelModes, 
    //
    destinationName, destinationType,
    activeMode, setActiveMode,
    initialLoadStatus, 
    activeSearchLocationType, setActiveSearchLocationType,
    inputDestinationValue, 
    airContent, trainContent, busContent, 
    air_PlaceName, train_PlaceName, bus_PlaceName, 
    prompt, 
    //functions
    componentInitialLoad, 
    handleDestinationClick, handleDestinationClick_2,
    handleLoadTravelModeData_ModeClick, 
    lat, lng, zoom,
    fitAllLocations, setFitAllLocations,
    test
  } = useHowToReachContext();

  const navigate = useNavigate();

  //*Mode of Travel - air, bus, train
  const {firstMode, secondMode, thirdMode} = useParams();

  //*AI Tools - 
  //1. quick ai itinerary; 
  //*navigationId - 
  //nb-02, nb-03, nb-04
  const {unique_id, navigationId, ai_feature_id} = useParams();
  
  //other. 
  const {nameDestination, typeDestination, travelType} = useParams();

  useEffect(() => {

    componentInitialLoad(
      firstMode, 
      unique_id, navigationId, ai_feature_id,
      nameDestination, typeDestination, travelType
    );

  }, [])


  
  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  const[navigation_content_map, setNavigation_content_map] = useState("onlyMap")

  
  return(
    <div>

<Helmet>   

<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Assistant:wght@400&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap" rel="stylesheet" />

</Helmet>

      {/* <div onClick={test}>test </div> */}

      {
        (loadingStatusTravelModes === "loading_data") &&
        <div className="overlay">
          <div className="spinner-container">
            <div className="spinner"></div>
            <p>Loading Data...</p>
          </div>
        </div>
      }


      {/*//!When the map is active */}
      {/*//*Search */}
      {/* {
        (airContent.length > 0 || trainContent.length > 0 || busContent.length > 0) &&
      
        <div
        className='container_p-HowToReach_Main_Mobile'
        >
          <div className='container_s-HowToReach_Main'>

            <div
            className='container_t-1-HowToReach_Main'
            >
              <button
                onClick={()=>{setActiveSearchLocationType("destination")}}
                className={activeSearchLocationType === "destination" ? 'button-active-HowToReach_Main' : 'button-unactive-HowToReach_Main'}
              >
                Destination 
              </button>

              <button
                onClick={()=>{setActiveSearchLocationType("location")}}
                className={activeSearchLocationType === "location" ? 'button-active-HowToReach_Main' : 'button-unactive-HowToReach_Main'}
                >
                Nearby Tourist Locations
              </button>
            </div>

            <div
            className='container_t-2-HowToReach_Main'
            >

              <img 
                src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/search_icon_1.png'
                className='img-searchHotel-HowToReach_Main'
              />

              <div>
                {
                  activeSearchLocationType === "destination" &&
                  (
                    <div
                    className='container_t-3-HowToReach_Main'
                    >
                      <Search_Destinations_HTR 
                        handleSearchResultClick={handleDestinationClick}
                      />
                    </div>   
                  )
                }

                {
                  activeSearchLocationType === "location" &&
                  (
                    <div
                    className='container_t-3-HowToReach_Main'
                    >
                      <Search_Locations_HTR 
                        handleSearchResultClick={handleDestinationClick_2}
                      />
                    </div>   
                  )
                }
              </div>

            </div>

          </div>
        </div> 
      } */}

<div style={{display:'flex', flexDirection:'column'}}>

        <div
          className='conatiner_p-component-content_map-HowToReach_Main_Mobile'
        >
  
          {
            (!airContent.length > 0 && !trainContent.length > 0 && !busContent.length > 0) &&
          
            <div
            className='conatiner_p-heading-HowToReach_Main'
            >  

              <div
              className='conatiner_s-1-heading-Hotels'
              >
                <div
                className='heading-1-heading-HowToReach_Main'
                > 
                  How To Reach
                </div>

                <img 
                src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/how_to_reach_image_1.jpg'

                // https://designerapp.officeapps.live.com/designerapp/document.ashx?path=/d427e476-136f-454a-9071-ff9fb462c162/DallEGeneratedImages/dalle-9976fba9-7bf1-47ef-9ae5-a4348addccd60251681068742642360200.jpg&dcHint=IndiaCentral&fileToken=14ef67e1-604a-4640-8535-f1116fb91402
                
                className='img-1-heading-HowToReach_Main'
                />
              </div>   

            </div>  
          }


        <div
        className={(airContent.length > 0 || trainContent.length > 0 || busContent.length > 0) ? '' : 'container_p-0-HowToReach_Main_Mobile'}
        >

          {/*//!When the map is active */}
          {/*//*Search */}
          {
            // (airContent.length > 0 || trainContent.length > 0 || busContent.length > 0) &&
          
            <div
            className={(airContent.length > 0 || trainContent.length > 0 || busContent.length > 0) ? 'container_p-HowToReach_Main_Mobile' : 'container_p-2-HowToReach_Main_Mobile'}
            // className='container_p-HowToReach_Main_Mobile'
            >
              <div className='container_s-HowToReach_Main_Mobile'>

                <div
                className='container_t-1-HowToReach_Main_Mobile'
                >
                  <button
                    onClick={()=>{setActiveSearchLocationType("destination")}}
                    className={activeSearchLocationType === "destination" ? 'button-active-HowToReach_Main_Mobile' : 'button-unactive-HowToReach_Main_Mobile'}
                  >
                    Destination 
                  </button>

                  <button
                    onClick={()=>{setActiveSearchLocationType("location")}}
                    className={activeSearchLocationType === "location" ? 'button-active-HowToReach_Main_Mobile' : 'button-unactive-HowToReach_Main_Mobile'}
                    >
                    Nearby Tourist Locations
                  </button>
                </div>

                <div
                className='container_t-2-HowToReach_Main'
                >

                  <img 
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/search_icon_1.png'
                    className='img-searchHotel-HowToReach_Main_Mobile'
                  />

                  <div>
                    {
                      activeSearchLocationType === "destination" &&
                      (
                        <div
                        className='container_t-3-HowToReach_Main'
                        >
                          <Search_Destinations_HTR 
                            handleSearchResultClick={handleDestinationClick}
                          />
                        </div>   
                      )
                    }

                    {
                      activeSearchLocationType === "location" &&
                      (
                        <div
                        className='container_t-3-HowToReach_Main'
                        >
                          <Search_Locations_HTR 
                            handleSearchResultClick={handleDestinationClick_2}
                          />
                        </div>   
                      )
                    }
                  </div>

                </div>

              </div>
            </div> 
          }


          {/*//*Navigation  */}
          <div
          className={(airContent.length > 0 || trainContent.length > 0 || busContent.length > 0) ? 'container_p-navigation-HowToReach_Main_Mobile' : 'container_p-2-navigation-HowToReach_Main_Mobile'}
          >

            <button 
              onClick={()=>{
                setActiveMode("air")
                if(destinationName){
                  handleLoadTravelModeData_ModeClick("air")
                  setFitAllLocations(true)
                }
              }}
            className={activeMode === "air" ? 'active-button-Reach_D' : 'unActive-button-Reach_D'}
            >   
              <div className='heading-modeName-HowToReach_Main_Mobile'>
                Airports 
              </div>
              <div className='icon-modeName-HowToReach_Main_Mobile'>
                <FaPlaneDeparture />
              </div>
            </button> 


            <button 
              onClick={()=>{
                setActiveMode("train")
                if(destinationName){
                  handleLoadTravelModeData_ModeClick("train")
                  setFitAllLocations(true)
                }
              }}
            className={activeMode === "train" ? 'active-button-Reach_D' : 'unActive-button-Reach_D'}
            >   
              <div className='heading-modeName-HowToReach_Main_Mobile'>
                Railway Stations
              </div>
              <div className='icon-modeName-HowToReach_Main_Mobile'>
                <GiRailRoad />
              </div>
            </button> 


            {/*//  */}
            <button 
              onClick={()=>{
                setActiveMode("bus")
                if(destinationName){
                  handleLoadTravelModeData_ModeClick("bus")
                  setFitAllLocations(true)
                }
              }}
            className={activeMode === "bus" ? 'active-button-Reach_D' : 'unActive-button-Reach_D'}
            >   
              <div className='heading-modeName-HowToReach_Main_Mobile'>
                Bus Stands
              </div>
              <div className='icon-modeName-HowToReach_Main_Mobile'>
                <FaBusAlt />
              </div>
            </button> 

          </div>


        </div>
          {
            (airContent.length > 0 || trainContent.length || 0 || busContent.length > 0) &&

            <div
            className='container_p-navigation-2-HowToReach_Main_Mobile'
            >
              <button
              onClick={()=>{setNavigation_content_map("onlyMap")}}
              className={navigation_content_map === "onlyMap" ? 'button-active-navigation-HowToReach_Main_Mobile' : 'button-unActive-navigation-HowToReach_Main_Mobile'}
              >
                Map
              </button>
              <button
              onClick={()=>{setNavigation_content_map("onlyModes")}}
              className={navigation_content_map === "onlyModes" ? 'button-active-navigation-HowToReach_Main_Mobile' : 'button-unActive-navigation-HowToReach_Main_Mobile'}
              >
                Only Modes
              </button>
            </div>
          }    

</div>
       
        {
          navigation_content_map === "onlyModes" &&
          (activeMode && !initialLoadStatus) && 

          <div
            className='continer_p-1-HowToReach_Main_Mobile'
          >

            <div 
              className='popup-background-1-Entities_TD'
              onClick={()=>{
                setNavigation_content_map("onlyMap")
                closePopup()
              }}
            >
              {/* Close */}
            </div>


            <div className='popup_p-3-HowToReach_Mobile'>
            <div className='popup_s-addNote-HowToReach_Mobile'>
          
            <button
              className='button-close-DayWise_Mobile'
              onClick={()=>{
                setNavigation_content_map("onlyMap")
                closePopup()
              }}
            >
              <IoMdCloseCircle />
            </button>

            <div
            className='container_p-ModesContent-HowToReach_Main'
            >


              {
                (activeMode === "air" && air_PlaceName) && 
                <div
                className='container_s-ModesContent-HowToReach_Main'
                >
                  <Air />
                </div>  
              }
              
              {
                (activeMode === "train" && train_PlaceName) && 
                <div
                className='container_s-ModesContent-HowToReach_Main'
                >
                  <Train />
                </div>  
              }

              {
                (activeMode === "bus" && bus_PlaceName) && 
                <div
                className='container_s-ModesContent-HowToReach_Main'
                >
                  <Bus />
                </div>  
              }


              {
                !destinationName && 
                <div
                className='conatiner_t-noLocationSearched-HowToReach_Main'
                >
                  <div
                  className='text-1-noLocationSearched-HowToReach_Main'
                  >
                    Search a destination/tourist location
                  </div>
                </div>
              }

              {
                !destinationName && 
                <img 
                src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/no_travel_mode_found_image_1.jpeg'
                
                className='img-2-heading-HowToReach_Main'
                />
              }

            </div>  

            </div> 
            </div>

            {/* <div style={{height:'120px'}}></div> */}

        </div> 
        }  

        {/*//! Map - #2  */}
        {
          (destinationName && lat && lng) &&
          (
            <div
            className='continer_p-2-HowToReach_Main_Mobile'
            >
              <div
              className='component-map-HowToReach_Main_Mobile'
              >
                <Map_HowToReach lat={lat} lng={lng} zoom={zoom} />
              </div>
            </div> 
          )
        }
      
        
      </div>

    </div>
  )
}

export default HowToReach_Main_Mobile



