import React from 'react'

//import scss
import './Hotels_Heading.scss' 

function Hotels_Heading() {
  return (
    <div>Hotels_Heading

      <div>
        <div> 
          Discover Best Hotels
        </div>
        <div>
          Hotel Hunt Simplified
        </div>
      </div>   

    </div>
  )
}

export default Hotels_Heading