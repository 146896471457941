import React, { useEffect, useState } from 'react'

//import css
import './Itinerary_CI.scss'

//import react context
import {useCreateItineraryPlanTrip} from '../../Manage/Context/CreateItinerary_Context';
import {useCreateItinerary_2_PlanTrip} from '../../Manage/Context/CreateItinerary_Context_2';

//importing "Itinerary_CI" component internal componenets
import {
  DaySelector_TD, 
  TopicSelector_TD, 
  //Topic Details
  Entities_TD, 
  DayInfo_TD,
  Note_TD, 
  Checklist_TD 
} from '../../Manage/Export/Export_CreateItinerary'

//import icons
import { BsDot } from "react-icons/bs";


//importing drag and drop
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AiItinerary_Chat from './Components - Itinerary CI/AiItinerary_Chat/AiItinerary_Chat';
import Entities_TD_Mobile from './Components - Itinerary CI/Topic Details/Entities-01 TD/SameDesign/Entities_TD_Mobile';


function Itinerary_CI() {

  const active_savedPlacesData_itinerary = [
    {"name": "Place A", "id":"1"}, {"name":"Place B", "id":"1"}, {"name":"Place C", "id":"1"}, {"name":"Place D", "id":"1"}, {"name":"Place E", "id":"1"}
  ]

  const {
      test,
     //!States
      //navigate
      activeViewSavedType, savedPlacesNavigation,
      //status
      loadDataStatus_Itinerary, loadDataStatus_SavedPlaces, saveChangesStatus, setSaveChangesStatus,
      
      //Map
      lat_CI, lng_CI, zoom_CI,
      
      //Saved Places
      savedPlacesData,
      
      //*Itinerary
      //?Day Selector
      dayCount, setDayCount,
      activeDayId, setActiveDayId,
      //?Topic Selector
      itinerary_AddColumn,
      //?Topic Detail 
      //data
      itineraryData, checkListData, tripDocumentsData,
      //show saved places data
      showSavedData_Status, setShowSavedData_Status, hoverTopicId,
      
      //!Functions   
      handleMap_HoverClick,
      //*handle select from saved places data
      handleShowSavedData, 
      //*Handle Itinerary Input Data 
      //?name
      handleName_SavedPlaceSelected, handleName_Input,
      //save itinerary
      handleSaveTripItinerary
  } = useCreateItineraryPlanTrip()


  const {
    //!States

    //*navigate
    navigate_aiItineraryChat, setNavigate_aiItineraryChat,
    aiItineraryChat_window, setAiItineraryChat_window,
    
  } = useCreateItinerary_2_PlanTrip()




  const[activeItineraryNavigation, setActiveItineraryNavigation] = useState("entity")


  const handleScrollDay = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, 100); // Delay by 100 milliseconds
  };


  //!mobile navigation
  const[navigation_CI_Mobile, setNavigation_CI_Mobile] = useState("itinerary")

  //!
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 799); // Initialize with the current width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 799); // Update state on window resize
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  return (
    <div>

    <div className='conatiner_p-Heading-CreateItinerary-Itinerary_CI'>
      <div className='text-Heading-CreateItinerary-Itinerary_CI'>
        Create Itinerary
      </div>     
    </div>


    { saveChangesStatus &&
      <div>
        <button
        onClick={()=>{handleSaveTripItinerary()}}
        className='button-saveChanges-Itinerary_CI'
        >
          Save Changes
        </button>

        {
          !isMobile &&
          <button
          className='icon-dot-saveChanges-Itinerary_CI'
          onClick={handleScrollDay}
          >
            <BsDot />
          </button>
        }
      </div>   
    }

    {/* //!Day Selector
    <div>
      <DaySelector_TD />
    </div>    */}

    {/*//!Topic Selector */}
      {/*addTopicClicked : day_id, topic_id, topic_category, topic_type, topic_icon_name, drag_id, visibility_type, add_limit, different_design*/}
    {/* <div>
      <TopicSelector_TD 
        setActiveItineraryNavigation={setActiveItineraryNavigation}
      />
    </div> */}

    {
      isMobile &&
      <div style={{marginTop:'12px'}}></div>
    }

    {/*//!Day Selector */}
    <div>
      <DaySelector_TD 
      isMobile={isMobile}
      />
    </div> 
    
    <div>
      <TopicSelector_TD 
        activeItineraryNavigation={activeItineraryNavigation}
        setActiveItineraryNavigation={setActiveItineraryNavigation}
        isMobile={isMobile}
        />
    </div>  

    <br></br>

    {/* <div 
    className='conatiner_p-Navigation-Itinerary_TO'
    > 
      <div>
        <button
        onClick={()=>{setActiveItineraryNavigation("entity")}}
        className={activeItineraryNavigation === "entity" ? 'button-active-Navigation-Itinerary_TO' : 'button-unActive-Navigation-Itinerary_TO'}
        >
          # Places
        </button>
      </div>

      <div>
        <button
        onClick={()=>{setActiveItineraryNavigation("day_info")}}
        className={activeItineraryNavigation === "day_info" ? 'button-active-Navigation-Itinerary_TO' : 'button-unActive-Navigation-Itinerary_TO'}
        >
          # Day Info
        </button>
      </div>

      <div>
        <button
        onClick={()=>{setActiveItineraryNavigation("note")}}
        className={activeItineraryNavigation === "note" ? 'button-active-Navigation-Itinerary_TO' : 'button-unActive-Navigation-Itinerary_TO'}
        >
          # Day Notes
        </button>
      </div>

      <div>
        <button
        onClick={()=>{setActiveItineraryNavigation("checklist")}}
        className={activeItineraryNavigation === "checklist" ? 'button-active-Navigation-Itinerary_TO' : 'button-unActive-Navigation-Itinerary_TO'}
        >
          # Day Checklist
        </button>
      </div>

    </div> */}


    {/*//!Topic Details */}
    <div>
      {/* Topic Details {activeDayId} */}
      {
        activeDayId && itineraryData && itineraryData.length > 0 && 
        <div>
          {
            itineraryData.map((data, index) => {
              if(data.day_id === activeDayId){
                return(
                  <div>
                    {/* //*For Tourist Places, Hotels, Restaurant, Bar and Pubs, Shopping Mall, Street Shopping, Activities, Amusement/Water Park, Rent Vehicle*/}

                    {/*//!Entities #1*/}
                    {
                       //this is for "topic_category" with similar design
                      (
                        // !data.different_design //the different design is false
                        // && data.visibility_type === "day" 
                        data.topic_category === "entity" && activeItineraryNavigation === "entity" 
                        && data.add_limit < 11  
                        // && data.topic_type === "hotel" 
                      ) && 

                      <div >

                        {
                          !isMobile ?
                          <Entities_TD  
                          visibility_type={data.topic_type} 
                          different_design={data.different_design} 
                          topic_type={data.topic_type}
                          topic_id={data.topic_id}
                          saveChangesStatus={saveChangesStatus}
                          setSaveChangesStatus={setSaveChangesStatus}
                          />
                          :
                          <Entities_TD_Mobile  
                          visibility_type={data.topic_type} 
                          different_design={data.different_design} 
                          topic_type={data.topic_type}
                          topic_id={data.topic_id}
                          saveChangesStatus={saveChangesStatus}
                          setSaveChangesStatus={setSaveChangesStatus}
                          />
                        }

                      </div>   
                    }

                    {/*//!Day Info #2*/}
                    {   
                      (
                        data.topic_category === "day_info" && activeItineraryNavigation === "day_info"
                        && data.add_limit < 2
                      )  &&
                      <div>
                        {/* //*Budget For the Day, Day Overview, Travel Around, What to wear */}
                        <DayInfo_TD 
                          visibility_type={data.visibility_type} 
                          different_design={data.different_design} 
                          topic_type={data.topic_type}
                          topic_id={data.topic_id}
                          saveChangesStatus={saveChangesStatus}
                          setSaveChangesStatus={setSaveChangesStatus}
                        />
                      </div>    
                    }


                    {/*//!Notes #3*/}
                    { 
                      (
                        data.topic_category === "note" && activeItineraryNavigation === "note"
                        && data.add_limit < 11
                      )  && 
                      <div>
                        <Note_TD 
                          visibility_type={data.visibility_type} 
                          different_design={data.different_design} 
                          topic_type={data.topic_type}
                          topic_id={data.topic_id}
                          saveChangesStatus={saveChangesStatus}
                          setSaveChangesStatus={setSaveChangesStatus}
                          isMobile={isMobile}
                        />
                      </div>    
                    }

                    {/*//!Checklist #4*/}
                    { 
                      (
                        data.topic_category === "checklist" && activeItineraryNavigation === "checklist"
                        && data.add_limit < 11
                      )  &&
                      <div>
                        <Checklist_TD 
                          visibility_type={data.visibility_type} 
                          different_design={data.different_design} 
                          topic_type={data.topic_type}
                          topic_id={data.topic_id}
                          saveChangesStatus={saveChangesStatus}
                          setSaveChangesStatus={setSaveChangesStatus}
                          isMobile={isMobile}
                        />
                      </div>    
                    }

                  
                    
                  </div>   
                )
              }
            })
          }
        </div>  
      }
    </div>


    { 
        navigate_aiItineraryChat && 
        
        <div>
            
          <div className='popup-background-1-AiItinerary_Chat'
          onClick={()=>{
          // closePopup()
          setNavigate_aiItineraryChat(false)
          }}
          > 

            <div className='popup_p-2-addNote-AiItinerary_Chat'
            onClick={(e)=>{e.stopPropagation()}}
            >
            {/* <div className='popup_s-2-addNote-AddNotes_TO_Popup'> */}

              <AiItinerary_Chat />

            {/* </div> */}
            </div>

          </div>

        </div>
      }

    <div style={{height:'100px'}}></div>

    </div>
  )
}

export default Itinerary_CI