import React from 'react'

// import scss
import './History_VM.scss'

//
import ReactMarkdown from 'react-markdown';

function History_VM(props) {

  const{historyData_Props} = props;

  return (
    <div>

      <div
      className='conatiner_p-data-About_VM'
      >
        <div
        className='content-1-data-About_VM'
        >
          <ReactMarkdown>{historyData_Props}</ReactMarkdown>
        </div>
      </div>  

    </div>
  )
}

export default History_VM