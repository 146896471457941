import {React, useState, useEffect, useRef} from 'react'


//import css
import './_AboutDestination.scss';

//!This is the home page for the About Destination feature in the plan Trip. 
 
import { Helmet } from 'react-helmet' 

//importing react context
import { useAboutDestinationPlanTrip } from '../Manage/Context/AboutDestination_Context';

//importing other React Context
import { Context_validatePlanTrip, useValidatePlanTrip } from '../../../Manage - Plan Trip/Context/ValidatePlanTrip';
import { Context_globalNavigationPlanTrip } from '../../../Manage - Plan Trip/Context/GlobalNavigationPlanTrip';

//import other interal "About Destination" components
import {
  //main interal components
  About_D,
  Overview_D, 
  QnA_D,
  Reach_D,
  //other helpful
  DestinationCreativeHeader_D,
  Map_D
} from '../Manage/Export/Export_AboutDestination'


function AboutDestination() {

  const { 
    //loading
    loadingStatus_AboutDestination,
    //
    activeAboutCategory, 
    //
    handleMainCategoryClick, 
    overviewPlaceData

  } = useAboutDestinationPlanTrip()
  
  // using react context
  const { userEmail, tripId, tripName, 
    //trip details
    tripDestinationName, tripDestinationType, stateName, districtName, cityName, destinationLat, destinationLng
  } = useValidatePlanTrip();


  //
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 899);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 899); // Update state on window resize
    };
  
    window.addEventListener('resize', handleResize);
  
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div>

      { loadingStatus_AboutDestination &&
        <div className="overlay">
            <div className="spinner-container">
                <div className="spinner"></div>
                <div
                className='loading-1-data-PromptAndOverview'
                >
                    <p>Loading...</p>
                </div>
            </div>
        </div>
      }

    <Helmet>

    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=Assistant:wght@200&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Maven+Pro&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

    </Helmet>

      {/*//! header */}
      <div className='container_p-header-AboutDestination'>

        <div className='container_s-header-AboutDestination'>
          <DestinationCreativeHeader_D  
            tripDestinationName={tripDestinationName} 
            tripDestinationType={tripDestinationType}
            overviewPlaceData={overviewPlaceData}
          />
        </div>

      </div>


      {/*//! */}
      <div className='container_p-navigationMain-AboutDestination'
      >
        <div
        className='container_s-navigationMain-AboutDestination'
        >

          {/* //?Overview */}
          <div>
            <button
              onClick={()=>{handleMainCategoryClick("overview")}}
              className={`${activeAboutCategory === "overview" ? 'button-active-mainNavigation-AboutDestination' : 'button-unactive-mainNavigation-AboutDestination'}`}
              >
              <div
              className='container_t-navigationText-AboutDestination'
              >
                 Quick Guide
              </div>
              <div>
                <img
                  className='img-navigationText-AboutDestination'
                  src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/island-tropical.png' 
                />
              </div>
            </button>
          </div>


          {/* //?About */}
          <div>
            <button
              onClick={()=>{handleMainCategoryClick("about")}}
              // className={`mainNavigationButton-box2 ${activeAboutCategory === "about" ? 'mainNavigationButtonActive-box2' :''}`} 
              className={`${activeAboutCategory === "about" ? 'button-active-mainNavigation-AboutDestination' : 'button-unactive-mainNavigation-AboutDestination'}`}
            >
              <div
              className='container_t-navigationText-AboutDestination'
              >
                All About {tripDestinationName}
              </div>
              <div>
                <img
                  className='img-navigationText-AboutDestination'
                  src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/map-marker.png' 
                />
              </div>
            </button>
          </div>


          {/* //?QnA */}
          <div>
            <button
              onClick={()=>{handleMainCategoryClick("qna")}}
              className={`${activeAboutCategory === "qna" ? 'button-active-mainNavigation-AboutDestination' : 'button-unactive-mainNavigation-AboutDestination'}`}
            >
              <div
              className='container_t-navigationText-AboutDestination'
              >
                QnA
              </div>
              <div>
                <img
                  className='img-navigationText-AboutDestination'
                  src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/story-book.png' 
                  // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/align-center.png' 
                />
              </div>
            </button>
          </div>


          {/* //?How To Reach*/}
          <div>
            <button
              onClick={()=>{handleMainCategoryClick("reach")}} 
              className={`${activeAboutCategory === "reach" ? 'button-active-mainNavigation-AboutDestination' : 'button-unactive-mainNavigation-AboutDestination'}`}
            >
              <div
              className='container_t-navigationText-AboutDestination'
              >
                How To Reach 
              </div>
              <div>
                <img
                  className='img-navigationText-AboutDestination'
                  src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/direction-signal.png' 
                />
              </div>
            </button>
          </div>

        </div>

      </div>


      {/*//! */}
      <div>

        {
          activeAboutCategory === "overview" &&
          (
            <div 
            className='container_p-component-overview-AboutDestination'>
              {/* <div
               className='container_s-component-overview-AboutDestination'
              > */}
                <Overview_D />
              {/* </div>    */}
            </div>  
          )
        }

        {
          activeAboutCategory === "about" &&
          (
            <div 
            className='container_p-component-about-AboutDestination'>
              <div
               className='container_s-component-about-AboutDestination'
              >
                <About_D />
              </div>   
            </div>   
          )
        }

        {
          activeAboutCategory === "qna" &&
          (
            <div 
            className='container_p-component-qna-AboutDestination'>
              <div
               className='container_s-component-qna-AboutDestination'
              >
                <QnA_D />
              </div>   
            </div>   
          )
        }

        {
          activeAboutCategory === "reach" &&
          (
            <div 
            className='container_p-component-reach-AboutDestination'>
              <div
               className='container_s-component-reach-AboutDestination'
              >
                <div className='component-reach-AboutDestination'>
                  <Reach_D />
                </div>
                {
                  (!isMobile && destinationLat && destinationLng) &&

                  <div className='component-reachMap-AboutDestination'>
                    <Map_D />
                  </div>  
                }
              </div>   
            </div>   
          )
        }

      </div>

      <div className='footerSpace-AboutDestination'>

      </div>


    </div>
    
  )
}

export default AboutDestination

