import React, { useState } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import FontFamily from '@tiptap/extension-font-family';
import Link from '@tiptap/extension-link';
import Highlight from '@tiptap/extension-highlight';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Blockquote from '@tiptap/extension-blockquote';
import Heading from '@tiptap/extension-heading';
import CharacterCount from '@tiptap/extension-character-count';

import { Helmet } from 'react-helmet'

import { SketchPicker } from 'react-color';

//import icons
import { MdFormatColorText } from "react-icons/md";
import { RiFontSize } from "react-icons/ri";
import { AiOutlineHighlight } from "react-icons/ai";
import { TbHeading } from "react-icons/tb";
import { MdOutlineTextRotationAngleup } from "react-icons/md";
import { BsTextParagraph } from "react-icons/bs";
// import { CiCircleList } from "react-icons/ci";
// import { FaListUl } from "react-icons/fa";
// import { MdFormatListBulletedAdd } from "react-icons/md";
// import { FaList } from "react-icons/fa6";
// import { RiListIndefinite } from "react-icons/ri";
import { IoListCircle } from "react-icons/io5";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { TbLinkPlus } from "react-icons/tb";
import { MdAddLink } from "react-icons/md";
import { TiThList } from "react-icons/ti";
import { FaThList } from "react-icons/fa";
import { BsListOl } from "react-icons/bs";
import { BsListUl } from "react-icons/bs";
import { IoIosCheckboxOutline } from "react-icons/io";
// import { RiListIndefinite } from "react-icons/ri";
import { RiListCheck2 } from "react-icons/ri";
import { MdOutlineRule } from "react-icons/md";
import { FaPencilRuler } from "react-icons/fa";
import { FaQuoteLeft } from "react-icons/fa";
import { FiAlignLeft } from "react-icons/fi";
import { FiAlignRight } from "react-icons/fi";
import { AiOutlineAlignCenter } from "react-icons/ai";


import './TestingLexical.scss'; // Import CSS for styling

const TestingLexical = ({ value, onChange, placeholder, wordLimit }) => {
  const [activeExtensions, setActiveExtensions] = useState([]);

  //states
  const[activeHover, setActiveHover] = useState()

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Bold,
      Italic,
      Strike,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      TextStyle,
      Color,
      FontFamily,
      Link.configure({
        autolink: true,
        openOnClick: true,
        linkOnPaste: true,
        validate: (href) => isValidURL(href),
      }),
      Highlight.configure({ multicolor: true }),
      BulletList,
      OrderedList,
      ListItem,
      HorizontalRule,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Blockquote,
      Heading.configure({
        levels: [1, 2, 3],
      }),
      CharacterCount.configure({
        limit: wordLimit,
      }),
    ],
    content: value, // Set initial content from props
    onUpdate: ({ editor }) => {
      const characterCount = editor.storage.characterCount?.characters() || 0;
      const wordCount = editor.storage.characterCount?.words() || 0;
      
      // Check if character count exceeds the limit
      if (characterCount > wordLimit) {
        // Trim content to fit within the limit (roughly estimate)
        const trimmedText = editor.getText().substring(0, wordLimit);
        editor.commands.setContent(trimmedText);
      }

      onChange(editor.getJSON()); // Call onChange with updated content
    },
  });

  // Function to validate URL format
  const isValidURL = (url) => {
    const pattern = /\.com/;
    return pattern.test(url);
  };

  // Command to set a link
  const handleSetLink = () => {
    let url = window.prompt('Enter the URL:');
    if (url) {
      // Add http:// if the URL does not start with http:// or https://
      if (!url.match(/^(https?:\/\/)/)) {
        url = 'http://' + url;
      }
      // Validate the URL format
      if (isValidURL(url)) {
        editor.chain().focus().setLink({ href: url }).run();
      } else {
        alert('Invalid URL format. Please enter a valid URL.');
      }
    }
  };

  const handleToggleBold = () => editor.chain().focus().toggleBold().run();
  const handleToggleItalic = () => editor.chain().focus().toggleItalic().run();
  const handleToggleUnderline = () => editor.chain().focus().toggleUnderline().run();
  const handleToggleStrike = () => editor.chain().focus().toggleStrike().run();
  const handleAlign = (alignment) => editor.chain().focus().setTextAlign(alignment).run();


  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState('#000'); // Default color set to black

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  const handleColorChange = (color) => {
    setCurrentColor(color.hex);
    handleTextColor(color.hex);
  };
  const handleTextColor = (color) => editor.chain().focus().setColor(color).run();

  const handleFontFamily = (family) => editor.chain().focus().setFontFamily(family).run();
  const handleToggleHighlight = (color) => editor.chain().focus().toggleHighlight({ color }).run();
  const handleToggleBulletList = () => editor.chain().focus().toggleBulletList().run();
  const handleToggleOrderedList = () => editor.chain().focus().toggleOrderedList().run();
  const handleToggleTaskList = () => editor.chain().focus().toggleTaskList().run();
  const handleSplitListItem = (itemType) => editor.chain().focus().splitListItem(itemType).run();
  const handleSinkListItem = (itemType) => editor.chain().focus().sinkListItem(itemType).run();
  const handleLiftListItem = (itemType) => editor.chain().focus().liftListItem(itemType).run();
  const handleSetHorizontalRule = () => editor.chain().focus().setHorizontalRule().run();
  const handleToggleBlockquote = () => editor.chain().focus().toggleBlockquote().run();
  const handleToggleHeading = (level) => editor.chain().focus().toggleHeading({ level }).run();

  // Ensure editor is defined before rendering
  if (!editor) {
    return null;
  }

  // Function to handle toggling active state for dropdowns and inner options
  const handleDropdownClick = (extension) => {
    if (activeExtensions.includes(extension)) {
      setActiveExtensions(activeExtensions.filter(ext => ext !== extension));
    } else {
      setActiveExtensions([...activeExtensions, extension]);
    }
  };

  const characterCount = editor.storage.characterCount?.characters() || 0;
  const wordCount = editor.storage.characterCount?.words() || 0;


  return (
    <div className="editor-container">
      {/* <div className="editor-toolbar"> */}

      <Helmet>   

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Assistant:wght@400&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />

        <link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap" rel="stylesheet" />

      </Helmet>


      {/*//! Conatiner 1  */}
      <div className='container_p-buttons-TestingLexical'>

        {/* Text Color Dropdown */}
        <div className="dropdown">
          <button className="dropbtn" 
          onClick={() => {
            handleDropdownClick('color')
            handleClick()
          }}
          onMouseOver={()=>{setActiveHover("textColor")}}
          onMouseOut={()=>{setActiveHover(false)}}
          >
            {/* Text Color */}
            <MdFormatColorText />
          </button>
          {
            activeHover === "textColor" &&
         
            <div className="dropdown-content"
            onMouseOver={()=>{setActiveHover("textColor")}}
            onMouseOut={()=>{setActiveHover(false)}}
            >
              {/* {displayColorPicker ? ( */}
                <div style={{ position: 'absolute', zIndex: '2' }}>
                  <div style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }} 
                  onClick={()=>{setActiveHover(false)}} 
                  />
                  <SketchPicker color={currentColor} onChange={handleColorChange} />
                </div>
              {/* // ) : null} */}
            </div>
          }
        </div>

        {/* Font Family Dropdown */}
        <div className="dropdown">
          <button className="dropbtn" 
          onClick={() => handleDropdownClick('fontFamily')}
          onMouseOver={()=>{setActiveHover("fonts")}}
          onMouseOut={()=>{setActiveHover(false)}}
          >
            {/* Font Family */}
            <RiFontSize />
          </button>

          { activeHover === "fonts" &&
            <div 
            className="container_p-font-TestingLexical"
            onMouseOver={()=>{setActiveHover("fonts")}}
            onMouseOut={()=>{setActiveHover(false)}}
            >
              
              <button onClick={() => handleFontFamily('Arial')} className='button-3-dropdown-TestingLexical'>
                Arial
              </button>
              <button onClick={() => handleFontFamily('Courier New')} className='button-3-dropdown-TestingLexical'>
                Courier New
              </button>
              <button onClick={() => handleFontFamily('Assistant')} className='button-3-dropdown-TestingLexical'>
                Assistant
              </button>
              <button onClick={() => handleFontFamily('Comfortaa')} className='button-3-dropdown-TestingLexical'>
                Comfortaa
              </button>
              <button onClick={() => handleFontFamily('Finlandica')} className='button-3-dropdown-TestingLexical'>
                Finlandica
              </button>
              <button onClick={() => handleFontFamily('Karla')} className='button-3-dropdown-TestingLexical'>
                Karla
              </button>
              <button onClick={() => handleFontFamily('Kodchasan')} className='button-3-dropdown-TestingLexical'>
                Kodchasan
              </button>
              <button onClick={() => handleFontFamily('Lato')} className='button-3-dropdown-TestingLexical'>
                Lato
              </button>
              <button onClick={() => handleFontFamily('Mitr')} className='button-3-dropdown-TestingLexical'>
                Mitr
              </button>
              <button onClick={() => handleFontFamily('Montserrat')} className='button-3-dropdown-TestingLexical'>
                Montserrat
              </button>
              <button onClick={() => handleFontFamily('Nanum Gothic')} className='button-3-dropdown-TestingLexical'>
                Nanum Gothic
              </button>
              <button onClick={() => handleFontFamily('Nunito Sans')} className='button-3-dropdown-TestingLexical'>
                Nunito Sans
              </button>
              <button onClick={() => handleFontFamily('Oxygen')} className='button-3-dropdown-TestingLexical'>
                Oxygen
              </button>
              <button onClick={() => handleFontFamily('Poppins')} className='button-3-dropdown-TestingLexical'>
                Poppins
              </button>
              <button onClick={() => handleFontFamily('Quicksand')} className='button-3-dropdown-TestingLexical'>
                Quicksand
              </button>
              <button onClick={() => handleFontFamily('Rubik')} className='button-3-dropdown-TestingLexical'>
                Rubik
              </button>
              <button onClick={() => handleFontFamily('Satisfy')} className='button-3-dropdown-TestingLexical'>
                Satisfy
              </button>
              <button onClick={() => handleFontFamily('Space Grotesk')} className='button-3-dropdown-TestingLexical'>
                Space Grotesk
              </button>
              <button onClick={() => handleFontFamily('Work Sans')} className='button-3-dropdown-TestingLexical'>
                Work Sans
              </button>
              <button onClick={() => handleFontFamily('Ysabeau Office')} className='button-3-dropdown-TestingLexical'>
                Ysabeau Office
              </button>
              <button onClick={() => handleFontFamily('Comic Neue')} className='button-3-dropdown-TestingLexical'>
                Comic Neue
              </button>
          </div>
          }

        </div>

        {/* Highlight Dropdown */}
        <div className="dropdown">
          <button className="dropbtn" onClick={() => handleDropdownClick('highlight')}>
            <AiOutlineHighlight />
          </button>
          <div className="dropdown-content">
            <button onClick={() => handleToggleHighlight('yellow')} style={{ backgroundColor: 'yellow' }}
            className='button-3-dropdown-TestingLexical'
            >
              Yellow
            </button>
            <button onClick={() => handleToggleHighlight('pink')} style={{ backgroundColor: 'pink' }}
            className='button-3-dropdown-TestingLexical'
            >
              Pink
            </button>
            <button onClick={() => handleToggleHighlight('lightblue')} style={{ backgroundColor: 'lightblue' }}
            className='button-3-dropdown-TestingLexical'
            >
              Light Blue
            </button>
          </div>
        </div>

        {/* Heading Dropdown */}
        <div className="dropdown">
          <button className="dropbtn" onClick={() => handleDropdownClick('heading')}
            onMouseOver={()=>{setActiveHover("headings")}}
            onMouseOut={()=>{setActiveHover(false)}}
          >
            {/* Heading */}
            <TbHeading />
          </button>

          {
            activeHover === "headings" &&
     
            <div 
            // className="dropdown-content"
            className="container_p-heading-TestingLexical"
            onMouseOver={()=>{setActiveHover("headings")}}
            onMouseOut={()=>{setActiveHover(false)}}
            >
              <button onClick={() => handleToggleHeading(1)}
              className='button-3-dropdown-TestingLexical'
              >
                H1
              </button>
              <button onClick={() => handleToggleHeading(2)}
              className='button-3-dropdown-TestingLexical'
              >
                H2
              </button>
              <button onClick={() => handleToggleHeading(3)}
              className='button-3-dropdown-TestingLexical'
              >
                H3
              </button>
            </div>
          }

        </div>

        {/* Text Style Dropdown */}
        <div className="dropdown">
          <button className="dropbtn" onClick={() => handleDropdownClick('textStyle')}
            onMouseOver={()=>{setActiveHover("textStyle")}}
            onMouseOut={()=>{setActiveHover(false)}}
          >
            {/* Text Style */}
            <MdOutlineTextRotationAngleup />
          </button>
          {
            activeHover === "textStyle" &&
        
            <div 
              // className="dropdown-content"
              className="container_p-heading-TestingLexical"
              onMouseOver={()=>{setActiveHover("textStyle")}}
              onMouseOut={()=>{setActiveHover(false)}}
            >
              <button onClick={handleToggleBold}
              className='button-3-dropdown-TestingLexical'
              >
                <strong>B</strong>
              </button>
              <button onClick={handleToggleItalic}
              className='button-3-dropdown-TestingLexical'
              >
                <em>I</em>
              </button>
              <button onClick={handleToggleUnderline}
              className='button-3-dropdown-TestingLexical'
              >
                U
              </button>
              <button onClick={handleToggleStrike}
              className='button-3-dropdown-TestingLexical'
              >
                S
              </button>
            </div>

          }

        </div>

        {/* Alignment Dropdown */}
        <div className="dropdown">
          <button className="dropbtn" onClick={() => handleDropdownClick('textAlign')}
           onMouseOver={()=>{setActiveHover("textAlignment")}}
           onMouseOut={()=>{setActiveHover(false)}}
          >
            {/* Alignment */}
            <BsTextParagraph />
          </button>

          {
            activeHover === "textAlignment" &&

            <div
            className="container_p-heading-TestingLexical"
            onMouseOver={()=>{setActiveHover("textAlignment")}}
            onMouseOut={()=>{setActiveHover(false)}}
            >
              <button onClick={() => handleAlign('left')} className='button-3-dropdown-TestingLexical'
              style={{fontSize:'20px'}}
              >
                {/* Left  */}
                <FiAlignLeft />
              </button>
              <button onClick={() => handleAlign('center')} className='button-3-dropdown-TestingLexical'
              style={{fontSize:'20px'}}
              >
                {/* Center */}
                {/* <LuAlignCenter /> */}
                <AiOutlineAlignCenter />
              </button>
              <button onClick={() => handleAlign('right')} className='button-3-dropdown-TestingLexical'
              style={{fontSize:'20px'}}
              >
                {/* Right */}
                <FiAlignRight />
              </button>
            </div>
          }

        </div>

        {/* List Dropdown */}
        <div className="dropdown">
          <button className="dropbtn" 
          onClick={() => handleDropdownClick('list')}
          onMouseOver={()=>{setActiveHover("list")}}
          onMouseOut={()=>{setActiveHover(false)}}
          >
            {/* Lists */}
            <TiThList />
          </button>
          {
            activeHover === "list" &&
            
            <div 
            className="container_p-heading-TestingLexical"
            onMouseOver={()=>{setActiveHover("list")}}
            onMouseOut={()=>{setActiveHover(false)}}
            >
              <button onClick={handleToggleBulletList}
              className='button-3-dropdown-TestingLexical'
              style={{fontSize:'20px'}}
              >
                {/* Toggle Bullet List */}
                <BsListOl />
              </button>
              <button onClick={handleToggleOrderedList}
              className='button-3-dropdown-TestingLexical'
              style={{fontSize:'20px'}}
              >
                {/* Toggle Ordered List */}
                <BsListUl />
              </button>
            </div>
          }
        </div>

        {/* Edit Lists Dropdown */}
        <div className="dropdown">
          <button className="dropbtn" onClick={() => handleDropdownClick('editLists')}
            onMouseOver={()=>{setActiveHover("editList")}}
            onMouseOut={()=>{setActiveHover(false)}}
          >
            {/* Edit Lists */}
            <IoListCircle />
          </button>
          { activeHover === "editList" &&

            <div 
              className="container_p-3-TestingLexical"
              onMouseOver={()=>{setActiveHover("editList")}}
              onMouseOut={()=>{setActiveHover(false)}}
            >
              <button onClick={() => handleSplitListItem('listItem')} disabled={!editor.can().liftListItem('listItem')}
              className='button-3-dropdown-TestingLexical'
              >Lift Bullet/Ordered List Item</button>
              <button onClick={() => handleSinkListItem('listItem')} disabled={!editor.can().sinkListItem('listItem')}
              className='button-3-dropdown-TestingLexical'
              >Sink Bullet/Ordered List Item</button>
            </div>
          } 
        </div>

        {/* Other Editor Commands */}
        <button onClick={handleSetLink} 
        className='button-1-buttons-TestingLexical'
        >
          {/* Add Link */}
          <MdAddLink />
          {/* <TbLinkPlus /> */}
        </button>
        <button onClick={handleToggleTaskList} 
        className='button-1-buttons-TestingLexical'
        >
          {/* Toggle Task List */}
          <IoIosCheckboxOutline />
        </button>
        
        {/* Edit Task Dropdown */}
        <div className="dropdown">
          <button className="dropbtn" onClick={() => handleDropdownClick('editTask')}
           onMouseOver={()=>{setActiveHover("editTask")}}
           onMouseOut={()=>{setActiveHover(false)}}
          >
            {/* Edit Task */}
            <RiListCheck2 />
          </button>
          { activeHover === "editTask" &&
            <div 
            className="container_p-3-TestingLexical"
            onMouseOver={()=>{setActiveHover("editTask")}}
            onMouseOut={()=>{setActiveHover(false)}}
            >
              <button onClick={() => handleLiftListItem('taskItem')} disabled={!editor.can().liftListItem('taskItem')}
              className='button-3-dropdown-TestingLexical'
              >Lift Task List Item</button>
              <button onClick={() => handleSinkListItem('taskItem')} disabled={!editor.can().sinkListItem('taskItem')}
              className='button-3-dropdown-TestingLexical'
              >Sink Task List Item</button>
            </div>
          }
        </div>

        <button onClick={handleSetHorizontalRule}
        className='button-1-buttons-TestingLexical'
        >
          {/* Set Horizontal Rule */}
          {/* <MdOutlineRule /> */}
          <FaPencilRuler />
        </button>

        <button onClick={handleToggleBlockquote}
        className='button-1-buttons-TestingLexical'
        >
          {/* Toggle Blockquote */}
          <FaQuoteLeft />
        </button>

      </div>


      {/*//! Conatiner 2 - Word Count */}
      <div
      className='container_p-WordCount-TestingLexical'
      >
        <div 
        className='container_s-WordCount-TestingLexical'
        >
          {characterCount}/{wordLimit} characters
          {/* <br /> */}
          &nbsp; | &nbsp;
          {wordCount} words
        </div>
      </div>   
      

      {/*//! Conatiner 3 - Text Editor */}
      <div className="conatiner_p-editor-TestingLexical">
        <EditorContent editor={editor} />
      </div>

      <div style={{height:'100px'}}></div>      

    </div>
  );
};

export default TestingLexical;







