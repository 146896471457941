import React, { useContext }  from 'react'

//import scss
import './TravelCategories.scss'

import { server_baseUrl } from '../../../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';

//using react context
import {TravelCategories_Context} from '../../../Manage/Context/TravelCategories_Context'
import { TravelCategories_2_Context } from '../../../Manage/Context/TravelCategories_2_Context';
import { useValidatePlanTrip } from '../../../../../Manage - Plan Trip/Context/ValidatePlanTrip';

function TravelCategories() {

    // using react context 
    const {activeTravelCategory, setActiveTravelCategory, activeDestination} = TravelCategories_Context();

    const {
        handle_loadHotelsData, showMoreCount, sortHotelPrice 
    } = TravelCategories_2_Context();

    const{
        tripDestinationType, tripDestinationName
    } = useValidatePlanTrip();


    const handleImageLink = (type) => {
        let link;
    
        if(type === "tourist_places"){
          link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/tourist_place_icon_1.png'
        }
        else if(type === "hotels"){
          link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/hotel_icon_1.png'
        }
        else if(type === "restaurants"){
          link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/restaurant_icon_3.png'
        }
        else if(type === "bars_and_pubs"){
          link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/bar_and_pub_icon_3.png'
        }
        else if(type === "adventure_activities"){
          link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/activity_icon_3.png'
        }
        else if(type === "amusement_parks"){
          link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/amusement_park_icon_3.png'
        }
        else if(type === "shopping_malls"){
          link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/shopping_mall_icon_1.png'
        }
        else if(type === "street_market"){
          link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/street_market_icon_3.png'
        }
        else if(type === "rent_vehicle"){
          link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/rent_vehicle_4_icon_1.png'
        }
    
        return link;
      }

  return ( 
    <div>
        
        <div
        className='conatiner_p-TravelCategories'
        >
            <button 
                className={
                activeTravelCategory === "tourist_places" ? 'button-active-TravelCategories-TravelCategories' :
                'button-unActive-TravelCategories-TravelCategories'
                }
                onClick={()=>{setActiveTravelCategory("tourist_places")}}
            >
                <div
                className={
                activeTravelCategory === "tourist_places" ? 'heading-active-TravelCategories-TravelCategories' :
                'heading-unActive-TravelCategories-TravelCategories'
                }
                >
                    Tourist Places
                </div>
                <img
                    src={handleImageLink("tourist_places")} 
                    className={
                    activeTravelCategory === "tourist_places" ? 'img-active-TravelCategories-TravelCategories' :
                    'img-unActive-TravelCategories-TravelCategories'
                    }
                />
            </button>

            <button 
                className=
                {activeTravelCategory === "hotels" ? 'button-active-TravelCategories-TravelCategories' :
                'button-unActive-TravelCategories-TravelCategories'
                }
                onClick={() => {
                    setActiveTravelCategory("hotels");
                    const destination_to_pass = tripDestinationType === "state" ? activeDestination : tripDestinationName;
                    handle_loadHotelsData(0, false, "all", sortHotelPrice, destination_to_pass);
                }}  
            >
                <div
                className={
                activeTravelCategory === "hotels" ? 'heading-active-TravelCategories-TravelCategories' :
                'heading-unActive-TravelCategories-TravelCategories'
                }
                >
                    Hotels
                </div>
               
                <img
                    src={handleImageLink("hotels")} 
                    className={
                    activeTravelCategory === "hotels" ? 'img-active-TravelCategories-TravelCategories' :
                    'img-unActive-TravelCategories-TravelCategories'
                    }
                />

            </button>

            {/* <button 
                className={
                activeTravelCategory === "restaurants" ? 'button-active-TravelCategories-TravelCategories' :
                'button-unActive-TravelCategories-TravelCategories'
                }
                onClick={()=>{setActiveTravelCategory("restaurants")}}
            >
                <div
                className={
                activeTravelCategory === "restaurants" ? 'heading-active-TravelCategories-TravelCategories' :
                'heading-unActive-TravelCategories-TravelCategories'
                }
                >
                    Restaurants
                </div>
              
                <img
                    src={handleImageLink("restaurants")} 
                    className={
                    activeTravelCategory === "restaurants" ? 'img-active-TravelCategories-TravelCategories' :
                    'img-unActive-TravelCategories-TravelCategories'
                    }
                />

            </button>

            <button 
                className={
                activeTravelCategory === "bars_and_pubs" ? 'button-active-TravelCategories-TravelCategories' :
                'button-unActive-TravelCategories-TravelCategories'
                }
                onClick={()=>{setActiveTravelCategory("bars_and_pubs")}}
            >
                <div
                className={
                activeTravelCategory === "bars_and_pubs" ? 'heading-active-TravelCategories-TravelCategories' :
                'heading-unActive-TravelCategories-TravelCategories'
                }
                >
                    Bars and Pubs
                </div>
              
                <img
                    src={handleImageLink("bars_and_pubs")} 
                    className={
                    activeTravelCategory === "bars_and_pubs" ? 'img-active-TravelCategories-TravelCategories' :
                    'img-unActive-TravelCategories-TravelCategories'
                    }
                />

            </button>

            <button 
                className={
                activeTravelCategory === "amusement_parks" ? 'button-active-TravelCategories-TravelCategories' :
                'button-unActive-TravelCategories-TravelCategories'
                }
                onClick={()=>{setActiveTravelCategory("amusement_parks")}}
            >
                <div
                className={
                activeTravelCategory === "amusement_parks" ? 'heading-active-TravelCategories-TravelCategories' :
                'heading-unActive-TravelCategories-TravelCategories'
                }
                >
                    Amusement Parks
                </div>
             
                <img
                    src={handleImageLink("amusement_parks")} 
                    className={
                    activeTravelCategory === "amusement_parks" ? 'img-active-TravelCategories-TravelCategories' :
                    'img-unActive-TravelCategories-TravelCategories'
                    }
                />

            </button>

            <button 
                className={
                activeTravelCategory === "adventure_activities" ? 'button-active-TravelCategories-TravelCategories' :
                'button-unActive-TravelCategories-TravelCategories'
                }
                onClick={()=>{setActiveTravelCategory("adventure_activities")}}
            >
                <div
                className={
                activeTravelCategory === "adventure_activities" ? 'heading-active-TravelCategories-TravelCategories' :
                'heading-unActive-TravelCategories-TravelCategories'
                }
                >
                    Adventure Activities
                </div>
              
                <img
                    src={handleImageLink("adventure_activities")} 
                    className={
                    activeTravelCategory === "adventure_activities" ? 'img-active-TravelCategories-TravelCategories' :
                    'img-unActive-TravelCategories-TravelCategories'
                    }
                />

            </button>

            <button 
                className={
                activeTravelCategory === "rent_vehicle" ? 'button-active-TravelCategories-TravelCategories' :
                'button-unActive-TravelCategories-TravelCategories'
                }
                onClick={()=>{setActiveTravelCategory("rent_vehicle")}}
            >
                <div
                className={
                activeTravelCategory === "rent_vehicle" ? 'heading-active-TravelCategories-TravelCategories' :
                'heading-unActive-TravelCategories-TravelCategories'
                }
                >
                    Rent Vehicle
                </div>
               
                <img
                    src={handleImageLink("rent_vehicle")} 
                    className={
                    activeTravelCategory === "rent_vehicle" ? 'img-active-TravelCategories-TravelCategories' :
                    'img-unActive-TravelCategories-TravelCategories'
                    }
                />

            </button>

            <button 
                className={
                activeTravelCategory === "shopping_malls" ? 'button-active-TravelCategories-TravelCategories' :
                'button-unActive-TravelCategories-TravelCategories'
                }
                onClick={()=>{setActiveTravelCategory("shopping_malls")}}
            >
                <div
                className={
                activeTravelCategory === "shopping_malls" ? 'heading-active-TravelCategories-TravelCategories' :
                'heading-unActive-TravelCategories-TravelCategories'
                }
                >
                    Shopping Malls
                </div>
              
                <img
                    src={handleImageLink("shopping_malls")} 
                    className={
                    activeTravelCategory === "shopping_malls" ? 'img-active-TravelCategories-TravelCategories' :
                    'img-unActive-TravelCategories-TravelCategories'
                    }
                />

            </button>

            <button 
                className={
                activeTravelCategory === "street_market" ? 'button-active-TravelCategories-TravelCategories' :
                'button-unActive-TravelCategories-TravelCategories'
                }
                onClick={()=>{setActiveTravelCategory("street_market")}}
            >
                <div
                className={
                activeTravelCategory === "street_market" ? 'heading-active-TravelCategories-TravelCategories' :
                'heading-unActive-TravelCategories-TravelCategories'
                }
                >
                    Street Market
                </div>
             
                <img
                    src={handleImageLink("street_market")} 
                    className={
                    activeTravelCategory === "street_market" ? 'img-active-TravelCategories-TravelCategories' :
                    'img-unActive-TravelCategories-TravelCategories'
                    }
                />

            </button> */}

        </div>

    </div>
  )
}

export default TravelCategories








