import React, { useState } from 'react'

//import scss
import './MustVisit.scss'

//
import { Rating } from 'react-simple-star-rating';

//react icon
import { BsBookmarkPlus } from "react-icons/bs";
import { BsFillBookmarkDashFill } from "react-icons/bs";
// import { BsBookmarkHeart } from "react-icons/bs";
// import { BsBookmarkHeartFill } from "react-icons/bs";

import {TravelCategories_Context} from '../../../../../Manage/Context/TravelCategories_Context'
import {TravelCategories_3_Context} from '../../../../../Manage/Context/TravelCategories_3_Context'
//--global components
import {useValidatePlanTrip} from '../../../../../../../Manage - Plan Trip/Context/ValidatePlanTrip'
import {useGlobalNavigationPlanTrip} from '../../../../../../../Manage - Plan Trip/Context/GlobalNavigationPlanTrip';
import ManageLogin from '../../../../../../../../../Manage User Credentials/User Login/ManageLogin';
import CreateTrip from '../../../../../../Create Trip/CreateTrip';

//
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import PreHotel_Temp from '../../../Hotels/PreHotel_Temp';


function MustVisit(props) {

  const {
    mustVisit_touristPlaces_data, passedZeros_forUnique, stateName
  } = props;


  const { 
    //places and saved places data
    saved_touristPlaces_data,  //mustVisit_touristPlaces_data
  } = TravelCategories_Context();

  const {
    handle_Save_Places, handle_UnSave_Places,
    handle_Click_TouristPlaces, handle_MouseOver_TouristPlaces, handle_MouseOut_TouristPlaces, handle_ViewMore_TouristPlaces
  } = TravelCategories_3_Context();
  
  const {tripDestinationType, stateMultipleDistricts, userEmail, tripId} = useValidatePlanTrip();

  const { createTripStatus, setCreateTripStatus, handleTripName, handleCreateUpdateTrip } = useGlobalNavigationPlanTrip();

  const[loginWindow_limited, setLoginWindow_limited] = useState(false)
  const[createTripWindow_limited, setCreateTripWindow_limited] = useState(false)

  const modified_state_name = stateName.toLowerCase().replace(/\s+/g, '_');
    
  return(
    <div
    // style={{alignItems:'center', textAlign:'center'}}
      className='container_p-cards-TouristPlace-MustVisit'
    >

      {/* <PreHotel_Temp /> */}

      {/* <div onClick={mustVisit_Test}>test - mustVisit</div> */}
      { mustVisit_touristPlaces_data &&
        mustVisit_touristPlaces_data.map((data, index) => {
          // if(data.district === activeDestination){
          return(
            <button
            className='card-TouristPlace-MustVisit'
            onClick={()=>handle_Click_TouristPlaces(data.sn, data.lat, data.lng, data.name)}
            onMouseOver={()=>handle_MouseOver_TouristPlaces(data.name, data.lat, data.lng)}
            onMouseOut={()=>handle_MouseOut_TouristPlaces()}
            style={{position:'relative'}}
            >
              <div style={{cursor:'auto'}}>

              </div>
              <div style={{position:'relative'}}>

                <img 
                  className="image-Card-TouristPlace-MustVisit" 
                  // src='https://st.depositphotos.com/28379836/60629/i/450/depositphotos_606291124-stock-photo-painted-anime-nature-environment-background.jpg'
                  src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${data.sn}-01.jpg`}
                  onError={(e) => { e.target.src = 'https://plus.unsplash.com/premium_photo-1676218968741-8179dd7e533f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'; }}
                  alt="Image 1" 
                />

                <div
                className='text-index-MustVisit'     
                >
                  {passedZeros_forUnique}{index+1}.
                </div>  

                {/* bookmark */}
                { 
                  saved_touristPlaces_data && saved_touristPlaces_data.some(item=> item.id === data.sn)
                    ?
                  (
                    <button className='container_t-icon-card-TouristPlace-MustVisit' onClick={(e) => {
                      e.stopPropagation();
                      handle_UnSave_Places(data.sn);
                    }}> 
                      <div className='icon-card-TouristPlace-MustVisit'>
                        <BsFillBookmarkDashFill />
                      </div>   
                    </button>   
                  )
                  :
                  (
                    <button className='container_t-icon-card-TouristPlace-MustVisit' onClick={(e) => {
                      e.stopPropagation(); 
                      if(userEmail && tripId){
                        handle_Save_Places(data.sn, data.explore_duration, data.name, data.lat, data.lng, data.district, "tourist_places");
                      }
                      else if(!userEmail){
                        // setLoginWindow_limited(true)
                        toast.error("Please login!", {
                          position: toast.POSITION.TOP_CENTER
                        })
                      }
                      else{
                        // setCreateTripWindow_limited(true)
                        setCreateTripStatus(true)
                      }
                      }}>
                      <div className='icon-card-TouristPlace-MustVisit'>
                        <BsBookmarkPlus />
                      </div>   
                    </button> 
                  )

                }
                
              </div>
                
              <div className="heading-placeName-TouristPlace-MustVisit">
                  {data.name}
              </div>

              <div className="container_t-placeName-TouristPlace-MustVisit">

                <div className='heading-1-placeName-TouristPlace-MustVisit'>
                    <Rating
                      initialValue={data.rating}
                      size={16}
                      emptyColor="#ccc"
                      fillColor="gold"
                      strokeColor="gold"
                      strokeWidth={0}
                      allowFraction={true}
                      readonly
                    />  
                </div>

                <div className='heading-2-placeName-TouristPlace-MustVisit'>
                ({parseFloat(data.rating).toFixed(1)}) &nbsp; 
                </div>

                {/*  */}
                <div className='heading-3-placeName-TouristPlace-MustVisit'>
                  {
                    data.review_count ? Number(data.review_count).toLocaleString('en-IN') : 'N/A' 
                  }
                </div>

              </div>  

              {/*  */}
              {
                (data.explore_duration && data.explore_duration != "") &&
                <div 
                className='heading-4-card-duration-TouristPlace-MustVisit'
                >
                  Explore in: {data.explore_duration} hrs
                </div>
              }


              {/*  */}
              <div 
              className="container_t-2-placeName-TouristPlace-MustVisit"
              >
{/* 
                { //only for state
                  stateMultipleDistricts && tripDestinationType === "state" && data.destination && 
                  <div
                  className='heading-4-card-placeName-TouristPlace-MustVisit'
                  >
                    in #{data.destination}
                  </div>    
                } */}

                <div
                className='heading-4-card-placeName-TouristPlace-MustVisit'
                >
                  #{data.category}
                </div>

              </div>

            
            

                {/*  */}
                <div className='container_s-line-MustVisit'>
                  <div className='line-card-MustVisit'></div> 
                </div>

                <div className='container_s-viewMore-MustVisit'>
                  <button className="button-viewMore-card-MustVisit" 
                  onClick={(e) => {
                    e.stopPropagation(); 
                    handle_ViewMore_TouristPlaces(data.sn);
                  }}>
                      View More
                  </button>
                </div>

            </button>  
          )
        // }
        })
      }

      <div style={{height:'120px'}}></div>

      {/* <div > */}
      {loginWindow_limited && 
        <div
        className='conatiner_p-login-PlanTripHeader'
        >
            <div
            className='heading-login-PlanTripHeader'
            >
                Login to Your Trip
            </div>    
            <div
            className='container_t-login-PlanTripHeader'
            >
                <ManageLogin />
            </div>
        </div>
      }

      {/* {createTripWindow_limited && 
        <div >
            <CreateTrip />
        </div>
      } */}

      <ToastContainer />

    </div>
  )
}

export default MustVisit











                      {/* <img src="https://i.ibb.co/VC5LGZb/save-bookmark.png" alt="Image 1" className='PlaceCardsBookmark-PlaceCardsCss02'/> */}




 {/* <img src="https://i.ibb.co/fr32spL/bookmark.png" alt="Image 1" className='PlaceCardsBookmark-PlaceCardsCss02'/>  */}
                      {/* <BsBookmarkHeart /> */}