import React from 'react'

//import css
import './Ai_Suggestions.css';

//importing react context
import { useAiCreateItinerary } from '../../../Create Itinerary - Result/Manage/Context/Ai_CreateItineraryContext';

function Ai_Suggestions() {

  const {
    
  } = useAiCreateItinerary()

  return (
    <div>Ai_Suggestions
      
    </div>
  )
}

export default Ai_Suggestions