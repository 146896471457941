import React from 'react'

// import react context 
import {useHowToReachContext} from '../../../Manage/Context/HowToReachContext_Mobile'

//import icons
import { GrLocationPin } from "react-icons/gr";



function Air_Mobile() {

  //import from useContext 
  const {
    airContent, 
    destinationType, destinationName, 
    //functions
    handleHoverLocation_Map, handleHoverOffLocation_Map,
    handleClickLocation_Map
  } = useHowToReachContext();
  

  return (
    <div>

      {
        !airContent && 
        <img 
        src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/no_travel_mode_found_image_1.jpg'
        
        className='img-1-heading-HowToReach_Main'
        />
      }

      {
        airContent.find((data=> (data.mode_type === "international" || data.mode_type === "International")))
        &&
        <div className='heading-1-mode-Reach_D'>
            International Airport
          </div>    
      }


      <div className='container_p-Cards-Air-Reach_D_Mobile'>
      {
          airContent.map((data, index) => {
              if(data.mode_type === "international" || data.mode_type === "International"){
              return(
                <button key={index}
                className='card-Air-Reach_D_Mobile'
                onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name)}}
                onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name)}}
                onMouseOut={()=>{handleHoverOffLocation_Map()}}
                >
                    <div className='text-1-air-Reach_D'>
                        {data.mode_name}
                    </div>

                    <div className='text-2-air-Reach_D'>
                        Code: {data.mode_code}
                    </div>

                    <div className='text-3-air-Reach_D'>
                        <div className='text-3-1-air-Reach_D'>
                            in {data.mode_district} 
                        </div>
                        {data.mode_state_name && 
                        <div className='text-3-1-air-Reach_D'>
                            , {data.mode_state_name}.
                        </div>
                        }
                    </div>

                    {
                    data.mode_g_review &&
                    <div className='text-4-air-Reach_D'>
                        G reviews: ({data.mode_g_review.toLocaleString('en-IN')})
                    </div>
                    }     
                    
                
                    { data.distance &&
                    destinationType !== "state" ?
                    <div className='text-5-air-Reach_D'>
                        <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                    </div> :
                    null
                    }
                    
                </button>    
              )
              } 
          })
      }
      </div>

      {/* <div className='heading-1-mode-Reach_D'>
          Domestic Airports
      </div>   */}

      {/* {
      airContent.map((data, index) => {
        if((index < 1 && data.mode_type === "domestic" || data.mode_type === "Domestic")){
        return(
          <div className='heading-1-mode-Reach_D'>
              Domestic Airport
          </div>    
        )
      }})
      } */}

      
      {
        airContent.find((data=> (data.mode_type === "domestic" || data.mode_type === "Domestic")))
        &&
        <div className='heading-1-mode-Reach_D'>
              Domestic Airport
          </div>    
      }


      <div className='container_p-Cards-Air-Reach_D'>
      {
          airContent.map((data, index) => {
              if(data.mode_type === "domestic" || data.mode_type === "Domestic"){
              return(
                  <button key={index}
                  className='card-Air-Reach_D'
                  onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name)}}
                  onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name)}}
                  onMouseOut={()=>{handleHoverOffLocation_Map()}}
                  >
                      <div className='text-1-air-Reach_D'>
                          {data.mode_name}
                      </div>

                      <div className='text-2-air-Reach_D'>
                          Code: {data.mode_code}
                      </div>

                      <div className='text-3-air-Reach_D'>
                          <div className='text-3-1-air-Reach_D'>
                              in {data.mode_district} 
                          </div>
                          {data.mode_state_name && 
                          <div className='text-3-1-air-Reach_D'>
                              , {data.mode_state_name}.
                          </div>
                          }
                      </div>

                      { data.mode_g_review &&
                      <div className='text-4-air-Reach_D'>
                          G reviews: ({data.mode_g_review.toLocaleString('en-IN')})
                      </div>
                      }    
                      
                  
                      { data.distance &&
                      destinationType !== "state" ?
                      <div className='text-5-air-Reach_D'>
                          <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                      </div> :
                      null
                      }

                  </button>    
              )
              } 
          })
      }
      </div>


      {/* <div> 
        { airContent &&
          airContent.map((data, index) => {
            return(
            <div key={index}
              onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name)}}
              onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name)}}
              onMouseOut={()=>{handleHoverOffLocation_Map()}}
            >

              mode_name: {data.mode_name} <br></br>
              mode_code: {data.mode_code} <br></br>
              mode_type: {data.mode_type} <br></br>
              mode_district: {data.mode_district} <br></br>
              mode_state_name: {data.mode_state_name}

              <br></br> <br></br> <br></br> &nbsp;
            </div> 
            ) 
          })
        }
      </div> */}

    </div>
  )
}

export default Air_Mobile