import React, {useEffect, useState, useRef} from 'react'
import { useNavigate } from 'react-router-dom';
//for generating unique id
import { v4 as uuidv4 } from 'uuid';

//import scss
import './CreateItinerary_AI.scss'

//import icons
import { IoMdCloseCircle } from "react-icons/io";

//importing arrays
import {get_prompt_1, prompt_select_categories_1, prompt_select_categories_2, prompt_when, prompt_duration} from '../Manage/Data/Prompts_CreateItinerary';

// import other tools 
import axios from 'axios';
import ManageLogin from '../../../../../Manage User Credentials/User Login/ManageLogin';
import { server_baseUrl } from '../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';

function CreateItinerary_AI(props){

  //@@ 
  const navigate = useNavigate();

  //states
  const [randomPromt1, setRandomPromt1] = useState("")
  const [randomPromt2_1, setRandomPromt2_1] = useState()
  const [randomPromt2_2, setRandomPromt2_2] = useState()
  const [randomPromt3, setRandomPromt3] = useState()
  const [randomPromt4, setRandomPromt4] = useState()

  const [promt1_options, setPromt1_options] = useState()
  const [promt2_options, setPromt2_options] = useState()
  const [promt3_options, setPromt3_options] = useState()

  const [promt1_select, setPromt1_select] = useState()

  const [destinationName, setDestinationName] = useState()
  const [destinationType, setDestinationType] = useState()
  const [categoryClick, setCategoryClick] = useState("famous_categories")

  const [easySelectCat, setEasySelectCat] = useState()
  const [inputResult, setInputResult] = useState()

  const [famousCategories, setFamousCategories] = useState([]);
  const [otherCategories, setOtherCategories] = useState([]);
  const [categoryMessage, setCategoryMessage] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [categoryDoneStatus, setCategoryDoneStatus] = useState(false);

  const [bestSeason, setBestSeason] = useState([]);
  const [seasonTypeInput, setSeasonTypeInput] = useState();

  const [number, setNumber] = useState(0);

  //@@ using props
  const handle_CloseAIFeature = props.handle_CloseAIFeature;
  const{userEmail} = props;

  //@@ useEffect 
  useEffect(() => {
    handleGetPromt1();
    // handleInput_CityDistrict();
  }, [])


  function animateText(randomEntity_temp, setRandomPromt1, setDisplayLater) {
    let index = 0;
    const intervalId = setInterval(() => {
      const currentWord = randomEntity_temp.substring(0, index + 1);
      setRandomPromt1(currentWord); // Update external state
      index++;
  
      if (index >= randomEntity_temp.length) {
        clearInterval(intervalId);
        if(setDisplayLater){
          setDisplayLater(true)
        }
      }
    }, 30);
  
  }
  
  
  const[showLater1, setShowLater1] = useState(false)
  const[showLater2, setShowLater2] = useState(false)
  const[showLater3, setShowLater3] = useState(false)
  const[showLater4, setShowLater4] = useState(false)

  // handle load prompt 1
  const handleGetPromt1 = () => {
    const randomEntity_temp = get_prompt_1[Math.floor(Math.random() * get_prompt_1.length)];

    // setRandomPromt1(randomEntity_temp);
    animateText(randomEntity_temp, setRandomPromt1, setShowLater1)
  }

  // handle load prompt 2
  const handleGetPromt2 = () => {
    const category_1 = prompt_select_categories_1[Math.floor(Math.random() * prompt_select_categories_1.length)];
    const category_2 = prompt_select_categories_2[Math.floor(Math.random() * prompt_select_categories_2.length)];

    // setRandomPromt2_1(category_1);
    animateText(category_1, setRandomPromt2_1)
    
    setRandomPromt2_2(category_2);
    animateText(category_2, setRandomPromt2_2, setShowLater2)


  }

  // handle load prompt 3
  const handleGetPromt3 = () => {
    const inputType_temp = prompt_when[Math.floor(Math.random() * prompt_when.length)];
    setRandomPromt3(inputType_temp);
    animateText(inputType_temp, setRandomPromt3, setShowLater3)
  }

  // handle load prompt 4
  const handleGetPromt4 = () => {
    const inputType_temp = prompt_duration[Math.floor(Math.random() * prompt_duration.length)];
    setRandomPromt4(inputType_temp);
    animateText(inputType_temp, setRandomPromt4, setShowLater4)
  }

  //@@ handle button click/input
  const handleDestinationClick = (name, type) => {
    // alert("i was called...")
    handleGetPromt2();
    setDestinationName(name)
    setDestinationType(type)
    handleLoadCategories(name, type);
    setInputResult();
  }

  const handleCategoriesClick = (data) => {
    setCategoryClick(data);
    if(data === "famous_categories"){
      setEasySelectCat();
    }else{
      setCategoryList([]);
    }
  }

  const handleEasySelectClick = (data) => {
    setEasySelectCat(data);
    setCategoryList([]);
  }

  const handleCategoryClick = (category) => {
    setCategoryList([...categoryList, category]);
    setEasySelectCat();
}

  const handleCategoryUnClick = (category) => {
      const filteredCategoryList = categoryList.filter(item => item !== category);
      setCategoryList(filteredCategoryList);
  }

  const handleCategoryDone = () => {
    setCategoryDoneStatus(true);
    handleGetPromt3()
    handleGetBestSeason();
  }

  // handle season click
  const handleSeasonSelect = (value) => {
    handleGetPromt4()
    setSeasonTypeInput(value)
  }

  // Function to handle increasing the number
  const increaseNumber = () => {
    if (number < 10) {
      setNumber(prevNumber => prevNumber + 1);
    }
  };

  // Function to handle decreasing the number
  const decreaseNumber = () => {
    if (number > 0) {
      setNumber(prevNumber => prevNumber - 1);
    }
  };


  //@@ get prompt options

  // handle load functions click
  const handleInput_CityDistrict = async (event) => {
    const inputValue = event.target.value;
    setDestinationName(inputValue);      
    if(inputValue){
      const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/manage_ai_components/get_district_city_name`, {inputValue})
      // console.log("handleDestinationInput", response.data);
      if(response.data.length > 0){
        setInputResult(response.data)
      }
      else{
        setInputResult();
      }
    }
  }

  // handle load functions click
  const handleLoadCategories = async (destination_name, destination_type) => {
    const response = await axios.post(`${server_baseUrl}/csc/send/api/ai_tools/ai_components/itinerary_ai/get_categories`, {destination_name, destination_type})
    // console.log("handleGetValuesPromt1", response.data)
    if(response.data){
      setFamousCategories(response.data.data.famousCategories)
      setOtherCategories(response.data.data.otherCategories)
      setCategoryMessage(response.data.data.message)
    }
  }

  // handle load functions click
  const handleGetBestSeason = async () => {
    const response = await axios.post(`${server_baseUrl}/csc/send/api/ai_tools/ai_components/itinerary_ai/get_season`, {destinationName, destinationType})
    // console.log("handleGetBestSeason", response.data)
    if(response.data){
      setBestSeason(response.data.data);
    }
  }


  
  //@@ submit

  const handleGetItinerary = async () => {

    const unique_id = await insertItineraryAiSearch();

    console.log("unique_id - unique_id - unique_id - unique_id", unique_id)

    navigate(`ai-tool/create-itinerary-by-ai/${unique_id}`)

  };
  
  const insertItineraryAiSearch = async () => {
    alert("i was called")
    // console.log(easySelectCat, "asas - categoryClick", categoryClick)
    const response =  await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/itinerary_ai/insert_data`, {categoryClick, categoryList, seasonTypeInput, number, destinationName, destinationType, easySelectCat})

    console.log("response", response.data)
    
    return response.data;
  }


  //!!!!!!!!!!!!!!!!

  const containerRef = useRef(null);

  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
    handle_CloseAIFeature(false)
    document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };

  const[displaySearchResult, setDisplaySearchResult] = useState(false)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setDisplaySearchResult(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div>

      {/* {
        !userEmail &&
        <ManageLogin />
      } */}

      <div 
      onClick={()=>{
        closePopup()
      }}
      className='popup-background-1-CreateItinerary_AI'
      > </div>

     <div className='popup_p-2-addNote-CreateItinerary_AI'>

      {
        !userEmail &&
        <div
        className='popup_s-2-1-addNote-CreateItinerary_AI'
        > 
          <ManageLogin />

        </div>
      }  

      {/*//! #1st Container  */}
      {
        userEmail &&
      
        <div
        className='popup_s-2-1-addNote-CreateItinerary_AI'
        >

          <button
          className='button-close-CreateItinerary_AI'
          onClick={()=>closePopup()}
          >
            <IoMdCloseCircle />
          </button>

         <div>
          {
            (randomPromt1) && 
            (
            <div
            className='container_p-searchDestination-CreateItinerary_AI'
            ref={containerRef}
            >
              {/* prompt 1 */}
              <div
              className='container_p-propmt-1-CreateItinerary_AI'
              >
                <img 
                src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/ai_stars_2.png'
                className='img-stars-search-CreateItinerary_AI'
                alt='stars showing artificial intelligence (ai) powered search'
                />
          
                <div
                className='text-propmt-1-CreateItinerary_AI'
                >
                  {randomPromt1}
                </div>
              </div>

              { showLater1 &&
                <div className='text-suggest-searchInput-CreateItinerary_AI'> eg: Surat, Vadodara, Ahmedabad</div>
              }
            
              {/*//! Step 1 #1  */}
              { showLater1 &&
                <div
                className='container_p-searchInput-CreateItinerary_AI'
                >
                  <input 
                  placeholder='enter city/town/district' 
                  value={destinationName} 
                  onChange={handleInput_CityDistrict}
                  onClick={()=>{setDisplaySearchResult(true)}}
                  className='input-searchInput-CreateItinerary_AI'
                  disabled={randomPromt2_1}
                  />
                </div>
              }

              { (displaySearchResult && inputResult && destinationName) &&
                <div
                className='container_p-popup-searchDestinations-CreateItinerary_AI'
                >
                {
                  inputResult.map((data, index) => {
                    return(
                      <button key={index}
                      className='container_s-popup-searchDestinations-CreateItinerary_AI'
                      onClick={()=>{handleDestinationClick(data.name,
                        data.type)}}
                      >
                        <div
                         className='text-1-popup-searchDestinations-CreateItinerary_AI'
                        >
                          {data.name}
                        </div>  

                        <div
                          className='text-2-popup-searchDestinations-CreateItinerary_AI'
                        >
                          {/* a <i>{data.type}</i> */}
                          &nbsp; a {data.type}
                        </div> 

                        <div
                        className='text-3-popup-searchDestinations-CreateItinerary_AI'
                        >
                          &nbsp; in {data.state_name}
                        </div>
                   
                      </button>  
                    )
                  })
                }
                </div>
              }

            </div>
            )
          } 

          {
            (randomPromt2_1) && 
            <div>
             
              {/* prompt 2 */}
              {/* <div>
                {randomPromt2_1} {destinationName}. {randomPromt2_2}
              </div> */}

              <div
              className='container_p-propmt-2-CreateItinerary_AI'
              >
                <img 
                src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/ai_stars_2.png'
                className='img-stars-search-CreateItinerary_AI'
                alt='stars showing artificial intelligence (ai) powered search'
                />
          
                <div
                className='text-propmt-1-CreateItinerary_AI'
                >
                  {/* {randomPromt2_1} {destinationName}.  */}
                  {randomPromt2_2}
                </div>
              </div>

              {
                showLater2 &&
              
                <div
                className='container-buttons-prompt-2-CreateItinerary_AI'
                >
                  <button 
                  onClick={()=>{handleCategoriesClick("easy_select")}}
                  className={categoryClick === 'easy_select' ? 'button-active-prompt-2-CreateItinerary_AI' : 'button-unActive-prompt-2-CreateItinerary_AI'}
                  disabled={randomPromt3}
                  >
                    Easy Select
                  </button>

                  <button 
                  onClick={()=>{handleCategoriesClick("famous_categories")}}
                  className={categoryClick === 'famous_categories' ? 'button-active-prompt-2-CreateItinerary_AI' : 'button-unActive-prompt-2-CreateItinerary_AI'}
                  disabled={randomPromt3}
                  >
                    Select Categories
                  </button>

                </div>
              }
              
              {
                showLater2 &&
                
                <div
                className='container_p-1-categories-CreateItinerary_AI'
                >         
                  {
                    categoryClick === "easy_select" && 
                    (
                      <div
                      className='container_p-buttons-prompt2-CreateItinerary_AI'
                      >

                        <button 
                        onClick={()=>{handleEasySelectClick("famous")}}
                        className={easySelectCat === 'famous' ? 'button-active-category-prompt2-CreateItinerary_AI' : 'button-unActive-category-prompt2-CreateItinerary_AI'}
                        disabled={randomPromt3}
                        >
                          Only Famous Places
                        </button>

                        <button 
                        onClick={()=>{handleEasySelectClick("less_explored")}}
                        className={easySelectCat === 'less_explored' ? 'button-active-category-prompt2-CreateItinerary_AI' : 'button-unActive-category-prompt2-CreateItinerary_AI'}
                        disabled={randomPromt3}
                        >
                          Only Less Explored Places
                        </button>

                        {/* <button 
                        onClick={()=>{handleEasySelectClick("hidden_gems")}}
                        className={easySelectCat === 'hidden_gems' ? 'button-active-category-prompt2-CreateItinerary_AI' : 'button-unActive-category-prompt2-CreateItinerary_AI'}
                        disabled={randomPromt3}
                        >
                          Hidden Gems
                        </button>

                        <button 
                        onClick={()=>{handleEasySelectClick("mix_famous_gems")}}
                        className={easySelectCat === 'mix_famous_gems' ? 'button-active-category-prompt2-CreateItinerary_AI' : 'button-unActive-category-prompt2-CreateItinerary_AI'}
                        disabled={randomPromt3}
                        >
                          A mix of famous and Hidden Gems
                        </button> */}

                        <button 
                        onClick={()=>{handleEasySelectClick("ai_decide")}}
                        className={easySelectCat === 'ai_decide' ? 'button-active-category-prompt2-CreateItinerary_AI' : 'button-unActive-category-prompt2-CreateItinerary_AI'}
                        disabled={randomPromt3}
                        >
                          Let AI Decide
                        </button>

                      </div>
                    )
                  }
                </div>
              }

              { showLater2 &&
                categoryClick === "famous_categories" && 
                (
                  <div
                  className='container_p-2-categories-CreateItinerary_AI'
                  >

                    { famousCategories.length > 0 &&
                      <div>
                        <div
                        className='text-1-category-prompt2-CreateItinerary_AI'
                        >
                          Famous Attraction Types
                        </div>

                        <div
                         className='container_p-buttons-prompt2-CreateItinerary_AI'
                        >
                        {
                          famousCategories.map((category, index) => {
                            return(
                            <div key={index}>
                              {(categoryList && categoryList.length > 0) && categoryList.includes(category) ? (
                                <button 
                                  style={{fontWeight:"bold"}}
                                  onClick={() => {handleCategoryUnClick(category)}}
                                  className='button-active-category-prompt2-CreateItinerary_AI'
                                  disabled={randomPromt3}
                                >
                                  {category}
                                </button>
                              ) 
                              : 
                              (
                                <button 
                                  onClick={() => {handleCategoryClick(category)}}
                                  className='button-unActive-category-prompt2-CreateItinerary_AI'
                                  disabled={randomPromt3}
                                >
                                  {category}
                                </button>
                              )}
                            </div>  
                            )
                          })
                        }
                        </div>  

                      </div>
                    }

                                                    
                    {  showLater2 && otherCategories.length > 0 &&
                      <div
                      >
                        <div
                          className='text-1-category-prompt2-CreateItinerary_AI'
                        >
                          Other Interesting Attraction Types
                        </div>

                        <div
                          className='container_p-buttons-prompt2-CreateItinerary_AI'
                        >
                          {
                            otherCategories.map((category, index) => {
                              return(
                                <div key={index}> 
                                  { categoryList &&
                                    categoryList.includes(category) ? 
                                    (
                                      <button 
                                        style={{fontWeight:"bold"}}
                                        onClick={() => {handleCategoryUnClick(category)}}
                                        className='button-active-category-prompt2-CreateItinerary_AI'
                                        disabled={randomPromt3}
                                      >
                                        {category}
                                      </button>
                                    )
                                    :
                                    (
                                      <button 
                                        onClick={() => {handleCategoryClick(category)}}
                                        className='button-unActive-category-prompt2-CreateItinerary_AI'
                                        disabled={randomPromt3}
                                      >
                                        {category}
                                      </button>
                                    )
                                  }
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    }

                  </div>
                )
              }
            
              {
                showLater2 &&
              
                <div
                >
                  { 
                    (!categoryDoneStatus && ((categoryList && categoryList.length > 0) || easySelectCat)) && 
                    (
                      <button 
                      onClick={handleCategoryDone}
                      className='button-1-submit-CreateItinerary_AI'
                      >
                        Done
                      </button> 
                    )
                  }
                </div>
              }

            </div>  
          }

          {
            categoryDoneStatus && 
            (<div>
                {/* <div>
                  {bestSeason}
                  {randomPromt3} {destinationName}?
                </div>   */}

                <div
                className='container_p-propmt-2-CreateItinerary_AI'
                >
                  <img 
                  src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/ai_stars_2.png'
                  className='img-stars-search-CreateItinerary_AI'
                  alt='stars showing artificial intelligence (ai) powered search'
                  />
            
                  <div
                  className='text-propmt-1-CreateItinerary_AI'
                  >
                    {randomPromt3} {destinationName}? {bestSeason} 
                  </div>
                </div>

                {
                  showLater3 &&
                
                  <div
                  className='container_p-seasons-buttons-prompt2-CreateItinerary_AI'
                  >
                    <button 
                    onClick={()=>{handleSeasonSelect("winter")}}
                    className={seasonTypeInput === 'famous' ? 'button-active-category-prompt2-CreateItinerary_AI' : 'button-unActive-category-prompt2-CreateItinerary_AI'}
                    >
                      Winter
                    </button>
                    
                    <button 
                    onClick={()=>{handleSeasonSelect("summer")}}
                    className={seasonTypeInput === 'summer' ? 'button-active-category-prompt2-CreateItinerary_AI' : 'button-unActive-category-prompt2-CreateItinerary_AI'}
                    >
                      Summer
                    </button>

                    <button 
                    onClick={()=>{handleSeasonSelect("monsoon")}}
                    className={seasonTypeInput === 'monsoon' ? 'button-active-category-prompt2-CreateItinerary_AI' : 'button-unActive-category-prompt2-CreateItinerary_AI'}
                    >
                      Monsoon
                    </button>

                    <button 
                    onClick={()=>{handleSeasonSelect("post_monsoon")}}
                    className={seasonTypeInput === 'post_monsoon' ? 'button-active-category-prompt2-CreateItinerary_AI' : 'button-unActive-category-prompt2-CreateItinerary_AI'}
                    >
                      Post-Monsoon
                    </button>

                  </div>  
                } 

              </div>
            )
          }


          {
            seasonTypeInput && 
            (<div>
                
                <div
                className='container_p-propmt-2-CreateItinerary_AI'
                >
                  <img 
                  src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/ai_stars_2.png'
                  className='img-stars-search-CreateItinerary_AI'
                  alt='stars showing artificial intelligence (ai) powered search'
                  />
            
                  <div
                  className='text-propmt-1-CreateItinerary_AI'
                  >
                    {randomPromt4} 
                  </div>
                </div>

                {
                  showLater4 &&
                
                  <div className="days-CreateItinerary_AI">
                    <button onClick={decreaseNumber}>-</button>
                    <input type="text" value={number} readOnly />
                    <button onClick={increaseNumber}>+</button>
                  </div>
                }

              </div>
            )
          }


          {
            number > 0 && 
            (<div>
              <button 
              onClick={()=>{
                handleGetItinerary()
                closePopup()
              }}
              className='button-1-submit-CreateItinerary_AI'
              >
                Create Itinerary
              </button>
              <img 
              src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/ai_stars_1.png'
              className='img-stars-2-search-CreateItinerary_AI'
              alt='stars showing artificial intelligence (ai) powered search'
              />
            </div>  
            )
          }

          <div style={{height:'100px'}}></div>

         </div>  

        </div>

      }  


        {/*//! #2nd Container  */}
        <div
        className='popup_s-2-2-Image-CreateItinerary_AI'
        >
          <img 
          src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/create_ai_itinerary-1.jpeg'
          className='image-ai-main-CreateItinerary_AI'
          />

        </div>


     </div>

    </div>
  )
}

export default CreateItinerary_AI


