import React, {useRef, useEffect} from 'react'

//importing css
import './DaySelector_TD.scss'

//import react context
import {useCreateItineraryPlanTrip} from '../../../../Manage/Context/CreateItinerary_Context';
import {useCreateItinerary_2_PlanTrip} from '../../../../Manage/Context/CreateItinerary_Context_2';

//import icon
import { RiDeleteBinLine } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import AiItinerary_Chat from '../AiItinerary_Chat/AiItinerary_Chat';


function DaySelector_TD(props) {

  const{isMobile} = props;

  const containerRef = useRef(null);

  const {
    //*states 
    dayData,
    activeDayId, setActiveDayId,
    showDates_ActiveId, setShowDates_ActiveId,

    //*Status
    saveChangesStatus, setSaveChangesStatus,

    //*functions 
    handleAddDay,
    handleDeleteDay,
    handleEdit_AddDate_Day,
    handleShowDates
  } = useCreateItineraryPlanTrip()


  const {
   
    //!States

    //*manage
    aiItinerary_initialLoad_Status, setAiItinerary_initialLoad_Status,
    sentActive_status, setSentActive_status,

    //track ai questions count
    askQuestionCount_day,    

    //*data
    aiItineraryData, setAiItineraryData,
    currentQuestion, setCurrentQuestion,
    currentAnswer, setCurrentAnswer,
    aiItinerary_Id, setAiItinerary_Id,

    //*navigate
    navigate_aiItineraryChat, setNavigate_aiItineraryChat,
    aiItineraryChat_window, setAiItineraryChat_window,
  
    
    //!Functions   
    handleInput_CurrentQuestion,
    handleAskQuestion

  } = useCreateItinerary_2_PlanTrip()


  const formatDate = (dateString) => {
    const options = { day: '2-digit', weekday: 'short', month: 'short', year: 'numeric' };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', options);
    const [day, weekday, month, year] = formattedDate.split(' ');
    return `${day} (${weekday}) ${month} ${year}`;
  };

  // const handleScrollDay = () => {
  //   setTimeout(handleScrollDay, 4000);
  //   if (containerRef.current) {
  //     containerRef.current.scrollLeft = containerRef.current.scrollWidth;
  //   }
  // };

  const handleScrollDay = () => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollLeft = containerRef.current.scrollWidth;
      }
    }, 100); // Delay by 1 second
  };



  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
    setAiItineraryChat_window(false);
    document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  return (
    <div>



      {
        aiItineraryChat_window && 
        
        <div>
            
          <div className='popup-background-1-AiItinerary_Chat'
          onClick={()=>{
          closePopup()
          }}
          > 

            <div className='popup_p-2-addNote-AiItinerary_Chat'
            onClick={(e)=>{e.stopPropagation()}}
            >
            {/* <div className='popup_s-2-addNote-AddNotes_TO_Popup'> */}

              <AiItinerary_Chat />

            {/* </div> */}
            </div>

          </div>

        </div>
      }




      <div className='conatiner_p-addDay-DaySelector_TD'>

        <button 
        onClick={()=>{
          handleAddDay()
          handleScrollDay()
          setSaveChangesStatus(true)
        }}
        className='button-addDay-DaySelector_TD'
        >
          <div
          className='heading-addDay-DaySelector_TD'
          >
            add day  
          </div>
          {/* <div> */}
          <img 
          className='image-addDay-DaySelector_TD'
          src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/add_icon_6.png'
          />  
          {/* </div> */}
        </button>


        <button
        className='container-askAi-DaySelector_TD'
        onClick={()=>{
          setAiItineraryChat_window(true)
          openPopup()
        }}
        >
          <div className='text-trackAiQCount-DaySelector_TD'>
            {askQuestionCount_day}/20
          </div>

          <div
          className='button-askAi-DaySelector_TD'
          >  
            Ask Ai
          </div>

          <img
            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/ai_chatbot-2.png'
            className='img-iButton-AiItinerary_Chat' 
          />

        </button>


      </div>

      {
        isMobile &&
        <div style={{marginTop:'12px'}}></div>
      }

      { dayData &&
        <div 
        ref={containerRef}
        className='conatiner_p-Days-DaySelector_TD'
        >
        {
        dayData.map((data, index) => {
          return(
            <button key={index} 
            className='conatiner_s-Days-DaySelector_TD'
            onClick={()=>{
              setActiveDayId(data.day_id)
              // setShowDates_ActiveId()
              // setShowDates_ActiveId(data.day_id)
              if(data.day_id !== showDates_ActiveId){
                setShowDates_ActiveId()
              }
            }}
            >
              {
                
                data.day_id === activeDayId 
                // || activeDayId === 1 
                ? 
                <div
                className='conatiner_p-active-Day_Delete-DaySelector_TD'
                >
                  <div 
                    className='heading-Active-Day-DaySelector_TD'
                  >
                    {
                      !data.date ?
                      <div
                      className='heading-Active-Day-DaySelector_TD'
                      > 
                        Day {data.day_id}
                      </div> 
                      :
                      <div
                        className='heading-Active-Day-DaySelector_TD'
                      >
                        {formatDate(data.date)}
                      </div>    
                    }
                  </div>

                  <div>
                    <button 
                    onClick={(event)=>{
                      handleDeleteDay(data.unique_id, data.day_id)
                      //if the previous day exsists, then other wise it would set to zero. 
                      setActiveDayId(data.day_id-1)
                      event.stopPropagation()
                    }}
                    className='button-delete-Day-DaySelector_TD'
                    >
                      <RiDeleteBinLine />
                    </button>
                  </div> 

                </div>  
                :
                <div
                className='conatiner_p-unActive-Day_Delete-DaySelector_TD'
                >
                  {
                    !data.date ?
                    <div
                    className='heading-unActive-Day-DaySelector_TD'
                    > 
                      Day {data.day_id}
                    </div> 
                    :
                    <div
                      className='heading-unActive-Day-DaySelector_TD'
                    >
                      {formatDate(data.date)}
                    </div>    
                  }
                </div>   
             
              }


              { data.day_id === activeDayId && !showDates_ActiveId &&
                <div>
                  <buton 
                  onClick={(event)=>{
                    handleShowDates(data.day_id)
                    event.stopPropagation()
                  }}
                  className='buttonA-edit_add_date-DaySelector_TD'
                  >
                    {
                      data.date ? 
                      <button
                      className='button-edit_add_date-DaySelector_TD'
                      >
                        Edit Date
                      </button>
                      :
                      <button
                      className='button-edit_add_date-DaySelector_TD'
                      >
                        Add Date
                      </button>
                    } 
                  </buton>
                </div>  
              }
              
              {
                (showDates_ActiveId && showDates_ActiveId === data.day_id) && data.day_id === activeDayId &&
                <div className='conatiner_p-inputDate-DaySelector_TD'>

                  <div>
                    <input 
                    className='input-inputDate-DaySelector_TD'
                      value={data.date}
                      onChange={(event)=>{
                        event.stopPropagation(); 
                        handleEdit_AddDate_Day(event, data.day_id)
                      }}
                      type='date'
                    />
                  </div> 

                  <div>
                    <buton onClick={()=>{setShowDates_ActiveId()}}
                    className='button-DaySelector_TD'
                    >
                      <IoClose />
                    </buton>
                  </div>   

                </div> 
              }

            

            </button>
          )
        })
        }
        </div>
      }
    </div>
  )
}

export default DaySelector_TD