import React from 'react'

// import react context 
import {useHowToReachContext} from '../../../Manage/Context/HowToReachContext_Mobile'

//import icons
import { GrLocationPin } from "react-icons/gr";

function Bus_Mobile() {

  //import from useContext 
  const {
    busContent,
    destinationType, destinationName,
    //functions
    handleHoverLocation_Map, handleHoverOffLocation_Map,
    handleClickLocation_Map
  } = useHowToReachContext();
  

  return (
    <div>


              {
                busContent.find((item=> (item.mode_type === "isbt" || item.mode_type === "ISBT")))
                &&
                <div className='heading-1-mode-Reach_D'>
                    Inter-State Bus Terminals
                  </div>    
              }

              
                <div className='container_p-Cards-Air-Reach_D_Mobile'>
                    {
                        busContent.map((data, index) => {
                            if(data.mode_type === "isbt" || data.mode_type === "ISBT"){
                            return(
                                <button
                                className='card-Air-Reach_D_Mobile'
                                onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name)}}
                                onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name)}}
                                onMouseOut={()=>{handleHoverOffLocation_Map()}}
                                >
                                    <div className='text-1-air-Reach_D'>
                                        {data.mode_name}
                                    </div>

                                    {
                                        destinationType === "state" ?
                                        <div className='text-3-1-air-Reach_D'>
                                            in {data.mode_state_name}.
                                        </div> 
                                        : null    
                                    }
                                   
                                   { data.distance &&
                                    destinationType !== "state" ?
                                    <div className='text-5-air-Reach_D'>
                                        <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                                    </div> :
                                    null
                                    }

                                </button>    
                            )
                            } 
                        })
                    }
                </div>      


                {
                  busContent.find((item=> (item.mode_type === "cbs" || item.mode_type === "CBS")))
                  &&
                  <div className='heading-1-mode-Reach_D'>
                        Central Bus Stand
                    </div>    
                }

                <div className='container_p-Cards-Air-Reach_D'>
                    {
                        busContent.map((data, index) => {
                            if(data.mode_type === "cbs" || data.mode_type === "CBS"){
                            return(
                                <button
                                className='card-Air-Reach_D'
                                onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name)}}
                                onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name)}}
                                onMouseOut={()=>{handleHoverOffLocation_Map()}}
                                >
                                    
                                    <div className='text-1-air-Reach_D'>
                                        {data.mode_name}
                                    </div>

                                    {
                                        destinationType === "state" ?
                                        <div className='text-3-1-air-Reach_D'>
                                            in {data.mode_state_name}.
                                        </div> 
                                        : null    
                                    }
                                   
                                   { data.distance &&
                                    destinationType !== "state" ?
                                    <div className='text-5-air-Reach_D'>
                                        <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                                    </div> :
                                    null
                                    }

                                </button>    
                            )
                            }
                        })
                    }
                </div>     

               
                {
                  busContent.find((item=> item.mode_type === "local"))
                  &&
                  <div className='heading-1-mode-Reach_D'>
                    Local Bus Stand
                  </div>    
                }

                <div className='container_p-Cards-Air-Reach_D'>
                    {
                        busContent.map((data, index) => {
                            if(data.mode_type === "local"){
                            return(
                                <button
                                className='card-Air-Reach_D'
                                onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name)}}
                                onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name)}}
                                onMouseOut={()=>{handleHoverOffLocation_Map()}}
                                >
                                   
                                    <div className='text-1-air-Reach_D'>
                                        {data.mode_name}
                                    </div>

                                    {
                                        destinationType === "state" ?
                                        <div className='text-3-1-air-Reach_D'>
                                            in {data.mode_state_name}.
                                        </div> 
                                        : null    
                                    }
                                   
                                   { data.distance &&
                                    destinationType !== "state" ?
                                    <div className='text-5-air-Reach_D'>
                                        <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                                    </div> :
                                    null
                                    }

                                </button>    
                            )
                            } 
                        })
                    }
                </div> 




      {/* <div>
        { busContent &&
          busContent.map((data, index) => {
            return(
            <div key={index}
              onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name)}}
              onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name)}}
              onMouseOut={()=>{handleHoverOffLocation_Map()}}
            >


              mode_name: {data.mode_name} <br></br>
              mode_code: {data.mode_code} <br></br>
              mode_type: {data.mode_type} <br></br>
              mode_district: {data.mode_district} <br></br>
              mode_state_name: {data.mode_state_name}

              <br></br> <br></br> <br></br> &nbsp;
            </div> 
            ) 
          })
        }
      </div> */}

    </div>
  )
}

export default Bus_Mobile