// MyContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'

import axios from 'axios';
import Moment from 'moment';


//importing react context
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip';
import { server_baseUrl } from '../../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';


// Step 1: Create a new context
const Context = createContext();


// Step 2: Create a context provider
export const Context_tripOverview_2_PlanTrip = ({ children }) => {

  const navigate = useNavigate();

  // using react context
  const { userEmail, tripId, tripName, 
    //trip details
    tripDestinationName, tripDestinationType, stateName, districtName, cityName, destinationLat, destinationLng
  } = useValidatePlanTrip();



  //!states
  //*manage 
  const[useEffectDataLoaded2, setUseEffectDataLoaded2] = useState(false)
  const[trackStatus_Itinerary, setTrackStatus_Itinerary] = useState(false)
  const[trackStatus_SavedPlaces, setTrackStatus_SavedPlaces] = useState(true)

  //*Map
  const[lat_TO, setLat_TO] = useState(destinationLat)
  const[lng_TO, setLng_TO] = useState(destinationLng)
  const[zoom_TO, setZoom_TO] = useState(7)
  const[activePlace_MapData, setActivePlace_MapData] = useState([])

  const[hoverItineraryData, setHoverItineraryData] = useState([])
  const[placeClicked_map, setPlaceClicked_map] = useState(false)
  const[fitAllLocations, setFitAllLocations] = useState()

  useEffect(() => {
    setLat_TO(destinationLat)
    setLng_TO(destinationLng)
  }, [destinationLat, destinationLng])

  //*navigation
  //-main
  const[activeViewSavedType, setActiveViewSavedType] = useState("itinerary") 
  //-saved places
  const[savedPlacesNavigation, setSavedPlacesNavigation] = useState("location")

  //*Itinerary
  //--store data
  const[savedItineraryData, setSavedItineraryData] = useState([])

  //*Saved Places
  //--store data
  const[savedPlacesData, setSavedPlacesData] = useState([])
  const[placesInfo, setPlacesInfo] = useState([])


  //share trip
  const[share_trip_id, setShare_trip_id] = useState()
  const[shareTrip_loading_status, setShareTrip_loading_status] = useState()

    

  //!Initial load
  useEffect(() => {
    if(!useEffectDataLoaded2 && userEmail && tripId){
      // alert("useEffectDataLoaded2")
        loadSavedTripItinerary() //it would try to load the itinerary, if the data is not found, it woudl head to load the saved places data, and in there too it woudl start with the places, not found, then head to hotels.... and more or there could be a way to find whether there are any saved places or not...and then display accordingly
        
        setUseEffectDataLoaded2(true)
    }
  }, [])

  
  //!Navigate 
  //saved place type
  const handleSavedType = (passedType) => {

    setActiveViewSavedType(passedType)
    // console.log("passedType: ", passedType, "trackStatus_SavedPlaces: ", trackStatus_SavedPlaces)
    //data for the itinerary has already been loaded. 
    if(passedType === "savedPlaces" && trackStatus_SavedPlaces){
      loadSavedPlaces()
      // alert("handleSavedType was called")
    }

  }  


  //!Saved Itinerary

  //*load data
  const loadSavedTripItinerary = async () => {
    
    const response = await axios.post(`${server_baseUrl}/csc/handle_itinerary/1/load/saved/itinerary`, {userEmail, tripId})
    
    // console.log("loadSavedTripItinerary", response.data)

    if(response.data && response.data !== "0" && response.data.length > 0){

      const rawItineraryData = response.data[0].itinerary_data;
      const temp_itineraryData = JSON.parse(rawItineraryData);

      // console.log("temp_itineraryData", temp_itineraryData)

      setSavedItineraryData(temp_itineraryData)
      //
      setTrackStatus_Itinerary(true)
    }
    else{
      // console.log("itinerary is empty for the trip")
      loadSavedPlaces()
    }
  }

  const test_Context = () => {
    // loadSavedTripItinerary();
  }

  
  //!Saved Places

  //*load data
  //::API Location - Trip Planning > LoadUserSavedActivity  
  const loadSavedPlaces = async () => {
    // alert(" loadSavedPlaces was called  ")
    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/load_user_saved_activity/saved_tourist_locations`, {userEmail, tripId, stateName})
    
    // console.log("loadSavedPlaces", response.data)

    if(response.data !== "0" && response.data.saved_places.length > 0){
      setSavedPlacesData(response.data.saved_places)
      setPlacesInfo(response.data.places_data)
      //
      setTrackStatus_SavedPlaces(false)
      setActiveViewSavedType("savedPlaces")
    }
  }
  

  //!Map
  const handleMap_HoverClick = (topicIdPassed, latPassed, lngPassed, displayNamePassed, indexPassed, namePassed) => {

    if(fitAllLocations){
      setFitAllLocations(false)
    }
    else{
      setFitAllLocations(true)
    }

    if(latPassed && lngPassed && namePassed){
      setHoverItineraryData([{
        id: topicIdPassed, lat:latPassed, lng:lngPassed, displayName:displayNamePassed, index:indexPassed, name:namePassed
      }])
    }

    setPlaceClicked_map(false)
  }


  //!Share Trip
  const handle_ShareTrip = async () => {
    if(!share_trip_id){
      setShareTrip_loading_status(true)

      const response = await axios.post(`${server_baseUrl}/csc/api/plan_trip/share_trip/manage_link`, {userEmail, tripId, stateName, tripDestinationName, tripDestinationType, destinationLat, destinationLng, tripName})
    
      // console.log("handle_ShareTrip", response.data)
  
      if(response.data !== "0"){

        setShare_trip_id(response.data)

        setShareTrip_loading_status(false)
      }
      else{
        alert("error in loading the share id. You might try again...")
        setShareTrip_loading_status(false)
      }

    }
  }

  

  // Step 5: Return a provider with value containing state and functions
  return(
    <Context.Provider value={{ 
      //!States
      //navigate
      activeViewSavedType, savedPlacesNavigation,
      //status
      trackStatus_Itinerary, trackStatus_SavedPlaces,

      //Map
      lat_TO, lng_TO, zoom_TO,
      hoverItineraryData, setHoverItineraryData,
      placeClicked_map, setPlaceClicked_map,
      fitAllLocations, setFitAllLocations,
      activePlace_MapData, setActivePlace_MapData,

      //Itinerary
      savedItineraryData,

      //Saved Places
      savedPlacesData, placesInfo, 

      //share trip
      share_trip_id, setShare_trip_id, shareTrip_loading_status, setShareTrip_loading_status,
        
      //!Functions
      handleSavedType,   
      handleMap_HoverClick,
      //share trip
      handle_ShareTrip,

      //map
      handleMap_HoverClick,

      //test
      test_Context
      
    }}>
      {children}
    </Context.Provider>
  );

};

// Step 6: Create a custom hook to consume the context
export const useTripOverview_2_PlanTrip = () => {
  return useContext(Context);
};



