import React, {useState, useRef} from 'react'

//import css
import './AddTravellers_TO.scss'

//importing icons
import { RiDeleteBin5Line } from "react-icons/ri";
import { IoMdCloseCircle } from "react-icons/io";

//importing context form Internal Trip Overview
import {useTripOverviewPlanTrip} from '../../Manage/Context/TripOverview_Context'

function AddTravellers_TO(props) {

  const{shareTripId} = props;

   // use react context
  const {
    test,
    //!States
    //*trip details
    //--data
    travellersData,
    //--other
    travellersDataWindowStatus, setTravellersDataWindowStatus, 
    travellersId, setTravellersId, 
    addTravelInputStatus, setAddTravelInputStatus,
    tempTravellerName, setTempTravellerName, 
    tempTravellerStatus, setTempTravellerStatus,

    //!Functions 
    //
    handleTempTravellerName, saveTraveller, deleteTraveller
  } = useTripOverviewPlanTrip()


  const[activePopup, setActivePopup] = useState();

  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
      setActivePopup(null);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  return (
    <div>

      <div >

      {
        travellersDataWindowStatus &&
        (
          <div className='conatiner_p-AddTravellers_TO'>

            <div 
            className='container_p-heading-tripDetails-AddTravellers_TO'
            >  

              <div className='heading-TripDetails-AddTravellers_TO'>
                Traveller  
                ({
                  travellersData &&
                  travellersData.length
                })
              </div>

              {
                !shareTripId &&
                <button 
                  onClick={()=>{
                  setAddTravelInputStatus(true)
                  setTravellersId(travellersId+1)
                  openPopup()
                  setActivePopup("add_travellers")
                  }}
                  className='button-edit-tripDetails-AddTravellers_TO'
                > 
                  <img 
                    className='image-TripDetails-AddTravellers_TO'
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/edit_icon_1.png'
                  />
                </button>
              }

            </div>


            {/*//! Travellers Data  */}
            <div 
            className='container_p-data-travellers-AddTravellers_TO'
            >


              { travellersData && travellersData.length > 0?
                travellersData.map(data => {
                  return(
                    <div 
                    className='container_p-travellers-AddTravellers_TO'
                    >
                      <div
                      className='heading-1-travellers-AddTravellers_TO'
                      >
                        {data.name} 
                      </div> 

                      <div
                      className={
                        data.status === "may_be" ? 'heading-maybe-travellersStatus-AddTravellers_TO' : data.status === "not_sure" ? 'heading-notSure-travellersStatus-AddTravellers_TO' :  'heading-sure-travellersStatus-AddTravellers_TO'
                      }
                      >
                        [{data.status.replace(/_/g, ' ')}]
                      </div> 

                      {
                        !shareTripId &&

                        <div 
                        className='heading-3-travellers-AddTravellers_TO'
                        >
                          <button onClick={()=>deleteTraveller(data.id)}
                          className='heading-3-travellers-AddTravellers_TO'
                          >
                            <RiDeleteBin5Line />
                          </button>
                        </div>  
                      }
      
                    </div>  
                  )
                }) 
                :
                <div>
                  <div
                  className='heading-1-noTravellers-AddTravellers_TO'
                  >
                    No travellers added.
                  </div>
                </div>  
              }
            </div>  


            {/*//! Popup  */}
            {
              activePopup === "add_travellers" && 

              <div>

                <div className='popup-background-1-Entities_TD'
                  onClick={()=>{
                  closePopup()
                  }}> 
                </div>

                <div className='popup_p-addNote-AddTravellers_TO'>

                  <button
                    onClick={()=>{
                      closePopup()
                    }}
                    className='button-close-popup-AddTravellers_TO'
                  >
                     <IoMdCloseCircle />
                  </button>

                  {/* <div
                  className='conatiner-addNote-AddTravellers_TO'
                  >
                    
                    <div>
                      <input 
                      value={tempTravellerName} 
                      onChange={handleTempTravellerName} 
                      maxLength={60} 
                      />
                    </div>  

                    {
                      tempTravellerName &&
                      <div>
                        <p>{tempTravellerName.length}/60 &nbsp;</p> 
                      </div>  
                    }
                  </div> */}

                  <div
                  className='conatiner_p-heading-AddNotes_TO_Popup'
                  >
                    <div
                    className='conatiner_t-2-heading-AddNotes_TO_Popup'
                    >
                      <input 
                        type='text' 
                        value={tempTravellerName} 
                        onChange={handleTempTravellerName}
                        placeholder='add name...'
                        maxLength={40}
                        className='input-heading-AddNotes_TO_Popup'
                      />
                    </div>

                    {
                      tempTravellerName && 
                      <div
                      className='conatiner_t-3-heading-AddNotes_TO_Popup'
                      >
                      {tempTravellerName.length}/40
                      </div>
                    }

                  </div>

                  { tempTravellerName && tempTravellerName.length > 0 &&
                  <div>

                    <div
                    className='text-addStaus-popup-AddTravellers_TO'
                    >
                      Add status
                    </div>     

                    <div>

                      <button 
                      onClick={()=>{
                        setTempTravellerStatus("sure")
                      }}
                      className={tempTravellerStatus === "sure" ? 'button-active-popup-AddTravellers_TO' : 'button-unActive-popup-AddTravellers_TO'}
                      >
                        Sure
                      </button>

                      <button 
                      onClick={()=>{
                        setTempTravellerStatus("may_be")
                      }}
                      className={tempTravellerStatus === "may_be" ? 'button-active-popup-AddTravellers_TO' : 'button-unActive-popup-AddTravellers_TO'}
                      >
                        may be
                      </button>

                      <button 
                      onClick={()=>{
                        setTempTravellerStatus("not_sure")
                      }}
                      className={tempTravellerStatus === "not_sure" ? 'button-active-popup-AddTravellers_TO' : 'button-unActive-popup-AddTravellers_TO'}
                      >
                        not sure
                      </button>

                    </div>  

                    <div>

                      <button 
                        onClick={()=>{
                          saveTraveller()
                          if(tempTravellerStatus && tempTravellerName){
                            closePopup()
                          }
                        }}
                        className='button-save-popup-AddTravellers_TO'
                      >
                        Save
                      </button>

                    </div>  
                  </div> 
                  }

                  <div className='text-note-popup-AddTravellers_TO'>
                    <i>note: you can't edit the name once saved. </i>
                  </div>   

                </div>
 
              </div>  
            }


          </div>  
        )
      }

      </div>

    </div>
  )
}

export default AddTravellers_TO