// MyContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'

import axios from 'axios';
import Moment from 'moment';


//importing react context
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip';
import { useGlobalNavigationPlanTrip } from '../../../../Manage - Plan Trip/Context/GlobalNavigationPlanTrip';
import { server_baseUrl } from '../../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';


// Step 1: Create a new context
const Context = createContext();


// Step 2: Create a context provider
export const Context_tripOverviewPlanTrip = ({ children }) => {

  const navigate = useNavigate();

  // using react context
  const { userEmail, tripId, tripName, 
    //trip details
    tripDestinationName, tripDestinationType, stateName, districtName, cityName, destinationLat, destinationLng
  } = useValidatePlanTrip();

  const {global_SavedDestinations, setGlobal_SavedDestinations} = useGlobalNavigationPlanTrip()

  //!if the data in global_SavedDestinations is empty, that means the user has either directly clicked on the Trip Overview feature, or has no saved plans. either way we'll load the data.
  //!and the state "global_SavedDestinations" gets update when the saved destimations are loaded, add, and deleted

  useEffect(() => {
    if(!global_SavedDestinations){
       loadSavedDestinations() 
    }
  }, [])

  const loadSavedDestinations = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/load_user_saved_activity/saved_destinatiions`, {userEmail, tripId}) 

    // console.log("loadSavedDestinations", response.data)

    if(response.data !== "0"){
        const tempSavedDestinationsName = response.data.map(item => item.district_name);
        setGlobal_SavedDestinations([...tempSavedDestinationsName])
    }else{
        // console.log("error loading the User S. Destinations. You can try again")
    }
    
  }


  //!states
  //*manage 
  const[useEffectDataLoaded, setUseEffectDataLoaded] = useState(false)
  
//   //*itinerary, savedPlaces
//   const[activeViewSavedType, setActiveViewSavedType] = useState("itinerary") 
    
  //*trip details
  //--data
  const[tripDetailsData, setTripDetailsData] = useState([])
  //
  const[tripDetailsWindowStatus, setTripDetailsWindowStatus] = useState(false)
  const[datesInputType, setDatesInputType] = useState("date")
  const[selectedMonth_tripDetails, setSelectedMonth_tripDetails] = useState()
  const[selectedWeek_tripDetails, setSelectedWeek_tripDetails] = useState()
  //track changes
  const[tempTripDetailsData, setTempTripDetailsData] = useState([]) //data is inserted on initial load and when the travellers data is saved

  //*user saved itinerary data
  const[dayData, setDayData] = useState([])
  const[itineraryData, setItineraryData] = useState([])
  const[checkListData, setCheckListData] = useState([])
  const[tripDocumentsData, setTripDocumentsData] = useState([])
  const[topicIdCount, setTopicIdCount] = useState([])

  //*travellers data
  //--data
  const[travellersData, setTravellersData] = useState([])
  //
  const[travellersDataWindowStatus, setTravellersDataWindowStatus] = useState(true)
  const[tempTravellerName, setTempTravellerName] = useState()
  const[tempTravellerStatus, setTempTravellerStatus] = useState()
  const[travellersId, setTravellersId] = useState(0)
  const[addTravelInputStatus, setAddTravelInputStatus] = useState(false)

  //*notes data
  //--data
  const[notesData, setNotesData] = useState([])
  //
  const[notesWindowStatus, setNotesWindowStatus] = useState(false)
  const[editNotesStatus, setEditNotesStatus] = useState(false)
  //keep track of the total count of the notes
  const[trackNotesId, setTrackNotesId] = useState(0)
  //temp states to store the active notes
  const[activeNotesId, setActiveNotesId] = useState(0)
  const[activeNotesHeading, setActiveNotesHeading] = useState()
  const[activeNotesEmoji, setActiveNotesEmoji] = useState()
  const[activeNotesText, setActiveNotesText] = useState()
  
  //!Trip Notes Data
  
  //Load saved notes data
  const loadSavedNotesData = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/trip_overview/load/saved/trip/notes/data`, {userEmail, tripId})

    // console.log("loadSavedNotes", response.data)
    if(response.data !== "0" && response.data.length > 0){
        setNotesData(response.data)
        setTrackNotesId(response.data[response.data.length-1].id + 1)
        setActiveNotesId(response.data[0].id)
    }
    else{
        // console.log("error in loading the saved notes. You might try again.")
    }
  }

  //Add new note
  const handleAddNewNote = async () => {

    setNotesWindowStatus(true)
    setEditNotesStatus(false)
    setActiveNotesEmoji()
    setActiveNotesHeading()
    setActiveNotesText()
  }

  //Load saved notes data
  const handleEditNotes = async (passedId, passedEmoji, passedHeading, passedContent) => {

    // console.log("passedId, passedEmoji, passedHeading, passedContent", passedId, passedEmoji, passedHeading, passedContent)
    setNotesWindowStatus(true)
    setEditNotesStatus(true)
    setActiveNotesId(passedId)
    setActiveNotesEmoji(passedEmoji)
    setActiveNotesHeading(passedHeading)
    setActiveNotesText(passedContent)

    //to track if changes are made, and accordingly show the update button
    // setTemp_ActiveNotesEmoji_forEdit(activeNotesEmoji)
    // setTemp_NotesHeading_forEdit(activeNotesHeading)
    // setTemp_ActiveNotesText_forEdit(activeNotesText)
  }
  const[temp_ActiveNotesText_forEdit, setTemp_ActiveNotesEmoji_forEdit] = useState()
  const[temp_NotesHeading_forEdit, setTemp_NotesHeading_forEdit] = useState()
  const[temp_ActiveNotesEmoji_forEdit, setTemp_ActiveNotesText_forEdit] = useState()

  //Save New Note
  const handleSaveNotes = async () => {
    // const passedActiveNotesId = activeNotesId+1;
    
    let passedActiveNotesId;

    if(notesData && notesData.length > 0){
      passedActiveNotesId = Math.max(...notesData.map(note => note.id)) + 1;
    }
    else{
      passedActiveNotesId = 1;
    }

    // console.log("handleSaveNotes", notesData, passedActiveNotesId)

    const response = await axios.post(`${server_baseUrl}/csc/trip_overview/insert/new/notes/data`, {userEmail, tripId, passedActiveNotesId, activeNotesHeading, activeNotesText, activeNotesEmoji})

    // console.log("handleSaveNotes", response.data)

    if(response.data == "1"){

        setNotesData([...notesData, {
            "id":passedActiveNotesId, 
            "heading":activeNotesHeading, 
            "note":activeNotesText, 
            "emoji":activeNotesEmoji
        }])

        setNotesWindowStatus(false) //to edit or update the note, the function has to go through either handleAddNewNote, or handleEditNotes. There they'll handle setting up all the necessary state accordingly. 
        setActiveNotesId(passedActiveNotesId)
    }
  }

  //Update Notes - edited note
  const handleUpdateNotes = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/trip_overview/update/trip/notes/data`, {userEmail, tripId, activeNotesId, activeNotesHeading, activeNotesText, activeNotesEmoji})

    // console.log("handleUpdateNotes", response.data)

    if(response.data == "1"){

      // console.log('Before update:', notesData);

        setNotesData(notesData.map(note => 
            note.id === activeNotesId 
              ? {...note, 
                    // "id":activeNotesId, 
                    "heading":activeNotesHeading, 
                    "note":activeNotesText, 
                    "emoji":activeNotesEmoji
                } 
              : note
        ));

        // console.log('After update:', notesData);

        setNotesWindowStatus(false) //explained in handleSaveNotes
        setActiveNotesId(activeNotesId)
    }
  }

  //Delete Note
  const handleDeleteNote = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/trip_overview/delete/trip/note/data`, {userEmail, tripId, activeNotesId})

    // console.log("handleDeleteNote", response.data)

    if(response.data == "1"){

        setNotesData(notesData.filter(note => note.id !== activeNotesId));

        setNotesWindowStatus(false) //explained in handleSaveNotes
    }
    else{
        alert("error in deleting the notes. You can try again.")
    }
  }




  //!Initial Load
  useEffect(() => {
    if(!useEffectDataLoaded && userEmail && tripId){

        loadSavedTripItinerary() //load user saved itinerary
        loadTripDetailsData() 
        loadTravellersData() //loadTravellers 
        //you need to set: tripDetailsDataType, datesInputType, selectedMonth_tripDetails, selectedWeek_tripDetails
        // loadSavedNotesData()
        loadSavedNotesData()
        setUseEffectDataLoaded(true)
    }
  }, [])


  const test = () => {
    // loadTripDetailsData()
    // console.log("tripDetailsData", tripDetailsData)
    // loadTripDetailsData()
    // console.log("tempTripDetailsData", tempTripDetailsData)
  }

//! Load User Saved Itineray  

//*load data
const loadSavedTripItinerary = async () => {
    
  const response = await axios.post(`${server_baseUrl}/csc/handle_itinerary/1/load/saved/itinerary`, {userEmail, tripId})
  
  // console.log("loadSavedTripItinerary", response.data)

  if(response.data !== "0" && response.data.length > 0){
    // Parsing JSON strings back to JavaScript arrays or objects
    const dayData = JSON.parse(response.data[0].day_data);
    const itineraryData = JSON.parse(response.data[0].itinerary_data);
    const checkListData = JSON.parse(response.data[0].check_list_data);
    const tripDocumentsData = JSON.parse(response.data[0].documents_data);
    const topicIdCount = response.data[0].topic_id_count;

    // Setting the parsed data to the respective states
    setDayData(dayData);
    setItineraryData(itineraryData);
    setCheckListData(checkListData);
    setTripDocumentsData(tripDocumentsData);
    setTopicIdCount(topicIdCount);

  }
  else{
    // console.log("no saved itinerary found")
    // alert("no saved itinerary found")
  }
}

//   //!TripOverview 
//   //saved place type
//   const handleSavedType = (passedType) => {
//     setActiveViewSavedType(passedType)
//   }

  //!Travellers Data

  const handleTempTravellerName = (event) => {
    setTempTravellerName(event.target.value)  
  }

  //*load travellers data
  const loadTravellersData = async () => {
    const response = await axios.post(`${server_baseUrl}/csc/trip_overview/load/saved/travellers/data`, {userEmail, tripId})
    
    // console.log("loadTravellersData", response.data)

    if(response.data !== "0" && response.data.length > 0){
      setTravellersData(response.data)
      setTravellersId(response.data[response.data.length-1].id + 1)
    }
  }

  //*save/insert travellers data
  const saveTraveller = async () => {
    if(tempTravellerName && tempTravellerStatus){

        const response = await axios.post(`${server_baseUrl}/csc/trip_overview/insert/travellers/data`, {userEmail, tripId, travellersId, tempTravellerName, tempTravellerStatus})

        if(response.data !=="0"){
            setTravellersData([...travellersData, {"id":travellersId, "name":tempTravellerName, "status":tempTravellerStatus}])

            setTempTravellerName(null)
            setTempTravellerStatus(null)
            setAddTravelInputStatus(false)
        } 
        else{
            alert("try again. Error saving the data.")
        }
    }
    else{
      alert("Traveller Name, or status is missing.")
    }
  }

  //*delete travellers data
  const deleteTraveller = async (travellerIdPassed) => {

    const response = await axios.post(`${server_baseUrl}/csc/trip_overview/delete/traveller/data`, {travellerIdPassed, userEmail, tripId})
      
    if(response.data !=="0"){
        setTravellersData(travellersData.filter(data => data.id !== travellerIdPassed));
    } 
    else{
        alert("try again. Error removing the data.")
    }
  }

  //!Trip Details 

//   const handleTripDetailsChange_Month = (event) => {
//     setSelectedMonth_tripDetails(event.target.value)
//   }

//   const handleTripDetailsChange_Week = (event) => {
//     setSelectedWeek_tripDetails(event.target.value)
//   }

  //*Load Data
  const loadTripDetailsData = async () => {
    const response = await axios.post(`${server_baseUrl}/csc/trip_overview/load/trip/details/data`, {userEmail, tripId})

    // console.log("loadTripDetailsData", response.data)

    if(response.data !== "0"){
      setTripDetailsData(response.data)
      setDatesInputType(response.data[1].data_type_2)
      setSelectedMonth_tripDetails(response.data[1].data_1)
      setSelectedWeek_tripDetails(response.data[1].data_2)

      //track changes
      setTempTripDetailsData(response.data)
    }
  }

  //* Track changes and manage saving the new travellers data
  const handleTripDetails_CloseWindow_SaveChanges = async () => {

    //perform check to discover changes
    const changesResult = await checkTripDetailsChanges(tripDetailsData, tempTripDetailsData);

    if(!changesResult){
        // console.log("Changes Found! Save trip details data")
        handleSaveTripDetailsData()
    }
    else{
        setTripDetailsWindowStatus(false)
        // console.log("No Changes Found!")
    }
  }

  //*save trip details data
  const handleSaveTripDetailsData = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/trip_overview/insert/trip/details/data`, {userEmail, tripId, tripDetailsData})

      // console.log("handleSaveTripDetailsData", response.data)

    if(response.data == "1"){
        setTripDetailsWindowStatus(false)
        setTempTripDetailsData(tripDetailsData)
    } 
    else{
        alert("try again. Error saving the Trip Details data.")
    }
  }

  const checkTripDetailsChanges = (arr1, arr2) => {

    if (arr1.length !== arr2.length) {
      return false;
    }
  
    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2.find(item => item.id === obj1.id);
  
      if (!obj2) {
        return false;
      }
  
      for (let key in obj1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }
  
    return true;
  }

  //*
  const handleTripDetailsChange = async (event, dataType, dataType2) => {

    if(dataType === "duration"){
      setTripDetailsData(tripDetailsData.map(data => 
        data.data_type === dataType 
          ? {...data, data_1: event.target.value} 
          : data
      ));
    }
    else if(dataType === "budget"){
      const numberPass = event.target.value;
      const formattedValue = formatIndianStyle(numberPass);

      setTripDetailsData(tripDetailsData.map(data => 
        data.data_type === dataType 
          ? {...data, data_1: formattedValue} 
          : data
      ));
    }
    else if(dataType === "budget_currency"){

      setTripDetailsData(tripDetailsData.map(data => 
        data.data_type === "budget" 
          ? {...data, data_2: event.target.value} 
          : data
      ));

    }
    else{
      if(dataType === "date" ){
        setTripDetailsData(tripDetailsData.map(data => 
          data.data_type_2 === dataType //"decided" 
            ? {...data, data_1: event.target.value, data_2: null} 
            : data
        ));
      }
      else{
        if(!dataType2){ //for month - as dataType2 contains data for week

          setSelectedMonth_tripDetails(event.target.value)

          setTripDetailsData(tripDetailsData.map(data => 
            data.data_type_2 === dataType 
              ? {...data, data_1: event.target.value} 
              : data
          ));
        }
        else{ //for week

          setSelectedWeek_tripDetails(event.target.value)

          setTripDetailsData(tripDetailsData.map(data => 
            data.data_type_2 === dataType 
              ? {...data, data_2: event.target.value} 
              : data
          ));
        }
      }
    }
  }

  //*
  const handleMetaTripDetails = async (dataPass, dataType) => {
    // setTempDurationValue(dataPass)
    if(dataType !== "date"){

      if(dataPass !== "not_yet_decided"){
        setTripDetailsData(tripDetailsData.map(data => 
          data.data_type === dataType 
            ? {...data, data_meta_info_1: dataPass} 
            : data
        ));
      }
      else{
        setTripDetailsData(tripDetailsData.map(data => 
          data.data_type === dataType 
            ? {...data, data_1: '', data_2: null, data_meta_info_1: dataPass} 
            : data
        ));
     
      }
    }
    else{ //?when date is true. Here, the meta data is stored in data_type_2 column
      setDatesInputType(dataPass)

      if(dataPass !== "not_yet_decided"){
        setTripDetailsData(tripDetailsData.map(data => 
          data.data_type === dataType 
            ? {...data, data_type_2: dataPass} 
            : data
        ));
      }
      else{
        setTripDetailsData(tripDetailsData.map(data => 
          data.data_type === dataType 
            ? {...data, data_1: null, data_2: null, data_type_2: dataPass} 
            : data
        ));
      }
    }
  }

  //!Other
  // const formatIndianStyle = (value) => {
  //   if (!value) return ''; // Return empty string if value is not provided
  
  //   // Remove existing commas and non-numeric characters
  //   const numericValue = value.replace(/[^0-9]/g, '');
  
  //   // Determine the length of the value
  //   const length = numericValue.length;
  
  //   // Determine the number of commas needed
  //   const numCommas = Math.floor((length - 1) / 2);
  
  //   // Initialize the formatted value with the last three digits
  //   let formattedValue = numericValue.substr(-3);
  
  //   // Iterate over the remaining digits, adding commas after every two digits
  //   for (let i = length - 3, j = 0; i > 0; i--, j++) {
  //     if (j % 2 === 0) {
  //       formattedValue = numericValue.charAt(i) + ',' + formattedValue;
  //     } else {
  //       formattedValue = numericValue.charAt(i) + formattedValue;
  //     }
  //   }
  
  //   return formattedValue;
  // };
  const formatIndianStyle = (value) => {
    if (!value) return ''; // Return empty string if value is not provided

    // Remove existing commas and non-numeric characters
    const numericValue = value.toString().replace(/[^0-9]/g, '');

    // Initialize the formatted value with the last three digits
    let formattedValue = numericValue.slice(-3);

    // Iterate over the remaining digits, adding commas after every two digits
    for (let i = numericValue.length - 3; i > 0; i -= 2) {
        const start = Math.max(i - 2, 0);
        formattedValue = numericValue.slice(start, i) + (i !== 2 ? ',' : '') + formattedValue;
    }

    return formattedValue;  
  };


  

  // Step 5: Return a provider with value containing state and functions
  return (
    <Context.Provider value={{ 
        test,
        //!States
        //
        // activeViewSavedType, 

        //*userSaved Itinerary Data
        dayData,
        itineraryData,
        checkListData,
        tripDocumentsData,
        topicIdCount,

        //*trip details
        //--data
        tripDetailsData,
        //--window/popup
        tripDetailsWindowStatus, setTripDetailsWindowStatus, 
        //
        datesInputType, selectedMonth_tripDetails, selectedWeek_tripDetails, setSelectedWeek_tripDetails,

        //*travellers data
        //--data
        travellersData,
        //--window/popup
        travellersDataWindowStatus, setTravellersDataWindowStatus, 
        //
        travellersId, setTravellersId, 
        addTravelInputStatus, setAddTravelInputStatus,
        tempTravellerName, setTempTravellerName, 
        tempTravellerStatus, setTempTravellerStatus,
        //track changes
        tempTripDetailsData,

        //*notes data
        //--data
        notesData,
        //--window/popup
        notesWindowStatus, setNotesWindowStatus,
        editNotesStatus, setEditNotesStatus,  
        //
        trackNotesId, setTrackNotesId, 
        activeNotesId, setActiveNotesId,
        activeNotesHeading, setActiveNotesHeading,
        activeNotesEmoji, setActiveNotesEmoji,
        activeNotesText, setActiveNotesText,
        //for udpate notes - track if changes are made or not
        temp_ActiveNotesText_forEdit,
        temp_NotesHeading_forEdit,
        temp_ActiveNotesEmoji_forEdit,

        //!Functions
        // handleSavedType, 
        //
        loadSavedTripItinerary,
        //trip details
        handleMetaTripDetails, handleTripDetailsChange,
            //--track changes and save them - triggered when the trip details window is closed
            handleTripDetails_CloseWindow_SaveChanges,
        //travellers data 
        handleTempTravellerName, saveTraveller, deleteTraveller,
        //notes data
        loadSavedNotesData, handleAddNewNote, handleEditNotes, handleSaveNotes, handleUpdateNotes, handleDeleteNote,
        //other
        formatIndianStyle
      
    }}>
      {children}
    </Context.Provider>
  );

};

// Step 6: Create a custom hook to consume the context
export const useTripOverviewPlanTrip = () => {
  return useContext(Context);
};



