import React from 'react'

//
import Hotels from './Hotels'

//
// import Hotels from '../../../../../../Travel Categories/Hotels/Main/Hotels'

function PreHotel_Temp() {
  return (
    <div>
        <div>
            <Hotels/> 
        </div>
    </div>
  )
}

export default PreHotel_Temp