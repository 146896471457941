import React, {useEffect, useState} from 'react'

//import css
import './Search_Tool.scss'
import './Search_Tool_2.scss'

//import search tool
import {Search_Locations, Search_AllDestinations, Search_Hotels} from '../../../../Universal Components/Search/Manage Search/Export/Export_Search'

// import react context
import {useHotelsContext} from '../../Manage/Context/HotelsContext'
import { IoMdCloseCircle } from "react-icons/io";
import { MdOutlinePool } from "react-icons/md";
import { TbParking } from "react-icons/tb";
import { MdOutlineRoomService } from "react-icons/md";
import { IoRestaurantOutline } from "react-icons/io5";
import { IoOptionsSharp } from "react-icons/io5";
import { MdOutlineFreeCancellation } from "react-icons/md";
import { IoRibbonOutline } from "react-icons/io5";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaRegStarHalf } from "react-icons/fa";
import { GiStarFormation } from "react-icons/gi";
import { TbSortAscendingNumbers } from "react-icons/tb";
import { TbSortDescendingNumbers } from "react-icons/tb";
import { GrRevert } from "react-icons/gr";
import { MdManageSearch } from "react-icons/md";

//importing components 
//?importing home internal components
import {OtherAmenities} from '../../Manage/Export/Export_Hotels'


//import icons



function Search_Tool() {

  //use react context 
  const {
    // values
    searchType, hotelCategory, ptExclusiveData, selectedAmenitiesData, otherAmenitiesWindow, sortHotelData, hotelRatings, hotelsRatingsWindow, sortHotelPrice, underKm, keywordChangeDetected, placeStateName, dataLoadedInputType, placeName, destinationType, 
    //
    setViewMore_id, setViewMoreStatus, handleGetReviewdData,
    //map
    lat, lng, hotelLat, hotelLng,
    //track changes - search
    trackChange_SelectedAmenitiesData, trackChange_SortHotelData, trackChange_HotelRatings, trackChange_SortHotelPrice, noPlaceName_SearchTry,
    // set values
    setOtherAmenitiesWindow, setHotelsRatingsWindow,
    //hotel name and id
    searchedHotelName, searchedHotelId,
    // functions
    handleSearchType, handleDestinationInputClick, handleLocationInputClick, handleHotelNameInputClick,
    handleHotelCategoryClick, handlePTExclusiveClick, handleAmenitiesClick, handleSortHotelsClick, handleHotelsRatingsClick, handleSortHotelPriceClick, handleUnderKm, handleSearchHotels_Keywords, handleRevertKeywordsChange, 
    //filters
    handleClearAllFilters, handleFreshStart,

    testFunctionHotel
  } = useHotelsContext()

  const[sortDataWindow, setSortDataWindow] = useState(false)


  // !
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 799); // Initialize with the current width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 799); // Update state on window resize
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div>
      {/* <button onClick={testFunctionHotel}>Test - Hotel</button>
      <br></br> <br></br> */}

      {/* {noPlaceName_SearchTry && 
        <div>
          <b>
            Please Enter a location or a destination.
          </b>
        </div>   
      } */}


      {/*//!Dynamic - Web */}
      {
        !isMobile &&
      
        <div
        className='container_p-Search_Tool'
        >
          <div className='container_s-Search_Tool'>

            <div
            className='container_s-1-Search_Tool'
            >
              <img 
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/search_icon_1.png'
              className='img-searchHotel-Search_Tool'
              />

              <div
              className='heading-1-searchHotel-Search_Tool'
              >
                Search Hotel
              </div>
            </div>   

            <div
            className='container_s-2-Search_Tool'
            >

              <div
              className='container_t-2-Search_Tool'
              >
                <button
                  onClick={()=>{handleSearchType("destination")}}
                  className={searchType === "destination" ? 'button-active-SearchTool' : 'button-unactive-SearchTool'}
                >
                  Destination 
                </button>

                <button
                  onClick={()=>{handleSearchType("location")}}
                  className={searchType === "location" ? 'button-active-SearchTool' : 'button-unactive-SearchTool'}
                  >
                  Nearby Tourist Locations
                </button>
              </div>

              {
                searchType === "destination" &&
                (
                  <div
                  className='container_t-3-Search_Tool'
                  >
                    <Search_AllDestinations 
                      handleSearchResultClick={handleDestinationInputClick}
                    />
                  </div>   
                )
              }

              {
                searchType === "location" &&
                (
                  <div
                  className='container_t-3-Search_Tool'
                  >
                    <Search_Locations 
                      handleSearchResultClick={handleLocationInputClick}
                    />
                  </div>   
                )
              }

            </div>

          </div>
        </div>  
      }



      {/*//!Dynamic - App */}
      {
        isMobile &&
      
        <div
        className='container_p-Search_Tool_Mobile'
        >
          <div className='container_s-Search_Tool_Mobile'>

            <div
            className='container_s-1-Search_Tool_Mobile'
            >
              <img 
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/search_icon_1.png'
              className='img-searchHotel-Search_Tool'
              />

              <div
              className='heading-1-searchHotel-Search_Tool_Mobile'
              >
                Search Hotels
              </div>
            </div>   

            <div
            className='container_s-2-Search_Tool_Mobile'
            >

              <div
              className='container_t-2-Search_Tool'
              >
                <button
                  onClick={()=>{handleSearchType("destination")}}
                  className={searchType === "destination" ? 'button-active-SearchTool' : 'button-unactive-SearchTool'}
                >
                  Destination 
                </button>

                <button
                  onClick={()=>{handleSearchType("location")}}
                  className={searchType === "location" ? 'button-active-SearchTool' : 'button-unactive-SearchTool'}
                  >
                  Nearby Tourist Locations
                </button>
              </div>

              {
                searchType === "destination" &&
                (
                  <div
                  className='container_t-3-Search_Tool'
                  >
                    <Search_AllDestinations 
                      handleSearchResultClick={handleDestinationInputClick}
                    />
                  </div>   
                )
              }

              {
                searchType === "location" &&
                (
                  <div
                  className='container_t-3-Search_Tool'
                  >
                    <Search_Locations 
                      handleSearchResultClick={handleLocationInputClick}
                    />
                  </div>   
                )
              }

            </div>

          </div>
        </div>  
      }




      {
        placeName &&
        <div
        className='container_p-heading-Search_Tool'
        >
          { 
            destinationType !== "location" ?
            <div
            className='text-1-placeName-Search_Tool'
            >
              Hotels in {placeName}
            </div>
            :
            <div
            className='text-1-placeName-Search_Tool'
            >
              Hotels near {placeName}
            </div>
          }
        </div>
      }      

      {/* <div>
        <button onClick={()=>{handleFreshStart()}}
        className='buttton-clearAll-Search_Tool'
        >
          Fresh Start
        </button>
      </div> */}

      {/*//*Navigation  */}
      {/*//!Navigation 1 */}
      <div>
          <div
          className='container_p-navigation_1-Search_Tool'
          >

            <button
              onClick={()=>{handleHotelCategoryClick("all")}}
              className={hotelCategory === "all" ? 'conatiner_t-active-navigation_1-Search_Tool' : 'conatiner_t-unActive-navigation_1-Search_Tool'}
            >
              <div
              className={hotelCategory === "all" ? 'heading-1-active-navigation_1-Search_Tool' : 'heading-1-unActive-navigation_1-Search_Tool'}
              >
                All <i></i>
              </div>

              <img 
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/type_a_hotel_icon_1.png'
              className='img-1-navigation_1-Search_Tool'
              />
            </button>

            <button
              onClick={()=>{handleHotelCategoryClick("a")}}
              className={hotelCategory === "a" ? 'conatiner_t-active-navigation_1-Search_Tool' : 'conatiner_t-unActive-navigation_1-Search_Tool'}
            >
              <div
               className={hotelCategory === "a" ? 'heading-1-active-navigation_1-Search_Tool' : 'heading-1-unActive-navigation_1-Search_Tool'}
              >
                Travel Focused <i></i>
                {/* Travel Focused <i>(less than 3000)</i> */}
              </div>
             
              <img 
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/type_a_hotel_icon_2.png'
              className='img-1-navigation_1-Search_Tool'
              />
            </button>

            <button
              onClick={() => {handleHotelCategoryClick("b") }}
              className={hotelCategory === "b" ? 'conatiner_t-active-navigation_1-Search_Tool' : 'conatiner_t-unActive-navigation_1-Search_Tool'}
            >
              <div
                className={hotelCategory === "b" ? 'heading-1-active-navigation_1-Search_Tool' : 'heading-1-unActive-navigation_1-Search_Tool'}
              >
                {/* Budget and Comfort <i>(between 3000 - 7000)</i> */}
                Budget and Comfort <i></i>
              </div>
            
              <img 
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/type_b_hotel_icon_1.png'
              className='img-1-navigation_1-Search_Tool'
              />

              {/* {hotelCategory === "b" &&
                <div>
                  X
                </div>
              } */}
            </button>

            <button
              onClick={() => {handleHotelCategoryClick("c") }}
              className={hotelCategory === "c" ? 'conatiner_t-active-navigation_1-Search_Tool' : 'conatiner_t-unActive-navigation_1-Search_Tool'}
            >
              <div
                className={hotelCategory === "c" ? 'heading-1-active-navigation_1-Search_Tool' : 'heading-1-unActive-navigation_1-Search_Tool'}
              >
                {/* Premium Stay <i>(7000+)</i> */}
                Premium Stay <i></i>
              </div>
              
              <img 
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/premeium_hotel_icon_2.png'
              className='img-1-navigation_1-Search_Tool'
              />

              {/* {hotelCategory === "c" &&
                <div>
                  X
                </div>
              } */}
            </button>

          </div>
      </div>

      <div>
        <button onClick={()=>{handleFreshStart()}}
        className='buttton-clearAll-Search_Tool'
        >
          Fresh Start
        </button>
      </div>

      {/*//!Navigation 2 */}
      <div
      className='container_p-BookDirect-Search_Tool_2'
      >

        <div
        className='heading-1-BookDirect-Search_Tool_2'
        >
          #PrepareTrip Exclusive:
        </div>

        <div
        className='conatiner_s-2-BookDirect-Search_Tool_2'
        >

          <button
              onClick={() => {handlePTExclusiveClick("a_class") }} 
              className={ptExclusiveData === "a_class" ? 'conatiner_s-active-navigation_2-Search_Tool_2' : 'conatiner_s-unActive-navigation_2-Search_Tool_2'}
          >

            <img 
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/book_direct_icon_1.png'
              className='img-1-navigation_1-Search_Tool_2'
            />

            <div
            className='heading-2-BookDirect-Search_Tool_2'
            >
              {/* Book Direct (hotels with offcial site) <i></i> */}
              Book Direct
            </div>
            

            {ptExclusiveData === "a_class" &&
              <div
              className='icon-1-BookDirect-Search_Tool_2'
              >
                <IoMdCloseCircle />
              </div>
            }

          </button>

        </div>

      </div> 

    

      {/*//!Navigation 3 */}
      <div
      className='container_p-navigation_3-Search_Tool_2'
      >

        {/*//! Container #1 */}
        <div
        className='conatiner_p-SortData-navigation_3-Search_Tool_2'
        >

          {/*//* #1  */}
          <div
          className='conatiner_t-1-navigation_3-Search_Tool_2'
            onMouseOver={()=>{setSortDataWindow(true)}}
            onMouseOut={()=>{setSortDataWindow(false)}}
            style={{cursor:'pointer'}}
          >
            <button
            className='heading-1-navigation_3-Search_Tool_2'
            >
              Sort Data
            </button>

            <div
            className='icon-1-sortData-navigation_3-Search_Tool_2'
            >
            <IoOptionsSharp/>
            </div>

          </div>

          {/* <div>
            <button onClick={()=>{handleFreshStart()}}
            className=' buttton-clearAll-Search_Tool'
            >
              Fresh Start
            </button>
          </div> */}

          {/*//* #2  */}
          <div
          className='conatiner_t-2-navigation_3-Search_Tool_2'
          >
            {
              JSON.stringify(trackChange_SelectedAmenitiesData) !== JSON.stringify(selectedAmenitiesData) ?
              <div
              className='conatiner_t-save_revert-navigation_3-Search_Tool_2'
              >
                <button
                  onClick={()=>{handleSearchHotels_Keywords()}}
                  className='button-save-navigation_3-Search_Tool_2'
                >
                  <div
                  className='heading-1-save-navigation_3-Search_Tool_2'
                  >
                    Search 
                  </div>
                  <div
                  className='icon-1-save-navigation_3-Search_Tool_2'
                  >
                    <MdManageSearch />
                  </div>
                </button>

                <button
                  onClick={()=>{handleRevertKeywordsChange()}}
                  className='button-revertChanges-navigation_3-Search_Tool_2'
                >
                  <div
                  className='heading-1-revertChanges-navigation_3-Search_Tool_2'
                  >
                    Revert Changes
                  </div>
                  <div
                  className='icon-1-revertChanges-navigation_3-Search_Tool_2'
                  >
                    <GrRevert />
                  </div>
                </button>
              </div>  

              :

              JSON.stringify(trackChange_SortHotelData) !== JSON.stringify(sortHotelData) ?
            
              <div
              className='conatiner_t-save_revert-navigation_3-Search_Tool_2'
              >
                <button
                  onClick={()=>{handleSearchHotels_Keywords()}}
                  className='button-save-navigation_3-Search_Tool_2'
                >
                  <div
                  className='heading-1-save-navigation_3-Search_Tool_2'
                  >
                    Search 
                  </div>
                  <div
                  className='icon-1-save-navigation_3-Search_Tool_2'
                  >
                    <MdManageSearch />
                  </div>
                </button>

                <button
                  onClick={()=>{handleRevertKeywordsChange()}}
                  className='button-revertChanges-navigation_3-Search_Tool_2'
                >
                  <div
                  className='heading-1-revertChanges-navigation_3-Search_Tool_2'
                  >
                    Revert Changes
                  </div>
                  <div
                  className='icon-1-revertChanges-navigation_3-Search_Tool_2'
                  >
                    <GrRevert />
                  </div>
                </button>
              </div>  

              : 

              trackChange_SortHotelPrice !== sortHotelPrice ?
            
              <div
              className='conatiner_t-save_revert-navigation_3-Search_Tool_2'
              >
                <button
                  onClick={()=>{handleSearchHotels_Keywords()}}
                  className='button-save-navigation_3-Search_Tool_2'
                >
                  <div
                  className='heading-1-save-navigation_3-Search_Tool_2'
                  >
                    Search 
                  </div>
                  <div
                  className='icon-1-save-navigation_3-Search_Tool_2'
                  >
                    <MdManageSearch />
                  </div>
                </button>

                <button
                  onClick={()=>{handleRevertKeywordsChange()}}
                  className='button-revertChanges-navigation_3-Search_Tool_2'
                >
                  <div
                  className='heading-1-revertChanges-navigation_3-Search_Tool_2'
                  >
                    Revert Changes
                  </div>
                  <div
                  className='icon-1-revertChanges-navigation_3-Search_Tool_2'
                  >
                    <GrRevert />
                  </div>
                </button>
              </div>  

              :

              trackChange_HotelRatings !== hotelRatings ?
              
              <div
              className='conatiner_t-save_revert-navigation_3-Search_Tool_2'
              >
                <button
                  onClick={()=>{handleSearchHotels_Keywords()}}
                  className='button-save-navigation_3-Search_Tool_2'
                >
                  <div
                  className='heading-1-save-navigation_3-Search_Tool_2'
                  >
                    Search 
                  </div>
                  <div
                  className='icon-1-save-navigation_3-Search_Tool_2'
                  >
                    <MdManageSearch />
                  </div>
                </button>

                <button
                  onClick={()=>{handleRevertKeywordsChange()}}
                  className='button-revertChanges-navigation_3-Search_Tool_2'
                >
                  <div
                  className='heading-1-revertChanges-navigation_3-Search_Tool_2'
                  >
                    Revert Changes
                  </div>
                  <div
                  className='icon-1-revertChanges-navigation_3-Search_Tool_2'
                  >
                    <GrRevert />
                  </div>
                </button>
                </div>  

              : 
              
              null
            }                    

          </div>

          {/*//* #3  */}
          <div
          className='conatiner_t-3-navigation_3-Search_Tool_2'
          >
            {
              (selectedAmenitiesData.length > 0 ||
              sortHotelData > 0 || sortHotelPrice ||
              hotelRatings)
              &&
              (
                <div>
                  <button onClick={()=>{handleClearAllFilters()}}
                  className='buttton-clearAll-Navigation_3-Search_Tool_2'
                  >
                    clear filter(s)
                  </button>
                </div>        
              )

            }    
          </div>

        </div>

        {
        sortDataWindow && 
     
        // {/*//! Container #2 */}
      <div
      className='conatiner_p-2-0-navigation_3-Search_Tool_2'
      onMouseOver={()=>{setSortDataWindow(true)}}
      onMouseOut={()=>{setSortDataWindow(false)}}
      >

        <div 
          className='conatiner_p-2-navigation_3-Search_Tool_2'
          onMouseOver={()=>{setSortDataWindow(true)}}
          onMouseOut={()=>{setSortDataWindow(false)}}
        > 
          {/*//?the whole meta search container */}
          {/* Quick Amenities - start */}

          {/*//!1  */}
          <div className='conatiner_t-2-1-navigation_3-Search_Tool_2'
          style={{position:'relative'}}
          >
            <div
            className='heading-1-active-amenity-Search_Tool'
            >
              Quick Amenities
            </div>

            {/* <div className='temp3-SearchTool'> */}

              <button
                onClick={() => {handleAmenitiesClick("pool") }} 
                className={selectedAmenitiesData && selectedAmenitiesData.includes("pool") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
              >
                <div
                 className={selectedAmenitiesData && selectedAmenitiesData.includes("pool") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                  Pool 
                </div>
                <div
                className='icon-1-amenity-Search_Tool'
                >
                  <MdOutlinePool />
                </div>
                {selectedAmenitiesData.includes("pool") &&
                  <div
                  className='icon-2-amenity-Search_Tool'
                  >
                    <IoMdCloseCircle />
                  </div>
                }
              </button>

              <button
                onClick={() => {handleAmenitiesClick("parking") }}
                className={selectedAmenitiesData && selectedAmenitiesData.includes("parking") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
              >
                <div
                  className={selectedAmenitiesData && selectedAmenitiesData.includes("parking") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                  Parking
                </div>
                <div
                 className='icon-1-amenity-Search_Tool'
                >
                  <TbParking />
                </div>
                {selectedAmenitiesData.includes("parking") &&
                  <div
                  className='icon-2-amenity-Search_Tool'
                  >
                    <IoMdCloseCircle />
                  </div>
                }
              </button>

              <button
                onClick={() => {handleAmenitiesClick("room_service") }}
                className={selectedAmenitiesData && selectedAmenitiesData.includes("room_service") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
              >
                <div
                  className={selectedAmenitiesData && selectedAmenitiesData.includes("room_service") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                  Room Service
                </div>
                <div
                 className='icon-1-amenity-Search_Tool'
                >
                  <MdOutlineRoomService />
                </div>
                {selectedAmenitiesData && selectedAmenitiesData.includes("room_service") &&
                  <div
                  className='icon-2-amenity-Search_Tool'
                  >
                    <IoMdCloseCircle />
                  </div>
                }
              </button>

              <button
                onClick={() => {handleAmenitiesClick("restaurant") }}
                className={selectedAmenitiesData && selectedAmenitiesData.includes("restaurant") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
              >
                <div
                  className={selectedAmenitiesData && selectedAmenitiesData.includes("restaurant") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                  Restaurant
                </div>
                <div
                 className='icon-1-amenity-Search_Tool'
                >
                  <IoRestaurantOutline />
                </div>
                {selectedAmenitiesData && selectedAmenitiesData.includes("restaurant") &&
                  <div
                  className='icon-2-amenity-Search_Tool'
                  >
                    <IoMdCloseCircle />
                  </div>
                }
              </button>

              <button
                onClick={()=>{
                  if(otherAmenitiesWindow){
                    setOtherAmenitiesWindow(false)
                  }
                  else{
                    setOtherAmenitiesWindow(true)
                  }
                }}
                // onMouseOut={()=>{setOtherAmenitiesWindow(false)}}
                className={otherAmenitiesWindow ? 'conatiner_p-hover-active-amenity-Search_Tool' : 'conatiner_p-hover-unActive-amenity-Search_Tool'}
              >
                <div>
                  <RiArrowDropDownLine />
                </div>

                <div
                 className='title-1-hover-amenity-Search_Tool'
                >
                  Other Amenities 
                </div>

                <div
                 className='icon-1-amenity-Search_Tool'
                >
                  <IoOptionsSharp />
                </div>

              </button>


            {/* </div> */}

          </div>  


            {/*//*wraping the the whole other amenities */}
            { otherAmenitiesWindow &&
                <div
                  className='conatiner_p-hover-otherAmenities-amenity-Search_Tool'
                >
                  {/* <div> */}
        
                    <OtherAmenities 
                      selectedAmenitiesData={selectedAmenitiesData} 
                      handleAmenitiesClick={handleAmenitiesClick}
                      setOtherAmenitiesWindow={setOtherAmenitiesWindow}
                    />
                  {/* </div> */}
                </div>
              }


      



          {/*//!2  */}
          <div
          className='conatiner_t-2-1-navigation_3-Search_Tool_2'
          >

            <div>

              <button
                onClick={() => {handleSortHotelsClick("free_cancellation") }}
                className={sortHotelData.includes("free_cancellation") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
              >
                <div
                className={sortHotelData && sortHotelData.includes("free_cancellation") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                  Free Cancellation
                </div>

                <div
                className='icon-1-amenity-Search_Tool'
                >
                  <MdOutlineFreeCancellation />
                </div>

                {sortHotelData && sortHotelData.includes("free_cancellation") &&
                  <div
                  className='icon-2-amenity-Search_Tool'
                  >
                    <IoMdCloseCircle />
                  </div>
                }
              </button>

            </div>

            <div>

              <button
                onClick={() => {handleHotelsRatingsClick("4") }}
                className={hotelRatings === "4" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
              >
                <div
                className={hotelRatings === "4"? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                  4+ Ratings
                </div>

                <div
                className='icon-1-amenity-Search_Tool'
                >
                  <FaRegStarHalf />
                </div>
                {hotelRatings === "4" &&
                  <div
                  className='icon-2-amenity-Search_Tool'
                  >
                    <IoMdCloseCircle />
                  </div>
                }
              </button>

            </div>


            <div
              style={{position:'relative', display:'flex', flexDirection:'row'}}
            >

              <button
                onMouseOver={()=>{setHotelsRatingsWindow(true)}}
                onMouseOut={()=>{setHotelsRatingsWindow(false)}}

                className={hotelsRatingsWindow ? 'conatiner_p-hover-active-amenity-Search_Tool' : 'conatiner_p-hover-unActive-amenity-Search_Tool'}
              >
                <div
                className='icon-1-amenity-Search_Tool'
                >
                  <RiArrowDropDownLine />
                </div>
                <div
                className='title-1-hover-amenity-Search_Tool'
                >
                  Select Ratings
                </div>
                <div
                className='icon-1-amenity-Search_Tool'
                >
                  <GiStarFormation />
                </div>
              </button>

              {
                hotelsRatingsWindow && 
                (
                  <div
                  onMouseOver={()=>{setHotelsRatingsWindow(true)}}
                  onMouseOut={()=>{setHotelsRatingsWindow(false)}}
                  className='conatiner_p-hover-ratings-navigation_3-Search_Tool_2'
                  >

                    <button
                      onClick={() => {
                        handleHotelsRatingsClick("2") 
                      }}
                      className={hotelRatings === "2" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
                    >
                      <div
                       className='title-1-hover-amenity-Search_Tool'
                      >
                        2+
                      </div>
                      {hotelRatings === "2" &&
                        <div
                        className='icon-1-amenity-Search_Tool'
                        >
                          <IoMdCloseCircle />
                        </div>
                      }
                    </button>


                    <button
                      onClick={() => {
                        handleHotelsRatingsClick("3") 
                      }}
                      className={hotelRatings === "3" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
                    >
                      <div
                       className='title-1-hover-amenity-Search_Tool'
                      >
                        3+
                      </div>
                      {hotelRatings === "3" &&
                        <div
                        className='icon-1-amenity-Search_Tool'
                        >
                          <IoMdCloseCircle />
                        </div>
                      }
                    </button>


                    <button
                      onClick={() => {
                        handleHotelsRatingsClick("4") 
                      }}
                      className={hotelRatings === "4" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
                    >
                      <div
                       className='title-1-hover-amenity-Search_Tool'
                      >
                        4+
                      </div>
                      {hotelRatings === "4" &&
                        <div
                        className='icon-1-amenity-Search_Tool'
                        >
                          <IoMdCloseCircle />
                        </div>
                      }
                    </button>

                    <button
                      onClick={() => {
                        handleHotelsRatingsClick("4.5") 
                      }}
                      className={hotelRatings === "4.5" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
                    >
                      <div
                       className='title-1-hover-amenity-Search_Tool'
                      >
                        4.5+
                      </div>
                      {hotelRatings === "4.5" &&
                        <div
                        className='icon-1-amenity-Search_Tool'
                        >
                          <IoMdCloseCircle />
                        </div>
                      }
                    </button>
                 
                  </div> 
                )
              }

            </div>

          </div>




          {/*//!3  */}
          <div 
          className='conatiner_t-2-1-navigation_3-Search_Tool_2'
          >

            <div
              className='heading-1-active-amenity-Search_Tool'
            >
              Price
            </div>

            <button
              onClick={() => {handleSortHotelPriceClick("price_h_to_l") }}
              className={sortHotelPrice === "price_h_to_l" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
              <div
              className={sortHotelPrice === "price_h_to_l" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
              >
                High to Low
              </div>
              <div
              className='icon-1-amenity-Search_Tool'
              >
                <TbSortDescendingNumbers />
              </div>
              {
                sortHotelPrice === "price_h_to_l" &&
                <div
                className='icon-2-amenity-Search_Tool'
                >
                  <IoMdCloseCircle />
                </div>
              }
            </button>

            <button
              onClick={() => {handleSortHotelPriceClick("price_l_to_h") }}
              className={sortHotelPrice === "price_l_to_h" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
              <div
              className={sortHotelPrice === "price_l_to_h" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
              >
                Low to High
              </div>
              <div
              className='icon-1-amenity-Search_Tool'
              >
                <TbSortAscendingNumbers />
              </div>
              {
                sortHotelPrice === "price_l_to_h" &&
                <div
                className='icon-2-amenity-Search_Tool'
                >
                  <IoMdCloseCircle />
                </div>
              }
            </button>

            <button
              onClick={() => {handleSortHotelPriceClick("relevant") }}
              className={sortHotelPrice === "relevant" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
              <div
              className={sortHotelPrice === "relevant" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
              >
                Relevant Hotels
              </div>
              <img 
              className='img-1-amenity-Search_Tool'
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/relevant_hotel_icon_1.png'
              />
              {
                sortHotelPrice === "relevant" &&
                <div
                className='icon-2-amenity-Search_Tool'
                >
                  <IoMdCloseCircle />
                </div>
              }
            </button>

            <button
              onClick={() => {handleSortHotelPriceClick("most_reviewed") }}
              className={sortHotelPrice === "most_reviewed" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
              <div
              className={sortHotelPrice === "most_reviewed" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
              >
                Most Reviewed
              </div>
              <img 
              className='img-1-amenity-Search_Tool'
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/most_reviewed_icon_2.png'
              />
              {
                sortHotelPrice === "most_reviewed" &&
                <div
                className='icon-2-amenity-Search_Tool'
                >
                  <IoMdCloseCircle />
                </div>
              }
            </button>   

            
            <button
              onClick={() => {handleSortHotelPriceClick("nearest_first") }}
              className={sortHotelPrice === "nearest_first" ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
              <div
              className={sortHotelPrice === "nearest_first" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
              >
                Nearest First
              </div>
              <img 
              className='img-1-amenity-Search_Tool'
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/nearest_icon_1.png'
              />
              {
                sortHotelPrice === "nearest_first" &&
                <div
                className='icon-2-amenity-Search_Tool'
                >
                  <IoMdCloseCircle />
                </div>
              }
            </button>   

          </div>  

        </div> 
      </div>  
      }
        {/*//?the whole meta search container */}

        {/*//! END - Container #2 - end*/}



        {/*//!Container #3 */}
        
        {/*//*!Under Km */}
        {
          dataLoadedInputType === "location" && 
          (
          <div
          className='container_p-umderKm-Search_Tool_2'
          > {/*//?under km - whole container */}

            <div
            className='container_s-umderKm-Search_Tool_2'
            >

              <button
                onClick={() => {handleUnderKm("5")}}
                className={underKm === "5" ? 'button-active-umderKm-Search_Tool_2' : 'button-unActive-umderKm-Search_Tool_2'}
              >
                <div
                className={underKm === "5" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                  under 5 km
                </div>
                {/* {underKm === "5" &&
                  <div
                  className='icon-2-amenity-Search_Tool'
                  >
                    <IoMdCloseCircle />
                  </div>
                } */}
              </button>

              <button
                onClick={() => {handleUnderKm("8")}}
                className={underKm === "8" ? 'button-active-umderKm-Search_Tool_2' : 'button-unActive-umderKm-Search_Tool_2'}
              >
                <div
                className={underKm === "8" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                  under 8 km
                </div>
                {/* {underKm === "8" &&
                  <div
                  className='icon-2-amenity-Search_Tool'
                  >
                    <IoMdCloseCircle />
                  </div>
                } */}
              </button>

              <button
                onClick={() => {handleUnderKm("12")}}
                className={underKm === "12" ? 'button-active-umderKm-Search_Tool_2' : 'button-unActive-umderKm-Search_Tool_2'}
              >
                <div
                className={underKm === "12" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                  under 12 km
                </div>
                {/* {underKm === "12" &&
                  <div
                  className='icon-2-amenity-Search_Tool'
                  >
                    <IoMdCloseCircle />
                  </div>
                } */}
              </button>

              <button
                onClick={() => {handleUnderKm("20")}}
                className={underKm === "20" ? 'button-active-umderKm-Search_Tool_2' : 'button-unActive-umderKm-Search_Tool_2'}
              >
                <div
                className={underKm === "20" ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                  under 28 km
                </div>
                {/* {underKm === "20" &&
                  <div
                  className='icon-2-amenity-Search_Tool'
                  >
                    <IoMdCloseCircle />
                  </div>
                } */}
              </button>

            </div>

          </div> //?under km - whole container

          )
        }


      </div>

      {/* setViewMore_id(data.id)
      setViewMoreStatus(true)
      handleGetReviewdData(data.id) */}

      
      {/* //! */}
      {/* //? There is some issue in this, when clicked on the hotel name it should open the popup with the data of hotel but it is snot working... */}
      {/* placeStateName: {placeStateName} */}
      {/* {
        dataLoadedInputType && dataLoadedInputType === "destination" && 
        (
        <div>
          <Search_Hotels  
          handleSearchResultClick={handleHotelNameInputClick}
          stateName={placeStateName}
          destinationName={placeName}
          destinationType={destinationType}
          lat={hotelLat}
          lng={hotelLng}

          setViewMore_id={setViewMore_id}
          setViewMoreStatus={setViewMoreStatus}
          handleGetReviewdData={handleGetReviewdData}
          />
          {searchedHotelName} {searchedHotelId}
        </div>   
        )
      }   */}

    </div>
  )
}

export default Search_Tool


//?Quick Amenities
//Pool, Room service, Airport shuttle, Parking, Restaurant, Free Breakfast

//?Other Amenities
//--final other amenities
//Wi-fi, Pet-friendly, Bar, Breakfast, Paid Breakfast, Accessible, Outdoor Pool, Spa, Laundry service, Kitchens in some rooms, Business Centre, Fitness Centre

//Wi-fi, Pet-friendly, Bar, Breakfast, Paid Breakfast, Smoke-free, Accessible, Outdoor pool, Spa, Air-conditioned, Child friendly, Laundry service, Kitchens in some rooms, Business Centre, Fitness Centre, Hot Tub, Golf


// Wi-fi - Free Wi-Fi, Wi-Fi, Paid Wi-Fi
// Room service
// Airport shuttle
// Breakfast - Free Breakfast, Breakfast, Paid breakfast
// Smoke-free
// Accessible
// Pool - Pool, Outdoor pool
// Air-conditioned
// Child friendly
// Pet-friendly
// Parking - Parking, Free parking, Paid parking
// Laundry service
// Restaurant
// Bar
// Kitchens in some rooms
// Spa
// Hot tub
// Golf
// Business centre
// Fitness centre

//!!

{/* <div>
         
{
  JSON.stringify(trackChange_SelectedAmenitiesData) !== JSON.stringify(selectedAmenitiesData) ?

  <button
    onClick={()=>{handleSearchHotels_Keywords()}}
  >
    Search 
  </button>

  :

  JSON.stringify(trackChange_SortHotelData) !== JSON.stringify(sortHotelData) ?

  <button
    onClick={()=>{handleSearchHotels_Keywords()}}
  >
    Search 
  </button>

  : 

  trackChange_SortHotelPrice !== sortHotelPrice ?

  <button
    onClick={()=>{handleSearchHotels_Keywords()}}
  >
    Search 
  </button>

  :

  trackChange_HotelRatings !== hotelRatings ?

  <button
    onClick={()=>{handleSearchHotels_Keywords()}}
  >
    Search 
  </button>

  : 
  
  null
}                    

</div> */}


// !for hotel star
            {/* <div>

              <button
                onClick={() => {
                  handleSortHotelsClick("hotel_star") 
                  setSortHotelsWindow("hotel_star")
                }}
                className={sortHotelData === "restaurant" ? 'active_other_amenities-SearchToolHotel' : 'unActive_other_amenities-SearchToolHotel'}
              >
                <div>
                  Hotel Star
                </div>
                <div>
                  Icon
                </div>
              </button>

              {
                sortHotelData === "hotel_star" && 
                (
                  <div>
                    
                    <button
                      onClick={() => {
                        handleSortHotelsClick("3 star") 
                        setSortHotelsWindow("hotel_star")
                      }}
                      className={sortHotelData === "restaurant" ? 'active_other_amenities-SearchToolHotel' : 'unActive_other_amenities-SearchToolHotel'}
                    >
                      <div>
                        Hotel Star
                      </div>
                      <div>
                        Icon
                      </div>
                    </button>


                    <button
                      onClick={() => {
                        handleSortHotelsClick("hotel_star") 
                        setSortHotelsWindow("hotel_star")
                      }}
                      className={sortHotelData === "restaurant" ? 'active_other_amenities-SearchToolHotel' : 'unActive_other_amenities-SearchToolHotel'}
                    >
                      <div>
                        Hotel Star
                      </div>
                      <div>
                        Icon
                      </div>
                    </button>


                    <button
                      onClick={() => {
                        handleSortHotelsClick("hotel_star") 
                        setSortHotelsWindow("hotel_star")
                      }}
                      className={sortHotelData === "restaurant" ? 'active_other_amenities-SearchToolHotel' : 'unActive_other_amenities-SearchToolHotel'}
                    >
                      <div>
                        Hotel Star
                      </div>
                      <div>
                        Icon
                      </div>
                    </button>

                  </div>   
                )
              }

            </div> */}





          //   <div>

          //   <button
          //       onClick={() => {handlePTExclusiveClick("a_class") }} 
          //       className={ptExclusiveData === "a_class" ? 'active_other_amenities-SearchToolHotel' : 'unActive_other_amenities-SearchToolHotel'}
          //   >
          //     <div>
          //       A Class 
          //     </div>
          //     <div>
          //       Icon
          //     </div>
          //     {ptExclusiveData === "a_class" &&
          //       <div>
          //         x
          //       </div>
          //     }
          //   </button>
  
          //   <button
          //       onClick={() => {handlePTExclusiveClick("b_class") }} 
          //       className={ptExclusiveData === "b_class" ? 'active_other_amenities-SearchToolHotel' : 'unActive_other_amenities-SearchToolHotel'}
          //   >
          //     <div>
          //       B Class 
          //     </div>
          //     <div>
          //       Icon
          //     </div>
          //     {ptExclusiveData === "b_class" &&
          //       <div>
          //         x
          //       </div>
          //     }
          //   </button>
  
          //   <button
          //       onClick={() => {handlePTExclusiveClick("c_class") }} 
          //       className={ptExclusiveData === "c_class" ? 'active_other_amenities-SearchToolHotel' : 'unActive_other_amenities-SearchToolHotel'}
          //   >
          //     <div>
          //       C Class 
          //     </div>
          //     <div>
          //       Icon
          //     </div>
          //     {ptExclusiveData === "c_class" &&
          //       <div>
          //         x
          //       </div>
          //     }
          //   </button>
  
          // </div>