// src/analytics.js
import ReactGA from 'react-ga4';

// Initialize Google Analytics with your GA4 Measurement ID
export const initGA = () => {
  ReactGA.initialize('G-EH05T6NLTD'); // Replace with your GA4 Measurement ID
};

// Track page view
export const trackPageView = (path) => {
  ReactGA.send({ hitType: 'pageview', page: path });
};


// Track User Actions
export const trackButtonClick = (categoryPassed, actionPassed, labelPassed) => {
    ReactGA.event({
      category: categoryPassed,
      action: actionPassed,
      label: labelPassed
    });
};



//!how to use
//*Initialize GA4 when the app loads
// useEffect(() => {
//     initGA();
// }, []);

//*Track page views on route change
// useEffect(() => {
//     trackPageView(location.pathname);
// }, [location.pathname]);

//!Other
//*Summary of Available Parameters:
// Parameter	Description	Example
// category	Groups similar events together.	'Button', 'Form', 'Video'
// action	Describes the user’s action.	'Clicked Button', 'Submitted Form'
// label	Adds more detail to the event.	'Homepage', 'Signup Form'
// value	Numeric value associated with the event.	29.99, 50 (scroll percentage)
// nonInteraction	If true, doesn’t affect the bounce rate.	true/false
// transport	Defines how the event is sent (beacon, xhr, image).	'beacon'
// custom dimensions/metrics	Custom dimensions or metrics added to events.	'LoggedInUser', 'user_score'
// event parameters	Custom event parameters for additional data.	'user_id', 'campaign', 'region'
