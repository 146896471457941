//@@1

//this is how you can import define it:

// MyContext.js
import React, { createContext, useContext, useState } from 'react';

//
import axios from 'axios';

import { server_baseUrl } from '../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';

// Step 1: Create a new context
const CC_Main = createContext();



// Step 2: Create a context provider
export const HowToReachContextProvider_Mobile = ({ children }) => {

  //active travel mode
  const [activeMode, setActiveMode] = useState("train")
  const [initialLoadStatus, setInitialLoadStatus] = useState(false)
  const [destinationName, setDestinationName] = useState()
  const [destinationType, setDestinationType] = useState()

  //loading
  const[loadingStatusTravelModes, setLoadingStatusTravelModes] = useState()

  //store data for each travel mode
  const [airContent, setAirContent] = useState([])
  const [trainContent, setTrainContent] = useState([])
  const [busContent, setBusContent] = useState([])

  //store place name for the loaded data for each travel mode
  const [air_PlaceName, setAir_PlaceName] = useState()
  const [train_PlaceName, setTrain_PlaceName] = useState()
  const [bus_PlaceName, setBus_PlaceName] = useState()

  //set prompt - only for when the data is loaded from the ai using unique id (when it is present in the url)
  const [prompt, setPrompt] = useState()
  const [masterData, setMasterData] = useState([])

  //map
  const [lat, setLat] = useState(20.5937);
  const [lng, setLng] = useState(78.9629);
  const [zoom, setZoom] = useState(6);

  const[fitAllLocations, setFitAllLocations] = useState()

  const [hoverLocationData_map, setHoverLocationData_map] = useState([])
  const [activeLocationData_map, setActiveLocationData_map] = useState([])

  //input
  const [inputResult, setInputResult] = useState([])
  const [activeSearchLocationType, setActiveSearchLocationType] = useState("destination")
  const [inputDestinationValue, setInputDestinationValue] = useState()

  //active destination brief data
  const [activeTrainDestination, setActiveTrainDestination] = useState() 
  const [activeAirDestination, setActiveAirDestination] = useState() 
  const [activeBusDestination, setActiveBusDestination] = useState() 

  const componentInitialLoad = async (
    firstMode, //when called from home page | with a seleted travel mode
    unique_id, navigationId, ai_feature_id,//when when from ai tool | handle ai search
    nameDestination, typeDestination, travelType //to load the result, with no need for initial user input
  ) => {
    //?main call - from home
    if(firstMode){
      // console.log("11")
      setActiveMode(firstMode)
      setInitialLoadStatus(false)
    }
    //?ai tool call - from ai feature
    else if(unique_id){
      if(ai_feature_id === "nb-01"){

        //load the prompt and content from the unique_id
        loadPrompt_1_AiTool(unique_id) 
        const result = await loadAiContent_AiTool(unique_id)

        const tempTypeD_L = result.destination_location_type;
        const tempName = result.place_name;

        loadDestinationDataLatLng(tempName, tempTypeD_L)
        // console.log("tempName, tempTypeD_L", tempName, tempTypeD_L)

        //handling the component/page for the ai tool
        if(navigationId === "nb-02"){
          // handle for airports
          handleAirports_AiTool(result) 
        }
        else if(navigationId === "nb-03"){
          //handle for railway stations
          handleRailwayStations_AiTool(result)
        }
        else if(navigationId === "nb-04"){
          //handle for bus stand
          handleBusStands_AiTool(result)
        }
      }
    }
    //?just load the page and get the result
    // else if(nameDestination || typeDestination || travelType){
    //   // console.log("22")
    //   setDestinationName(nameDestination)
    //   setDestinationType(typeDestination)
    //   setActiveMode(travelType)

    //   loadTravelModeData(nameDestination, typeDestination, travelType)

    //   setInitialLoadStatus(false)
    // }
    //?if no travel mode is seleted and none other matches | fresh search
    else{
      setInitialLoadStatus(true)
    }
  }

  //!Handle Ai Tool - start

  const loadPrompt_1_AiTool = async (unique_id) => {

    const response =  await axios.post(`${server_baseUrl}/csc/send/api/ai_tools/ai_components/nearby_ai/prompt`, {unique_id})

    // console.log("loadPrompt_1_AiTool", response.data)
    
    if(response.data !== "0"){
      setPrompt(response.data)
    }
    else{
      alert("error loading some content")
    } 
  }

  const loadAiContent_AiTool = async (unique_id) => {

    const response =  await axios.post(`${server_baseUrl}/csc/send/api/ai_tools/ai_components/nearby_ai/load_content`, {unique_id})
    
    // console.log("loadPrompt_1_AiTool", response.data)
    
    if(response.data !== "0"){
      // setPrompt(response.data)
      setMasterData([response.data])
      return response.data;
    }
    else{
      alert("error loading some content")
    } 
  }

  
  //!Handle Ai Tool - end
  
  ////////////////////////////////////////////////////////
  
  //!Handle Content - start

  //* main - non-ai - start

  const handleDestinationClick = async (destination_name, destination_type, lat, lng) => {

    setInputResult([])
    setTrackLoadTravelModeData_Array([])
    
    // const trueTravelMode = travel_mode ? travel_mode : activeMode;
    
    const trueTravelMode = activeMode;

    //later or soon you can remove this, and get the data from handleDestinationClick
    insertDestinationDataLatLng(destination_name, lat, lng)

    setDestinationName(destination_name)

    if(activeSearchLocationType === "destination"){
      // console.log("destination_type, destination_type, ", destination_type)
      setDestinationType(destination_type)
    }

    setActiveMode(trueTravelMode)

    if(!initialLoadStatus)
    {
      loadTravelModeData(destination_name, destination_type, trueTravelMode);
    }
 
  }

  // for Location
  const handleDestinationClick_2 = async (location_name, id, type, lat, lng) => {

    setInputResult([])
    setTrackLoadTravelModeData_Array([])

    const destination_type = "location";

    const trueTravelMode = activeMode; 

    // loadDestinationDataLatLng(location_name, destination_type)
    insertDestinationDataLatLng(location_name, lat, lng)

    setDestinationName(location_name)

    if(activeSearchLocationType === "destination"){
      // console.log("destination_type, destination_type, ", destination_type)
      setDestinationType(destination_type)
    }

    setActiveMode(trueTravelMode)

    if(!initialLoadStatus)
    {
      loadTravelModeData(location_name, destination_type, trueTravelMode);
    }
 
  }

  const[trackLoadTravelModeData_Array, setTrackLoadTravelModeData_Array] = useState([])

  const handleLoadTravelModeData_ModeClick = (passedTravelMode) => {
    
    if(!trackLoadTravelModeData_Array.includes(passedTravelMode)){
      loadTravelModeData(destinationName, destinationType, passedTravelMode)
    }

  }

  //?Here we are getting data for destinations and locations differently and especially in the case of train stations you need to make sure that the data that we're getting for the locations we do not have a type there like for the destination we have major and manner for the locations we don't have that so we are using direct categories to make a judgment for whether it is a major or minor and similarly in the same manner so the controller file is where it starts from and for destinations and locations it's different and later you do have to figure out how we are going to proceed further and yes that's it

  const loadTravelModeData = async (destination_name, destination_type, travel_mode) => {

    setLoadingStatusTravelModes("loading_data") 

    const response =  await axios.post(`${server_baseUrl}/csc/send/api/ai_tools/ai_components/how_to_reach_ai/get_data`, {destination_name, destination_type, travel_mode, activeSearchLocationType})

    // console.log("response.data - handleDestinationClick", response.data)
  
    if(response.data !== "0"){
      // setActiveDataMode(travel_mode)
      // setTravelModeData(response.data)
      setLat(response.data.data[0].lat)
      setLng(response.data.data[0].lng)
      setZoom(6)

      // console.log("travel_mode", travel_mode)
      if(travel_mode === "air"){
        setAirContent(response.data.data)
        setAir_PlaceName(travel_mode)
      }
      else if(travel_mode === "train"){
        setTrainContent(response.data.data)
        setTrain_PlaceName(travel_mode)
      }
      else{ //travel_mode === "bus"
        setBusContent(response.data.data)
        setBus_PlaceName(travel_mode)        
      }

      trackLoadTravelModeData_Array.push(travel_mode);
      setLoadingStatusTravelModes(null)

    }
    else{
      alert(`failed to get travel modes for ${destination_name}. You might try again.`)
      setLoadingStatusTravelModes(null)
    }

  }


  const insertDestinationDataLatLng = async (destination_name, lat, lng) => {

    // console.log("destination_name, lat, lng", destination_name, lat, lng)

    if(destination_name || lat || lng){
      
      if(activeMode === "train"){
        setActiveTrainDestination([{
          "name": destinationName, "lat":lat, "lng":lng
        }])
      }
      else if(activeMode === "air"){
        setActiveAirDestination([{
          "name": destinationName, "lat":lat, "lng":lng
        }])
      }
      else{
        setActiveBusDestination([{
          "name": destinationName, "lat":lat, "lng":lng
        }])
      }
    }
  }


  //only used for the initial load done in the AI Feature
  const loadDestinationDataLatLng = async (destination_name, destination_type) => {

    // const destination_type = temp_destination_type !== 
    // "city" ? temp_destination_type : "destination"

    const response =  await axios.post(`${server_baseUrl}/csc/api/global_functions/api/api_1_quick_detail/get_destination_quick_info/from_name_type`, {destination_name, destination_type})

    // console.log("response.data - loadDestinationDataLatLng", response.data)

    if(response.data !== "0"){
      
      if(activeMode === "train"){
        setActiveTrainDestination(response.data)
      }
      else if(activeMode === "air"){
        setActiveAirDestination(response.data)
      }
      else{
        setActiveBusDestination(response.data)
      }

    }

  }

  //* main - non-ai - end    

  //?the content from the back for the ai request, in relation to the unique_id is alredy sorted for the correct trave mode.   
  
  //* ai -- start
  const handleAirports_AiTool = (passedMasterData) => {
    setActiveMode("air")

    if(passedMasterData && passedMasterData.data){
      setAirContent(passedMasterData.data)
      setAir_PlaceName(passedMasterData.place_name)
    }
    else{
      // console.log("error code - 001122")
    }
  }
  
  const handleRailwayStations_AiTool = (passedMasterData) => {
    setActiveMode("train")
    
    // console.log("handleRailwayStations_AiTool - masterData", passedMasterData)

    if(passedMasterData && passedMasterData.data){
      setTrainContent(passedMasterData.data)
      setTrain_PlaceName(passedMasterData.place_name)
    }
    else{
      // console.log("error code - 001133")
    }
  }

  const handleBusStands_AiTool = (passedMasterData) => {
    setActiveMode("bus")

    if(passedMasterData && passedMasterData.data){
      setBusContent(passedMasterData.data)
      setBus_PlaceName(passedMasterData.place_name)
    }
    else{
      // console.log("error code - 001144")
    }
  }

  //* ai -- end



  //!Handle Content - end

////////////////////////////////////////////////////////

  //!Handle Map - start

  const handleHoverLocation_Map = (passedLat, passedLng, passedName) => {
    setHoverLocationData_map([{lat: passedLat, lng: passedLng, name: passedName}])

    setLat(passedLat)
    setLng(passedLng)

    if(activeMode === "air"){
      setZoom(7)
    }
    else if(activeMode === "bus"){
      setZoom(10)
    }
    else if(activeMode === "train"){
      setZoom(10)
    }

  }

  const handleHoverOffLocation_Map = () => {
    setHoverLocationData_map([])
    setActiveLocationData_map([])
  }

  const handleClickLocation_Map = (passedLat, passedLng, passedName) => {
    setLat(passedLat)
    setLng(passedLng)
    setActiveLocationData_map([{lat: passedLat, lng: passedLng, name: passedName}])

    if(activeMode === "air"){
      setZoom(10)
    }
    else if(activeMode === "bus"){
      setZoom(10)
    }
    else if(activeMode === "train"){
      setZoom(10)
    }
  }

  //!Handle Map - end

////////////////////////////////////////////////////////

const test = () => {
  // console.log("trainContent - test - how to reach", trainContent)
  // console.log('\n')
  // console.log("airContent - test - how to reach", airContent)
  // console.log('\n')
  // console.log("busContent - test - how to reach", busContent)
  // console.log('\n')

  // console.log("activeTrainDestination - test - activeTrainDestination", activeTrainDestination)
  // console.log('\n')
  // console.log("activeBusDestination - test - activeBusDestination", activeBusDestination)
  // console.log('\n')
  // console.log("activeAirDestination - test - activeAirDestination", activeAirDestination)
  // console.log('\n')
}

  // Step 5: Return a provider with value containing state and functions
  return (
    <CC_Main.Provider value={{ 
        //loading
        loadingStatusTravelModes, setLoadingStatusTravelModes,
        //
        destinationName, destinationType,
        activeMode, setActiveMode,
        initialLoadStatus, 
        //search tool for destination or location
        activeSearchLocationType, setActiveSearchLocationType,
        inputDestinationValue, 
        airContent, trainContent, busContent, 
        air_PlaceName, train_PlaceName, bus_PlaceName, 
        activeTrainDestination, activeAirDestination, activeBusDestination,
        prompt, 
        //map
        lat, lng, zoom,
        hoverLocationData_map, activeLocationData_map,
        fitAllLocations, setFitAllLocations,
        //functions
        componentInitialLoad, 
        //this handles when the user clicks the places from the search tool
        handleDestinationClick, handleDestinationClick_2,
        handleLoadTravelModeData_ModeClick,
        //map
        handleHoverLocation_Map, handleHoverOffLocation_Map, handleClickLocation_Map, 
        test
    }}>
      {children}
    </CC_Main.Provider>
  );
};

// Step 6: Create a custom hook to consume the context
export const useHowToReachContext = () => {
  return useContext(CC_Main);
};