import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { server_baseUrl } from '../../Universal Components/Server Side Hit Url/ServerHitUrlPart';
function Result_HowToReach_AI() {
  
const {unique_id} = useParams();

useEffect(() => {
    handleLoadPrompt(unique_id);
    loadContent()
}, [])

// prompt/question
const [prompt, setPrompt] = useState()

//store data prompts data
const [data_1, setData_1] = useState();
const [data_2, setData_2] = useState();
const [data_3, setData_3] = useState();
const [data_4, setData_4] = useState();

// load prompt
const handleLoadPrompt = async () => {
    const response =  await axios.post(`${server_baseUrl}/csc/send/api/ai_tools/ai_components/how_to_reach_ai/prompt`, {unique_id})
    // console.log("handleLoadPrompt", response.data)
 
    setPrompt(response.data)
}

// load content
// const [context,]

const loadContent = async () => {
    const response =  await axios.post(`${server_baseUrl}/csc/send/api/ai_tools/ai_components/how_to_reach_ai/load_content`, {unique_id})
    // console.log("loadContent", response.data)
 
    // setPrompt(response.data)
}



  return (
    <div>   
        Prompt: {prompt}
    </div>
  )
}


export default Result_HowToReach_AI