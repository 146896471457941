import React, {useEffect, useState, useRef} from 'react'

//import scss
import './AiItinerary_Chat.scss'

//import react context'
import {useCreateItinerary_2_PlanTrip} from '../../../../Manage/Context/CreateItinerary_Context_2';

//import icons
import { TbSend } from "react-icons/tb";

//
import ReactMarkdown from 'react-markdown';


function AiItinerary_Chat() {

    const {
        
    //!States

    //*manage
    aiItinerary_initialLoad_Status, setAiItinerary_initialLoad_Status,
    sentActive_status, setSentActive_status,

    //track
    askQuestionCount_day, setAskQuestionCount_day,

    //i button
    iButtonMessage, setIButtonMessage, 
    handleIButton,

    //*data
    aiItineraryData, setAiItineraryData,
    currentQuestion, setCurrentQuestion,
    currentAnswer, setCurrentAnswer,
    aiItinerary_Id, setAiItinerary_Id,
    last_2_conversation, setLast_2_conversation,

    //*navigate
    navigate_aiItineraryChat, setNavigate_aiItineraryChat,
    aiItineraryChat_window, setAiItineraryChat_window,
    
    
    //!Functions   
    handleInput_CurrentQuestion,
    handleAskQuestion, handleLoadAiItinerary,

    //
    test
    
    } = useCreateItinerary_2_PlanTrip()


    const containerRef = useRef(null);

    useEffect(() => {
        if(aiItineraryChat_window){
            const container = containerRef.current;
            if (container) {
                container.scrollTop = container.scrollHeight;
            }
        }
    }, [aiItineraryChat_window]); 

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.scrollTop = container.scrollHeight;
        }
    }, [aiItineraryData]); 




  return (
    <div
    className='container_p-AiItinerary_Chat'
    >
        
    {
        aiItineraryData && aiItineraryData.length > 0 &&
        <div
        ref={containerRef}
        className='conatiner_s-1-answer-AiItinerary_Chat'
        >
            {
                aiItineraryData.map((data, index) => {
                return(

                    <div key={index}
                    className='conatiner_t-answer-AiItinerary_Chat'
                    >

                        {
                            data.question && 
                            <div
                            className='container-t-question-AiItinerary_Chat'
                            >
                                <div
                                className='text-1-question-AiItinerary_Chat'
                                >
                                    {data.question}
                                </div>
                            </div>
                        }     

                        {
                            data.answer && data.answer.length > 0 && 
                            <div
                            className='container-t-answer-AiItinerary_Chat'
                            >
                                <div
                                className='content-1-data-About_VM'
                                style={{marginTop:'-1%'}}
                                >
                                    {/* {data.answer} */}
                                    <ReactMarkdown>{data.answer}</ReactMarkdown>
                                </div>
                       
                            </div>     
                        } 

                    </div>
                )
                })
            }

        </div>
    }

    {
        sentActive_status && 
        <div
        style={{color:'brown'}}
        >
            Answer is loading...
        </div>
    }

    <div
    className='conatiner_s-2-answer-AiItinerary_Chat'
    >
        <button
        className='container_p-iButton-AiItinerary_Chat'
        onMouseOver={()=>{handleIButton(true, "message")}}
        onMouseOut={()=>{setIButtonMessage(false)}}
        >
            <img
            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/i-button-icon-2.png'
            className='img-iButton-AiItinerary_Chat' 
            />

            {
                iButtonMessage && 
                <div
                className='text-iButton-popup-AiItinerary_Chat'
                >
                    {iButtonMessage}
                </div>
            }

        </button>

        {
            askQuestionCount_day < 21 &&
          
            <div style={{width:"80%"}}>
                <textarea 
                value={currentQuestion}
                onChange={handleInput_CurrentQuestion}
                placeholder='ask your question...(max 200)'
                className='input-askAi-AiItinerary_Chat'
                maxLength={200}    
                disabled={sentActive_status}
                />
            </div>

        }

        {   askQuestionCount_day < 21 &&
            <div
            className='text-askQlimit-AiItinerary_Chat'
            >
            {askQuestionCount_day}/20
            </div>   
        }

        {
            askQuestionCount_day < 21 &&
            <div>


                <button
                onClick={()=>{
                    if(currentQuestion && currentQuestion.length > 0){
                        handleAskQuestion()
                    }else{alert('empty question!')}
                }}
                className='button-send-AiItinerary_Chat'
                disabled={!currentQuestion || sentActive_status}
                >
                    {/* <TbSend /> */}
                    <img
                    src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/send_message_button-2.png'
                    className='img-sendButton-AiItinerary_Chat' 
                    />
                </button>
            </div>
        }

        {  askQuestionCount_day > 20 &&
            <div
            className='text-limitExceeded-AiItinerary_Chat'
            >
                You have exceeded your daily limit of 20 questions. 
                {/* <br></br> <br></br> */}
                We're working on a premium plan to offer higher limits.
            </div>
        }   

    </div>    



        
    </div>
  )
}

export default AiItinerary_Chat
