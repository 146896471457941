import React from 'react'

//import css
import './OtherAmenities.scss'

//import icons
import { IoMdCloseCircle } from "react-icons/io";
import { FaWifi } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { MdOutlineLocalBar } from "react-icons/md";
import { MdOutlineFreeBreakfast } from "react-icons/md";
import { TbAirConditioning } from "react-icons/tb";
import { MdAccessible } from "react-icons/md";
import { FaSwimmingPool } from "react-icons/fa";
import { MdSpa } from "react-icons/md";
import { MdOutlineLocalLaundryService } from "react-icons/md";
import { MdOutlineSoupKitchen } from "react-icons/md";
import { MdOutlineAirportShuttle } from "react-icons/md";
 
function OtherAmenities(props) {

    const {selectedAmenitiesData, handleAmenitiesClick, setOtherAmenitiesWindow} = props;

  return (
    <div>
     
    <div
    className='conatiner_p-OtherAmenities'
    >
       
        <button
        onClick={()=>{setOtherAmenitiesWindow(false)}}
        className='button-close-popup-OtherAmenities'
        >
            <IoMdCloseCircle />
        </button>

        <div
        className='conatiner_s-OtherAmenities'
        >

            {/* view other amenities list */}
            <button
            onClick={() => {handleAmenitiesClick("wi_fi") }}
            className={selectedAmenitiesData.includes("wi_fi") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
                <div
                className={selectedAmenitiesData && selectedAmenitiesData.includes("wi_fi") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                    Wi_fi
                </div>
                <div
                className='icon-1-amenity-Search_Tool'
                >
                    <FaWifi />
                </div>
                {selectedAmenitiesData && selectedAmenitiesData.includes("wi_fi") &&
                    <div
                    className='icon-2-amenity-Search_Tool'
                    >
                        <IoMdCloseCircle />
                    </div>
                }
            </button>

{/* //* */}
            <button
            onClick={() => {handleAmenitiesClick("pet_friendly") }}
            className={selectedAmenitiesData.includes("pet_friendly") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
                <div
                className={selectedAmenitiesData && selectedAmenitiesData.includes("pet_friendly") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                    Pet_friendly
                </div>
                <div
                className='icon-1-amenity-Search_Tool'
                >
                    <MdPets />
                </div>
                {selectedAmenitiesData && selectedAmenitiesData.includes("pet_friendly") &&
                    <div
                    className='icon-2-amenity-Search_Tool'
                    >
                        <IoMdCloseCircle />
                    </div>
                }
            </button>

{/* //* */}
            <button
            onClick={() => {handleAmenitiesClick("bar") }}
            className={selectedAmenitiesData.includes("bar") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
                <div
                className={selectedAmenitiesData && selectedAmenitiesData.includes("bar") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                    Bar
                </div>
                <div
                className='icon-1-amenity-Search_Tool'
                >
                    <MdOutlineLocalBar />
                </div>
                {selectedAmenitiesData && selectedAmenitiesData.includes("bar") &&
                    <div
                    className='icon-2-amenity-Search_Tool'
                    >
                        <IoMdCloseCircle />
                    </div>
                }
            </button>

{/* //* */}
            <button
            onClick={() => {handleAmenitiesClick("breakfast") }}
            className={selectedAmenitiesData.includes("breakfast") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
                <div
                className={selectedAmenitiesData && selectedAmenitiesData.includes("breakfast") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                    Breakfast
                </div>
                <div
                className='icon-1-amenity-Search_Tool'
                >
                    <MdOutlineFreeBreakfast />
                </div>
                {selectedAmenitiesData && selectedAmenitiesData.includes("breakfast") &&
                    <div
                    className='icon-2-amenity-Search_Tool'
                    >
                        <IoMdCloseCircle />
                    </div>
                }
            </button>

{/* //* */}
            <button
            onClick={() => {handleAmenitiesClick("air_conditioned") }}
            className={selectedAmenitiesData.includes("air_conditioned") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
                <div
                className={selectedAmenitiesData && selectedAmenitiesData.includes("air_conditioned") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                    Air-Conditioned
                </div>
                <div
                className='icon-1-amenity-Search_Tool'
                >
                    <TbAirConditioning />
                </div>
                {selectedAmenitiesData && selectedAmenitiesData.includes("air_conditioned") &&
                    <div
                    className='icon-2-amenity-Search_Tool'
                    >
                        <IoMdCloseCircle />
                    </div>
                }
            </button>

{/* //* */}
            <button
            onClick={() => {handleAmenitiesClick("accessible_w") }}
            className={selectedAmenitiesData.includes("accessible_w") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
                <div
                className={selectedAmenitiesData && selectedAmenitiesData.includes("accessible_w") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                    Accessible
                </div>
                <div
                className='icon-1-amenity-Search_Tool'
                >
                    <MdAccessible />
                </div>
                {selectedAmenitiesData && selectedAmenitiesData.includes("accessible_w") &&
                    <div
                    className='icon-2-amenity-Search_Tool'
                    >
                        <IoMdCloseCircle />
                    </div>
                }
            </button>
{/* //* */}
            <button
            onClick={() => {handleAmenitiesClick("outdoor_pool") }}
            className={selectedAmenitiesData.includes("outdoor_pool") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
                <div
                className={selectedAmenitiesData && selectedAmenitiesData.includes("outdoor_pool") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                    Outdoor Pool
                </div>
                <div
                className='icon-1-amenity-Search_Tool'
                >
                    <FaSwimmingPool />
                </div>
                {selectedAmenitiesData && selectedAmenitiesData.includes("outdoor_pool") &&
                    <div
                    className='icon-2-amenity-Search_Tool'
                    >
                        <IoMdCloseCircle />
                    </div>
                }
            </button>

{/* //* */}
            <button
            onClick={() => {handleAmenitiesClick("spa") }}
            className={selectedAmenitiesData.includes("spa") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
                <div
                className={selectedAmenitiesData && selectedAmenitiesData.includes("spa") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                    Spa
                </div>
                <div
                className='icon-1-amenity-Search_Tool'
                >
                    <MdSpa />
                </div>
                {selectedAmenitiesData && selectedAmenitiesData.includes("spa") &&
                    <div
                    className='icon-2-amenity-Search_Tool'
                    >
                        <IoMdCloseCircle />
                    </div>
                }
            </button>

{/* //* */}
            <button
            onClick={() => {handleAmenitiesClick("laundry_service") }}
            className={selectedAmenitiesData.includes("laundry_service") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
                <div
                className={selectedAmenitiesData && selectedAmenitiesData.includes("laundry_service") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                    Laundry service
                </div>
                <div
                className='icon-1-amenity-Search_Tool'
                >
                    <MdOutlineLocalLaundryService />
                </div>
                {selectedAmenitiesData && selectedAmenitiesData.includes("laundry_service") &&
                    <div
                    className='icon-2-amenity-Search_Tool'
                    >
                        <IoMdCloseCircle />
                    </div>
                }
            </button>

{/* //* */}
            <button
            onClick={() => {handleAmenitiesClick("kitchen") }}
            className={selectedAmenitiesData.includes("kitchen") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
                <div
                className={selectedAmenitiesData && selectedAmenitiesData.includes("kitchen") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                    Kitchens in some rooms
                </div>
                <div
                className='icon-1-amenity-Search_Tool'
                >
                    <MdOutlineSoupKitchen />
                </div>
                {selectedAmenitiesData && selectedAmenitiesData.includes("kitchen") &&
                    <div
                    className='icon-2-amenity-Search_Tool'
                    >
                        <IoMdCloseCircle />
                    </div>
                }
            </button>

{/* //* */}
            <button
            onClick={() => {handleAmenitiesClick("airport_shuttle") }}
            className={selectedAmenitiesData.includes("airport_shuttle") ? 'button-active-Amenity-Search_Tool' : 'button-unActive-Amenity-Search_Tool'}
            >
                <div
                className={selectedAmenitiesData && selectedAmenitiesData.includes("airport_shuttle") ? 'title-1-active-amenity-Search_Tool' : 'title-1-unActive-amenity-Search_Tool'}
                >
                    Airport Shuttle
                </div>
                <div
                className='icon-1-amenity-Search_Tool'
                >
                    <MdOutlineAirportShuttle />
                </div>
                {selectedAmenitiesData && selectedAmenitiesData.includes("airport_shuttle") &&
                    <div
                    className='icon-2-amenity-Search_Tool'
                    >
                        <IoMdCloseCircle />
                    </div>
                }
            </button>

            {/* <button
            onClick={() => {handleAmenitiesClick("fitness_centre") }}
            className={selectedAmenitiesData.includes("fitness_centre") ? 'active_other_amenities-SearchToolHotel' : 'unActive_other_amenities-SearchToolHotel'}
            >
            <div>
                Fitness Centre
            </div>
            <div>
                Icon
            </div>
            {selectedAmenitiesData && selectedAmenitiesData.includes("fitness_centre") &&
                <div>
                    x
                </div>
            }
            </button> */}

        </div>
    </div>
    </div>
  )
}

export default OtherAmenities