import React, { useState } from 'react'

// import css
import './AddTripDetails_TO.scss'

//importing context form Internal Trip Overview
import {useTripOverviewPlanTrip} from '../../Manage/Context/TripOverview_Context'

//import data
import { MonthsName, Weeks } from '../../Manage/Data/Data_1_TripOverview'

//import icon
import { IoMdCloseCircle } from "react-icons/io";

function AddTripDetails_TO(props) {

  const{shareTripId} = props;

  // use react context
  const {
    test,
    //!States
    //*trip details
    //--data
    tripDetailsData,
    //--other
    tripDetailsWindowStatus, setTripDetailsWindowStatus, datesInputType, selectedMonth_tripDetails, selectedWeek_tripDetails, setSelectedWeek_tripDetails, 
    //--track changes, and save them
    handleTripDetails_CloseWindow_SaveChanges,

    //!Functions
    handleSavedType, 
    //trip details
    handleMetaTripDetails, handleTripDetailsChange
  } = useTripOverviewPlanTrip()

  const[activePopup, setActivePopup] = useState();

  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
      setActivePopup(null);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };

  return (
    <div>
      {/* <button onClick={test}>test</button> */}

      <div className='conatiner_p-AddTripDetails_TO'>


        <div 
        className='container_p-heading-tripDetails-AddTripDetails_TO'
        >  

          <div className='heading-TripDetails-AddTripDetails_TO'>
            Trip Details
          </div>

          {
            !shareTripId &&
            <button 
            onClick={()=>{
              setTripDetailsWindowStatus(true)
              setActivePopup("trip_details")
              openPopup()
            }}
            className='button-edit-tripDetails-AddTripDetails_TO'
            > 
              <img 
                className='image-TripDetails-AddTripDetails_TO'
                src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/edit_icon_1.png'
                />
            </button>
          }

        </div>

        <div className='container_p-data-tripDetails-AddTripDetails_TO'>

          {/* {
            tripDetailsData && 
            <div>No Details Added</div>
          } */}
          
          {/*//! duration */}
          {
            tripDetailsData && 
            tripDetailsData.map((data, index) => {
            if(data.data_type === "duration"){
              return(
                <div className='container_p-Duration-AddTripDetails_TO'>

                  <div 
                  className='conatainer_t-1-Duration-AddTripDetails_TO'
                  > 
                    <div className='heading-1-Duration-AddTripDetails_TO'>
                      duration:
                    </div> 

                    <div className='heading-2-Duration-AddTripDetails_TO'>
                      {data.data_1} days
                    </div>

                    <div className='heading-3-Duration-AddTripDetails_TO'>
                      #{data.data_meta_info_1}
                    </div> 

                  </div>  

                  {/* <div
                  className='conatainer_t-2-Duration-AddTripDetails_TO'
                  >
                    <div className='heading-3-Duration-AddTripDetails_TO'>
                      #{data.data_meta_info_1}
                    </div>  
                  </div> */}

                </div>   
              )
            }
            })
          }  

          {/*//! Date  */}
          {
            tripDetailsData && 
            tripDetailsData.map((data, index) => {
            if(data.data_type === "date"){
              return(
                <div className='container_p-Duration-AddTripDetails_TO'>
                  <div 
                  className='conatainer_t-1-Duration-AddTripDetails_TO'
                  > 
                    <div className='heading-1-Duration-AddTripDetails_TO'>
                      date:
                    </div> 

                    {
                      data.data_type_2 === "date" &&
                      <div className='conatainer_t-1-Duration-AddTripDetails_TO'>
                        <div 
                        className='heading-3-Duration-AddTripDetails_TO'
                        >
                          starting on...
                        </div> 
                        <div
                        className='heading-2-Duration-AddTripDetails_TO'
                        >
                          {/* {data.data_1} */}
                          {new Date(data.data_1).toLocaleDateString('en-US', { 
                            weekday: 'short', 
                            month: 'short', 
                            year: 'numeric' 
                          })}
                        </div>     
                      </div>   
                    }

                    {
                      data.data_type_2 === "month_week" &&
                      <div className='conatainer_t-1-Duration-AddTripDetails_TO'>

                        <div 
                        className='container_t-monthWeek-AddTripDetails_TO'
                        >
                          <div  
                          className='heading-4-Duration-AddTripDetails_TO'
                          >
                            Month 
                          </div>  
                          <div
                          className='heading-5-Duration-AddTripDetails_TO'
                          >
                            {selectedMonth_tripDetails}
                          </div>

                          <div
                          className='heading-4-Duration-AddTripDetails_TO'
                          >
                            Week
                          </div>
                          <div
                          className='heading-5-Duration-AddTripDetails_TO'
                          >
                            {selectedWeek_tripDetails}
                          </div>      
                        </div>  

                        <div
                        className='heading-3-Duration-AddTripDetails_TO'
                        >
                          #rough idea
                        </div> 

                      </div>   
                    }

                    {
                      data.data_type_2 === "not_yet_decided" &&
                      <div>
                        Not Yet Decided
                      </div> 
                    }    

                  </div> 

                </div>   
              )
            }
            })
          }  

          {/*//! Budget  */}
          {
            tripDetailsData && 
            tripDetailsData.map((data, index) => {
            if(data.data_type === "budget"){
              return(
                <div key={index}
                className='container_p-Duration-AddTripDetails_TO'
                >
                  <div
                  className='conatainer_t-1-Duration-AddTripDetails_TO'
                  >  
                    <div
                    className='heading-1-Duration-AddTripDetails_TO'
                    >
                      budget:
                    </div> 

                    <div
                    className='heading-2-Duration-AddTripDetails_TO'
                    >
                      {data.data_2}
                    </div>

                    <div
                    className='heading-2-Duration-AddTripDetails_TO'
                    >
                      {data.data_1}
                    </div>

                    <div
                    className='heading-3-Duration-AddTripDetails_TO'
                    >
                      #{data.data_meta_info_1}
                    </div>
                  </div> 

                </div>   
              )
            }
            })
          }  

        </div>  

      </div>  

      {/*//! Popup  */}
      {
        activePopup === "trip_details" && 

        <div> 

          <div className='popup-background-1-AddTripDetails_TO'
            onClick={()=>{
              handleTripDetails_CloseWindow_SaveChanges()
              closePopup()
              }}>
          </div>

          <div className='popup_p-addNote-AddTripDetails_TO'>
            <div className='popup_s-addNote-AddTripDetails_TO'>

            <button
              onClick={()=>{
                handleTripDetails_CloseWindow_SaveChanges()
                closePopup()
                }}
              className='button-close-popup-AddTripDetails_TO'
            >
              <IoMdCloseCircle />
            </button>
              
            {/*//! Enter Duration */}
            {
              tripDetailsData && 
              tripDetailsData.map((durationData, index) => {
                if(durationData.data_type === "duration"){
                  return(
                  <div key={index}
                  className='conatiner_p-Duration-popup-AddTripDetails_TO'
                  >

                    <div
                    className='heading-1-popup-AddTripDetails_TO'
                    >
                      Enter Trip Duration
                    </div>   

                    <div
                    className='container_t-Duration-popup-AddTripDetails_TO'
                    >
                      <input type="number" 
                      value={durationData.data_1} 
                      placeholder='xx'
                      maxLength={2}
                      onChange={(event) => {
                        if(event.target.value > -1){
                        handleTripDetailsChange(event, "duration")
                        }
                      }}
                      className='input-2-popup-AddTripDetails_TO'
                      />  
                      
                      <div
                      className='text-1-popup-AddTripDetails_TO'
                      >
                        days
                      </div>   
                    </div>

                    {/*//* Meta Data  */}
                    <div>

                      {
                        durationData.data_1 && 
                        (
                        <div
                        className='container_t-MetaInfo-Duration-popup-AddTripDetails_TO'
                        >
                          <button 
                          onClick={()=>handleMetaTripDetails("confirmed", "duration")}
                          className={durationData.data_meta_info_1 === "confirmed" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                          >
                            confirmed
                          </button>

                          <button 
                          onClick={()=>handleMetaTripDetails("aprox", "duration")}
                          className={durationData.data_meta_info_1 === "aprox" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                          >
                            Aproxx
                          </button>

                          <button 
                          onClick={()=>handleMetaTripDetails("can_change", "duration")}
                          className={durationData.data_meta_info_1 === "can_change" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                          >
                            Can Change. (Planning For a future trip)
                          </button>
                        </div> 
                        )
                      }

                      {
                        // !durationData.data_1 && 
                        (
                          <button onClick={()=>handleMetaTripDetails("not_yet_decided", "duration")}
                          className={durationData.data_meta_info_1 === "not_yet_decided" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                          >
                            Not Yet Decided
                          </button>
                        )
                      }

                    </div> 

                  </div>

                  )
                }
              })
            }

            {/*//! Dates  */}
            {
              tripDetailsData &&
              tripDetailsData.map((durationData, index) => {
                if(durationData.data_type === "date"){
                  return(
                    <div key={index}
                    className='conatiner_p-Duration-popup-AddTripDetails_TO'
                    >

                      <div
                      className='heading-1-popup-AddTripDetails_TO'
                      >
                        When Status?
                      </div>   

                      <div style={{marginTop:'10px'}}>
                        <div>
                          <button onClick={()=>handleMetaTripDetails("date", "date")}
                          className={durationData.data_type_2 === "date" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                          >
                            Dates Decided
                          </button>
                        </div> 

                        <div>
                          <button onClick={()=>handleMetaTripDetails("month_week", "date")}
                          className={durationData.data_type_2 === "month_week" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                          >
                            I have a rough idea.
                          </button>
                        </div>   

                        <div>
                          <button onClick={()=>handleMetaTripDetails("not_yet_decided", "date")}
                          className={durationData.data_type_2 === "not_yet_decided" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                          >
                            Plannng a future trip. (dates not yet decided)
                          </button>
                        </div>   

                      </div>

<br></br>

                      <div>

                        {/* //* decided */}
                        {
                          datesInputType === "date" &&
                          <div 
                          className='conatainer_t-DateInputType-popup-AddTripDetails_TO'
                          >

                            <div
                            className='text-1-DateInputType-popup-AddTripDetails_TO'
                            >
                              starting on...
                            </div>  

                            <div>
                              <input
                                type="date"
                                value={durationData.data_1}
                                onChange={(event) => handleTripDetailsChange(event, "date")}
                                className='dateInput-1-popup-AddTripDetails_TO'
                              />
                            </div>

                          </div>
                        } 

                        {/* //* mayBeDecided */}
                        { datesInputType === "month_week" &&
                          <div
                          className='conatainer_t-DateInputType-popup-AddTripDetails_TO'
                          >

                            {/* //? */}
                            <div
                            className='text-1-DateInputType-popup-AddTripDetails_TO'
                            >
                              select the month
                            </div>  

                            <div>
                              <select
                                value={selectedMonth_tripDetails}
                                onChange={(event) => handleTripDetailsChange(event, "month_week")}
                                className='dropdown-1-popup-AddTripDetails_TO'
                              >
                                <option value="" disabled>
                                  Select the Month
                                </option>
                                {
                                  MonthsName.map((data, index) => {
                                    return(
                                      <option key={index} value={data}>
                                        {data}
                                      </option>
                                    )
                                  })
                                }
                              </select>
                            </div>  

                            {/* //? */}
                            <div
                            className='text-1-DateInputType-popup-AddTripDetails_TO'
                            >
                              select the week <i>(if relevant)</i>
                            </div>  

                            <div>
                              {
                                selectedMonth_tripDetails && 

                                <select
                                  value={selectedWeek_tripDetails}
                                  onChange={(event) => handleTripDetailsChange(event, "month_week", "dataType2")}
                                  className='dropdown-1-popup-AddTripDetails_TO'
                                  // disabled={!selectedMonth_tripDetails}
                                >
                                  <option value="" disabled>
                                    Select the Week
                                  </option>
                                  {
                                    Weeks.map((data, index) => {
                                      return(
                                          <option key={index} value={data}>
                                            {data}
                                          </option>
                                      )
                                    })
                                  }
                                </select>
                              }
                              
                            </div>  

                          </div>
                        }

                      </div>  

                    </div>
                  )
                }
              })
            }

            {/*//! Budget  */}   
            {
              tripDetailsData && 
              tripDetailsData.map((budgetData, index) => {
                if(budgetData.data_type === "budget"){
                  return(
                    <div key={index}
                    className='conatiner_p-Duration-popup-AddTripDetails_TO'
                    >

                      <div
                      className='heading-1-popup-AddTripDetails_TO'
                      >
                        Budget
                      </div> 

                      <div
                      className='conatiner_t-Duration_Date-popup-AddTripDetails_TO'
                      >

                        <div
                        className='container_t-Duration-popup-AddTripDetails_TO'
                        >
                          <input type="text" 
                          value={budgetData.data_1} 
                          placeholder='xx'
                          maxLength={9}
                          onChange={(event) => handleTripDetailsChange(event, "budget")}
                          className='input-1-popup-AddTripDetails_TO'
                          /> 
                        </div>

                        {/*//?Input Budget Currency */}
                        <div>
                          <select
                            value={budgetData.data_2}
                            onChange={(event)=>{
                              handleTripDetailsChange(event, "budget_currency")
                              // setSaveChangesStatus(true)
                            }}
                            className='select-popup-AddTripDetails_TO'
                          >
                            <option value={null} disabled>
                              Select
                            </option>

                            <option value="$"
                            className='option-popup-AddTripDetails_TO'
                            >
                              $
                            </option>

                            <option value="₹"
                            className='option-popup-AddTripDetails_TO'
                            >
                              ₹
                            </option>

                          </select>
                        </div>

                      </div>   
<br></br>
                      <div>

                        {
                          budgetData.data_1 && 
                          (
                          <div>
                            <button 
                            onClick={()=>handleMetaTripDetails("confirmed", "budget")}
                            className={budgetData.data_meta_info_1 === "confirmed" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                            >
                              confirmed
                            </button>

                            <button 
                            onClick={()=>handleMetaTripDetails("aprox", "budget")}
                            className={budgetData.data_meta_info_1 === "aprox" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                            >
                              Aproxx
                            </button>

                            <button 
                            onClick={()=>handleMetaTripDetails("can_change", "budget")}
                            className={budgetData.data_meta_info_1 === "can_change" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                            >
                              Can Change. (Planning For a future trip)
                            </button>
                          </div> 
                          )
                        }

                        {
                          (
                            <button 
                            onClick={()=>handleMetaTripDetails("not_yet_decided", "budget")}
                            className={budgetData.data_meta_info_1 === "not_yet_decided" ? 'button-active-popup-AddTripDetails_TO' : 'button-unActive-popup-AddTripDetails_TO'}
                            >
                              Not Yet Decided
                            </button>
                          )
                        }

                      </div>

                    </div>   
                  )
                }
              })
            }

           </div>
          </div> 

        </div>    
      }    

      
      

    </div>
  )
}

export default AddTripDetails_TO







  {/* //* notDecided */}
                        {/* { datesInputType === "notDecided" && data.data_type_2 === "notDecided" &&
                          <div>

                            <div>
                              select the months you think the trip could happen?
                            </div>  

                            <div>
                              <input
                                type="date"
                                value={durationData.data_1}
                                onChange={(event) => handleTripDetailsChange(event, "date", "start_date")}
                              />
                            </div>

                          </div>
                        } */}

                        {/* <div>

                            <div>
                              <button onClick={()=>handleMetaTripDetailsSave("confirmed", "date")}>
                                confirmed
                              </button>
                            </div>

                            <div>
                              <button onClick={()=>handleMetaTripDetailsSave("can_change", "date")}>
                                can change
                              </button>
                            </div>  
                        </div>   */}