import React, {useState, useEffect, useRef} from 'react';

//import css
import './Map_CI.scss';

//import react context 
import {useCreateItineraryPlanTrip} from '../../Manage/Context/CreateItinerary_Context';

//importing context form Internal Trip Overview
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip';


// importing map
import L from "leaflet";
import {MapContainer, Tooltip, TileLayer, Marker, Popup, useMapEvent, useMap, useMapEvents, circle, Circle, GeoJSON} from 'react-leaflet';


//importing map icons
import {active_tourist_places, hover_tourist_places, saved_tourist_places, map_pin_1, map_pin_2, map_pin_3, map_pin_1_s, place_1_l} from '../../../../Manage - Plan Trip/Icons/MapIcons';


function Map_CI() {

  // using react context
  const {
    tripDestinationName, destinationLat, destinationLng, destinationZoom
  } = useValidatePlanTrip()
  
  const {
     //!States
      //navigate
      activeViewSavedType, savedPlacesNavigation,
      //status
      trackStatus_Itinerary, trackStatus_SavedPlaces,

      //data
      itineraryData,

      //Map
      activePlace_MapData,
      lat_CI, lng_CI, zoom_CI,
      fitAllLocations, setFitAllLocations,
      hoverItineraryData, setHoverItineraryData,
      placeClicked_map, setPlaceClicked_map,

      //Itinerary
      setSavedItineraryData,

      //Saved Places
      savedPlacesData,
      
      //!Functions
      handleMap_HoverClick
      
  } = useCreateItineraryPlanTrip()


  const mapRef = useRef(null);

  //handle map - drag
  useEffect(() => {
    if (mapRef.current){
      mapRef.current.setView([destinationLat, destinationLng], destinationZoom);
    }
  }, [destinationLat, destinationLng, destinationZoom])

  // actionType, id, type, name, lat, lng

  const accessToken = 'pk.eyJ1IjoicmlzaGFiaDY5IiwiYSI6ImNseHU5dTNuMzBnaGYycXNkZTRhODUxaTIifQ.o7XiAXhwt0J2lk9cQfLRrw';


  // Custom icons based on index
  const customIcons = itineraryData.map((_, index) => L.divIcon({
    className: 'custom-marker-icon',
    html: `<div class='marker-icon-${index}'>${index}</div>`
  }));


  // locations
  const handleFitAllLocations = () => {
    const map = mapRef.current;
    if (map) {
      const allLocations = [
        ...savedPlacesData,
        ...itineraryData,
        { lat: destinationLat, lng: destinationLng }
      ].filter(loc => loc.lat && loc.lng);
  
      if (allLocations.length > 0) {
        const bounds = L.latLngBounds(allLocations.map(loc => [loc.lat, loc.lng]));
        map.fitBounds(bounds);
  
        // Calculate the ideal zoom level
        const idealZoom = map.getBoundsZoom(bounds);
        
        // Set the zoom level to be one level lower
        // map.setZoom(idealZoom - 1);
      }
    }
    setFitAllLocations(false);
    // closeAllPopups();
  };
  
  

  useEffect(() => {
    handleFitAllLocations(); 

  }, [fitAllLocations])


  //custum map image
  const getCustomIcon = (iconType, idPassed) => {

    let class_name;

    const url = handleImageLink(iconType);

    const activeStatus = hoverItineraryData.some(data=>data.id === idPassed);
    
    class_name = !activeStatus ? 'map-pin-unActive' : 'map-pin-active';

    if(!activeStatus){
      return new L.Icon({
        iconUrl: url,
        iconSize: [26, 26],
        iconAnchor: [13, 26],
        className: class_name, // Use className to apply CSS styles
      })
    }
    else{
      return new L.Icon({
        iconUrl: url,
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        // iconSize: [24, 24],
        // iconAnchor: [12, 24],
        className: class_name, // Use className to apply CSS styles
      });
    }
    
  }

  const handleImageLink = (type) => {
    let link;

    if(type === "Tourist Place"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/tourist_place_icon_1.png'
    }
    else if(type === "Hotel"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/hotel_icon_1.png'
    }
    else if(type === "Restaurant"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/restaurant_icon_3.png'
    }
    else if(type === "Bars/Pubs"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/bar_and_pub_icon_3.png'
    }
    else if(type === "Activity"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/activity_icon_3.png'
    }
    else if(type === "Amusement/Water Park"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/amusement_park_icon_3.png'
    }
    else if(type === "Shopping Mall"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/shopping_mall_icon_1.png'
    }
    else if(type === "Street Shopping"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/street_market_icon_3.png'
    }
    else if(type === "Rent Vehicle"){
      link = 'https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/rent_vehicle_4_icon_1.png'
    }

    return link;
  }


  return (
    <div className='MainMainConatiner-MapCI'>

      <div
      className='container_p-buttons-Map_Hotels'
      >

        <button
        onClick={()=>{handleFitAllLocations()}}
        className='button-fitAll-Map_Hotels'
        >
          Fit All
        </button>

        {/* <button
        onClick={()=>{handleLocateDestination()}}
        className='button-fitAll-Map_Hotels'
        >
          Destination
        </button>

        <button 
        onClick={()=>{closeAllPopups()}}
        className='button-fitAll-Map_Hotels'
        >Close All Popup</button> */}

      </div>

      <MapContainer
      className='MainMainConatiner-MapCI' 
      center = {[lat_CI, lng_CI]} 
      zoom={zoom_CI} 
      ref={mapRef}
      >

      {/* <TileLayer 
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' 
      /> */}
      
      <TileLayer
        url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${accessToken}`}
        attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
      />

        {/*//!destination */}
        {
          destinationLat && destinationLng &&
          (
            <Marker
            position={[destinationLat, destinationLng]}
            zoom={destinationZoom}
            icon={map_pin_1_s} 
            >
              <Tooltip 
              direction="bottom" 
              permanent className='toolTip-destinationName'
              >
                  {tripDestinationName} 
              </Tooltip>       
            </Marker>
          )
        }

        {/*//!hover and active */}
        { activePlace_MapData &&
          activePlace_MapData.map((data, index) => {
            return(
              <Marker
              position={[(data.lat), (data.lng)]} 
              zoom={6}
              icon={map_pin_2}
              >

                <Popup style={{width:"1000%"}} closeOnEscapeKey={false}>
                    {data.name}
                </Popup>

              </Marker>
            )
          })
        }

        {/*//! Saved Tourist Places  */}
        { savedPlacesData && 
          savedPlacesData.map((data, index) => {
            if (!itineraryData.some(item => data.lat === item.lat && data.lng === item.lng)) {
              return(
                <Marker key={index}
                position={[(data.lat), (data.lng)]} 
                zoom={6}
                icon={place_1_l}
                >
                  <Popup style={{width:"1000%"}} closeOnEscapeKey={false}>
                    {data.name}
                  </Popup>

                </Marker>
              )
            }
          })
        }


        {/*!!  */}
        { itineraryData &&
          itineraryData.map((data, index) => {
          if(data.lat && data.lng){
            return(
              <Marker key={index}
              position={[(data.lat), (data.lng)]} 
              zoom={6}
              // icon={customIcons[index]}
              icon={getCustomIcon(data.display_name, data.topic_id)}
              // icon={IconWrapper(map_pin_1)}
              >

                <Tooltip 
                direction="right" 
                permanent 
                className='customIcon-ToolTip-active'     
                >
                  {index}
                </Tooltip>  

                <Popup style={{width:"1000%"}} closeOnEscapeKey={false}>
                  {data.name.split(',')[0]}
                </Popup>

              </Marker>
            )
          }})
        }

      </MapContainer>
    </div>
  )
}

export default Map_CI