import React from 'react'

function AdventureActivities() {
  return (
    <div>
      {/* AdventureActivities */}

      <div
      className='conatiner_p-notLogin-CreateItinerary'
      > 

        <button
        className='button-heading-1-CreateItinerary'
        style={{padding:'6px', borderRadius:'8px'}}
        >
            <div
            className='heading-login-PlanTripHeader'
            >
                Adventure Activities
            </div>    

            <div
            className='container_t-login-PlanTripHeader'
            >
              Comming Soon...
            </div>
        </button>

        <img 
        src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/create_itinerary_5.jpeg'
        className='image-notLogin-CreateItinerary'
        />
    
      </div> 

    </div>
  )
}

export default AdventureActivities