import React from 'react'

//import css
import './Ask_Questions.scss';

//importing react context
import { useAiCreateItinerary } from '../../../Create Itinerary - Result/Manage/Context/Ai_CreateItineraryContext';

//import icons
import { IoMdCloseCircle } from "react-icons/io";
import { BsStars } from "react-icons/bs";

//
import ReactMarkdown from 'react-markdown';

function Ask_Questions() {

  const {
    //
    destinationName, 
    
    //*Ask Questions
    array_AskQ, setArray_AskQ,
    activeQuestion, setActiveQuestion,
    activeResponseData, setActiveResponseData,
    activeDisplayQuestion, setActiveDisplayQuestion,
    activeResponseData_array, setActiveResponseData_array, 
    askQuestionInput, setAskQuestionInput,
    activeQuestionText, setActiveQuestionText,
    question_1, setQuestion_1,
    question_2, setQuestion_2,
    questionAskedCount, setQuestionAskedCount,
    question1_Data, setQuestion1_Data,
    question2_Data, setQuestion2_Data,
    answerData_question,
    //loading
    loadingType, setLoadingType,
    //
    aboutDestinationData,
    mustKnowData,
        //questions
        about_destination_question,
        must_know_things_question,
        history_question,
        attraction_question,
        climate_question,
    
    //!Functions
    //*Ask Questions
    handleSubmit_AskQuestion, 
    handleLoadAnswer_AboutDestination, 
    handleLoadAnswer_MustKnow,

  } = useAiCreateItinerary()

  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
      setAskQuestionInput(false);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };

  
  return (
    <div>

      {/*//* Questions */}
      <div
      className='container_p-Questions-AskQuestions'
      >

        {/* about_destination_question */}
        {/*//? q1 */}
        <div>
          <button
            onClick={()=>{
              setActiveQuestion("about_destination")
              if(aboutDestinationData){
                setArray_AskQ(false)
                setActiveDisplayQuestion(about_destination_question)
                setActiveResponseData(aboutDestinationData[0].about_destination)
              }else{
                handleLoadAnswer_AboutDestination("about_destination", about_destination_question)
              }
            }}
            className={activeQuestion === "about_destination" ? 'text-active-Question-AskQuestions' : 'text-unActive-Question-AskQuestions'}
          >
            {about_destination_question} ?
          </button>
        </div>


        {/* must_know_things_question */}
        {/*//? q2 */}
        <div>
          <button
            onClick={()=>{
              setActiveQuestion("must_know_things")
              if(mustKnowData){
                setArray_AskQ(true)
                setActiveDisplayQuestion(must_know_things_question)
                setActiveResponseData_array(mustKnowData)
              }else{
                handleLoadAnswer_MustKnow("must_know_things", must_know_things_question)
              }
            }}
            className={activeQuestion === "must_know_things" ? 'text-active-Question-AskQuestions' : 'text-unActive-Question-AskQuestions'} 
          >
            {must_know_things_question} ?
          </button>
        </div>


        {/* history_question */}
        {/*//? q3 */}
        <div>
          <button
            onClick={()=>{
              setActiveQuestion("history")
              if(aboutDestinationData){
                setArray_AskQ(false)
                setActiveDisplayQuestion(history_question)
                setActiveResponseData(aboutDestinationData[0].history)
              }else{
                handleLoadAnswer_AboutDestination("history", history_question)
              }
            }}
            className={activeQuestion === "history" ? 'text-active-Question-AskQuestions' : 'text-unActive-Question-AskQuestions'}
          >
            {history_question} ?
          </button>
        </div>


        {/* attraction_question */}
        {/*//? q4 */}
        <div>
          <button
            onClick={()=>{
              setActiveQuestion("attraction")
              if(aboutDestinationData){
                setArray_AskQ(false)
                setActiveDisplayQuestion(attraction_question)
                setActiveResponseData(aboutDestinationData[0].attraction)
              }else{
                handleLoadAnswer_AboutDestination("attraction", attraction_question)
              }
            }}
            className={activeQuestion === "attraction" ? 'text-active-Question-AskQuestions' : 'text-unActive-Question-AskQuestions'}
          >
            {attraction_question} ?
          </button>
        </div>


        {/* climate_question */}
        {/*//? q5 */}
        <div>
          <button
            onClick={()=>{
              setActiveQuestion("climate")
              if(aboutDestinationData){
                setArray_AskQ(false)
                setActiveDisplayQuestion(climate_question)
                setActiveResponseData(aboutDestinationData[0].climate)
              }else{
                handleLoadAnswer_AboutDestination("climate", climate_question)
              }
            }}
            className={activeQuestion === "climate" ? 'text-active-Question-AskQuestions' : 'text-unActive-Question-AskQuestions'}
          >
            {climate_question} ?
          </button>
        </div>


        {/*//? question_1 */}
        { question1_Data &&
          <div>
            <button
              onClick={()=>{
                setActiveQuestion("question_1")
                setArray_AskQ(false)
                setActiveDisplayQuestion(question_1)
                setActiveResponseData(question1_Data)
              }}
              className={activeQuestion === "question_1" ? 'text-active-Question-AskQuestions' : 'text-unActive-Question-AskQuestions'}
              >
                <BsStars /> &nbsp;

                {question_1.length > 100 ? question_1.slice(0, 100) + '...' : question_1} 
            </button>
          </div>
        }

        {/*//? question_2 */}
        { question2_Data &&
          <div>
            <button
              onClick={()=>{
                setActiveQuestion("question_2")
                setArray_AskQ(false)
                setActiveDisplayQuestion(question_2)
                setActiveResponseData(question2_Data)
              }}
              className={activeQuestion === "question_2" ? 'text-active-Question-AskQuestions' : 'text-unActive-Question-AskQuestions'}
              >
                <BsStars /> &nbsp;
              {question_2.length > 100 ? question_2.slice(0, 100) + '...' : question_2}
            </button>
          </div>
        }

      </div>

      <div>  
        {/*//!Ask Question */}
        {/* Ask Button */}
        <div
        className='conatiner_s-button-askQuestion-AskQuestions'
        >
          <button 
              onClick={()=>{
              setAskQuestionInput(true)
               openPopup()
              }}
              disabled={questionAskedCount===2}
              className='button-askQuestion-AskQuestions'
          >
           
              Ask Question +  {questionAskedCount}/2
           
          </button>
        </div>

        {/* Input Question */}
        {
          (
          askQuestionInput && (!question_1 || !question_2) 
          ) &&  
          <div >

            <div className='popup-background-1-Entities_TD'
            onClick={()=>closePopup()}>
            </div>

            <div className='popup_p-3-Entities_TO_Popup'>
              <div className='popup_s-addNote-Entities_TO_Popup'>


                <div
                className="create-itinerary-ai"
                >

                  <div
                  className='text-1-askQuestion-AskQuestions'
                  >
                    Type Your Question
                  </div>
                  <div>
                    <textarea 
                    type='text'
                    value={activeQuestionText}
                    maxLength={200}   
                    onChange={(event)=>{setActiveQuestionText(event.target.value)}}    
                    className='input-askQuestion-AskQuestions'
                    placeholder='type in you question...'
                    />
                  </div>

                  {
                    activeQuestionText &&
                    <div style={{color:'lightgray'}}>
                      {activeQuestionText.length}/200
                    </div>
                  }

                  {
                    askQuestionInput && activeQuestionText && 
                    <div>
                      <button 
                      onClick={()=>{
                        handleSubmit_AskQuestion(activeQuestionText)
                        setActiveQuestionText()
                        closePopup()
                      }}
                      className='button-save-AskQuestions'
                      >
                        Submit Question
                      </button>
                    </div>
                  }

                 
                  <button
                  className='button-close-AskQuestions'
                  onClick={()=>{closePopup()}}
                  >
                    <IoMdCloseCircle />
                  </button>
                    
                </div>

              </div> 
            
            </div>  
            
          </div> 

        }

        {/* Submit Question */}
       

      </div>

      {
        loadingType === "ai_question" &&
        <div
        className='text-1-answer-AskQuestions'
        >
          Answering your question...
        </div>
      }


      {/*//! Response/Answer */}
      <div className='conatiner_p-answer-Ask_Questions'>

        {
          !array_AskQ && activeResponseData &&
          <div
          className='conatiner_s-asnwer-Ask_Questions'
          >

              <div
              className='text-1-answer-AskQuestions'
              >
                <b>Q:</b> {activeDisplayQuestion} ?
              </div>

              <div
                className='content-1-answer-AskQuestions'
              >
                <ReactMarkdown>
                  {activeResponseData}
                </ReactMarkdown>
              </div> 

          </div> 
        }

        {
          array_AskQ && activeResponseData_array.length > 0 &&
          <div>

              <div
              className='text-1-answer-AskQuestions'
              >
                <b>Q:</b> {activeDisplayQuestion} ?
              </div>

              <div
              className='content-1-answer-AskQuestions'
              >
                {
                  activeResponseData_array.map((data, index) => {
                    return(
                      <div key={index}>
                        {
                          data.heading &&
                          <div>
                            <b>{data.heading}</b>
                          </div> 
                        }
                        <div>
                          <div dangerouslySetInnerHTML={{ __html: data.content }} 
                          />
                        </div> 

                        {/* <div
                          className='content-1-answer-AskQuestions'
                        >
                          <ReactMarkdown>
                            {data.content}
                          </ReactMarkdown>
                        </div>  */}

                        <br></br>
                        
                      </div>   
                    )
                  })
                }

              </div> 

            </div>  
        }


      </div>

      <div style={{height:'200px'}}>

      </div>


    </div>
  )
}

export default Ask_Questions
