import React, { useEffect, useState, useRef } from 'react'

//import css
import './TouristPlaces_VM.scss';
import './TouristPlaces_VM_S_Mobile.scss';

//import icons
import { IoMdCloseCircle } from "react-icons/io";

//
import { Rating } from 'react-simple-star-rating';
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

//
import ReactMarkdown from 'react-markdown';
import Overview_VM from './Components/Overview_VM';
import About_VM from './Components/About_VM';
import Review_VM from './Components/Review_VM';
import History_VM from './Components/History_VM';

function TouristPlaces_VM(props) {

  const {
    viewMoreData_touristPlaces, 
    viewMoreData_About,
    viewMoreData_Review,
    viewMoreData_PlaceInfo,
    activeId_viewMore_touristPlaces, setActiveId_viewMore_touristPlaces, 
    stateName,
    //!depricated
    //handle_UnSave_Places, handle_Save_Places, saved_touristPlaces_data, 
    // closePopup
  } = props;

  const [active_innerNavigation_viewMore, setActive_innerNavigation_viewMore] = useState("Overview")


  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -500, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 500, behavior: 'smooth' })
    }
  };

  const containerRef = useRef(null);


  //!not working
  const scrollToContainer = () => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }
  };


  const modified_state_name = stateName && stateName.toLowerCase().replace(/\s+/g, '_');
    

  const test = () => {
    // console.log("viewMoreData_PlaceInfo", viewMoreData_PlaceInfo)
  }


  return (
    <div 
    // className='mainContainer-TouristPlaces_VM'
    >
    
      {/* <div className='popupOverlay-TouristPlaces_VM'></div> */}

      <div className='popup-background-1-TouristPlaces_VM'
        onClick={()=>{
          setActiveId_viewMore_touristPlaces(false)
        }}
      >
      </div>

      <div
       className='popup_p-addNote-TouristPlaces_VM'
      >
      <div
      className='popup_s-addNote-TouristPlaces_VM'
      ref={containerRef}
      >


      { (activeId_viewMore_touristPlaces && viewMoreData_touristPlaces) &&
        viewMoreData_touristPlaces.map((data, index) => {
          if(data.id === activeId_viewMore_touristPlaces){
          // if(data.id === 14){
            return(
              <div
              key={index} 
              // className='popup_s-addNote-TouristPlaces_VM'
              style={{alignContent:'center', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'column'}}
              > 

              <button
                onClick={()=>{
                  setActiveId_viewMore_touristPlaces(false)
                }}
                className='button-close-popup-TouristPlaces_VM'
              >
                <IoMdCloseCircle />
              </button>
              

              {/* {data.name} */}
              {
                viewMoreData_PlaceInfo.map((placeData, index) => {
                  if(placeData.id === activeId_viewMore_touristPlaces){
                    return(
                    <div className="container_t-heading-TouristPlaces_VM">

                    <div
                    className='container_t-placeName-TouristPlaces_VM'
                    >  

                      <div
                      className='heading-1-heading-TouristPlaces_VM'
                      >
                        {placeData.name}
                      </div>   

                      <div
                      className='container_p-ratings-TouristPlaces_VM'
                      >

                        <div 
                        className='heading-rating-heading-TouristPlaces_VM'
                        >
                            <Rating
                              initialValue={placeData.rating}
                              size={18}
                              emptyColor="#ccc"
                              fillColor="gold"
                              strokeColor="gold"
                              strokeWidth={0}
                              allowFraction={true}
                              readonly
                            />  
                        </div>

                        <div className='heading-2-heading-TouristPlaces_VM'>
                        ({parseFloat(placeData.rating).toFixed(1)}) 
                        </div>
                      
                        <div className='heading-3-heading-TouristPlaces_VM'>
                          {
                            placeData.review_count ? Number(placeData.review_count).toLocaleString('en-IN') : 'N/A'
                          }
                        </div>

                      </div>

                    </div>      

                    </div> 
                    )
                  }
                })
              }  


              {/*//!Images  */}
              <div
              className='container_p-images-TouristPlaces_VM'
              >
                <button
                className='scroll-images-TouristPlaces_VM'
                onClick={scrollLeft}
                >
                  <FaChevronLeft />
                </button>  

                {/* {`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${activeId_viewMore_touristPlaces}-1.jpg`} */}

                <div 
                className='container_s-1-images-TouristPlaces_VM'
                >
                  <div
                  className='container_s-2-images-TouristPlaces_VM'
                  ref={scrollContainerRef}
                  >
                    <img
                    className='image-images-TouristPlaces_VM'
                    // src='https://plus.unsplash.com/premium_photo-1676218968741-8179dd7e533f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                    src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${activeId_viewMore_touristPlaces}-01.jpg`}
                    onError={(e) => { e.target.src = 'https://plus.unsplash.com/premium_photo-1676218968741-8179dd7e533f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'; }}
                    />

                    <img
                    className='image-images-TouristPlaces_VM'
                    // src='https://images.unsplash.com/photo-1506905925346-21bda4d32df4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                    src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${activeId_viewMore_touristPlaces}-02.jpg`}
                    onError={(e) => { e.target.style.display = 'none'; }}
                    />

                    <img
                    className='image-images-TouristPlaces_VM'
                    // src='https://images.unsplash.com/photo-1505299916137-b69793a66907?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                    src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${activeId_viewMore_touristPlaces}-03.jpg`}
                    onError={(e) => { e.target.style.display = 'none'; }}
                    />

                    <img
                    className='image-images-TouristPlaces_VM'
                    // src='https://images.unsplash.com/photo-1497846498055-4f8b3f1a9b81?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                    src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${activeId_viewMore_touristPlaces}-04.jpg`}
                    onError={(e) => { e.target.style.display = 'none'; }}
                    />

                    <img
                    className='image-images-TouristPlaces_VM'
                    // src='https://images.unsplash.com/photo-1504518391227-a907cb2876a7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
                    src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${activeId_viewMore_touristPlaces}-05.jpg`}
                    onError={(e) => { e.target.style.display = 'none'; }}
                    />
                  </div> 
                </div>

                <button
                className='scroll-images-TouristPlaces_VM'
                onClick={scrollRight}
                >
                  <FaChevronRight />
                </button>  

              </div>

      

              <div
              className='container_p-Navigation-TouristPlaces_VM'
              >

                <button 
                onClick={()=>{
                  setActive_innerNavigation_viewMore("Overview")
                  // scrollToContainer()
                }}
                className={active_innerNavigation_viewMore === "Overview" ? 'button-active-Navigation-TouristPlaces_VM' : 'button-unActive-Navigation-TouristPlaces_VM'}
                >
                  Overview
                </button>

                <button 
                onClick={()=>{setActive_innerNavigation_viewMore("About")}}
                className={active_innerNavigation_viewMore === "About" ? 'button-active-Navigation-TouristPlaces_VM' : 'button-unActive-Navigation-TouristPlaces_VM'}
                >
                  About
                </button>

                <button 
                onClick={()=>{setActive_innerNavigation_viewMore("History")}}
                className={active_innerNavigation_viewMore === "History" ? 'button-active-Navigation-TouristPlaces_VM' : 'button-unActive-Navigation-TouristPlaces_VM'}
                >
                  History
                </button>

                <button 
                onClick={()=>{setActive_innerNavigation_viewMore("Reviews")}}
                className={active_innerNavigation_viewMore === "Reviews" ? 'button-active-Navigation-TouristPlaces_VM' : 'button-unActive-Navigation-TouristPlaces_VM'}
                >
                  Reviews
                </button>

              </div>  

          
              <div
              className='conatiner_p-components-TouristPlaces_VM'
              >
                <div
                className='conatiner_s-components-TouristPlaces_VM'
                > 

                  { 
                  active_innerNavigation_viewMore === "Overview" && 

                    <Overview_VM 
                    viewMoreData_PlaceInfo={viewMoreData_PlaceInfo}
                    viewMoreData_About={viewMoreData_About}
                    activeId_viewMore_touristPlaces={activeId_viewMore_touristPlaces}
                    />
                    
                  }  
                

                  {
                    active_innerNavigation_viewMore === "About" && 

                    <About_VM
                      aboutData_Props={data.about_conclusion_data}
                    />
                  }


                  {
                    active_innerNavigation_viewMore === "History" && 
                    (
                      <History_VM
                      historyData_Props={data.history_data}
                      />
                    )
                  }

                  {
                    active_innerNavigation_viewMore === "Reviews" && 
                    (
                      <Review_VM
                      viewMoreData_PlaceInfo={viewMoreData_PlaceInfo}
                      activeId_viewMore_touristPlaces={activeId_viewMore_touristPlaces}
                      viewMoreData_Review={viewMoreData_Review}
                      viewMoreData_About={viewMoreData_About}
                      />  
                    )
                  }

                  </div>

                </div> 

              </div>
            )
          }
        })
      }
      </div>
      </div>
    
    </div>
  )
}

export default TouristPlaces_VM




//! Bookmark
{/* save/unsave places */}
// { saved_touristPlaces_data &&
//   data.sn === saved_touristPlaces_data.filter(savedPlacesData => savedPlacesData === data.sn) 
//     ?
//   (
//     <button className='PlaceCardsBookmarkDiv-PlaceCardsCss02' onClick={(e) => {
//       e.stopPropagation(); // Prevent the click event from reaching the parent
//       handle_Save_Places(data.sn, data.explore_duration, data.name, data.lat, data.lng);
//     }}>
//       <img src="https://i.ibb.co/VC5LGZb/save-bookmark.png" alt="Image 1" className='PlaceCardsBookmark-PlaceCardsCss02'/> 
//     </button> 
//   )
//   :
//   (
//     <button className='PlaceCardsBookmarkDiv-PlaceCardsCss02' onClick={(e) => {
//       e.stopPropagation(); // Prevent the click event from reaching the parent
//       handle_UnSave_Places(data.sn);
//     }}>
//       <img src="https://i.ibb.co/fr32spL/bookmark.png" alt="Image 1" className='PlaceCardsBookmark-PlaceCardsCss02'/>
//     </button>   
//   )
// }
