import React, {useState, useEffect, useRef} from 'react'

//import css
import './Map_Ai_Itinerary.css';

//importing react context
import { useAiCreateItinerary } from '../../../Create Itinerary - Result/Manage/Context/Ai_CreateItineraryContext';

// importing map
import {MapContainer, Tooltip, TileLayer, Marker, Popup, useMapEvent, useMap, useMapEvents, circle, Circle, Polyline} from 'react-leaflet'
import L from "leaflet";

//!delete
// import { defaultMarker, defaultMarker3 } from '../../../../../../../Plan/ActualPlanning/defaultMarker'

// importing icons 
import {
  // active_tourist_places, hover_tourist_places, saved_tourist_places
  airport_dead_1, airport_normal_1, airport_normal_2, airport_active_1,
  train_dead_1, train_normal_1, train_normal_2, train_active_1, 
  bus_dead_1, bus_normal_1, bus_normal_2, bus_active_1, 
  map_pin_1, map_pin_2, map_pin_3, airport_normal_Ai_Reach, train_normal_Ai_Reach, bus_normal_Ai_Reach
} from '../../../../../Plan Trip/Manage - Plan Trip/Icons/MapIcons'


function Map_Ai_Itinerary() {
  
  const {
    //*
    destinationName, savedPlacesData, activeNavigationCategory_ItineraryResult,
    //*Map
    destinationLat, destinationLng,
    destinationZoom, setDestinationZoom,
    lat, lng, zoom, setZoom, 
    hoverLocationData_map, activeLocationData_map, 
    //reach
    airportData_reach,
    railwayData_reach,
    busData_reach,
    //route
    optimizedItinerary, 
    optimizedItineraryRoute,
    //hover data
    hoverData
  } = useAiCreateItinerary()
  
  
  const mapRef = useRef(null);

  //handle map - drag
  useEffect(() => {
    if (mapRef.current){
      mapRef.current.setView([lat, lng], zoom);
    }
  }, [lat, lng, zoom])


  const GradientPolyline2 = ({ coordinates }) => {
    const borderColor = '#FFFFFF'; // Dark blue border color
    const interiorColor = '#408FFF'; // Light blue interior color

    return (
        <>
            {/* Dark blue border */}
            <Polyline
                positions={coordinates}
                weight={5} // Thickness for the border
                color={borderColor}
                lineJoin="round"
                lineCap="round"
            />
            {/* Light blue interior */}
            <Polyline
                positions={coordinates}
                weight={4} // Thickness for the interior
                color={interiorColor}
                lineJoin="round"
                lineCap="round"
            />
        </>
    );
  };


  
  const handleHoverIcon = (passedTravelMode) => {

    let passActive;
  
    if(passedTravelMode === "air"){
      passActive = airport_normal_Ai_Reach;
    }
    else if(passedTravelMode === "bus"){
      passActive = bus_normal_Ai_Reach;
    }
    else if(passedTravelMode === "train"){
      passActive = train_normal_Ai_Reach;
    }
  
    return passActive;
  }
  

  // const customIconDistrict_2 = L.divIcon({
  //   className: 'custom-iconDD-MapAiItinerary',
  //   html: `<div class="number-icon-MapAiItinerary_2"></div>`,
  //   iconSize: [30, 30],
  //   iconAnchor: [1s5, 30]
  // });

  const customIconDistrict_2 = L.divIcon({
    className: 'custom-iconDD-MapAiItinerary',
    html: `<div class="number-icon-MapAiItinerary">${hoverData &&hoverData.id+1}</div>`,
    iconSize: [32, 32],
    iconAnchor: [16, 32]
  });



  return (
    <div
    className='conatiner_p-map-Map_Hotels'
    >
    {/* <button onClick={testMap}><b>Test Map</b></button> */}
    {/* {
      lat && lng && */}

      <MapContainer  
        className="conatiner_p-map-Map_Hotels" 
        center = {[lat, lng]} 
        zoom={zoom} 
        ref={mapRef}
      > 

      <TileLayer 
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' 
      />
      

      {/*//! destination */}
      {
        
        destinationLat && destinationLng &&
        (
          <Marker
          position={[destinationLat, destinationLng]}
          zoom={destinationZoom}
              //! delete
          icon={map_pin_1} 
          >
              <Tooltip 
              direction="bottom" 
              permanent className='hoverToolTipOverall'
              >
                  {destinationName}
              </Tooltip>       
          </Marker>
        )
      }

      
      {/*//! hover */}
      {
        (hoverData && hoverData.lat && hoverData.lng) &&
        (
            <Marker
            position={[hoverData.lat, hoverData.lng]}
            zoom={zoom}
            icon={customIconDistrict_2} 
            
            >
                <Tooltip 
                direction="bottom" 
                permanent className='hoverToolTipOverall'
                >
                  <div
                  className='hoverToolTipOverall'
                  >
                    {hoverData.name}
                  </div>
                </Tooltip>       
            </Marker>
        )
      }

      {/*//!OptimizedItinerary */}
      {
        savedPlacesData && optimizedItinerary && optimizedItinerary.length > 0 &&

        (
          optimizedItinerary.map((data, index) => {
            
            const customIconDistrict = L.divIcon({
              className: 'custom-iconDD-MapAiItinerary',
              html: `<div class="number-icon-MapAiItinerary">${index + 1}</div>`,
              iconSize: [24, 24],
              iconAnchor: [12, 24],
              popupAnchor: [0, -30]
            });

            return(
              
              
            savedPlacesData.map((placeData, index2) => {
              if(data.sq_source === placeData.sn){
                return(
                      <Marker position={[(placeData.lat), (placeData.lng)]}
                      icon={customIconDistrict} >

                        <Popup>
                          {placeData.name}
                        </Popup>
                      
                        <Tooltip direction="bottom" permanent>
                           Day {data.day}
                        </Tooltip> 
                      
                      </Marker>
                    )
              }
            })
            
            )

          })
        )
      }

        {/*//* Display Route */}
        {optimizedItineraryRoute && optimizedItineraryRoute.map(data => {
        const processCoordinates = (coordinates) => {
            try {
                return (coordinates).map(coord => [coord[1], coord[0]]);
            } catch (error) {
                console.error('Error parsing coordinates:', error);
                return null;
            }
        };

        const processedCoordinates = processCoordinates(data.crd2 ? data.crd2 : data.crd1);

          // Log the processed coordinates and the index
          // // console.log(`Processing item ${index}:`, data);
          // console.log(`Processed coordinates:`, processedCoordinates);

          return processedCoordinates && (

              <GradientPolyline2 coordinates={processedCoordinates} />

          );
        })}


        {/*//!How to reach */}

        {/* //* Air */}
        {
          activeNavigationCategory_ItineraryResult === "how_to_reach" && airportData_reach && 
          (
            airportData_reach.map((data, index) => {
              return(
              <Marker
                key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={airport_dead_1}
              >

                <Popup>
                  {data.mode_name} ({data.mode_code}) 
                </Popup>

              </Marker>
              )
            })
          )
        }

        {/* //* Train */}
        {
          activeNavigationCategory_ItineraryResult === "how_to_reach" && railwayData_reach && 
          (
            railwayData_reach.map((data, index) => {
              return(
              <Marker
                key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={train_dead_1}
              >
                <Popup>
                  {data.mode_name} ({data.mode_code}), {data.mode_state_name}
                </Popup>

              </Marker>
              )
            })
          )
        }

        {/* //* Bus */}
        {
          activeNavigationCategory_ItineraryResult === "how_to_reach" && busData_reach && 
          (
            busData_reach.map((data, index) => {
              return(
              <Marker
                key={index}
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={bus_dead_1}
              >

                <Popup>
                  {data.mode_name} ({data.mode_code}), {data.mode_state_name}
                </Popup>

              </Marker>
              )
            })
          )
        }

        //* hover location 
        { hoverLocationData_map.length > 0 &&
          hoverLocationData_map.map((data, index) => {

            const customIconDistrict = L.divIcon({
              className: 'custom-iconDD-MapAiItinerary',
              html: `<div class="number-icon-MapAiItinerary">${index + 1}</div>`,
              iconSize: [30, 30],
              iconAnchor: [15, 30]
            });

            return(
              <Marker
                position={[(data.lat), (data.lng)]}
                zoom={zoom}
                icon={handleHoverIcon(data.travel_mode)}
                // icon={customIconDistrict}
              >
                <Tooltip 
                  direction="bottom" permanent
                  className='hoverToolTipOverall'
                >
                  {data.name}   
                </Tooltip>

              </Marker>
            )
          })
        }

      </MapContainer>

    {/* } */}

    </div>
  )
}

export default Map_Ai_Itinerary