import React, { useState, useEffect } from 'react'

//import css
import './Search_Destinations_HTR.scss'

import axios from 'axios';

import { Helmet } from 'react-helmet';

//import icons
import { IoMdCloseCircle } from "react-icons/io";

import { server_baseUrl } from '../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';


function Search_Destinations_HTR(props) {

  const {handleSearchResultClick} = props;

  // useEffect(() => {
  //   handleSearch
  // }, [])

  const [userInput, setUserInput] = useState()
  const [inputResult, setInputResult] = useState([]) 
  //the result contains these things: name, type, lat, lng, id


  const handleUserInput = async (event) => {
    const inputValue = event.target.value;
    
    //set the user input
    setUserInput(event.target.value)

    if(inputValue){

      const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/manage_ai_components/get_destination_name/all`, {inputValue})
      
      // console.log("handleUserInput", response.data);
      
      if(response.data.length > 0){
        setInputResult([...response.data])
      }else{
        setInputResult([])
      }
    }
    else{
      setInputResult([])
    }
  }

  return (
    <div
    style={{position:'relative'}}
    >

<Helmet>   

<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Assistant:wght@400&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap" rel="stylesheet" />

</Helmet>

      <div
       className='container_p-input-Search_Locations'
      >
        <input 
          placeholder='Shimla, Goa, Delhi...' 
          value={userInput}
          onChange={handleUserInput}  
          className='input-inputDate-Search_Destinations_HTR_Mobile' 
        />
      </div>

      { inputResult && inputResult.length > 0 &&
        <div
        className='conatiner_p-searchOptions-Search_Destinations_HTR'

        >
          <div
          className='conatiner_s-searchOptions-Search_Destinations_HTR'
          >
              <button 
              onClick={(event)=>{
              setInputResult()
              event.stopPropagation()
              }}
              className='button-close-popup-Search_Destinations_HTR'
              >
                <IoMdCloseCircle />
              </button>

            {
            inputResult.map((data, index) => {
              return(
                // <div
                // className='conatiner_t-searchOptions-Search_Destinations_HTR'
                // >
                  // {/* data tyepe = city/district/state */}
                  <button 
                  onClick={()=>{
                    handleSearchResultClick(data.name, data.type, data.lat, data.lng, data.stateName)
                    setInputResult([])
                    setUserInput(data.name)
                  }}
                  className='button-searchOptions-Search_Destinations_HTR'
                  >
                    <div
                    className='container_t-texts-searchOptions-Search_Destinations_HTR'
                    >

                      <div
                      className='text-1-searchOptions-Search_Destinations_HTR'
                      >
                        {data.name}
                      </div>

                      {
                        data.type &&
                        <div
                        className='text-2-searchOptions-Search_Destinations_HTR'
                        >
                        <i> a {data.type} </i>
                        </div>
                      }

                      {data.type !== "state" && data.stateName &&
                      <div
                      className='text-3-searchOptions-Search_Destinations_HTR'
                      >
                        in {data.stateName}
                      </div> 
                      }

                    </div>

                  </button>
                // </div>
              )
            })
            }
          </div>
        </div>
      }

    </div>
  )
}

export default Search_Destinations_HTR