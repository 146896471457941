import React from 'react'

import { useParams } from 'react-router-dom';

//import css
import './SavedPlaces_TO.scss' 

//importing context form Internal Trip Overview
import {useTripOverview_2_PlanTrip} from '../../Manage/Context/TripOverview_2_Context'

//global
import { useGlobalNavigationPlanTrip } from '../../../../Manage - Plan Trip/Context/GlobalNavigationPlanTrip';
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip';


function SavedPlaces_TO(props) {
  // const{stateName} = useParams();

  const {stateName} = useValidatePlanTrip();

  
  const{shareTripId} = props;
  
  const {
    savedPlacesData, placesInfo,
    //
    activePlace_MapData, setActivePlace_MapData, 
    //
    setFitAllLocations,
  } = useTripOverview_2_PlanTrip()


  const {
    //view more channel
    channel_viewMore_id, setChannel_viewMore_id
  } = useGlobalNavigationPlanTrip()

  const modified_state_name = stateName ? stateName.toLowerCase().replace(/\s+/g, '_') : 'Himachal_Pradesh';

  

  return (
    <div className='conatiner_p-SavedPlacesCI'>
      <div className='conatiner_s-SavedPlacesCI'>

      {/* //? Here, when adding hotels and more, you need to add use a more unibversal term in place of "savedPlacesData" where your respective saved entity places would be soaved too...*/}

        {
          (!savedPlacesData || savedPlacesData.length < 1 || !placesInfo) && 
          <div>
            <div className='text-1-notFound-Itinerary_TO'>You have no saved places.</div>
            <img 
            src='https://pub-49c1c7ccb82b416b9193855757189b4a.r2.dev/no-saved-places-found-2.jpeg'
            className='img-notFound-SavedPlacesCI'
            />
            {/* <div>You have no Saved Places</div> */}
          </div>
        }

        {/*//! Saved Tourist Places  */}
        { savedPlacesData && placesInfo && 
            savedPlacesData.map((data) => {
              return(
                placesInfo.map((placeData, index) => {
                  if(data.id === placeData.id){
                  return(
                    <div key={index}
                    className='conatiner_p-card-SavedPlacesCI'
                    // className='card-TouristPlace-SavedPlacesCI'

                    // onClick={()=>handle_Click_TouristPlaces(data.sn, data.lat, data.lng, data.name)}
                    onMouseOver={()=>{
                      setFitAllLocations(true)
                      setActivePlace_MapData([{
                        lat:data.lat,
                        lng:data.lng,
                        name:placeData.name
                      }])
                    }}
                    onMouseOut={()=>setActivePlace_MapData()}

                    style={{position:'relative'}}
                    >

                      <div className='text-index-SavedPlaces_TO'>{index+1}.</div>

                      <img 
                        className="image-Card-TouristPlace-SavedPlacesCI" 
                        // src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/create_itinerary_5.jpeg'
                        src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${data.id}-01.jpg`}
                        alt="Image 1" 
                      />

                      <div className="heading-placeName-TouristPlace-SavedPlacesCI">
                        {placeData.name}
                      </div>

                      <div className="container_t-placeName-TouristPlace-MustVisit">

                        <div className='heading-2-placeName-TouristPlace-SavedPlacesCI'>
                        ({parseFloat(placeData.rating).toFixed(1)}) &nbsp; 
                        </div>

                        <div className='heading-3-placeName-TouristPlace-SavedPlacesCI'>
                          {
                            placeData.review_count ? placeData.review_count.toLocaleString('en-IN') : 'N/A'
                          }
                        </div>

                        <div
                        className='heading-4-card-placeName-TouristPlace-SavedPlacesCI'
                        >
                          #{placeData.category}
                        </div>

                      </div>


                      {
                        (placeData.explore_duration && placeData.explore_duration != "") &&
                        <div 
                        className='heading-4-card-duration-TouristPlace-SavedPlacesCI'
                        >
                          Explore in: {placeData.explore_duration} hrs
                        </div>
                      }



                      {/* <div 
                      className="container_t-2-placeName-TouristPlace-SavedPlacesCI"
                      >

                        <div
                        className='heading-4-card-placeName-TouristPlace-SavedPlacesCI'
                        >
                          #{placeData.category}
                        </div>

                      </div> */}


                      {/* <div className='PlaceCardsLineDiv-PlaceCardsCss02'>
                        <div className='line-card-SavedPlacesCI'></div> 
                      </div> */}

                      <div className='PlaceCardsButtonDiv-PlaceCardsCss02'>
                        <button className="button-viewMore-card-SavedPlacesCI" 
                        onClick={(e) => {
                          e.stopPropagation(); 
                          // handle_ViewMore_TouristPlaces(placeData.sn);
                          setChannel_viewMore_id(placeData.id)
                        }}>
                            View More 
                        </button>
                      </div>

                    </div>
                  )
                }
                })
              )
            })
        }

        {/* <div style={{height:'200px'}}></div> */}

      </div>
    </div>
  )
}

export default SavedPlaces_TO


