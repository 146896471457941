import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
//for generating unique id
import { v4 as uuidv4 } from 'uuid';

//importing arrays
import {get_prompt_1, prompt_all_destinations, prompt_district_state,
  prompt_states, prompt_season, prompt_india_division} from './Prompts_EverythingFamous'

//import search components
import {
  Search_Ds_and_Ls,
  Search_AllDestinations, Search_District_State, Search_Cities, Search_Districts, Search_States, 
  Search_Locations
} from '../../Universal Components/Search/Manage Search/Export/Export_Search'

// import other tools 
import axios from 'axios';
import { server_baseUrl } from '../../Universal Components/Server Side Hit Url/ServerHitUrlPart';

function EverythingFamous_AI() {

  //@@ 
  const navigate = useNavigate();

  //states
  const [randomPromt1, setRandomPromt1] = useState()
  const [randomPromt2, setRandomPromt2] = useState()
  const [randomPromt3, setRandomPromt3] = useState()

  
  const [promt1_options, setPromt1_options] = useState()
  
  const [promt1_select, setPromt1_select] = useState()
  
  const [destinationType, setDestinationType] = useState()
  const [destinationName, setDestinationName] = useState()
  const [inputResult, setInputResult] = useState()
  const [seasonTypeInput, setSeasonTypeInput] = useState()
  const [indiaDivisionInput, setIndiaDivisionInput] = useState()
  const [inputdestinationType, setInputDestinationType] = useState()

  const [navigationId, setNavigationId] = useState()

 
  //@@ useEffect 
  useEffect(() => {
    handleGetPromt1();
    handleGetValuesPromt1();
  }, [])


  // handle load prompt
  const handleGetPromt1 = () => {
    const randomEntity_temp = get_prompt_1[Math.floor(Math.random() * get_prompt_1.length)];
    setRandomPromt1(randomEntity_temp);
  }

  // handle load prompt
  const handleGetPromt2 = (entity_type) => {
    const inputType_temp = entity_type[Math.floor(Math.random() * entity_type.length)];
    setRandomPromt2(inputType_temp);
  }

  // handle load prompt
  const handleGetPromt3 = () => {
    const inputType_temp = prompt_india_division[Math.floor(Math.random() * prompt_india_division.length)];
    setRandomPromt3(inputType_temp);
  }

  
  // handle button click
  const handleOption1_click = (value, navigation) => {
    setPromt1_select(value);
    handleGetValuesPromt2(value);
    setNavigationId(navigation);
  }
  
  // handle button click
  const handleOption2_click = (value) => {
    handleGetPromt3()
    setSeasonTypeInput(value)
  }

  // handle button click
  const handleOption3_D_click = (value, type) => {
    setDestinationName(value)
    setDestinationType(type)
  }

  const handleOption3_click = (value) => {
    setIndiaDivisionInput(value)
  }
  
  // handle load functions click
  const handleGetValuesPromt1 = async () => {
    const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/every_thing_famous/entity_name`)
    // console.log("handleGetValuesPromt1", response.data)
    if(response.data.length > 0){
      setPromt1_options(response.data)
    }
  }
  
  // handle load functions click
  const handleGetValuesPromt2 = async (value) => {
    const prompt_1_select = value;
    const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/every_thing_famous/entity_destination_options`, {prompt_1_select})
    // console.log("handleGetValuesPromt1", response.data)
    if(response.data.length > 0){
      const entity_destination_options = response.data[0].entity_destination_options;

      setInputDestinationType(entity_destination_options)

      if(entity_destination_options === "All Destinations"){
        handleGetPromt2("prompt_all_destinations")
      }
      else if(entity_destination_options === "D_S"){
        handleGetPromt2("prompt_district_state")
      }
      else if(entity_destination_options === "State"){
        handleGetPromt2("prompt_states")
      }
      else{
        handleGetPromt2("prompt_season")
        setInputDestinationType("season")
      }
    }
  }
  

  const handleDestinationInput = async (event) => {
    const inputValue = event.target.value;
    if(inputValue){
      setDestinationName(inputValue);
      const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/manage_ai_components/get_destination_name/all`, {inputValue})
      // console.log("handleDestinationInput", response.data);
      if(response.data.length > 0){
        setInputResult(response.data)
      }
      else{
        setInputResult()
      }
    }
  }

  const handleStateInput = async (event) => {
    const inputValue = event.target.value;
    if(inputValue){
      setDestinationName(inputValue);      
      const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/nearby_ai/get_state_name`, {inputValue})
      // console.log("handleDestinationInput", response.data);
      if(response.data.length > 0){
        setInputResult(response.data)
      }
      else{
        setInputResult()
      }
    }
  }

  const handleDistrict_and_StateInput = async (event) => {
    const inputValue = event.target.value;
    if(inputValue){
      setDestinationName(inputValue);      
      const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/manage_ai_components/get_destination_name/district_state`, {inputValue})
      // console.log("handleDestinationInput", response.data);
      if(response.data.length > 0){
        setInputResult(response.data)
      }
      else{
        setInputResult()
      }
    }
  }


  //submit user input/query
  const handleSubmit = async () => {

    // const unique_id = await generateUniqueId();

    const unique_id = await insertNearbySearch();

    if(unique_id !== "0" && navigationId){
      const ai_feature_id = "ef-01";
      navigate(`ai-tool/discover-everything-famous/${unique_id}/${navigationId}/${ai_feature_id}`)
    }
    else{
      alert("opp! something went wrong. You might try again.")
      // console.log("!! error - Everything Famous; in getting the id for navigation. AI Tools.")
    }
  }

  const insertNearbySearch = async () => {

    const response =  await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/every_thing_famous/insert_data`, {promt1_select, destinationName, destinationType, seasonTypeInput, indiaDivisionInput})
    
    return response.data;
  }



  return (
    <div>
      
      <div>

        <div>

          <div>
          {/* prompt 1 */}
          <div>
            {randomPromt1}
          </div>
          
          {/* prompt 1 options */}
          <div>
            <div>
              { promt1_options &&
                promt1_options.map((data, index) => {
                  return(
                    <div>
                      <button onClick={()=>{handleOption1_click(data.entity_name, data.navigation_id)}}>{data.entity_name}</button>
                       &nbsp; <br></br>
                    </div>
                  )
                })
              }
            </div>
          </div>
          </div>

          {
            (promt1_select) && 
            (
            <div>
              {/* prompt 1 */}
              <div>
                {randomPromt1}
              </div>

              {
                inputdestinationType === "All Destinations" &&
                <div>
                  {/* <input placeholder='enter destination' value={destinationName} onChange={handleDestinationInput}/> */}
                  <Search_AllDestinations
                      handleSearchResultClick={handleOption3_D_click}
                  />
                </div>
              }
              
              {
                inputdestinationType === "D_S" &&
                <div>
                  {/* <input placeholder='enter district/state' value={destinationName} onChange={handleDistrict_and_StateInput}/> */}
                  <Search_District_State 
                    handleSearchResultClick={handleOption3_D_click}
                  />
                  
                </div>
              }
              
              {
                inputdestinationType === "State" &&
                <div>
                  {/* <input placeholder='enter states name' value={destinationName} onChange={handleStateInput}/> */}
                  <Search_States 
                    handleSearchResultClick={handleOption3_D_click}
                  />
                </div>
              }

              {/* { (inputResult && !destinationType) &&

                inputResult.map((data, index) => {
                  return(
                    <div>
                      <div>
                        <button onClick={()=>{handleOption3_D_click(data.name, data.type)}}>
                          {data.name} a <i>{data.type}</i>
                        </button>
                        &nbsp; <br></br>
                      </div>
                    </div>  
                  )
                })
              } */}
              
              {
                inputdestinationType === "season" &&
                <div>
                    <button onClick={()=>{handleOption2_click("winter")}}>
                      Winter
                    </button>
                    <button onClick={()=>{handleOption2_click("summer")}}>
                      Summer
                    </button>
                    <button onClick={()=>{handleOption2_click("monsoon")}}>
                      Monsoon
                    </button>
                    <button onClick={()=>{handleOption2_click("post_monsoon")}}>
                      Post-Monsoon
                    </button>
                </div>
              }

            </div>
            )
          }    


          {
            seasonTypeInput && 
            (
            <div>
              {/* prompt 1 */}
              <div>
                {randomPromt3}
              </div>

              {
                seasonTypeInput &&
                <div>
                    <button onClick={()=>{handleOption3_click("east india")}}>
                      East India
                    </button>
                    <button onClick={()=>{handleOption3_click("west india")}}>
                      West India
                    </button>
                    <button onClick={()=>{handleOption3_click("north india")}}>
                      North India
                    </button>
                    <button onClick={()=>{handleOption3_click("south india")}}>
                      South India
                    </button>
                    <button onClick={()=>{handleOption3_click("central india")}}>
                      Central India
                    </button>
                    <button onClick={()=>{handleOption3_click("northeast india")}}>
                      Northeast India
                    </button>
                </div>
              }

            </div>
            )
          }

          {/* submit */}
          <div>
            <div>
              { (destinationName || indiaDivisionInput) &&
                <button onClick={handleSubmit}>Discover {promt1_select} 🤟</button>
              }
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default EverythingFamous_AI