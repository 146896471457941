// MyContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'


import axios from 'axios';
import Moment from 'moment';

//importing react context
import { useValidatePlanTrip } from '../../Manage - Plan Trip/Context/ValidatePlanTrip';
import { server_baseUrl } from '../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';


// Step 1: Create a new context
const Context = createContext();


// Step 2: Create a context provider
export const Context_globalNavigationPlanTrip = ({ children }) => {

  const navigate = useNavigate();

  const {shareTripId} = useParams()

  // using react context
  const { userEmail, tripId, tripName, setTripName, tripDestinationType, stateName, districtName, cityName, setTripId } = useValidatePlanTrip();

  const currentUrl = window.location.href;
  const currentPath = window.location.pathname;
  const queryString = window.location.search;

  //View more place info 
  //?This has been set up here because the view more detail or pop up function that handles everything is in travel categories three context and for trip overview to use that function in that respective context it has to be wrapped around but I am getting error when I am wrapping the trip overview in plant trip home component when I am wrapping it with travel categories three context so I am creating this state for better communication I mean for communication and so both this the the context can communicate
  const[channel_viewMore_id, setChannel_viewMore_id] = useState()

  const [viewMoreData_touristPlaces, setViewMoreData_touristPlaces] = useState([])
  const [viewMoreData_About, setViewMoreData_About] = useState([])
  const [viewMoreData_Review, setViewMoreData_Review] = useState([])
  const [viewMoreData_PlaceInfo, setViewMoreData_PlaceInfo] = useState([])

  const [activeId_viewMore_touristPlaces, setActiveId_viewMore_touristPlaces] = useState()

  // handle "loading" view more data status
  const[viewMoreLoading, setViewMoreLoading] = useState(false)



  

  //plan trip status
  const[tripProgressStatus, setTripProgressStatus] = useState()
  //this should make sure that the value of track_limit_progressStatus is not changed more that 10 times...
  const[track_limit_progressStatus, setTrack_limit_progressStatus] = useState(0)

  useEffect(() => {
    if(!shareTripId && tripId){
      getPlanTripStatus()
    }  
  }, [tripId])

  const getPlanTripStatus = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/manage_trip/get/trip_status`, {
      tripId, userEmail
    });

    if(response.data !== "0"){
      setTripProgressStatus(response.data[0].plan_trip_status)
    } 

  }

  const handleUpdateTripPlanStatus = async (passedTripProgressStatus) => {

    if(track_limit_progressStatus < 11 ){
      const response = await axios.post(`${server_baseUrl}/csc/plan_trip/manage_trip/add_update/trip_status`, {
        tripId, userEmail, passedTripProgressStatus
      });

      if(response.data !== "0"){
        setTripProgressStatus(passedTripProgressStatus)
        // setTrack_limit_progressStatus(tripProgressStatus+1)
      } 
      else{
        alert("opps! failed to update the status. Please try again.")
        // setTrack_limit_progressStatus(tripProgressStatus+1)
      }
    }
    else{
      alert("you have reached the limit to update the your trip status.")
    }

  }



  //*##1 plan trip navigation
  const [activeFeature, setActiveFeature] = useState(!shareTripId ? "about" : "trip_overview") //about, trip_plan, create_itinerary, trip_overview  

  const handlePlanTripNavigation = (featureClicked) => {
    //about, plan_trip, create_itinerary, view_trip
    setActiveFeature(featureClicked)
  }


  //*##2 Create New Trip
  const [createTripStatus, setCreateTripStatus] = useState(false)
  const [editTripStatus, setEditTripStatus] = useState(false) // depricated
  // const [tripName, setTripName] = useState()

  //!## Global Variables, Share Data
  const[global_SavedDestinations, setGlobal_SavedDestinations] = useState();
  
  const handleCreateTripStatus = (status) => {
    setCreateTripStatus(status)
  }

  const handleEditTripStatus = (status) => {
    setEditTripStatus(status)
  }

  const handleTripName = (event) => {
    setTripName(event.target.value)
  }

  const handleCreateUpdateTrip = async () => {
    
    const date = Moment(new Date()).format('YYYY-MM-DD hh:mm:ss A');

    if(tripId){
        updateTrip(date, tripDestinationType, stateName, districtName, cityName, userEmail)
    }
    else{
        CreateTrip(date, tripDestinationType, stateName, districtName, cityName, userEmail)
    }
  }
  

  //##
  const CreateTrip = async (date, tripDestinationType, stateName, districtName, cityName, userEmail) => {
    
    try {

        const response = await axios.post(`${server_baseUrl}/csc/plan_trip/manage_trip/create_trip`, {
        date, tripName, tripDestinationType, stateName, districtName, cityName, userEmail
        });
        // console.log("CreateTrip", response.data)
        if(response.data === "0"){
          // console.log("error in creating your trip. Please try again.")
        }
        else{
          const tripId = response.data;
          // console.log("currentPath", currentPath, "tripId", tripId)
          navigate(`${currentPath}${tripId}/${tripName}`);
          window.location.reload();
          //Depricated: setCreateTripStatus(false)
        }

    } catch (error) {
        console.error("Error in creating creating the trip");
        // console.log(error)
    }

  }

  //##
  const updateTrip = async (date, tripDestinationType, stateName, districtName, cityName, userEmail) => {

    try {

        const response = await axios.post(`${server_baseUrl}/csc/plan_trip/manage_trip/update_trip`, {
        date, tripName, tripId, tripDestinationType, stateName, districtName, cityName, userEmail
        });

        if(response.data === "0"){
            // console.log("error in updating your trip. Please try again.")
        }
        else{
          // console.log("here")
            // navigate(`${currentPath}?nameChange=true`);
            setCreateTripStatus(false)
            // return;
        }

    } catch (error) {
        console.error("Error in creating updating the trip");
    }

  } 



  // Step 5: Return a provider with value containing state and functions
  return (
    <Context.Provider value={{ 
      //
      handleUpdateTripPlanStatus, tripProgressStatus, track_limit_progressStatus,
      //
      activeFeature, setActiveFeature, 
      handlePlanTripNavigation, handleCreateUpdateTrip, 
      createTripStatus, setCreateTripStatus, 
      editTripStatus, setEditTripStatus, 
      handleCreateTripStatus, handleEditTripStatus, 
      handleTripName,
      //!Global States - to share data and above reload
      global_SavedDestinations, setGlobal_SavedDestinations,

      //view more channel
      channel_viewMore_id, setChannel_viewMore_id,

      viewMoreData_touristPlaces, setViewMoreData_touristPlaces,
      viewMoreData_About, setViewMoreData_About,
      viewMoreData_Review, setViewMoreData_Review,
      viewMoreData_PlaceInfo, setViewMoreData_PlaceInfo,
      activeId_viewMore_touristPlaces, setActiveId_viewMore_touristPlaces,

      //loading status handle
      viewMoreLoading, setViewMoreLoading,
    }}>
      {children}
    </Context.Provider>
  );

};

// Step 6: Create a custom hook to consume the context
export const useGlobalNavigationPlanTrip = () => {
  return useContext(Context);
};

