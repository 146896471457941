import React from 'react'

// import react context 
import {useHowToReachContext} from '../../../Manage/Context/HowToReachContext'

//import icons
import { GrLocationPin } from "react-icons/gr";


function Train() {

  //import from useContext 
  const {
    trainContent,
    destinationType, destinationName,
    //functions
    handleHoverLocation_Map, handleHoverOffLocation_Map,
    handleClickLocation_Map
  } = useHowToReachContext();
  

  return (
    <div>


      {
        trainContent.find((data=> (data.mode_type === "major" || (data.mode_type === "A" || data.mode_type === "A1" || data.mode_type === "B"))))
        &&
        <div className='heading-1-mode-Reach_D'>
            Major Train Station
          </div>    
      }



      <div className='container_p-Cards-Air-Reach_D'>
        {
        trainContent.map((data, index) => {
          if(data.mode_type === "major" || (data.mode_type === "A" || data.mode_type === "A1" || data.mode_type === "B")){
          return(
            <button
              className='card-Air-Reach_D'
              onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name)}}
              onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name)}}
              onMouseOut={()=>{handleHoverOffLocation_Map()}}
            >

              <div className='text-1-air-Reach_D'>
                  {data.mode_name}
              </div>

              <div className='text-2-air-Reach_D'>
                  Code: {data.mode_code}
              </div>

              <div className='text-3-air-Reach_D'>
                  <div className='text-3-1-air-Reach_D'>
                      in {data.mode_state_name} 
                  </div>
                  {/* {data.mode_state_name && 
                  <div className='text-3-1-air-Reach_D'>
                      , {data.mode_state_name}.
                  </div>
                  } */}
              </div>

              {/* {
                  destinationType !== 'state' ?
                  <div>
                      in {data.mode_state_name}.
                  </div>    
                  : 
                  destinationName !== data.mode_state_name ?
                  <div>
                      in {data.mode_state_name}.
                  </div> 
                  : null    
              } */}

              { data.distance &&
                destinationType !== "state" ?
                <div className='text-5-air-Reach_D'>
                  <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                </div> :
                  null
              }
            </button>    
          )} 
          })
        }
      </div>
      


      {/* {
      trainContent.map((data, index) => {
        if(index < 1 && data.mode_type === "minor" || (data.mode_type !== "A" || data.mode_type !== "A1" || data.mode_type !== "B")){
        return(
          <div className='heading-1-mode-Reach_D'>
            Other Train Station
          </div>    
        )
      }})
      } */}

      {
        trainContent.find((data=> (data.mode_type === "minor" || (data.mode_type === "minor" || (data.mode_type === "C" || data.mode_type === "D" || data.mode_type === "E" || data.mode_type === "F")))))
        &&
        <div className='heading-1-mode-Reach_D'>
            Other Train Station
          </div>    
      }


      <div className='container_p-Cards-Air-Reach_D'>
        {
          trainContent.map((data, index) => {
            if(data.mode_type === "minor" || (data.mode_type === "C" || data.mode_type === "D" || data.mode_type === "E" || data.mode_type === "F")){
            return(
              <button
                className='card-Air-Reach_D'
                onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name)}}
                onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name)}}
                onMouseOut={()=>{handleHoverOffLocation_Map()}}
              >

                  <div className='text-1-air-Reach_D'>
                      {data.mode_name}
                  </div>

                  <div className='text-2-air-Reach_D'>
                      Code: {data.mode_code}
                  </div>

                  <div className='text-3-air-Reach_D'>
                      <div className='text-3-1-air-Reach_D'>
                          in {data.mode_state_name} 
                      </div>
                      {/* {data.mode_state_name && 
                      <div className='text-3-1-air-Reach_D'>
                          , {data.mode_state_name}.
                      </div>
                      } */}
                  </div>
              
                  { data.distance &&
                    destinationType !== "state" ?
                    <div className='text-5-air-Reach_D'>
                    <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                    </div> :
                    null
                  }
              </button>    
            )
            } 
          })
        }
      </div>

            {/* </div>     */}

      
      {/* <div> 
        { trainContent &&
          trainContent.map((data, index) => {
            return(
            <div key={index}
              onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name)}}
              onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name)}}
              onMouseOut={()=>{handleHoverOffLocation_Map()}}
            >



              mode_name: {data.mode_name} <br></br>
              mode_code: {data.mode_code} <br></br>
              mode_type: {data.mode_type} <br></br>
              mode_district: {data.mode_district} <br></br>
              mode_state_name: {data.mode_state_name}

              <br></br> <br></br> <br></br> &nbsp;
            </div>  
            )
          })
        }
      </div> */}

    </div>
  )
}

export default Train