import React, {useState, useEffect} from 'react'

import axios from 'axios';

//import css
import './TripPlanning.scss'

import { Helmet } from 'react-helmet' 

import { server_baseUrl } from '../../../../Universal Components/Server Side Hit Url/ServerHitUrlPart';

//
import StarRatingComponent from 'react-star-rating-component';

//using react context
import {useValidatePlanTrip} from '../../../Manage - Plan Trip/Context/ValidatePlanTrip';

import {TravelCategories_Context} from '../../Trip Planning/Manage/Context/TravelCategories_Context'
import {TravelCategories_3_Context} from '../../Trip Planning/Manage/Context/TravelCategories_3_Context'

import TouristPlaces_VM from './View More/TouristPlaces_VM'
import Destinations from '../Components/Destinations/Destinations'
import TouristPlaces from '../Components/Travel Categories/Tourist Places/TouristPlaces'
import TravelCategories from '../Components/Travel Categories/Main/TravelCategories'
import Hotels from '../Components/Travel Categories/Hotels/Hotels'
import Restaurants from '../Components/Travel Categories/Restaurants/Restaurants'
import Bars_and_Pubs from '../Components/Travel Categories/Bars and Pubs/Bars_and_Pubs'
import AmusementParks from '../Components/Travel Categories/Amusement Parks/AmusementParks'
import AdventureActivities from '../Components/Travel Categories/Adventure Activities/AdventureActivities'
import RentVehicles from '../Components/Travel Categories/Rent Vehicle/RentVehicles'
import ShoppingMalls from '../Components/Travel Categories/Shopping Malls/ShoppingMalls'
import ShoppingStreet from '../Components/Travel Categories/Shopping Street/ShoppingStreet'
import Map from '../Components/Map/Map'
import { useGlobalNavigationPlanTrip } from '../../../Manage - Plan Trip/Context/GlobalNavigationPlanTrip';
import PreHotel_Temp from '../Components/Travel Categories/Hotels/PreHotel_Temp';

function TripPlanning() {

  // using react context 
  const {tripDestinationType, tripDestinationName, stateMultipleDistricts, stateName} = useValidatePlanTrip()
  const {
    activeTravelCategory, setActiveTravelCategory, destinationsData, lat, lng
  } = TravelCategories_Context()

  const {
    // viewMoreData_touristPlaces, 
    // viewMoreData_About,
    // viewMoreData_Review,
    // viewMoreData_PlaceInfo,
    // activeId_viewMore_touristPlaces, setActiveId_viewMore_touristPlaces, handle_UnSave_Places, handle_Save_Places, saved_touristPlaces_data, testValue, setTestValue, handleTest
    // viewMoreLoading, setViewMoreLoading
  } = TravelCategories_3_Context();

  const{
    viewMoreData_touristPlaces, setViewMoreData_touristPlaces,
    viewMoreData_About, setViewMoreData_About,
    viewMoreData_Review, setViewMoreData_Review,
    viewMoreData_PlaceInfo, setViewMoreData_PlaceInfo,
    activeId_viewMore_touristPlaces, setActiveId_viewMore_touristPlaces,
    //loading status handle
    viewMoreLoading, setViewMoreLoading,
  } = useGlobalNavigationPlanTrip();

  //*
  const [display_loadFamousLocationsStatus, setDisplay_loadFamousLocationsStatus] = useState(true)
  const [displayFamousLocationsData, setDisplayFamousLocationsData] = useState([])

  useEffect(() => {

    if(display_loadFamousLocationsStatus && tripDestinationName && stateName){

      handleLoadDisplayData(tripDestinationName, stateName)

      setDisplay_loadFamousLocationsStatus(false)

    }
  }, [])

  const handleLoadDisplayData = async (tripDestinationName, stateName) => {

    const response = await axios.post(`${server_baseUrl}/csc/plan_trip/trip_planning/display/famous_locations`, {tripDestinationType, tripDestinationName, stateName}) 

    // console.log("handleLoadDisplayData", response.data)  

    if(response.data !== "0"){    
      setDisplayFamousLocationsData(response.data)
    }
    else{
      // console.log("error in loading the famous places to display.")
    }

  }

  useEffect(() => {
    if(activeId_viewMore_touristPlaces){
      document.body.style.overflow = 'hidden';
    }else{
      document.body.style.overflow = 'auto';
    }
  }, [activeId_viewMore_touristPlaces])
  
  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
      setActiveId_viewMore_touristPlaces(false);
      document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  const modified_state_name = stateName.toLowerCase().replace(/\s+/g, '_');


  //
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 849);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 849); // Update state on window resize
    };
  
    window.addEventListener('resize', handleResize);
  
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 

  return (
    <div>

<Helmet>

<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Assistant:wght@200&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Maven+Pro&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />
<link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

</Helmet>

      <div className='mainContainer-TripPlanning'>

        {/* ## container 1 */}
        <div className='Container1-TripPlanning'>

          {
          displayFamousLocationsData &&

          <div className='conatiner_p-headerPlaces-TripPlanning'>

            {
              displayFamousLocationsData.map((data, index) => {
                if(!isMobile || isMobile && index < 2){
                return(
                  <div className='card-headerPlaces-TripPlanning'>

                    <div className='overlay-headerPlaces-TripPlanning'></div>

                    <img
                    className='image-card-headerPlaces-TripPlanning'
                    // src='https://cdn.britannica.com/36/178136-050-7F77D46B/village-Alpine-valley-canton-Saint-Moritz-Engadin.jpg' 

                    src={`https://pub-4808dd26afae44afab70101260c2bd8f.r2.dev/${modified_state_name}-${data.sn}-0${index+1}.jpg`}
                    onError={(e) => { e.target.src = 'https://plus.unsplash.com/premium_photo-1676218968741-8179dd7e533f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'; }}
                    />

                    <div
                    className='card_data-headerPlaces-TripPlanning'
                    >

                      {/*  */}
                      <div className='heading-1-card-headerPlaces-TripPlanning'>
                        {data.name}
                      </div>


                      {/*  */}
                      <div className='container_t-card-headerPlaces-TripPlanning'>

                        
                        <div className='heading-4-card-headerPlaces-TripPlanning'> 
                          <StarRatingComponent
                            name="rating"
                            starCount={5}
                            value={data.rating}
                            editing={false}
                            starColor="gold" // This sets the color for the filled stars
                            emptyStarColor="#ccc" // This sets the color for the empty stars
                          />  
                        </div>

                        <div 
                        className='heading-2-card-headerPlaces-TripPlanning'
                        >
                          ({parseFloat(data.rating).toFixed(1)})
                        </div>

                        <div
                        className='heading-2-card-headerPlaces-TripPlanning'
                        >
                          {
                            data.review_count && 
                            Number(data.review_count).toLocaleString('en-IN')
                          }
                        </div>

                      </div>   

                      {/*  */}
                      <div className='container_t-2-card-headerPlaces-TripPlanning'>
                        <div
                        className='heading-3-card-headerPlaces-TripPlanning'
                        >
                          #{data.category}
                        </div>
                        { //only for state
                          tripDestinationType === "state" && data.destination && 
                          <div
                          className='heading-3-card-headerPlaces-TripPlanning'
                          >
                            in #{data.destination}
                          </div>    
                        }
                      </div>  

                      
                      {/* {
                        (data.explore_duration) && 
                        <div>
                          <div
                          className='heading-1-card-headerPlaces-TripPlanning'
                          >
                            Total Duration:
                          </div>   
                          <div
                          className='heading-1-card-headerPlaces-TripPlanning'
                          >
                            {data.explore_duration}
                          </div>   
                        </div>   
                      }  */}

                    </div> 

                  </div>   
                )
                }
              })
            }

          </div>
          }

          <div
          className='container_p-destinationName-TripPlanning'
          >

            <div className='heading-destinationName-TripPlanning'>
                {tripDestinationName}
            </div>

            <img 
              className='img-destinationName-TripPlanning'
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/map_india_1.png'
            />

          </div>

          {(stateMultipleDistricts && tripDestinationType === "state" && destinationsData) && 
            <div className='DestinationName-MainConatiner-TripPlanning'>
                  <Destinations />
            </div>
          }

          <div className='TripDetails-MainConatiner-TripPlanning'>
              
          </div>

          <div className='Destinations-MainConatiner-TripPlanning'>
              
          </div> 

          <div className='SelectedDestination-MainConatiner-TripPlanning'>
              
          </div>

          {/* <div className='container_p-TravelCategories-TripPlanning'> */}
          {/* </div> */}
          
          {
            // false &&
            <div>
              <TravelCategories />
            </div> 
          }
        
          <div className='Places-MainConatiner-TripPlanning'>

            {activeTravelCategory === "tourist_places" && 
              <TouristPlaces />
            }
            {/* <div>Hello jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj</div> */}
            {activeTravelCategory === "hotels" && 
              // <Hotels />
              <PreHotel_Temp />
            }

            {activeTravelCategory === "restaurants" && 
              <Restaurants />
            }

            {activeTravelCategory === "bars_and_pubs" && 
              <Bars_and_Pubs />
            }

            {activeTravelCategory === "amusement_parks" && 
              <AmusementParks />
            }

            {activeTravelCategory === "adventure_activities" && 
              <AdventureActivities />
            }

            {activeTravelCategory === "rent_vehicle" && 
              <RentVehicles />
            }

            {activeTravelCategory === "shopping_malls" && 
              <ShoppingMalls />
            }

            {activeTravelCategory === "street_market" && 
              <ShoppingStreet />
            }

          </div>

        </div> 
        
        {(!isMobile && lat && lng) &&
          <div className='Container2-TripPlanning'> {/*div-2*/}

            <div className='Map-MainConatiner-TripPlanning'>
              <Map />
            </div>

          </div> 
        }

      </div>

      {/* activeId_viewMore_touristPlaces: {activeId_viewMore_touristPlaces}
      testValue: {testValue}
      <button onClick={()=>{handleTest("asasas")}}>test - random</button> */}

      {
        viewMoreLoading && 
        <div className="loading-overlay">
          <div className="spinner">
            {/* You can add a loading spinner here */}
          </div>
        </div>
      }

      {
        activeId_viewMore_touristPlaces && 
        <div >
          <TouristPlaces_VM 
            //view more tourist places data
            viewMoreData_touristPlaces={viewMoreData_touristPlaces}
            viewMoreData_About={viewMoreData_About}
            viewMoreData_Review={viewMoreData_Review}
            viewMoreData_PlaceInfo={viewMoreData_PlaceInfo}
            //handle active id
            activeId_viewMore_touristPlaces={activeId_viewMore_touristPlaces} setActiveId_viewMore_touristPlaces={setActiveId_viewMore_touristPlaces }
            stateName={stateName}

            // save/unsave places
            //!depricated
              // handle_UnSave_Places={handle_UnSave_Places}
              // handle_Save_Places={handle_Save_Places}
              // saved_touristPlaces_data={saved_touristPlaces_data}
          />
        </div>  
      }
      <div>

      </div>

    </div>
  )
}

export default TripPlanning




