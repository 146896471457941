import React from 'react'

//import scss
import './Search_TravelCategories.scss'

import { useNavigate, useParams, useLocation } from 'react-router-dom';


//importing components 
//?Travel Categories
import {Hotels} from '../../../Travel Categories/Manage/Export/Export_TravelCategories'

function Search_TravelCategories(props) {

  const {handleToastMessage} = props;
  
  const navigate = useNavigate(); 


  // const plan_ai_trip = useRef(null);

  // useEffect(() => {

  //   scrollToFeedback(scrollPageTo_TravelCategories)

  // }, [scrollPageTo_TravelCategories])

  // const scrollToFeedback = (scrollPageTo) => {

  //  if(scrollPageTo === "plan_ai_trip"){
  //     plan_ai_trip.current.scrollIntoView({ behavior: 'smooth' });
  //   }

  // };

  const handleNavigate_AdventureActivities = () => {
    navigate('/discover/Adventure-Activities')
  }

  const handleNavigate_AmusementParks = () => {
    navigate('/discover/Amusement-Parks')
  }

  const handleNavigate_Bars_and_Pubs = () => {
    navigate('/discover/Bars-and-Pubs')
  }

  const handleNavigate_Hotels = () => {
    navigate('/discover/Hotels')
  }

  const handleNavigate_Rent = () => {
    navigate('/discover/Rent')
  }

  const handleNavigate_Restaurants = () => {
    navigate('/discover/Restaurants')
  }

  const handleNavigate_ShoppingMalls = () => {
    navigate('/discover/Shopping-Malls')
  }

  const handleNavigate_ShoppingStreet = () => {
    navigate('/discover/Shopping-Street/Street-Market')
  }


  return (
    <div>
      {/* <div>
        Explore/Discover Travel Categories
      </div> */}

      {/* <div>
        <button onClick={()=>{handleNavigate_Hotels()}}>
          Discover Hotels 
        </button>
      </div> */}

<br></br>
<br></br>

      {/*//!Hotels #1 */}
      <div
      className='container_p-createItinerary-AISection'
      >
        <button 
        className='container_s-Search_TravelCategories'
        onClick={()=>{handleNavigate_Hotels()}}
        >
          <img 
          className='img-hotel-Search_TravelCategories'
          src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/home-hotel-travelCategory-1.png'
          />

          <div
          className='container_p-metaData-createItinerary-AISection'
          >
            <div
            className='container_s-metaData-createItinerary-AISection'
            >
              <button 
              className='button-1-metaData-createItinerary-AISection'
              >
                # Compare prices across sites
              </button>
              <button
              className='button-1-metaData-createItinerary-AISection'
              >
                # Book Direct
              </button>
              <button
              className='button-1-metaData-createItinerary-AISection'
              >
                # Book on OTA's site
              </button>
              <button
              className='button-1-metaData-createItinerary-AISection'
              >
                # Best Deals
              </button>
              <button
              className='button-1-metaData-createItinerary-AISection'
              >
                # Nearest Hotel
              </button>
            </div>
          </div>
          
        </button>   
      </div>


      <br></br>
      <br></br>

      {/*//!Restaurants #2 */}
      <div
      className='container_p-createItinerary-AISection'
      >
        <button 
        className='container_s-Search_TravelCategories'
        // onClick={()=>{handleNavigate_Restaurants()}}
        >
          <img 
          onClick={()=>{handleToastMessage("Comming Soon", "info")}}
          // onClick={()=>{handleNavigate_Restaurants()}}
          className='img-hotel-Search_TravelCategories'
          src='https://pub-ea13113554d14248a7f6901f2863719e.r2.dev/home-restaurant-travelCategory-1.png'
          />

          <div
          className='container_p-metaData-createItinerary-AISection'
          >
            <div
            className='container_s-metaData-createItinerary-AISection'
            >
              <button 
              className='button-1-metaData-createItinerary-AISection'
              >
                # Top Restaurants
              </button>
              <button
              className='button-1-metaData-createItinerary-AISection'
              >
                # Explore Cuisine 
              </button>
              <button
              className='button-1-metaData-createItinerary-AISection'
              >
                # Best Eats
              </button>
              <button
              className='button-1-metaData-createItinerary-AISection'
              >
                # Bookmark Your Fav
              </button>
            </div>
          </div>
          
        </button>   
      </div>



     {/*//!Bars and Pubs #3 */}
      {/* <div>
          <button onClick={()=>{handleNavigate_Bars_and_Pubs()}}>
              Bars and Pubs
          </button>
      </div> */}

       
      {/*//!Restaurants #4 */} 
      {/* <div>
          <button onClick={()=>{handleNavigate_AdventureActivities()}}>
              Adventure Activities
          </button>
      </div> */}


      {/*//!Restaurants #5 */}
      {/* <div>
          <button onClick={()=>{handleNavigate_AmusementParks()}}>
              Amusement Parks
          </button>
      </div> */}


      {/*//!Restaurants #6 */}
      {/* <div>
          <button onClick={()=>{handleNavigate_Rent()}}>
              Rent Cars/Bikes
          </button>
      </div> */}


     {/*//!Restaurants #7 */}
      {/* <div>
          <button onClick={()=>{handleNavigate_ShoppingMalls()}}>
              Shopping Malls
          </button>
      </div> */}


     {/*//!Restaurants #8 */}
      {/* <div>
          <button onClick={()=>{handleNavigate_ShoppingStreet()}}>
              Street Shopping/Street Market 
          </button>
      </div> */}

      
    </div>
  )
}

export default Search_TravelCategories