import React from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import FontFamily from '@tiptap/extension-font-family';
import Link from '@tiptap/extension-link';
import Highlight from '@tiptap/extension-highlight';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Blockquote from '@tiptap/extension-blockquote';
import Heading from '@tiptap/extension-heading';

const TipTap_ReadOnly = ({ content }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Bold,
      Italic,
      Strike,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      TextStyle,
      Color,
      FontFamily,
      Link.configure({
        autolink: true,
        openOnClick: true,
        linkOnPaste: true,
        validate: (href) => isValidURL(href),
      }),
      Highlight.configure({ multicolor: true }),
      BulletList,
      OrderedList,
      ListItem,
      HorizontalRule,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Blockquote,
      Heading.configure({
        levels: [1, 2, 3],
      }),
    ],
    content: content,
    editable: false, // Set editor to read-only
  });

  // Function to validate URL format
  const isValidURL = (url) => {
    const pattern = /\.com/;
    return pattern.test(url);
  };

  // Ensure editor is defined before rendering
  if (!editor) {
    return null;
  }

  return (
    // <div className="editor-container">
      <EditorContent editor={editor} />
    // {/* </div> */}
  );
};

export default TipTap_ReadOnly;
