import React, {useState, useEffect} from 'react'

import Markdown from 'react-markdown';

//import css
import './PromptAndOverview.scss';

//importing react context
import { useAiCreateItinerary } from '../../../Create Itinerary - Result/Manage/Context/Ai_CreateItineraryContext';

//
import ReactMarkdown from 'react-markdown';

function PromptAndOverview() {

  const {
    //*prompt 
    prompt,

    //*Day Wise
    activeDay, setActiveDay,
    exploreDuration,
    travelDuration,
    stateName,
    destinationName,
    itineraryDuration,
    tripSeason,

    //open AI
    overviewContent_ai
  } = useAiCreateItinerary()


  const [isExpanded, setIsExpanded] = useState(false);

  // Define a character limit for "Read More"
  const charLimit = 100;
  
  // Toggle between showing full content or truncated content
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  
  return (
    <div>

      <div
      className='conatiner_t-question-PromptAndOverview'
      >
        <div
        className='text-1-question-PromptAndOverview'
        >
          Q:
        </div>
        <div
        className='text-2-question-PromptAndOverview'
        >
          {prompt}
        </div>
      </div>

      <div
      className='text-1-heading-PromptAndOverview'
      >
        Trip Overview 
      </div>  


      {overviewContent_ai ? (
        <div
        className='content-1-data-PromptAndOverview'
        >

          {/* <ReactMarkdown>{overviewContent_ai}</ReactMarkdown> */}

          {/* Display either full content or truncated content */}
          <div
          // className={`content-read-more-less ${isExpanded ? 'expanded' : ''}`}
          >
            <ReactMarkdown>
              {isExpanded ? overviewContent_ai : `${overviewContent_ai.slice(0, charLimit)}...`}
            </ReactMarkdown>
          </div>

          <br></br>

          {
            isExpanded &&
            <div>
              You'll spend {exploreDuration} hours exploring the places in your itinerary and {parseFloat(travelDuration).toFixed(1)} hours traveling between them.

              <br></br>
            </div>
          }

          {/* <br></br> */}
          
          {/* Toggle button for Read More / Read Less */}
          <button onClick={toggleReadMore}
          className='button-read_M_L-PromptAndOverview'
          >
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>

          <br></br>
          <br></br>

          <i style={{color:'gray'}}>
            Note: The number of days in your itinerary may vary based on the available tourist places under your selected categories and preferences.
          </i>


        </div>
      ) : (
        <p
        className='loading-1-data-PromptAndOverview'
        >
          <br></br>
          Loading AI Overview...
        </p>
      )}

        {/* <p
        className='loading-1-data-PromptAndOverview'
        >
          <br></br>
          Loading AI Overview...
        </p> */}



    </div>
  )
}

export default PromptAndOverview



