import React, {useState, useEffect} from 'react'
import './App.css';


import {BrowserRouter as Router, Link, Routes, Route} from 'react-router-dom'

//!new start

//?travel categories

//importing travel categories
import {AdventureActivities, AmusementParks, Bars_and_Pubs, Hotels, Rent, Restaurants, ShoppingMalls, ShoppingStreet, HowToReach_Main} from 
'./Components/Production Components/Travel Categories/Manage/Export/Export_TravelCategories';

//import context providers
import {AdventureActivitiesContextProvider, AmusementParksContextProvider, Bars_and_PubsContextProvider, HotelsContextProvider, RentContextProvider, RestaurantsContextProvider, ShoppingMallsContextProvider, ShoppingStreetContextProvider, HowToReachContextProvider} from './Components/Production Components/Travel Categories/Manage/Export/Export_TravelCategories'

//?AI - travel categories 

//importing ai travel categories
import {AttractionTypes, Destinations, Seasons, TouristPlaces} from 
'./Components/Production Components/Travel Categories - Ai/Manage/Export/Export_Ai_TravelCategories';

//import ai context providers
import {AttractionTypesContextProvider, DestinationsContextProvider, SeasonsContextProvider, TouristPlacesContextProvider} from './Components/Production Components/Travel Categories - Ai/Manage/Export/Export_Ai_TravelCategories'

//!new end
//?there are some files below which are new, but not labeled

import PrivacyPolicy from './MoreOptions/PrivacyPolicy';
import ContactUs from './MoreOptions/ContactUs';

//analytics
import ReactGA from "react-ga";
import AboutUs from './MoreOptions/AboutUS';

import Result_NearBy_AI from './Components/Production Components/AI Components/Near by/Result_NearBy_AI';
import Result_HowToReach_AI from './Components/Production Components/AI Components/How To Reach/Result_HowToReach_AI';
import Result_EverythingFamous_AI from './Components/Production Components/AI Components/Everything Famous/Result_EverythingFamous_AI';
import Home from './Components/Production Components/Home/Main - Home/Home';
import PlanTripHome from './Components/Production Components/Plan Trip/Main - Plan Trip/PlanTripHome';
import { Context_globalNavigationPlanTrip } from './Components/Production Components/Plan Trip/Manage - Plan Trip/Context/GlobalNavigationPlanTrip';
import { Context_validatePlanTrip } from './Components/Production Components/Plan Trip/Manage - Plan Trip/Context/ValidatePlanTrip';
import { Editor } from './Components/Test Playground/Notes/Editor';
import Ai_CreateItinerary from './Components/Production Components/AI Components/Create Itinerary/Create Itinerary - Result/Main/Ai_CreateItinerary';
import Notes_Tool from './Components/Production Components/Plan Trip/Global Tool - Plan Trip/Notes/Notes_Tool';
import { Context_AiCreateItinerary } from './Components/Production Components/AI Components/Create Itinerary/Create Itinerary - Result/Manage/Context/Ai_CreateItineraryContext';
import { Context_AiCreateItinerary_2 } from './Components/Production Components/AI Components/Create Itinerary/Create Itinerary - Result/Manage/Context/Ai_CreateItineraryContext_2';
import Error404 from './Errors/Error404';
import Hotel_HomePage from './Components/Production Components/B2B/Hotel/Hotel B2B Home Page/Hotel_HomePage';
import HowToReach_Main_Mobile from './Components/Production Components/Travel Categories/How To Reach Mobile/Main/HowToReach_Main_Mobile';
import { HowToReachContextProvider_Mobile } from './Components/Production Components/Travel Categories/How To Reach Mobile/Manage/Context/HowToReachContext_Mobile';

  

const TRACKING_ID = "UA-235137119-1";
ReactGA.initialize(TRACKING_ID)


function App() { 


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initialize with the current width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 699); // Update state on window resize
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Router>
      <div className="App">


        <Routes>
                    
                    <Route path='/' element={<Home />} />

                    <Route path='/plan-trip-with-ai/ai-trip-planner' element={<Home />} />

                    <Route path='/plan-trip' element={<Home />} />

             

                      {/*//! Using the old component for the shared trip */}
                      <Route exact 
                      path={`/shared-trip/plan-trip-to-:destinationName/:shareTripId`} 
                      element={ 
                        <Context_validatePlanTrip>
                        <Context_globalNavigationPlanTrip>

                          <PlanTripHome  />

                        </Context_globalNavigationPlanTrip>
                        </Context_validatePlanTrip>
                      } 
                      /> 



                      <Route exact path={`/plan-complete-trip/state/:stateName`} element={
                         <Context_validatePlanTrip>
                         <Context_globalNavigationPlanTrip>
 
                           <PlanTripHome  />
 
                         </Context_globalNavigationPlanTrip>
                         </Context_validatePlanTrip>
                      } /> 

                      <Route exact path={`/plan-complete-trip/state/:stateName/:tripId/:tripName`} 
                      element={
                        <Context_validatePlanTrip>
                        <Context_globalNavigationPlanTrip>

                          <PlanTripHome  />

                        </Context_globalNavigationPlanTrip>
                        </Context_validatePlanTrip>
                      } /> 
                      

                      {/* //*district */}

                      <Route exact path={`/plan-complete-trip/:districtName/:stateName`} 
                      element={
                        <Context_validatePlanTrip>
                         <Context_globalNavigationPlanTrip>

                            <PlanTripHome  />

                         </Context_globalNavigationPlanTrip>
                         </Context_validatePlanTrip>
                      } /> 

                      <Route exact path="/plan-complete-trip/:districtName/:stateName/:tripId/:tripName" element={
                      <Context_validatePlanTrip>
                        <Context_globalNavigationPlanTrip>

                            <PlanTripHome  />

                        </Context_globalNavigationPlanTrip>
                      </Context_validatePlanTrip>
                      } />


                      {/* //*destination */}

                      <Route exact path={`/plan-trip-to-:cityName/:districtName/:stateName`} 
                      element={
                        <Context_validatePlanTrip>
                        <Context_globalNavigationPlanTrip>

                          <PlanTripHome  />

                        </Context_globalNavigationPlanTrip>
                        </Context_validatePlanTrip>
                      } /> 

                      <Route exact path="/plan-trip-to-:cityName/:districtName/:stateName/:tripId/:tripName" element={
                        <Context_validatePlanTrip>
                        <Context_globalNavigationPlanTrip>

                          <PlanTripHome  />

                        </Context_globalNavigationPlanTrip>
                        </Context_validatePlanTrip>
                      } />


                      <Route exact path={`privacy_policy`} element={<PrivacyPolicy />} />
                      <Route exact path={`contact_us`} element={<ContactUs />} /> 
                      <Route exact path={`about_us`} element={<AboutUs />} /> 
                      {/* <Route exact path={`manage_login`} element={<ManageLogin />} />  */}
                      {/* manage_login */}


                                        {/*//!AI Itinerary NSCS*/}
                        {/* <Route exact path={'dt/001/create/itinerary'} element={<CreateItinerary />} /> */}
                      {/* developement tools  end*/}

                      {/* not client side code ends */} 
                      {/* //!!!!!!!!!! */}

                      {/*//! Tip Tap Rich Text Editor  */}
                      {/* <Route exact path={'text-editor/tip-tap'} element={<TipTap_Test />} /> */}

                      <Route exact path={'home'} element={<Home />} />

                       {/* testing - new code structure */}
                       {/* <Route exact path={'test/new'} element={<Main_C />} /> */}
                       {/* <Route exact path={'test/react/context'} element={<Componenet_Main />} /> */}

                       {/* result ai */}
                      
                        <Route exact path={'ai-tool/discover-nearby/:unique_id'} element={
                            <Result_NearBy_AI />
                        } />

                        <Route exact path={'ai-tool/how-to-reach/explore-travel-modes/:unique_id'} element={
                            <Result_HowToReach_AI />
                        } />

                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id'} element={
                            <Result_EverythingFamous_AI />
                        } />

                        <Route exact path={'ai-tool/create-itinerary-by-ai/:unique_id'} element={
                          <Context_AiCreateItinerary>
                            <Context_AiCreateItinerary_2>

                              <Ai_CreateItinerary/>
                            
                            </Context_AiCreateItinerary_2>
                          </Context_AiCreateItinerary>
                        } />

                        <Route exact path={'notes'} element={
                            <Editor />
                        } />

                        //!Notes_tool
                        <Route exact path={'notes-for-production'} element={
                            <Notes_Tool />
                        } />
  
                        {/* AdventureActivities, AmusementParks, Bars_and_Pubs, Hotels, Rent, Restaurants, ShoppingMalls, ShoppingStreet */}
                        //! direct travel categories new
                        {/* //*AdventureActivities */}
                        <Route exact path={'discover/Adventure-Activities'} element={
                          <AdventureActivitiesContextProvider>
                            <AdventureActivities />
                          </AdventureActivitiesContextProvider>
                        } />

                        {/* //*AmusementParks */}
                        <Route exact path={'discover/Amusement-Parks'} element={
                          <AmusementParksContextProvider>
                            <AmusementParks />
                          </AmusementParksContextProvider>
                        } />

                        {/* //*Bars_and_Pubs */}
                        <Route exact path={'discover/Bars-and-Pubs'} element={
                          <Bars_and_PubsContextProvider>
                            <Bars_and_Pubs />
                          </Bars_and_PubsContextProvider>
                        } />

                        {/* //*hotels */}
                        <Route exact path={'discover/Hotels'} element={
                          <HotelsContextProvider>
                            <Hotels />
                          </HotelsContextProvider>
                        } />

                        <Route exact path={'discover/Hotels/:hotelType'} element={
                          <HotelsContextProvider>
                            <Hotels />
                          </HotelsContextProvider>
                        } />

                        {/* //*Rent */}
                        <Route exact path={'discover/Rent'} element={
                          <RentContextProvider>
                            <Rent />
                          </RentContextProvider>
                        } />

                        {/* //*Restaurants */}
                        <Route exact path={'discover/Restaurants'} element={
                          <RestaurantsContextProvider>
                            <Restaurants />
                          </RestaurantsContextProvider>
                        } />

                        {/* //*ShoppingMalls */}
                        <Route exact path={'discover/Shopping-Malls'} element={
                          <ShoppingMallsContextProvider>
                            <ShoppingMalls />
                          </ShoppingMallsContextProvider>
                        } />

                        {/* //*ShoppingStreet */}
                        <Route exact path={'discover/Shopping-Street/Street-Market'} element={
                          <ShoppingStreetContextProvider>
                            <ShoppingStreet />
                          </ShoppingStreetContextProvider>
                        } />

                        //*how to reach 
                        {/* <Route exact path={'discover-travel-modes/:firstMode/:secondMode/:thirdMode'} element={
                          <HowToReachContextProvider>
                            {
                              isMobile ? 
                              <HowToReach_Main_Mobile /> 
                              :
                              <HowToReach_Main />
                            }
                          </HowToReachContextProvider>
                        } /> */}

                        {
                          isMobile ? 

                          <Route exact path={'discover-travel-modes/:firstMode/:secondMode/:thirdMode'} element={
                            <HowToReachContextProvider_Mobile>
                              <HowToReach_Main_Mobile /> 
                            </HowToReachContextProvider_Mobile>
                          } />

                          :

                          <Route exact path={'discover-travel-modes/:firstMode/:secondMode/:thirdMode'} element={
                            <HowToReachContextProvider>
                              <HowToReach_Main />
                            </HowToReachContextProvider>
                          } />

                        }



                        
                        {
                          isMobile ? 

                          <Route exact path={'how-to-reach/explore-travel-modes/train-air-bus'} element={
                            <HowToReachContextProvider_Mobile>
                              <HowToReach_Main_Mobile /> 
                            </HowToReachContextProvider_Mobile>
                          } />

                          :

                          <Route exact path={'how-to-reach/explore-travel-modes/train-air-bus'} element={
                            <HowToReachContextProvider>
                              <HowToReach_Main />
                            </HowToReachContextProvider>
                          } />

                        }

                        //? :: no pre travel mode set. Search from very new 
                        {/* <Route exact path={'how-to-reach/explore-travel-modes/train-air-bus'} element={
                          <HowToReachContextProvider>
                            {
                              isMobile ? 
                              <HowToReach_Main_Mobile /> 
                              :
                              <HowToReach_Main />
                            }
                          </HowToReachContextProvider>
                        } /> */}

                        //!not yet handled
                        //? :: get the results on the page from the url; by sending the destination name, destinations type and travel type/travel mode,     
                        <Route exact path={'how-to-reach/explore-travel-modes/:nameDestination/:typeDestination/:travelType'} element={
                          <HowToReachContextProvider>
                            <HowToReach_Main />
                          </HowToReachContextProvider>
                        } />

                        //!Everything Famous - ai tool

                        {/* Must Visit Tourist Locations */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-00'} element={
                          <TouristPlacesContextProvider>
                            <TouristPlaces />
                          </TouristPlacesContextProvider> 
                        } />

                        {/* Top Attraction Types */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-01'} element={
                          <AttractionTypesContextProvider>
                            <AttractionTypes />
                          </AttractionTypesContextProvider> 
                        } />

                        {/* Best Season to Explore a Destination */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-02'} element={
                          <SeasonsContextProvider>
                            <Seasons />
                          </SeasonsContextProvider> 
                        } />

                        {/* Popular Destinations in a Season */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-03'} element={
                          <DestinationsContextProvider>
                             <Destinations />
                          </DestinationsContextProvider> 
                        } />

                        {/* Top Rated Hotels */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-04'} element={
                          <HotelsContextProvider>
                            <Hotels />
                          </HotelsContextProvider> 
                        } />

                        {/* Famous Restaurants */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-05'} element={
                          <TouristPlacesContextProvider>
                            <Restaurants />
                          </TouristPlacesContextProvider> 
                        } />

                        {/* Popular Bars and Pubs */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-06'} element={
                          <TouristPlacesContextProvider>
                            <Bars_and_Pubs />
                          </TouristPlacesContextProvider> 
                        } />

                        {/* Famous Amusement Parks */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-07'} element={
                          <TouristPlacesContextProvider>
                            <AmusementParks />
                          </TouristPlacesContextProvider> 
                        } />

                        {/* Top Rated Adventure Activities */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-08'} element={
                          <TouristPlacesContextProvider>
                            <AdventureActivities />
                          </TouristPlacesContextProvider> 
                        } />

                        {/* Top Rated Bike Rentals */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-09'} element={
                          <RentContextProvider>
                            <Rent />
                          </RentContextProvider> 
                        } />

                        {/* Top Rated Car Rentals  */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-010'} element={
                          <RentContextProvider>
                            <Rent />
                          </RentContextProvider> 
                        } />

                        {/* Famous Shopping Malls  */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-011'} element={
                          <TouristPlacesContextProvider>
                            <ShoppingMalls />
                          </TouristPlacesContextProvider> 
                        } />

                        {/* Must Explore Local Shopping Markets */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-012'} element={
                          <TouristPlacesContextProvider>
                            <ShoppingStreet />
                          </TouristPlacesContextProvider> 
                        } />

                        {/* Well-Known Cities/Towns */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-013'} element={
                          <DestinationsContextProvider>
                            <Destinations />
                          </DestinationsContextProvider> 
                        } />

                        {/* Popular Districts */}
                        <Route exact path={'ai-tool/discover-everything-famous/:unique_id/ef-014'} element={
                          <DestinationsContextProvider>
                            <Destinations />
                          </DestinationsContextProvider> 
                        } />


                        //!Nearby - ai tool

                        {/* Must Visit Tourist Locations */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-00/:ai_feature_id'} element={
                          <TouristPlacesContextProvider>
                            <TouristPlaces />
                          </TouristPlacesContextProvider> 
                        } />

                        {/* Top Attraction Types */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-01/:ai_feature_id'} element={
                          <AttractionTypesContextProvider>
                            <AttractionTypes />
                          </AttractionTypesContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                          {/* //?nb-02  */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/:navigationId/:ai_feature_id'} element={
                          <HowToReachContextProvider>
                            <HowToReach_Main />
                          </HowToReachContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                           {/* //?nb-03  */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/navigationId/:ai_feature_id'} element={
                          <HowToReachContextProvider>
                            <HowToReach_Main />
                          </HowToReachContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                           {/* //?nb-04  */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/navigationId/:ai_feature_id'} element={
                          <HowToReachContextProvider>
                            <HowToReach_Main />
                          </HowToReachContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-05/:ai_feature_id'} element={
                          <HotelsContextProvider>
                            <Hotels />
                          </HotelsContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-06/:ai_feature_id'} element={
                          <RestaurantsContextProvider>
                            <Restaurants />
                          </RestaurantsContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-07/:ai_feature_id'} element={
                          <Bars_and_PubsContextProvider>
                            <Bars_and_Pubs />
                          </Bars_and_PubsContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-08/:ai_feature_id'} element={
                          <AmusementParksContextProvider>
                            <AmusementParks />
                          </AmusementParksContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-09/:ai_feature_id'} element={
                          <AdventureActivitiesContextProvider>
                            <AdventureActivities />
                          </AdventureActivitiesContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-010/:ai_feature_id'} element={
                          <RentContextProvider>
                            <Rent />
                          </RentContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-011/:ai_feature_id'} element={
                          <RentContextProvider>
                            <Rent />
                          </RentContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-012/:ai_feature_id'} element={
                          <ShoppingMallsContextProvider>
                            <ShoppingMalls />
                          </ShoppingMallsContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-013/:ai_feature_id'} element={
                          <ShoppingStreetContextProvider>
                            <ShoppingStreet />
                          </ShoppingStreetContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-014/:ai_feature_id'} element={
                          <DestinationsContextProvider>
                            <Destinations />
                          </DestinationsContextProvider> 
                        } />

                         {/* Must Visit Tourist Locations */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-015/:ai_feature_id'} element={
                          <DestinationsContextProvider>
                            <Destinations />
                          </DestinationsContextProvider> 
                        } />

                         {/* Well-Known Cities/Towns */}
                        <Route exact path={'ai-tool/discover-nearby/:unique_id/nb-016/:ai_feature_id'} element={
                          <DestinationsContextProvider>
                            <Destinations />
                          </DestinationsContextProvider> 
                        } />

                        {/* //!delete me */}
                        {/* <Route exact path={'delete-me'} element={
                          <DestinationsContextProvider>
                            <ItineraryAI_CI />
                          </DestinationsContextProvider> 
                        } /> */}

                        //!B2B 
                        //*Hotel
                        {/* Well-Known Cities/Towns */}
                          <Route exact path={'stay-revolution/list-hotel/get-direct-booking'} element={
                            <Hotel_HomePage />
                        } />


                        //! new

                      {/* // chnage : added below  */}
                       {/* 👇️ only match this when no other routes match */}
                        <Route path="*" element={<Error404 />} />

                  </Routes>



      </div> 
    </Router> 
  ); 
}

export default App; 
