  //@@ queries

   // first prompt
   export const get_prompt_1 = [
    "What are you look for?",
    "Select your prefrence!!",
    "Find famous things:"
  ];

  
  // second propmt
  export const prompt_all_destinations = [
    "Enter the destination.",
    "Where do you wish to find famous things?"
  ];

  export const prompt_district_state = [
    "Type in you district or state",
    "District or State; enter the destinations you wish to explore :)"
  ];

  export const prompt_states = [
    "Enter the state you wish to explore.",
    "Which state you would like to explore?"
  ];

  export const prompt_season = [
    "Select the Season",
    "In which season are you planning to travel?"
  ];

  export const prompt_india_division = [
    "Select the region you wish to explore:",
    "Which part of India you wish to find destinations in?"
  ];
 
