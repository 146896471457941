import React, {useState, useEffect} from 'react'

//importing react context
import {useGlobalNavigationPlanTrip} from '../../Manage - Plan Trip/Context/GlobalNavigationPlanTrip';
import { useValidatePlanTrip } from '../../Manage - Plan Trip/Context/ValidatePlanTrip';
import ManageLogin from '../../../../Manage User Credentials/User Login/ManageLogin';
import CreateTrip from '../../Plan Trip - Components/Create Trip/CreateTrip';

//user credentitals - cookies
import {getCookies_userCredentials, parseUserCredentialsData} from '../../../../Cookies/LoginCredentitals'

//import css
import './PlanTripHeader.scss'

import { Helmet } from 'react-helmet' 
import { useNavigate } from 'react-router-dom';


function PlanTripHeader() {

  const navigate = useNavigate();

  // using react use context
  const { activeFeature, setActiveFeature } = useGlobalNavigationPlanTrip();
  // about, trip_plan, create_itinerary, trip_overview
  const { tripId, tripName, tripDestinationName, tripDestinationType, stateName } = useValidatePlanTrip();

  // use cookies 
  const [userEmail, setUserEmail] = useState();
  const [loginStatus, setLoginStatus] = useState()

  
  useEffect(() => {
    
    handleGetUserLoginStatus();
    
  }, [])
  
  
  const handleGetUserLoginStatus = () => {

    const userDataCookie = getCookies_userCredentials("userData");
    // console.log("userDataCookie", userDataCookie);
    if (userDataCookie) {
      const userData = parseUserCredentialsData(userDataCookie);
      if (userData) {
          setLoginStatus(userData.email);
          setUserEmail(userData.email);
          // console.log("Login status:", userData.loginStatus);
      }
    } else {
      // console.log("User data cookie not found. - handleGetUserLoginStatus");
    }

  }


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 899);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 899); // Update state on window resize
    };
  
    window.addEventListener('resize', handleResize);
  
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  
  const getShortenedTripDestinationName = (name) => {
    const words = name.split(' ');
    // console.log("words.length", words.length);
    if (words.length > 4) {
      return words.slice(0, 4).join(' ') + '...';
    }
    return name;
  };


  return (
    <div className='Main-PlanTripHeader'> 

<Helmet>

<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Assistant:wght@200&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Maven+Pro&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />
<link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

</Helmet>

        {/* <div
        className='conatiner_p-line-PlanTripHeader'
        >
          <div className='line-PlanTripHeader'></div>
        </div> */}

        {(isMobile && !loginStatus) && 
          <div
          className='conatiner_p-login-PlanTripHeader'
          >   &nbsp; &nbsp;&nbsp;&nbsp;
              <div
              className='heading-login-PlanTripHeader'
              >
                  Login to Your Trip
              </div>    
              <div
              className='container_t-login-PlanTripHeader'
              >
                  <ManageLogin />
              </div>
          </div>
        }


      {
        !isMobile &&
        <button
        className='container_t-1_1-logo-PlanTripHeader'
        onClick={()=>{navigate('/')}}
        >
          <img 
          className='img-logo-site-1-PlanTripHeader'
          alt='preparetrip.com website main logi. An ai travel marker. A marker, with some intelligence.'
          src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/prepare_trip_logo_1.png'
          />
        </button>
      }  

      <div className='container_p-PlanTripHeader'>

        <div
          className='conatiner_p-line-PlanTripHeader'
        >
          <div className='line-PlanTripHeader'></div>
        </div>

        <div className='container_s-PlanTripHeader'>

          <button onClick={()=>{setActiveFeature("about")}}
          className={`${activeFeature === "about" ? "button-active-feature-PlanTripHeader" : "button-unActive-feature-PlanTripHeader"}`} 
          >
             <img 
               className={activeFeature === "about" ? "img-active-feature-PlanTripHeader" : "img-unActive-feature-PlanTripHeader"}
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/travel_around_icon_2.png'
             alt="about the tourist destination" 
             />


              {/* { */}
              {/* // activeFeature === "about" &&  */}
             <div 
             className={activeFeature === "about" ? "heading-active-feature-PlanTripHeader" : "heading-unActive-feature-PlanTripHeader"}
             >
              About Destination
              {/* About {tripDestinationName && tripDestinationName.length > 6 
              ? tripDestinationName.substring(0, 12) + '...' 
              : "Destination"} */}
             </div>  
          {/* } */}

          </button>

          <div className='line-divide-PlanTripHeader'></div>

          <button onClick={()=>{setActiveFeature("trip_plan")}}
          className={`${activeFeature === "trip_plan" ? "button-active-feature-PlanTripHeader" : "button-unActive-feature-PlanTripHeader"}`} 
          >
             <img 
               className={activeFeature === "trip_plan" ? "img-active-feature-PlanTripHeader" : "img-unActive-feature-PlanTripHeader"}
               src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/tourist_place_icon_2.png'
              alt="plan trip" 
             />

            {/* { */}
              {/* // activeFeature === "trip_plan" &&  */}

             <div 
             className={activeFeature === "trip_plan" ? "heading-active-feature-PlanTripHeader" : "heading-unActive-feature-PlanTripHeader"}
             >
                Plan Trip
             </div>  
            {/* // } */}

          </button>

          <div className='line-divide-PlanTripHeader'></div>

          <button onClick={()=>{setActiveFeature("create_itinerary")}}
          className={`${activeFeature === "create_itinerary" ? "button-active-feature-PlanTripHeader" : "button-unActive-feature-PlanTripHeader"}`} 
          >
             <img 
              className={activeFeature === "create_itinerary" ? "img-active-feature-PlanTripHeader" : "img-unActive-feature-PlanTripHeader"}
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/overview_icon_4.png'
              alt="create itinerary" 
             />

          {/* { */}
              {/* // activeFeature === "create_itinerary" &&  */}
             <div 
             className={activeFeature === "create_itinerary" ? "heading-active-feature-PlanTripHeader" : "heading-unActive-feature-PlanTripHeader"}
             >
                Create Itinerary
             </div>  
          {/* } */}

          </button>

          <div className='line-divide-PlanTripHeader'></div>

          <button onClick={()=>{setActiveFeature("trip_overview")}}
          className={`${activeFeature === "trip_overview" ? "button-active-feature-PlanTripHeader" : "button-unActive-feature-PlanTripHeader"}`} 
          >
             <img 
             className={activeFeature === "trip_overview" ? "img-active-feature-PlanTripHeader" : "img-unActive-feature-PlanTripHeader"}
             src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/trip_icon_1.png'
             alt="trip overview of you saved trip" 
             />


            {/* { */}
              {/* // activeFeature === "trip_overview" &&  */}
             <div 
             className={activeFeature === "trip_overview" ? "heading-active-feature-PlanTripHeader" : "heading-unActive-feature-PlanTripHeader"}
             >
                {/* My {tripDestinationName} Trip */}
                My Trip

             </div>  
            {/* } */}

          </button>

          {loginStatus && 
            <div >
                <CreateTrip />
            </div>
          }
    
        </div>

        {/* <div > */}
        {(!isMobile && !loginStatus) && 
          <div
          className='conatiner_p-login-PlanTripHeader'
          >
              <div
              className='heading-login-PlanTripHeader'
              >
                  Login to Your Trip
              </div>    
              <div
              className='container_t-login-PlanTripHeader'
              >
                  <ManageLogin />
              </div>
          </div>
        }

        {/* {!tripId && 
          <div
          className='conatiner_p-login-PlanTripHeader'
          >
              <div
              className='heading-login-PlanTripHeader'
              >
                  Create Trip
              </div>    
              <div
              className='container_t-2-login-PlanTripHeader'
              >
                  <CreateTrip />
              </div>
          </div>
        } */}
    

        {/* <div
        className='conatiner_p-line-PlanTripHeader'
        >
          <div className='line-PlanTripHeader'></div>
        </div> */}

      </div>
    </div>
  )
}

export default PlanTripHeader