import React, { useState, useEffect, useRef } from 'react'

//import scss
import './Search_Locations.scss'

import { Helmet } from 'react-helmet';

import axios from 'axios'

import { server_baseUrl } from '../../Server Side Hit Url/ServerHitUrlPart';

function Search_Locations(props) {

  const {handleSearchResultClick} = props;


  const containerRef = useRef(null);
  const[displaySearchResult, setDisplaySearchResult] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setDisplaySearchResult(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   handleSearch
  // }, [])

  const [userInput, setUserInput] = useState()
  const [inputResult, setInputResult] = useState([])

  const handleUserInput = async (event) => {
    const inputValue = event.target.value;
    
    //set the user input
    setUserInput(event.target.value)

    if(inputValue){

      const response = await axios.post(`${server_baseUrl}/csc/api/ai_tools/ai_components/nearby_ai/get_location_name`, {inputValue})
      
      // console.log("handleUserInput", response.data);
      
      if(response.data.length > 0){
        setInputResult([...response.data])
      }else{
        setInputResult([])
      }
    }
    else{
      setInputResult([])
    }
  }

  return (
    <div>

<Helmet>   

<link rel="preconnect" href="https://fonts.googleapis.com" />
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
<link href="https://fonts.googleapis.com/css2?family=Assistant:wght@400&family=Comfortaa:wght@300&family=Finlandica&family=Karla:wght@300&family=Kodchasan:wght@200&family=Lato:wght@300&family=Mitr:wght@300&family=Montserrat:wght@300&family=Nanum+Gothic&family=Nunito+Sans:opsz,wght@6..12,300&family=Oxygen&family=Poppins:wght@300&family=Quicksand:wght@300&family=Rubik&family=Satisfy&family=Space+Grotesk&family=Work+Sans:wght@300&family=Ysabeau+Office:wght@200&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap" rel="stylesheet" />

<link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap" rel="stylesheet" />

</Helmet>


      <div
      className='container_p-input-Search_Locations'
      ref={containerRef}
      >
        <input 
          placeholder='India Gate, Taj Mahal...' 
          value={userInput}
          onChange={handleUserInput}   
          onClick={()=>{setDisplaySearchResult(true)}}
          className='input-inputDate-Search_Locations'
        />
      
        { displaySearchResult && inputResult &&
          <div 
          className='results-container'
          >
            { inputResult.map((data, index) => {
              return(
                <div key={index} className='result-item'>
                  <button 
                  onClick={()=>{
                    handleSearchResultClick(data.name, data.id, data.state_name, data.lat, data.lng)
                    setUserInput(data.name)
                    setInputResult([])
                  }}
                  className='container_p-Content-Search_Locations'
                  >

                      <div
                      className='text-1-name-Search_Locations'
                      >
                        {data.name}
                      </div>

                      {
                        data.category &&
                        <div
                        className='text-2-category-Search_Locations'
                        >
                        {/* <i> a {data.category} </i> */}
                        a {data.category} 
                        </div>
                      }

                      {data.type !== "state" && data.state_name &&
                      <div
                      className='text-3-category-Search_Locations'
                      >
                        in {data.state_name}
                      </div> 
                      }

                  </button>
                </div>
              )
            })}
          </div>
        }

      </div>  

        {/* { displaySearchResult && inputResult &&
            inputResult.map((data, index) => {
              return(
                <div key={index}
                className='results-container'
                >
                  <button onClick={()=>{
                    handleSearchResultClick(data.name, data.id, data.state_name, data.lat, data.lng)
                    setUserInput(data.name)
                    setInputResult([])
                    }}
                  >
                    {data.name}, <i>a {data.category} in {data.state_name}</i>
                  </button>
                  &nbsp; <br></br>
                </div>  
              )
          })
        } */}

    </div>
  )
}

export default Search_Locations