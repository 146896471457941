//@@1

//this is how you can import define it:

// MyContext.js
import React, { createContext, useContext, useState } from 'react';

// Step 1: Create a new context
const CC_Main = createContext();


// Step 2: Create a context provider
export const AdventureActivitiesContextProvider = ({ children }) => {

    // console.log('AdventureActivitiesContextProvider invoked');

    const [testValue, setTestValue] = useState("Default Text");
  
    const handleCallTestFunction = (passedData) => {
        if(passedData){
            // console.log("got this data - ", passedData)
            setTestValue(passedData)
        }
        else{
            // console.log("passedData in empty")
            setTestValue(passedData)
        }
    }


  // Step 5: Return a provider with value containing state and functions
  return (
    <CC_Main.Provider value={{ 
        testValue, setTestValue, handleCallTestFunction
    }}>
      {children}
    </CC_Main.Provider>
  );
};

// Step 6: Create a custom hook to consume the context
export const useAdventureActivitiesContext = () => {
  return useContext(CC_Main);
};