import React, { useEffect, useState, useRef } from 'react';

// import scss 
import './Feedback_1.scss'

//
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


import axios, { Axios } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import {FiSearch} from "react-icons/fi"; 
import {FaMapPin} from "react-icons/fa"; 
import {HiOutlineMap} from "react-icons/hi"; 
import { Helmet } from 'react-helmet';
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";  

import { HiXCircle } from "react-icons/hi"; 
import { BsBookmark } from "react-icons/bs";
import { BsFillBookmarkFill } from "react-icons/bs";

import { Rating } from 'react-simple-star-rating';
// import Login from '../Plan/UserLogin/Login';

import { Button, Slider } from '@mui/material'

import {AiFillLinkedin} from 'react-icons/ai'
import {FaFacebook} from 'react-icons/fa'
import {BsTwitter} from 'react-icons/bs'
import {AiFillInstagram} from 'react-icons/ai'
import {BsYoutube} from 'react-icons/bs'
import {FcReddit} from 'react-icons/fc'

import Moment from 'moment' 

import { server_baseUrl } from '../../../Server Side Hit Url/ServerHitUrlPart';

function Feedback_1(props) {

  const {userEmail, feedbackLoadedStatus, setFeedbackLoadedStatus,
    feedbackGivenStatus, setFeedbackGivenStatus, feedbackWindow, setFeedbackWindow} = props;


  useEffect(() => {
    if(!feedbackLoadedStatus){
      loadFeedbackStatus();
    }
  }, [feedbackLoadedStatus])


  const loadFeedbackStatus = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/user/userprofile/load/feedback/status`, {userEmail})

    // console.log("handleLoadUserProfile - response.data", response.data)

    if(response.data == "1"){
      setFeedbackLoadedStatus(true)
      setFeedbackGivenStatus(true)
    }
    else if(response.data == "0"){
      setFeedbackLoadedStatus(true)
      setFeedbackGivenStatus(false)
    }
    else{
      alert("error in loading the user feedback. ")
    }
  }


  const updateFeedbackStatus = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/user/userprofile/update/feedback/status`, {userEmail, recommendNumber, feedback2Data, messageRecommend, visionMessageRecommend})

    // console.log("updateFeedbackStatus", response.data)

    if(response.data == "1"){
      setFeedbackGivenStatus(true)
      toast.success("Thanks for your feedback. :)", {
        position: toast.POSITION.TOP_CENTER
      })
    }
    else{
      toast.error("Error in updating. You can try again.", {
        position: toast.POSITION.TOP_CENTER
      })
    }
  }



  const [recommendNumber, setRecommendNumber] = useState(1)
  const [feedback2Data, setFeedback2Data ] = useState()
  const [messageRecommend, setMessageRecommend] = useState()
  const[visionMessageStatus, setVisionMessageStatus] = useState()
  const [visionMessageRecommend, setVisionMessageRecommend] = useState()

  const handleRecommendRange = (event) => {
    // console.log(event.target.value)
    setRecommendNumber(event.target.value)
  }

  const handleFeedback2 = (data) => {
    setFeedback2Data(data)                                      
  }

  const handleFeedbackMessage = (event) => {
    setMessageRecommend(event.target.value)
  }

  const handleVisionFeedbackMessage = (event) => {
    setVisionMessageRecommend(event.target.value)
  }

  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
    document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


  return (
    <div
    className='container_p-feedback_1'
    >

      {
        (feedbackWindow && feedbackLoadedStatus) &&

        <div
        className='popup-background-1-feedback_1'
        onClick={()=>{
          setFeedbackWindow(false)
          closePopup()
        }}
        >
          <div
          className='popup_p-2-addNote-feedback_1'
          onClick={(e)=>{
            e.stopPropagation()
          }}
          >
            {
              !feedbackGivenStatus && 
            

            <div className='container_p-giveFeedback-Feedback_1'>

              <div
              className='text-1-Feedback_1'
              >
                Feedback
              </div>

              <div
              className='heading-1-Feedback_1'
              >
                How likely are you to recommend us for planning a trip?
              </div> 

              <div
              className='container_p-slider-Feedback_1'
              >

                <Slider
                  aria-label="Temperature"
                  value={recommendNumber}
                  style={{width:'60%', height:'4%'}}
                  // getAriaValueText={valuetext}
                  valueLabelDisplay="auto"
                  // width:{30%}
                  step={1}
                  marks
                  min={1}
                  max={10}
                  onChange={handleRecommendRange} 
                /> 

                {/* <div>
                  {recommendNumber}
                </div>      */}
              
              </div>
            

              {/* <div className='line-1-Feedback_1'></div> */}
              <br></br>


              <div
              className='heading-1-Feedback_1'
              >
                {/* How would you feel if we haven't met.  */}
                If our trip planning service did not exist, how would you feel?
              </div>

              <div
              className='container_p-buttons-Feedback_1'
              >

                <button onClick={()=>{handleFeedback2("Very Disappointed")}}
                className={feedback2Data === "Very Disappointed" ? 'button-active-recommend-Feedback_1' : 'button-unActive-recommend-Feedback_1'}
                >
                  Very Disappointed
                </button>

                <button onClick={()=>{handleFeedback2("Somewhat Disappointed")}}
                className={feedback2Data === "Somewhat Disappointed" ? 'button-active-recommend-Feedback_1' : 'button-unActive-recommend-Feedback_1'}
                >
                  Somewhat Disappointed
                </button>

                <button onClick={()=>{handleFeedback2("Not Disappointed")}}
                className={feedback2Data === "Not Disappointed" ? 'button-active-recommend-Feedback_1' : 'button-unActive-recommend-Feedback_1'}
                >
                  Not Disappointed  
                </button> 

              </div> 

              <div className='line-1-Feedback_1'></div>

              <div
              className='heading-1-Feedback_1'
              >
                Any feedback for us? (Optional).
              </div>
              <textarea
                value={messageRecommend}
                onChange={handleFeedbackMessage}
                placeholder='enter your message... (Max 200 words)'
                className='textArea-1-Feedback_1'
                maxLength={200}
              />

                <br></br>
              <div
              className='heading-1-vision-Feedback_1'
              >
                {
                  visionMessageStatus &&
                  <div
                  className='container_p-vision-Feedback_1'
                  >
                    This is our misison. Let's have...
                  </div>
                }

                This is  
                <u 
                className='text-vision-Feedback_1'
                onMouseOver={()=>{setVisionMessageStatus(true)}}
                onMouseOut={()=>{setVisionMessageStatus(false)}}
                >
                  our mssion
                </u>
                . Any feedback on that? (Optional).
              </div>
              <textarea
                value={visionMessageRecommend}
                onChange={(e) => handleVisionFeedbackMessage(e)}
                placeholder='enter your message... (Max 200 words)'
                className='textArea-1-Feedback_1'
                maxLength={200}
              />
              

              {
                (recommendNumber > 1 && feedback2Data) ?
                <button
                onClick={()=>{
                  updateFeedbackStatus()
                  closePopup()
                }}
                className='button-submit-Feedback_1'
                >
                  Submit Feedback
                </button>
                :
                <button
                disabled={true}
                className='button-submit-Feedback_1'
                >
                  Submit Feedback
                </button>
              }
            
            </div>  

            }


            {/*//! */}
            {
              (feedbackLoadedStatus && feedbackGivenStatus) &&
              <div
              className='feedback-thank-you'
              >
                <h3 className="thank-you-heading">
                  You've already submitted feedback. 
                </h3>
                <p className="feedback-note">
                  {/* Your input is invaluable to us.  */}
                  If you have any additional comments, feel free to share them at    
                  {/* reach out to us at */}
                   
                  <a href="mailto:contact@preparetrip.com" className="email-link"> contact@preparetrip.com</a>.
                </p>
              </div>
            }

          </div>

        </div>
      }

   

      {/*//! */}
      {
        feedbackLoadedStatus && !feedbackGivenStatus &&
        <div
        className='container_p-feedbackGiven-Feedback_1'
        >
          <button
          onClick={()=>{
            setFeedbackWindow(true)
            openPopup()
          }}
          className='button-feedbackGiven-Feedback_1'
          >
            Give Us Feedback. It'll help :)
          </button>
        </div>
      }

      <ToastContainer />

    </div>
  )
}

export default Feedback_1