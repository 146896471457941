import React from 'react'

// import scss
import './About_VM.scss'

//
import ReactMarkdown from 'react-markdown';

function About_VM(props) {

  const{aboutData_Props} = props

  return (
    <div>
    
      <div
      className='conatiner_p-data-About_VM'
      >
        <div
        className='content-1-data-About_VM'
        >
          <ReactMarkdown>{aboutData_Props}</ReactMarkdown>
        </div>
      </div>  

    </div>
  )
}

export default About_VM