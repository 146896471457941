import React from 'react'

//import css
import './Reach_D.scss'

//importing internal components
import Map_D from '../Map_D/Map_D'

//import react icons
import { FaPlaneDeparture } from "react-icons/fa";
import { GiRailRoad } from "react-icons/gi";
import { FaBusAlt } from "react-icons/fa";
import { GrLocationPin } from "react-icons/gr";


//importing react context
import { useValidatePlanTrip } from '../../../../Manage - Plan Trip/Context/ValidatePlanTrip'
import { useAboutDestinationPlanTrip } from '../../Manage/Context/AboutDestination_Context'


function Reach_D() {
    
    // using react context
    const {
        destinationLat, destinationLng, destinationZoom, tripDestinationName, tripDestinationType
    } = useValidatePlanTrip()
    
    const {
        //Reach
        travelMode_Air_Data, travelMode_Train_Data, travelMode_Bus_Data, 
        //
        activeTravelMode,
        //
        handleMainCategoryClick, loadReachData,
        //
        handleReachClickMap, handleHoverReachMap, handleHoverOutReachMap,
        //
        setActiveReachPlace_Map
    } = useAboutDestinationPlanTrip()


  return (
    <div>

        {/* <div className='reach-MainContentMapContainer'> */}


        {/* <div className='famousPlacesText-box3-1'>
            Modes to Reach {tripDestinationName}
        </div>  */}

        <div 
        className='container_p-navigation-Reach_D'
        >
            {/* <div> */}
            <button 
            onClick={()=>{loadReachData("air")}}
            className={activeTravelMode === "air" ? 'active-button-Reach_D' : 'unActive-button-Reach_D'}
            >
                <div className='heading-modeName-Reach_D'>
                    Air
                </div>
                <div className='icon-modeName-Reach_D'>
                    <FaPlaneDeparture />
                </div>
            </button>   
            {/* </div>  */}

            {/* <div> */}
            <button onClick={()=>{loadReachData("train")}}
            className={activeTravelMode === "train" ? 'active-button-Reach_D' : 'unActive-button-Reach_D'}
            >   
                <div className='heading-modeName-Reach_D'>
                    Train 
                </div>
                <div className='icon-modeName-Reach_D'>
                    <GiRailRoad />
                </div>
            </button>   
            {/* </div>  */}

            {/* <div> */}
            <button onClick={()=>{loadReachData("bus")}}
            className={activeTravelMode === "bus" ? 'active-button-Reach_D' : 'unActive-button-Reach_D'}
            >
                <div className='heading-modeName-Reach_D'>
                    Bus 
                </div>
                <div className='icon-modeName-Reach_D'>
                    <FaBusAlt />
                </div>
            </button>    
            {/* </div> */}

        </div>   

        {/* main container for the travel modes|| //!Start */}
        <div className='main_p-travelModes-Reach_D'>

       
        {
            activeTravelMode === "air" &&
            (

            <div>

                {/* <div className='heading-1-mode-Reach_D'>
                    International Airports
                </div>   */}

                {
                travelMode_Air_Data.find((data=> (data.mode_type === "international")))
                &&
                <div className='heading-1-mode-Reach_D'>
                    International Airports
                </div>    
                }      

                <div className='container_p-Cards-Air-Reach_D'>
                {
                    travelMode_Air_Data.map((data, index) => {
                        if(data.mode_type === "international"){
                        return(
                            <button 
                            className='card-Air-Reach_D'
                            onClick={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "air", data.mode_name, true)}}
                            onMouseOver={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "air", data.mode_name)}}
                            onMouseOut={()=>{
                                setActiveReachPlace_Map([])
                            }}
                            >
                                <div className='text-1-air-Reach_D'>
                                    {data.mode_name}
                                </div>

                                <div className='text-2-air-Reach_D'>
                                    Code: {data.mode_code}
                                </div>

                                <div className='text-3-air-Reach_D'>
                                    <div className='text-3-1-air-Reach_D'>
                                        in {data.mode_district} 
                                    </div>
                                    {data.mode_state_name && 
                                    <div className='text-3-1-air-Reach_D'>
                                        , {data.mode_state_name}.
                                    </div>
                                    }
                                </div>


                                { data.mode_g_review &&
                                <div className='text-4-air-Reach_D'>
                                    G reviews: ({data.mode_g_review.toLocaleString('en-IN')})
                                </div>
                                }  
                                
                             
                                { data.distance &&
                                tripDestinationType !== "state" ?
                                <div className='text-5-air-Reach_D'>
                                    <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {tripDestinationName}.
                                </div> :
                                null
                                }
                                
                            </button>    
                        )
                        } 
                    })
                }
                </div>


                {
                travelMode_Air_Data.find((data=> (data.mode_type === "domestic")))
                &&
                <div className='heading-1-mode-Reach_D'>
                    Domestic Airports
                </div>    
                }      


                <div className='container_p-Cards-Air-Reach_D'>
                {
                    travelMode_Air_Data.map((data, index) => {
                        if(data.mode_type === "domestic"){
                        return(
                            <button 
                            className='card-Air-Reach_D'
                            onClick={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "air", data.mode_name, true)}}
                            onMouseOver={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "air", data.mode_name)}}
                            onMouseOut={()=>{
                                setActiveReachPlace_Map([])
                            }}
                            >
                                <div className='text-1-air-Reach_D'>
                                    {data.mode_name}
                                </div>

                                <div className='text-2-air-Reach_D'>
                                    Code: {data.mode_code}
                                </div>

                                <div className='text-3-air-Reach_D'>
                                    <div className='text-3-1-air-Reach_D'>
                                        in {data.mode_district} 
                                    </div>
                                    {data.mode_state_name && 
                                    <div className='text-3-1-air-Reach_D'>
                                        , {data.mode_state_name}.
                                    </div>
                                    }
                                </div>

                                { data.mode_g_review &&
                                <div className='text-4-air-Reach_D'>
                                    G reviews: ({data.mode_g_review.toLocaleString('en-IN')})
                                </div>
                                }  
                                
                             
                                { data.distance && 
                                tripDestinationType !== "state" ?
                                <div className='text-5-air-Reach_D'>
                                    <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {tripDestinationName}.
                                </div> :
                                null
                                }

                            </button>    
                        )
                        } 
                    })
                }
                </div>

            </div>   
        )}

        {/* //!Train Data */}

        { activeTravelMode === "train" &&
            (

            <div>

                {/* <div className='heading-1-mode-Reach_D'>
                    Major Train Stations
                </div>      */}

                {
                travelMode_Train_Data.find((data=> (data.mode_type === "major")))
                &&
                <div className='heading-1-mode-Reach_D'>
                    Major Train Stations
                </div>    
                }      

                <div className='container_p-Cards-Air-Reach_D'>
                {
                travelMode_Train_Data.map((data, index) => {
                    if(data.mode_type === "major"){
                    return(
                        <button
                        className='card-Air-Reach_D'
                        onClick={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "train", data.mode_name, true)}}
                        onMouseOver={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "train", data.mode_name)}}
                        onMouseOut={()=>{
                            setActiveReachPlace_Map([])
                        }}
                        >
                            <div className='text-1-air-Reach_D'>
                                {data.mode_name}
                            </div>

                            <div className='text-2-air-Reach_D'>
                                Code: {data.mode_code}
                            </div>

                            <div className='text-3-air-Reach_D'>
                                <div className='text-3-1-air-Reach_D'>
                                    in {data.mode_state_name} 
                                </div>
                                {/* {data.mode_state_name && 
                                <div className='text-3-1-air-Reach_D'>
                                    , {data.mode_state_name}.
                                </div>
                                } */}
                            </div>

                            {/* {
                                tripDestinationType !== 'state' ?
                                <div>
                                    in {data.mode_state_name}.
                                </div>    
                                : 
                                tripDestinationName !== data.mode_state_name ?
                                <div>
                                    in {data.mode_state_name}.
                                </div> 
                                : null    
                            } */}

                            {
                              tripDestinationType !== "state" ?
                              <div className='text-5-air-Reach_D'>
                                <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {tripDestinationName}.
                              </div> :
                               null
                            }
                        </button>    
                    )} 
                    })
                }

                {/* <div className='heading-1-mode-Reach_D'>
                    Minor Train Stations
                </div>     */}

                {
                travelMode_Train_Data.find((data=> (data.mode_type === "minor")))
                &&
                <div className='heading-1-mode-Reach_D'>
                    Other Train Station
                </div>    
                }        

                <div className='container_p-Cards-Air-Reach_D'>
                {
                    travelMode_Train_Data.map((data, index) => {
                        if(data.mode_type === "minor"){
                        return(
                            <button
                            className='card-Air-Reach_D'
                            onClick={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "train", data.mode_name, true)}}
                            onMouseOver={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "train", data.mode_name)}}
                            onMouseOut={()=>{
                                setActiveReachPlace_Map([])
                            }}
                            >
                                <div className='text-1-air-Reach_D'>
                                    {data.mode_name}
                                </div>

                                <div className='text-2-air-Reach_D'>
                                    Code: {data.mode_code}
                                </div>

                                <div className='text-3-air-Reach_D'>
                                    <div className='text-3-1-air-Reach_D'>
                                        in {data.mode_state_name} 
                                    </div>
                                    {/* {data.mode_state_name && 
                                    <div className='text-3-1-air-Reach_D'>
                                        , {data.mode_state_name}.
                                    </div>
                                    } */}
                                </div>
                            
                            {
                                    tripDestinationType !== "state" ?
                                    <div className='text-5-air-Reach_D'>
                                    <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {tripDestinationName}.
                                    </div> :
                                    null
                                }
                            </button>    
                        )
                        } 
                    })
                }
                </div>

            </div>    

        </div>

        )}

        {/* //!Bus Data */}
        { activeTravelMode === "bus" &&
            (
            <div>

                {/* <div className='heading-1-mode-Reach_D'>
                    ISBT
                </div>     */}

                {
                travelMode_Bus_Data.find((data=> (data.mode_type === "isbt")))
                &&
                <div className='heading-1-mode-Reach_D'>
                    Inter-State Bus Terminals
                </div>    
                }    

                <div className='container_p-Cards-Air-Reach_D'>
                    {
                        travelMode_Bus_Data.map((data, index) => {
                            if(data.mode_type === "isbt"){
                            return(
                                <button
                                className='card-Air-Reach_D'
                                onClick={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "bus", data.mode_name, true)}}
                                onMouseOver={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "bus", data.mode_name)}}
                                onMouseOut={()=>{
                                    setActiveReachPlace_Map([])
                                }}
                                >
                                    <div className='text-1-air-Reach_D'>
                                        {data.mode_name}
                                    </div>

                                    {
                                        tripDestinationType === "state" ?
                                        <div className='text-3-1-air-Reach_D'>
                                            in {data.mode_state_name}.
                                        </div> 
                                        : null    
                                    }
                                   
                                   {
                                    tripDestinationType !== "state" ?
                                    <div className='text-5-air-Reach_D'>
                                        <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {tripDestinationName}.
                                    </div> :
                                    null
                                    }

                                </button>    
                            )
                            } 
                        })
                    }
                </div>      

                {/* <div className='heading-1-mode-Reach_D'>
                    CBS
                </div>         */}

                {
                    travelMode_Bus_Data.find((data=> (data.mode_type === "cbs")))
                    &&
                    <div className='heading-1-mode-Reach_D'>
                        Central Bus Stand
                    </div>    
                }

                <div className='container_p-Cards-Air-Reach_D'>
                    {
                        travelMode_Bus_Data.map((data, index) => {
                            if(data.mode_type === "cbs"){
                            return(
                                <button
                                className='card-Air-Reach_D'
                                onClick={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "bus", data.mode_name, true)}}
                                onMouseOver={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "bus", data.mode_name)}}
                                onMouseOut={()=>{
                                    setActiveReachPlace_Map([])
                                }}
                                >
                                    
                                    <div className='text-1-air-Reach_D'>
                                        {data.mode_name}
                                    </div>

                                    {
                                        tripDestinationType === "state" ?
                                        <div className='text-3-1-air-Reach_D'>
                                            in {data.mode_state_name}.
                                        </div> 
                                        : null    
                                    }
                                   
                                   {
                                    tripDestinationType !== "state" ?
                                    <div className='text-5-air-Reach_D'>
                                        <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {tripDestinationName}.
                                    </div> :
                                    null
                                    }

                                </button>    
                            )
                            }
                        })
                    }
                </div>      

                {/* <div className='heading-1-mode-Reach_D'>
                    Local
                </div>   */}

                {
                travelMode_Bus_Data.find((data=> (data.mode_type === "local")))
                &&
                <div className='heading-1-mode-Reach_D'>
                    Local Bus Stand
                </div>    
                }   

                <div className='container_p-Cards-Air-Reach_D'>
                    {
                        travelMode_Bus_Data.map((data, index) => {
                            if(data.mode_type === "local"){
                            return(
                                <button
                                className='card-Air-Reach_D'
                                onClick={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "bus", data.mode_name, true)}}
                                onMouseOver={()=>{handleReachClickMap(data.mode_lat, data.mode_lng, "bus", data.mode_name)}}
                                onMouseOut={()=>{
                                    setActiveReachPlace_Map([])
                                }}
                                >
                                   
                                    <div className='text-1-air-Reach_D'>
                                        {data.mode_name}
                                    </div>

                                    {
                                        tripDestinationType === "state" ?
                                        <div className='text-3-1-air-Reach_D'>
                                            in {data.mode_state_name}.
                                        </div> 
                                        : null    
                                    }
                                   
                                   {
                                    tripDestinationType !== "state" ?
                                    <div className='text-5-air-Reach_D'>
                                        <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {tripDestinationName}.
                                    </div> :
                                    null
                                    }

                                </button>    
                            )
                            } 
                        })
                    }
                </div> 

            </div>   
            )}  

        </div>
        {/* main container for the travel modes|| //!Start */}

    </div>
  )
}

export default Reach_D