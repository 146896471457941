import React, { useEffect, useState, useRef } from 'react';
import axios, { Axios } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import {FiSearch} from "react-icons/fi"; 
import {FaMapPin} from "react-icons/fa"; 
import {HiOutlineMap} from "react-icons/hi"; 
import { Helmet } from 'react-helmet';
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";  

import { HiXCircle } from "react-icons/hi"; 
import { BsBookmark } from "react-icons/bs";
import { BsFillBookmarkFill } from "react-icons/bs";
import { FaWindowClose } from "react-icons/fa";
import { LuHotel } from "react-icons/lu";
import { FaAlignRight } from "react-icons/fa";

import { Rating } from 'react-simple-star-rating';
// import Login from '../Plan/UserLogin/Login';

import { Button, Slider } from '@mui/material'

import {AiFillLinkedin} from 'react-icons/ai'
import {FaFacebook} from 'react-icons/fa'
import {BsTwitter} from 'react-icons/bs'
import {AiFillInstagram} from 'react-icons/ai'
import {BsYoutube} from 'react-icons/bs'
import {FcReddit} from 'react-icons/fc'
import { WiStars } from "react-icons/wi";

import { MdOutlineAccountCircle } from "react-icons/md";
import { VscAccount } from "react-icons/vsc";

import Moment from 'moment' 

import './_Home.scss'

import './Header.scss'
import './Header_Mobile.scss'

import ManageLogin from '../../../../Manage User Credentials/User Login/ManageLogin';

//
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

import { server_baseUrl } from '../../Server Side Hit Url/ServerHitUrlPart';


function Header(props) {

  const{userEmail, manageWaitlistLoaded, setManageWaitlistLoaded, scrollToFeedback, setScrollPageTo_TravelCategories, setScrollPageTo_AISection, manageLoginWindow, setManageLoginWindow} = props;

  const[discoverContentStatus_Mobile_Limited, setDiscoverContentStatus_Mobile_Limited] = useState()


  const[joinWaitlistStatus, setJoinWaitlistStatus] = useState()

  //how header on mobile
  const[showHeaderMobile, setShowHeaderMobile] = useState(false)


  
  useEffect(() => {
    if(!manageWaitlistLoaded && userEmail){
      handleLoadUserProfile()
    }
  }, [userEmail])

  const handleLoadUserProfile = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/user/userprofile/load/userprofile/waitlist`, {userEmail})

    // console.log("handleLoadUserProfile - response.data", response.data)

    if(response.data == "1"){
      setJoinWaitlistStatus(true)
      setManageWaitlistLoaded(true)
    }
    else if(response.data == "0"){
      setJoinWaitlistStatus(false)
      setManageWaitlistLoaded(true)
    }
    else{
      alert("error in loading the user profile. ")
    }
  }


  const handleUpdateUserProfile = async () => {

    const response = await axios.post(`${server_baseUrl}/csc/user/userprofile/update/userprofile/waitlist`, {userEmail})

    if(response.data == "1"){
      setJoinWaitlistStatus(true)

      toast.success("Joined successfully. We'll update you!", {
        position: toast.POSITION.TOP_CENTER
      })
    }
    else{
      toast.error("Error in updating. You can try again.", {
        position: toast.POSITION.TOP_CENTER
      })
    }
  }


  const handleManageLoginClicked = () => {
    if(manageWaitlistLoaded){

      if(!userEmail){
        toast.error("You are not logged in!", {
          position: toast.POSITION.TOP_CENTER
        })
      }
      else if(!joinWaitlistStatus){
        handleUpdateUserProfile()
      }
    }
    else{
      toast.error("Please login.", {
        position: toast.POSITION.TOP_CENTER
      })
    }
  }


  const openPopup = () => {
    document.body.style.overflow = 'hidden'; // Disable scrolling on the body
  };

  const closePopup = () => {
    document.body.style.overflow = 'auto'; // Enable scrolling on the body
  };


const urlMain = 'https://us-central1-preparetrip-node-jul01.cloudfunctions.net/api'


  return (
    <div>

      {
        manageLoginWindow && 
        <div>
          <div
          className='popup-background-1-Header'
          onClick={()=>{
            setManageLoginWindow(false)
            closePopup()
          }}
          >
            <div
            className='popup_p-2-addNote-Header'
            onClick={(e)=>{
              e.stopPropagation()
            }}
            >
              <div
              className='text-3-manageLogin-Header'
              >
                Manage Login
              </div>

              <br></br>
              <div
               className='text-4-manageLogin-Header'
              >
                {userEmail}
              </div>

              <br></br>
              <ManageLogin />

              <div
              className='line-1-manageLogin-Header'
              ></div>

              <div
              className='text-1-manageLogin-Header'
              >
                <u className='text-0-manageLogin-Header'>A traveller?</u> Do you have a travel profile that you can share to the world? 
              </div>

              <div
              className='text-2-manageLogin-Header'
              >
                We have something for you.
              </div>

              <div>
                {
                  joinWaitlistStatus ?
                  <button
                  className='button-joined-manageLogin-Header'
                  >
                    You have joined waitlist. We'll update you soon.
                  </button>
                  :
                  <button
                  className='button-notJoined-manageLogin-Header'
                  onClick={(e)=>{
                    handleManageLoginClicked()
                    e.stopPropagation()
                  }}
                  >
                    Join the waitlist.
                  </button>
                }
              </div>

            </div>
          </div>
        </div>
      }


      {/*//! Website */}
      {/*//! */}
      <div className='container_p-header'>

        <div
        className='container_t-1-logo_tag-header'
        >
          <div
          className='container_t-1_1-logo-header'
          >
            <img 
            className='img-logo-site-1-header'
            alt='preparetrip.com website main logi. An ai travel marker. A marker, with some intelligence.'
            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/prepare_trip_logo_1.png'
            />

          </div>

          <div
          className='container_t-1_2-tag-header'
          >

            <h1
            className='heading-prepareTrip-1-tag-header'
            >
              Prepare Trip
            </h1>

            <h2
            className='heading-prepareTrip-2-tag-header'
            >
              Miss Nothing!              
            </h2>

            <h2
            className='heading-prepareTrip-3-tag-header'
            >
              {/* In-country or adroad, truly explore.  */}
              Truly Plan Your Trip. In-country or abroad.
            </h2>

          </div>


        </div>

        {/* //!Website */}
        <div
        className='container_t-2-site_navigation-header'
        >

          <button
          className='button-listHotel-header'
          onClick={()=>{setScrollPageTo_AISection("my_ai_trips")}}
          >
            List your hotel <LuHotel />
          </button>
              
s
          {/* display for zoom below 125% */}
          <div
          className='container_t-discover-header'
          >

            {/* <h3
            className='heading-discover-header'
            >
              Discover:
            </h3> */}

            {/* <div> */}
              {/* <button
              className='button-1-stays-header'
              >
                Stays
              </button>
              <button
              className='button-1-stays-header'
              >
                Eat
              </button>
              <button
              className='button-1-stays-header'
              >
                Activity
              </button>
              <button
              className='button-1-stays-header'
              >
                Rentals
                </button> */}
                
              <button
              className='button-1-stays-header'
              onClick={()=>{setScrollPageTo_AISection("my_ai_trips")}}
              >
                Plan with AI <WiStars />
              </button>
              
              <button
              className='button-1-stays-header'
              onClick={()=>{scrollToFeedback("travel_modes")}}
              >
                Travel Modes
              </button>

            {/* </div> */}

          </div>


          {/* display for zoom above 125% */}
          <div
          className='container_t-discover-header-2'
          >

            <h3
            className='heading-discover-header'
            onMouseOver={()=>{setDiscoverContentStatus_Mobile_Limited(true)}}
            onMouseOut={()=>{setDiscoverContentStatus_Mobile_Limited(false)}}
            >
              Discover +
              {/* <u>Discover:</u> */}
            </h3>

            {/* <div> */}
            {
              discoverContentStatus_Mobile_Limited &&
              <div 
              className='container_t-discoverContent-header-1'
              onMouseOver={()=>{setDiscoverContentStatus_Mobile_Limited(true)}}
              onMouseOut={()=>{setDiscoverContentStatus_Mobile_Limited(false)}}
              >
                <div>
                  <button
                  className='button-1-stays-header'
                  >
                    Stays
                  </button>
                </div>

                <div>
                  <button
                  className='button-1-stays-header'
                  >
                    Eat
                  </button>
                </div>

                <div>
                  <button
                  className='button-1-stays-header'
                  >
                    Activity
                  </button>
                </div>  

                <div>
                  <button
                  className='button-1-stays-header'
                  >
                    Rentals
                  </button>
                </div>

                <div>
                  <button
                  className='button-1-stays-header'
                  onClick={()=>{scrollToFeedback("travel_modes")}}
                  >
                    Travel Modes
                  </button>
                </div>

                <div>
                  <button
                  className='button-1-stays-header'
                  onClick={()=>{setScrollPageTo_AISection("my_ai_trips")}}
                  >
                    Plan with AI   <WiStars />
                  </button>
                </div>

              </div>
            }

          </div>



          <button
          className='heading-saved-1-header'
          onClick={()=>{scrollToFeedback("my_trips")}}
          >
            My Trips
          </button>

          <button
          className='heading-saved-1-header'
          onClick={()=>{scrollToFeedback("my_ai_trips")}}
          >
            AI Trips
          </button>

          <button
          className='container_t-startPlanning-header'
          onClick={()=>{scrollToFeedback("start_planning")}}
          >
            <h2
            className='heading-startPlanning-header-header'
            >
              Start Planning 
            </h2>
            <img 
              className='img-icon-1-header'
              src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/search_icon_3.png'
              alt='search tourist places'
            />
          </button>

          <button
          className='container_t-2-startPlanning-header'
          onClick={()=>{
            setManageLoginWindow(true)
            // handleManageLoginClicked()
            openPopup()
          }}
          >
            {/* <h2
            className='heading-startPlanning-header-header'
            >
              Manage Account
            </h2> */}
            <div
            className='icon-account-1-header'
            >
              <VscAccount />
            </div>
          </button>

        </div>

        
      </div>



      {/* //!Mobile */}
      <div className='container_p-mobile-header'>

        <div
        className='container_t-1-logo_tag-header'
        >
          <div
          className='container_t-1_1-logo-header'
          >
            <img 
            className='img-logo-site-1-header'
            alt='preparetrip.com website main logi. An ai travel marker. A marker, with some intelligence.'
            src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/prepare_trip_logo_1.png'
            />

          </div>

          <div
          className='container_t-1_2-tag-header'
          >

            <h1
            className='heading-prepareTrip-1-tag-header'
            >
              Prepare Trip
            </h1>

         
            {/* <h2
            className='heading-prepareTrip-2-tag-header'
            >
              A Complete trip planner
            </h2> */}

          </div>

        </div>


        <div
        className='container_t-2-site_navigation-header'
        >

          <div className='mobile-notWrapped-header'>
            <button
            className='button-listHotel-header'
            onClick={()=>{setScrollPageTo_AISection("my_ai_trips")}}
            >
              List your hotel <LuHotel />
            </button>

            <button
            className='container_t-startPlanning-header'
            onClick={()=>{scrollToFeedback("start_planning")}}
            >
              <h2
              className='heading-startPlanning-header-header'
              >
                Start Planning 
              </h2>
              <img 
                className='img-icon-1-header'
                src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/search_icon_3.png'
                alt='search tourist places'
              />
            </button>
          </div>

          <button
            onMouseOver={()=>{
              setShowHeaderMobile(true)
              openPopup()
            }}
            onMouseOut={()=>{
              setShowHeaderMobile(false)
              closePopup()
            }}
            className='button-moreOptions-header-Header_mobile'
          >
            <FaAlignRight />
          </button>


          {showHeaderMobile && 
            <div 
              className="overlay-header_mobile"
              onMouseOver={()=>{
                setShowHeaderMobile(true)
                openPopup()
              }}
              onMouseOut={()=>{
                setShowHeaderMobile(false)
                closePopup()
              }}
            >
              <div 
                className={`container-header-mobile ${showHeaderMobile ? 'active' : 'close'}`}
              >

                <button
                  onClick={()=>{
                    setShowHeaderMobile(false)
                    closePopup()
                  }}
                  className='button-close-header-Header_mobile'
                >
                  <FaWindowClose />
                </button>

                {/* Your buttons */}
                <button
                  className='button-1-stays-header'
                  onClick={() => { setScrollPageTo_AISection("my_ai_trips"); }}
                >
                  Plan with AI <WiStars />
                </button>

                <button
                  className='button-1-stays-header'
                  onClick={() => { scrollToFeedback("travel_modes"); }}
                >
                  Travel Modes
                </button>

                <button
                  className='heading-saved-1-header'
                  onClick={() => { scrollToFeedback("my_trips"); }}
                >
                  My Trips
                </button>

                <button
                  className='heading-saved-1-header'
                  onClick={() => { scrollToFeedback("my_ai_trips"); }}
                >
                  AI Trips
                </button>


                <div className='mobile-notWrapped-header-2'>
                  <button
                  className='button-listHotel-header'
                  onClick={()=>{setScrollPageTo_AISection("my_ai_trips")}}
                  >
                    List your hotel <LuHotel />
                  </button>
                        <br></br><br></br>
                  <button
                  className='container_t-startPlanning-header'
                  onClick={()=>{scrollToFeedback("start_planning")}}
                  >
                    <h2
                    className='heading-startPlanning-header-header'
                    >
                      Start Planning 
                    </h2>
                    <img 
                      className='img-icon-1-header'
                      src='https://pub-5886c2c500aa4180b5fae6e2b47d8492.r2.dev/search_icon_3.png'
                      alt='search tourist places'
                    />
                  </button>
                </div>

                <button
                  style={{alignSelf:'center'}}
                  className='container_t-startPlanning-header'
                  onClick={() => {
                    setManageLoginWindow(true);
                    openPopup();
                  }}
                >
                  <h2 className='heading-startPlanning-header-header'>
                    Manage Account
                  </h2> &nbsp;
                  <div className='icon-account-1-header'>
                    <VscAccount />
                  </div>
                </button>


              </div>
            </div>
          }

        </div>


      </div>


      <ToastContainer />


    </div>
  )
}

export default Header




      {/* <header className='box1-header-Home'>

        <div className='logo-box1-Home'>
            <img className='logo-Home' src='https://i.ibb.co/Z8sybKy/FA-removebg-preview.png' />
        </div>

        <div className='navLinks-box1-Home'>
          <div className='navLinks2-box1-Home'>
            <div className='navLink-button-box1-Home'>
              <h2 className='navLinkTest-box1-Home'>
                Decide Destination
              </h2>
              <div className='searchIcon2-Home'><FaMapPin /></div>
            </div>
        
            <div className='navLink-button-box1-Home'>
              <h2 className='navLinkTest2-box1-Home'>
                Start Planning
              </h2>
              <div className='searchIcon2-Home'><FiSearch /></div>
            </div>
          </div>
        </div>

        <div className='knowMore-box1-Home'>
          <div className='knowMore2-box1-Home'>
            <button className='knowMore-button-box1-Home'>
                Quick Explore
            </button>
            <button className='knowMore-button-box1-Home'>
                How to Plan Trip?
            </button>
          </div>
        </div>

      </header> */}


