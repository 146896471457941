import React, { useState, useEffect } from 'react'

//import css
import './HowToReach.scss';

//importing react context
import { useAiCreateItinerary } from '../../../Create Itinerary - Result/Manage/Context/Ai_CreateItineraryContext';

//
import ReactMarkdown from 'react-markdown';

//import react icons
import { FaPlaneDeparture } from "react-icons/fa";
import { GiRailRoad } from "react-icons/gi";
import { FaBusAlt } from "react-icons/fa";
import { GrLocationPin } from "react-icons/gr";


function HowToReach() {

  const {
    //!states
    loadReachData_Status,
    overview_reach,
    airportData_reach,
    railwayData_reach,
    busData_reach,
    //!functions
    handleLoadReachData, 
    destinationName, destinationType,
    //map
    handleHoverLocation_Map, handleHoverOffLocation_Map,
    handleClickLocation_Map
  } = useAiCreateItinerary()
  
  useEffect(() => {

    if(!loadReachData_Status && !overview_reach){
      handleLoadReachData()
    }

  }, [])


  const [isExpanded, setIsExpanded] = useState(false);

  // Define a character limit for "Read More"
  const charLimit = 100;
  
  // Toggle between showing full content or truncated content
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };



  return (
    <div>

      {
        overview_reach ?
        <div
        className='content-1-data-HowToReach'
        >
          {/* <ReactMarkdown>{overview_reach}</ReactMarkdown> */}
          <ReactMarkdown>
            {isExpanded ? overview_reach : `${overview_reach.slice(0, charLimit)}...`}
          </ReactMarkdown>

          <button onClick={toggleReadMore}
            className='button-read_M_L-PromptAndOverview'
          >
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
          
        </div>   
        :
        <div
        className='loading-1-data-PromptAndOverview'
        >
          Loading AI Overview ...
        </div>
      }

      {/* <button onClick={toggleReadMore}
        className='button-read_M_L-PromptAndOverview'
      >
        {isExpanded ? 'Read Less' : 'Read More'}
      </button> */}


      <div> 

        {
          airportData_reach && 
          <div> 

            <div
            className='heading-1-mode-Reach_D'
            > 
              Airports 
            </div>   

            <div
            className='container_p-Cards-Air-Reach_D'
            >

              {
                airportData_reach.map((data, index) => {
                  return(
                    <button key={index}
                    className='card-Air-Reach_D'
                    onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name, "air")}}
                    onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name, "air")}}
                    onMouseOut={()=>{handleHoverOffLocation_Map()}}
                    >
                        <div className='text-1-air-Reach_D'>
                            {data.mode_name}
                        </div>
    
                        <div className='text-2-air-Reach_D'>
                            Code: {data.mode_code}
                        </div>
    
                        <div className='text-3-air-Reach_D'>
                            <div className='text-3-1-air-Reach_D'>
                                in {data.mode_district} 
                            </div>
                            {data.mode_state_name && 
                            <div className='text-3-1-air-Reach_D'>
                                , {data.mode_state_name}.
                            </div>
                            }
                        </div>
    
                        {
                        data.mode_g_review &&
                        <div className='text-4-air-Reach_D'>
                            G reviews: ({data.mode_g_review.toLocaleString('en-IN')})
                        </div>
                        }     
                        
                    
                        { data.distance &&
                        destinationType !== "state" ?
                        <div className='text-5-air-Reach_D'>
                            <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                        </div> :
                        null
                        }

                        {
                          data.mode_type && 
                          <div className='text-3-1-air-Reach_D'>
                            #{data.mode_type} 
                          </div>
                        }
                        
                    </button> 
                  )
                })
              }
            </div>  

          </div>   
        }

        
        {
          railwayData_reach && 
          <div> 

            <div
            className='heading-1-mode-Reach_D'
            > 
              Raiway Stations
            </div>   

            <div
            className='container_p-Cards-Air-Reach_D'
            >
              
              {
                railwayData_reach.map((data, index) => {
                  return(
                    <button
                    className='card-Air-Reach_D'
                    onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name, "train")}}
                    onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name, "train")}}
                    onMouseOut={()=>{handleHoverOffLocation_Map()}}
                    >
        
                      <div className='text-1-air-Reach_D'>
                          {data.mode_name}
                      </div>
        
                      <div className='text-2-air-Reach_D'>
                          Code: {data.mode_code}
                      </div>
        
                      <div className='text-3-air-Reach_D'>
                          <div className='text-3-1-air-Reach_D'>
                              in {data.mode_state_name} 
                          </div>
                          {/* {data.mode_state_name && 
                          <div className='text-3-1-air-Reach_D'>
                              , {data.mode_state_name}.
                          </div>
                          } */}
                      </div>
        
                      {/* {
                          destinationType !== 'state' ?
                          <div>
                              in {data.mode_state_name}.
                          </div>    
                          : 
                          destinationName !== data.mode_state_name ?
                          <div>
                              in {data.mode_state_name}.
                          </div> 
                          : null    
                      } */}
        
                        { data.distance &&
                          destinationType !== "state" ?
                          <div className='text-5-air-Reach_D'>
                            <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                          </div> :
                            null
                        }

                        {/* {
                          data.mode_type && data.mode_type === "major" || (data.mode_type === "A" || data.mode_type === "A1" || data.mode_type === "B") &&
                          <div className='text-3-1-air-Reach_D'>
                            # A Major Train Station
                          </div>
                        } */}


                    </button> 
                  )
                })
              }
            </div>  
            
          </div>   
        }

        
        {
          busData_reach && 
          <div> 

            <div
            className='heading-1-mode-Reach_D'
            > 
              Bus Stands
            </div>   

            <div
            className='container_p-Cards-Air-Reach_D'
            >
              {
                busData_reach.map((data, index) => {
                  return(
                    <button
                    className='card-Air-Reach_D'
                    onClick={()=>{handleClickLocation_Map(data.lat, data.lng, data.mode_name, "bus")}}
                    onMouseOver={()=>{handleHoverLocation_Map(data.lat, data.lng, data.mode_name, "bus")}}
                    onMouseOut={()=>{handleHoverOffLocation_Map()}}
                    >
                      <div className='text-1-air-Reach_D'>
                          {data.mode_name}
                      </div>

                      {
                          destinationType === "state" ?
                          <div className='text-3-1-air-Reach_D'>
                              in {data.mode_state_name}.
                          </div> 
                          : null    
                      }
                      
                      { data.distance &&
                      destinationType !== "state" ?
                      <div className='text-5-air-Reach_D'>
                          <GrLocationPin /> Approx {data.distance.toFixed(2)} kms away from {destinationName}.
                      </div> :
                      null
                      }

                    </button>
                  )
                })
              }
            </div>  
            
          </div>   
        }

      </div>  
      
      
    </div>
  )
}

export default HowToReach